import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// from https://gomakethings.com/how-to-get-the-value-of-a-querystring-with-native-javascript/
var getQueryString = function ( field, url ) {
	var href = url ? url : window.location.href;
	var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
	var string = reg.exec(href);
	return string ? string[1] : null;
};

window.PARAM = {
    type : getQueryString('type'),
    id : getQueryString('id'),
    num : getQueryString('num'),
    name : getQueryString('name'),
    judge_id : getQueryString('judge_id'),
    coord_id : getQueryString('coord_id')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
