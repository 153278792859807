import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { AppDomain} from '../constants';
import Tooltip from 'react-bootstrap/Tooltip';

import postMaster from '../logic/postmaster'
import {divFightShuffle,slowShuffleDiv, swapFights} from '../functions';
import {seeds} from '../constants';
import {FightCall, Fight, BracketChart} from './fights'
import {Standing} from './standing'

export class Division {
    constructor(id) {
        this.needs_update = true;
        this.id = id;
        this.code = '';
        this.child_divisions = [];
        this.name = '';
        this.config_type = '';
        this.min_judges = '';
        this.start_time = '';
        this.standings = [];
        this.status = 0;
        this.current_comp = null;
        this.ordered = 0;
        this.winner_display = false;
        
        this.show_fight_list = false;
        this.zoomed_fight = null;
        
        this.break_ties_till = 4;
        this.min_place = 999;
        this.fights = [];
        this.score_rules = 'float'
        this.start_time = new Date()
        this.shuffled = 0;
        this.tie_solver = '';
        this.deviation = 0;
        this.drop_high = 0;
        this.drop_low = 0;
        this.schedule_id = '';        
        this.temp_ties_at = [];
        this.ring = null;
        this.timer_length = 120;
        
        this.order_by_ranks = false;
        
        this.before_announcer_notes = '';
        this.before_comm_notes = '';
        this.during_announcer_notes = '';
        this.during_comm_notes = '';
        this.certificate_name = '';
        this.after_announcer_notes = '';
        this.after_comm_notes = '';
        
        this.before_announcer_notes_edit = false;
        this.before_comm_notes_edit = false;
        this.during_announcer_notes_edit = false;
        this.during_comm_notes_edit = false;
        this.after_announcer_notes_edit = false;
        this.after_comm_notes_edit = false;
        
        this.rings_imported = [];
        this.ring_import_requests = [];
        
        this.scorekeeper_warning = '';
        
        this.ignore_final_fight = 0;
        
        this.sparring_bracket_type = '';
        this.sparring_rr_if_three = 'No';
        
        this.number_rounds = 1;
        this.number_rounds_first = 1;
        this.number_rounds_third = 1;
        this.number_rounds_quarter = 1;

        this.break_timer = 0;
        this.break_timer_first = 0;
        this.break_timer_third = 0;
        this.break_timer_quarter = 0;
        
        this.max_fight_spread = 0;
        this.max_fight_spread_third = 0;
        this.max_fight_spread_first = 0;
        this.max_fight_spread_quarter = 0;
        
        this.min_fight_spread_first = 0;
        this.break_timer_first = 0;
        this.min_fight_spread_third = 0;
        this.min_fight_spread_quarter = 0;
        
        this.timer_first = 120;
        this.timer_third = 120;
        this.timer_quarter = 120;
        
        this.call_pack = '';
        this.duration_estimate = 0;
        
        this.is_team = 0;
        this.min_size = 1;
        this.max_size = 5;
        
        this.age_begin = null;        
        this.age_end = null;
        
        this.level_begin = null;
        this.level_end = null;
        
        this.gender = '';
        this.is_grand = 0;
        
        this.protests = [];
        
        this.getUncheckedDisplay = this.getUncheckedDisplay.bind(this);
        this.getCompPlacesDisplay = this.getCompPlacesDisplay.bind(this);
        
        this.min_score = null;
        this.max_score = null;
        this.score_prefill = null;
        
        this.split_countries = false;
        
        this.shuffle_text = '';
        
        this.deviation_below_drop_above = 0;
    }
    
    parseText(text) {
        text = text.replace('%%div_name%%', this.name);
        if(this.certificate_name) {
            text = text.replace('%%cert_name%%', this.certificate_name);
        } else {
            text = text.replace('%%cert_name%%', this.name);
        }
        let winner = this.getWinner();
        if(winner) {
            text = text.replace('%%winner_name%%', winner.name);
            text = text.replace('%%winner_city%%', winner.getCity() );
            text = text.replace('%%winner_state%%', winner.getState() );
            text = text.replace('%%winner_s_state%%', winner.getShortState() );
            text = text.replace('%%winner_country%%', winner.country_name);
            text = text.replace('%%winner_team%%', winner.getTeam() );
            text = text.replace('%%winner_weight%%', winner.getWeightSimple() );
            text = text.replace('%%winner_age%%', winner.age);
            text = text.replace('%%winner_gender%%', winner.gender);
            text = text.replace('%%winner_instructor%%', winner.getInstructor() );
            text = text.replace('%%winner_total_score%%', winner.getScoreTotal(this) );
            
        }
        let div = this;
        let standings = this.getCompOrder();
        standings.forEach((value, index, self2) => {
            text = text.replace('%%comp'+value.ordered+'_name%%', value.name);
            text = text.replace('%%comp'+value.ordered+'_city%%', value.getCity() );
            text = text.replace('%%comp'+value.ordered+'_state%%', value.getState() );
            text = text.replace('%%comp'+value.ordered+'_s_state%%', value.getShortState() );
            text = text.replace('%%comp'+value.ordered+'_country%%', value.country_name);
            text = text.replace('%%comp'+value.ordered+'_team%%', value.getTeam() );
            text = text.replace('%%comp'+value.ordered+'_weight%%', value.getWeightSimple() );
            text = text.replace('%%comp'+value.ordered+'_age%%', value.age); 
            text = text.replace('%%comp'+value.ordered+'_instructor%%', value.getInstructor() );
            text = text.replace('%%comp'+value.ordered+'_gender%%', value.gender );
            
            text = text.replace('%%comp'+value.ordered+'_score1%%', value.getTextScore(0)); 
            text = text.replace('%%comp'+value.ordered+'_score2%%', value.getTextScore(1)); 
            text = text.replace('%%comp'+value.ordered+'_score3%%', value.getTextScore(2)); 
            text = text.replace('%%comp'+value.ordered+'_score4%%', value.getTextScore(3)); 
            text = text.replace('%%comp'+value.ordered+'_score5%%', value.getTextScore(4)); 
            text = text.replace('%%comp'+value.ordered+'_score6%%', value.getTextScore(5)); 
            text = text.replace('%%comp'+value.ordered+'_score7%%', value.getTextScore(6));
            
            text = text.replace('%%comp'+value.ordered+'_total_score%%', value.getScoreTotal(div) );
        });
        
        if(this.config_type == 'sparring') {
            if(this.ring && this.ring.current_fight) {
                var left = this.ring.current_fight.getLeftComp();
                var right = this.ring.current_fight.getRightComp();
                
                text = text.replace('%%fight_time%%', this.ring.current_fight.timer.getDisplay() );
                
                text = text.replace('%%left_name%%', left.getName() );
                text = text.replace('%%right_name%%', right.getName() );
                
                text = text.replace('%%left_points%%', this.ring.current_fight.getLeftPoints() );
                text = text.replace('%%right_points%%', this.ring.current_fight.getRightPoints() );

                text = text.replace('%%left_cpoints%%', this.ring.current_fight.getLeftTotalPoints() );
                text = text.replace('%%right_cpoints%%', this.ring.current_fight.getRightTotalPoints() );

                text = text.replace('%%left_city%%', left.getCity() );
                text = text.replace('%%right_city%%', right.getCity() );
                
                text = text.replace('%%left_state%%', left.getState() );
                text = text.replace('%%right_state%%', right.getState() );
                
                text = text.replace('%%left_s_state%%', left.getShortState() );
                text = text.replace('%%right_s_state%%', right.getShortState() );
                
                text = text.replace('%%left_weight%%', left.getWeightSimple() );
                text = text.replace('%%right_weight%%', right.getWeightSimple() );
                
                text = text.replace('%%left_age%%', left.age );
                text = text.replace('%%right_age%%', right.age );
                
                text = text.replace('%%left_team%%', left.getTeam() );
                text = text.replace('%%right_team%%', right.getTeam());
                
                text = text.replace('%%left_country%%', left.country_name );
                text = text.replace('%%right_country%%', right.country_name );
                
                text = text.replace('%%left_instructor%%', left.getInstructor() );
                text = text.replace('%%right_instructor%%', right.getInstructor() );
                
                
                text = text.replace('%%left_gender%%', left.gender );
                text = text.replace('%%right_gender%%', right.gender );
                
            }
        }
        return text;
    }
    
    processProfile(p){
        var c = this.getComp(p.standing_id);
        if(c) c.profile_data = p.comp;
    }
    
    hasDuplicateChecked() {
        var comps_seen = [];
        var doubles = false;
        
        var comps = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true;
                }
        );
        
        comps.forEach(function(c){
           if(doubles) return;            
           if(comps_seen.includes(c.name) ) {
               doubles = true;
           }  else {
               comps_seen.push(c.name);
           }
        });
        return doubles;
    }
    
    unCheckDups() { 
        var div = this;
    
        if(div.config_type == 'scores' && !window.confirm('This will un-check duplicate competitors before the shuffle, are you sure you want to do this?')) {
            return;
        }
    
        var comps = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true;
                }
        );
        
        comps.forEach(function(c){ 
            if(!c.checked_in) {
                return;
            }
            
            if(c.members.length >0 ) {
                return;
            }
            
            var others = comps.filter(x=>x.name == c.name && x.checked_in == true && c.id != x.id);
            others.forEach(x=>{
                x.check(false);        
                postMaster.toSend('/tournament-ring/'+ div.ring.id + '/standing/' + x.id + '/checkin/false');
            });
        });
        window.APP.reset();
    }
    
    hasDuplicateCompetitors() {
        var comps_seen = [];
        var doubles = false;
        
        var comps = this.getCompOrder();
        
        comps.forEach(function(c){
           if(doubles) return;            
           if(comps_seen.includes(c.name) ) {
               doubles = true;
           }  else {
               comps_seen.push(c.name);
           }
        });
        return doubles;
    }
    
    
    deDuplicateComps() {
        var comps = this.getCompOrder();
        var d=this;
        comps.forEach(function(c){
           var later = comps.find(x=>x.name == c.name && c.ordered < x.ordered);
           if(later) {
               var num = c.ordered;
               c.ordered = 0;
               postMaster.toSend('/tournament-ring/'+ d.ring.id + '/standing/' + c.id + '/checkin/false');
               var laters = comps.filter( x => num < x.ordered);
               laters.forEach(l=>{ l.ordered = l.ordered - 1; });
           }
        });
        this.completeShuffle();
        window.APP.reset();
    }
    
    checkAndRunDraws(ring) {
        if( this.fights.length > 0 || this.shuffled > 0) {
            return;
        }
        
        this.standings.forEach((comp)=> {
            if(comp.members.length == 0) {
                if(comp.is_blocked != 'Yes') {
                    comp.check(true);        
                    postMaster.toSend('/tournament-ring/'+ ring.id + '/standing/' + comp.id + '/checkin/true');
                }
            } else {
                comp.members.forEach( (st, index, self2) => {
                    if(st.is_blocked != 'Yes') {
                        st.check(true);        
                        postMaster.toSend('/tournament-ring/'+ ring.id + '/standing/' + st.id + '/checkin/true');
                    }
                });
            }
        });
        
        this.earlyShuffle();
    }

    printDurationEstimate() {
        if (this.duration_estimate == 0) {
            return 'No time required';
        }
    
        var min = Math.floor(this.duration_estimate / 60);
        var sec = this.duration_estimate % 60;
        if(sec < 10) {
            sec = '0' + new String(sec);
        }
        return min + ":" + sec;
    }

    niceFormatScore(f) {
         if (this.score_rules != 'int') {
             return f.toFixed(2);
         } else {
             return f;
         }
    }
    
    statusSymbol() {
        if(this.status == 1) {
            return <span class='text-primary'><i class="fas fa-play-circle"></i>&nbsp;</span>;
        }
        
        if(this.status == 2) {
            return <span class='text-primary'><i class="fas fa-list-ol"></i>&nbsp;</span>;
        }
        
        if(this.status == 3) {
            return <span class='text-success'><i class="fas fa-check-circle"></i>&nbsp;</span>;
        }
        
        if(this.status == 4) {
            return <span class='text-warning'><i class="fas fa-play-pause-circle"></i>&nbsp;</span>;
        }
        
        if(this.status == 5) {
            return <span class='text-danger'><i class="fas fa-play-stop-circle"></i>&nbsp;</span>;
        }
    }
    
    niceStatus() {    
        if(this.status == 0) {
            return <div>Not Started</div>;
        }
        
        if(this.status == 1) {
            return <div class='text-primary'>Competing {this.is_final == '1' && <span>(Finalized)</span> }</div>;
        }
        
        if(this.status == 2) {
            return <div class='text-primary'>Scoring {this.is_final == '1' && <span>(Finalized)</span> }</div>;
        }
        
        if(this.status == 3) {
            return <div class='text-success'>Completed {this.is_final == '1' && <span>(Finalized)</span> }</div>;
        }
        
        if(this.status == 4) {
            return <div class='text-warning'>Paused</div>;
        }
        
        if(this.status == 5) {
            return <div class='text-danger'>Canceled</div>;
        }
    }
    
    codePop(ring_data = false) {
        const popover = (
            <Popover >
              <Popover.Title>{this.name}</Popover.Title>
              <Popover.Content>
              {this.niceStatus()}
              {ring_data && this.ring && <div>
              {this.ring.name}<br></br>
              {this.estimated_start.toLocaleDateString(window.APP.date_locale,window.APP.date_options)} {this.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}
                </div>}
              
              {this.status == 0 && <div>
                {this.getCheckedIn().length} / {this.standings.length} checked in
                    </div>}
                {this.status == 1 && <div>
                {this.current_comp && <div>{this.current_comp.ordered} {this.current_comp.name}</div>}
                {this.getCheckedIn().length} total
                    </div>}
                {this.status == 2 && <div>
                {this.current_comp && <div>{this.current_comp.ordered} {this.current_comp.name}</div>}
                {this.getCheckedIn().length} total
                    </div>}
                {this.status == 3 && <div>
                    {this.getTies.length} ties
                    </div>}
                
              </Popover.Content>
            </Popover>
          );
  
        const Area = () => (
            <div>
                <OverlayTrigger  placement="top" overlay={popover}>
                <div>{this.statusSymbol()}{this.code}</div>
                </OverlayTrigger>
            </div>
         );
         
         return <Area />;
    }
    
    progressBar(variant = null) {
        var value = 0;
        var show = false;
        
        if(this.status == 0) {
            show = true;
            value = this.getCheckedIn().length / this.standings.length
        }
        
        if(this.config_type == 'scores') {
            if(this.status == 1 && this.current_comp && this.getCheckedIn().length > 0) {
                value = this.current_comp.ordered / this.getCheckedIn().length
                show = true;
            }

            if(this.status == 2  && this.current_comp && this.getCheckedIn().length > 0) {
                value = this.current_comp.ordered / this.getCheckedIn().length
                show = true;
            }
        }
       
        if(this.config_type == 'sparring') {
            if(this.status == 1 && (this.fights.length - this.getCheckedIn().length ) > 0) { 
                value = (this.fights.filter(function(e) {return e.winner != 0; }).length - this.getCheckedIn().length) / (this.fights.length - this.getCheckedIn().length)
                show = true;
            }
        }
        
        if(!show) {
            return <div></div>
        }
        
        value = value * 100;
        
        if(!variant) {
            return  <ProgressBar  now={value} />;
        } else {
            return  <ProgressBar  variant={variant} now={value} />;
        }
    }
    
    upperProgressBar(variant = null) {
        var value = 0;
        var show = false;
        
        if(this.status == 0) {
            show = true;
            value = this.getCheckedIn().length / this.standings.length
        }
        
        if(this.config_type == 'scores') {
            if(this.status == 1 && this.current_comp && this.getCheckedIn().length > 0) {
                value = this.current_comp.ordered / this.getCheckedIn().length
                show = true;
            }

            if(this.status == 2  && this.current_comp && this.getCheckedIn().length > 0) {
                value = this.current_comp.ordered / this.getCheckedIn().length
                show = true;
            }
        }
        
        if(this.config_type == 'sparring') {
            if(this.status == 1 && (this.fights.length - this.getCheckedIn().length ) > 0) { 
                value = (this.fights.filter(function(e) {return e.winner != 0; }).length - this.getCheckedIn().length) / (this.fights.length - this.getCheckedIn().length)
                show = true;
            }
        }
        
        if(!show) {
            return <div></div>
        }
        
        value = value * 100;
        
        if(!variant) {
            return  <ProgressBar style={{ height:'32px' }} now={value} />;
        } else {
            return  <ProgressBar style={{ height:'32px' }} variant={variant} now={value} />;
        }
    }
    
   
    
    fixAllScores() {
        var needsToFix = false;
        
        this.standings.filter(
            function (stand) {
                return stand.checked_in == true && stand.getScoreTotal() > 0;
        }).forEach( (value, index, self2) => {
            value.fixScores(this);
        })
        
        var scores = [];
        
        this.standings.forEach(comp => {
            comp.scores.forEach(s => {
                if(s.score != 0 && parseFloat(this.score_prefill) != parseFloat(s.og_score) ) {
                    needsToFix = true;
                }
                s.judge_id = window.APP.ring.getJudge(parseInt(s.judge) + 1).id;
                scores.push({ id: comp.id,score: s.score,og_score: s.og_score,drop: s.drop, num:s.judge, judge_id:s.judge_id });
            })           
        });
        
        if(needsToFix) {
            postMaster.toSendData('/tournament-ring/' +window.APP.ring.id + '/division/' +this.id + '/fixed-scores',scores )
        }
    }
    
    getFirstRowBracketCount() {
        if(this.zoomed_fight) {
            return this.zoomed_fight.getLowerFirstFightsCount();
        }
    
        return this.fights.filter((e) => {return e.comp1_fight == null && e.comp2_fight == null && e.is_bye == 0;}).length + (this.fights.filter((e) => {return e.comp1_fight == null && e.comp2_fight == null && e.is_bye == 1;}).length * 2)
    }
    
    bracketsD3Chart() {    
        if(this.fights.length == 0) {
            return null;
        }
        if(this.fights.length == 1) {
            return this.fights[0].getD3Rep(this);
        }
        
        
        
        var root = {
            id : 'rootbrackets',
            name : 'root',
            children : [], click: function() {},
            fill: '#2b2b2b'
        }
        
        if(this.zoomed_fight) {
            root.children.push( this.zoomed_fight.getD3Rep(this) );
        } else {
            this.fights.forEach( (f) => {
                if(f.proceeds_winner == null && f.proceeds_loser== null) {
                    root.children.push( f.getD3Rep(this) );
                }
            });
        }
        return root;
    }
    
    fightsInOrder() {    
        var fights = [];
        var next_fights = [];
        
        if(this.zoomed_fight) {
            next_fights.push(this.zoomed_fight);
        } else {
            this.fights.forEach( (f) => {
                if(f.proceeds_winner == null && f.proceeds_loser== null) {
                    next_fights.push( f );
                }
            });
        }
        
        //next fight are currently the top fights
        var round = -1;
        
        while(next_fights.length > 0) {
            var current_fights = next_fights;
            next_fights = [];
            //go through these current fights, clear the next list
            current_fights.forEach(function(f){
                fights.push(f); //we want all fights
                
                f.bracket_round = round;
                
                //go through right, then left and check them in the next round if they aren't done
                if(f.getRightFight() && f.getRightFight().is_bye == 0
                        && (f.getRightFight().proceeds_loser == null || f.getRightFight().proceeds_loser.id != f.id )
                        && (f.getRightFight().winner == 0 || ( f.getRightFight().comp1 && f.getRightFight().comp2) )
                        ) {
                    next_fights.push(f.getRightFight());
                }
                
                if(f.getLeftFight() && f.getLeftFight().is_bye == 0
                        && (f.getLeftFight().proceeds_loser == null || f.getLeftFight().proceeds_loser.id != f.id )
                        && (f.getLeftFight().winner == 0 || (f.getLeftFight().comp1 && f.getLeftFight().comp2) )
                        ) {
                    next_fights.push(f.getLeftFight());
                }
            });       
            round = round - 1;
        }
        
        var max_round = (round * -1);
        fights.forEach(function(f){
           f.bracket_round = f.bracket_round + max_round;
        });
        
        var number = 1;        
        fights = fights.reverse();        
        //we add from top down, right to left, but we want to return left to right, bottom up
        
        fights.forEach(f=>{
            f.fight_num = number;
            number++;
        });
        
        return fights;
    }
    
    toggleWinnerPage() {
        this.winner_display = !this.winner_display;
    }
    
    toggleFightList() {
        this.show_fight_list = !this.show_fight_list;
    }
    
    completeSplit() {
        this.status = 3;
        
        let text = '?';
        this.standings.forEach( (value, index, self2) => {
            if(value.members.length == 0) {
                text = text + value.id + '=' + value.child_division_set_to + '&';
            } else {
                value.getCheckedIn().forEach( (value2, index, self2) => {
                    text = text + value2.id + '=' + value.child_division_set_to + '&';
                }) 
            }
            
         }) 
        
        postMaster.toSend( '/tournament-division/'+this.id+'/parent-split' + text );
    }
    
    complete() {
        this.status = 3;
        if(this.child_divisions > 0){
            return;
        }
        this.setPlaces();
    }
    
    setCurrentComp(comp) {
        this.current_comp = comp;
    }
    
    completeShuffle() {
        let text = '?';
        this.standings.forEach( (value, index, self2) => {
            text = text + value.id + '=' + value.ordered + '&';
         }) 
        this.shuffled = 1;
        this.shuffle_text = '';
        postMaster.toSend( '/tournament-division/'+this.id+'/shuffle' + text );        
    }
    
    earlyShuffle() {
        this.shuffle_text = 'Shuffling';
        if(this.config_type == 'scores') {
            this.standings.map(c => {c.ordered = 0;});        
            slowShuffleDiv(this.standings, 1,0, this)
        } else {
            this.fights = [];
            this.setupBrackets(3);            
        }
    }
    
    shufStart() {
        this.status = 1;
        this.current_comp = null;
        this.shuffle_text = 'Shuffling';
        
        
        if(this.config_type == 'scores') {
            this.standings.map(c => {c.ordered = 0;});
        
            slowShuffleDiv(this.standings, 1,5, this)
        } else {
            this.fights = [];
            this.setupBrackets();            
        }
    }
    
    coordShuf() {
        this.shuffle_text = 'Shuffling';
        if(this.config_type == 'scores') {
            this.standings.map(c => {c.ordered = 0;});        
            slowShuffleDiv(this.standings, 1,5, this)
        } else {
            this.fights = [];
            this.setupBrackets();            
        }
    }
    
    start() {
        this.status = 1;
        this.current_comp = null;
    }
    
    
    
    addFight(fight) {
        var f = new Fight(fight.winner_place,null, this.timer_length);
        if(this.ignore_final_fight == 1 && f.winner_place == 1) {
            f.shouldIgnore();
        }
        this.fights.push(f);
        
        f.swapped = fight.swapped;
        
        f.current_round = parseInt(fight.current_round);
        f.rounds = parseInt(fight.rounds);
        f.break_timer = parseInt(fight.break_timer);
        f.on_break = parseInt(fight.on_break);
        f.round_timer = parseInt(fight.round_timer);
        
        
        f.max_fight_spread = parseInt(fight.max_fight_spread);
        f.min_fight_spread = parseInt(fight.min_fight_spread);
        
        f.id = fight.id;
        f.winner = fight.winner;
        f.person_1_points = parseInt(fight.person_1_points);
        f.person_2_points = parseInt(fight.person_2_points);
        f.is_bye = fight.is_bye;
        
        f.comp1 = this.getComp(fight.person_1);
        f.comp2 = this.getComp(fight.person_2);
       
        f.comp1_loser = fight.person_1_fight_loser;
        f.comp2_loser = fight.person_2_fight_loser;
       
        if(fight.fight_1 && fight.fight_1 != '') {
            if(typeof(fight.fight_1) == 'string' ) {
                f.comp1_fight = this.getFight(fight.fight_1);
                f.comp1_fight.proceeds_loser = f;
            } else {
                f.comp1_fight = this.addFight(fight.fight_1);
                f.comp1_fight.proceeds_winner = f;
            }
        }
        
        if(fight.fight_2 && fight.fight_2 != '') {
            if(typeof(fight.fight_2) == 'string' ) {
                f.comp2_fight = this.getFight(fight.fight_2);
                f.comp2_fight.proceeds_loser = f;
            } else {
                f.comp2_fight = this.addFight(fight.fight_2);
                f.comp2_fight.proceeds_winner = f;
            }
        }
        
        if(fight.calls &&  this.ring) {
            fight.calls.forEach(e => { 
                let acall = this.ring.getAVCalls().find(element => element.id == e.call_id);
            
                let comp = null;

                if(e.person_id && f.comp1 && e.person_id == f.comp1.id) {
                   comp = f.comp1;
                }
                if(e.person_id && f.comp2 && e.person_id == f.comp2.id) {
                   comp = f.comp2;
                }

                let call = new FightCall({
                    id: e.id,
                    opposing_points: e.opposing_points,
                    points: e.points,
                    dq: e.dq,
                    warning: e.warning,
                    person: comp,
                    availableCall: acall,
                    winner: e.winner,
                    time_change: e.time_change,
                    end_round: e.end_round
                });
                f.receiveCall(call, false)
                 });
        }
            f.timer.current =fight.timer_current;
        
        if( fight.in_ring_name) {
            f.in_ring_name = fight.in_ring_name;
        }
        return f;
    }
    
    setupBrackets(animated = 0) { this.shuffle_text = 'Shuffling Brackets';
        const div = this;
        var comps = this.standings.filter(function(e){ return e.checked_in;});
        var count = comps.length;
        var top = null;
        divFightShuffle(comps, this);
        comps = comps.sort(function(a, b){             
                return a.ordered - b.ordered;
        });
        
        
        var fights = [];
        
        if(comps.length == 1) { 
            var comp = new Fight(1,null, div.timer_first, div.number_rounds_first, div.break_timer_first, div.max_fight_spread_first, div.min_fight_spread_first);
            comp.comp1 = comps.pop();
            comp.winner = 1;
            fights.push(comp);
            
            this.fights = fights;
            postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:[this.fights[0].simple()] } );
            this.shuffled = 1;
            this.shuffle_text = '';
            window.APP.reset();
            return;
        } 
        if(comps.length == 2) { 
            top = new Fight(1,null, div.timer_first, div.number_rounds_first, div.break_timer_first, div.max_fight_spread_first, div.min_fight_spread_first);
            if(this.ignore_final_fight == 1) {
                top.shouldIgnore();
            }
            
            if(this.sparring_bracket_type == 'double') {
                top.double_1 = true;
                top.double_2 = true;
            }
            fights.push(top);
            
            var comp = new Fight(3,null, div.timer_length);
            comp.comp1 = comps.pop();
            comp.winner = 1;

            top.comp1_fight = comp;
            top.comp1 = comp.comp1;
            comp.proceeds_winner = top;
            fights.push(comp);

            comp = new Fight(3,null, div.timer_length);
            comp.comp1 = comps.pop();
            comp.winner = 1;

            top.comp2_fight = comp;
            top.comp2 = comp.comp1;
            comp.proceeds_winner = top;
            fights.push(comp);
            
            this.fights = fights;
            postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:[this.fights[0].simple()] } );   
            this.shuffled = 1;
            this.shuffle_text = '';
            window.APP.reset();
            return;
        }
        
        if(comps.length == 3 && (this.sparring_rr_if_three == 'Yes' || this.sparring_rr_if_three == 'Yes With Accumalation Tie Breaker' ) ) { 
            if(this.ignore_final_fight == 1) { 
                var comp1 = comps[0];
                var comp2 = comps[1];
                var comp3 = comps[2];

                var top = new Fight(1,null, div.timer_length, div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                top.shouldIgnore();

                fights.push(top);
                
                var added1 = new Fight(3,null, div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                top.comp1_fight = added;
                added1.proceeds_winner = top;
                top.comp1_fight = added1;
                fights.push(added1);

                var comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp1;
                comp.winner = 1;

                added1.comp1_fight = comp;
                added1.comp1 = comp.comp1;
                comp.proceeds_winner = added1;
                fights.push(comp);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp2;
                comp.winner = 1;

                added1.comp2_fight = comp;
                added1.comp2 = comp.comp1;
                comp.proceeds_winner = added1;
                fights.push(comp);

                var added2 = new Fight(3,null, div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                top.comp2_fight = added;
                added2.proceeds_winner = top;
                top.comp2_fight = added2;
                fights.push(added2);
                
                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp3;
                comp.winner = 1;

                added2.comp2_fight = comp;
                added2.comp2 = comp.comp1;
                added2.comp1_loser = 1;
                comp.proceeds_winner = added2;
                fights.push(comp);

                added2.comp1_fight = added1;
                added1.proceeds_loser = added2;

                this.fights = fights;
                var top_fights = [];

                this.fights.forEach((f)=>{
                    if(f.proceeds_winner == null && f.proceeds_loser == null) {
                        top_fights.push(f.simple());
                    }
                })

                postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:top_fights} );
                this.shuffled = 1;
                this.shuffle_text = '';
                window.APP.reset();
                return;
            
            } else {
                var comp1 = comps[0];
                var comp2 = comps[1];
                var comp3 = comps[2];

                var top = new Fight(3,null, div.timer_length, div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);

                fights.push(top);

                var comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp1;
                comp.winner = 1;

                top.comp1_fight = comp;
                top.comp1 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp2;
                comp.winner = 1;

                top.comp2_fight = comp;
                top.comp2 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                top = new Fight(3,null, div.timer_length, div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                fights.push(top);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp1;
                comp.winner = 1;

                top.comp1_fight = comp;
                top.comp1 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp3;
                comp.winner = 1;

                top.comp2_fight = comp;
                top.comp2 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                top = new Fight(3,null, div.timer_length, div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                fights.push(top);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp2;
                comp.winner = 1;

                top.comp1_fight = comp;
                top.comp1 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                comp = new Fight(3,null, div.timer_length);
                comp.comp1 = comp3;
                comp.winner = 1;

                top.comp2_fight = comp;
                top.comp2 = comp.comp1;
                comp.proceeds_winner = top;
                fights.push(comp);

                this.fights = fights;
                var top_fights = [];

                this.fights.forEach((f)=>{
                    if(f.proceeds_winner == null && f.proceeds_loser == null) {
                        top_fights.push(f.simple());
                    }
                })

                postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:top_fights} );
                this.shuffled = 1;
                this.shuffle_text = '';
                window.APP.reset();
                return;
            }
        }
        var best_fights;
        var min_conflicts = Number.MAX_VALUE;
        var attempts = 0;
            
        if(this.sparring_bracket_type != 'double') {
            //This will setup the 'typical' brackets
            //setup as many fights as possible before byes are considered.        
            
            var best_third;            
            var third = null;

            while(attempts < 100 && attempts < count * 4) {  
                fights = [];   
                top = new Fight(1,null, div.timer_first, div.number_rounds_first, div.break_timer_first, div.max_fight_spread_first, div.min_fight_spread_first);
                if(this.ignore_final_fight == 1) {
                    top.shouldIgnore();
                }
                fights.push(top);        
                var last_round = [top];
                comps = this.standings.filter(function(e){ return e.checked_in;});
                divFightShuffle(comps,this);
                comps = comps.sort(function(a, b){             
                    return a.ordered - b.ordered;
                });
                
                var place = 1;
                //the filler rounds
                while(last_round.length * 2 < comps.length) {
                    var current_round = [];
                    place = (place * 2) + 1;
                    last_round.forEach(element => {
                        var added = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                        element.comp1_fight = added;
                        added.proceeds_winner = element;
                        fights.push(added);
                        current_round.push(added);

                        added = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                        element.comp2_fight = added;
                        added.proceeds_winner = element;
                        fights.push(added);
                        current_round.push(added);
                    });

                    last_round = current_round;
                }

                const total_byes = (last_round.length * 2) - comps.length;
                                
                var byes_left = total_byes;
                var distance_only_byes = 0;
                
                if(comps.length == 13) {
                    distance_only_byes= 1;
                }
                const distance = last_round.length;

               
                place = (place * 2) + 1;
                last_round.forEach( function(element,i) {
                    //BYE based on required - not priortized
                    if(byes_left >= ( distance - i ) || 
                            ( (byes_left - distance_only_byes) / (total_byes  - distance_only_byes) ) >= ( (distance - i ) / distance ) 
                        ) {
                        
                        var bye_comp = null;
                                                
                        if(bye_comp == null) {
                            bye_comp = comps.shift();
                        }
                        element.comp1 = bye_comp;
                        element.winner = 1;
                        element.is_bye = 1;
                        byes_left = byes_left - 1;

                        element.declareWinner(1);

                        //not a bye
                    } else {
                        var comp = new Fight(place, null,div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                        comp.comp1 = comps.shift();
                        comp.winner = 1;

                        element.comp1_fight = comp;
                        element.comp1 = comp.comp1;
                        comp.proceeds_winner = element;
                        fights.push(comp);

                        comp = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer, div.max_fight_spread, div.min_fight_spread);
                        comp.comp1 = comps.shift();
                        comp.winner = 1;

                        element.comp2_fight = comp;
                        element.comp2 = comp.comp1;
                        comp.proceeds_winner = element;
                        fights.push(comp);
                    }


                });
                
                //NEED TO MAKE SURE PRIORTIZED BYES GET THEIR BYE IF EASY
                last_round.forEach( function(element,i) {
                    var bye_swap = 0;
                    var comp = null;
                    var bye_fight = null;
                    if(element.is_bye  == 1) {
                        return;
                    }
                    
                    if(element.comp1.pri_bye > 0) {
                        bye_swap = 1;
                        bye_fight = element.comp1_fight;
                        comp = element.comp1;
                    }
                    
                    
                    if(element.comp2.pri_bye > 0) {
                        bye_swap = 2;
                        bye_fight = element.comp2_fight;
                        comp = element.comp2;
                    }
                    
                    if(bye_swap == 0) {
                        return;
                    }
                    
                    var am_fight = 0;
                    
                    if(element.proceeds_winner.comp2_fight == element) {
                        am_fight = 2;
                    }
                    
                    if(element.proceeds_winner.comp1_fight == element) {
                        am_fight = 1;
                    }
                    
                    if(am_fight == 0) {
                        return;
                    }
                    
                    if(am_fight == 1 && element.proceeds_winner.comp2_fight.is_bye == 0) {
                        return;
                    }
                    
                    if(am_fight == 2 && element.proceeds_winner.comp1_fight.is_bye == 0) {
                        return;
                    }
                    
                    var bye_comp_s = null;
                    
                    if(am_fight == 1) {
                        bye_comp_s = element.proceeds_winner.comp2_fight.comp1;
                    }
                    
                    if(am_fight == 2) {
                        bye_comp_s = element.proceeds_winner.comp1_fight.comp1;
                    }
                    
                    if(bye_comp_s.pri_bye >= comp.pri_bye) {
                        return;
                    }
                    
                    
                    
                    if(am_fight == 1) {
                        swapFights(bye_fight,element.proceeds_winner.comp2_fight);
                    }
                    
                    if(am_fight == 2) {
                        swapFights(bye_fight, element.proceeds_winner.comp1_fight);
                    }
                });



                if(top.comp1_fight){
                    top.comp1_fight.rounds = div.number_rounds_quarter;
                    top.comp1_fight.break_timer = div.number_rounds_quarter;
                    top.comp1_fight.setTimer(div.timer_quarter);
                    top.comp1_fight.max_fight_spread = div.max_fight_spread_quarter;
                    top.comp1_fight.min_fight_spread = div.min_fight_spread_quarter;
                }

                if(top.comp2_fight){
                    top.comp2_fight.rounds = div.number_rounds_quarter;
                    top.comp2_fight.break_timer = div.number_rounds_quarter;
                    top.comp2_fight.setTimer(div.timer_quarter);                    
                    top.comp2_fight.max_fight_spread = div.max_fight_spread_quarter;
                    top.comp2_fight.min_fight_spread = div.min_fight_spread_quarter;
                }

                if(this.break_ties_till > 2 && count > 3 && top.comp1_fight && top.comp2_fight) {
                    var leftF = top.comp1_fight;
                    var rightF = top.comp2_fight;

                    leftF.winner_place = 5;
                    rightF.winner_place = 5;

                    var added = new Fight(place,null, div.timer_third, div.number_rounds_third, div.break_timer_third, div.max_fight_spread_third, div.min_fight_spread_third);
                    added.comp1_fight = leftF;
                    added.comp2_fight = rightF;

                    added.comp1_loser= 1;
                    added.comp2_loser= 1;

                    leftF.proceeds_loser = added;
                    rightF.proceeds_loser = added;

                    fights.push(added);

                    added.winner_place = 3;

                    this.third = added;
                    third = added;
                } else {
                    if( top.comp1_fight && top.comp2_fight) {
                        top.comp1_fight.winner_place = 2;
                        top.comp2_fight.winner_place = 2;
                    }       
                }     
                var num_conf = 0.0;            
                fights.forEach(element => { 
                    if(element.comp1 == null || element.comp2 == null) {
                        return;
                    }
                    var has_conf = false;
                    element.comp1.schoolteams.forEach(school1 => { 
                        if(has_conf) {
                            return;
                        }    
                        
                        if(div.split_countries) {
                            if(element.comp1.country_name == element.comp2.country_name) {
                                 has_conf = true;
                                 return;
                            }
                        }
                        
                        element.comp2.schoolteams.forEach(school2 => { 
                            if(school1 == school2 && school1 != '' && school2 != "" ) {
                                has_conf = true;
                                return;
                            }
                        });                    
                    });
                    if(has_conf) {
                        num_conf = num_conf + 50.0;
                    }
                });            
                
                if(div.split_countries) { 
                    var count_comps = this.standings.filter(function(e){ return e.checked_in;});
                    count_comps.forEach(comp1 => { 
                        count_comps.forEach(comp2 => { 
                            var comp1_fight_cur = null;
                            var comp2_fight_cur = null;
                            
                            if(comp1.country_name != comp2.country_name) return;
                            
                            fights.forEach(element => { 
                                if( (element.comp1 == comp1 && element.comp1_fight == null) || (element.comp2 == comp1 && element.comp2_fight == null)) {
                                    comp1_fight_cur = element;
                                }
                                if( (element.comp1 == comp2 && element.comp1_fight == null) || (element.comp2 == comp2 && element.comp2_fight == null )) {
                                    comp2_fight_cur = element;
                                }
                            });
                            
                            
                            if(comp2_fight_cur == null) {
                                return;
                            }
                            
                            if(comp2_fight_cur == comp1_fight_cur) {
                                return;
                            }
                            
                            if(comp1_fight_cur.is_bye == 0) {
                                comp1_fight_cur = comp1_fight_cur.proceeds_winner;
                            }
                            
                            if(comp2_fight_cur.is_bye == 0) {
                                comp2_fight_cur = comp2_fight_cur.proceeds_winner;
                            }
                            
                            let height = 1;
                            while(comp1_fight_cur != null && comp2_fight_cur != null && comp1_fight_cur != comp2_fight_cur ) {
                                comp1_fight_cur = comp1_fight_cur.proceeds_winner;
                                comp2_fight_cur = comp2_fight_cur.proceeds_winner;
                                height = height + 1;
                            }
                                                        
                            if(comp2_fight_cur == null) {
                                return;
                            }
                            
                            if(comp1_fight_cur == comp2_fight_cur) {
                                if(comp1_fight_cur.proceeds_winner == null) {
                                    return;
                                }
                                num_conf = num_conf + ( 1.0 / (height ** 3) );
                            }
                        });
                    });
                }
                if(num_conf < min_conflicts) {
                    min_conflicts = num_conf;
                    best_fights = fights;
                    best_third = third;
                }
                if(num_conf == 0.0) {
                    console.log('no conflicts');
                    break;
                }  
                attempts = attempts + 1;
            }
            third = best_third;
            this.fights = best_fights;
            window.APP.reset();
            if(animated == 3) {
                var top_fights = [];
                top_fights.push(this.fights[0].simple());

                if(third) {
                    top_fights.push(third.simple() );
                } 

                postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:top_fights} );
                this.shuffled = 1;
                this.shuffle_text = '';
                window.APP.reset();
            } else {
                var t = this;
                setTimeout(function(){
                        t.setupBrackets(animated + 1);
                    }, 1000);
            }
        } else {
            while(attempts < 1) { 
                attempts = attempts  +1;
                fights = [];   
                
                comps = this.standings.filter(function(e){ return e.checked_in;});
                divFightShuffle(comps, this);
                comps = comps.sort(function(a, b){             
                    return a.ordered - b.ordered;
                });
                
                top = new Fight(1,null, div.timer_first, div.number_rounds_first, div.break_timer_first);
                top.double_1 = true;
                if(this.ignore_final_fight == 1) {
                    top.shouldIgnore();
                }
                fights.push(top);
                
                //Setup the top bit                
                var winner_fights_last = [];
                var loser_fights_last = [];
                var added = null;
                var added2 = null;
                
                added = new Fight(2,null, div.timer_length,div.number_rounds, div.break_timer);
                top.comp1_fight = added;
                added.proceeds_winner = top;
                fights.push(added);
                winner_fights_last.push(added);

                added2 = new Fight(2,null, div.timer_length,div.number_rounds, div.break_timer);
                top.comp2_fight = added2;
                added2.proceeds_winner = top;
                fights.push(added2);
                
                added.proceeds_loser = added2;
                added2.comp1_fight = added;
                added2.comp1_loser = 1;
                
                loser_fights_last = [];
                
                added = new Fight(3,null, div.timer_length,div.number_rounds, div.break_timer);
                added2.comp2_fight = added;
                added.proceeds_winner = added2;
                fights.push(added);
                loser_fights_last.push(added);
                
                place = 4;
                
                while(winner_fights_last.length * 2 < comps.length) {
                    var current_winner_round = [];
                    var current_loser_round = [];
                    
                    winner_fights_last.forEach(element => {
                        added = new Fight(place * 2,null, div.timer_length,div.number_rounds, div.break_timer);
                        element.comp1_fight = added;
                        added.proceeds_winner = element;
                        fights.push(added);
                        current_winner_round.push(added);

                        added = new Fight(place * 2,null, div.timer_length,div.number_rounds, div.break_timer);
                        element.comp2_fight = added;
                        added.proceeds_winner = element;
                        fights.push(added);
                        current_winner_round.push(added);
                    });

                    winner_fights_last = current_winner_round;
                    
                    while(loser_fights_last.length < winner_fights_last.length ) {
                        var new_losers = [];

                        loser_fights_last.forEach(element => {
                            added = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer);
                            element.comp1_fight = added;
                            added.proceeds_winner = element;
                            fights.push(added);
                            new_losers.push(added);

                            added = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer);
                            element.comp2_fight = added;
                            added.proceeds_winner = element;
                            fights.push(added);
                            new_losers.push(added);
                        });
                        place = place * 2;
                        loser_fights_last = new_losers;
                    }

                    current_loser_round = [];
                    loser_fights_last.reverse();
                    loser_fights_last.forEach( (element, index) => {
                        var loser_from = winner_fights_last[index];
                        var spot = loser_fights_last[index];

                        loser_from.proceeds_loser = spot;
                        spot.comp1_fight = loser_from;
                        spot.comp1_loser = 1;

                        added = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer);
                        spot.comp2_fight = added;
                        added.proceeds_winner = spot;
                        fights.push(added);
                        current_loser_round.push(added);
                    });
                    loser_fights_last = current_loser_round;
                    
                    
                    
                }
                
                const total_byes = (winner_fights_last.length * 2) - comps.length;
                var byes_left = total_byes;
                const distance = winner_fights_last.length;
                
                winner_fights_last.forEach( function(element,i) {
                    //BYE
                    if(byes_left >= ( distance - i ) || 
                            (byes_left / total_byes) >= ( (distance - i ) / distance ) 
                        ) {

                        element.comp1 = comps.pop();
                        element.winner = 1;
                        element.is_bye = 1;
                        byes_left = byes_left - 1;

                        element.declareWinner(1);

                        //not a bye
                    } else {
                        var comp = new Fight(place, null,div.timer_length,div.number_rounds, div.break_timer);
                        comp.comp1 = comps.pop();
                        comp.winner = 1;

                        element.comp1_fight = comp;
                        element.comp1 = comp.comp1;
                        comp.proceeds_winner = element;
                        fights.push(comp);

                        comp = new Fight(place,null, div.timer_length,div.number_rounds, div.break_timer);
                        comp.comp1 = comps.pop();
                        comp.winner = 1;

                        element.comp2_fight = comp;
                        element.comp2 = comp.comp1;
                        comp.proceeds_winner = element;
                        fights.push(comp);
                    }


                });
                
                var remove = [];
                //check for 'fights' that have turned into byes
                
                
                fights = fights.filter(function(e){ return !remove.includes(e.id); } );
                
                remove = [];                
                fights.forEach(e => {
                    if(e.comp1_fight != null 
                            && e.comp1_loser == 1 
                            && e.comp2_fight != null 
                            && e.comp2_fight.comp2_fight == null 
                            && e.comp2_fight.comp1_fight == null 
                            && e.comp2_fight.proceeds_winner != null) {
                        
                        if(e.id == e.proceeds_winner.comp1_fight.id) {
                            e.proceeds_winner.comp1_fight = e.comp1_fight;
                            e.proceeds_winner.comp1_loser = e.comp1_loser;
                        }
                        
                        if(e.id == e.proceeds_winner.comp2_fight.id) {
                            e.proceeds_winner.comp2_fight = e.comp1_fight;
                            e.proceeds_winner.comp2_loser = e.comp1_loser;
                        }
                        
                        remove.push(e.comp2_fight.id);
                        remove.push(e.id);
                    }
                });
                
                fights = fights.filter(function(e){ return !remove.includes(e.id); } );
                
                
                remove = [];                
                fights.forEach(e => {
                    if(e.comp1_fight != null 
                            && e.comp1_loser == 1 
                            && e.comp2_fight != null 
                    
                            //is an entry / bye
                            && e.comp1_fight.comp2_fight == null 
                            && e.comp1_fight.comp1_fight == null 
                            ) {
                        
                        if(e.id == e.proceeds_winner.comp1_fight.id) {
                            e.proceeds_winner.comp1_fight = e.comp2_fight;
                            e.proceeds_winner.comp1_loser = e.comp2_loser;
                        }
                        
                        if(e.id == e.proceeds_winner.comp2_fight.id) {
                            e.proceeds_winner.comp2_fight = e.comp2_fight;
                            e.proceeds_winner.comp2_loser = e.comp2_loser;
                        }
                        
                        remove.push(e.id);
                    }
                });                
                fights = fights.filter(function(e){ return !remove.includes(e.id); } );
                
                remove = [];                
                fights.forEach(e => {
                    if(e.comp1_fight != null 
                            && e.comp1_loser == 1 
                            && e.comp2_fight != null 
                    
                            //is an entry / bye
                            && e.comp2_fight.comp2_fight == null 
                            && e.comp2_fight.comp1_fight == null 
                            ) {
                        
                        if(e.id == e.proceeds_winner.comp1_fight.id) {
                            e.proceeds_winner.comp1_fight = e.comp1_fight;
                            e.proceeds_winner.comp1_loser = e.comp1_loser;
                        }
                        
                        if(e.id == e.proceeds_winner.comp2_fight.id) {
                            e.proceeds_winner.comp2_fight = e.comp1_fight;
                            e.proceeds_winner.comp2_loser = e.comp1_loser;
                        }
                        
                        remove.push(e.id);
                    }
                });                
                fights = fights.filter(function(e){ return !remove.includes(e.id); } );
                
                best_fights = fights;
            }  
            this.fights = best_fights;
            window.APP.reset();
            if(animated == 3) {
                var top_fights = [];
                top_fights.push(this.fights[0].simple());
                postMaster.toSendData('/tournament-division/' + this.id +'/new-brackets', {f:top_fights} );
                this.shuffled = 1;
                this.shuffle_text = '';
                window.APP.reset();
            } else {
                var t = this;
                setTimeout(function(){
                        t.setupBrackets(animated + 1);
                    }, 1000);
            }
        }
    }
    
    startScore() {
        this.status = 2;
        this.current_comp = null;
    }
    
    cancel() {
        this.status = 0;
    }
    
    getComp(id) {
      return  this.standings.find(element => element.id == id || element.members.find(m => m.id == id) != null );
    }
    
    getFight(id) {
      return  this.fights.find(element => element.id == id);
    }
    
    getProtest(id) {
      return  this.protests.find(element => element.id == id);
    }
    
    updateProtest(protest) {
        var p = this.getProtest(protest.protest_id);
        if(!p) {
            p = {};
            p.id = protest.protest_id;   
            this.protests.push(p);
        }
        p.protestor_id = protest.protestor_id;
        p.protestee_id = protest.protestee_id;
        p.status = protest.status;
        p.reason = protest.reason;
        p.response = protest.response;
        p.refund = protest.refund;
        p.complete = protest.complete;
        p.user = protest.user;
    }
    
    activeProtests() {
        return this.protests.filter(function(e) { return !e.complete } );
    }
    
    load(division) {
        
        let n_div = this;
        
        if(division){
            if(division.child_divisions) {
                this.child_divisions = division.child_divisions;
            }
            
            this.name = division.name;
            this.code = division.code;
            this.config_type = division.config_type;
            this.min_judges = division.min_judges;
            this.session_start_time = division.start_time;    
            this.estimated_start = new Date(division.estimated_start);    
            this.estimated_stop = new Date(division.estimated_stop);    
            
            this.certificate_name = division.certificate_name;
            
            this.is_team = division.is_team;
            this.min_size = division.min_team_size;;
            this.max_size = division.max_team_size;;
            
            this.ordered = division.ordered;
            this.status = division.status;
            this.score_rules = division.score_rules;
            this.standings = [];
            this.shuffled = division.shuffled;
            
            this.winner_screen = division.winner_screen;
            
            this.scorekeeper_warning = division.scorekeeper_warning;
            
            this.sparring_rr_if_three = division.sparring_rr_if_three;
            this.sparring_bracket_type = division.sparring_bracket_type;
            
            this.tie_solver = division.tie_solver;
            this.deviation = division.deviation;
            this.drop_high = division.drop_high;
            this.drop_low = division.drop_low;
            
            this.break_ties_till = parseInt(division.tie_break_till);
            this.min_place = parseInt(division.min_place);
            
            this.call_pack = division.call_pack;
            
            this.timer_length = parseInt(division.timer);
            
            this.number_rounds =  parseInt(division.number_rounds);
            this.number_rounds_first =  parseInt(division.number_rounds_first);
            this.number_rounds_third =  parseInt(division.number_rounds_third);
            this.number_rounds_quarter =  parseInt(division.number_rounds_quarter);

            this.break_timer = parseInt(division.break_timer);
            this.break_timer_first = parseInt(division.break_timer_first);
            this.break_timer_third =  parseInt(division.break_timer_third);
            this.break_timer_quarter = parseInt(division.break_timer_quarter);

            this.max_fight_spread = parseInt(division.max_fight_spread);
            this.max_fight_spread_third = parseInt(division.max_fight_spread_third);
            this.max_fight_spread_first = parseInt(division.max_fight_spread_first);
            this.max_fight_spread_quarter = parseInt(division.max_fight_spread_quarter);

            this.min_fight_spread = parseInt(division.min_fight_spread);
            this.min_fight_spread_first = parseInt(division.min_fight_spread_first);
            this.min_fight_spread_third = parseInt(division.min_fight_spread_third);
            this.min_fight_spread_quarter = parseInt(division.min_fight_spread_quarter);

            this.timer_first = parseInt(division.timer_first);
            this.timer_third =  parseInt(division.timer_third);
            this.timer_quarter =  parseInt(division.timer_quarter);
            
            this.duration_estimate = parseInt(division.duration_estimate);
            
            this.schedule_id = division.schedule_id;
            
            this.score_prefill = division.score_prefill;
            this.max_score = parseFloat(division.max_score);
            this.min_score = parseFloat(division.min_score);
            
            this.quick_max_score = parseFloat(division.quick_max_score);
            this.quick_min_score = parseFloat(division.quick_min_score);
            
            this.score_dq = parseFloat(division.score_dq);
            
            this.before_announcer_notes = division.before_announcer_notes;
            this.before_comm_notes = division.before_comm_notes;
            this.during_announcer_notes = division.during_announcer_notes;
            this.during_comm_notes = division.during_comm_notes;
            this.after_announcer_notes = division.after_announcer_notes;
            this.after_comm_notes = division.after_comm_notes;
            
            if(division.split_countries == 1) {
                this.split_countries = true;
            } else {
                this.split_countries = false;
            }
            
            if(division.deviation_below_drop_above) {
                this.deviation_below_drop_above = division.deviation_below_drop_above;
            }
            
            this.age_begin = division.age_begin;        
            this.age_end = division.age_end;

            this.level_begin = division.level_begin;
            this.level_end = division.level_end;

            this.is_final = division.is_final;

            this.gender = division.gender;
            this.is_grand = division.is_grand;
            
            division.standings.forEach(element => { 
                let standing = new Standing(element.id);
                standing.load(element, n_div, this.is_final != 1 );
                this.standings.push(standing);
                
                if(division.current_comp_id == standing.id) {
                    n_div.current_comp = standing;                    
                } 
                
            });
            
            this.ignore_final_fight = parseInt(division.ignore_final_fight);
            if(division.top_fights) {
                division.top_fights.forEach((t)=>{ 
                    
                    this.addFight(t);
                });
            }
            
            
            this.fights.forEach(fight => {
                fight.is_editable = true;
                fight.is_viewable = true;
            });
            
            this.fights.forEach(fight => {
                fight.check();
            });
            
            this.checkTeams();
            
            if(division.protests) {
                division.protests.forEach(element => { 
                    this.updateProtest(element);
                });
            }
            
            this.ring_imported = division.ring_imported;
            this.ring_import_requests = division.ring_import_requests;
        }
    }
    
    simpleOrderAll() {
        const compSort = function(a, b){             
                return a.ordered - b.ordered;
        }
        
        var arr = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true;
                }
        ).sort(compSort);

        return arr;
    }
    
    simpleOrder() {
        const compSort = function(a, b){             
                return a.ordered - b.ordered;
        }
        
        var arr = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true && stand.ordered != 0;
                }
        ).sort(compSort);

        return arr;
    }
    
    getCompOrder() {
        
        const compSort = function(a, b){             
                return a.ordered - b.ordered;
        }
        
        var arr = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true && stand.ordered != 0;
                }
        ).sort(compSort);

        var preceding = null;
        
        for (let step = 0; step < arr.length; step++) {
            arr[step].preceding = null;
            
            arr[step].after = null;
        }
        
        for (let step = 0; step < arr.length; step++) {
            arr[step].preceding = preceding;
            if(preceding) {
                preceding.after =arr[step];
            } 
            preceding = arr[step];
        }
        
        
        return arr;
    }
    
    setTempPlaces() {
        const compSort = function(a, b){             
                return b.temp_score - a.temp_score;
        }
        
        this.standings.forEach( (value, index, self2) => {
            value.temp_place = 0;
        })
        
        var arr = this.standings.filter(
                function (stand) {
                    return stand.checked_in == true && stand.temp_score > 0;
                }
        ).sort(compSort);

        let places = [];
        let place = [];
        let score = -1;

        
        for (let step = 0; step < arr.length; step++) {
            if (score !== arr[step].temp_score) {
                places.push(place);
                place = [];
            } 
            score = arr[step].temp_score;
            place.push(arr[step]);
        }
        places.push(place);
        
        let rank = 1;
        this.temp_ties_at = [];
        for (let step = 0; step < places.length; step++) {
            places[step].forEach(comp => {
                comp.temp_place = rank;
            });
            
            if (places[step].length > 1 && rank <= this.break_ties_till) {
                this.temp_ties_at.push(rank);
            }
            
            rank = rank + places[step].length;
            
            if(rank > this.min_place) {
                rank = this.min_place;
            }
        }
    }
    
    getWinner() {
        let comp =  this.getPlaceOrder();
        
        if(comp.length == 0) {
            return null;
        }
        
        let compW =comp[0];
        
        if(comp.length > 1 ) { 
            if(compW.place == comp[1].place ) { 
                compW = null;
            }
        }
        return compW;
    }
    
    setPlaces() { 
        var places = null;
        if(this.config_type == 'scores') {
            this.fixAllScores();

            const compSort = function(a, b){             
                    return b.getScoreTotal() - a.getScoreTotal();
            }

            this.standings.forEach( (value, index, self2) => {
                value.place = 0;
            })

            var arr = this.standings.filter(
                    function (stand) {
                        return stand.checked_in == true && stand.getScoreTotal() > 0;
                    }
            ).sort(compSort);

            let places = [];
            let place = [];
            let score = -1;


            for (let step = 0; step < arr.length; step++) {
                if (score !== arr[step].getScoreTotal()) {
                    places.push(place)
                    place = []  
                } 
                score = arr[step].getScoreTotal();
                place.push(arr[step]);
            }
            places.push(place)

            let rank = 1;

            for (let step = 0; step < places.length; step++) {
                places[step].forEach(comp => {
                    comp.place = rank;
                });

                rank = rank + places[step].length;

                if(rank > this.min_place) {
                    rank = this.min_place;
                }
            }


            if(this.tie_solver != '') {
                var numj = window.APP.ring.getNumJudges()
                var ties = this.getTies();

                ties.forEach( (tie) => {            
                  if(this.tie_solver == 'Majority') {
                        if(tie.length == 2) {
                            var c1 = tie[0];
                            var c2 = tie[1];

                            var c1n = 0;
                            var c2n = 0;
                            for (let step = 0; step < numj; step++) {
                                if( Math.abs(parseFloat(c1.getScore(step).score) - parseFloat(c2.getScore(step).score ) ) < 0.005 ) { 
                                    continue;
                                }                            
                                if(parseFloat(c1.getScore(step).score) > parseFloat(c2.getScore(step).score ) ) {
                                    c1n = c1n + 1;
                                }else if(parseFloat(c2.getScore(step).score) > parseFloat(c1.getScore(step).score) ) {
                                    c2n = c2n + 1;
                                } 
                            }

                            if(c1n > c2n && c1n > numj / 2) {
                                this.standings.forEach(r => {
                                    if(r.place==c1.place && c1.id != r.id) {
                                        r.place = c1.place + 1
                                    }
                                }); 
                            }

                            if(c2n > c1n && c2n > numj / 2) {
                                this.standings.forEach(r => {
                                    if(r.place==c2.place && c2.id != r.id) {
                                        r.place = c2.place + 1
                                    }
                                }); 
                            }
                        }
                    }    
                    
                    if(this.tie_solver == 'High-Low') {
                       if(tie.length == 2) { 
                            var c1 = tie[0];
                            var c2 = tie[1];

                            var c1n = 0;
                            var c2n = 0;
                            for (let step = 0; step < numj; step++) {
                                if( Math.abs(parseFloat(c1.getScore(step).og_score) - parseFloat(c2.getScore(step).og_score ) ) < 0.005 ) { 
                                    continue;
                                }                            
                                if(parseFloat(c1.getScore(step).og_score) > parseFloat(c2.getScore(step).og_score ) ) {
                                    c1n = c1n + 1;
                                }else if(parseFloat(c2.getScore(step).og_score) > parseFloat(c1.getScore(step).og_score) ) {
                                    c2n = c2n + 1;
                                }                                 
                            }

                            if(c1n > c2n && c1n > numj / 2) {
                                this.standings.forEach(r => {
                                    if(r.place==c1.place && c1.id != r.id) {
                                        r.place = c1.place + 1
                                    }
                                }); 
                            }

                            if(c2n > c1n && c2n > numj / 2) {
                                this.standings.forEach(r => {
                                    if(r.place==c2.place && c2.id != r.id) {
                                        r.place = c2.place + 1
                                    }
                                }); 
                            }
                        }
                    }
                    
                    /*
                    if(this.tie_solver == 'High-Low') {
                        const compSort = function(a, b){             
                                return b.getAllScoreTotal() - a.getAllScoreTotal();
                        }

                        var tieComps = tie.sort(compSort);

                        let tieplaces = [];
                        let tieplace = [];
                        let tiescore = -1;


                        for (let step = 0; step < tieComps.length; step++) {
                            if (tiescore !== arr[step].getAllScoreTotal()) {
                                tieplaces.push(tieplace)
                                tieplace = []  
                            } 
                            tiescore = tieComps[step].getAllScoreTotal();
                            tieplace.push(tieComps[step]);
                        }
                        tieplaces.push(tieplace)

                        let tierank = tie[0].place;

                        for (let step = 0; step < tieplaces.length; step++) {
                            tieplaces[step].forEach(comp => {
                                comp.place = tierank;
                            });

                            tierank = tierank + tieplaces[step].length;
                        }
                    } */
                })            
            } 
        }
        
        let max = this.standings.filter(
                    function (stand) {
                        return stand.checked_in == true;
                    }
            ).length;
    
        
    
        this.standings.forEach( (value, index, self2) => {
            if(value.place > max) {
                value.place = max;
            }
        });
        
        let text = '?';
        this.standings.forEach( (value, index, self2) => {
            if(value.members.length == 0) {
                text = text + value.id + '=' + value.place + '&';
            } else {
                value.getCheckedIn().forEach( (value2, index, self2) => {
                    text = text + value2.id + '=' + value.place + '&';
                 }) 
            }
            
         }) 
        
        postMaster.toSend( '/tournament-division/'+this.id+'/places' + text );        
                
        return places;
    }
    
    getTies() {
        let ties = [];
        for (let step = 1; step <= this.break_ties_till; step++) {
            if(this.ignore_final_fight == 1 ) {
                if(step <= 2) continue;
            }            
            let p = this.standings.filter( function(value) { return value.place == step }  )
            if (p.length > 1) {ties.push(p); }
        }
        return ties;
    }
    
    getPlaceOrder() {
        
        const compSort = function(a, b){             
                return a.place - b.place;
        }
        
        return this.standings.filter(
                function (stand) {
                    return stand.place > 0 && stand.checked_in == true;
                }
        ).sort(compSort);
    }
    
    getCheckedIn() {
        
        const compSort = function(a, b){ 
            if (a.checked_in == true && b.checked_in == false) {
                return 1;
            }
             if (a.checked_in == true && b.checked_in == true) {
                if(a.seeding.localeCompare(b.seeding) == 0) {
                    return a.name.localeCompare(b.name);
                }
                return -1 * a.seeding.localeCompare(b.seeding);
            }
             if (a.checked_in == false && b.checked_in == false) {
                return a.name.localeCompare(b.name);
            }
             if (a.checked_in == false && b.checked_in == true) {
                return -1;
            }
        }
        
        return this.standings.filter(
                function (stand) {
                    return stand.checked_in == true;
                }
        ).sort(compSort);
    }
    
    checkTeams() {
        if(this.is_team != 1) {
            return;
        }
        var d = this;
        this.standings.forEach(standing => {
            var ms = standing.getCheckedIn();
            
            if(ms.length >= d.min_size && ms.length <= d.max_size ) {
                standing.checked_in = true;
            } else {
                standing.checked_in = false;
            }
            
        });
    }
    
    getNotCheckedIn() {
        
        const compSort = function(a, b){ 
            if (a.checked_in == true && b.checked_in == false) {
                return 1;
            }
             if (a.checked_in == true && b.checked_in == true) {
                if(a.seeding.localeCompare(b.seeding) == 0) {
                    return a.name.localeCompare(b.name);
                }
                return -1 * a.seeding.localeCompare(b.seeding);
            }
             if (a.checked_in == false && b.checked_in == false) {
                return a.name.localeCompare(b.name);
            }
             if (a.checked_in == false && b.checked_in == true) {
                return -1;
            }
        }
        
        return this.standings.filter(
                function (stand) {
                    return stand.checked_in == false;
                }
        ).sort(compSort);
    }
    
    getCheckinMainOrder() {
        const compSort = function(a, b){ 
            if (a.checked_in == true && b.checked_in == false) {
                return 1;
            }
             if (a.checked_in == true && b.checked_in == true) {
                if(a.seeding.localeCompare(b.seeding) == 0) {
                    return a.name.localeCompare(b.name);
                }
                return -1 * a.seeding.localeCompare(b.seeding);
            }
             if (a.checked_in == false && b.checked_in == false) {
                return a.name.localeCompare(b.name);
            }
             if (a.checked_in == false && b.checked_in == true) {
                return -1;
            }
        }
        return this.standings.sort(compSort);
    }
    
    getCheckinRowOrder(orderby) {
        
        
        if(orderby == 'seed') {
            return this.standings.sort(function(a, b){ 
                return -1 * a.seeding.localeCompare(b.seeding);
            });
        }
        
        
        
        if(orderby == 'checkin') {
            return this.standings.sort(function(a, b){ 
                if (a.checked_in == true && b.checked_in == false) {
                    return 1;
                }
                 if (a.checked_in == true && b.checked_in == true) {
                    if(a.seeding.localeCompare(b.seeding) == 0) {
                        return a.name.localeCompare(b.name);
                    }
                    return -1 * a.seeding.localeCompare(b.seeding);
                }
                 if (a.checked_in == false && b.checked_in == false) {
                    return a.name.localeCompare(b.name);
                }
                 if (a.checked_in == false && b.checked_in == true) {
                    return -1;
                }
            });
        }
        
        
        return this.standings.sort(function(a, b){ 
                return a.name.localeCompare(b.name);
            });
    }
    
    setCheckNumbers() {
        if(this.shuffled == 1 && this.config_type == 'scores') {
            this.standings.forEach( (x) => {
                x.check_num = x.ordered;
            });
            return;
        } 
    
        const compSort = function(a, b){ 
                if(a.seeding.localeCompare(b.seeding) == 0) {
                    return a.name.localeCompare(b.name);
                }
                return -1 * a.seeding.localeCompare(b.seeding);            
        }
        
        var standings = this.standings.sort(compSort);
        var num = 1;
        standings.forEach( (x) => {
           x.check_num = num;
           num = num + 1;
        });
    }
    
    getUncheckedDisplay(styling) { 
        const adj = styling.adj;
        
        if(styling.display_type != 'green') { 
        
        const compList = this.getNotCheckedIn().map((comp ) => 
        <Col xs="1" s="2" md="3" xlg="4">
        <Card style={{ padding:((5 * adj) + 'px'), marginBottom:((15 * adj) + 'px')  }}  bg='dark'  border={comp.checked_in ? 'success':'danger'}
                    
                        text= 'light'>
                        <Container>
                        <Row>
                        <Col xs={3}>
                        {comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width: ((40 * adj) + 'px') }} src={comp.photo} /> }
                        </Col>

                        <Col xs={7}>
                        {comp.check_num}. {comp.name} {!comp.reg_check && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not Checked In</Tooltip> }><i  style={{ marginLeft:'10px' }} class={'text-danger fas fa-vote-yea'} ></i></OverlayTrigger>}
                        </Col>
                        {seeds &&
                        <Col xs={1}>
                        {comp.seeding}
                        </Col>}
                        </Row>
                        </Container>                    
                    
        </Card>
                </Col>
          );
  
  const compListIn = this.getCheckedIn().map((comp ) => 
        <Col xs="12" sm="6" md="4" xl="3">
        <Card style={{ padding:((5 * adj) + 'px'), marginBottom:((15 * adj) + 'px')  }}  bg='dark'  border={comp.checked_in ? 'success':'danger'}
                    
                        text= 'light'>
                        <Container>
                        <Row>
                        <Col xs={3}>
                        {comp.photo != ''  && !styling.hide_thumbnails &&  <img style={{ width: ((40 * adj) + 'px')}} src={comp.photo} /> }
                        </Col>

                        <Col xs={7}>
                        {comp.check_num}. {comp.name}
                        </Col>
                        {seeds &&
                        <Col xs={1}>
                        {comp.seeding}
                        </Col>}
                        </Row>
                        </Container>                    
                    
        </Card>
                </Col>
          );
  
         return <Container style={{ width:'100%', maxWidth:'100%' }}>
             { compList.length > 0 && <div>
         <Row style={{ textAlign:'center' }}>
                
                <div style={{ color:"white", textTransform : "uppercase", width : '100%',  paddingTop: '1%',textAlign:'center', fontSize :'125%'  }}>
                
                    <h2 style={{ fontSize:"90%"}}>Left to Check-In</h2>
                
                </div>
                </Row>
         
            
            <Row style={{ textAlign:'center', fontSize:'110%' }}>
               {compList}
            </Row></div>
            }
            { compListIn.length > 0 && <div>
            <Row style={{ textAlign:'center' }}>
        
                
                <div style={{ color:"white", textTransform : "uppercase", width : '100%',  paddingTop: '1%',textAlign:'center', fontSize :'125%'  }}>
                
                    <h2 style={{ fontSize:"90%"}}>Checked-In</h2>
                
                </div>
                </Row>
         
            
            <Row style={{ textAlign:'center', fontSize:'110%' }}>
               {compListIn}
               </Row></div>
            }                                            
            </Container>;
        } else {
            return <div style={{ position: 'absolute',top: '50%',left: '50%', width:'100%' }}  className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>
                <div style={{ transform: 'translate(-50%, -50%)' }} >
                    <div style={{ width:'100%', paddingLeft:'10%', paddingRight:'10%' }} >
                <Card bg="dark" style={{ color:"white" }}>
                    <Card.Header style={{ textAlign:'center',fontSize: '200%' }}>{this.name}</Card.Header>
                <Card.Body style={{ textAlign:'center',padding:'0px' }} >
                  Checking Competitors In Now
                  </Card.Body></Card>
                  </div>
                  </div>
            </div>
        }
    }
    
    getCompOrderDisplay(judges_number2) { 
        const t = this;
        const judges_number = judges_number2;
        
        return function(styling) {
            const adj = styling.adj;

            
            if(styling.display_type != 'green') { 
                const compList = t.getCompOrder().map((comp ) => {
                    if(judges_number != comp.getNumberScores() ) {
                        comp.resetScores(judges_number)
                    }
                    const scoresList = comp.getScores()
                    .map((score) => 
                        <td style={{ fontSize:((150 * adj) + '%') }}>
                            { (score.score > 0 && parseFloat(score.og_score) != parseFloat(this.score_prefill) ) ? <i class="fas fa-check" style={{ color:'white' }}></i> : <i style={{ color:'white' }} class="far fa-clock"></i> }
                        </td>  

                        );
                   return <tr>
                  <td style={{ fontSize:((125 * adj) + '%') }}>{comp.ordered}</td>

                  <td style={{ fontSize:((125 * adj) + '%'), width:((50 * adj) + 'px') }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width: ((40 * adj) + 'px')}} src={comp.photo} /> }</td>
                      <td style={{ fontSize:((125 * adj) + '%'), width:((50 * adj) + 'px'), verticalAlign:'middle' }}>
                       {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
                       src={comp.country}   ></img> }
                  </td>
                                                                                <td style={{ fontSize:((125 * adj) + '%'), textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td>{t.status != 2 && seeds &&  <td style={{ fontSize:((125 * adj) + '%') }}>{comp.seeding}</td>}{scoresList}
                                                        </tr>;
                                                        }
                );
             return <Container style={{ width:'100%', maxWidth:'100%' }} className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>            
                <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {t.config_type == 'scores' && 
                                        <Table variant="dark">
                                               {compList}                                                                                    
                                    </Table>}

                        {t.config_type == 'sparring' &&                         
                        <BracketChart tv={1} div={this} screen={styling.screen} > </BracketChart>}

                        </Card.Body>
                  </Card>

                </Container>
            } else {
                const compList = t.getCompOrder().map((comp ) => {
                    if(judges_number != comp.getNumberScores() ) {
                        comp.resetScores(judges_number)
                    }
                    const scoresList = comp.getScores()
                    .map((score) => 
                        <td style={{ fontSize:((220 * adj) + '%') }}>
                            { (score.score > 0) ? <i class="fas fa-check" style={{ color:'white' }}></i> : <i style={{ color:'white' }} class="far fa-clock"></i> }
                        </td>  

                        );
                   return <tr>
                  <td style={{ fontSize:((220 * adj) + '%') }}>{comp.ordered}</td>

                  {!styling.hide_thumbnails && <td style={{ fontSize:((220 * adj) + '%'), width:((80 * adj) + 'px') }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ height:'1.2em'}} src={comp.photo} /> }</td>}
                      {!styling.hide_flags && <td style={{ fontSize:((220 * adj) + '%'), width:((80 * adj) + 'px'), verticalAlign:'middle' }}>
                       {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1.2em' }} 
                       src={comp.country}   ></img> }
                  </td>}
                                                                                <td style={{ fontSize:((220 * adj) + '%'), textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>{t.status != 2 && seeds &&  <td style={{ fontSize:((220 * adj) + '%') }}>{comp.seeding}</td>}{scoresList}
                                                        </tr>;
                                                        }
                );
        
                return <div style={{ position: 'absolute',top: '50%',left: '50%', width:'100%' }}  className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>
                <div style={{ transform: 'translate(-50%, -50%)' }} >
                    <div style={{ width:'100%', paddingLeft:'10%', paddingRight:'10%' }} >           
                <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      ><Card.Header style={{ textAlign:'center',fontSize: '200%' }}>{this.name}</Card.Header>
                        <Card.Body >
                        {t.config_type == 'scores' && 
                                        <Table variant="dark">
                                               {compList}                                                                                    
                                    </Table>}

                        {t.config_type == 'sparring' &&                         
                        <BracketChart tv={1} div={this} screen={styling.screen} > </BracketChart>}

                        </Card.Body>
                  </Card>
                    </div>
                  </div>
            </div>
            }
        }.bind(this);
    }
    
    winTie(comp) {
        this.standings.forEach(r => {
                    if(r.place==comp.place && comp.id != r.id) {
                        r.place = comp.place + 1
                    }
                });
               
        let text = '?';
        this.standings.forEach( (value, index, self2) => {
            
            text = text + value.id + '=' + value.place + '&';
         }) 
        
        postMaster.toSend( '/tournament-division/'+this.id+'/places' + text );        
           
               
        window.APP.forceUpdate();
    }
    
    getCompPlacesDisplay(styling) { 
        const adj = styling.adj;
        if(this.getTies().length > 0 && !( styling.force_winner_screen || styling.force_places_screen ) ) {
            const tiesList = this.getTies()
                .map((tie) => {
                    const ties = tie.map( (comp) => {
                        
                        const upScoresList = comp.getUpScores()
                                .map((score) => 
                                    <td style={{ fontSize:((125 * adj) + '%'), 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }} >
                                    {this.niceFormatScore(parseFloat(score.score) )} 
                                            
                                    { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-up text-success"></i>                                    
                                    }
                                    
                                    { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-down text-danger"></i>                                    
                                    }
                                    </td>  
                                 );
                    
                        const scoresList = comp.getScores()
                                .map((score, index) => 
                                    <td style={{ fontSize:((125 * adj) + '%'), 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none','borderLeftStyle' : ( ( upScoresList.length > 0 && index == 0 )?'solid':'none') }} >
                                    {this.niceFormatScore(parseFloat(score.score) )} 
                                            
                                    { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-up text-success"></i>                                    
                                    }
                                    
                                    { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-down text-danger"></i>                                    
                                    }
                                    </td>  
                                 );
                         
                         
                         
                    if (this.config_type == 'scores') {                                                                                            
                    return <tr style={{ fontSize:'150%' }}><td style={{ fontSize:((125 * adj) + '%') }}>{comp.place}</td><td style={{ fontSize:((125 * adj) + '%') }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width: ((40 * adj) + 'px')}} src={comp.photo} /> }</td><td style={{ fontSize:((125 * adj) + '%'),  width:((50 * adj) + 'px'), verticalAlign:'middle' }} >
            {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
            src={comp.country}   ></img> }
       </td><td style={{ borderRightStyle:'solid',fontSize:((125 * adj) + '%'), textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>{upScoresList}{scoresList}<td style={{ borderLeftStyle:'solid',fontSize:((125 * adj) + '%') }}>{comp.getScoreTotal() }</td></tr>; }
       else {
           return <tr><td style={{ fontSize:((125 * adj) + '%') }}>{comp.place}</td><td style={{ fontSize:((125 * adj) + '%') }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width:((40 * adj) + 'px')}} src={comp.photo} /> }</td><td style={{ fontSize:((125 * adj) + '%'), width:((50 * adj) + 'px'), verticalAlign:'middle' }} >
                {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
                src={comp.country}   ></img> }
           </td><td style={{ fontSize:((125 * adj) + '%'), textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td></tr>;
       }
                     } )

                    return <Table variant="dark">
            {ties}
                  </Table> ;

                     } );


            return <Container className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>
            {this.activeProtests().length > 0 && <Alert variant="danger"  className='text-center' style={{margin:'10px'}}>
                        <Alert.Heading>Not Final</Alert.Heading>
                        There is a protest on this division and these are not the final results.
                </Alert>}
            <Row style={{ textAlign:'center' }}>
             <div style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'150%'  }}>

                        <h1 style={{ fontSize:"120%"}}>TIES</h1>

                    </div>
            </Row>
            <Row style={{ textAlign:'center' }}>
        {tiesList}
        </Row>
        </Container>;
        }
        
        if(styling.force_winner_screen && this.getTies().length > 0 ) {
            return <Container></Container>;
        }
        
        let compW = this.getPlaceOrder()[0];
        
        if(this.getPlaceOrder().length > 1 ) { 
            if(compW.place == this.getPlaceOrder()[1].place ) { 
                compW = null;
            }
        }
        
        if(! styling.force_places_screen   && this.getTies().length == 0 && this.activeProtests().length == 0 && ( this.winner_display || (styling.force_winner_screen) || (this.winner_screen > 0 && compW != null && compW.winner_screen_forced && this.break_ties_till > 0) ) ) {
            
            
            if(!compW) {
                return <Container></Container>;
            } else {
            
                if(styling.display_type != 'green') { 
                    return <div className={compW.amimateWinnerIn} >
                        <Container style={{ width:'100%', maxWidth:'100%' }}>
                        <Row style={{ textAlign:'center' }}>
                         <div style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'150%'  }}>

                                    <h1 style={{ fontSize:"120%"}}>WINNER</h1>

                                </div>
                        </Row>

                        <Row style={{ textAlign:'center' }}>
                            <div className={compW.amimateWinner} style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'200%'  }}>

                                <h1   style={{ fontSize:"120%"}}>{compW.name}</h1>
                                    <div style={{ textAlign:'center' }}>
                                        {compW.photoL != '' &&  <img style={{ width: '250px'}} src={compW.photoL} /> }
                                    </div>

                                    {compW.country != '' && !styling.hide_flags && <div style={{ textAlign:'center', marginTop:'20px' }}><img style={{ 'width':'10%' }} 
                                            src={compW.country}   ></img></div> }
                            </div>
                        </Row>


                        </Container></div>;
                } else {

                return <div className={(styling.not_body == 1)? 'animated slideOutDown' : 'animated slideInUp' } style={{ width:'100%',position:'absolute',bottom:'0' }} >
                <Card style={{ marginTop:'3%',marginLeft:'auto',marginRight:'auto',width:'50%', padding:((5 * adj) + 'px'), marginBottom:((15 * adj) + 'px')  }}  bg='dark'>
                    <Card.Header style={{ textAlign:'center',fontSize: '200%', color:'white' }}>{this.name}</Card.Header>
                   <Card.Body>
                <Container style={{ width:'100%', maxWidth:'100%' }}>
                    <Row>
                    <Col xs={2} style={{ display:'flex',alignItems:'center', justifyContent: 'space-around',flexDirection: 'column' }}>
                            <img style={{ 'width':'50%', marginLeft:'auto',marginRight:'auto' }} 
                                src={AppDomain + "/app/white_M_icon.png"}   ></img>
                            
                            {compW.country != '' && !styling.hide_flags && <div style={{ textAlign:'center'}}><img style={{ 'width':'75%' }} 
                                            src={compW.country}   ></img></div> }
                                            
                                             
                            
                    </Col>
                    <Col xs={8}>
                    <Container>
                        <Row style={{ textAlign:'center' }}>
                         <div style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'150%'  }}>

                                    <h1 style={{ fontSize:"120%"}}>WINNER</h1>

                                </div>
                        </Row>

                        <Row style={{ textAlign:'center' }}>
                            <div className={compW.amimateWinner} style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'200%'  }}>
                                <h1   style={{ fontSize:"120%"}}>{compW.name}</h1>
                                    
                            </div>
                        </Row>
                    </Container>
                    </Col>
                    <Col xs={2} style={{ display:'flex',alignItems:'center' }}>
                        {compW.photoL != '' &&  <img style={{ width: '100%', maxHeight:'100%'}} src={compW.photoL} /> }
                        
                    </Col>
                </Row>
                </Container></Card.Body>
                </Card></div>;
                }
            }
        } else {
                
                                                
            if(styling.display_type != 'green') { 
                const compList = this.getPlaceOrder().map((comp ) => {
                    const upScoresList = comp.getUpScores()
                    .map((score) => 
                        <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }} >
                            {this.niceFormatScore(parseFloat(score.score) )}

                            { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-up text-success"></i>

                            }
                            { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-down text-danger"></i>

                            }
                            </td>  
                            );

                    const scoresList = comp.getScores()
                    .map((score, index) => 
                        <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none','borderLeftStyle' : ( ( upScoresList.length > 0 && index == 0 )?'solid':'none' ) }} >
                            {this.niceFormatScore(parseFloat(score.score) )}

                            { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-up text-success"></i>

                            }
                            { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-down text-danger"></i>

                            }
                            </td>  
                            );
                                   if(this.config_type == 'scores') {
                                   return <tr  style={{ fontSize:'150%' }} class='trmine'>
                                   <td>{comp.place}</td><td style={{ width:'50px' }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td><td style={{ width:'50px', verticalAlign:'middle' }} >
                                   {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
                                   src={comp.country}   ></img> }
                              </td><td style={{ textAlign:'left', borderRightStyle:'solid' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>{upScoresList}{scoresList}<td style={{ borderLeftStyle:'solid' }}>{this.niceFormatScore(parseFloat(comp.getScoreTotal()) )}</td>
                                                                               </tr>;
                                                                           } else {
                                        return <tr  style={{ fontSize:'150%' }}>
                                   <td>{comp.place}</td><td style={{ width:'50px' }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td><td style={{ width:'50px', verticalAlign:'middle' }} >
                                   {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
                                   src={comp.country}   ></img> }
                              </td><td style={{ textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>
                                                                               </tr>;                                        
                                                    }
                });
             return <Container style={{ width:'100%', maxWidth:'100%' }} className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>
                {this.activeProtests().length > 0 && <Alert variant="danger" className='text-center'>
                        <Alert.Heading>Not Final</Alert.Heading>
                        There is a protest on this division and these are not the final results.                          
                </Alert>}
                <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                                    <Table variant="dark">
                                               {compList}                                                                                    
                                    </Table>
                        </Card.Body>
                  </Card>
                </Container>;
            } else { 
                const compList = this.getPlaceOrder().map((comp ) => {
                    const upScoresList = comp.getUpScores()
                    .map((score) => 
                        <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }} >
                            {this.niceFormatScore(parseFloat(score.score) )}

                            { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-up text-success"></i>

                            }
                            { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-down text-danger"></i>

                            }
                            </td>  
                            );
                    const scoresList = comp.getScores()
                    .map((score, index) => 
                        <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none','borderLeftStyle' : ( ( upScoresList.length > 0 && index == 0 )?'solid':'none') }} >
                            {this.niceFormatScore(parseFloat(score.score) )}

                            { this.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-up text-success"></i>

                            }
                            { this.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && this.niceFormatScore(parseFloat(score.score) ) != this.niceFormatScore(parseFloat(score.og_score) ) &&

                            <i class="fas fa-long-arrow-alt-down text-danger"></i>

                            }
                            </td>  
                            );
                                   if(this.config_type == 'scores') {
                                   return <tr  style={{ fontSize:'200%' }}>
                                   <td>{comp.place}</td>{!styling.hide_thumbnails && <td style={{ width:'50px' }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ 'height':'1.2em' }} src={comp.photo} /> }</td>}{!styling.hide_flags && <td style={{ width:'100px', verticalAlign:'middle' }} >
                                   {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1.2em' }} 
                                   src={comp.country}   ></img> }
                                   </td>}<td style={{ textAlign:'left', borderRightStyle:'solid' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>{upScoresList}{scoresList}<td style={{ borderLeftStyle:'solid' }}><strong>{this.niceFormatScore(parseFloat(comp.getScoreTotal()) )}</strong></td>
                                                                               </tr>;
                                                                           } else {
                                        return <tr  style={{ fontSize:'200%' }}>
                                   <td>{comp.place}</td>{!styling.hide_thumbnails && <td style={{ width:'50px' }}>{comp.photo != '' && !styling.hide_thumbnails &&  <img style={{ 'height':'1.2em' }} src={comp.photo} /> }</td>}{!styling.hide_flags && <td style={{ width:'100px', verticalAlign:'middle' }} >
                                   {comp.country != '' && !styling.hide_flags && <img style={{ 'height':'1.2em' }} 
                                   src={comp.country}   ></img> }
                              </td>}<td style={{ textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </td>
                                                                               </tr>;                                        
                                                    }
                });
                
                return <div style={{ position: 'absolute',top: '50%',left: '50%', width:'100%' }}  className={ (styling.display_type == 'green') ? ((styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft') : '' }>
                <div style={{ transform: 'translate(-50%, -50%)' }} >
                    <div style={{ width:'100%', paddingLeft:'5%', paddingRight:'5%' }} >
                        {this.activeProtests().length > 0 && <Alert variant="danger" className='text-center'>
                        <Alert.Heading>Not Final</Alert.Heading>
                        There is a protest on this division and these are not the final results.                          
                </Alert>}
                <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                      <Card.Header style={{ textAlign:'center',fontSize: '200%' }}>{this.name}</Card.Header>
                        <Card.Body >
                                    <Table variant="dark">
                                               {compList}                                                                                    
                                    </Table>
                        </Card.Body>
                  </Card>
                  </div>
                </div>
                </div>
            }
        }
    }
    
    protestsDisplay(holder) {
        return  this.activeProtests().map((p) => { 
        
                        const protestor = this.getComp(p.protestor_id);
                        const protestee = this.getComp(p.protestee_id);

                        const Area = () => (
                                <Alert variant="danger">
                                    <Alert.Heading>Protest - {protestor && protestor.name}{!protestor && <span>?</span>} VS {protestee && protestor.name}{!protestee && <span>?</span>}</Alert.Heading>
                                    <Container>
                                    <Row>
                                    <Col xs='10'>
                                        <p>Status: {p.status}</p>
                                        <p>Reason: {p.reason}</p>
                                    </Col>
                                    <Col xs='2'>
                                        {holder && <Button variant='primary' onClick={ ()=>{ holder.setActiveProtest(p); } } ><i class="fas fa-eye"></i></Button>}
                                    </Col>
                                    </Row>
                                    </Container>                                    
                                </Alert>
                         );

                         return <Area />   
               }
        );
             
    
    }
}