import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {AppContext, AppDomain} from '../constants';
import ReactDOM from 'react-dom';

export class SoundsManager extends React.Component { 
    
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.warningAudio = new Audio(AppDomain + '/sounds/warning.mp3');
        this.stopAudio = new Audio(AppDomain + '/sounds/stop.wav');
        
        this.ignore_sounds=false;
    }
   
    playWarning() {
        if(this.ignore_sounds) {
            return;
        }
        this.warningAudio.play();
    }
    
    playStop() {
        if(this.ignore_sounds) {
            return;
        }
        this.stopAudio.play();
    }
    
   
    render() {
        return <div></div>
        ;
    }
}
