import React from 'react';
import Toast from 'react-bootstrap/Toast';
import {AppContext} from '../constants';


export class MessagesManager {
    constructor() {
        this.messages = [];
        this.insides = [];
        
        
    }
    
    getNumberNew() {
        return this.messages.filter((x)=>x.new).length;
    }
    
    clearMessages() {
        this.messages = [];
        this.update();
    }
    
    removedMessage() {
        
        this.update();
    }
    
    addMessage(message) {
        this.messages.unshift(
                message
        );
        this.update();
    }
    
    update() {
        this.messages = this.messages.filter(function(value, index, arr){ return value.show == true; } );
        this.insides.map( (window) => window.setState({messages:this.messages}) );
        
    }
    
    addDisplay(inside) {
        this.insides.push( inside );
        this.update();        
    }
    
    removeDisplay(inside) {
        this.insides = this.insides.filter(function(value, index, arr){ return value.show;});
    }
    
    getMessages() {
        return this.messages;
    }
}

export class Message {
    constructor(header, body) {
        this.header = header;
        this.body = body;
        this.time = new Date();
        this.show = true;
        this.new = true;
    }
}

export class MessagesDisplay  extends React.Component {
    static contextType = AppContext;
    
    constructor(props) {
        super(props);
        this.show = true;
        this.messages = []
        
        this.removeMessage = this.removeMessage.bind(this)
    }
    
    componentDidMount() {
        this.context.messages_manager.addDisplay(this);
    }
    
    componentWillUnmount() {
       this.show = false;
       this.context.messages_manager.removeDisplay(this);
    }
   
    removeMessage(message) {
        message.show = false;
        this.context.messages_manager.removedMessage()
    }
   
    
    render() {
        const messageList = this.context.messages_manager.messages.map((message ) => {
            message.new = false;
        return <Toast onClose={() => this.removeMessage(message) } >
            <Toast.Header >
              <strong className="mr-auto">{message.header}</strong>
              <small>{message.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }</small>
            </Toast.Header>
            <Toast.Body>{message.body}</Toast.Body>
          </Toast>
          ;});
  
        return <div>
        {messageList}
        </div>;
    }
}