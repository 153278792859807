import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';

import postMaster from '../../logic/postmaster'
import {StopWatch, Timer} from '../../logic/clocks';
import {AppContext, AppDomain, seeds} from '../../constants';
import {FightCall, Fight, BracketChart} from '../../models/fights'
import {Standing} from '../../models/standing'
import {Division} from '../../models/division'
import {Judge} from '../../models/judge'
import {MessagesDisplay, Message} from '../../models/messages'
import {swapFights} from '../../functions';
import {joinMeeting} from '../../logic/meeting';


export class DivisionGraphicsDisplay extends React.Component { 
    static contextType = AppContext;
    

    constructor(props) {
        super(props);
        
        props.holder.div_area = this;
        
        this.ring = props.ring;
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        
        
        this.swapToDivision = this.swapToDivision.bind(this);
        this.checkCompetitor = this.checkCompetitor.bind(this);
        this.checkin_filter = '';
        
        this.checkinFilterUpdate = this.checkinFilterUpdate.bind(this);
        this.startDivision = this.startDivision.bind(this);
        this.startShufDivision = this.startShufDivision.bind(this);
        this.startScoringDivision = this.startScoringDivision.bind(this);
        this.cancelDivision = this.cancelDivision.bind(this);
        this.setScore = this.setScore.bind(this);
        this.completeDivision = this.completeDivision.bind(this);
        this.toggleWinner = this.toggleWinner.bind(this);
        this.setAddTime = this.setAddTime.bind(this);
        this.setSubTime = this.setSubTime.bind(this);
        this.toggleCardView = this.toggleCardView.bind(this);
        this.setCheckinOrder = this.setCheckinOrder.bind(this);
        this.toggleShowCompleted = this.toggleShowCompleted.bind(this)
        this.filteredComplete = this.filteredComplete.bind(this);
        this.closeDivision = this.closeDivision.bind(this);
        this.clearJudges = this.clearJudges.bind(this);
        this.openOptionsModal = this.openOptionsModal.bind(this);
        this.closeOptionsModal = this.closeOptionsModal.bind(this);
        this.options_modal = false;
        
        this.show_completed = false;
        
        this.addTime = false;
        this.subTime = false;
        
        this.card_view = false;
        
        this.check_order = 'checkin'
        
        this.show_standing_order = false;
        this.show_flags = true;
        this.show_thumbnails = true;
        this.show_title_images = true;
        this.invert_directions = false;
        this.number_upcoming = 6;
        
        this.p_type = 1;
        this.p_current_div = null;
        this.p_current_status = 0;
        this.p_current_item = null;
        this.p_header_display = -1;
        this.p_body_out = 0;
        this.p2_body_out = 0;
        this.p_complete_disp = 0;
        this.p_network_bug = false;
        this.g_network_bug = false;
        
        this.p_custom_comp_1 = '';
        this.p_custom_comp_2 = '';
        
        this.g_type = 1;
        this.g_current_div = null;
        this.g_current_status = 0;
        this.g_current_item = null;
        this.g_header_display = -1;
        this.g_body_out = 0;
        this.g_custom_comp_1 = '';
        this.g_custom_comp_2 = '';
        this.g_complete_disp = 0;
        
        this.bracket_sizing = 1.2;
        
        this.edit_fight_value = 30;
        
        this.logo_displayed = null;
        
        this.p_style = {adj:0.5, screen:{sizing:'1.0' }, display_type:'green' };
        
        this.logos = [
            {
                animateInGreen:'zoomInGreen',
                animateOutGreen:'zoomOutGreen',
                url:AppDomain + '/MATTechLogo2.png',
                name:'MAT Tech',
                status:0,
                width:'27%'
            },
            {
                animateInGreen:'zoomInGreen',
                animateOutGreen:'zoomOutGreen',
                url:AppDomain + '/SMA2.png',
                name:'SMA',
                status:0,
                width:'initial'
            },
   /*         {
                animateInGreen:'zoomInGreen',
                animateOutGreen:'zoomOutGreen',
                url:AppDomain + '/century_white_logo.png',
                name:'Century',
                status:0,
                width:'33%'
            }, */
            {
                animateInGreen:'zoomInGreen',
                animateOutGreen:'zoomOutGreen',
                url:AppDomain + '/wkc.png',
                name:'WKC',
                status:0,
                width:'23%'
            }
        ];
    }
    
    startLogo(logo) {
        this.logo_displayed = logo;
        this.setState({logo_displayed:logo});
    }
    
    flyOutLogo() {
        if(!this.logo_displayed) return;
        this.logo_displayed.status = 1;
        this.setState({logo_displayed:this.logo_displayed});
    }
    
    clearLogo() {
        if(!this.logo_displayed) return;
        this.logo_displayed.status = 0;
        this.logo_displayed = null;
        this.setState({logo_displayed:null});
    }
    
    apply() {
        this.g_current_div = this.p_current_div;
        this.g_current_status = this.p_current_status;      
        this.g_current_item = this.p_current_item;  
        
        this.g_header_display = this.p_header_display;      
        this.g_body_out = this.p_body_out;  
        this.p2_body_out = this.p_body_out;  
        
        this.g_network_bug = this.p_network_bug;
        
        this.g_type = this.p_type;
        this.g_custom_comp_1 = this.p_custom_comp_1;
        this.g_custom_comp_2 = this.p_custom_comp_2;        
        this.g_complete_disp = this.p_complete_disp;
        
        if(this.p_body_out == 1) {
            this.p_body_out = 2;
            this.p2_body_out = 2;
        }
        
        window.APP.reset();
    }
    
    resetPending() {
        this.p_current_div = this.g_current_div;
        this.p_current_status = this.g_current_status; 
        this.p_current_item = this.g_current_item;  
        
        this.p_header_display = this.g_header_display;      
        this.p_body_out = this.p2_body_out;  
        this.p_network_bug = this.g_network_bug;
        
        this.p_type = this.g_type;
        this.p_custom_comp_1 = this.g_custom_comp_1;
        this.p_custom_comp_2 = this.g_custom_comp_2;
        this.p_complete_disp = this.g_complete_disp;
        
        window.APP.reset();
    }
    
    
    
    flyBodyOut() {
        this.g_body_out = 1;  
        window.APP.reset();
    }
    
    flyBodyIn() {
        this.g_body_out = 0;  
        window.APP.reset();
    }
    
    resetRing() {
        this.p_current_div = this.current_division;
        
        if(this.current_division) {
            this.p_current_status = this.current_division.status;                
        } else {
            this.p_current_status = 0;  
        }     
        
        if(this.ring.current_fight != null) {
            this.p_current_item = this.ring.current_fight;
        } else if(this.ring.current_division != null) {
            this.p_current_item = this.ring.current_division.current_comp;
        }
        
        this.p_type = 1;
        
        window.APP.reset();
    }
    
    next() {
        if(this.current_division != null && this.current_division.current_comp != null) {
            let comp = this.current_division.current_comp;
            if(comp.after == null) {
                if(this.current_division.status == 2) {
                    //this.completeDivision();
                }
                
                if(this.current_division.status == 1) {
                    //this.startScoringDivision();
                }                
            } else {
                this.setCurrentComp(comp.after);
            }
        }
    }
    
    previous() {
        if(this.current_division != null && this.current_division.current_comp != null) {
            let comp = this.current_division.current_comp;
            this.setCurrentComp(comp.preceding);
        }        
    }
    
    clearJudges() {
        var r = this.ring;
        var js = [];
        r.getJudges().forEach((judge) => {
            judge.stop_time = new Date();
            if(isNaN(judge.planned_stop ) ) {
                judge.planned_stop = new Date();
            } 
            js.push(judge);
        });   
        
        js.forEach((judge) => {
           r.updateJudges(judge); 
        });
        
        
        window.APP.forceUpdate();
    }
    
    filteredComplete(bool){
        if(!bool) {
            return this.ring.getSchedule(24).filter(function(value, index, arr){ 
                return value.status < 3;
            });
        } else {
            return this.ring.getSchedule(24);
        }
    }
    
    toggleShowCompleted() {
        this.show_completed = !this.show_completed
        this.setState({show_completed: this.show_completed});
    }
    
    setCheckinOrder(sortBy) {
        this.check_order = sortBy;
        this.setState({check_order: this.check_order});
    }
    
    toggleCardView() {
        this.card_view = !this.card_view;
        this.setState({card_view:this.card_view} );
    }
    
    swapToDivision(division) {
        this.current_division = division;
        this.ring.setDivision(division);
        
        if(division) {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/current-division/' + division.id )
        } else {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/current-division/clear' )
        }
        this.setState({current_division: division});
    }
    
    startShufDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 1 )
        this.current_division.shufStart();
        this.setState({current_division: this.current_division});
    }
    
    startDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 1 )
        this.current_division.start();
        this.setState({current_division: this.current_division});
    }
    
    toggleWinner() {
        this.current_division.toggleWinnerPage();
        this.setState({current_division: this.current_division});
    }
    
    startScoringDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 2 )
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        this.current_division.startScore();
        this.setState({current_division: this.current_division});
    }
    
    
    completeDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 )
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        this.current_division.complete();
        this.setState({current_division: this.current_division});
    }
    
    closeDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 );
                
        if(this.current_division.getNotCheckedIn().length > 0 && !window.confirm("This division has competitors - are you sure you want to close it?") ) { 
            return;            
        }
        
        this.current_division.complete();
        this.swapToDivision(null);
        this.setState({current_division: this.current_division});
    }
    
    cancelDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 0 )
        this.current_division.cancel();
        this.setState({current_division: this.current_division});
    }
    
    checkCompetitor(comp, value) {
        if(comp.members.length == 0) {
            comp.check(value);        
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/checkin/' + value )
        } else {
            comp.members.forEach( (st, index, self2) => {
                this.checkCompetitor(st, value);
            });
        }                                                                                                                            
        this.setState({current_division: this.division});
    }
    
    checkinFilterUpdate(event) {
        this.setState({checkin_filter: event.target.value.toLowerCase() });
        this.checkin_filter =  event.target.value.toLowerCase();
    }
    
    sendStandingReminder(comp) {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/reminder' );
        alert('Sent message if possible');
    }
    
    competitorSeeding(comp, value) {
        comp.setSeeding(value);
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/seeding/' + value )
        var ring = this.ring;
        comp.members.forEach( (st, index, self2) => {
           if (st.id == comp.id) return; 
           st.setSeeding(value);
           postMaster.toSend('/tournament-ring/'+ ring.id + '/standing/' + st.id + '/seeding/' + value )
        }) 
                                            
        this.setState({current_division: this.division});
    }
    
    competitorModalOpen(comp) {
        comp.changeModal(true);
        this.setState({current_division: this.division});
    }

    competitorModalClose(comp) {
        comp.changeModal(false);
        this.setState({current_division: this.division});
    }
    
    setCurrentComp(comp) {    
        if(this.current_division.current_comp && this.current_division.current_comp.stopWatch.stop() ) {
            comp.stopWatch.stop();
        }
        this.current_division.setCurrentComp(comp)
            
            
        if(comp) {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/' + comp.id  )
        } else {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        }
        this.setState({current_division: this.division});
    }
    
    
    setScore(comp, judge,value) {
        let f = parseFloat(value);
        if (this.current_division.score_rules != 'int' &&  f > 10.0) {
            f = f / 10.0;
        } 
        if (this.current_division.score_rules != 'int') {
            f =  Math.round(f * 100.0) / 100.0;
        }
        if(isNaN(f) ) {
            f = 0;
        }        
        
        var og = value.toString();
        var n = f.toString();
                
        if (this.current_division.score_rules != 'int') {
            while(og.replace(".", "").length > n.replace(".", "").length && og.length > 2  && n.length < 4 ) {
                n = n + '0';
            }
            if(parseFloat(n) > 10) {
                n = '10';
            }
        }
        comp.setScore(judge, n);
        postMaster.immediateGet('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/' + comp.id + '/score/' + judge + '/' + n );        
        this.forceUpdate();
    }
    
    setAddTime(v) {
        this.addTime = v;
        this.subTime = false;
        this.setState({subTime: this.subTime, addTime: this.addTime } );
    }
    
    setSubTime(v) {
        this.subTime = v;
        this.addTime = false;
        this.setState({subTime: this.subTime, addTime: this.addTime } );   
    }
    
    openOptionsModal() {
        this.options_modal = true;
        this.setState({options_modal: this.options_modal } );   
    }
    
    closeOptionsModal() {
        this.options_modal = false;
        this.setState({options_modal: this.options_modal } );   
    }
    
    getCompCardCustomFunc(line_1, line_2) {
        return function(styling ) { 
            if(line_1 == '') return <Container></Container>;
            const adj = styling.adj;
            
            return <Container className={(styling.not_body == 1)? 'animated slideOutLeft' : 'animated slideInLeft' } style={{ width:'80%', maxWidth:'80%', position:'absolute',bottom:'10px',left:'0' }}>

                <Row  style={{ textAlign:'center' }}>
                    <Col xs={1} style={{padding:'0', backgroundColor:'#000',display:'flex',alignItems:'center'}}>
                        <img style={{ 'width':'60%',marginLeft:'auto',marginRight:'auto' }} 
                                src={AppDomain + "/app/white_M_icon.png"}   ></img>
                    </Col> 

                    <Col xs={6} style={{padding:'0'}}>
                        <Card bg='dark' 
                        style={{ width:'100%', borderRadius:'0'  }}
                            text= 'light'
                          >
                           <Card.Body  style={{ fontSize:'150%' }} >{line_1}</Card.Body>
                      </Card>
                      </Col>
                    </Row>

                    {line_2 != '' && <Row  style={{ textAlign:'center' }}>
                    <Col xs={7} style={{padding:'0'}}>
                        <Card
                        style={{ width:'100%', borderRadius:'0'  }}
                          >
                           <Card.Body  style={{ fontSize:'90%',color:'#212529' }} >
                                <Container>
                                <Row>
                                <Col xs={12}>{line_2}</Col>
                                </Row>
                                </Container>
                            </Card.Body>
                      </Card>
                      </Col>
                    </Row>}


                </Container>;
                
        }
    }
    
    getUpcomingDivisionsFunc() {
        const t = this;
        return function(styling ) { 
            const adj = styling.adj;
            const divisionsList = t.ring.getSchedule(24)
                    .filter(function(value, index, arr){ return (value.status == 0); })
            .slice(0, t.number_upcoming)
                    .map((division) => 
            <tr><td style={{ fontSize:((32 * adj) + 'px'),textAlign:'center' }}>{division.name}</td></tr>
              );

            return <div style={{ position: 'absolute',top: '50%',left: '50%', width:'100%' }}  className={ (styling.not_body == 1) ? 'animated slideOutLeft' : 'animated slideInLeft' }>
                <div style={{ transform: 'translate(-50%, -50%)' }} >
                    <div style={{ width:'100%', paddingLeft:'10%', paddingRight:'10%' }} > 
                        <Card bg='dark'
                        style={{ margin:'1%', marginTop:((15 * adj) + 'px'), width:'98%'  }}
                            text= 'light'
                          >
                            <Card.Header style={{ textAlign:'center',fontSize:'2em' }}>
                                   {t.ring.name} Upcoming         
                            </Card.Header>
                            <Card.Body>
                             <Table striped bordered hover variant="dark">
                                    <tbody>
                                        {divisionsList}
                                    </tbody>
                                </Table>
                            </Card.Body>
                      </Card>
                      </div>
                </div>                                                       
                </div>
            }

                
        
    }
    
    getMiddleCardCustomFunc(line_1, line_2) {
        return function(styling ) { 
            if(line_1 == '') return <Container></Container>;
            const adj = styling.adj;
            return <div className={(styling.not_body == 1)? 'animated slideOutDown' : 'animated slideInUp' } style={{ width:'100%', position:'absolute',bottom:'10px' }}>
                <Container style={{ marginLeft:'auto',marginRight:'auto',width:'80%' }}>
                    <Row  style={{ textAlign:'center' }}>
                    <Col xs={1} style={{padding:'0', backgroundColor:'#000',display:'flex',alignItems:'center'}}>
                        <img style={{ 'width':'60%',marginLeft:'auto',marginRight:'auto' }} 
                                src={AppDomain + "/app/white_M_icon.png"}   ></img>
                    </Col> 

                    <Col xs={10} style={{padding:'0'}}>
                        <Card bg='dark' 
                        style={{ width:'100%', borderRadius:'0'  }}
                            text= 'light'
                          >
                           <Card.Body  style={{ fontSize:'150%' }} >{line_1}</Card.Body>
                      </Card>
                      </Col>
                      <Col xs={1} style={{padding:'0', backgroundColor:'#000',display:'flex',alignItems:'center'}}>
                          <img
                                                src={window.APP.ring.image}
                                                width={'100%' }
                                                alt="tournament logo"
                                              />
                    </Col> 
                    </Row>

                    {line_2 != '' && <Row  style={{ textAlign:'center' }}>
                    <Col xs={12} style={{padding:'0'}}>
                        <Card
                        style={{ width:'100%', borderRadius:'0'  }}
                          >
                           <Card.Body  style={{ fontSize:'110%',color:'#212529' }} >
                                <Container>
                                <Row>
                                <Col xs={12}>{line_2}</Col>
                                </Row>
                                </Container>
                            </Card.Body>
                      </Card>
                      </Col>
                    </Row>}


                </Container>
                </div>;
        }
    }
    
    render() {
        
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        const holder = this;
        
        
        
        if(this.g_current_div ) {
            window.APP.header_second = this.g_current_div.name;
        } else {
            window.APP.header_second = '';
        }
        
        var preview = <Container></Container>;
        
        var g_styling_options = {};
        
        if(this.g_header_display == 1) {
            g_styling_options.not_header = false;
        }
        
        if(this.g_header_display == -1) {
            g_styling_options.not_header = true;
        }
        
        if(!this.show_standing_order) {
            g_styling_options.hide_standing_order = true;
        }
        
        if(!this.show_flags) {
            g_styling_options.hide_flags = true;
        }
        
        if(!this.show_thumbnails) {
            g_styling_options.hide_thumbnails = true;
        }
        
        if(!this.show_title_images) {
            g_styling_options.hide_title_images = true;
        }
        
        if(this.invert_directions) {
            g_styling_options.invert_directions = true;
        }
        
        
        g_styling_options.not_body = this.g_body_out;
        
        if(this.g_complete_disp == 0) {
            g_styling_options.force_winner_screen = true;
        }
        
        if(this.g_complete_disp == 1) {
            g_styling_options.force_places_screen = true;
        }
        
        this.context.window_manager.setLogo(
                this.logo_displayed
        );

        g_styling_options.network_bug = this.p_network_bug;
        g_styling_options.brackets_adj = this.bracket_sizing;
        
        if(this.g_type == 0) {
            g_styling_options.not_header = true;
            this.context.window_manager.setDef(this.getUpcomingDivisionsFunc(), g_styling_options )
            
        }
        
        if(this.g_type == 1) {
            if(this.g_current_item instanceof Fight) { 
                if(this.g_header_display == 0) {
                    g_styling_options.not_header = true;
                }

                this.context.window_manager.setDef(
                    this.g_current_item.getDisplay, g_styling_options
                );
            } else {
                if(this.g_header_display == 0) {
                    g_styling_options.not_header = false;
                }

                if(this.g_current_div) {
                    var div = this.g_current_div;

                    if(this.g_current_status == -1) { 
                        this.context.window_manager.setDef( this.getMiddleCardCustomFunc(div.name, this.ring.name), styling_options);
                    }

                    if(this.g_current_status == 0) {
                        if(this.g_header_display == 0) {
                            g_styling_options.not_header = true;
                        }
                        this.context.window_manager.setDef(
                            div.getUncheckedDisplay, g_styling_options
                       );
                    }

                    if (this.g_current_status == 1) {
                        if(this.g_current_item == null || (this.g_current_item != null && ! this.g_current_item instanceof Standing) ) {
                                if(this.g_header_display == 0) {
                                    g_styling_options.not_header = true;
                                }
                                this.context.window_manager.setDef(
                                    div.getCompOrderDisplay() , g_styling_options
                                );
                            } else {
                            div.getCompOrder();

                            let comp = this.g_current_item;
                            let upcoming_c = [];

                            var u_comp = comp.after;
                            while(u_comp) {
                                upcoming_c.push(u_comp);
                                u_comp = u_comp.after;
                            }

                            const upcomingListTv = function(styling) {
                                return upcoming_c.map((comp) => {

                                  return   <tr>
                                      </tr>;
                                  }
                                );
                            };

                            this.context.window_manager.setDef(
                                comp.getCompetingWindow (upcomingListTv, div), g_styling_options
                            );
                        }
                    }


                    if (this.g_current_status == 2) {
                        if(!this.g_current_item || ! this.g_current_item instanceof Standing) {
                                if(this.g_header_display == 0) {
                                    g_styling_options.not_header = true;
                                }
                                this.context.window_manager.setDef(
                                    div.getCompOrderDisplay(this.ring.getNumJudges()), g_styling_options 
                                );

                        } else {
                            this.context.window_manager.setDef(
                                this.g_current_item.getScoringWindow (div), g_styling_options
                            );
                        }
                    }

                    if (this.g_current_status == 3) {
                        if(this.g_header_display == 0) {
                            g_styling_options.not_header = true; 
                        }
                        this.context.window_manager.setDef(
                            div.getCompPlacesDisplay, g_styling_options
                        );
                    }
                } else {
                    this.context.window_manager.setDef(
                            <Container></Container>, g_styling_options
                        );
                }
            }
        }
        
        if(this.g_type == 2) {
            this.context.window_manager.setDef(this.getCompCardCustomFunc(this.g_custom_comp_1, this.g_custom_comp_2), g_styling_options )
        }
        
        if(this.g_type == 3) {
            this.context.window_manager.setDef(this.getMiddleCardCustomFunc(this.g_custom_comp_1, this.g_custom_comp_2), g_styling_options )
        }
        
        if(this.g_type == -1) {
            this.context.window_manager.setDef( this.getMiddleCardCustomFunc('WKC', this.ring.name), styling_options);
        }
        
        var styling_options = this.p_style;
        
       styling_options.screen.brackets_adj = this.brackets_adj;
        
        if(this.p_header_display == 1) {
            styling_options.not_header = false;
        }
        if(this.p_header_display == -1) {
            styling_options.not_header = true;
        }
        
        if(!this.show_standing_order) {
            styling_options.hide_standing_order = true;
        }
        
        if(!this.show_flags) {
            styling_options.hide_flags = true;
        }
        
        if(!this.show_thumbnails) {
            styling_options.hide_thumbnails = true;
        }
        
        if(!this.show_title_images) {
            styling_options.hide_title_images = true;
        }
        
        if(this.p_complete_disp == 0) {
            styling_options.force_winner_screen = true;
        }
        
        if(this.p_complete_disp == 1) {
            styling_options.force_places_screen = true;
        }
        
        if(this.invert_directions) {
            styling_options.invert_directions = true;
        }
        
        styling_options.screen.brackets_adj = this.bracket_sizing;
        
        styling_options.not_body = this.p_body_out;
        if(this.p_type == 0) {
            preview =  this.getUpcomingDivisionsFunc()(styling_options);
        }
        
        var p_header_appears = true;
        
        if(this.p_type == -1) { p_header_appears = false;
            preview = this.getMiddleCardCustomFunc('WKC', this.ring.name)(styling_options);
        }
        
        if(this.p_type == 1) {
            if(this.p_current_item instanceof Fight) { p_header_appears = false;
                preview = this.p_current_item.getDisplay(styling_options);
            } else {
                if(this.p_current_div) {
                    var div = this.p_current_div;

                    if(this.p_current_status == -1) { p_header_appears = false;
                        preview = this.getMiddleCardCustomFunc(div.name, this.ring.name)(styling_options);
                    }

                    if(this.p_current_status == 0) { p_header_appears = false;
                        preview = div.getUncheckedDisplay(styling_options)
                    }

                    if (this.p_current_status == 1) {
                        if(this.p_current_item == null || (this.p_current_item != null && ! this.p_current_item instanceof Standing) ) {
                             preview =  div.getCompOrderDisplay() (styling_options); p_header_appears = false;

                            } else {
                            div.getCompOrder();

                            let comp = this.p_current_item;
                            let upcoming_c = [];

                            var u_comp = comp.after;
                            while(u_comp) {
                                upcoming_c.push(u_comp);
                                u_comp = u_comp.after;
                            }

                            const upcomingListTv = function(styling) {
                                return upcoming_c.map((comp) => {

                                  return   <tr>
                                      </tr>;
                                  }
                                );
                            };

                            preview =  comp.getCompetingWindow (upcomingListTv, div) (styling_options)
                        }
                    }


                    if (this.p_current_status == 2) {
                        if(!this.p_current_item || ! this.p_current_item instanceof Standing) {
                            preview =  div.getCompOrderDisplay(this.ring.getNumJudges()) (styling_options); p_header_appears = false;

                        } else {
                                preview = this.p_current_item.getScoringWindow (div) (styling_options)                        
                        }
                    }

                    if (this.p_current_status == 3) {

                        preview =  div.getCompPlacesDisplay(styling_options); p_header_appears = false;

                    }
                } else {
                    preview =  <Container></Container>;
                }

            }
        }
        
        if(this.p_type == 2) {
            preview =  this.getCompCardCustomFunc(this.p_custom_comp_1, this.p_custom_comp_2)(styling_options)
        }
        
        if(this.p_type == 3) {
            preview =  this.getMiddleCardCustomFunc(this.p_custom_comp_1, this.p_custom_comp_2)(styling_options)
        }
        
        if(this.p_header_display == 1) {
            p_header_appears = true;
        }
        if(this.p_header_display == -1) {
            p_header_appears = false;
        }
        
        var p_nb = this.p_network_bug;
        
        var p_header = <div>
            
              <div style={{ width:'100%', display:(p_header_appears) ? 'block' : 'none' }}  >
        <Navbar style={{ display:'flex',flexDirection:'row',  paddingTop:'3px'  }} bg="dark" expand="lg" variant="dark" className='justify-content-between' 
        >
            <Nav style={{ flexDirection:'row',flex:'1' }}>
                { window.APP && window.APP.ring && window.APP.ring.image && 
                <Navbar.Brand href="#home"><img
                src={window.APP.ring.image}
                height={120}
                alt="tournament logo"
              />
              { (this.type == 'stream') && <img
                  src={ 'https://cdn.mataction.com/CDN_3/MAT-Logo-top.png' }
                  height={80 }
                  alt="MAT Action logo"
                  style={{ marginTop: '20px' }}
                />}
                    </Navbar.Brand> }
            
            </Nav>
            <Nav style={{ flexDirection:'row' }}>
                <Navbar.Brand style={{ fontSize:'200%',textAlign:'center',verticalAlign:'middle',marginTop:'auto',marginBottom:'auto',whiteSpace:'normal' }} href="#home"> { window.APP && window.APP.ring &&  window.APP.ring.name} { (window.APP && window.APP.header_second != '') ? <span>{this.display_type == 'green' && <br></br>}{this.display_type != 'green' && <span> - </span>}{window.APP.header_second}</span> : '' }</Navbar.Brand>
            </Nav>
            <Nav style={{ flexDirection:'row',flex:'1' }}>
                <Navbar.Brand href="#home" style={{ marginLeft: 'auto' }}>
            
                { (this.type != 'stream') && <img
                  src={ 'https://cdn.mataction.com/CDN_3/MAT-Logo-top.png' }
                  height={80}
                  alt="MAT Action logo"
                  style={{ marginTop: '20px' }}
                />}</Navbar.Brand>
            </Nav>
        </Navbar></div>
        </div>;
        
        return <Container>
            <Row  style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Modal size="lg" show={this.options_modal} onHide={this.closeOptionsModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ width:'100%;', maxWidth:'100%' }}>
                        <Row>
                        <Col xs={12}>
                        <Table>
                        <tr>
                                <th style={{ textAlign:'left' }} >Play Sounds</th>
                               <td>
                               <Form.Check onClick={ (x) => { window.APP.sound_manager.ignore_sounds = !window.APP.sound_manager.ignore_sounds; window.APP.reset(); } }
                                    checked={!window.APP.sound_manager.ignore_sounds}
                                    label="Play Sounds"
                                  />
                               </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Bracket Sizing</th>
                               <td>
                               <Form.Group controlId="formBasicRangeCustom">
                                   <Form.Control value={this.bracket_sizing} step="0.1" min="0.1" max="2" type="range" custom onChange={(e)=>{ holder.bracket_sizing = parseFloat(e.target.value); window.APP.reset(); }} />
                               </Form.Group>     
                               </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign:'left' }} >Show Order Numbers</th>
                                <td>
                                <Form.Check onClick={ (x) => { holder.show_standing_order = !holder.show_standing_order; window.APP.reset(); } }
                                    checked={holder.show_standing_order}
                                    label="Show Order Numbers"
                                  />
                                </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Show Flags</th>
                                <td>
                                <Form.Check onClick={ (x) => { holder.show_flags = !holder.show_flags; window.APP.reset(); } }
                                    checked={holder.show_flags}
                                    label="Show Flags"
                                  />
                                </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Show Thumbnails</th>
                                <td>
                                <Form.Check onClick={ (x) => { holder.show_thumbnails = !holder.show_thumbnails; window.APP.reset(); } }
                                    checked={holder.show_thumbnails}
                                    label="Show Thumbnails"
                                  />
                                </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Show Title Images</th>
                                <td>
                                <Form.Check onClick={ (x) => { holder.show_title_images = !holder.show_title_images; window.APP.reset(); } }
                                    checked={holder.show_title_images}
                                    label="Show Title Profiles"
                                  />
                                </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Invert</th>
                                <td>
                                <Form.Check onClick={ (x) => { holder.invert_directions = !holder.invert_directions; window.APP.reset(); } }
                                    checked={holder.invert_directions}
                                    label="Invert"
                                  />
                                </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'left' }} >Upcoming Number Divisions</th>
                                <td>
                                <Form.Group controlId="formBasicRangeCustom">
                                   <Form.Control value={this.number_upcoming} type="number" onChange={(e)=>{ this.number_upcoming = parseInt(e.target.value);  window.APP.reset(); }} />
                               </Form.Group> 
                                
                                </td>
                            </tr>
                        </Table>
                        </Col>    
                        </Row>
                    </Container>
                    </Modal.Body>
                </Modal>
                <Card bg='dark'
                style={{ margin:'1%', width:'98%'  }}
                    text= 'light'
                  >
                   <Card.Header >Graphics Controller</Card.Header>
                    <Card.Body >
                        <Container>
                            <Row  style={{ textAlign:'center',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Scorekeeper</strong>
                                </Col>
                                <Col xs={4}>
                                    {holder.current_division != null && <div>Division: {holder.current_division.name}</div>}
                                    {holder.current_division != null && <div>Status: {holder.current_division.status == 0 && <span>Check-in</span>}{holder.current_division.status == 1 && <span>Competing</span>}{holder.current_division.status == 2 && <span>Scoring</span>}{holder.current_division.status == 3 && <span>Completed</span>}</div>}
                                </Col>
                                <Col xs={4}>
                                Current: {holder.current_division != null && holder.current_division.current_comp != null && <span>{holder.current_division.current_comp.name}</span> }
                                {this.ring.current_fight != null && <span>{this.ring.current_fight.getLeftComp() != null && this.ring.current_fight.getLeftComp().name} vs {this.ring.current_fight.getRightComp() != null && this.ring.current_fight.getRightComp().name}</span> }
                                </Col>
                                <Col xs={3}>
                                </Col>
                            </Row>
                            
                            <Row  style={{ display:'none', textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Display</strong>
                                </Col>
                                <Col xs={4}>
                                <div>
                                    <div>Division: {holder.g_current_div != null && <span>{holder.g_current_div.name}</span> }</div>
                                    <div>Status: {holder.g_current_status == 0 && <span>Check-in</span>}{holder.g_current_status == 1 && <span>Competing</span>}{holder.g_current_status == 2 && <span>Scoring</span>}{holder.g_current_status == 3 && <span>Completed</span>}</div>
                                </div>
                                </Col>
                                <Col xs={4}>
                                {this.g_current_div != null && <div>
                                    Display Item: {this.g_current_item != null && <span>
                                        {this.g_current_item instanceof Fight &&
                                                <span>{this.g_current_item.getLeftComp() != null && this.g_current_item.getLeftComp().name} vs {this.g_current_item.getRightComp() != null && this.g_current_item.getRightComp().name}</span>}

                                        {! this.g_current_item instanceof Fight &&
                                            <span>{this.g_current_item.name}</span>}
                                    </span>}

                                    </div>}
                                </Col>
                                <Col xs={3}>
                                </Col>
                            </Row>
                            <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={2}>
                                <div>
                                {this.g_body_out > 0 && <Button  variant='warning' onClick={ ()=>{ holder.flyBodyIn() } }>Fly In</Button>}
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                {this.g_body_out === 0 && <Button  variant='warning' onClick={ ()=>{ holder.flyBodyOut() } }>Fly Out</Button>}
                                </div>
                                </Col>                                    
                                <Col xs={2}>
                                <div>
                                <Button onClick={ ()=>{ holder.apply() } }>Apply Pending to Display</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button variant='danger' onClick={ ()=>{ holder.resetRing() } }>Set Pending to Scorekeeper</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button  variant='warning' onClick={ ()=>{ holder.resetPending() } }>Reset Pending to Display</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button  variant='primary' onClick={ ()=>{ holder.resetPending() } } onClick={this.openOptionsModal}>Options</Button>
                                </div>
                                </Col>
                            </Row>
                            
                            {this.logo_displayed == null && <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>
                                <div>
                                <strong>Logo Fly-In</strong>
                                </div>
                                </Col>
                                
                                
                                {this.logos.map( (x) =><Col xs={1}>
                                        <div style={{ padding:'5px' }}>
                                        <Button variant='primary' onClick={ () => { holder.startLogo(x); } } >{x.name}</Button>                                                                
                                        </div>
                                </Col>  )}
                                
                            </Row>}
                            
                            {this.logo_displayed != null && this.logo_displayed.status == 0 && <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>
                                <div>
                                <strong>Logo Fly-Out</strong>
                                </div>
                                </Col>
                                
                                <Col xs={11}>
                                <div>
                                <Button variant='success' onClick={ () => { holder.flyOutLogo() } }>Fly-Out Logo</Button>
                                </div>
                                </Col>
                                
                            </Row>}
                            
                            {this.logo_displayed != null && this.logo_displayed.status == 1 && <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>
                                <div>
                                <strong>Logo Clear</strong>
                                </div>
                                </Col>
                                
                                <Col xs={11}>
                                <div>
                                <Button variant='success' onClick={ () => { holder.clearLogo() } }>Clear Logo</Button>
                                </div>
                                </Col>
                                
                            </Row>}
                            
                            <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Pending</strong>
                                </Col>
                                <Col xs={4}>
                                    Type
                                    <Form.Control value={this.p_type} onChange={(e) => { holder.p_type = e.target.value; window.APP.reset();  }} as="select" size="lg" >
                                        <option value='-1'>Ring Title Card</option>
                                        <option value='0'>Upcoming Divisons</option>
                                        <option value='1'>Division</option>
                                        <option value='2'>Custom Competitor Title</option>
                                        <option value='3'>Custom Center Title</option>
                                    </Form.Control>
                                    {holder.p_type == 1 && <div>
                                    Division to Display
                                    <Form.Control value={(this.p_current_div != null) ?  this.p_current_div.id : '' } onChange={(e) => { holder.p_current_div = holder.divisions.find(x => x.id == e.target.value); holder.p_current_item = null; window.APP.reset();  }} as="select" size="lg" >
                                        <option value=''>None</option>
                                        {this.ring.getDivisionsInOrder().map(x => <option value={x.id}>{x.name}</option> )}
                                    </Form.Control>
                                    
                                    Division Status to Display
                                    <Form.Control value={this.p_current_status} onChange={(e) => { this.p_current_status = e.target.value; window.APP.reset(); }} as="select" size="lg" >
                                        <option value='-1'>Title Card</option>
                                        <option value='0'>Check-In</option>
                                        <option value='1'>Competing</option>
                                        <option value='2'>Scoring</option>
                                        <option value='3'>Completed</option>                                      
                                    </Form.Control>   
                                    </div>}
                                </Col>
                                <Col xs={4}>
                                    {holder.p_type == 1 &&  this.p_current_div != null &&  (this.p_current_status == 1 || this.p_current_status == 2) && <div>
                                      
                                       <div>Title Card
                                            <Form.Control value={(this.p_current_item != null) ?  this.p_current_item.id : '' } onChange={(e) => { holder.p_current_item = holder.p_current_div.standings.find(x => x.id == e.target.value); window.APP.reset(); }} as="select" size="lg" >
                                                <option value=''>None</option>
                                                {this.p_current_div.simpleOrderAll().map(x => <option value={x.id}>{x.ordered} {x.name}</option> )}
                                            </Form.Control></div>                                                                                                    
                                               
                                             {this.p_current_div.config_type == 'sparring' && <div>
                                            Fight
                                            <Form.Control value={(this.p_current_item != null) ?  this.p_current_item.id : '' } onChange={(e) => { holder.p_current_item = holder.p_current_div.fights.find(x => x.id == e.target.value); window.APP.reset(); }} as="select" size="lg" >
                                                <option value=''>None</option>
                                                {this.p_current_div.fights.filter(x => x .comp1 != null && x.comp2 != null ).map(x => <option value={x.id}>{x.getLeftComp() != null && x.getLeftComp().name} vs {x.getRightComp() != null && x.getRightComp().name}</option> )}
                                            </Form.Control>
                                            </div>}
                                            
                                        
                                    </div>}
                                    
                                    {holder.p_type == 1 &&  this.p_current_div != null &&  this.p_current_status == 3 && <div>
                                        Current Display:
                                            <Form.Control value={this.p_complete_disp} onChange={(e) => { holder.p_complete_disp = e.target.value; window.APP.reset(); }} as="select" size="lg" >
                                                <option value='0'>Winner Screen</option>
                                                <option value='1'>Places</option>
                                            </Form.Control>                                        
                                    </div>}
                                    
                                    {holder.p_type == 2 && <div>
                                        Title Line:
                                            <Form.Control value={this.p_custom_comp_1} onChange={(e) => { holder.p_custom_comp_1 = e.target.value; window.APP.reset(); }} type="text" size="lg" >
                                            </Form.Control>
                                        Second Line:                                        
                                            <Form.Control value={this.p_custom_comp_2} onChange={(e) => { holder.p_custom_comp_2 = e.target.value; window.APP.reset(); }} type="text" size="lg" >
                                            </Form.Control>
                                        
                                    </div>}
                                    
                                    {holder.p_type == 3 && <div>
                                        Title Line:
                                            <Form.Control value={this.p_custom_comp_1} onChange={(e) => { holder.p_custom_comp_1 = e.target.value; window.APP.reset(); }} type="text" size="lg" >
                                            </Form.Control>
                                        Second Line:                                        
                                            <Form.Control value={this.p_custom_comp_2} onChange={(e) => { holder.p_custom_comp_2 = e.target.value; window.APP.reset(); }} type="text" size="lg" >
                                            </Form.Control>
                                        
                                    </div>}
                                </Col>
                                <Col xs={3}>
                                    Display Header:
                                    <Form.Control value={this.p_header_display} onChange={(e) => { holder.p_header_display = e.target.value; window.APP.reset(); }} as="select" size="lg" >
                                        <option value='-1'>No</option>
                                        <option value='0'>Default</option>
                                        <option value='1'>Yes</option>  
                                    </Form.Control>
                                    
                                    <Form.Check onClick={ (x) => { return; holder.p_network_bug = !holder.p_network_bug; window.APP.reset(); } }
                                    checked={holder.p_network_bug}
                                    label="Show Network Bug"
                                    disabled
                                  />
                                    
                                    <div style={{ marginTop:'15px'}}>Display Body:</div>
                                    <Form.Control value={this.p_body_out} onChange={(e) => { this.p_body_out = e.target.value; window.APP.reset(); }} as="select" size="lg" >
                                        <option value='0'>Yes</option>
                                        <option value='1'>Out</option>
                                        <option value='2'>Hide</option>
                                    </Form.Control> 
                                    
                                    
                                  
                                </Col>
                            </Row>                            
                            <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                
                                <Col xs={12}>  
                                <div style={{ wdith:'100%',height:'800px',backgroundColor:'green' }}>{ p_header }{styling_options.not_body != 2 && preview}</div>
                                </Col>
                            </Row>                            
                        </Container>
                    </Card.Body>
              </Card>
              </Col>
            </Row>
        </Container>;
        
    }
    
}