import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import postMaster from '../logic/postmaster'
import {MatAlert} from './MATModels'

export class AvailableAlert {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.category = data.category;
        this.description = data.description;
        this.color = data.color;
        this.symbol = data.symbol;
        this.toolbar = data.toolbar
        
        this.stages = data.stages;
    }
    
    getStage(id) {
       return this.stages.find(element => element.id == id);
    }
}



export class AlertsManager {
    constructor() {
        this.availableAlerts = [];
        this.currentAlerts = [];
        this.url = '';
        this.up_url = '';
    }
    
    receiveEvent(e) {
        let a = this.getAlert(e.id);
        if(!a) {
            this.addAlert(e);
        } else {
            if(e.stage == '') {
                this.removeAlert(e.id)
            } else {
                let aa = this.getAvAlert(e.available_alert_id);
                if(!aa)return;
                let s = aa.getStage(e.stage);
                a.stage = s;
            }
        }
    }
    
    getAlert(id) {
        return this.currentAlerts.find(element => element.id == id);
    }
    
    removeAlert(id) {
        this.currentAlerts = this.currentAlerts.filter(function(value, index, arr){ return value.id != id });
    }
    
    hasAlerts() {
        return this.currentAlerts.length > 0;
    }
    
    addAlert(data) {
        let ava = this.getAvAlert(data.available_alert_id);
        if(!ava)return
        let stage = ava.getStage(data.stage)
        
        this.currentAlerts.push(new MatAlert(data, ava, stage ))
    }
    
    getAvAlert(id) {
       return this.availableAlerts.find(element => element.id == id);
    }
    
    setCreateURL(url) {
        this.url = url;
    }
    
    setUpdateURL(url) {
        this.up_url = url;
    }
    
    createNew(a) {
        postMaster.immediateGet( this.url + a.id );        
    }
    
    forwardNew(a) {
        postMaster.immediateGet( this.up_url + a.id );        
    }
    
    load(alerts) {
        this.availableAlerts = [];
        this.currentAlerts = [];
        alerts.forEach(alert => {
            this.availableAlerts.push(new AvailableAlert(alert) );
        });
    }
    
    popupChooserDisplay() {
        const avList = this.availableAlerts
                    .map((alert) => 
            <Col xs={4} style={{ textAlign:'center' }}>
            
                <h2 style={{ color:alert.color }} ><i class={alert.symbol}></i></h2>
                <Button onClick={() => this.createNew(alert) } variant="primary">{alert.name}</Button>
            </Col>
                     );
            return <Container>
            <Row>
                {avList}
            </Row>
            </Container>
             
    }
    
    medicNavButton() { 
        
        var a = this.availableAlerts.filter(
        function(value, index, arr){ 
            return value.toolbar == '1';
       });

        return a.map(
                (x) => <Nav.Link onClick={() => this.createNew(x)} >{x.name} <i style={{color: x.color }} class={x.symbol}></i></Nav.Link>
                    ) ;
     
    }
    
    medicButton() { 
        
        var a = this.availableAlerts.filter(
        function(value, index, arr){ 
            return value.name == 'Medic';
       });

       if(a.length > 0) {
           return <Button onClick={() => this.createNew(a[0]) } variant="primary">{a[0].name}</Button>
       } else {
           return <div></div>
       }             
    }
    
    multiDisplay() {
        const avList = this.currentAlerts
                    .map((alert) => 
            <Col xs={4} style={{ textAlign:'center' }}>
                <Card style={{borderColor: alert.av.color,marginTop:'15px' }}>
                    <Card.Header style={{color: alert.av.color }} >{alert.av.name} | {alert.item_name}</Card.Header>
                    <Card.Body>
                    Status: {alert.stage.name}    
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </Col>
                     );
            return <Container>
            <Row>
                {avList}
            </Row>
            </Container>
             
    }
    
    activeDisplay() {
        const avList = this.currentAlerts
                    .map((alert) => 
            <Col xs={12} style={{ textAlign:'center' }}>
                <Card style={{borderColor: alert.av.color,marginTop:'15px' }}>
                    <Card.Header style={{color: alert.av.color }} >{alert.av.name}</Card.Header>
                    <Card.Body>
                    Status: {alert.stage.name}    
                    </Card.Body>
                    <Card.Footer>
                    {alert.stage.controlled == 1 && 
                            <Button onClick={() => this.forwardNew(alert) } variant="primary">{alert.stage.forward}</Button>
                                        }
                    </Card.Footer>
                </Card>
            </Col>
                     );
            return <Container>
            <Row>
                {avList}
            </Row>
            </Container>
             
    }
    
    symbolsDisplay() {
        return this.currentAlerts
                    .map((alert) => { 
                     const popover = (
                            <Popover>
                              <Popover.Title>{alert.av.name}</Popover.Title>
                              <Popover.Content>
                                    Status: {alert.stage.name}    
                              </Popover.Content>
                            </Popover>
                          );

                        const Area = () => (
                                <OverlayTrigger placement="top" overlay={popover}>
                                    <i style={{color: alert.av.color,marginLeft:'3px',marginRight:'3px' }} class={alert.av.symbol}></i>
                                </OverlayTrigger>
                         );

                         return <Area />   
               }
        );
             
    }
    
    alertsDisplay() {
        const avList = this.currentAlerts
                    .map((alert) => 
            <Col xs={12} style={{ textAlign:'center' }}>
                <Alert style={{borderColor: alert.av.color }} variant="danger">
                    <Alert.Heading>{alert.av.name}</Alert.Heading>
                    <p>Status: {alert.stage.name} </p>
                </Alert>
            </Col>
                     );
            return <Container>
            <Row>
                {avList}
            </Row>
            </Container>
    }
}