import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';

import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import {TimeDisplay} from './TimeDisplay';

import {FightCall, Fight, BracketChart} from '../models/fights'
import {swapFights} from '../functions';

import DropdownButton from 'react-bootstrap/DropdownButton';


import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';

import postMaster from '../logic/postmaster'
import {StopWatch, Timer} from '../logic/clocks';
import {AppContext, AppDomain} from '../constants';
import {Standing} from '../models/standing'
import {Division} from '../models/division'
import {Judge} from '../models/judge'
import {MessagesDisplay, Message} from '../models/messages'
import Tab from 'react-bootstrap/Tab';
import {Ring} from '../models/ring'

import {sessionFormat, searchArray, searchDivisionHay, searchRingHay, getTimeString} from '../functions';
import {ProtestModal} from './newprotest';

import {dayWords} from '../constants';

export class CoordinatorInteface extends React.Component { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);        
        
        this.judge_check_in = 0;
        this.first_load = true;
        
        this.is_held =false;
        
        this.messages =false;
        this.displayModal = false;
        this.stopwatchModal = false;
        this.timerModal = false;
        
        this.stopwatch = new StopWatch();
        this.timer = new Timer(120);
        
        this.settingsModalOpen = this.settingsModalOpen.bind(this);
        this.settingsModalClose = this.settingsModalClose.bind(this);
        
        this.displayModalOpen = this.displayModalOpen.bind(this);
        this.displayModalClose = this.displayModalClose.bind(this);
        
        this.toggleCardView = this.toggleCardView.bind(this);
        
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        
        this.timerModalOpen = this.timerModalOpen.bind(this);
        this.timerModalOpen = this.timerModalOpen.bind(this);
        
        this.messagesToggle = this.messagesToggle.bind(this);
        
        this.setType = this.setType.bind(this);
        
        this.image = '';
        this.tournament_name = '';
        this.tournament_id = props.tournament_id;
        
        this.rings = [];
        this.un_divs = [];
        
        this.type = 'rings';
        this.current_ring_id = '';
        this.current_div_id = '';
        this.current_comp_id = '';
        
        this.division_id = null;
        this.ring_id = null;
        this.judge_id = null;
        this.send_movement_notifications = true;
        this.send_ring_notifications = true;
        this.ringMovementNotificationsClick = this.ringMovementNotificationsClick.bind(this);
        this.card_view = true;
        
        this.location_filter = '';
        this.group_filter = '';
        this.name_filter = '';
        this.date_filter = 'All';
        this.session_filter = 'All';
        this.ring_order = 'ordered';
        
        this.div_name_filter = '';
        
        this.judge_name_filter = '';
        
        this.schedules = [];
        
        this.dates = [];
        this.days = [];
        
        window.APP.coord = this;
        
        this.resize = this.resize.bind(this);
        
        this.load();
        var t = this;
        window.Echo.private('tournament.' + this.tournament_id + '.cordinator') 
        .listen('CustomMessage', (e) => {        
            window.APP.messages_manager.addMessage(new Message(e.message.subject, e.message.body  ));
            window.APP.reset();
        })
        .listen('RefreshRequest', (e) => {        
            if(e.type == 'data') {
                window.APP.needs_reload = 1;
        
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                   t.reload();
                }
            }
    
            if(e.type == 'refresh') {
                window.APP.needs_refresh = 1;
                
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                    window.location.reload();
                }
            }
            
            window.APP.reset();
        })
        .listen('TournamentRingStatus', (e) => { 
            var ring = this.getRing(e.ring);
            if(!ring) return;
            ring.status = e.status;   
            ring.active = e.active;       
            window.APP.reset();            
        })
        .listen('TournamentRingScheduleUpdate', (e) => { 
            var ring = this.getRing(e.ring)
            if(!ring) return;
                  
            e.updates.forEach(element => { 
                let div = ring.getDivision(element.division_id);
                if(!div) return; 
                div.ordered = parseInt(element.ordered);
                div.duration_estimate = parseInt(element.duration_estimate);
                
                div.estimated_start = new Date(element.est_start);    
                div.estimated_stop = new Date(element.est_stop);    
            });            
            window.APP.reset();            
        })
        .listen('TournamentDivisionStatus', (e) => {
            let div = this.findDivision(e.division_id);
            if(div) { div.status = e.status; div.is_final = e.is_final; }
            window.APP.reset();
        })
        .listen('ProtestUpdate', (e) => {
            let div = this.findDivision(e.division_id);
            if(div) div.updateProtest(e);
            window.APP.messages_manager.addMessage(new Message("Protest Update", e.message  ));
            window.APP.reset();
        })
        .listen('TournamentRingCurrentDivision', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            let div = ring.getDivision(e.division_id);
            ring.current_division = div
            window.APP.reset();
        })
        .listen('TournamentDivisionUpdate', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return; 
                        
            for (const [key, value] of Object.entries(e.changes)) {
                if(key == 'break_ties_till' || key == 'min_place' || key == 'min_size' || key == 'max_size' || key == 'is_team'  || key == 'ignore_final_fight') {
                    div[key] = parseInt(value);   
                } else {
                    div[key] = value;    
                }
            }
            
            div.standings.forEach((s) => {
                s.division = div;
            })
            
            window.APP.reset();            
        })
        .listen('TournamentDivisionPlaces', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;
            
            e.standings.forEach(element => { 
                let standing = div.getComp(element.id)
                if(standing == null) return;
                standing.place = parseInt(element.rank)
            });

            window.APP.reset();
        })
        .listen('TournamentDivisionShuffle', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.ordered = parseInt(element.ordered);
            });
            div.shuffled = 1;
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffleReset', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;
            div.shuffled = 0;
            window.APP.reset();
        })
        .listen('TournamentDivisionCompAdd', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(team == null) {
                    team = new Standing(e.comp.id);
                    team.name = e.team_name;
                    team.seeding = e.comp.seeding;
                    div.standings.push(team) 
                }
                
                let member = new Standing(e.comp.id);
                member.load(e.comp, div);
                team.members.push(member)
                
                div.checkTeams();
            } else {
                let standing = new Standing(e.comp.id);
                standing.load(e.comp, div);
                div.standings.push(standing)  
            }
            div.checkTeams();
            window.APP.reset();
        })
        .listen('TournamentDivisionCompRemoved', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) {
                    //check for old team with removed lead
                    team = div.getComp(e.id);
                    if(!team) return;
                    
                    team.id = e.team;
                };
                team.members = team.members.filter(function(value, index, arr){ return value.id != e.id });
                
                if(team.members.length == 0 ) { 
                    div.standings = div.standings.filter(function(value, index, arr){ return value.id != team.id });
                } 
            } else { 
                div.standings = div.standings.filter(function(value, index, arr){ return value.id != e.id });
            }
            div.checkTeams();
            window.APP.reset();
        })
        .listen('TournamentDivisionAllScores', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                comp.setScore(s.num, parseFloat(s.score));
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentDivisionFixedScores', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
        
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                
                if(s.num < 0) {
                    if(comp.up_scores[s.num * -1] == null) {
                        comp.up_scores[s.num* -1] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.up_scores[s.num* -1].score = s.score;
                    comp.up_scores[s.num* -1].og_score = s.og_score;
                    comp.up_scores[s.num* -1].drop = s.drop;
                    comp.up_scores[s.num* -1].judge_id = s.judge_id;
                } else {
                    if(comp.scores[s.num] == null) {
                        comp.scores[s.num] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.scores[s.num].score = s.score;
                    comp.scores[s.num].og_score = s.og_score;
                    comp.scores[s.num].drop = s.drop;
                    comp.scores[s.num].judge_id = s.judge_id;
                }
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentDivisionCurrentComp', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
        
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(div.current_comp && div.current_comp.stopWatch.master &&  div.current_comp.stopWatch.running) {
                div.current_comp.stopWatch.stop()
            }
            
            div.current_comp = div.getComp(e.standing_id)
            window.APP.reset();
        })
        .listen('TournamentStandingScore', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
        
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            comp.setScore(e.num, parseFloat(e.value));           
            window.APP.reset();
        })
        .listen('TournamentJudgesUpdate', (e) => {   
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            e.sent.forEach(j => {
               var jf = ring.a_judges.filter(function(e) {return e.id == j.id;});
               if(jf.length > 0) {
                   jf[0].update(j)
               } else {
                   ring.a_judges.push(new Judge(j))
               }
            });
            window.APP.reset();
        })
        .listen('TournamentRingDivisionRemove', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            div.ring = null;
            this.un_divs.push(div);
            
            ring.divisions = ring.divisions.filter(function(value, index, arr){ return value.id != e.division_id });
            
            
            window.APP.reset();
        })
        .listen('TournamentStandingUpdate', (e) => {
            let div = this.findDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) return;
                
                team.name = e.team_name;
                team.seeding = e.comp.seeding;
                
                let comp = team.getMember(e.standing_id);
                if(!comp) return;
                comp.update(e.comp);  
                div.checkTeams();
            } else {
                let comp = div.getComp(e.standing_id)
                if(!comp) return;
                comp.update(e.comp);  
            }
            window.APP.reset();
        })
       .listen('TournamentRingDivisionAdd', (e) => {
            
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            var d = this.findAndRemoveDivision(e.division_id);
            if(d) {
                ring.divisions.push(d);
                d.ring = ring;
            } else {
                postMaster.toSend("/tournament-ring/" + ring.id + '/division/' + e.division_id,
                function(p) {
                    p.then(res => res.json())
                    .then((result) => {
                         let div = new Division(result.id);
                         div.load(result);
                         ring.divisions.push(div);
                         div.ring = ring;
                         window.APP.reset();
                     })
                });
            }
            window.APP.reset();
        }).listen('AlertForward', (e) => {
            if(e.item_type == 'Ring') { 
                var ring = this.getRing(e.item_id)
                if(!ring) return;
                ring.alertsManager.receiveEvent(e);
            }
           
            window.APP.reset();
        })
        .listen('TournamentFightCall', (e) => {       
            var ring = this.getRing(e.ring_id)
            if(!ring) return;      
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            let f = div.getFight(e.fight_id)
            if(!f) return;
    
    
            let acall = ring.getAVCalls().find(element => element.id == e.call.call_id);
            
            let comp = null;
            
            if(e.call.person_id == f.comp1.id) {
               comp = f.comp1;
            }  else {
                comp = f.comp2;
            }
            
            let call = new FightCall({
                id: e.call.id,
                opposing_points: e.call.opposing_points,
                points: e.call.points,
                dq: e.call.dq,
                warning: e.call.warning,
                person: comp,
                availableCall: acall,
                winner: e.call.winner,
                time_change: e.call.time_change,
                end_round: e.call.end_round
            });
    
    
            f.receiveCall(call)
        })
        
        .listen('TournamentFightTick', (e) => { return;
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            let f = div.getFight(e.fight_id)
            if(!f) return;
            
            if(e.running == 'sync' || e.tick == 'sync') {
                if(f.timer.master && (f.timer.left != 1000 || f.timer.running  )) {
                    f.timer.update();
                }
                return;
            }
            
            f.timer.master = false;
            f.timer.sync(e.running, e.tick );
            
            if(e.round) {
                f.current_round = e.round
            }
            
            if(e.break) {
                f.on_break = e.break
            }
            
            window.APP.reset();
        })
        
        .listen('TournamentFightSwapSides', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            let f = div.getFight(e.fight_id)
            f.swapped = parseInt(e.value);
        })
        
        .listen('TournamentRingCurrentFight', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = this.findDivision(e.division_id);
            if(!div) return;
            
            div.fights
                    .filter(x=>{return x.in_ring_name == e.ring_name;})
                    .forEach(x=>{x.in_ring_name = ''; });
            
            if(div.ring.id != e.ring_id) {     
                let f = div.getFight(e.fight_id);
                if(f) {
                    f.in_ring_name = e.ring_name;
                }
            } else {
                let f = div.getFight(e.fight_id);
                div.ring.current_fight = f;                
                if(f) {
                    f.in_ring_name = e.ring_name;
                }
            }
            
            window.APP.reset();
        })
        
        .listen('TournamentDivisionNewBrackets', (e) => {
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            div.fights = [];
            div.shuffled = 1;
            if(e.top_fights) {
                e.top_fights.forEach((t)=>{ 
                    div.addFight(t);
                });
            }
            window.APP.reset();
        })
        
        .listen('TournamentDivisionFightSwap', (e) => {  
            var ring = this.getRing(e.ring_id)
            if(!ring) return;
            
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            let f1 = div.getFight(e.one);
            let f2 = div.getFight(e.two);
            if(f1 == null || f2 == null) {
                return;
            }
            swapFights(f1, f2);
            window.APP.reset();
        })
        
        
        
        .listen('TournamentDivisionImportRequest', (e) => {
            var ring = this.getRing(e.ring_id);
    
            
    
            if(e.division_id == '' || !e.division_id) { 
                if(ring.ring_import_request != null) {
                    let div = this.findDivision(ring.ring_import_request.uuid);
                    div.ring_import_requests = div.ring_import_requests.filter(x=>x.uuid != e.ring_id);
                }
                
                ring.ring_import_request = null;
            } else {            
                let div = this.findDivision(e.division_id);
                
                div.ring_import_requests.push({
                    uuid:ring.id,
                    name:ring.name,
                });
                
                ring.ring_import_request = {
                    uuid:e.division_id,
                    code:e.division_code,
                    name:e.division_name,
                };
            }
           window.APP.reset();
        })
        
        .listen('TournamentDivisionImported', (e) => { 
            var ring = this.getRing(e.ring_id);
        
            if(e.division_id) {
                let div = this.findDivision(e.division_id);
                ring.ring_import = div;
                ring.ring_import_request = null;
                
                div.ring_imported.push({
                    uuid:ring.id,
                    name:ring.name,
                });
                div.ring_import_requests = div.ring_import_requests.filter(x=>x.uuid != e.ring_id);
                
                window.APP.reset();                    
            } else {
                let div = ring.ring_import;
                if(div) {
                    div.ring_imported = div.ring_imported.filter(x=>x.uuid != e.ring_id);
                }
                
                ring.ring_import = null;
                ring.ring_import_request = null;
                window.APP.reset();
            }
        })
        ;
        /*
        $j["ring_imported"] = [];        
        foreach($division->importedRings as $i_ring ) {
            $j["ring_imported"][] = [
                'uuid' =>   $i_ring->uuid,
                'name' =>   $i_ring->name,
            ];
        }
        
        $j["ring_import_requests"] = [];        
        foreach($division->importRingRequests as $i_ring ) {
            $j["ring_import_requests"][] = [
                'uuid' =>   $i_ring->uuid,
                'name' =>   $i_ring->name,
            ];
        }
         */
        this.timer_special_value = 120;
        this.updateSpecialTimerValue = this.updateSpecialTimerValue.bind(this);
    }
    
    
    ringMovementNotificationsClick() {
        this.send_ring_notifications = !this.send_ring_notifications;
        this.setState({send_ring_notifications: this.send_ring_notifications });
    }
    
    updateSpecialTimerValue(e) {
        this.timer_special_value = parseInt(e.target.value);
        this.setState({timer_special_value:this.timer_special_value});
    }
    
    getUnallDiv(id) {
        return this.un_divs.find(element => element.id == id);
    }
    
    findDivision(id) {
        var d = this.getUnallDiv(id);
        if(d) {
            return d;
        }
        this.rings.forEach(element => {
            var k = element.getDivision(id);
            if(k) {
                d = k;
            }
        });
        
        return d;
    }
    
    findAndRemoveDivision(id) {
        var d = this.getUnallDiv(id);
        if(d) {
            this.un_divs = this.un_divs.filter(function(value, index, arr){ return value.id != id });
            return d;
        }
        
        var r = null;
        this.rings.forEach(element => {
            var k = element.getDivision(id);
            if(k) {
                r = element;
                d = k;
            }
        });
        
        if(r) {
            r.divisions = r.divisions.filter(function(value, index, arr){ return value.id != id });
        }
        return d;
    }
    
    
    getRing(id) {
       return this.rings.find(element => element.id == id);
    }
    
    getSchedule(id) {
        return this.schedules.find(element => element.id == id);
    }
    
    reload() {
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        window.APP.needs_reload = 0;
        this.load();
        
        window.APP.reload();
    }
    
    load() {
        var inter = this;
        postMaster.immediateGet("/tournament/" + this.tournament_id + '/coordinator_info').then(res => res.json())
       .then(
          (result) => {
                window.APP.setTimeOption('timeZone',result.timeZone);
                if(result.uses_kg) {
                    window.APP.useKg(result.uses_kg);
                }
                inter.image = result.image;
                inter.judge_check_in = result.judge_check_in;
                inter.send_movement_notifications = parseInt(result.send_movement_notifications) > 0;
                if(!inter.send_movement_notifications) {
                    inter.send_ring_notifications = false;
                }
                inter.name = result.name;
                inter.rings = [];
                result.rings.forEach(element => { 
                    let ring = new Ring(element.id);
                    ring.load(element);
                    inter.rings.push(ring);
                    ring.judge_check_in = result.judge_check_in;
                });
                
                inter.dates = [];
                inter.days = [];
                
                var today_found = false;
                var today = new Date();
                
                inter.rings.forEach(r => { 
                    r.divisions.forEach(di => { 
                        if(di.estimated_start !=null && di.estimated_start.getDate() != 'NaN' && !inter.dates.includes(di.estimated_start.getDate() ) ) {
                            inter.dates.push(di.estimated_start.getDate() );
                            inter.days.push( {date:di.estimated_start.getDate(),day:di.estimated_start.getDay()} );
                            
                            if(di.estimated_start.getDate() == today.getDate() && di.estimated_start.getMonth() == today.getMonth() && di.estimated_start.getYear() == today.getYear() ) {
                                today_found = true;
                            }                            
                        }
                    });                    
                });
                
                
                inter.dates.sort();
                if(inter.first_load && today_found ) {
                    inter.date_filter = today.getDate();
                    console.log(inter.date_filter);
                }
                inter.first_load = false;
                
                if(inter.rings.length == 0) {
                    inter.setType('divisions')
                }
                
                inter.un_divs = [];
                result.un_allocated_divisions.forEach(element => { 
                    let div = new Division(element.id);
                    div.load(element);
                    inter.un_divs.push(div)
                });
                
                inter.rings.forEach(r => { 
                    if(r.ring_import_uuid) {
                        r.ring_import = this.findDivision(r.ring_import_uuid);
                    }
                
                });
                
                this.schedules = result.sessions;
                                
                this.schedules.forEach( (value, index, self2) => { 
                     value.time = new Date(value.time);
                });
                
                document.getElementById("loading_area").style.display = "none";
                window.APP.onReload();
                window.APP.reset();
          }
        );
        
    }
    
    toggleCardView() {
        this.card_view = !this.card_view;
        this.setState({card_view:this.card_view} );
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    timerModalOpen() {
        this.timerModal = true;
        this.setState({timerModal:true});
    }

    timerModalClose() {
        this.timerModal = false;
        this.setState({timerModal:false});
    }
    
    stopwatchModalOpen() {
        this.stopwatchModal = true;
        this.setState({stopwatchModal:true});
    }

    stopwatchModalClose() {
        this.stopwatchModal = false;
        this.setState({stopwatchModal:false});
    }
    
  
    displayModalOpen() {
        this.displayModal = true;
        this.setState({displayModal:true});
    }

    displayModalClose() {
        this.displayModal = false;
        this.setState({displayModal:false});
    }

    settingsModalOpen() {
        this.compAddSearchState = '';
        this.compAddSearch = [];
    
        this.settingsModal = true;
        this.setState({settingsModal:true});
    }

    settingsModalClose() {
        this.settingsModal = false;
        this.setState({settingsModal:false});
    }
   
   componentWillUnmount(){
        window.APP.unRegisterResize(this);
   }
   
   componentDidMount(){
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
        this.headerRow.clientHeight);

        window.APP.registerResize(this);
   }
   
   resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
   }
   
   componentWillUnmount(){
        
   }
   
   setType(t) {
        this.type = t;
        this.ring_id = null;
        this.division_id = null;
        this.judge_id = null;
        
        this.setState({type:t,ring_id:null, division_id:null, judge_id:null });
    }
    
    setRing(id) {
        this.ring_id = id;
        this.division_id = null;
        this.setState({ring_id:id, division_id: null });
    }
    
    clearRing() {
        this.ring_id = null;
        this.ring = null;
        this.setState({ ring_id:null, ring:null });
    }
    
    setDivision(id) {
        this.division_id = id
        this.setState({ division_id:id });
    }
    
    clearDivision() {
        this.division_id = null;
        this.division = null;
        this.setState({ division_id:null,division:null });
    }
    
    setJudge(id) {
        this.judge_id = id
        this.setState({ judge_id:id });
    }
    
    clearJudge() {
        this.judge_id = null
        this.setState({ judge_id:null });
    }
    
    allDivisions() {
        var divs = this.un_divs;        
        this.rings.forEach(element => { 
            divs = divs.concat(element.divisions);
        });
        return divs;
    }
    
    getManager() {
        
        if(this.judge_id != null) {
            var judge = null;
            return <CoordinatorJudgeDisplay key={this.judge_id} judge={judge} interface={this}></CoordinatorJudgeDisplay>;
        }
        
        if(this.division_id != null) {
            if(this.division && this.division.id == this.division_id) {
            } else {
                this.division = this.findDivision(this.division_id);
            }
            return <CoordinatorDivisionDisplay key={this.division.id} division={this.division} interface={this}></CoordinatorDivisionDisplay>; 
        }
        
        if(this.ring_id != null) {
            if(this.ring && this.ring.id == this.ring_id) {
            } else {
                this.ring = this.getRing(this.ring_id);
            }
            return <CoordinatorRingDisplay key={this.ring.id} ring={this.ring} interface={this}></CoordinatorRingDisplay>;
        }
        
        if(this.type == 'incidentcenter') {
            return <IncidentCenter interface={this}></IncidentCenter>
        }
        
        if(this.type == 'rings') {
           return <CoordinatorRingManagerDisplay interface={this}></CoordinatorRingManagerDisplay>;
        }
        
        if(this.type == 'divisions') {
            return <CoordinatorDivisionsManagerDisplay interface={this}></CoordinatorDivisionsManagerDisplay>;
        }
        
        if(this.type == 'judges') {
            return <CoordinatorJudgeManagerDisplay interface={this}></CoordinatorJudgeManagerDisplay>;
        }
        
        if(this.type == 'comps') {
            return <CoordinatorCompManagerDisplay interface={this}></CoordinatorCompManagerDisplay>
        }
        
        if(this.type == 'scheduler') {
           // return <CoordinatorScheduler interface={this}></CoordinatorScheduler>
        }
        
        if(this.type == 'staffscheduler') {
            return <StaffScheduler interface={this}></StaffScheduler>
        }
        
        if(this.type == 'division_assigner') {
            return <CoordinatorDivisionsAssignerDisplay interface={this}></CoordinatorDivisionsAssignerDisplay>;
        }
        
        return <div></div>;
    }
    
   render() {
        let showSidebar = this.messages;

        var manager = this.getManager();
        let holder = this;
        


        return (
           <Container>
     <Row>
        <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
            <Navbar bg="dark" expand="lg" variant="dark">
            { this.image && 
                <Navbar.Brand href="#home"><img
                src={this.image}
                className="tournamentlogo"
                alt="tournament logo"
              /></Navbar.Brand> }
            <Navbar.Brand href="#home" className="tagname">Coordinator</Navbar.Brand>
                <Navbar.Toggle aria-controls="tools-navbar-nav" />   
                <Navbar.Collapse id="tools-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => this.displayModalOpen()} >Menu <i class="text-info fas fa-bars"></i></Nav.Link>
                    <NavDropdown title="Tools" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={this.timerModalOpen}>Timer</NavDropdown.Item>
                          <NavDropdown.Item onClick={this.stopwatchModalOpen}>Stopwatch</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link onClick={() => this.settingsModalOpen()} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
                </Nav>
                </Navbar.Collapse>
                <Nav>
                <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
                </Nav>
            </Navbar>

            <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table responsive>
                <tbody>
                <tr><th>Card View</th><td><Form.Check 
                type='checkbox' checked={ (this.card_view) ?'checked' : '' } onChange={this.toggleCardView}
                /></td></tr>
                
                        </tbody>        
                </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal size="lg" show={this.stopwatchModal} onHide={() => this.stopwatchModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Stopwatch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px', fontSize:'4em' }}>
                    {this.stopwatch.getDisplay()}</Col></Row>
                <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <ButtonGroup className="mr-2" aria-label="First group">
                    {!this.stopwatch.running && <Button varient='primary' onClick={()=>this.stopwatch.start()} ><i class="fas fa-play"></i></Button>}
                    {this.stopwatch.running && <Button variant="warning" onClick={()=>this.stopwatch.stop()}><i class="fas fa-pause"></i></Button> }
                    {!this.stopwatch.running && <Button variant="danger" onClick={()=>this.stopwatch.reset()}><i class="fas fa-sync"></i></Button>}
                    </ButtonGroup></Col></Row>
                    <Row>
                    <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                        <ButtonGroup className="mr-2" aria-label="First group" >
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-10) }>-10</Button>
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-5) }>-5</Button>
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-1) }>-1</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(1) }>+1</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(5) }>+5</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(10) }>+10</Button>
                        </ButtonGroup>
                    </Col>
                    </Row>
                    {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <h2>Displays</h2>
                    <Table responsive>
                        {
                        this.context.window_manager.insides.map((w, index) =>
                        <tr><td>Display {(index + 1)}</td>
                            <td>
                                {w.mode != 'stopwatch' && <Button variant="success" onClick={(e) => { w.setMode("stopwatch");window.APP.reset(); }}>Set to StopWatch</Button>}
                                {w.mode == 'stopwatch' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                            </td>
                        </tr> 
                        )
                        }
                        </Table></Col>
                    </Row>}
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.stopwatchModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
        
            <Modal size="lg" show={this.timerModal} onHide={() => this.timerModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Timer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'30px', fontSize:'4em' }}>
                    {this.timer.getDisplay()}</Col></Row>
                <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <ButtonGroup className="mr-2" aria-label="First group">
                    {!this.timer.running && <Button varient='primary' onClick={()=>this.timer.start()} ><i class="fas fa-play"></i></Button>}
                    {this.timer.running && <Button variant="warning" onClick={()=>this.timer.stop()}><i class="fas fa-pause"></i></Button> }
                    {!this.timer.running && <Button variant="danger" onClick={()=>this.timer.reset()}><i class="fas fa-sync"></i></Button>}
                    </ButtonGroup></Col></Row>
                    <Row>
                    <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                        <ButtonGroup className="mr-2" aria-label="First group" >
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-10) }>-10</Button>
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-5) }>-5</Button>
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-1) }>-1</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(1) }>+1</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(5) }>+5</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(10) }>+10</Button>
                        </ButtonGroup>
                    </Col>
                    </Row>

                    {!this.timer.running && <Row>
                        <Col xs={5} style={{ textAlign:'center',marginTop:'10px' }}>
                            Timer Value (seconds)
                        </Col>
                        <Col xs={4} style={{ textAlign:'center',marginTop:'10px' }}>
                            <Form.Control type="text"  onChange={this.updateSpecialTimerValue} value={this.timer_special_value}  />
                        </Col>
                        <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}>
                            <Button variant="danger" onClick={()=>{ this.timer.setTimer(this.timer_special_value); this.timer.reset()}}>Set</Button>
                        </Col>
                    </Row>}
                    {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <h2>Displays</h2>
                    <Table responsive>
                        {
                        this.context.window_manager.insides.map((w, index) =>
                        <tr><td>Display {(index + 1)}</td>
                            <td>
                                {w.mode != 'timer' && <Button variant="success" onClick={(e) => { w.setMode("timer");window.APP.reset(); }}>Set to Timer</Button>}
                                {w.mode == 'timer' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                            </td>
                        </tr> 
                        )
                        }
                    </Table></Col>
                    </Row>}
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.timerModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal size="lg" show={this.displayModal} onHide={() => this.displayModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Menu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                <Row>
                <Table responsive>
                    <tbody>
                        <tr><th>Incident Center</th><td><Button onClick={ ()=>{ this.setType('incidentcenter'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Ring Manager</th><td><Button onClick={ ()=>{ this.setType('rings'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Division Manager</th><td><Button onClick={ ()=>{ this.setType('divisions'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Division Assigner</th><td><Button onClick={ ()=>{ this.setType('division_assigner'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Staff Scheduler</th><td><Button onClick={ ()=>{ this.setType('staffscheduler'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr style={{ display:'none' }}><th>Scheduler</th><td><Button onClick={ ()=>{ this.setType('scheduler'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Judge Manager</th><td><Button onClick={ ()=>{ this.setType('judges'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                        <tr><th>Competitor Search</th><td><Button onClick={ ()=>{ this.setType('comps'); this.displayModalClose(); } } variant="primary">Go</Button></td></tr>
                    </tbody>        
                </Table>
                </Row>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.displayModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>

        </Col>   
        <Col className={(showSidebar) ? "d-none d-md-block" : ''}  xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'60px'  }}>
            {window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

              {window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

                {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Internet was lost - data may be outdated - {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}
                
                {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    When you have a moment please redownload data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                </Alert>}

                {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Please refresh at the nearest opportunity <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                </Alert>}
            {manager}
        </Col>
        { showSidebar && 
        <Col xs={12} md={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'60px' }}>

            <Container>
                { this.messages && 
                <Row>
                    <Col xs={12}>

                    <Card  bg='dark' text='light' >
                    <Card.Header >Messages
                    <div style={{float:'right'}}>
                    <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                                </div>
                                </Card.Header>
                    <Card.Body style={{ color:'black' }} >
                        <MessagesDisplay></MessagesDisplay>  
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>}

            </Container>

        </Col>
        }

    </Row>


            <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">

              <Nav className="mr-auto">
              <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
              
              
              {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
          </Nav>}
          
          <Nav style={{ flexDirection:'row'}}>
            {postMaster.queue.length == 0 && postMaster.onMessage == null && window.APP.connection_status > 0 && window.pusher_status == 1 && <Nav.Link  onClick={() => { holder.reload(); } } ><span class={ (window.APP.connection_status == 1 ) ? 'text-primary':'text-secondary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Click To Download Data
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-download-alt"></i></OverlayTrigger></span></Nav.Link>}
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
          
              
              {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
                    
                    {window.APP.canFullscreen() && !window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.goFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Go Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-expand"></i></OverlayTrigger></span></Nav.Link>}
                
                    {window.APP.canFullscreen() && window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.exitFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Exit Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-compress"></i></OverlayTrigger></span></Nav.Link>}
                    
                <Navbar.Brand href="#home">
          <img
            src={ AppDomain + '/pass_M_Logo.png' }
            height="30"
            alt="MAT Action logo"
          />
        </Navbar.Brand>
              </Nav>
          </Navbar>
    </Container>
    );
  }
}

class CoordinatorRingManagerDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this);
        this.locationFilterUpdate = this.locationFilterUpdate.bind(this);
        this.groupFilterUpdate = this.groupFilterUpdate.bind(this);        
        this.onDragEnd = this.onDragEnd.bind(this);
        this.ringOrderUpdate = this.ringOrderUpdate.bind(this);        
        
        this.ring_order = this.interface.ring_order;
        
        this.name_filter = this.interface.name_filter;
        this.location_filter = this.interface.location_filter;
        this.group_filter = this.interface.group_filter;
        this.date_filter = this.interface.date_filter;
        this.session_filter = this.interface.session_filter;
        this.dateFilterUpdate = this.dateFilterUpdate.bind(this);
        this.sessionFilterUpdate = this.sessionFilterUpdate.bind(this);
        
        this.dragAndDropClick = this.dragAndDropClick.bind(this);
        
        this.show_filters = false;
        
        this.t_s_ring = null;
        this.t_2_ring = null;
        this.t_search = '';
        
        this.transfer_divisions_dnd = false;
        
        this.ringJudgeSelected = null;
    }
    
    
    
    toggleShowFilters() {
        this.show_filters = !this.show_filters
        this.setState({show_filters: this.show_filters });
    }
    
    clearFilters() {
        this.name_filter = '';
        this.location_filter = '';
        this.group_filter = '';
        this.setState({name_filter:'', location_filter:'',group_filter:'' });
    }
    
    dateFilterUpdate(event) {
        this.setState({date_filter: event.target.value });
        this.date_filter =  event.target.value;
        this.interface.date_filter =  event.target.value;
    }
    
    sessionFilterUpdate(event) {
        this.setState({session_filter: event.target.value });
        this.session_filter =  event.target.value;
        this.interface.session_filter =  event.target.value;
    }
    
    nameFilterUpdate(event) {
        this.setState({name_filter: event.target.value.toLowerCase() });
        this.name_filter =  event.target.value.toLowerCase();
        this.interface.name_filter =  event.target.value.toLowerCase();
    }
    
    locationFilterUpdate(event) {
        this.setState({name_filter: event.target.value });
        this.location_filter =  event.target.value;
        this.interface.location_filter =  event.target.value;
    }
    
    groupFilterUpdate(event) {
        this.setState({group_filter: event.target.value });
        this.group_filter =  event.target.value;
        this.interface.group_filter =  event.target.value;
    }
    
    ringOrderUpdate(event) {
        this.ring_order =  event.target.value;
        this.interface.ring_order =  event.target.value;
        this.setState({ring_order: event.target.value });
    }

    rings() {
        var rings = this.interface.rings;
        var t=this;
        if(this.name_filter.localeCompare('') != 0) {
                const name_filter =this.name_filter.toLowerCase(); 
                rings = rings.filter(function(value, index, arr){ 
                    return value.name.toLowerCase().indexOf(name_filter) >=0;
               });
           }
           
        if(this.location_filter.localeCompare('') != 0) {
             const name_filter =this.location_filter.toLowerCase(); 
             rings = rings.filter(function(value, index, arr){ 
                 return value.location.toLowerCase().indexOf(name_filter) >=0;
            });
        }
        
        if(this.group_filter.localeCompare('') != 0) {
            const name_filter =this.group_filter; 
            rings = rings.filter(function(value, index, arr){ 
                return value.groups.indexOf(name_filter) >= 0;
            });
        }
        
        if(this.ring_order == 'ordered') {
            rings = rings.sort((a, b) => {
                if(a.ordered != b.ordered) {
                    return a.ordered - b.ordered;
                }
                return a.name.localeCompare(b.name);
            });
        }
        
        if(this.ring_order == 'endtime') {
            rings = rings.sort((a, b) => {
                a = a.getMaxDivisionTime('',t.date_filter, t.session_filter);
                b = b.getMaxDivisionTime('',t.date_filter, t.session_filter);
                return b - a;
            });
        }
        
        if(this.ring_order == 'ipdivisions') {
            
            rings = rings.sort((a, b) => {
                a = a.divisions.filter(element=>{ return (t.date_filter == 'All' || t.date_filter == element.estimated_start.getDate() ) &&  (t.session_filter == element.schedule_id || t.session_filter == 'All') && element.status < 3 } ).length
                b = b.divisions.filter(element=>{ return (t.date_filter == 'All' || t.date_filter == element.estimated_start.getDate() ) &&  (t.session_filter == element.schedule_id || t.session_filter == 'All') && element.status < 3 } ).length
                return b - a;
            });
        }
        
        if(this.ring_order == 'endtime2') {
            rings = rings.sort((a, b) => {
                a = a.getMaxDivisionTime('',t.date_filter, t.session_filter);
                b = b.getMaxDivisionTime('',t.date_filter, t.session_filter);
                return a - b;
            });
        }
        
        if(this.ring_order == 'ipdivisions2') {
            rings = rings.sort((a, b) => {
                a = a.divisions.filter(element=>{ return (t.date_filter == 'All' || t.date_filter == element.estimated_start.getDate() ) &&  (t.session_filter == element.schedule_id || t.session_filter == 'All') && element.status < 3 } ).length
                b = b.divisions.filter(element=>{ return (t.date_filter == 'All' || t.date_filter == element.estimated_start.getDate() ) &&  (t.session_filter == element.schedule_id || t.session_filter == 'All') && element.status < 3 } ).length
                return a - b;
            });
        }
        
        return rings;
    }

    locations() {
        var rings = this.interface.rings;
        var locations = [];
        
        rings.forEach(element => { 
            if(element.location != '' && locations.indexOf(element.location) < 0 ) {
                locations.push(element.location)
            }
        });
        return locations;
    }
    
    dragAndDropClick() {
        this.transfer_divisions_dnd = !this.transfer_divisions_dnd;
        this.setState({transfer_divisions_dnd: this.transfer_divisions_dnd });
    }
    
    
    
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        
        var t = this;
        var ring_id = this.t_s_ring.id;
        var divs = this.t_s_ring.getSchedule(48);
            
        if(result.destination.droppableId == 'area2' || result.destination.droppableId == 'un_assiigned' ) { 
            ring_id = (this.t_2_ring) ? this.t_2_ring.id : 'none';
            if(this.t_2_ring) {
                divs = this.t_2_ring.getSchedule(48);
            }
        }

        
        divs = divs.filter(function(value, index, arr){ 
            return value.status < 3;
        });   
        
            
        var datas = sessionFormat(divs,  this.interface.schedules);


        var active_session = null;
        var found_division = null;
        var found = false;

        var index = 0;

        datas.forEach( (v, k, self2) => {
            if(found) return;

            if(v.type == 'division') { 
                if(result.destination.index == index) {
                    found_division = v.value;
                    found = true;
                    return;
                }
                if(v.value.id != result.draggableId) {
                    index = index + 1; 
                }
            } else {
                
                //if it is before the session - go before first in session
                if(result.destination.index == index) {                            
                    if(v.children.length > 0) {
                        found_division = v.children[0];
                    }
                    found = true;
                    return;
                }

                index = index + 1;
                active_session = v.value;

                //check if it is a sub-division
                v.children.forEach( (divv, l, self22) => { 
                    if(found) return;
                    if(result.destination.index == index) {
                        found_division = divv.value;
                        found = true;
                        return;
                    }
                    if(divv.value.id != result.draggableId) {
                        index = index + 1; 
                    }
                });

                if(found) return;


                //if it is replacing in the session                        
                if(result.destination.index == index) {
                    found = true;
                    return;
                }
                active_session = null;
                index = index + 1; 

            }
        });

        var session_id = 'NA';
        var division_spot_id = 'NA';

        var confirmtext = '';

        if(active_session) {
            session_id = active_session.id;
            confirmtext = 'Assign to ' + active_session.name;
        }

        if(found_division) {
            division_spot_id = found_division.id;
            confirmtext = 'Assign to before ' + found_division.name;
        }
        
        if(active_session && found_division) {
           confirmtext = 'Assign to ' + active_session.name + ' before ' + found_division.name;
        }
        
        if(!active_session && !found_division) {
           confirmtext = 'Assign to ring without specification';
        }
        
        if(confirmtext != '') {
            if(!window.confirm(confirmtext)) {
                return;
            }
        }
        

        postMaster.toSend('/tournament-division/' + result.draggableId +'/action/assign?ring=' + ring_id + '&session=' + session_id + '&division_spot_id=' + division_spot_id + '&send_notification='+this.interface.send_ring_notifications);


    }
    
    groups() {
        var rings = this.interface.rings;
        var groups = [];
        
        rings.forEach(element => { 
            element.groups.forEach(group => { 
                if( groups.indexOf(group) < 0 ) {
                    groups.push(group)
                }
            });           
        } );
        return groups;
    }
    
    setTransferMainRing(ring) {
        this.t_s_ring = ring;
        this.setState({t_s_ring:this.t_s_ring});
    }

    summary() {
        var rings = this.rings();
        var svalue = 0;
        var ipvalue = 0;
        var total = 0;
        
        var svaluep = 0;
        var ipvaluep = 0;
        
        var date_filter = this.date_filter;
        var session_filter = this.session_filter;
        
        rings.forEach(element => { 
            svalue = svalue + element.getCompletedDivisions(date_filter, session_filter).length ;    
            ipvalue = ipvalue + element.getIPDivisions(date_filter, session_filter).length;
            total = total + element.getAllDivisions(date_filter, session_filter).length;
        });
        
        if(total > 0) {
            svaluep = svalue / total;
            ipvaluep = ipvalue / total;
        }
        
        svaluep = svaluep * 100;
        ipvaluep = ipvaluep * 100;
        return <div>                
                
                <Container>
                <Row>
                <Col md='3'>Left <span>{( total - (ipvalue + svalue) ) }</span></Col>
                <Col md='3'>Running <span class='text-primary'>{ipvalue}</span></Col>
                <Col md='3'>Completed <span class='text-success'>{svalue}</span></Col>
                <Col md='3'>Total <span>{total}</span></Col>
                                            </Row>
                </Container>
                {total > 0 &&  <ProgressBar style={{ height:'32px' }}>
                    <ProgressBar label={svalue} variant={'success'} now={svaluep} />
                    <ProgressBar label={ipvalue} variant={'primary'} now={ipvaluep} />
                </ProgressBar>}
               </div>;
    
    }

    render() { 
        
        var rings = this.rings();
        var main = <div></div>;
        var man = this.interface;
        this.date_filter = this.interface.date_filter;
        var date_filter= this.date_filter;
        var session_filter = this.session_filter;
        
        if(rings.length > 0) {
            if(this.interface.card_view) {
                const ringsList = rings.map((ring ) => { 
                    const left = ring.divisions.filter(element=>{ return  (date_filter == 'All' || date_filter == element.estimated_start.getDate() ) && (session_filter == element.schedule_id || session_filter == 'All' )  && element.status < 3 } ).length;
                    const time = ring.getMaxDivisionTime('',date_filter, session_filter);
                    
                    var bcolor = 'light';
                    
                    
                    if(ring.status == -1) {
                        bcolor = 'secondary';
                    }
                    
                    if(ring.status == 1) {
                        bcolor = 'warning';
                    }
                    
                    if(ring.status == 2) {
                        bcolor = 'danger';
                    }
                    
                    return <Col  xs={12} sm="6" md="4" lg="4">
                        <Card border={bcolor} bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }} 
                            onMouseUp={() => {
                                this.is_held =false;
                                return false;
                            }}
                            
                            onMouseDown={() => { 
                                this.is_held =true;
                                var t = this;
                                    // Set timeout
                                    window.setTimeout(function() {
                                        if(t.is_held) {
                                            t.setTransferMainRing(ring);
                                        }                                        
                                    },1000);
                                    return false; 
                                  } }
                             
                             
                             onContextMenu={(e)=>{ this.setTransferMainRing(ring); e.preventDefault(); return false; } }>
                            <Card.Header><div style={{ marginBottom:'10px', overflow: 'hidden'}}>
                                    <div style={{ float:'left' }}>{ring.icon() }</div>{ring.name}<div style={{ float:'right' }}><ButtonGroup>
                                    <Button onClick={()=>{ this.ringJudgeSelected =ring; this.setState({ringJudgeSelected:ring}); } } variant='secondary'><i class="fas fa-gavel"></i></Button>
                                    <Button onClick={()=>{ this.setTransferMainRing(ring); } } variant='secondary'><i class="fas fa-calendar"></i></Button>
                                    <Button onClick={()=>{ man.setRing(ring.id) } } variant='primary'><i class="fas fa-share"></i></Button>
                                </ButtonGroup></div>
                                        </div>{ ring.progressBar(false, date_filter, session_filter) }
                                     </Card.Header>
                                     <Card.Body>
                                <Container>
                                    {!ring.ring_import && <Row>
                                    <Col md='12' style={{ fontSize:'0.7em' }}>
                                        {ring.statusIcon()}
                                    </Col>
                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                            { left > 0 && <span>{time != null && <span>{time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span> } | {left} divs left</span> } 
                                            { left == 0 && <span>Complete</span> } 
                                        </Col>

                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                        {ring.location}{ ring.location == '' && <span>&nbsp;</span>}
                                        </Col>

                                         { ring.current_division && <Col md='12' style={{ height:'1px',backgroundColor:'#FFFFFF' }} ></Col>}


                                        <Col md='12' >
                                        { ring.current_division && ring.current_division.codePop() }{ !ring.current_division && <span>&nbsp;</span>}
                                        </Col>

                                        <Col md='12' style={{marginTop:'15px'}} >
                                            {ring.current_division && ring.current_division.progressBar()}
                                        </Col>
                                    </Row>}
                                    
                                    {ring.ring_import && <Row>
                                    <Col md='12' style={{ fontSize:'0.7em' }}>
                                        {ring.statusIcon()}
                                    </Col>
                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                            { left > 0 && <span>{time != null && <span>{time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span> } | {left} divs left</span> } 
                                            { left == 0 && <span>Complete</span> } 
                                        </Col>

                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                        {ring.location}{ ring.location == '' && <span>&nbsp;</span>}
                                        </Col>

                                         { ring.ring_import && <Col md='12' style={{ height:'1px',backgroundColor:'#FFFFFF' }} ></Col>}


                                        <Col md='12' >
                                        Imported { ring.ring_import && ring.ring_import.codePop() }{ !ring.ring_import && <span>&nbsp;</span>}
                                        </Col>

                                        <Col md='12' style={{marginTop:'15px'}} >
                                            {ring.ring_import && ring.ring_import.progressBar()}
                                        </Col>
                                    </Row>}
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>;
                });
                main = <Row  style={{ textAlign:'center' }}>
                        {ringsList}
                    </Row>  
            } else {
                const ringsList = rings.map((ring ) => {
                    const left = ring.divisions.filter(element=>{ return (date_filter == 'All' || date_filter == element.estimated_start.getDate() ) &&  (session_filter == element.schedule_id || session_filter == 'All') && element.status < 3 } ).length;
                    const time = ring.getMaxDivisionTime('',date_filter, session_filter);
                    return <tr><td>{ring.icon()}</td><td>{ring.name}</td><td>{ring.statusIcon()}</td><td>{ring.current_division && ring.current_division.niceStatus() } { ring.progressBar(false, date_filter, session_filter) }</td><td>{ left > 0 && <span>{time != null && <span>{time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span> } | {left} divs left</span> }</td><td>{ ring.current_division && ring.current_division.codePop() }</td><td>{ring.location}</td><td><Button onClick={()=>{ man.setRing(ring.id) } } variant='primary'><i class="fas fa-share"></i></Button></td></tr>;
                });
                main = <Table responsive variant="dark">
                    <tbody>
                        {ringsList}
                    </tbody>        
                </Table>
            }
        } else {
            main =  <Row  style={{ textAlign:'center' }}><Col md={12}>There are currently no rings created</Col></Row>
        }
        
        var modal = <div></div>
         if (this.t_s_ring) {
             
            var divs = this.t_s_ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); 
            var data = sessionFormat(divs,  this.interface.schedules);
             
            var divs_area_parts = [];

            data.forEach( (v, index, self2) => { 
            var item = v.value;
            if(v.type == 'division') {                                        
                divs_area_parts.push(<Draggable isDragDisabled={v.value.status != 0 || !this.transfer_divisions_dnd} key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <Container>
                              <Row>
                                  <Col md='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                       </Col>
                                       <Col md='3' style={{ fontSize:'0.7em' }}>
                                           { item.codePop() }                                                
                                       </Col>
                                       <Col md='4' style={{ fontSize:'0.7em' }}>
                                            {item.name}
                                       </Col>

                                       <Col md='1' style={{ fontSize:'0.7em' }}>
                                          {item.standings.length }
                                       </Col>
                                       <Col md='2' style={{ fontSize:'0.7em' }}>
                                            <div>{ item.printDurationEstimate() }</div>
                                            <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                       </Col>
                              </Row>
                          </Container>
                    </div>
                  )}
                </Draggable>);
                } else {
                        divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);

                        v.children.forEach( (divv, index2, self22) => { 
                            var div = divv.value;
                            divs_area_parts.push(<Draggable isDragDisabled={divv.value.status != 0 || !this.transfer_divisions_dnd} key={div.id} draggableId={div.id} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                </Col>
                                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                                    { div.codePop() }                                                
                                                </Col>
                                                <Col md='4' style={{ fontSize:'0.7em' }}>
                                                     {div.name}
                                                </Col>
                                                 
                                                <Col md='1' style={{ fontSize:'0.7em' }}>
                                                   {div.standings.length }
                                                </Col>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     <div>{ div.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                        });

                        divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id+'-2'} draggableId={item.id+'-2'} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    End of Session {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                              }


                
            });
                       
            var divsarea2 = <div></div>;
                                                                    
            if(!this.t_2_ring) { 
                var t = this;
                var u_divs = this.interface.
                        un_divs.filter(function(value, index, arr){ return value.status < 3 });
                    u_divs = searchArray(u_divs,searchDivisionHay, t.t_search);    
                        
                        divsarea2 = <Droppable droppableId="un_assiigned" >
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {u_divs.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!this.transfer_divisions_dnd}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='4' style={{ fontSize:'0.7em' }}>
                                                        { item.codePop() }
                                                    </Col>
                                                    <Col md='5' style={{ fontSize:'0.7em' }}>
                                                        {item.name}
                                                    </Col>
                                                    <Col md='3' style={{ fontSize:'0.7em' }}>
                                                        <div>{ item.printDurationEstimate() }</div>
                                                        <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                    </Droppable>;
        } else {
            var divs2 = this.t_2_ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); 
            var data2 = sessionFormat(divs2,  this.interface.schedules);
             
            var divs_area_parts2 = [];

            data2.forEach( (v, index, self2) => { 
            var item = v.value;
            if(v.type == 'division') {                                        
                divs_area_parts2.push(<Draggable isDragDisabled={v.value.status != 0 || !this.transfer_divisions_dnd} key={item.id} draggableId={item.id} index={divs_area_parts2.length}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <Container>
                              <Row>
                                  <Col md='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                       </Col>
                                       <Col md='3' style={{ fontSize:'0.7em' }}>
                                           { item.codePop() }                                                
                                       </Col>
                                       <Col md='4' style={{ fontSize:'0.7em' }}>
                                            {item.name}
                                       </Col>

                                       <Col md='1' style={{ fontSize:'0.7em' }}>
                                          {item.standings.length }
                                       </Col>
                                       <Col md='2' style={{ fontSize:'0.7em' }}>
                                            <div>{ item.printDurationEstimate() }</div>
                                            <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                       </Col>
                              </Row>
                          </Container>
                    </div>
                  )}
                </Draggable>);
                } else {
                        divs_area_parts2.push(<Draggable isDragDisabled={true} key={item.id+ '--2'} draggableId={item.id + '--2'} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);

                        v.children.forEach( (divv, index2, self22) => { 
                            var div = divv.value;
                            divs_area_parts2.push(<Draggable isDragDisabled={divv.value.status != 0 || !this.transfer_divisions_dnd} key={div.id} draggableId={div.id} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                </Col>
                                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                                    { div.codePop() }                                                
                                                </Col>
                                                <Col md='4' style={{ fontSize:'0.7em' }}>
                                                     {div.name}
                                                </Col>                                                 
                                                <Col md='1' style={{ fontSize:'0.7em' }}>
                                                   {div.standings.length }
                                                </Col>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     <div>{ div.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                        });

                        divs_area_parts2.push(<Draggable isDragDisabled={true} key={item.id+'--2-2'} draggableId={item.id+'--2-2'} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    End of Session {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                              }


                
            });
            divsarea2 = <Droppable droppableId="area2">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {divs_area_parts2}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>; 
        }
              
                  
                  var divsarea = <Droppable droppableId="area1">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {divs_area_parts}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>; 
             
             modal =  <Modal className='modal-superLarge' size="lg" show={this.t_s_ring != null} onHide={() => this.setTransferMainRing(null)}>
                <Modal.Header closeButton>
                  <Modal.Title>Division Transfer Tool</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <DragDropContext onDragEnd={this.onDragEnd}>
                <Container style={{ color:"white" }}>
                    <Row>
                        <Col xs='6'>
                            <Form.Control 
                                onChange={ (e) => { this.t_s_ring = this.interface.getRing(e.target.value); window.APP.reset(); } }
                                                                    as="select" value={this.t_s_ring.id } >
                                {this.interface.rings.filter((r)=>{return this.t_2_ring == null || r.id != this.t_2_ring.id } ).map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                            </Form.Control>
                            <div  style={{ fontSize:'0.75em',color:'black', }}>
                                <div>
                                    <div   className="mb-3">
                                        <Form.Check checked={(this.transfer_divisions_dnd) ? 'checked' : ''}
                                        onChange={this.dragAndDropClick}
                                        id={'transfer-popup-ednd'}
                                          type={'checkbox'}
                                          label='Enable Drag and Drop'
                                        />
                                    </div>
                                </div>
                                {this.interface.send_movement_notifications && <div>    
                                    <div  className="mb-3">
                                        <Form.Check checked={(this.interface.send_ring_notifications) ? 'checked' : ''}
                                        onChange={this.interface.ringMovementNotificationsClick}
                                        id={'transfer-popup-notifications'} 
                                          type={'checkbox'}
                                          label='Send Ring Movement Notifications'
                                        />
                                    </div>
                                </div>}
                            </div>
                            {divsarea}
                        </Col>
                        
                        <Col xs='6'>
                            <Form.Control as="select" 
                                onChange={ (e) => { this.t_2_ring = this.interface.getRing(e.target.value); window.APP.reset(); } }
                                value={ (this.t_2_ring) ? this.t_2_ring.id : '' }
                                                                            >
                                <option value=''>No Ring</option>
                                {this.interface.rings.filter((r)=>{return r.id != this.t_s_ring.id } ).map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                            </Form.Control>
                            {!this.t_2_ring && <Form.Control value={this.t_search} onChange={ (e) => { this.t_search = e.target.value; window.APP.reset(); } } type="text" placeholder="search" style={{ marginTop:'15px' }}>
                                </Form.Control>
                            }
                            {divsarea2}
                        </Col>
                    </Row>
                </Container>
                </DragDropContext>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.setTransferMainRing(null)}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
         }
         
        var ringJudgeModal = <div></div>;
        
        if(this.ringJudgeSelected != null) { 
            var nextRing = null;
            var prevRing = null;
            
            var found = false;
            var next = false;
            
            rings.forEach(x=>{
                if(found && !next) {
                    next = true;
                    nextRing = x;
                }
                if(found) {
                    return;
                }
            
                
                if(x.id == this.ringJudgeSelected.id) {
                    found = true;
                }
                if(!found) {
                    prevRing = x;   
                }
            });
            
            if(prevRing == null) {
                prevRing = rings[rings.length - 1];
            }
            
            if(nextRing == null) {
                nextRing = rings[0];
            }
            
            ringJudgeModal = <Modal className='modal-superLarge' size="lg" show={this.ringJudgeSelected != null} onHide={() => {this.ringJudgeSelected =null; this.setState({ringJudgeSelected:null}); } }>
                <Modal.Header closeButton>
                  <Modal.Title>{this.ringJudgeSelected.name} Judges</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                <Row>
                <Col xs='1'>
                <Button onClick={()=>{ this.ringJudgeSelected =prevRing; this.setState({ringJudgeSelected:prevRing}); } } variant='secondary'><i class="fas fa-arrow-left"></i></Button>
                </Col>
                <Col xs='10'>
                    <Form.Control 
                        onChange={ (e) => { this.ringJudgeSelected = this.interface.getRing(e.target.value); window.APP.reset(); } }
                                                            as="select" value={this.ringJudgeSelected.id } >
                        {this.interface.rings.map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                    </Form.Control>
                </Col>
                <Col xs='1' className='text-right'>
                <Button onClick={()=>{ this.ringJudgeSelected =nextRing; this.setState({ringJudgeSelected:nextRing}); } } variant='secondary'><i class="fas fa-arrow-right"></i></Button>
                </Col>
                </Row>
                <Row>
                <Col xs='12'>
                <h2>Current Judges</h2>
                <Table responsive>
                    <tbody>
                        {this.ringJudgeSelected.getCurrentJudgesArea()}
                    </tbody>
                </Table>
                
                Upcoming Judges
                <Table responsive>
                    <tbody>
                        {this.ringJudgeSelected.getFutureJudgesScheduleArea(this.interface.schedules)}                                           
                    </tbody>
                </Table>
                </Col>
                </Row>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => {this.ringJudgeSelected =null; this.setState({ringJudgeSelected:null}); }}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>;
        }
         
        return <Container>
        {modal}{ringJudgeModal}
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Ring Manager</Card.Header>
                        <Card.Body>
                        <Container>
                        <Row>
                        <Col md='12' style={{ marginBottom:'15px',textAlign:'center' }}>Divisions Summary</Col>   
                            <Col md='12' style={{ marginBottom:'15px' }}>{this.summary()}</Col>                                        
                        </Row>
                        <Row style={{ textAlign:'center', display: ( ( this.show_filters  ) ? 'none':'flex' ) }}>
                            <Col md='6' style={{ marginBottom:'15px',height:'1.5em' }}>
                                <button style={{ float:'left' }} onClick={ ()=> { this.toggleShowFilters() } } class="btn btn-primary">Show Ring Search / Filters</button>
                                { ( this.name_filter != '' || this.location_filter != '' || this.group_filter != '' ) && <button style={{ float:'right' }} onClick={ ()=> { this.clearFilters() } } class="btn btn-danger">Clear Ring Search / Filters</button>}
                            </Col> 
                            
                            <Col md='6' style={{ marginBottom:'15px',height:'1.5em' }}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Order By</InputGroup.Text>                                    
                                    <Form.Control value={this.ring_order} as="select" onChange={this.ringOrderUpdate} >
                                        <option value='ordered'>Ring Order</option>
                                        <option value='endtime'>End Time (Last => First)</option>
                                        <option value='endtime2'>End Time (First => Last)</option>
                                        <option value='ipdivisions'>Incomplete Divisions (Max => Min)</option>
                                        <option value='ipdivisions2'>Incomplete Divisions (Min => Max)</option>                                    
                                    </Form.Control>
                              </InputGroup>
                            </Col> 
                        </Row>
                        <Row style={{ textAlign:'center', display: ( ( this.show_filters  ) ? 'flex':'none' ) }}>
                            <Col md='6' style={{ marginBottom:'15px',height:'1.5em' }}><button style={{ float:'left' }}  onClick={ ()=> { this.toggleShowFilters() } } class="btn btn-primary">Hide Ring Search / Filters</button></Col>
                            <Col md='6' style={{ marginBottom:'15px',height:'1.5em' }}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Order By</InputGroup.Text>                                    
                                    <Form.Control value={this.ring_order} as="select" onChange={this.ringOrderUpdate} >
                                        <option value='ordered'>Ring Order</option>
                                        <option value='endtime'>End Time (Last => First)</option>
                                        <option value='endtime2'>End Time (First => Last)</option>
                                        <option value='ipdivisions'>Incomplete Divisions (Max => Min)</option>
                                        <option value='ipdivisions2'>Incomplete Divisions (Min => Max)</option>                                    
                                    </Form.Control>
                              </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ textAlign:'center', display: ( ( this.show_filters  ) ? 'flex':'none' ) }}>
                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                    <Form.Control value={this.name_filter} onChange={this.nameFilterUpdate}
                                      placeholder="Search Name/Code"
                                    />
                                </InputGroup>    
                            </Col>

                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text >Group</InputGroup.Text>
                                    <Form.Control value={this.group_filter} as="select" onChange={this.groupFilterUpdate}>
                                    <option value=''>No Filter</option>
                                    {this.groups().map((group) => <option  value={group} >{group}</option> ) }
                                    </Form.Control>
                                </InputGroup>    
                            </Col>

                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text >Location</InputGroup.Text>
                                    <Form.Control value={this.location_filter} as="select" onChange={this.locationFilterUpdate}>
                                    <option value=''>No Filter</option>
                                    {this.locations().map((location) => <option value={location} >{location}</option> ) }
                                    </Form.Control>
                                </InputGroup>    
                            </Col>

                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text >Date</InputGroup.Text>
                                    <Form.Control value={this.date_filter} as="select" onChange={this.dateFilterUpdate}>
                                    <option value='All'>No Filter</option>
                                    {this.interface.dates.map((date) => {
                                            var d = '0';
                                            var x = man.days.find((y)=>y.date == date);
                                            if(x != undefined) d = x.day;
                                            
                                            return <option value={date} >{dayWords[d]} ({date})</option>} ) }
                                    </Form.Control>
                                </InputGroup>    
                            </Col>
                            
                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text >Session</InputGroup.Text>
                                    <Form.Control value={this.session_filter} as="select" onChange={this.sessionFilterUpdate}>
                                    <option value='All'>No Filter</option>
                                    {this.interface.schedules.map((session) => <option value={session.id} >{session.name}</option> ) }
                                    </Form.Control>
                                </InputGroup>    
                            </Col>
                            
                        </Row>
                        
                        </Container>
                        {main}                                                       
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}


class CoordinatorDivisionsManagerDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this);
        
        this.div_name_filter = this.interface.div_name_filter;
    }
    
    getDivisions() {
        var divisions = this.interface.allDivisions();        
        divisions = searchArray(divisions,searchDivisionHay, this.div_name_filter);
        
      /*  if(this.div_name_filter.localeCompare('') != 0) {
                const name_filter =this.div_name_filter.toLowerCase(); 
                divisions = divisions.filter(function(value, index, arr){ 
                    return value.name.toLowerCase().indexOf(name_filter) >=0 || value.code.toLowerCase().indexOf(name_filter) >=0 ;
               });
        } 
        */
        return divisions;
    }
    
    getSortedDivisions() {
        return this.getDivisions().sort(function(a,b) {
                    //divisions w/o rings go first
                    if(a.ring == null && b.ring != null) {
                        return -1;
                    }
                    if(a.ring != null && b.ring == null) {
                        return 1;
                    }
                    
                    //status
                    if(a.status > b.status) {
                        return 1;
                    }
                    if(a.status < b.status) {
                        return -1;
                    }
                    return 0;
                });
    }

    nameFilterUpdate(event) {
        this.setState({div_name_filter: event.target.value.toLowerCase() });
        this.div_name_filter =  event.target.value.toLowerCase();
        this.interface.div_name_filter =  event.target.value.toLowerCase();
    }

    render() { 
        
        var divisions = this.getSortedDivisions();
        var main = <div></div>;
        var man = this.interface;
        if(this.interface.card_view) {
            const divisionsList = divisions.map((division ) => {
                return <Col xs={6} md="4" lg="4">
                    <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header><div style={{ marginBottom:'10px', overflow: 'hidden'}}>{division.name}<div style={{ float:'right' }}><Button onClick={()=>{ man.setDivision(division.id) } } variant='primary'><i class="fas fa-share"></i></Button></div></div>
                        { division.progressBar() }
                        </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row>
                                    <Col md='6' style={{ fontSize:'0.7em' }}>
                                    {division.ring && division.ring.name}{!division.ring && <span>&nbsp;</span>}
                                    </Col>
                                    
                                    <Col md='6' style={{ fontSize:'0.7em' }}>
                                    {division.ring && division.ring.location}{!division.ring && <span>&nbsp;</span>}
                                    </Col>
                                
                                { division.ring && <Col md='12' style={{ height:'1px',backgroundColor:'#FFFFFF' }} ></Col>}
                                    

                                
                                    <Col md='6' style={{ fontSize:'0.7em' }}>
                                    {division.standings.length} competitors
                                    </Col>
                                    
                                    <Col md='12' >
                                    { division.codePop() }
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>;
            });
            main = <Row  style={{ textAlign:'center' }}>
                    {divisionsList}
                </Row>  
        } else {
            const divisionsList = divisions.map((division ) => {
                return <tr><td>{division.name}</td><td>{division.niceStatus() } { division.progressBar() }</td><td>{ division.codePop() }</td><td>{division.ring && division.ring.name}</td><td><Button onClick={()=>{ man.setDivision(division.id) } } variant='primary'><i class="fas fa-share"></i></Button></td></tr>;
            });
            main = <Table responsive  variant="dark">
                <tbody>
                    {divisionsList}
                </tbody>        
            </Table>
        }
        
        
        return <Container>
            
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Division Manager</Card.Header>
                        <Card.Body>
                        <Container>
                        <Row style={{ textAlign:'center' }}>
                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                    <Form.Control value={this.div_name_filter} onChange={this.nameFilterUpdate}
                                      placeholder="Search Name/Code"
                                    />
                                </InputGroup>    
                            </Col>
                        </Row>
                        </Container>
                        {main}                                                       
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

class CoordinatorCompManagerDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        
        this.results = [];
        
        this.comp = null;
        this.comp_name = '';
        
        this.name_filter = '';
        
        this.search = this.search.bind(this);
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this);
        this.setComp = this.setComp.bind(this);
        this.weightChange = this.weightChange.bind(this);
        this.weighIn = this.weighIn.bind(this);
        this.preventChange = this.preventChange.bind(this);
        this.preventClick = this.preventClick.bind(this);
        this.injure = this.injure.bind(this);
        this.weight = '';
    }
    
    nameFilterUpdate(event) {
        this.setState({name_filter: event.target.value.toLowerCase() });
        this.name_filter =  event.target.value.toLowerCase();
        this.search(event.target.value.toLowerCase() );
    }
    
    componentDidMount(){       
        window.APP.registerReload(this)
    }

    componentWillUnmount() {
        window.APP.unRegisterReload(this)
    }
    
    reload() {
        this.searchIfSame(this.name_filter);
        
        if(this.comp != null) {
            this.resetComp()
        }
    }
    
    searchIfSame(value) {
        if(value != this.name_filter) { 
            return;
        }
        var it = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/coord/compsearch?search=' + this.name_filter).then(res => res.json())
          .then(
             (result) => {
                it.results = [];
                result.forEach(r => {
                   it.results.push(r);
               });
               it.setState( {results : it.results} )
             }
           );
    }
    
    search(value) {
        var t = this;
        setTimeout(function(){ t.searchIfSame(value); }, 500 );
    }
    
    weightChange(event) { 
        this.setState({weight: event.target.value });
        this.weight =  event.target.value;    
    }
    
    weighIn() {
        postMaster.toSend('/registration/' + this.comp.id + '/action/weigh-in?weight=' + this.weight);
        var t = this;
        setTimeout(function(){ t.resetComp(); }, 1000 );
        this.weight = '';
    }
    
    restoreRules() {
        postMaster.toSend('/registration/' + this.comp.id + '/action/rev-overrule');
        var t = this;
        setTimeout(function(){ t.resetComp(); }, 1000 );
        this.weight = '';
    }
    
    overruleRules() {
        postMaster.toSend('/registration/' + this.comp.id + '/action/overrule');
        var t = this;
        setTimeout(function(){ t.resetComp(); }, 1000 );
        this.weight = '';
    }
    
    preventChange(event) { 
        this.setState({prevent: event.target.value });
        this.prevent =  event.target.value;    
    }
    
    preventClick() {
        postMaster.toSend('/registration/' + this.comp.id + '/action/block?reason=' + this.prevent);
        var t = this;
        setTimeout(function(){ t.resetComp(); }, 1000 );
        this.prevent = '';
    }
    
    injure() {
        postMaster.toSend('/registration/' + this.comp.id + '/action/injure');
        var t = this;
        setTimeout(function(){ t.resetComp(); }, 1000 );
    }
    
    resetComp() {
        var it = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/coord/registration/'+ this.comp.id).then(res => res.json())
       .then(
          (result) => {
            it.comp = result;
            it.comp.divs = [];
            
            it.comp.divisions.forEach(element => {
                var d = it.interface.findDivision(element.division_id);
                if(!d) return;
                var s = d.getComp(element.standing_id)
                if(!s) return;
                
                it.comp.divs.push({ div:d,standing:s });
                
            });
                        
            this.setState( { comp:result} )
          }
        );
    }
    
    showOverrule() {
        var r = false;
        if(!this.comp) return r;
        if(this.comp.overrule_division_restrictions == 'Yes') {
            return true;
        }
        if(!this.comp.divs) return r;
        this.comp.divs.forEach(element => { 
            if(element.standing.blocked_reason != "") {
                r = true;
            } 
            });
        return r;
    }
    
    setComp(x) {
        this.comp_name = x.name;
        this.comp = null;
        
        this.setState( {comp_name : x.name, comp:null} )
        
        var it = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/coord/registration/'+ x.reg_id).then(res => res.json())
       .then(
          (result) => {
            it.comp = result;
            it.comp.divs = [];
            
            it.comp.divisions.forEach(element => {
                var d = it.interface.findDivision(element.division_id);
                if(!d) return;
                var s = d.getComp(element.standing_id)
                if(!s) return;                
                it.comp.divs.push({ div:d,standing:s });                
            });
                        
            this.setState( { comp:result} )
          }
        );
    }

    render() { 
        var man = this.interface;
        
        if(this.comp != null && this.comp_name == '') {
            this.comp_name = this.comp.name;
        }
        
        return <Container>
            
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Competitor Search</Card.Header>
                        <Card.Body>
                        <Container>
                        <Row style={{ textAlign:'center' }}>
                            <Col md='12'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                    <Form.Control value={this.div_name_filter} onChange={this.nameFilterUpdate}
                                      placeholder="Search Name"
                                    />
                                </InputGroup>    
                            </Col>
                        </Row>
                        { this.results.length > 0 && <Row>
                            { this.results.map( (x) => 
                            <Col md="4">
                            <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header>{x.name}</Card.Header>
                                <Card.Body >
                                    <Container>
                                    <Button onClick={ ()=>this.setComp(x) }>View</Button>
                                    </Container>
                                </Card.Body >
                            </Card>
                            </Col> ) }                                      
                        </Row>}
                        
                        { this.results.length == 0 && <Row>
                            <Col md="12">
                            <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header>No Results</Card.Header>                                
                            </Card>
                            </Col>                 
                        </Row>}
                        
                        </Container>                                                   
                        </Card.Body>
                    </Card>
                </Col>
                
                { this.comp_name != '' &&
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >{this.comp_name}</Card.Header>
                        <Card.Body>
                        {this.comp != null && 
                        <Container>
                        <Row>
                            <Col md="3">
                                {this.comp.photoL != '' &&  <img style={{ width: '100%'}} src={this.comp.photoL} /> }
                                {
                                    this.comp.alerts.map( (x) => 
                                        <Alert variant={x.v}>
                                        {x.text}
                                      </Alert>
                                      )
                                    }
                                </Col>   
                                <Col md="3">
                                <Table responsive variant="dark">
                                    <tbody>
                                    {this.comp.reg_checked_in == 'No' &&
                                        <tr class={'bg-danger'} ><th>Checked-In</th><td>No</td></tr>
                                    }
                                    {this.comp.reg_checked_in == 'Yes' &&
                                        <tr  ><th>Checked-In</th><td>Yes</td></tr>
                                    }
                                    {this.comp.injured != '' &&
                                        <tr class={'bg-danger'} ><th colspan="2">Injured</th></tr>
                                    }
                                    <tr><th>Age</th><td>{this.comp.age}</td></tr>
                                    <tr><th>Gender</th><td>{this.comp.gender}</td></tr>
                                    <tr><th>Rank</th><td>{this.comp.rank}</td></tr>
                                    
                                    <tr><th>Height</th><td>{this.comp.height}</td></tr>
                                    <tr class={ (this.comp.weighed_in =='Yes') ? 'bg-success' :  '' } ><th>Weight</th><td>{getTimeString(this.comp.weight)}</td></tr>
                                    <tr>
                                                    <td colspan="2">
                                                        <InputGroup className="mb-3">
                                                        <FormControl type="number" onChange={this.weightChange }
                                                            value={this.weight}
                                                            placeholder="new weight"
                                                        />
                                                          <Button onClick={()=>{this.weighIn() } } variant="outline-success">Weigh-In</Button>
                                                        
                                                      </InputGroup>

                                                    </td>
                                                    </tr>
                                    <tr><th>Country</th><td style={{ width:'50px', verticalAlign:'middle' }} >
                                                    {this.comp.country != '' && 
                                                            <OverlayTrigger placement={'top'}
                                              overlay={
                                                <Tooltip>
                                                  {this.comp.country_name}
                                                </Tooltip>
                                              }
                                              ><img style={{ 'width':'50px' }} src={this.comp.country}   ></img></OverlayTrigger> }
                                               </td></tr>
                                               {this.comp.injured == '' && <tr><td><Button onClick={()=>{this.injure() } } variant="danger">Mark Injured</Button></td></tr>}
                                                <tr>
                                                    <td colspan="2">
                                                        Prevent From Competing
                                                    </td>
                                                    </tr>   
                                                <tr>
                                                    <td colspan="2">
                                                        <InputGroup className="mb-3">
                                                        <FormControl onChange={this.preventChange }
                                                            value={this.prevent}
                                                            placeholder="reason"
                                                        />
                                                        
                                                          <Button onClick={()=>{this.preventClick() } } variant="outline-danger">Prevent</Button>
                                                        
                                                      </InputGroup>

                                                    </td>
                                                    </tr>
                                    </tbody>
                                </Table>

                            
                            
                            </Col>
                            
                            <Col md="6">
                            <Table responsive variant="dark">
                            <thead>
                                <tr><td>Code</td><td>Name</td><td>Rank</td><td>Go</td></tr>                                             
                            </thead>
                            <tbody>
                                {
                                this.comp.divs.map( (x) => 
                                <tr class={ (x.standing.checked_in == 1) ? 'bg-success' :  'bg-danger' } ><td>{x.div.codePop(true)}</td><td>{x.div.name} { x.standing.is_blocked == 'Yes' && <OverlayTrigger placement={'top'} overlay={<Tooltip>{x.standing.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={x.standing.blocked_symbol} ></i></OverlayTrigger>  }</td><td>{x.standing.place != null && !isNaN(x.standing.place) && x.standing.place}</td><td><Button onClick={()=>{ man.setDivision(x.div.id) } } variant='primary'><i class="fas fa-share"></i></Button></td></tr>    
                                    )
                                }
                            </tbody>
                            <tbody>
                            {this.showOverrule() &&
                                    <tr><td colspan='3'>{this.comp.overrule_division_restrictions == "Yes" &&  <Button onClick={()=>{this.restoreRules() } }  variant='success'>Restore Restrictions</Button>} {this.comp.overrule_division_restrictions == 'No' && <Button onClick={()=>{this.overruleRules() } } variant='danger'>Remove Division Restrictions</Button>}</td></tr> 
                            }                                                    
                            </tbody>
                            </Table>
                            </Col>
                        </Row>
                        </Container>}                                          
                        </Card.Body>
                    </Card>
                </Col>
                }
            </Row>
        </Container>;
    }
}

class CoordinatorJudgeManagerDisplay extends React.Component {
    
    summary() {
        var judges = this.getJudges();
        var ipvalue = judges.filter(element=>{ return element.status != ''}).length;
        var total = judges.length;
        
        var ipvaluep = 0;
        
        
        if(total > 0) {
            ipvaluep = ipvalue / total;
        }
        
        ipvaluep = ipvaluep * 100;
        return <div>                
                
                <Container>
                <Row>
                <Col md='4'>Not Assigned <span>{( total - (ipvalue) ) }</span></Col>
                <Col md='4'>Assigned <span class='text-primary'>{ipvalue}</span></Col>
                <Col md='4'>Total <span>{total}</span></Col>
                                            </Row>
                </Container>
                {total > 0 &&  <ProgressBar style={{ height:'32px' }}>
                    <ProgressBar label={ipvalue} variant={'primary'} now={ipvaluep} />
                </ProgressBar>}
               </div>;
    
    }
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        this.load();
        
        this.judge_name_filter = this.interface.judge_name_filter;
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this);
        this.judges = [];
        
    }
    
    
    
    getJudges() {
        var judges = this.judges;
        if(this.judge_name_filter.localeCompare('') != 0) {
                const name_filter =this.judge_name_filter.toLowerCase(); 
                judges = judges.filter(function(value, index, arr){ 
                    return value.name.toLowerCase().indexOf(name_filter) >=0;
               });
           } 
        return judges;
    }

    nameFilterUpdate(event) {
        this.setState({judge_name_filter: event.target.value.toLowerCase() });
        this.judge_name_filter =  event.target.value.toLowerCase();
        this.interface.judge_name_filter =  event.target.value.toLowerCase();
    }
    
    componentDidMount(){       
        window.APP.registerReload(this)
    }

    componentWillUnmount() {
        window.APP.unRegisterReload(this)
    }
    
    reload() {
        this.load();
    }
    
    load() {
        var inter = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/coordinator_judges').then(res => res.json())
       .then(
          (result) => {
              inter.judges = result.judges;
                window.APP.reset();
          }
        );
        
    }

    render() { 
        
        var judges = this.getJudges();
        var main = <div></div>;
        var man = this.interface;
        
        if(this.interface.card_view) {
            const judgesList = judges.map((judge ) => {
                return <Col xs={12} sm="6" lg="4">
                    <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header>{judge.name}<div style={{ float:'right' }}><Button onClick={()=>{ man.setJudge(judge.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                        
                        </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row>
                                    <Col md={ (this.interface.judge_check_in > 1) ? 4 : 6 } >
                                    {judge.thumb != '' &&  <img style={{ maxWidth: '80px',width:'100%'}} src={judge.thumb} /> }
                                    </Col>
                                    {this.interface.judge_check_in > 1 &&
                                        <Col md={ 2 } >
                                            {judge.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i></span>}
                                            {judge.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i></span>}
                                        </Col>
                                    }
                                    <Col md='6' >
                                    { judge.status }
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>;
            });
            main = <Row  style={{ textAlign:'center' }}>
                    {judgesList}
                </Row>  
        } else {
            const judgesList = judges.map((judge ) => {
                return <tr><td>{judge.name}</td><td>{judge.thumb != '' &&  <img style={{ width: '40px'}} src={judge.thumb} /> }</td><td>{judge.status}</td><td><Button onClick={()=>{ man.setJudge(judge.id) } } variant='primary'><i class="fas fa-share"></i></Button></td></tr>;
            });
            main = <Table responsive variant="dark">
                <tbody>
                    {judgesList}
                </tbody>        
            </Table>
        }
        
        
        return <Container>
            
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Judges Manager</Card.Header>
                        <Card.Body>
                        <Container>
                         <Row>
                            <Col md='12' style={{ marginBottom:'15px' }}>{this.summary()}</Col>                                        
                        </Row>
                        <Row style={{ textAlign:'center' }}>
                            <Col md='6'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                    <Form.Control value={this.judge_name_filter} onChange={this.nameFilterUpdate}
                                      placeholder="Search Name"
                                    />
                                </InputGroup>    
                            </Col>
                        </Row>
                        </Container>
                        {main}                                                       
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

class CoordinatorRingDisplay extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.interface = props.interface;
        this.ring = props.ring;
        this.scorekeeperMessage = '';
        
        this.updateScorekeeperMessage = this.updateScorekeeperMessage.bind(this);
        this.sendScorekeeperMessage = this.sendScorekeeperMessage.bind(this);
        this.toggleShowCompleted = this.toggleShowCompleted.bind(this);
        
        this.show_completed = false;
         
        this.judgeAddSearch = [];
        this.addCenterValue = false;
        
        this.addUnkownJudge = this.addUnkownJudge.bind(this);
        this.judgesAddSearchState = ''
        this.addJudgeSearchUpdate = this.addJudgeSearchUpdate.bind(this);
        
        this.onDragEnd = this.onDragEnd.bind(this);
        this.setConfirm = this.setConfirm.bind(this);
        this.checkInAll = this.checkInAll.bind(this);
        
        this.t_s_ring = this.ring;
        this.t_2_ring = null;
        this.t_search = '';
        
        this.confirm_draws = false;
        this.processing_draws = '';
        
        
        this.dragAndDropClick = this.dragAndDropClick.bind(this);
        
        this.transfer_divisions_dnd = false;
    }
    
    checkInAll() {
        this.processing_draws = 'Currently running do not interact';
        this.setState({processing_draws:this.processing_draws});
            
        var holder = this;
            
        this.ring.divisions.filter((x)=>x.status == 0).forEach( (value, index, self2) => {
            holder.processing_draws = 'Currently running do not interact - ' + value.name;
            holder.setState({processing_draws:holder.processing_draws});
            value.checkAndRunDraws(holder.ring);    
                
        });
        
        this.processing_draws = 'Draws complete, please wait till all data is uploaded (can see in toolbar)';
    }
    
    setConfirm() {
        this.confirm_draws = true;
        this.setState({confirm_draws:true});
    }
    
    componentDidMount(){       
        window.APP.registerOnReload(this)
    }

    componentWillUnmount() {
        window.APP.unRegisterOnReload(this)
    }
    
    postReload() {
        if(this.ring != null) {
            this.ring = this.interface.rings.find((x) => x.id == this.ring.id);
        }     
        
        this.t_s_ring = this.ring;
        
        if(this.t_2_ring != null) {
            this.t_2_ring = this.interface.rings.find((x) => x.id == this.t_2_ring.id)
        } 
    }
    
    
    toggleShowCompleted() {
        this.show_completed = !this.show_completed;
        this.setState({show_completed: this.show_completed});
    }
    
    updateScorekeeperMessage(event) {
        this.setState({scorekeeperMessage: event.target.value.toLowerCase() });
        this.scorekeeperMessage =  event.target.value.toLowerCase();
    }
    
    addJudgeToDivision(comp) {
        postMaster.immediatePost( '/tournament-ring/' + this.ring.id + '/add-ejudge',
        { 
            name:comp.uuid,
            center:this.addCenterValue
        })
        .then(
             (result) => {  
                this.judgesAddSearchState = '';
                 this.judgeAddSearch = [];
                window.APP.reset();
             }
           );
            this.judgesAddSearchState = '';
            this.judgeAddSearch = [];
            window.APP.reset();
    }
    
    
    judgesSearchList() { 
        return this.judgeAddSearch.map( (comp ) => 
        <ListGroup.Item  style={{color:'black'}} onClick={() => this.addJudgeToDivision(comp)} as="li">{comp.name}</ListGroup.Item>
          );
    }
    
    addJudgeSearchUpdate(event) { 
        var t = this;
        this.judgesAddSearchState = event.target.value;
        this.setState( {judgesAddSearchState : this.judgesAddSearchState} );
        var k = event.target.value;
        setTimeout(function(){ t.addJudgeSearchUpdateCheck(k); }, 500 ); 
    }
    
    addJudgeSearchUpdateCheck(value) {
        if(value != this.judgesAddSearchState) { 
            return;
        }
        
        if(this.ring.tournament_id) {  
         postMaster.immediateGet("/tournament/" + this.ring.tournament_id + '/judgesearch?ring_id='+ this.ring.id + '&search=' + this.judgesAddSearchState).then(res => res.json())
           .then(
              (result) => {
                  this.judgeAddSearch = [];
                  result.forEach(r => {
                    this.judgeAddSearch.push(r);
                });                
                this.setState( {judgeAddSearch : this.judgeAddSearch} )
                
              }
            );
        }
    }
    
    addCenterUpdate(event) {
        this.addCenterValue = event.target.checked;
    }
    
    addUnkownJudge() {
        postMaster.immediatePost( '/tournament-ring/' + this.ring.id + '/add-ejudge',
        { 
            name:this.judgesAddSearchState,
            center:this.addCenterValue
        })
        .then(
             (result) => {  
                this.judgesAddSearchState = '';
                this.judgeAddSearch = [];
                window.APP.reset();
             }
           );
            this.judgesAddSearchState = '';
            this.judgeAddSearch = [];
            window.APP.reset();
    }
    
    sendScorekeeperMessage() {
        postMaster.toSendData('/tournament-ring/' + this.ring.id +'/message?ajax=1',{message: this.scorekeeperMessage } )
        
        this.setState({scorekeeperMessage: '' });
        this.scorekeeperMessage =  '';
    }
    

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        
        var t = this;
        var ring_id = this.t_s_ring.id;

        var divs = this.t_s_ring.getSchedule(48);

        if(result.destination.droppableId == 'area2' || result.destination.droppableId == 'un_assiigned' ) { 
            ring_id = (this.t_2_ring) ? this.t_2_ring.id : 'none';
            if(this.t_2_ring) {
                divs = this.t_2_ring.getSchedule(48);
            }
        }

        
        if(this.show_completed == false) {    
            divs = divs.filter(function(value, index, arr){ 
                return value.status < 3;
            });
        }
        
            
        var datas = sessionFormat(divs,  this.interface.schedules);

        var active_session = null;
        var found_division = null;
        var found = false;

        var index = 0;

        datas.forEach( (v, k, self2) => {
            if(found) return;

            if(v.type == 'division') { 
                if(result.destination.index == index) {
                    found_division = v.value;
                    found = true;
                    return;
                }
                if(v.value.id != result.draggableId) {
                    index = index + 1; 
                }
            } else {


                //if it is before the session - go before first in session
                if(result.destination.index == index) {                            
                    if(v.children.length > 0) {
                        found_division = v.children[0];
                    }
                    found = true;
                    return;
                }

                index = index + 1;
                active_session = v.value;

                //check if it is a sub-division
                v.children.forEach( (divv, l, self22) => { 
                    if(found) return;
                    if(result.destination.index == index) {
                        found_division = divv.value;
                        found = true;
                        return;
                    }
                    if(divv.value.id != result.draggableId) {
                        index = index + 1; 
                    }
                });

                if(found) return;


                //if it is replacing in the session                        
                if(result.destination.index == index) {
                    found = true;
                    return;
                }
                active_session = null;
                index = index + 1; 

            }
        });


        if(found_division && found_division.id == result.draggableId) { 
            return;
        }

        var session_id = 'NA';
        var division_spot_id = 'NA';

        var confirmtext = '';
        if(active_session) {
            session_id = active_session.id;
            confirmtext = 'Assign to ' + active_session.name;
        }

        if(found_division) {
            division_spot_id = found_division.id;
            confirmtext = 'Assign to before ' + found_division.name;
        }
        
        if(active_session && found_division) {
           confirmtext = 'Assign to ' + active_session.name + ' before ' + found_division.name;
        }
        
        if(!active_session && !found_division) {
           confirmtext = 'Assign to ring wihtout specification';
        }
        
        if(confirmtext != '') {
            if(!window.confirm(confirmtext)) {
                return;
            }
        }

        postMaster.toSend('/tournament-division/' + result.draggableId +'/action/assign?ring=' + ring_id + '&session=' + session_id + '&division_spot_id=' + division_spot_id  + '&send_notification='+this.interface.send_ring_notifications);


    }
    
    dragAndDropClick() {
        this.transfer_divisions_dnd = !this.transfer_divisions_dnd;
        this.setState({transfer_divisions_dnd: this.transfer_divisions_dnd });
    }
    
    render() { 
        var inter = this;
        var divs = this.ring.getSchedule(48);
        var man = this.interface;

        if(this.show_completed == false) {
         divs = divs.filter(function(value, index, arr){ 
                return value.status < 3;
            });   
        }
        
        var data = sessionFormat(divs,  this.interface.schedules);
                
        var divs_area_parts = [];

        data.forEach( (v, index, self2) => { 
            var item = v.value;
            if(v.type == 'division') {                                        
                divs_area_parts.push(<Draggable isDragDisabled={v.value.status != 0  || !this.transfer_divisions_dnd} key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <Container>
                              <Row>
                                  <Col md='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                       </Col>
                                       <Col md='3' style={{ fontSize:'0.7em' }}>
                                           { item.codePop() }                                                
                                       </Col>
                                       <Col md='4' style={{ fontSize:'0.7em' }}>
                                            {item.name}
                                       </Col>

                                       <Col md='1' style={{ fontSize:'0.7em' }}>
                                          {item.standings.length }
                                       </Col>
                                       <Col md='2' style={{ fontSize:'0.7em' }}>
                                            <div>{ item.printDurationEstimate() }</div>
                                            <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                       </Col>
                              </Row>
                          </Container>
                    </div>
                  )}
                </Draggable>);
                } else {
                    if(v.incomplete == 1 || this.show_completed ) {
                        divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);

                        v.children.forEach( (divv, index2, self22) => { 
                            var div = divv.value;
                            divs_area_parts.push(<Draggable isDragDisabled={divv.value.status != 0  || !this.transfer_divisions_dnd} key={div.id} draggableId={div.id} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                </Col>
                                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                                    { div.codePop() }                                                
                                                </Col>
                                                <Col md='4' style={{ fontSize:'0.7em' }}>
                                                     {div.name}
                                                </Col>
                                                 
                                                <Col md='1' style={{ fontSize:'0.7em' }}>
                                                   {div.standings.length }
                                                </Col>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     <div>{ div.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                        });

                        divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id+'-2'} draggableId={item.id+'-2'} index={divs_area_parts.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    End of Session {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                              }


                }
            });


        var divsarea = <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {divs_area_parts}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>;   
        
        
        var transfer_area = <div></div>
         if (this.t_s_ring) {
             
            var divs = this.t_s_ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); 
            var data = sessionFormat(divs,  this.interface.schedules);
             
            var divs_area_parts3 = [];

            data.forEach( (v, index, self2) => { 
            var item = v.value;
            if(v.type == 'division') {                                        
                divs_area_parts3.push(<Draggable isDragDisabled={v.value.status != 0  || !this.transfer_divisions_dnd} key={item.id} draggableId={item.id} index={divs_area_parts3.length}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <Container>
                              <Row>
                                  <Col md='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                       </Col>
                                       <Col md='3' style={{ fontSize:'0.7em' }}>
                                           { item.codePop() }                                                
                                       </Col>
                                       <Col md='4' style={{ fontSize:'0.7em' }}>
                                            {item.name}
                                       </Col>

                                       <Col md='1' style={{ fontSize:'0.7em' }}>
                                          {item.standings.length }
                                       </Col>
                                       <Col md='2' style={{ fontSize:'0.7em' }}>
                                            <div>{ item.printDurationEstimate() }</div>
                                            <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                       </Col>
                              </Row>
                          </Container>
                    </div>
                  )}
                </Draggable>);
                } else {
                        divs_area_parts3.push(<Draggable isDragDisabled={true} key={item.id} draggableId={item.id} index={divs_area_parts3.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);

                        v.children.forEach( (divv, index2, self22) => { 
                            var div = divv.value;
                            divs_area_parts3.push(<Draggable isDragDisabled={divv.value.status != 0  || !this.transfer_divisions_dnd} key={div.id} draggableId={div.id} index={divs_area_parts3.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                </Col>
                                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                                    { div.codePop() }                                                
                                                </Col>
                                                <Col md='4' style={{ fontSize:'0.7em' }}>
                                                     {div.name}
                                                </Col>
                                                 
                                                <Col md='1' style={{ fontSize:'0.7em' }}>
                                                   {div.standings.length }
                                                </Col>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     <div>{ div.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                        });

                        divs_area_parts3.push(<Draggable isDragDisabled={true} key={item.id+'-2'} draggableId={item.id+'-2'} index={divs_area_parts3.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    End of Session {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                              }


                
            });
                       
            var divsarea2 = <div></div>;
                                                                    
            if(!this.t_2_ring) { 
                var t = this;
                var u_divs = this.interface.
                        un_divs.filter(function(value, index, arr){ return value.status < 3 });
                u_divs = searchArray(u_divs,searchDivisionHay, t.t_search)
                divsarea2 = <Droppable droppableId="un_assiigned">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {u_divs.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!this.transfer_divisions_dnd}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='4' style={{ fontSize:'0.7em' }}>
                                                        { item.codePop() }
                                                    </Col>
                                                    <Col md='5' style={{ fontSize:'0.7em' }}>
                                                        {item.name}
                                                    </Col>
                                                    <Col md='3' style={{ fontSize:'0.7em' }}>
                                                        <div>{ item.printDurationEstimate() }</div>
                                                        <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>;
        } else {
            var divs2 = this.t_2_ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); 
            var data2 = sessionFormat(divs2,  this.interface.schedules);
             
            var divs_area_parts2 = [];

            data2.forEach( (v, index, self2) => { 
            var item = v.value;
            if(v.type == 'division') {                                        
                divs_area_parts2.push(<Draggable isDragDisabled={v.value.status != 0  || !this.transfer_divisions_dnd} key={item.id} draggableId={item.id} index={divs_area_parts2.length}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                          <Container>
                              <Row>
                                  <Col md='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                       </Col>
                                       <Col md='3' style={{ fontSize:'0.7em' }}>
                                           { item.codePop() }                                                
                                       </Col>
                                       <Col md='4' style={{ fontSize:'0.7em' }}>
                                            {item.name}
                                       </Col>

                                       <Col md='1' style={{ fontSize:'0.7em' }}>
                                          {item.standings.length }
                                       </Col>
                                       <Col md='2' style={{ fontSize:'0.7em' }}>
                                            <div>{ item.printDurationEstimate() }</div>
                                            <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                       </Col>
                              </Row>
                          </Container>
                    </div>
                  )}
                </Draggable>);
                } else {
                        divs_area_parts2.push(<Draggable isDragDisabled={true} key={item.id+ '--2'} draggableId={item.id + '--2'} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);

                        v.children.forEach( (divv, index2, self22) => { 
                            var div = divv.value;
                            divs_area_parts2.push(<Draggable isDragDisabled={divv.value.status != 0 || !this.transfer_divisions_dnd} key={div.id} draggableId={div.id} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                </Col>
                                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                                    { div.codePop() }                                                
                                                </Col>
                                                <Col md='4' style={{ fontSize:'0.7em' }}>
                                                     {div.name}
                                                </Col>
                                                 
                                                <Col md='1' style={{ fontSize:'0.7em' }}>
                                                   {div.standings.length }
                                                </Col>
                                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                                     <div>{ div.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                        });

                        divs_area_parts2.push(<Draggable isDragDisabled={true} key={item.id+'--2-2'} draggableId={item.id+'--2-2'} index={divs_area_parts2.length}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}

                                    style={getItemSessionStyle(v,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                        <Container>
                                            <Row>
                                                <Col md='12' style={{ fontSize:'0.7em' }}>
                                                    End of Session {item.name}
                                                </Col>
                                            </Row>
                                        </Container>
                                  </div>
                                )}
                              </Draggable>);
                              }


                
            });
            divsarea2 = <Droppable droppableId="area2">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {divs_area_parts2}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>; 
        }
              
                  
                  var divsareatransfer = <Droppable droppableId="area1">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {divs_area_parts3}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>; 
             
             transfer_area =  
                <DragDropContext onDragEnd={this.onDragEnd}>
                <Container style={{ color:"white" }}>
                    <Row>
                        <Col xs='6'>
                            <Form.Control disabled='disabled'
                                onChange={ (e) => { this.t_s_ring = this.interface.getRing(e.target.value); window.APP.reset(); } }
                                                                    as="select" value={this.t_s_ring.id } >
                                {this.interface.rings.filter((r)=>{return this.t_2_ring == null || r.id != this.t_2_ring.id } ).map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                            </Form.Control>
                            <div  style={{ fontSize:'0.75em', }}>
                                <div>
                                    <div   className="mb-3">
                                        <Form.Check checked={(this.transfer_divisions_dnd) ? 'checked' : ''}
                                        onChange={this.dragAndDropClick}
                                        id={'transfer-popup-ednd'}
                                          type={'checkbox'}
                                          label='Enable Drag and Drop'
                                        />
                                    </div>
                                </div>
                                {this.interface.send_movement_notifications && <div>    
                                    <div  className="mb-3">
                                        <Form.Check checked={(this.interface.send_ring_notifications) ? 'checked' : ''}
                                        onChange={this.interface.ringMovementNotificationsClick}
                                        id={'transfer-popup-notifications'} 
                                          type={'checkbox'}
                                          label='Send Ring Movement Notifications'
                                        />
                                    </div>
                                </div>}
                            </div>
                            {divsareatransfer}
                        </Col>
                        
                        <Col xs='6'>
                            <Form.Control as="select" 
                                onChange={ (e) => { this.t_2_ring = this.interface.getRing(e.target.value); window.APP.reset(); } }
                                value={ (this.t_2_ring) ? this.t_2_ring.id : '' }
                                                                            >
                                <option value=''>No Ring</option>
                                {this.interface.rings.filter((r)=>{return r.id != this.t_s_ring.id  } ).map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                            </Form.Control>
                            {!this.t_2_ring && <Form.Control value={this.t_search} onChange={ (e) => { this.t_search = e.target.value; window.APP.reset(); } } type="text" placeholder="search" style={{ marginTop:'15px' }}>
                                </Form.Control>
                            }
                            {divsarea2}
                        </Col>
                    </Row>
                </Container>
                </DragDropContext>;
                
         }
         
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                <Card.Header ><Button style={{float:'left' }} variant='primary' onClick={()=>{ this.interface.clearRing() }} >Back</Button><div style={{ float:'right' }}>{this.ring.icon() }</div><div>{this.ring.status == 1 && <div class="spinner-grow text-warning" role="status"></div>}{this.ring.status == 2 && <div class="spinner-grow text-danger" role="status"></div>}&nbsp;{this.ring.name}&nbsp;{this.ring.status == 1 && <div class="spinner-grow text-warning" role="status"></div>}{this.ring.status == 2 && <div class="spinner-grow text-danger" role="status"></div>}</div></Card.Header>
                    <Card.Body >
                        <Container>
                        <Row  style={{marginBottom:'10px'}}>
                            <Col md='1' style={{ fontSize:'0.7em' }}>
                            {this.ring.statusIcon() }
                            </Col> 
                            <Col md='3' style={{ fontSize:'0.7em' }}>
                            {this.ring.current_division && this.ring.current_division.niceStatus() }
                            {this.ring.ring_import && this.ring.ring_import.niceStatus() }
                            </Col>  
                            <Col md='2' style={{ fontSize:'0.7em' }}>
                            { this.ring.current_division && this.ring.current_division.codePop() }
                            { this.ring.current_division && <Button variant='primary' onClick={ ()=>{ this.interface.setDivision(this.ring.current_division.id) } }><i class="fas fa-share"></i></Button>}
                            
                            { this.ring.ring_import && <div>{this.ring.ring_import.codePop()} from {this.ring.ring_import.ring.name}</div> }
                            { this.ring.ring_import && <Button variant='primary' onClick={ ()=>{ this.interface.setDivision(this.ring.ring_import.id) } }><i class="fas fa-share"></i></Button>}
                                                        
                            </Col>                             
                            <Col md='3' style={{ fontSize:'0.7em' }}>
                            {this.ring.location }
                            </Col> 
                            <Col md='3' style={{ fontSize:'0.7em' }}>
                                <a target="_blank" href={'/app?type=ring&id='+this.ring.id}>Scorekeep</a>
                            </Col> 
                        </Row>
                        <Row>
                            <Col md='12' style={{ fontSize:'0.7em',marginTop:'15px' }}>
                            { this.ring.upperProgressBar() }
                            </Col> 
                            
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12}>
            {this.ring.alertsManager.alertsDisplay() }
            {this.ring.protestsDisplay(this.interface) }
            </Col>
            
            {this.ring.ring_import && <Col xs={12}>
                    <Alert variant="primary" style={{ textAlign:'center' }}>
                    <p>This division was imported from {this.ring.ring_import.ring.name} <Button variant='primary' onClick={ ()=>{ this.interface.setRing(this.ring.ring_import.ring.id) } }><i class="fas fa-share"></i></Button></p>
                    </Alert></Col>}
                    
            {this.ring.ring_import_request && <Col xs={12}><Alert variant="primary" style={{ textAlign:'center' }}>
                <Alert.Heading>Import Division Request</Alert.Heading>
                <p>There is a request to help with {this.ring.ring_import_request.code} - {this.ring.ring_import_request.name}</p>
                </Alert></Col>}
            
            <Col xs={12}>
            <Container>
            
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Schedule</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Current Judges</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Planned Judges</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fourth">Admin</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fith">Divison Transfer Tool</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                        <Tab.Pane eventKey="fith">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header>Division Transfer Tool</Card.Header>
                                <Card.Body >
                                    {transfer_area}
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                        <Tab.Pane eventKey="first">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Schedule</Card.Header>
                                <Card.Body >
                                <Container >
                                            <Row>
                                                <Col md='4'>
                                                    <div   className="mb-3">
                                                    <Form.Check 
                                                        type='checkbox' checked={ (this.show_completed) ?'checked' : '' } onChange={this.toggleShowCompleted}
                                                      /> Show Completed
                                                    </div>
                                                </Col>

                                                <Col md='4'>
                                                        <div   className="mb-3">
                                                            <Form.Check checked={(this.transfer_divisions_dnd) ? 'checked' : ''}
                                                            onChange={this.dragAndDropClick}
                                                            id={'schedule-ednd'}
                                                              type={'checkbox'}
                                                              label='Enable Drag and Drop'
                                                            />
                                                        </div>
                                                </Col>
                                                {this.interface.send_movement_notifications && false && <Col md='4'>    
                                                        <div  className="mb-3">
                                                            <Form.Check checked={(this.interface.send_ring_notifications) ? 'checked' : ''}
                                                            onChange={this.interface.ringMovementNotificationsClick}
                                                            id={'schedule-notifications'} 
                                                              type={'checkbox'}
                                                              label='Send Ring Movement Notifications'
                                                            />
                                                        </div>
                                                    </Col>}
                                        </Row>
                                    </Container>
                                
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Container >
                                            <Row>
                                                <Col md='12'>
                                                    {divsarea}
                                                </Col>
                                            </Row>
                                        </Container>
                                    </DragDropContext>
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Current Judges</Card.Header>
                                <Card.Body >
                                    <Table responsive variant='dark'>
                                        <tbody>
                                            {this.ring.getCurrentJudgesArea()}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Upcoming Judges</Card.Header>
                                <Card.Body >
                                    <Table responsive variant='dark'>
                                        <tbody>
                                            {this.ring.getFutureJudgesArea()}                                           
                                        </tbody>
                                    </Table>
                                    
                                    <div>
                                    <h2>Add Judge</h2>
                                    <Form.Check
                                        onChange={this.addCenterUpdate}
                                        type="checkbox"
                                        className="mb-2 mr-sm-2"
                                        label="Center"
                                      />
                                    <Form.Control type="text" placeholder="search name" onChange={this.addJudgeSearchUpdate} value={this.judgesAddSearchState} />
                                    <ListGroup as="ul">
                                        {this.judgesSearchList()}  
                                    </ListGroup>
                                    <Button variant='success' onClick={this.addUnkownJudge} >Add New Judge</Button>
                                    </div>
                                    
                                    
                                    
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          
                          <Tab.Pane eventKey="fourth">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Admin</Card.Header>
                                <Card.Body >
                                    {this.ring.status > -1 && this.ring.active == 0 && <CloseRingButton key={this.ring} ring={this.ring} />}
                                
                                    <h3>Send Scorekeeper Message</h3>
                                    <InputGroup className="mb-3">
                                    <FormControl onChange={this.updateScorekeeperMessage}
                                     value={this.scorekeeperMessage} placeholder="Scorekeeper Message"
                                    />
                                    
                                      <Button onClick={this.sendScorekeeperMessage} variant="outline-success">Send</Button>
                                      
                                  </InputGroup>
                                  

                                  <h3>Run Draws</h3>
                                    {this.processing_draws == '' && <p>This will check-in all competitors that are checked in at reg and run the draws.</p>}
                                    {this.processing_draws != '' && <p>{this.processing_draws}</p>}
                                    {this.confirm_draws == false && this.processing_draws == '' && <Button onClick={this.setConfirm} variant="outline-success">Run Draws</Button>}
                                    {this.confirm_draws && this.processing_draws == '' && <Button onClick={this.checkInAll} variant="success">Confirm</Button>}
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          
                        </Tab.Content>
                      </Col>
                    </Row>
                </Tab.Container>
            </Container>
            </Col>
          </Row>
      </Container>;
    }
}

class CoordinatorDivisionDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        this.division = props.division;
        
        this.ring = null;
        this.order = 'name' ;
        this.shuffles = [];
        this.comp_alerts = [];
        this.start_alerts = [];
        
        this.setShuffle = this.setShuffle.bind(this);
        this.clearShuffle = this.clearShuffle.bind(this);
        
        this.standing = null;
        this.shuffle = null;
        
        this.loadExtra();
        
        this.compAddSearchState = '';
        
        this.competitorSearchList = this.competitorSearchList.bind(this);
        this.addCompSearchUpdate = this.addCompSearchUpdate.bind(this);
        this.teamCompSearchUpdate = this.teamCompSearchUpdate.bind(this);
        this.teamAddSearchState = '';
        this.loadExtra = this.loadExtra.bind(this);
        this.restoreCurrentShuffle = this.restoreCurrentShuffle.bind(this);
        this.splitTypeUpdate = this.splitTypeUpdate.bind(this);
        this.dsplitTypeUpdate = this.dsplitTypeUpdate.bind(this);
        this.splitMapNumberUpdate = this.splitMapNumberUpdate.bind(this);
        this.splitSubmit = this.splitSubmit.bind(this);
        this.moveSelectedRing = this.moveSelectedRing.bind(this);
        this.importRequestSelectedRing = this.importRequestSelectedRing.bind(this);
        
        this.compAddSearch = [];
        
        this.move_ring_id = '';
        this.import_ring_id = '';
        
        this.split_type = 'custom';
        this.dsplit_type = 'without_mapping';
        
        console.log(this.division);
        
        this.move_merge_division_filter = '';
        this.move_merge_division_selected_id = '';
        
        this.remove_restrictions = null;
        this.need_weigh_in = null;
        
        this.protest = null;
        this.submitResponse = this.submitResponse.bind(this);
        this.showNewProtest = this.showNewProtest.bind(this);
        this.hideNewProtest = this.hideNewProtest.bind(this);
        this.addSearchToDivision = this.addSearchToDivision.bind(this);
    }
    
    getMergeDivisions() {
        var divisions = this.interface.allDivisions();
        var t = this;
        divisions = divisions.filter(function(value, index, arr){ 
            return value.status == 0 && value.id != t.division.id;
        });
        
        if(this.move_merge_division_filter.localeCompare('') != 0) {
            const name_filter =this.move_merge_division_filter.toLowerCase(); 
            divisions = divisions.filter(function(value, index, arr){ 
                return value.name.toLowerCase().indexOf(name_filter) >=0 || value.code.toLowerCase().indexOf(name_filter) >=0 ;
           });
        } else {
            return [];
        }
        return divisions;
    }
    
    componentDidMount(){       
        window.APP.registerOnReload(this);
    }    

    componentWillUnmount() {
        window.APP.unRegisterOnReload(this);
    }
    
    postReload() {
        if(this.division != null) {
            this.division = this.interface.findDivision(this.division.id);
        }
        this.loadExtra();
        
        if(this.protest != null) {
            this.protest = this.division.protest.find((x) => x.id == this.protest.id);
        } 
        
        if(this.standing != null) {
            this.standing = this.division.standings.find((x) => x.id == this.standing.id);
        } 
        
        this.shuffle = null;
    }
    
    submitResponse() {
        if(this.p_status == null) {
            alert('Status required');
            return;
        }
        
        if(this.p_response == '' || this.p_response == null) {
            alert('Response required');
            return;
        }
        
        if(this.p_refund == null) {
            alert('Refund required');
            return;
        }
        
        postMaster.toSendData('/protest/' + this.protest.id +'/respond', 
        {
            result:this.p_status,
            reason:this.p_response,
            refund:this.p_refund,
            note: this.p_note
        }, false 
        );
    }
    
    restoreCurrentShuffle() {
        
        if(this.shuffle == null) {
            return;
        }
        
        let text = '?';
        this.shuffle.standings.forEach( (value, index, self2) => {
            text = text + value.standing + '=' + value.order + '&';
        }) 
         
        postMaster.toSend( '/tournament-division/'+this.division.id+'/shuffle' + text );        
    }
    
    teamCompSearchUpdate(event) {
        this.teamAddSearchState = event.target.value;
        this.setState( {teamAddSearchState : this.teamAddSearchState} )
    }
    
    addCompSearchUpdate(event) {
        var t = this;
        var k = new String(event.target.value);
        this.compAddSearchState = event.target.value;
        this.setState({compAddSearchState: this.compAddSearchState})
        setTimeout(function(){ t.addCompSearchUpdateCheck(k); }, 500 );        
               
        
    }
    
    addCompSearchUpdateCheck(value) {
    
        if(value != this.compAddSearchState) { 
            return;
        }
    
        postMaster.immediateGet("/tournament-division/" + this.division.id + '/available-ringside?search=' + this.compAddSearchState).then(res => res.json())
          .then(
             (result) => {
                 this.compAddSearch = [];
                 result.forEach(r => {
                   this.compAddSearch.push(r);
               });

               this.setState( {compAddSearch : this.compAddSearch} )

             }
           );
    }
    
    addCompToDivision(comp) { 
    
        var extra = '';
        if(this.division.is_team == 1) {
            extra = '?team=' + this.teamAddSearchState;
        }
    
        postMaster.toSend('/tournament-division/'+ this.division.id + '/add-comp/' + comp.reg_id + extra );

    }
    
    addSearchToDivision() { 
    
        if(!this.compAddSearchState || this.compAddSearchState == '' ) {
            return;
        }
    
        var extra = '';
        if(this.division.is_team == 1) {
            extra = '&team=' + this.teamAddSearchState;
        }
    
        postMaster.toSend('/tournament-division/'+ this.division.id + '/add-comp-reg?name=' + this.compAddSearchState + extra );

    }
    
    competitorSearchList() {
        return this.compAddSearch.map( (comp ) => 
        <ListGroup.Item onClick={ () => this.addCompToDivision(comp)  } style={{ color:'black' }} as="li">{comp.name}</ListGroup.Item>
          );
    }
    
    loadExtra() {
         var hold = this;
        postMaster.immediateGet("/tournament-division/" + this.division.id + '/coord').then(res => res.json())
       .then(
          (result) => {              
            this.shuffles = result.shuffles;
            this.shuffles.forEach((s) => {
                s.time = new Date(s.time);
            })
            this.comp_alerts = result.planned_comp_conflicts;
            this.start_alerts = result.start_alerts;
            this.remove_restrictions = result.remove_restrictions;
            this.need_weigh_in = result.need_weigh_in;
            
            if(result.top_fights && this.division.fights.length == 0) {
                result.top_fights.forEach((t)=>{                     
                    hold.division.addFight(t);
                });
                
                this.division.fights.forEach(fight => {
                    fight.is_editable = true;
                    fight.is_viewable = true;
                });

                this.division.fights.forEach(fight => {
                    fight.check();
                });
            }
            
            
            result.standings.forEach(rstanding => {
                if(rstanding.scores) {
                    let s = hold.division.getComp(rstanding.id);
                    if(s == null) return;
                    rstanding.scores.forEach(score => { console.log('updated score'); console.log(score);
                        if(score.key < 0) {
                            s.up_scores[score.key * -1] = {judge:score.key,score:score.score,drop:score.drop,og_score:score.og_score};
                        } else {
                            s.scores[score.key] = {judge:score.key,score:score.score,drop:score.drop,og_score:score.og_score};
                        }
                    });
                }
            });
            
            
            window.APP.reset();
          }
        );
        
    }
    
    setShuffle(s) {
        this.shuffle = s;
        this.setState({shuffle: this.shuffle});
    }
    
    clearShuffle() {
        this.shuffle = null;
        this.setState({shuffle: this.shuffle});
    }
    
    setCheckinOrder(o) {
        this.order = o;
        this.setState({order: this.order});
    }
    
    setStanding(s) {
        this.standing = s;
        this.setState({standing: this.standing});
    }
    
    clearStanding() {
        this.standing = null;
        this.setState({standing: this.standing});
    }

    competitorSeeding(comp, value) {
        comp.setSeeding(value);
        postMaster.toSend('/tournament-standing/' + comp.id + '/seeding/' + value );
        
        comp.members.forEach( (st, index, self2) => {
          if (st.id == comp.id) return; 
          st.setSeeding(value);
           postMaster.toSend('/tournament-standing/' + st.id + '/seeding/' + value )
        });        
        this.setState({current_division: this.division});
    }
    
    checkCompetitor(comp, value) {
        if(comp.members.length == 0) {

            comp.check(value);
            postMaster.toSend('/tournament-standing/' + comp.id + '/checkin/' + value )
        } else {
            comp.members.forEach( (st, index, self2) => {
                this.checkCompetitor(st, value);
            });             
        }
        this.setState({current_division: this.division});
    }
    
    changeMergeDivisionSelection(value) {           
        this.move_merge_division_filter = value;
        this.setState({move_merge_division_filter: value});
    }
    
    selectMergeDivisionSelection(value) {           
        this.move_merge_division_selected_id = value;
        this.setState({move_merge_division_selected_id: value});
    }
    
    mergeIntoDivision(value) {        
        postMaster.toSendData('/tournament-division/' + this.division.id + '/merge?ajax=1',{merge_into_division:value.id }, false );
    
        this.move_merge_division_selected_id = '';
        this.setState({move_merge_division_selected_id: ''});
    }
    
    changeMoveRingSelection(value) {           
        this.move_ring_id = value;
        this.setState({move_ring_id: value});
    }
    
    changeImportRingSelection(value) {           
        this.import_ring_id = value;
        this.setState({import_ring_id: value});
    }

    moveSelectedRing() {;
       postMaster.toSend('/move-tournament-division/single?ajax=1&division_id=' +  this.division.id + '&ring_id=' + this.move_ring_id + '&send_notification=' + this.interface.send_ring_notifications );
    }
    
    importRequestSelectedRing() {;
       postMaster.toSend('/tournament-division/'+this.division.id+'/action/import_request?ring_id='+this.import_ring_id);
    }
    
    splitTypeUpdate(event) {
        this.split_type =  event.target.value;
        this.setState({split_type: event.target.value });        
    }
    
    dsplitTypeUpdate(event) {
        this.dsplit_type =  event.target.value;
        this.setState({dsplit_type: event.target.value });        
    }

    splitMapNumberUpdate(event) {
        this.split_map_number =  event.target.value;
        this.setState({split_map_number: event.target.value });        
    }
    
    splitSubmit() {
        if(this.splitNeedsMapping() && (!this.split_map_number || this.split_map_number < 0) ) {
            alert('You need to specify a mapping value');
            return;
        }
        
        var data = '';
        if(this.split_type == 'custom') {
            this.division.standings.forEach((x) => {
                data = data + '&' +x.id + '=' + x.move; 
                x.members.filter(m=>m.id != x.id).forEach((m)=>{
                    data = data + '&' +m.id + '=' + x.move; 
                })
            });
        }
        postMaster.toSend('/tournament-division/' + this.division.id + '/action/split' + '?comp=' + this.split_type + '&type=' + this.dsplit_type + '&map_num=' + this.split_map_number + data);
        alert('split');
    }
    
    actionFire(action) {
        var t= this;
        postMaster.immediateGet('/tournament-division/' + this.division.id + '/action/' + action)
                .then(()=>{ t.loadExtra(); } ) ;
    }
    
    standingActionFire(action) {
        var t= this;
        postMaster.immediateGet('/tournament-standing/' + this.standing.id + '/action/' + action) ;
    }
    
    splitNeedsMapping() {
        var needs = [
            'with_mapping',
            'parent',
        ];
        return needs.includes(this.dsplit_type);
    }
    
    setActiveProtest(p) {
        this.p_status = null;
        this.p_response = null;
        this.p_refund = null;
        this.p_note = null;
        this.protest = p;
        this.setState({protest:p,p_refund:null,p_response:null,p_status:null,p_note:null });
    }

    showNewProtest() {
        this.new_protest = true;
        this.setState({new_protest:false});
    }
    
    hideNewProtest() {
        this.new_protest = false;
        this.setState({new_protest:false});
    }

    resetShuffle() {
       postMaster.toSend( '/tournament-division/'+this.division.id+'/shuffle?reset=1' );       
    }
    
    closeDivision() {                
        if(this.division.standings.length > 0 && !window.confirm("This division has competitors - are you sure you want to close it?") ) { 
            return;            
        }
        postMaster.toSend('/tournament-ring/'+ this.division.ring.id + '/division/' + this.division.id + '/status/' + 3 + '?admin=1' );
        
        this.division.complete();
        window.APP.reset();
    }
    
    finishSplit() { 
        var div = this.current_division;        
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 + '?admin=1' );
        this.division.completeSplit();
        window.APP.reset();
    }

    render() { 
        var divdisp = this;
        var div = this.division;
        
        return <Container>
        {this.new_protest && <ProtestModal onHide={this.hideNewProtest} division={this.division} ></ProtestModal>}
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                   <Card.Header ><Button style={{float:'left' }} variant='primary' onClick={()=>{ this.interface.clearDivision() }} >Back</Button>{this.division.name}</Card.Header>
                    <Card.Body >
                        <Container>
                            <Row style={{marginBottom:'10px'}} >
                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                    {this.division.ring && this.division.ring.name}<br />
                                { this.division.ring && <Button variant='primary' onClick={ ()=>{ this.interface.setRing(this.division.ring.id) } }><i class="fas fa-share"></i></Button>}
                                </Col> 
                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                {this.division.niceStatus() }
                                </Col>  
                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                { this.division.codePop() }
                                </Col> 
                                <Col md='2' style={{ fontSize:'0.7em' }}>
                                    { this.division.printDurationEstimate() }
                                </Col> 
                                <Col md='3' style={{ fontSize:'0.7em' }}>
                                    {this.division.estimated_start.toLocaleDateString(window.APP.date_locale,window.APP.date_options)} { this.division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                </Col> 
                            </Row>
                            <Row><Col md='12'>{this.division.upperProgressBar() }</Col></Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
              <Col xs={12}>
                {this.division.scorekeeper_warning && <Col xs={12}>
                                <Alert variant="danger" style={{ textAlign:'center' }}>
                                    <p>{this.division.scorekeeper_warning}</p>
                                    </Alert></Col>
                            }

                { this.division.child_divisions.length > 0 && this.division.status < 3   && <Row ><Col xs={12}>
                    <Alert variant="warning" style={{ textAlign:'center' }}>
                        <p>This is a parent division you must divide the competitors into child divisions</p>
                        {this.division.ring && <Button onClick={ ()=>{ this.closeDivision(); } } variant="danger">Mark Complete</Button>}
                        </Alert></Col></Row>
                }
                
                { this.division.child_divisions.length == 0 && this.division.status == 0 && this.division.standings.length == 0 && <Row ><Col xs={12}>
                    <Alert variant="warning" style={{ textAlign:'center' }}>
                        <p>This division is empty</p>
                        {this.division.ring && <Button onClick={ ()=>{ this.closeDivision(); } } variant="danger">Mark Complete</Button>}
                        </Alert></Col></Row>
                }
                
                {this.division.ring_imported.map(x=> <Col xs={12}>
                    <Alert variant="primary" style={{ textAlign:'center' }}>
                    <p>This division is imported into {x.name} <Button variant='primary' onClick={ ()=>{ this.interface.setRing(x.uuid) } }><i class="fas fa-share"></i></Button></p>
                    </Alert></Col> )}
            
                {this.division.ring_import_requests.map(x=> <Col xs={12}>
                    <Alert variant="warning" style={{ textAlign:'center' }}>
                    <p>There is a request to import this division into {x.name} <Button variant='primary' onClick={ ()=>{ this.interface.setRing(x.uuid) } }><i class="fas fa-share"></i></Button></p>
                    </Alert></Col> )}
            
            </Col>
              <Col xs={12}>
              {this.division.protestsDisplay(this) }
              </Col>
              <Col xs={12}>
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                          <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="first">Competitors</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="admin">Admin</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">Shuffles</Nav.Link>
                                </Nav.Item>
                                {this.division.child_divisions.length == 0 && this.division.config_type == 'scores' &&  <Nav.Item>
                                  <Nav.Link eventKey="third">Scores</Nav.Link>
                                </Nav.Item>}
                                {this.division.child_divisions.length == 0 && this.division.config_type == 'sparring' &&  <Nav.Item>
                                  <Nav.Link eventKey="brackets">Brackets</Nav.Link>
                                </Nav.Item>}
                                {this.division.child_divisions.length > 0 &&  <Nav.Item>
                                  <Nav.Link eventKey="child_splits">Child Division Split</Nav.Link>
                                </Nav.Item>}
                                <Nav.Item>
                                  <Nav.Link eventKey="fourth">Comp Alerts {this.comp_alerts.length > 0 && <Badge variant="danger">{this.comp_alerts.length}</Badge>} </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="fifth">Start Alerts {this.start_alerts.length > 0 && <Badge variant="danger">{this.start_alerts.length}</Badge>} </Nav.Link>
                                </Nav.Item>
                                {this.division.child_divisions.length == 0 && <Nav.Item>
                                  <Nav.Link eventKey="split">Split</Nav.Link>
                                </Nav.Item>}
                                <Nav.Item>
                                  <Nav.Link eventKey="protest">Protests</Nav.Link>
                                </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header >Competitors</Card.Header>
                                    <Card.Body >
                                    <div style={{ textAlign:'right' }}>
                                        {this.division.status == 0 && divdisp.division.shuffled == 0 && <Button 
                                        
                                        onClick={ ()=>{ 
                                            divdisp.division.standings.forEach((comp)=> {
                                                if(comp.members.length == 0) {
                                                    if(comp.is_blocked != 'Yes') {
                                                        if(comp.checked_in == true) {
                                                            return;
                                                        }                                                        
                                                        comp.check(true);        
                                                        postMaster.toSend('/tournament-ring/'+ divdisp.division.ring.id + '/standing/' + comp.id + '/checkin/true');
                                                    }
                                                } else {
                                                    comp.members.forEach( (st, index, self2) => {
                                                        if(st.is_blocked != 'Yes') {
                                                            if(st.checked_in == true) {
                                                                return;
                                                            }
                                                            st.check(true);        
                                                            postMaster.toSend('/tournament-ring/'+ divdisp.division.ring.id + '/standing/' + st.id + '/checkin/true');
                                                        }
                                                    });
                                                }
                                            });
                                        }}
                                        style={{ margin:'5px' }} variant="primary">Check All In</Button>}
                                        {this.division.status == 0 &&  divdisp.division.shuffled == 0 && <Button style={{ margin:'5px' }} 
                                        onClick={()=>{ divdisp.setCheckinOrder('ordered'); divdisp.division.coordShuf(); }}    
                                        variant="danger">Shuffle</Button>}
                                        
                                        {divdisp.division.config_type == 'scores' && divdisp.division.shuffled > 0 &&  !divdisp.division.current_comp && this.division.hasDuplicateCompetitors() && <Button style={{ margin:'5px' }} 
                                        onClick={()=>{ divdisp.division.deDuplicateComps(); }}    
                                        variant="danger">DeDuplicate Competitors</Button>}
                                        
                                        {this.division.status == 0 &&  divdisp.division.shuffled == 0 && this.division.hasDuplicateChecked() && <Button style={{ margin:'5px' }} 
                                        onClick={()=>{ divdisp.division.unCheckDups(); }}    
                                        variant="danger">Uncheck Duplicate Competitors</Button>}
                                    </div>
                                        {this.division.shuffle_text != '' && <Alert variant={'primary'} key={this.division.shuffle_text}>
                                            {this.division.shuffle_text}
                                        </Alert>}
                                        <Table responsive variant='dark'>
                                        <thead>
                                        <tr><td colspan='2'></td>
                                            <th style={{ textAlign:'left' }} onClick={()=>this.setCheckinOrder('name')}>{this.order != 'name' && <i class="fas fa-sort"></i>} Name</th>
                                            <th onClick={()=>this.setCheckinOrder('checked_in')}>{this.order != 'checked_in' && <i class="fas fa-sort"></i>} Status</th>
                                            <th onClick={()=>this.setCheckinOrder('seed')}>{this.order != 'seed' && <i class="fas fa-sort"></i>} Seed</th>
                                            <th onClick={()=>this.setCheckinOrder('ordered')}>{this.order != 'ordered' && <i class="fas fa-sort"></i>} Order</th>
                                            <th onClick={()=>this.setCheckinOrder('place')}>{this.order != 'place' && <i class="fas fa-sort"></i>} Place</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                            {
                                            this.division.standings
                                                    .sort(function (a, b){
                                                        if(divdisp.order == 'name') {
                                                            return a.name.localeCompare(b.name);
                                                        }    
                                                        return a[divdisp.order] - b[divdisp.order]
                                                        })
                                                    .map((comp) => {

                                                    return  <tr>
                                                                <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                                                                <td style={{ width:'50px', verticalAlign:'middle' }} >
                                                                    {comp.members.length == 0 && comp.country != '' && <OverlayTrigger placement={'top'}
                                                                    overlay={
                                                                      <Tooltip>
                                                                        {comp.country_name}
                                                                      </Tooltip>
                                                                      }
                                                                    ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                                                                     </td>

                                                                <th style={{ textAlign:'left' }} >{comp.name}</th>
                                                                <td>{comp.checked_in && <i class="text-success fas fa-check-circle"></i>}{!comp.checked_in && <i class="text-danger fas fa-times-circle"></i>}</td>
                                                                <td>{comp.seeding}</td>
                                                                <td>{comp.ordered}</td>
                                                                <td>{comp.place}</td>
                                                                <td><Button onClick={ ()=>this.setStanding(comp) } variant='primary'><i class="fas fa-share"></i></Button></td>
                                                            </tr>;
                                                    }
                                                  )
                                            }
                                            
                                            { this.division.status == 0 && 
                                                    <tr><th colspan='3'>Add Competitor</th>
                                                                <td colspan='3'>
                                                                    <Form.Control type="text" placeholder="search name" onChange={this.addCompSearchUpdate} value={this.compAddSearchState} />
                                                                    <ListGroup as="ul">
                                                      {this.competitorSearchList()}  
                                                    </ListGroup>


                                                  </td>
                                                  <td colspan="2"><Button disabled={(!this.compAddSearchState || this.compAddSearchState == '' ) ?'disabled':'' } onClick={this.addSearchToDivision} variant={'danger'}>New Registration</Button>
                                                  </td></tr>
                                                  }
                                                  
                                            { this.division.status == 0 && this.division.is_team == 1 && 
                                                    <tr><th colspan='3'>To Team</th>
                                                                <td colspan='5'>
                                                                    <Form.Control type="text" placeholder="team name" onChange={this.teamCompSearchUpdate} value={this.teamAddSearchState} />
                                             
                                                  </td>
                                                  </tr>
                                                  }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              <Tab.Pane eventKey="child_splits">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header >Competitors</Card.Header>
                                    <Card.Body >
                                    {this.division.child_divisions.length > 0 && 
                                                                    <Table variant="dark">
                                                            <tr>
                                                            <th colspan="3">Name</th>
                                                            <th>Age</th>
                                                            <th>Weight</th>
                                                            <th>Gender</th>
                                                            <th>Rank</th>
                                                            <th>Division</th>
                                                            </tr>
                                                            {this.division.getCheckedIn().map( x => <tr>
                                                                <td style={{ width:'50px' }}>{x.photo != '' &&  <img style={{ width: '40px'}} src={x.photo} /> }</td>
                                                                     <td style={{ width:'50px', verticalAlign:'middle' }}>
                                                                {x.country != '' && <OverlayTrigger placement={'top'}
                                                          overlay={
                                                            <Tooltip>
                                                              {x.country_name}
                                                            </Tooltip>
                                                          }
                                                          ><img style={{ 'width':'50px' }} src={x.country}   ></img></OverlayTrigger> }
                                                           </td>
                                                                <td style={{ textAlign:'left' }}>{x.name}</td>


                                                                    <td>{x.age}</td>
                                                                    <td>{x.getWeightString()}</td>
                                                                    <td>{x.gender}</td>
                                                                    <td>{x.rank}</td>
                                                                    <td>{
                                                                            this.division.child_divisions.map(d => <OverlayTrigger
                                                                                key={'top'}
                                                                                placement={'top'}
                                                                                overlay={
                                                                                  <Tooltip>
                                                                                  <span style={{ fontSize:'1.5em' }}>{d.name}</span>
                                                                                  </Tooltip>
                                                                                }
                                                                  >
                                                                  <Button style={{ margin:'3px' }} variant={x.child_division_set_to == d.id ? 'primary' : 'outline-primary'} onClick={()=>{ x.child_division_set_to = d.id; window.APP.reset();} }>{d.code}</Button>
                                                                    </OverlayTrigger>)     
                                                                    }</td>
                                                </tr> )}                                                                           
                                                </Table>}
                                                <Button onClick={()=>{ this.division.completeSplit(); }} variant={'success'}>Complete Division and Split</Button>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              <Tab.Pane eventKey="admin">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header >Admin</Card.Header>
                                    <Card.Body >
                                        <Table responsive variant='dark'>
                                            <tbody>
                                            {this.interface.send_movement_notifications && <tr>
                                                <td>
                                                    <div  className="mb-3">
                                                        <Form.Check checked={(this.interface.send_ring_notifications) ? 'checked' : ''}
                                                        onChange={this.interface.ringMovementNotificationsClick}
                                                        id={'singledivisoin-notifications'} 
                                                          type={'checkbox'}
                                                          label='Send Ring Movement Notifications'
                                                        />
                                                    </div>
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td>Move To Ring</td>
                                                <td><Form.Control as="select" size="lg" value={this.move_ring_id}  onChange={(event) => this.changeMoveRingSelection(event.target.value)} >
                                                    {this.interface.rings.map(
                                                        (input) => 
                                                            <option value={input.id}>{input.name}</option>
                                                        )
                                                    }
                                                  </Form.Control>
                                                </td>
                                                <td>
                                                    <Button variant="success" onClick={this.moveSelectedRing} >Submit</Button>
                                                </td>
                                            </tr>
                                            
                                            {this.division.status == 0 && <tr>
                                                <td>Merge into division</td>
                                                <td colspan='2'><Form.Control type="text" size="lg" value={this.move_merge_division_filter}  onChange={(event) => this.changeMergeDivisionSelection(event.target.value)} >
                                                
                                                  </Form.Control>
                                                </td>
                                            </tr>}
                                            </tbody>
                                        </Table>
                                        {this.division.status == 0 && <Table responsive variant='dark'>
                                            <tbody>
                                            {this.getMergeDivisions().map(
                                                (input) => 
                                                    <tr><td>{input.code} | {input.name}</td><td>{this.move_merge_division_selected_id != input.id && <Button onClick={()=>{ this.selectMergeDivisionSelection(input.id) }}>Merge To</Button>}{this.move_merge_division_selected_id == input.id && <Button variant='warning' onClick={()=>{ this.mergeIntoDivision(input) }}>Confirm</Button>}</td></tr>
                                                )
                                            }
                                            
                                            {this.remove_restrictions !== null && <tr>
                                                <td>Ignores Restrictions</td>
                                                <td>
                                                {this.remove_restrictions == 0 && <span>No</span>}
                                                {this.remove_restrictions == 1 && <span>Yes</span>}
                                                </td>
                                                <td>
                                                {this.remove_restrictions == 0 && <Button onClick={()=>{ divdisp.actionFire('ignore_restrictions') }} variant='danger'>Ignore</Button>}
                                                {this.remove_restrictions == 1 && <Button onClick={()=>{ divdisp.actionFire('enfore_restrictions') }} variant='success'>Enforce</Button>}
                                                </td>
                                            </tr>}
                                            
                                            {this.need_weigh_in !== null && <tr>
                                                <td>Needs Weigh In</td>
                                                <td>
                                                {this.need_weigh_in == -1 && <span>No Weight Restrictions</span>}
                                                {this.need_weigh_in == 0 && <span>No</span>}
                                                {this.need_weigh_in == 1 && <span>Yes</span>}
                                                </td>
                                                <td>
                                                {this.need_weigh_in == 0 && <Button onClick={()=>{ divdisp.actionFire('enfore_weight') }} variant='success'>Require</Button>}
                                                {this.need_weigh_in == 1 && <Button onClick={()=>{ divdisp.actionFire('ignore_weight') }} variant='danger'>Ignore</Button>}
                                                </td>
                                            </tr>}
                                            </tbody>
                                        </Table>}
                                        
                                        {this.division.status == 1 && this.division.config_type == 'sparring' && <Table responsive variant='dark'>
                                            <tbody>
                                            <tr>
                                                <td>Request Import Into</td>
                                                <td>
                                                    <Form.Control as="select" size="lg" value={this.import_ring_id}  onChange={(event) => this.changeImportRingSelection(event.target.value)} >
                                                    {this.interface.rings.map(
                                                        (input) => 
                                                            <option value={input.id}>{input.name}</option>
                                                        )
                                                    }
                                                  </Form.Control>
                                                </td>
                                                <td>
                                                    <Button variant="success" onClick={this.importRequestSelectedRing} >Submit</Button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>}
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header >Shuffles</Card.Header>
                                    <Card.Body >
                                        <Table responsive variant='dark'>
                                            <tbody>
                                            {(this.division.shuffled == '1' ||this.division.shuffled == 1) && this.division.status == 0 && <tr><td colspan='2'><Button onClick={ () => { this.resetShuffle() }} variant='danger'>Reset Shuffle</Button></td></tr>}
                                            {this.shuffles.map((shuff) => {
                                                        
                                               return   <tr>
                                                <td>{shuff.time.toLocaleString(window.APP.time_locale, window.APP.time_options)}</td>
                                                <td><Button onClick={ ()=>this.setShuffle(shuff) } variant='primary'><i class="fas fa-share"></i></Button></td>
                                                </tr>;
                                               }
                                             )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                                
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%', marginTop:'15px'  }}>
                                    <Card.Header >Custom Shuffle</Card.Header>
                                    <Card.Body >
                                        <Button variant={'primary'}
                                            onClick={ ()=>{ 
                                                this.division.standings.forEach((x) => {
                                                        x.temp_ordered = x.ordered;
                                                    });
                                                    window.APP.reset();
                                                } }
                                        >Reset Form Current</Button>
                                        <Table responsive variant='dark'>
                                            { 
                                            this.division.standings
                                                    .sort(function (a, b){
                                                        if(divdisp.order == 'name') {
                                                            return a.name.localeCompare(b.name);
                                                        }    
                                                        return a[divdisp.order] - b[divdisp.order]
                                                        })
                                                    .map((comp) => {

                                                    return  <tr>
                                                                <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                                                                <td style={{ width:'50px', verticalAlign:'middle' }} >
                                                                    {comp.members.length == 0 && comp.country != '' && <OverlayTrigger placement={'top'}
                                                                    overlay={
                                                                      <Tooltip>
                                                                        {comp.country_name}
                                                                      </Tooltip>
                                                                      }
                                                                    ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                                                                     </td>

                                                                <th style={{ textAlign:'left' }} >{comp.name}</th>
                                                                <td>{comp.checked_in && <i class="text-success fas fa-check-circle"></i>}{!comp.checked_in && <i class="text-danger fas fa-times-circle"></i>}</td>
                                                                <td><Form.Control value={comp.temp_ordered} type="number" onChange={ (event)=>{ comp.temp_ordered = event.target.value; window.APP.reset(); } }>
                                                                    </Form.Control>    </td>
                                                            </tr>;
                                                    }
                                                  )
                                            }
                                        </Table>
                                        <Button variant={'danger'}
                                            onClick={ ()=>{ 
                                                    let text = '?';
                                                    this.division.standings.forEach( (value, index, self2) => {
                                                        text = text + value.id + '=' + value.temp_ordered + '&';
                                                    });

                                                    postMaster.toSend( '/tournament-division/'+this.division.id+'/shuffle' + text );    
                                                    window.APP.reset();
                                                } }
                                        >OverRule Shuffle</Button>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              
                              <Tab.Pane eventKey="brackets" onEntered={() => { window.APP.reset(); } }>
                                  {window.APP.view_fight && 
                                            <Modal size="lg" show={window.APP.view_fight != null} onHide={()=>{ window.APP.view_fight = null; window.APP.reset(); }}>
                                                <Modal.Header closeButton>
                                                  <Modal.Title>Fight Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Container>
                                                    {window.APP.view_fight.getWinner() && 
                                                                <Alert variant="success" style={{ textAlign:'center' }}>
                                                                    <Alert.Heading>{window.APP.view_fight.getWinner().name}</Alert.Heading>
                                                                    <p>{window.APP.view_fight.getWinner().name} has won this fight</p>
                                                                </Alert>
                                                            }
                                                        <Row style={{ marginTop:'15px' }}>
                                                        <Col><Card bg="dark"><Card.Body style={{ textAlign:'center',padding:'0px' }} >
                                                            <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>
                                                            <Col xs={4}>
                                                            <Card border={ (window.APP.view_fight.getWinner() && window.APP.view_fight.getLeftComp().id == window.APP.view_fight.getWinner().id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                                                                <Card.Header className="sparring-red">{window.APP.view_fight.getWinner() && window.APP.view_fight.getLeftComp().id == window.APP.view_fight.getWinner().id && <i class="fas fa-crown text-warning"></i>} {window.APP.view_fight.getLeftComp() && window.APP.view_fight.getLeftComp().name  } &nbsp; 
                                                                {window.APP.view_fight.getLeftComp().country != '' && 
                                                                                    <OverlayTrigger placement={'top'}
                                                                      overlay={
                                                                        <Tooltip>
                                                                          {window.APP.view_fight.getLeftComp().country_name}
                                                                        </Tooltip>
                                                                      }
                                                                      ><img style={{ 'width':'50px' }} src={window.APP.view_fight.getLeftComp().country}   ></img></OverlayTrigger> }
                                                                </Card.Header>
                                                                <Card.Body style={{ textAlign:'center', backgroundColor:'#610008' }} ><Card.Body style={{ textAlign:'center' }} >{window.APP.view_fight.getLeftComp() && window.APP.view_fight.getLeftComp().team}</Card.Body>
                                                                </Card.Body>
                                                            </Card>
                                                            </Col>

                                                            <Col xs={4}>
                                                            <Card bg="dark" style={{ color:'white',height:'100%' }} >
                                                                <Card.Body style={{ textAlign:'center',padding:'0px',backgroundColor: '#0f0f0f' }} >
                                                                    <Col xs={12} style={{ textAlign:'center',fontSize:'3.5em',lineHeight:'1' }}>
                                                                    
                                                                    </Col>
                                                                    </Card.Body>
                                                            </Card>

                                                            </Col>

                                                            <Col xs={4}>
                                                            <Card border={ (window.APP.view_fight.getWinner() && window.APP.view_fight.getRightComp().id == window.APP.view_fight.getWinner().id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                                                                    <Card.Header className={"sparring-" + window.APP.sparring_color}  >{window.APP.view_fight.getWinner() && window.APP.view_fight.getRightComp() && window.APP.view_fight.getRightComp().id == window.APP.view_fight.getWinner().id && <i class="fas fa-crown text-warning"></i>} {window.APP.view_fight.getRightComp() && window.APP.view_fight.getRightComp().name  } &nbsp; {window.APP.view_fight.getRightComp().country != '' && 
                                                                                <OverlayTrigger placement={'top'}
                                                                            overlay={
                                                                              <Tooltip>
                                                                                {window.APP.view_fight.getRightComp().country_name}
                                                                              </Tooltip>
                                                                            }
                                                                            ><img style={{ 'width':'50px' }} src={window.APP.view_fight.getRightComp().country}   ></img></OverlayTrigger> }</Card.Header>

                                                                    <Card.Body style={{ textAlign:'center', backgroundColor:('#' + ( (window.APP.sparring_color == 'blue') ? '011c47' : 'afb4bd') ) }} >{window.APP.view_fight.getRightComp() && window.APP.view_fight.getRightComp().team}</Card.Body>
                                                            </Card>
                                                            </Col>

                                                            <Col xs={6}>
                                                            <Card border={ (window.APP.view_fight.getWinner() && window.APP.view_fight.getLeftComp().id == window.APP.view_fight.getWinner().id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                                                                <Card.Body style={{ textAlign:'center' }} >
                                                                    <Container>
                                                                    <Row style={{ paddingBottom:'20px' }}>
                                                                    <Col xs={5}>{window.APP.view_fight.getLeftComp() &&  window.APP.view_fight.getLeftComp().photoL != '' &&  <img style={{ width:  '98%',borderRadius:'5px'}} src={window.APP.view_fight.getLeftComp().photoL} /> }
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        { window.APP.view_fight.getLeftComp() && window.APP.view_fight.getLeftComp().weight && window.APP.view_fight.getLeftComp().weight != '' &&  <span>{window.APP.view_fight.getLeftComp().getWeightString()}</span> }<br/>
                                                                        { window.APP.view_fight.getLeftComp() && window.APP.view_fight.getLeftComp().age && window.APP.view_fight.getLeftComp().age != '' &&  <span>{window.APP.view_fight.getLeftComp().age} years</span> }<br/>
                                                                        { window.APP.view_fight.getLeftComp() &&  window.APP.view_fight.getLeftComp().height }
                                                                    </Col>
                                                                    <Col xs={3}>
                                                                    <Card>
                                                                        <Card.Body style={{ padding:'0',fontSize:'3em',color:'black',lineHeight:'1',paddingBottom:'4%'}}>{window.APP.view_fight.getLeftPoints() }</Card.Body>
                                                                    </Card>
                                                                    </Col>
                                                                    </Row>
                                                                    </Container>
                                                                </Card.Body>
                                                            </Card>
                                                            </Col>


                                                            <Col xs={6}>
                                                            <Card border={ (window.APP.view_fight.getWinner() && window.APP.view_fight.getRightComp().id == window.APP.view_fight.getWinner().id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                                                                <Card.Body style={{ textAlign:'center' }} >
                                                                    <Container>
                                                                    <Row style={{ paddingBottom:'20px' }}>
                                                                    <Col xs={3}>
                                                                        <Card>
                                                                            <Card.Body style={{ padding:'0',fontSize:'3em',color:'black',lineHeight:'1',paddingBottom:'4%'}}>{window.APP.view_fight.getRightPoints() }</Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        { window.APP.view_fight.getRightComp() && window.APP.view_fight.getRightComp().weight != '' && window.APP.view_fight.getRightComp().weight &&  <span>{window.APP.view_fight.getRightComp().getWeightString()}</span> }<br/>
                                                                        { window.APP.view_fight.getRightComp() && window.APP.view_fight.getRightComp().age != '' && window.APP.view_fight.getRightComp().age &&  <span>{window.APP.view_fight.getRightComp().age} years</span> }<br/>
                                                                        { window.APP.view_fight.getRightComp() &&  window.APP.view_fight.getRightComp().height }
                                                                    </Col>
                                                                    <Col xs={5}>{window.APP.view_fight.getRightComp() &&  window.APP.view_fight.getRightComp().photoL != '' &&  <img style={{ width:  '98%',borderRadius:'5px'}} src={window.APP.view_fight.getRightComp().photoL} /> }
                                                                    </Col>
                                                                    </Row>
                                                                    </Container>
                                                                </Card.Body>
                                                            </Card>
                                                            </Col>

                                                            </Row></Container>    

                                                        </Card.Body></Card></Col>                                                                                                                                                                                                            
                                                        </Row>  


                                                        <Row style={{ marginTop:'15px' }}>
                                                        <Col  ><Card bg="dark"><Card.Body style={{ textAlign:'center' }} >
                                                            <Table responsive variant='dark'>
                                                            <thead><tr><th>History</th></tr></thead>
                                                            { window.APP.view_fight.history.slice().reverse().map( call => {
                                                                    var on = '';
                                                                    var other = '';

                                                                    if(call.winner == 1) {
                                                                        return <tr>
                                                                        <td>{window.APP.view_fight.comp1.name} has won!</td>
                                                                        </tr>
                                                                    }

                                                                    if(call.winner == 2) {
                                                                        return <tr>
                                                                        <td>{window.APP.view_fight.comp2.name} has won!</td>
                                                                        </tr>
                                                                    }

                                                                    if(call.winner == -1) {
                                                                        return <tr>
                                                                        <td>Winner Cleared</td>
                                                                        </tr>
                                                                    }

                                                                    if(call.end_round != 0) {
                                                                       return <tr>
                                                                        <td>End of round ({ call.end_round })</td>
                                                                        </tr>
                                                                    }

                                                                    if(call.time_change != 0) {
                                                                       return <tr>
                                                                        <td>Manual Time Change ({ call.time_change })</td>
                                                                        </tr>
                                                                    }

                                                                    if(call.person && window.APP.view_fight.comp1 && call.person.id == window.APP.view_fight.comp1.id) {
                                                                        if(window.APP.view_fight.swapped == 1) {
                                                                            on = 'blue';
                                                                            other = 'red';
                                                                        } else {
                                                                            on = 'red';
                                                                            other = 'blue';
                                                                        }
                                                                    }

                                                                    if(call.person && window.APP.view_fight.comp2 && call.person.id == window.APP.view_fight.comp2.id) {
                                                                        if(window.APP.view_fight.swapped == 1) {                            
                                                                            on = 'red';
                                                                            other = 'blue';
                                                                        } else {
                                                                            on = 'blue';
                                                                            other = 'red';
                                                                        }
                                                                    }

                                                                    return <tr>
                                                                    <td>{call.points} to {on}{call.opposing_points != 0 && <span>, {call.opposing_points} to {other}</span>} {call.call && <span>for {call.call.name} on {on}</span> }</td>
                                                                    </tr>
                                                                    }
                                                                    ) }
                                                            </Table>                                                                                                                                                                                          
                                                        </Card.Body></Card></Col>                                                                                                                                                                                                            
                                                        </Row>
                                                    </Container>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={()=>{ window.APP.view_fight = null; window.APP.reset(); }}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>        
                                        }
                                  
                                {this.division.shuffle_text != '' && <Alert variant={'primary'} key={this.division.shuffle_text}>
                                            {this.division.shuffle_text}
                                        </Alert>}  
                                  
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Brackets {this.division.zoomed_fight && <div style={{float:'right'}}><Button variant="primary" onClick={()=>{ this.division.zoomed_fight = null; window.APP.reset(); }}>
                                        Clear Zoom
                                        </Button></div>}</Card.Header>
                                    <Card.Body >
                                        <BracketChart tv={0} div={this.division} > </BracketChart>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              
                              <Tab.Pane eventKey="third">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Scores</Card.Header>
                                    <Card.Body >
                                        <Table responsive variant="dark">
                                               {this.division.getPlaceOrder()
                                                    .map((comp) => {
                                                        const scoresList = comp.getScores()
                                                        .map((score) => 
                                                            <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }} >
                                                                {div.niceFormatScore(parseFloat(score.score) )} 

                                                                { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&

                                                                <i class="fas fa-long-arrow-alt-up text-success"></i>

                                                                }
                                                                { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) && 

                                                                <i class="fas fa-long-arrow-alt-down text-danger"></i>

                                                                }
                                                                </td>  
                                                         );
                                               return   <tr>
                                                <td>{comp.place}</td>
                                                <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td><td style={{ width:'50px', verticalAlign:'middle' }} >
                                                    {comp.country != '' && <OverlayTrigger placement={'top'}
                                                    overlay={
                                                      <Tooltip>
                                                        {comp.country_name}
                                                      </Tooltip>
                                                    }
                                                    ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                                               </td>
                                                <td>{comp.name}</td>
                                                {div.config_type == 'scores' && scoresList}
                                                {div.config_type == 'scores' && <td>{div.niceFormatScore(parseFloat(comp.getScoreTotal(this.division)) )}</td>}
                                                </tr>;
                                               }
                                             )}                                                                                    
                                        </Table>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              
                              <Tab.Pane eventKey="fourth">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Competitor Alerts</Card.Header>
                                    <Card.Body >
                                       {
                                        this.comp_alerts.map( (x) => 
                                            <Alert variant={'warning'}>
                                            {x}
                                          </Alert>
                                          )
                                    } 
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              
                              <Tab.Pane eventKey="fifth">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Instant Start Alerts</Card.Header>
                                    <Card.Body >
                                       {
                                        this.start_alerts.map( (x) => 
                                            <Alert variant={'warning'}>
                                            {x}
                                          </Alert>
                                          )
                                    } 
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              
                              <Tab.Pane eventKey="split">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Split</Card.Header>
                                    <Card.Body >
                                        {(this.division.shuffled == '1' ||this.division.shuffled == 1) && <Alert variant="danger" style={{ textAlign:'center' }}>
                                            This division already is shuffled or has brackets, splitting will also reset these.
                                        </Alert>}
                                        <Table responsive variant="dark">
                                            <tr>
                                                <td>
                                                    Competitor Split Type
                                                </td>
                                                <td>
                                                    <Form.Control value={this.split_type} as="select" onChange={this.splitTypeUpdate}>
                                                        {this.division.is_team === '0' && <option  value="random" >Random</option>}
                                                        {this.division.is_team === '0' && <option  value="seeding_group" >Seeding Group Based</option>}
                                                        {this.division.is_team === '0' && <option  value="gender" >Gender</option>}
                                                        <option  value="custom" >Custom</option>
                                                        <option  value="clone" >Clone</option>
                                                    </Form.Control>                                                                                       
                                                </td>                                                                                                                                    
                                            </tr>
                                            <tr>
                                                <td colspan="2" class='text-muted'>
                                                    {this.split_type == 'custom' && "This will start with them all in one division and you choose which ones get moved."}
                                                    {this.split_type == 'random' && this.division.is_team === '0' && "This will randomly split the competitors"}
                                                    {this.split_type == 'seeding_group' && this.division.is_team === '0'  && "This will split placing the A-C seeds in one division (parent if applicable) and D seeds in the other."}
                                                    {this.split_type == 'age'&& this.division.is_team === '0'  && "This will split the division based on the competing age of the competitors around a provided value."}
                                                    {this.split_type == 'gender' && this.division.is_team === '0'  && "This will split males into one division and females into another."}
                                                    {this.split_type == 'clone' && "This will put all competitors in both divisions."}
                                                </td>                                                                                                                                    
                                            </tr>
                                            <tr>
                                                <td>
                                                    Division Split Type
                                                </td>
                                                <td>
                                                    <Form.Control value={this.dsplit_type} as="select" onChange={this.dsplitTypeUpdate}>
                                                        <option  value="with_mapping" >Triangle Mapping</option>
                                                        <option  value="without_mapping" >Copy Mapping</option>
                                                        <option  value="parent" >Parent Mapping</option>
                                                    </Form.Control>                                                                                       
                                                </td>                                                                                                                                    
                                            </tr>

                                            <tr>
                                                <td colspan="2" class='text-muted'>
                                                    {this.dsplit_type == 'with_mapping' && "This will create 2 new divisions where the competitors will go into.  These two will the top X competitors back into the original division"}
                                                    {this.dsplit_type == 'without_mapping' && "This will result in both the original and one new division, with both following the original mapping rules - for example sending 2 to grands instead of 1.  This will result in both winning as they do not compete."}
                                                    {this.dsplit_type == 'parent' && "This will split the division into the original (a) and one new division (b).  The top X competitors from (b) will be mapped to (a) to compete.  The winners from (a) move on to grands like normal."}
                                                </td>                                                                                                                                    
                                            </tr>
                                            
                                            {this.splitNeedsMapping() && <tr>
                                                <td>
                                                    Custom Mapping Value
                                                </td>
                                                <td>
                                                    <Form.Control value={this.split_map_number} type="number" onChange={this.splitMapNumberUpdate}>
                                                    </Form.Control>                                                                                       
                                                </td>
                                            </tr>}
                                            </Table>
                                            
                                            {
                                            this.division.standings.filter((x)=> x.move == 0).length > 0 && <Button onClick={ ()=>{ div.standings.map((x)=>{x.move = 1;}); window.APP.reset();  }} variant='primary'>Check All</Button>
                                            }
                                            
                                            {
                                            this.division.standings.filter((x)=> x.move == 1).length > 0 && <Button onClick={ ()=>{ div.standings.map((x)=>{x.move = 0;}); window.APP.reset();  }} variant='danger'>Un-Check All</Button>
                                            }
                                            
                                            <Table responsive variant="dark"> 
                                            
                                            {this.split_type == 'custom' && <tbody><tr><td colspan='8'><h2>Competitors</h2></td></tr>
                                            { 
                                            this.division.standings
                                                    .sort(function (a, b){
                                                        if(divdisp.order == 'name') {
                                                            return a.name.localeCompare(b.name);
                                                        }    
                                                        return a[divdisp.order] - b[divdisp.order]
                                                        })
                                                    .map((comp) => {

                                                    return  <tr>
                                                            <td>
                                                                <Form.Group >
                                                                    <Form.Check id={'split_line_standing' + comp.id} type="checkbox" label="Division 2" checked={comp.move} onChange={ (event)=> { comp.move = event.target.checked; window.APP.reset(); } } />
                                                                </Form.Group>
                                                            </td>
                                                                                                                        
                                                                <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                                                                <td style={{ width:'50px', verticalAlign:'middle' }} >
                                                                    {comp.members.length == 0 && comp.country != '' && <OverlayTrigger placement={'top'}
                                                                    overlay={
                                                                      <Tooltip>
                                                                        {comp.country_name}
                                                                      </Tooltip>
                                                                      }
                                                                    ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                                                                     </td>

                                                                <th style={{ textAlign:'left' }} >{comp.name}</th>
                                                                <td>{comp.checked_in && <i class="text-success fas fa-check-circle"></i>}{!comp.checked_in && <i class="text-danger fas fa-times-circle"></i>}</td>
                                                                <td>{comp.age}</td>
                                                                <td>{comp.gender}</td>
                                                                <td>{comp.rank}</td>
                                                                <td><Button onClick={ ()=>this.setStanding(comp) } variant='primary'><i class="fas fa-share"></i></Button></td>
                                                            </tr>;
                                                    }
                                                  )
                                            }
                                            </tbody>}</Table>
                                            <Table responsive variant="dark">  
                                            {(!this.splitNeedsMapping() || (this.split_map_number && this.split_map_number > 0) ) && <tr><td colspan='2'><Button variant='success' onClick={this.splitSubmit}>Split</Button></td></tr>}
                                        </Table>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                              <Tab.Pane eventKey="protest">
                                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                    <Card.Header>Protests</Card.Header>
                                    <Card.Body >
                                        <Table responsive variant="dark">
                                        {div.protests.map((p) => { 
        
                                                                    const protestor = div.getComp(p.protestor_id);
                                                                    const protestee = div.getComp(p.protestee_id);

                                                                    return <tr>
                                                                        <td>
                                                                            {protestor && protestor.name}{!protestor && <span>?</span>} VS {protestee && protestor.name}{!protestee && <span>?</span>}
                                                                        </td>
                                                                        <td>
                                                                            {p.status}
                                                                        </td>
                                                                        <td>
                                                                        <Button onClick={()=>{ this.setActiveProtest(p) } }  ><i class="fas fa-eye"></i></Button>                                                                  
                                                                        </td>
                                                                    </tr>;  
                                                           }
                                                    )
                                            
                                            }
                                            <tr>
                                                <td colspan="3" class='text-center'>
                                                    <Button onClick={ this.showNewProtest}  >New</Button>                                                                  
                                                </td>
                                            </tr>
                                        </Table>
                                    </Card.Body>
                                </Card>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </Col>
            </Row>
            {this.protest && 
                <Modal size="lg" show={this.protest != null} onHide={()=>{ this.setActiveProtest(null) }}>
                    <Modal.Header closeButton>
                      <Modal.Title>{(div.getComp(this.protest.protestor_id))?  div.getComp(this.protest.protestor_id).name : <span>?</span>} VS {(div.getComp(this.protest.protestee_id))? div.getComp(this.protest.protestee_id).name : <span>?</span>}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive>
                           <tbody>
                                <tr><th>Status</th><td>{this.protest.status}</td></tr>
                                <tr><th>User</th><td>{this.protest.user}</td></tr>
                                <tr><th>On behalf Of</th><td>{(div.getComp(this.protest.protestor_id))?  div.getComp(this.protest.protestor_id).name : <span>?</span>}</td></tr>
                                <tr><th>Protestor</th><td>{(div.getComp(this.protest.protestee_id))?  div.getComp(this.protest.protestee_id).name : <span>?</span>}</td></tr>
                                <tr><th>Reason</th><td>{this.protest.reason}</td></tr>
                                {this.protest.complete && <tr><th>Response</th><td>{this.protest.response}</td></tr>}
                                {this.protest.complete && <tr><th>Refund</th><td>{(this.protest.refund == 0) ? 'No' : 'Yes'}</td></tr>}                           
                            
                            </tbody>                            
                        </Table>   
                        {!this.protest.complete && this.protest.status == "Awaiting Arbitration" && <div>
                            <h2>Response</h2>
                            <Table responsive>
                            <tr><th>Status</th><td>
                            <Form.Control as="select" size="lg" value={this.p_status}  onChange={(event) => { this.p_status = event.target.value; this.setState({p_status:event.target.value}); } }>
                                <option value=''>Please Select</option>
                                <option value='-3'>Withdrawn</option>
                                <option value='-1'>Failed</option>
                                <option value='1'>Passed</option>
                              </Form.Control>
                              </td></tr>
                            <tr><th>Response</th><td><Form.Control type='text' size="lg" value={this.p_response}  onChange={(event) => { this.p_response = event.target.value; this.setState({p_response:event.target.value}); } }></Form.Control></td></tr>
                            <tr><th>Refund</th><td><Form.Control as="select" size="lg" value={this.p_refund}  onChange={(event) => { this.p_refund = event.target.value; this.setState({p_refund:event.target.value}); } }>
                                <option value=''>Please Select</option>
                                <option value='no'>No</option>
                                <option value='yes'>Yes</option>
                              </Form.Control></td></tr>
                            <tr><th>Note</th><td><Form.Control type='text' size="lg" value={this.p_note}  onChange={(event) => { this.p_note = event.target.value; this.setState({p_note:event.target.value}); } }></Form.Control></td></tr>
                            
                              <tr><td class='text-center' colspan="2"><Button onClick={()=>{this.submitResponse(this.protest); }} variant='success'>Submit Response</Button></td></tr>
                              </Table>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{ this.setActiveProtest(null) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>        
            }
            
            {this.standing && 
                <Modal size="lg" show={this.standing != null} onHide={()=>{ this.clearStanding() }}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.standing.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive>
                           <tbody>
                           
                            {this.standing.blocked_reason != '' && this.standing.members.length == 0 &&
                                   <tr class="table-danger" ><th colspan="2">{this.standing.blocked_reason}</th></tr>       
                                   }
                            <tr>
                            {
                            this.standing.inputs.map(
                                    (input) => 
                                        <tr><th>{input.label}</th><td>{input.value}</td></tr>
                                    )
                                           }
                                <th>Seed</th>
                                <td>
                                <Form.Control as="select" size="lg" value={this.standing.seeding}  onChange={(event) => this.competitorSeeding(this.standing,  event.target.value)}>
                                    <option>A</option>
                                    <option>B</option>
                                    <option>C</option>
                                    <option>D</option>
                                    <option>E</option>
                                </Form.Control>
                              </td>
                            </tr>
                            {this.standing.members.length == 0 && <tr><th>Height</th><td>{this.standing.height}</td></tr>}
                            {this.standing.members.length == 0 && <tr><th>Weight</th><td>{this.standing.getWeightString()}</td></tr>}
                            {this.standing.members.length == 0 && <tr><th>Age</th><td>{this.standing.age}</td></tr>}
                            {this.standing.members.length == 0 && <tr><th>Gender</th><td>{this.standing.gender}</td></tr>}
                            {this.standing.members.length == 0 && <tr><th>Rank</th><td>{this.standing.rank}</td></tr>}
                            
                            {this.standing.members.length == 0 && <tr><th>Check-In</th><td>
                            {this.standing.members.length == 0 &&  this.standing.is_blocked != 'Yes' && <Form.Control as="select" size="lg" value={(this.standing.checked_in == true) ? 'true': 'false' }  onChange={(event) => this.checkCompetitor(this.standing,  event.target.value)}>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </Form.Control>}
                              {this.standing.members.length == 0 && this.standing.is_blocked == 'Yes' && <OverlayTrigger placement={'top'} overlay={<Tooltip>{this.standing.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={this.standing.blocked_symbol}></i></OverlayTrigger>  }
                              </td>
                              </tr>                            
                            }
                            {this.standing.remove_restrictions !== null && <tr><th>Ignore Restrictions</th><td>{this.standing.remove_restrictions == -1 && <span>Removed</span> }{this.standing.remove_restrictions == 1 &&  <span>Yes <Button onClick={()=>{ divdisp.standingActionFire('enforce_restrictions') }} variant='success'>Enforce</Button></span>} {this.standing.remove_restrictions == 0 && <span>No <Button onClick={()=>{ divdisp.standingActionFire('ignore_restrictions') }} variant='danger'>Ignore</Button></span>}</td></tr>}
                            
                            <tr>
                                <th>Ordered</th>
                                <td>{this.standing.ordered}</td>
                            </tr>
                            <tr>
                                <th>Place</th>
                                <td>{this.standing.place}</td>
                            </tr>
                            </tbody>  
                            {this.standing.members.length > 0 &&
                            <tbody>{
                                this.standing.members.map(
                                (input) => 
                        <tr><th>{input.name}</th>
                        <td>
                            <ButtonGroup>
                                {input.checked_in && input.is_blocked != 'Yes' && <Button onClick={() => this.checkCompetitor(input, false)} variant="danger">Check-Out <i class="fas fa-arrow-circle-up"></i></Button>}
                                {!input.checked_in  && input.is_blocked != 'Yes'  && <Button onClick={() => this.checkCompetitor(input, true)} variant="success">Check-In <i class="fas fa-arrow-circle-down"></i></Button>}
                             </ButtonGroup>
                            {input.is_blocked == 'Yes'  && <OverlayTrigger placement={'top'} overlay={<Tooltip>{input.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={input.blocked_symbol} ></i></OverlayTrigger>}
                        </td>
                        </tr>
                                )
                            }                      
                            </tbody>
                            }
                        </Table>                                                                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{ this.clearStanding() }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>        
            }
            
            {this.shuffle && 
                <Modal size="lg" show={this.shuffle != null} onHide={()=>{ this.clearShuffle() }}>
                    <Modal.Header closeButton>
                      <Modal.Title>{this.shuffle.time.toLocaleString(window.APP.time_locale, window.APP.time_options)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive>
                           { this.shuffle.standings.filter(function(value, index, arr) { return value.order != 0; } ).length > 0 &&<tbody>
                            { this.shuffle.standings.filter(function(value, index, arr) { return value.order != 0; } ).sort(function (a, b) {
                                    if (parseInt(a.order) > parseInt(b.order) ) return 1;
                                    if (parseInt(b.order) > parseInt(a.order) ) return -1;
                                    return 0;
                                  })
                                .map((standing) => { 
                                const comp = div.getComp(standing.standing);
                                return <tr><td>{ ( comp != null && comp.name ) || <span>Missing</span> }</td><td>{standing.order}</td></tr>
                            } ) }
                            
                            { this.shuffle.standings.filter(function (x){ return x.order == 0; } )
                                .map((standing) => { 
                                const comp = div.getComp(standing.standing);
                                return <tr><td>{ ( comp != null && comp.name ) || <span>Missing In Current List</span> }</td><td>Not Checked In</td></tr>
                            } ) }
                            </tbody>}     
                        </Table>
                        <Button onClick={ ()=>{ this.restoreCurrentShuffle(); this.clearShuffle(); this.loadExtra(); } } variant="danger">Restore</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{ this.clearShuffle() }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>        
            }
        </Container>;
    }
}

class CoordinatorJudgeDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        this.judge_id = this.interface.judge_id;
        this.judge = null;
        this.load();
    }
    
    componentDidMount(){       
        window.APP.registerReload(this);
    }

    componentWillUnmount() {
        window.APP.unRegisterReload(this);
    }
    
    
    reload() {
        if(this.division != null) {
            this.division = this.interface.findDivision(this.division.id);
        }
        this.load();
    }

    load() {
        var inter = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/coord/judge/' + this.judge_id).then(res => res.json())
       .then(
          (result) => {
            inter.judge = result;
    
            inter.judge.works = [];
            result.oworks.forEach(element => { 
                let j = new Judge(element);
                inter.judge.works.push(j);
            });
    
            window.APP.reset();
          }
        );        
    }

    getJudges() {
        if(this.judge == null) return [];
        const judgeSort = function(a, b){             
                return a.number - b.number;
        }
        
        return this.judge.works.filter(function(e) { return e.is_current();}).sort(judgeSort);
    }
    
    getUpcomingJudges() {
        if(this.judge == null) return [];
        const judgeSort = function(a, b){             
                return a.number - b.number;
        }
         return this.judge.works.filter(function(e) { return e.is_future();}).sort(judgeSort);
    }
    
    getPastJudges() {
        if(this.judge == null) return [];
        const judgeSort = function(a, b){             
                return a.number - b.number;
        }
         return this.judge.works.filter(function(e) { return e.is_done();}).sort(judgeSort);
    }
    
    
    getUpcomingJudgesArea() {
        return this.getUpcomingJudges().map((ju) => 
        <tr>
        <th>{ju.name}</th>
        <td>{ju.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>

            </tr>
                     )
    }
    
    getPastJudgesArea() {
        return this.getPastJudges().map((ju) => 
        <tr>
        <th>{ju.name}</th>
        <td>{ju.start_time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>
        <td>{ju.stop_time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>
            </tr>
                     )
    }
    
    getCurrentJudgesArea() {
        return this.getJudges().map((ju) => 
        <tr>
        <th>{ju.name} {ju.center != "" && <Badge variant="secondary">C</Badge>}</th>
        <td>{ju.number}</td>
        <td>{ju.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>

            </tr>
                     )
    }

    render() { 
        
        if(this.judge == null) {
            return <Container style={{ color:'white' }} >Loading</Container>
        }
        
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header ><Button style={{float:'left' }} variant='primary' onClick={()=>{ this.interface.clearJudge() }} >Back</Button>{this.judge.name}</Card.Header>
                    <Card.Body >
                        <Container>
                        <Row  style={{marginBottom:'10px'}}>
                            <Col xs={6} md="4">{this.judge.med != '' &&  <img style={{ width: '100px'}} src={this.judge.med} /> }
                            </Col>
                           <Col xs='6' md="4" style={{ fontSize:'0.7em' }}>
                            {this.judge.status }
                            </Col>
                            {this.interface.judge_check_in > 1 && <Col xs='6' md="4" style={{ fontSize:'0.7em' }}>
                                {(this.judge.checked_in == 1 )?<span>Checked in</span>:<span>Checked Out</span>}
                              
                            </Col>}
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col xs={12}>
                <Table responsive variant='dark'>
                    <tbody>{this.getCurrentJudgesArea()}
                    </tbody>
                </Table>
            </Col>
            <Col xs={12}>
            <Container>
            
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Schedule</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Tournament History</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Info</Nav.Link>
                          </Nav.Item>                          
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Schedule</Card.Header>
                                <Card.Body >
                                    <Table responsive variant='dark'>
                                        <tbody>{this.getUpcomingJudgesArea()}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Tournament History</Card.Header>
                                <Card.Body >
                                    <Table responsive variant='dark'>
                                        <tbody>{this.getPastJudgesArea()}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header >Info</Card.Header>
                                <Card.Body >
                                <h2>Designations</h2>
                                <Table responsive variant='dark'>
                                    <tbody>
                                    {this.judge.desig.map( (x) => <tr><td>{x.key}</td><td>{x.value}</td></tr> ) }
                                    </tbody>
                                </Table>
                                <h2>Certifications</h2>
                                <Table responsive variant='dark'>
                                    <tbody>
                                    {this.judge.certs.map( (x) => <tr><td>{x.media != '' && <img
            src={x.media}
            height="40"
          />}</td><td>{x.name}</td><td>{x.expiry}</td></tr> ) }
                                    
                                    </tbody>
                                </Table>
                                </Card.Body>
                            </Card>
                          </Tab.Pane>
                          
                        </Tab.Content>
                      </Col>
                    </Row>
                </Tab.Container>
            </Container>
            </Col>
          </Row>
      </Container>;
    }
}


class StaffScheduler extends React.Component {
    
     constructor(props) {
        super(props);        
        this.interface = props.interface;
        
        this.selected_session = null;
        this.selected_ring = null;
        
         
        this.judgeAddSearch = [];
        this.addCenterValue = false;
        
        this.judgesAddSearchState = ''
        this.addJudgeSearchUpdate = this.addJudgeSearchUpdate.bind(this);
        this.addCenterUpdate = this.addCenterUpdate.bind(this);
        
        this.show_current = true;
        this.show_upcoming = true;
        
        this.session_data ={};
        var t = this;
        this.interface.schedules.forEach(s=>{
            t.session_data[s.id] = {expanded:false}
        });
    }
    
    removeWork(ring, work) {
        postMaster.immediateGet( '/tournament-ring/' +ring.id+ '/remove-judge/' + work.id);
    }
    
    addJudgeToDivision(comp) {
        var added = '';
        
        if(this.addCenterValue) {
            added = '?center=1';
        }
        
        postMaster.immediateGet( '/tournament-ring/'+this.selected_ring.id+'/add-judge/'+this.selected_session.id+'/' + comp.uuid +added)
        .then(
             (result) => {  
                this.judgesAddSearchState = '';
                 this.judgeAddSearch = [];
                window.APP.reset();
             }
           );
            this.judgesAddSearchState = '';
            this.judgeAddSearch = [];
            window.APP.reset();
    }
    
    addCenterUpdate(event) {
        this.addCenterValue = event.target.checked;
    }
    
    
    
    judgesSearchList() { 
        return this.judgeAddSearch.map( (comp ) => 
        <ListGroup.Item  style={{color:'black'}} onClick={() => this.addJudgeToDivision(comp)} as="li">{comp.name}</ListGroup.Item>
          );
    }
    
    addJudgeSearchUpdate(event) { 
        var t = this;
        this.judgesAddSearchState = event.target.value;
        this.setState( {judgesAddSearchState : this.judgesAddSearchState} );
        var k = event.target.value;
        setTimeout(function(){ t.addJudgeSearchUpdateCheck(k); }, 500 ); 
    }
    
    addJudgeSearchUpdateCheck(value) {
        if(value != this.judgesAddSearchState) { 
            return;
        }
        
        if(this.interface.tournament_id) {  
         postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/judgesearch?search=' + this.judgesAddSearchState).then(res => res.json())
           .then(
              (result) => {
                  this.judgeAddSearch = [];
                  result.forEach(r => {
                    this.judgeAddSearch.push(r);
                });                
                this.setState( {judgeAddSearch : this.judgeAddSearch} )
                
              }
            );
        }
    }
    
    render() { 
        
        const holder = this;
        console.log(this.session_data);
        var session_number = 1;
        return <Container>
        
        {this.selected_session != null && this.selected_ring != null && <Modal show={this.selected_session != null && this.selected_ring != null} size="lg" onHide={() => {this.selected_session = null;this.selected_ring = null; window.APP.reset(); } }>
                <Modal.Header closeButton>
                  <Modal.Title>{this.selected_ring.name} | {this.selected_session.time.toLocaleDateString(window.APP.date_locale,window.APP.date_options) } {this.selected_session.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <tbody>
                            {this.selected_ring.getSessionJudges(this.selected_session.id).map(x=><tr>
                                    <td>{x.name} {x.center != "" && <Badge variant="secondary">C</Badge>}</td>
                                    <td><Button onClick={()=>{ holder.removeWork(this.selected_ring, x); }} variant="danger"><i class="fas fa-trash"></i></Button></td>
                                </tr>)}
                        
                            <div>
                            <h2>Add Judge</h2>
                            <Form.Check
                                onChange={this.addCenterUpdate}
                                type="checkbox"
                                className="mb-2 mr-sm-2"
                                label="Center"
                              />
                            <Form.Control type="text" placeholder="search name" onChange={this.addJudgeSearchUpdate} value={this.judgesAddSearchState} />
                            <ListGroup as="ul">
                                {this.judgesSearchList()}  
                            </ListGroup>
                            </div>
                        </tbody>
                    </Table>
                    
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => { this.selected_session = null;this.selected_ring = null; window.APP.reset(); } }>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>}
        
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >Staff Scheduler</Card.Header>
                    <Card.Body >
                        <Container>
                        <Row  style={{marginBottom:'10px'}}>
                            <Col md='4'>
                               <Form.Check
                                onChange={()=>{ holder.show_current = !holder.show_current; window.APP.reset(); }}
                                checked={holder.show_current ?'checked':''}
                                type="checkbox"
                                className="mb-2 mr-sm-2"
                                label="Expand Current"
                                id='staff_scheduler_current'
                              /> 
                            </Col>
                            
                            <Col md='4'>
                               <Form.Check
                                onChange={()=>{ holder.show_upcoming = !holder.show_upcoming; window.APP.reset(); }}
                                checked={holder.show_upcoming ?'checked':''}
                                type="checkbox"
                                className="mb-2 mr-sm-2"
                                label="Expand Upcoming"
                                id='staff_scheduler_upcoming'
                              /> 
                            </Col>
                            
                            {this.interface.schedules.filter(x=>x.time >= new Date() ).map( (s) => <Col md='4'>
                               <Form.Check
                                onChange={()=>{ holder.session_data[s.id].expanded = !holder.session_data[s.id].expanded; window.APP.reset(); }}
                                checked={holder.session_data[s.id].expanded ?'checked':''}
                                type="checkbox"
                                className="mb-2 mr-sm-2"
                                id={'staff_scheduler' + s.id}
                                label={"Expand " + s.time.toLocaleDateString(window.APP.date_locale,window.APP.date_options) + " " + s.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                              /> 
                            </Col>)}
                        </Row>
                        <Row  style={{marginBottom:'10px'}}>
                            <Col md='12'>
                            <Table variant='dark' responsive>
                                <thead>
                                    <tr>
                                    
                                    {this.interface.rings.map( (ring) => <th style={{ minWidth:'150px' }}>{ring.name }</th> ) }
                                    </tr>
                                </thead>  

                                <tbody>
                                    <tr>
                                    {this.show_current && this.interface.rings.map( (ring) => <td>
                                            <Container>
                                            {ring.getJudges().map(x=><Row>
                                            <Col xs='12'>
                                            <Card  bg='dark'  border="success" style={{ margin:'5px' }} >
                                            <Card.Body style={{ padding:'10px',color:'white'}}>{x.name}</Card.Body>
                                            </Card>
                                            </Col>
                                            </Row>)}
                                            </Container>
                                         </td> ) }
                                 
                                    {!this.show_current && this.interface.rings.map( (ring) => <td>
                                            {ring.getJudges().length > 0 && <Container>
                                            <Row>
                                            <Col xs='12'>
                                            <Card  bg='dark'  border="success" style={{ margin:'5px' }} >
                                            <Card.Body style={{ padding:'10px',color:'white'}}>{ring.getJudges().length} Judges</Card.Body>
                                            </Card>
                                            </Col>
                                            </Row>
                                            </Container>}
                                         </td> ) }
                                    </tr></tbody>
                                    
                                    <tbody><tr>
                                    {this.show_upcoming && this.interface.rings.map( (ring) => <td>
                                            <Container>
                                            {ring.getUpcomingJudges().map(x=><Row>
                                            <Col xs='12'>
                                            <Card  bg='dark'  border="warning" style={{ margin:'5px' }} >
                                            <Card.Body style={{ padding:'10px',color:'white'}}>{x.name}</Card.Body>
                                            </Card>
                                            </Col>
                                            </Row>)}
                                            </Container>
                                         </td> ) }
                                 
                                        {!this.show_upcoming && this.interface.rings.map( (ring) => <td>
                                           {ring.getUpcomingJudges().length > 0 && <Container>
                                           <Row>
                                           <Col xs='12'>
                                           <Card  bg='dark'  border="warning" style={{ margin:'5px' }} >
                                           <Card.Body style={{ padding:'10px',color:'white'}}>{ring.getUpcomingJudges().length} Judges</Card.Body>
                                           </Card>
                                           </Col>
                                           </Row>
                                           </Container>}
                                        </td> ) }
                                    </tr></tbody>
                                
                                
                                
                                    {this.interface.schedules.filter(x=>x.time >= new Date() ||true ).map( (s) => { 
                                        var ring_header_shown = false;
                                        
                                        if(session_number % 3 == 0) {
                                            ring_header_shown = true;
                                        }
                                        
                                        session_number = session_number + 1;
                                        
                                        return <tbody>{ring_header_shown && <tr>
                                        {holder.interface.rings.map( (ring) => <th style={{ minWidth:'150px' }}>{ring.name }</th> ) }
                                        </tr>
                                        }<tr>
                                        {this.interface.rings.map( (ring) => <td>
                                            <Container>
                                                <Row>
                                                <Col xs='12'>
                                                <Card  bg='dark'  border="danger" style={{ margin:'5px' }} >
                                                <Card.Header style={{ padding:'10px',color:'white',whiteSpace: 'nowrap' }}>{s.time.toLocaleDateString(window.APP.date_locale,window.APP.date_options) } {s.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )} <Button style={{ display:'inline' }} onClick={ ()=>{ holder.selected_ring = ring; holder.selected_session = s;  window.APP.reset(); } } variant="primary"><i class="fas fa-edit"></i></Button></Card.Header>
                                                </Card>
                                                </Col>
                                                </Row>
                                                
                                                {holder.session_data[s.id].expanded && ring.getSessionJudges(s.id).map(x=><Row>
                                                <Col xs='12'>
                                                <Card  bg='dark'  border="info" style={{ margin:'5px' }} >
                                                <Card.Body style={{ padding:'10px',color:'white'}}>{x.name} {x.center != "" && <Badge variant="secondary">C</Badge>}</Card.Body>
                                                </Card>
                                                </Col>
                                                </Row>)}
                                                
                                                {!holder.session_data[s.id].expanded && ring.getSessionJudges(s.id).length > 0 &&<Row>
                                                <Col xs='12'>
                                                <Card  bg='dark'  border="info" style={{ margin:'5px' }} >
                                                <Card.Body style={{ padding:'10px',color:'white'}}>{ ring.getSessionJudges(s.id).length} Judges</Card.Body>
                                                </Card>
                                                </Col>
                                                </Row>}
                                            </Container>                                                          
                                        </td> ) }
                                        
                                        </tr></tbody>
                                        } ) }
                            </Table>
                            </Col>
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
           
          </Row>
      </Container>;
    }
}

class CoordinatorScheduler extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        
        this.times = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    }
    
    render() { 
        
        
        
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >Scheduler</Card.Header>
                    <Card.Body >
                        <Container>
                        <Row  style={{marginBottom:'10px'}}>
                            <Col md='2'>
                                <div>Un Assigned</div>
                                { this.interface.un_divs.map( (div) => <Card bg='dark' text='light'><Card.Body>{div.name}</Card.Body></Card> ) }
                            </Col>
                            
                            <Col md='10'>
                            <Table variant='dark' responsive>
                                <thead>
                                    <tr><th>Time</th>
                                    {this.interface.rings.map( (ring) => <th style={{ minWidth:'150px' }}>{ring.name }</th> ) }
                                    </tr>
                                </thead>  

                                <tbody>
                                    {this.times.map( (time) => {
                                        return <tr><td>{time}</td>
                                        {this.interface.rings.map( (ring) => <td>{ ring.getSchedulerArea(time) }</td> ) }
                                        
                                        </tr>
                                        } ) }
                                </tbody>
                            </Table>
                            </Col>
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
           
          </Row>
      </Container>;
    }
}

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,  
  backgroundColor:'#212529',  

  // styles we need to apply on draggables
  ...draggableStyle
});

const getItemSessionStyle = (item, isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  backgroundColor:( (item.incomplete == 1) ? '#660000' : ( (item.children && item.children.length == 0) ? '#575a5c' : '#00571d'  )  ),  

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: '100%' ,
  height:'100%'
});

const getDropRingStyle = isDraggingOver => ({
    padding: grid,
    width: '100%',
    backgroundColor: isDraggingOver ? "#660000" : "inherit",
    borderRadius: '10px',
});



class CoordinatorDivisionsAssignerDisplay extends React.Component {
    
     constructor(props) {
        super(props);        
        this.interface = props.interface;        
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this); 
        this.nameRingFilterUpdate = this.nameRingFilterUpdate.bind(this); 
        
        this.ring_name_filter = '';
        this.div_name_filter = '';        
        this.schedule_filter = '';
        this.div_list_id = 'unassign';
        
        this.age_end = '';
        this.age_begin = '';
        
        this.level_end = '';
        this.level_begin = '';
        
        this.is_team = '';
        this.is_grand = '';
        
        this.scheduleFilterUpdate = this.scheduleFilterUpdate.bind(this);
        this.divListFilterUpdate = this.divListFilterUpdate.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        
        this.ageBeginFilterUpdate = this.ageBeginFilterUpdate.bind(this);
        this.ageEndFilterUpdate = this.ageEndFilterUpdate.bind(this);
        
        this.levelBeginFilterUpdate = this.levelBeginFilterUpdate.bind(this);
        this.levelEndFilterUpdate = this.levelEndFilterUpdate.bind(this);
        
        this.teamFilterUpdate = this.teamFilterUpdate.bind(this);
        this.grandFilterUpdate = this.grandFilterUpdate.bind(this);
    }
    
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }        
        if(result.destination.droppableId.includes('drop-into-') ) {
            var ring_id = result.destination.droppableId.replace('drop-into-', '');
            postMaster.toSend('/tournament-division/' + result.draggableId +'/action/assign?ring=' + ring_id + '&session=' + this.schedule_filter + '&send_notification=' + this.interface.send_ring_notifications);
            return;
        } 
    }
    
    nameRingFilterUpdate(event) {
        this.setState({ring_name_filter: event.target.value.toLowerCase() });
        this.ring_name_filter =  event.target.value.toLowerCase();
    }
    
    nameFilterUpdate(event) {
        this.setState({div_name_filter: event.target.value.toLowerCase() });
        this.div_name_filter =  event.target.value.toLowerCase();
    }
    
    ageBeginFilterUpdate(event) {
        this.setState({age_begin: event.target.value.toLowerCase() });
        this.age_begin =  event.target.value.toLowerCase();
    }
    
    ageEndFilterUpdate(event) {
        this.setState({age_end: event.target.value.toLowerCase() });
        this.age_end =  event.target.value.toLowerCase();
    }
    
    levelBeginFilterUpdate(event) {
        this.setState({level_begin: event.target.value.toLowerCase() });
        this.level_begin =  event.target.value.toLowerCase();
    }
    
    levelEndFilterUpdate(event) {
        this.setState({level_end: event.target.value.toLowerCase() });
        this.level_end =  event.target.value.toLowerCase();
    }
    
    teamFilterUpdate(event) {
        this.setState({is_team: event.target.value.toLowerCase() });
        this.is_team =  event.target.value.toLowerCase();
    }
    
    grandFilterUpdate(event) {
        this.setState({is_grand: event.target.value.toLowerCase() });
        this.is_grand =  event.target.value.toLowerCase();
    }
    
    scheduleFilterUpdate(event) {
        this.setState({schedule_filter: event.target.value.toLowerCase() });
        this.schedule_filter =  event.target.value.toLowerCase();
    }
    
    divListFilterUpdate(event) {
        this.setState({div_list_id: event.target.value.toLowerCase() });
        this.div_list_id =  event.target.value.toLowerCase();
    }
    
    
    render() {      
        var t = this;
        var rings = this.interface.rings
                .filter(function(value, index, arr){ return value.id != t.div_list_id;} );
        
        rings = searchArray(rings,searchRingHay, this.ring_name_filter);
        var divs_list = this.interface.rings;
       
        var ringsarea = <div></div>;
        var man = this.interface;
        
        if(rings.length > 0) {
            if(this.interface.card_view) {
                const ringsList = rings.map((ring ) => { 
                    const left = ring.divisions.filter(element=>{ return element.status < 3 && (element.schedule_id == this.schedule_filter || this.schedule_filter == '') } ).length;
                    const total = ring.divisions.filter(element=>{ return (element.schedule_id == this.schedule_filter || this.schedule_filter == '') } ).length;
                    const time = ring.getMaxDivisionTime(this.schedule_filter);
                    const last = ring.getLastDivision(this.schedule_filter);
                    
                    return <Col xs={12} sm="6" lg="4">
                        <Droppable droppableId={'drop-into-' + ring.id}>
                        {(provided, snapshot) => ( <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getDropRingStyle(snapshot.isDraggingOver)}
                                      >
                        <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                            <Card.Header><div style={{ marginBottom:'10px', overflow: 'hidden'}}>
                                    <div style={{ float:'left' }}>{ring.icon() }</div>{ring.name}<div style={{ float:'right' }}><Button onClick={()=>{ man.setRing(ring.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                        </div>
                            </Card.Header>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                            { left > 0 && <span>{time != null && <span>{time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span> } | {left} divs left</span> } 
                                            { left == 0 && total > 0 && <span>Complete</span> } 
                                            { left == 0 && total == 0 && <span>None Assigned</span> } 
                                        </Col>

                                        <Col md='6' style={{ fontSize:'0.7em' }}>
                                        {ring.location}{ ring.location == '' && <span>&nbsp;</span>}
                                        </Col>

                                        { last && <Col md='12' style={{ height:'1px',backgroundColor:'#FFFFFF' }} ></Col>}


                                        <Col md='12' >
                                        { last && last.codePop() }{ !last && <span>&nbsp;</span>}
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        </div>
                                            )}
                        </Droppable>
                    </Col>;
                });
                ringsarea = <Row  style={{ textAlign:'center' }}>
                        {ringsList}
                    </Row>  
            } else {
                const ringsList = rings.map((ring ) => { 
                    const last = ring.getLastDivision(this.schedule_filter);
                    const left = ring.divisions.filter(element=>{ return element.status < 3 } ).length;
                    const time = ring.getMaxDivisionTime(this.schedule_filter);
                    return <tr><td>{ring.icon()}</td><td>{ring.name}</td><td>{ring.location}</td><td>{ left > 0 && <span>{time != null && <span>{time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span> } | {left} divs left</span> }</td><td>{ last && last.codePop() }</td></tr>;
                });
                ringsarea = <Table responsive variant="dark">
                    <tbody>
                        {ringsList}
                    </tbody>        
                </Table>
            }
        } else {
            ringsarea =  <Row  style={{ textAlign:'center' }}><Col xs={12}>There are currently no rings created</Col></Row>
        }
        
        var divsarea = <div></div>;
        
        if(this.div_list_id == 'unassign') { 
        
            var u_divs = this.interface.
                    un_divs.filter(function(value, index, arr){ return value.status < 3 })
                    .filter(function(value, index, arr){ return value.name.toLowerCase() .includes(t.div_name_filter.toLowerCase() ) || value.code.toLowerCase() .includes(t.div_name_filter.toLowerCase() ) })
                    .filter(function(value, index, arr){ return value.age_begin == t.age_begin || t.age_begin == ''  })
                    .filter(function(value, index, arr){ return value.age_end == t.age_end || t.age_end == ''  })
                    .filter(function(value, index, arr){ return value.level_end.toLowerCase() == t.level_end.toLowerCase() || t.level_end == '' })
                    .filter(function(value, index, arr){ return value.level_begin.toLowerCase() == t.level_begin.toLowerCase() || t.level_begin == '' })
                    .filter(function(value, index, arr){ return value.is_team == t.is_team || t.is_team == '' })
                    .filter(function(value, index, arr){ return value.is_grand == t.is_grand || t.is_grand == '' })
                    
                        ;
                        divsarea = <Droppable droppableId="un_assiigned" isDropDisabled='true'>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {u_divs.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='4' style={{ fontSize:'0.7em' }}>
                                                        { item.codePop() }
                                                    </Col>
                                                    <Col md='5' style={{ fontSize:'0.7em' }}>
                                                        {item.name}
                                                    </Col>
                                                    <Col md='3' style={{ fontSize:'0.7em' }}>
                                                        <div>{ item.printDurationEstimate() }</div>
                                                        <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                    </Droppable>;
        } else {
            var ring_selected = this.interface.getRing(this.div_list_id);
            if(ring_selected) {
                var u_divs = ring_selected.getDivisionsInOrder().filter(function(value, index, arr){ return value.status == 0 && ( !ring_selected.current_division || value.id != ring_selected.current_division.id ); })
                    .filter(function(value, index, arr){ return value.name.toLowerCase() .includes(t.div_name_filter.toLowerCase() ) || value.code.toLowerCase() .includes(t.div_name_filter.toLowerCase() ) })
                    .filter(function(value, index, arr){ return value.age_begin == t.age_begin || t.age_begin == '' })
                    .filter(function(value, index, arr){ return value.age_end == t.age_end || t.age_end == '' })
                    .filter(function(value, index, arr){ return value.level_end == t.level_end || t.level_end == '' })
                    .filter(function(value, index, arr){ return value.level_begin == t.level_begin || t.level_begin == '' })
                    .filter(function(value, index, arr){ return value.is_team == t.is_team || t.is_team == '' })
                    .filter(function(value, index, arr){ return value.is_grand == t.is_grand || t.is_grand == '' })
                    ;
                    
                var data = sessionFormat(u_divs,  this.interface.schedules);
                
                var divs_area_parts = [];
                
                data.forEach( (v, index, self2) => { 
                    var item = v.value;
                    if(v.type == 'division') {                                        
                        divs_area_parts.push(<Draggable key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                                  <Container>
                                      <Row>
                                          <Col md='3' style={{ fontSize:'0.7em' }}>
                                              { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                          </Col>
                                          <Col md='3' style={{ fontSize:'0.7em' }}>
                                              { item.codePop() }
                                          </Col>
                                          <Col md='4' style={{ fontSize:'0.7em' }}>
                                              {item.name}
                                          </Col>
                                          <Col md='2' style={{ fontSize:'0.7em' }}>
                                                <div>{ item.printDurationEstimate() }</div>
                                                <div><Button onClick={()=>{ man.setDivision(item.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                          </Col>
                                      </Row>
                                  </Container>
                            </div>
                          )}
                        </Draggable>);
                        } else {
                            divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id} draggableId={item.id} index={divs_area_parts.length}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemSessionStyle(v,
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='12' style={{ fontSize:'0.7em' }}>
                                                        {item.name}
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>);

                            v.children.forEach( (divv, index2, self22) => { 
                                var div = divv.value;
                                divs_area_parts.push(<Draggable key={div.id} draggableId={div.id} index={divs_area_parts.length}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='3' style={{ fontSize:'0.7em' }}>
                                                        { div.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                                    </Col>
                                                    <Col md='3' style={{ fontSize:'0.7em' }}>
                                                        { div.codePop() }
                                                    </Col>
                                                    <Col md='4' style={{ fontSize:'0.7em' }}>
                                                        {div.name}
                                                    </Col>
                                                    <Col md='2' style={{ fontSize:'0.7em' }}>
                                                        <div>{ div.printDurationEstimate() }</div>
                                                        <div><Button onClick={()=>{ man.setDivision(div.id) } } variant='primary'><i class="fas fa-share"></i></Button></div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>);
                            });

                            divs_area_parts.push(<Draggable isDragDisabled={true} key={item.id+'-2'} draggableId={item.id+'-2'} index={divs_area_parts.length}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        
                                        style={getItemSessionStyle(v,
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                            <Container>
                                                <Row>
                                                    <Col md='12' style={{ fontSize:'0.7em' }}>
                                                        End of Session {item.name}
                                                    </Col>
                                                </Row>
                                            </Container>
                                      </div>
                                    )}
                                  </Draggable>);
                        
                        
                        }
                    });
                
                
                            divsarea = <div>
                                <Alert show={true} variant="info">
                                      This section no longer allows re-arranging.
                                </Alert><Droppable droppableId="droppable" isDropDisabled='true'>
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {divs_area_parts}
                                {provided.placeholder}
                              </div>
                            )}
                    </Droppable></div>;    
                    
            }
        }
        
    
        return <DragDropContext onDragEnd={this.onDragEnd}><Container style={{height:'100%'}}>
            
            <Row style={{ textAlign:'center', height:'49%',overflowY:'scroll' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'0.5%', width:'99%',minHeight:'95%'  }}>
                        <Card.Header >
                        <div style={{ float:'left' }} >
                            <Form.Control value={this.ring_name_filter} onChange={this.nameRingFilterUpdate}
                                      placeholder="Search Ring Name/Location"
                                    />
                        </div>
                        Rings
                        
                        <div style={{ float:'right' }} >
                            <Form.Control value={this.schedule_filter} as="select" onChange={this.scheduleFilterUpdate}>
                                <option value=''>Don't Set Session</option>
                                {this.interface.schedules.map((group) => <option  value={group.id} >{group.name}</option> ) }
                            </Form.Control>
                        </div>
                        </Card.Header>   
                        <Card.Body>
                        {ringsarea}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            <Row style={{ textAlign:'center', height:'49%',marginTop:'1%',overflowY:'scroll' }}>                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'0.5%',marginTop:'0px', width:'99%',minHeight:'95%'  }}>
                        <Card.Header >Divisions</Card.Header>   
                        <Card.Body>
                            <Container >
                            <Row>
                                <Col md='5'>
                                    <Container>
                                        {this.interface.send_movement_notifications && <Row>
                                        <Col md='12'>
                                            <div  className="mb-3">
                                                <Form.Check checked={(this.interface.send_ring_notifications) ? 'checked' : ''}
                                                onChange={this.interface.ringMovementNotificationsClick}
                                                id={'singledivisoin-notifications'} 
                                                  type={'checkbox'}
                                                  label='Send Ring Movement Notifications'
                                                />
                                            </div>
                                        </Col>
                                        </Row>}
                                        
                                        <Row>
                                        <Col md='12'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Divisions From</InputGroup.Text>
                                            <Form.Control value={this.div_list_id} as="select" onChange={this.divListFilterUpdate}>
                                                <option value='unassign'>Unassigned Divisions</option>
                                                {divs_list.map((ring) => <option  value={ring.id} >{ring.name}</option> ) }
                                             </Form.Control>
                                        </InputGroup>
                                        </Col>
                                        </Row>
                                        
                                        <Row>
                                        <Col md='12'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                            <Form.Control value={this.div_name_filter} onChange={this.nameFilterUpdate}
                                              placeholder="Search Division Name/Code"
                                            />
                                        </InputGroup>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Age Begin</InputGroup.Text>
                                            <Form.Control type='number' value={this.age_begin} onChange={this.ageBeginFilterUpdate}
                                              placeholder="Filter age start"
                                            />
                                        </InputGroup>
                                        </Col>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Age End</InputGroup.Text>
                                            <Form.Control type='number' value={this.age_end} onChange={this.ageEndFilterUpdate}
                                              placeholder="Filter age end"
                                            />
                                        </InputGroup>
                                        </Col>
                                        </Row>
                                        
                                        <Row>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Level Begin</InputGroup.Text>
                                            <Form.Control as='select' value={this.level_begin} onChange={this.levelBeginFilterUpdate}
                                              placeholder="Filter level begin"
                                            >
                                                <option value=''>No Filter</option>
                                                <option value='beginner'>Beginner</option>
                                                <option value='intermediate'>Intermediate</option>
                                                <option value='advanced'>Advanced</option>
                                                <option value='black belt'>Black Belt</option>
                                            </Form.Control>
                                        </InputGroup>
                                        </Col>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Level End</InputGroup.Text>
                                            <Form.Control as='select' value={this.level_end} onChange={this.levelEndFilterUpdate}
                                              placeholder="Filter level end"
                                            >
                                                <option value=''>No Filter</option>
                                                <option value='beginner'>Beginner</option>
                                                <option value='intermediate'>Intermediate</option>
                                                <option value='advanced'>Advanced</option>
                                                <option value='black belt'>Black Belt</option>
                                            </Form.Control>
                                        </InputGroup>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Team</InputGroup.Text>
                                            <Form.Control as='select' value={this.is_team} onChange={this.teamFilterUpdate}
                                              placeholder="Filter is team"
                                            >
                                                <option value=''>No Filter</option>
                                                <option value='0'>No</option>
                                                <option value='1'>Yes</option>
                                            </Form.Control>
                                        </InputGroup>
                                        </Col>
                                        <Col md='6'>
                                        <InputGroup className="mb-3">
                                              <InputGroup.Text >Grand</InputGroup.Text>
                                            <Form.Control as='select' value={this.is_grand} onChange={this.grandFilterUpdate}
                                              placeholder="Filter is grand"
                                            >
                                                <option value=''>No Filter</option>
                                                <option value='0'>No</option>
                                                <option value='1'>Yes</option>
                                            </Form.Control>
                                        </InputGroup>
                                         </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md='7'>
                                    {divsarea}
                                </Col>
                            </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </Container></DragDropContext>;
    }
}


class IncidentCenter extends React.Component {
    
     constructor(props) {
        super(props);        
        this.interface = props.interface;
        
    }
    
    
    render() { 
        
        const holder = this
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >Incident Manager</Card.Header>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col md='8' style={{ marginBottom:'15px',textAlign:'center' }}>
                                    <RingAlertsTable interface={this.interface} />
                                </Col>
                                <Col md='4' style={{ marginBottom:'15px',textAlign:'center' }}>
                                    <IssueRingsTable interface={this.interface} />
                                </Col>
                                <Col md='4' style={{ marginBottom:'15px',textAlign:'center' }}>
                                    <ToCloseRingsTable interface={this.interface} />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
</Container>;
    }
}

class RingAlertsTable extends React.Component {    
     constructor(props) {        
        super(props);
        this.interface = props.interface;        
    }
    
    render() { 
        var list = [];
        
        this.interface.rings.forEach( (ring, index, self2) => {
            ring.alertsManager.currentAlerts.forEach( (alert, index, self2) => {
                list.push({ring:ring,alert:alert});
            });
        });
        
        const holder = this
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >Active Alerts</Card.Header>
                    <Card.Body>
                        <Table variant="dark">
                            {list.map((alert) => <tr>
                                <td>
                                    {alert.ring.name}
                                </td>
                                <td>
                                    {alert.alert.av.name}
                                </td>
                                <td>
                                    {alert.alert.stage.name}
                                </td>
                            </tr>)}
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
</Container>;
    }
}


class IssueRingsTable extends React.Component {    
     constructor(props) {        
        super(props);
        this.interface = props.interface;        
    }
    
    render() {         
        const holder = this
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >Rings With Status</Card.Header>
                    <Card.Body>
                        <Table variant="dark">
                            {this.interface.rings.filter(x=>{return x.status > 0;}).map((ring) => {
                                var color = 'white';
                                
                                if(ring.status == 1) color='yellow';
                                else if (ring.status == 2) color='red';
                    
                                return <tr>
                                <td style={{color:color}}>
                                    {ring.name}
                                </td>
                                <td>
                                    <Button onClick={()=>{ holder.interface.setRing(ring.id) } } variant='primary'><i class="fas fa-share"></i></Button>
                                </td>
                            </tr>})}
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
</Container>;
    }
}


class ToCloseRingsTable extends React.Component {    
     constructor(props) {        
        super(props);
        this.interface = props.interface;    
    }
    
    render() {         
        const holder = this
        return <Container>
        <Row style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                    <Card.Header >To Close Rings</Card.Header>
                    <Card.Body>
                        <Table variant="dark">
                            {this.interface.rings.filter(x=>{return x.status > -1 && x.active == 0;}).map((ring) => <tr>
                                <td>
                                    {ring.name}
                                </td>
                                <td>
                                    <Button key={ring.id} onClick={()=>{ holder.interface.setRing(ring.id) } } variant='primary'><i class="fas fa-share"></i></Button>
                                </td>
                                <td>
                                    <CloseRingButton key={ring.id} ring={ring} />
                                </td>
                            </tr>)}
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
</Container>;
    }
}

class CloseRingButton extends React.Component {    
     constructor(props) {        
        super(props);
        this.ring = props.ring;        
    }
    
    render() {
        const ring_id = this.ring.id;
        var ring = this.ring;
        return <Button key={ring_id} onClick={()=>{ postMaster.immediatePost('/tournament-ring/'+ring_id+'/action/close') } } variant='secondary'>Close</Button>                       
    }
}