import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';


import postMaster from '../logic/postmaster'

export class ProtestModal  extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.onHide = props.onHide;
        this.division = props.division;
        
        this.protestor_id = '';
        this.protestee_id = '';
        this.reason = '';
        this.note = '';
        
        this.protestee_change = this.protestee_change.bind(this);
        this.protestor_change = this.protestor_change.bind(this);
        this.reason_change = this.reason_change.bind(this);
        this.note_change = this.note_change.bind(this);
        this.submit = this.submit.bind(this);
    }
    
    protestee_change(event) {
        this.protestee_id = event.target.value;
        this.setState({protestee_id: event.target.value });
    }
    
    protestor_change(event) {
        this.protestor_id = event.target.value;
        this.setState({protestor_id: event.target.value });
    }
    
    reason_change(event) {
        this.reason = event.target.value;
        this.setState({reason: event.target.value });
    }
    
    note_change(event) {
        this.note = event.target.value;
        this.setState({note: event.target.value });
    }
    
    ready() {
        return this.protestor_id != '' && this.protestee_id != '' && this.reason != '';
    }
    
    submit() {
        postMaster.toSendData('/tournament-division/' + this.division.id +'/admin-add-protest?ajax=1',
        {protestor_id: this.protestor_id, protestee_id: this.protestee_id, reason: this.reason, note: this.note }, false );
        this.onHide();
    }
    
    render() {
        
        return        <Modal size="lg" show={true} onHide={this.onHide}>
                <Modal.Header closeButton>
                  <Modal.Title>New Protest {this.division.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                    <tr><th>On behalf Of</th>
                    <td>
                            <Form.Control as="select" size="lg" value={this.protestor_id}  onChange={this.protestor_change}>
                            <option value=''>Please Select</option>
                                {this.division.standings.map( (x) => {
                                    return <option value={x.id}>{x.name}</option>
                                })}
                            </Form.Control>
                    </td></tr>
                    <tr><th>Against</th><td>
                        <Form.Control as="select" size="lg" value={this.protestee_id}  onChange={this.protestee_change}>
                            <option value=''>Please Select</option>
                                {this.division.standings.map( (x) => {
                                    return <option value={x.id}>{x.name}</option>
                                })}
                            </Form.Control>
                        </td></tr>
                        <tr><th>Reason</th><td>
                             <Form.Control type="text" size="lg" value={this.reason}  onChange={this.reason_change} ></Form.Control>
                </td></tr>
                <tr><th>Note</th><td>
                             <Form.Control type="text" size="lg" value={this.note}  onChange={this.note_change} ></Form.Control>
                </td></tr>
                {
                <tr><td colspan='2' class='text-center'>
                    {this.ready() && <Button variant="primary" onClick={this.submit}>
                        Add Protest
                      </Button>}
                </td></tr>
                }
                </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.onHide}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
        ;
    }
    
}