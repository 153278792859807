import React from 'react';

export class Seat {
    constructor(data) {
        this.id = data.id;
        this.statusname = data.statusname;
        this.status = data.status;
        this.ticketid = data.ticketid;
        this.ticketname = data.ticketname;
        
        this.opencolor = data.opencolor;
        this.filledcolor = data.filledcolor;
         
        this.filledname = data.filledname;
        this.number = data.number;
        this.statusname = data.statusname;
        
        this.row = data.row;
        this.block = data.block;
        this.code = data.code;
        this.tier = data.tier;
        this.tier_name = data.tier_name;
        
        this.x = data.x;
        this.y = data.y;
        
        this.asile = data.asile;
    }
}

export class SeatRow {
    constructor(data) {
        this.id = data.id;
        
        this.x = data.x;
        this.y = data.y;
        
        this.prefix = data.prefix;
        
        this.width = data.width;
        this.directional = data.directional;
        
        this.seats = [];
        var t = this;
        data.seats.forEach(element => { 
            t.seats.push( new Seat(element) );
        });
    }
    
    findSeat(id) {
        return this.seats.find((seat) => seat.id == id);
    }
}

export class SeatBlock {
    constructor(data) {
        this.id = data.id;
        
        this.width = data.width;
        this.height = data.height;
        
        this.x = data.x;
        this.y = data.y;
        
        this.directional = data.directional;
        
        this.rows = [];
        var t = this;
        data.rows.forEach(element => { 
            t.rows.push( new SeatRow(element) );
        });
    }
    
    findSeat(id) {
        var seat = null;
        this.rows.forEach((row) => {
            var f = row.findSeat(id);
            if(f != null) {
                seat = f;
            }
        });
        return seat;
    }
}

export class SeatRoom {
    
    subscribe(func) {
        this.toUpdate.push(func)
    }
    
    unSubscribe(func) {
        this.toUpdate = this.toUpdate.filter( (x) => x !== func);
    }
    
    sendUpdates() {
        var room = this;
        this.toUpdate.forEach((f) => {f(room) });
    }
    
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        
        this.toUpdate = [];
        
        this.width = data.width;
        this.height = data.height;
        this.tiers = data.tiers;
        this.blocks = [];
        var t = this;
        data.blocks.forEach(element => { 
            t.blocks.push( new SeatBlock(element) );
        });
        
        var room = this;
        window.Echo.private('ticket-map-room.admin.' + this.id)
        .listen('SeatUpdatePrivate', (e) => {
            var seat = room.findSeat(e.id);
    
            if(seat == null) {
                return;
            }
            
            seat.opencolor = e.open_color;
            seat.filledcolor = e.filled_color;

            seat.statusname = e.status_name;
            seat.status = e.status;
            seat.ticketid = e.ticket_id;
            seat.ticketname =  e.ticket_name ;
   
            room.sendUpdates();
        });
    }
    
    findSeat(id) {
        var seat = null;
        this.blocks.forEach((block) => {
            var f = block.findSeat(id);
            if(f != null) {
                seat = f;
            }
        });
        return seat;
    }
}