import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';

import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import postMaster from '../logic/postmaster';
import { AppDomain} from '../constants';
import {Division} from './division'
import {AlertsManager} from './alerts'
import {Judge} from './judge'
import {FightAvCall} from './fights'

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export class Ring {
    constructor(id) {
        this.id = id;
        this.name = '';
        this.divisions = [];
        this.a_judges = [];
        this.current_division = null;
        this.image = '';
        this.alertsManager = new AlertsManager()
        this.current_fight = null;

        this.status = 0;

        this.help_alert = null;

        this.available_call_packs = [];
        this.default_call_pack = '';
        this.judge_display = 0;
        this.tournament_id = '';
        this.judge_check_in = 0;
        
        this.ordered = 0;
        
        this.getWindowSchedule = this.getWindowSchedule.bind(this);
        
        this.ring_import_request = null;
        this.ring_import = null;
        
        this.ring_import_uuid=null;
        
        this.comm_data_from = null;
    }
        
    processProfile(p) {
        var d = this.getDivision(p.division_id);
        if(d) d.processProfile(p);
    }
    
    canRefresh() {
        if(this.current_division != null && this.current_division.status > 0) {
            return false;
        }
        if(this.current_fight != null) {
            return false;
        }
        return true;
    }
    
    getCurrentAvailableCalls() {
        if(this.current_division != null) {
            if(this.current_division.call_pack != null && this.current_division.call_pack != '' && this.available_call_packs[this.current_division.call_pack] != undefined && this.available_call_packs[this.current_division.call_pack] != null) {
                return this.available_call_packs[this.current_division.call_pack].available_calls;
            }
        } 
        
        if(this.default_call_pack != null && this.default_call_pack != '') {
            return this.available_call_packs[this.default_call_pack].available_calls;
        }
        
        return [];
    }
    
    getSchedulerArea(time) {
        var divs = this.divisions.filter(function(e) { if(e.estimated_start== null) return false; return  e.estimated_start.getHours() == time; } );
        return divs.map( (div)=><Card bg='dark' text='light'><Card.Body>{div.name}</Card.Body></Card>)
    }
    
    getCompletedDivisions(date = null,session_filter=null) {
        var divs = this.divisions;
        divs = divs.filter(element=>{ return session_filter == element.schedule_id || session_filter == 'All' || session_filter == null} )
        
        if(date == null || date == 'All') {
            return divs.filter(function(e) { return e.status == 3; } )
        } else {
            return divs.filter(function(e) { return e.estimated_start.getDate() == date && e.status == 3; } )            
        }
    }
    
    getIPDivisions(date = null, session_filter = null) {
        var divs = this.divisions;
        divs = divs.filter(element=>{ return session_filter == element.schedule_id || session_filter == 'All' || session_filter == null} )
        if(date == null || date == 'All') {
            return divs.filter(function(e) { return e.status == 1 || e.status == 2 } )
        } else {
            return divs.filter(function(e) { return e.estimated_start.getDate() == date && (e.status == 1 || e.status == 2); } )            
        }
    
    }
    
    getAllDivisions(date = null, session_filter = null) {
        var divs = this.divisions;
        divs = divs.filter(element=>{ return session_filter == element.schedule_id || session_filter == 'All' || session_filter == null} )
        if(date == null || date == 'All') {
            return divs;
        } else {
            return divs.filter(function(e) { return e.estimated_start.getDate() == date; } )            
        }
    }
    
    getMaxDivisionTime(session = '', date = null, session_filter = null) {
        var d = null;        
        this.divisions
        .filter(function(value, index, arr){ return value.schedule_id == session || session == ''; })
        .filter(element=>{ return  (date == 'All' || date == null || date == element.estimated_start.getDate() ) }  )
        .filter(element=>{ return session_filter == element.schedule_id || session_filter == 'All' || session_filter == null} )
        .forEach(element => { 
            if(element.status < 3) {
                if(d == null || d < element.estimated_stop) {
                    d = element.estimated_stop;
                }
            }
        });
        return d;
    }
    
    getLastDivision(session = '') {
        var d = null;
        var division = null;
        this.divisions
                .filter(function(value, index, arr){ return value.schedule_id == session || session == ''; })       
                .forEach(element => { 
            if(element.status < 3) {
                if(d == null || d < element.estimated_stop) {
                    d = element.estimated_stop;
                    division = element;
                }
            }
        });
        
        return division;
    }
    
    upperProgressBar(division_too = false) {
        
        var svalue = 0;
        var ipvalue = 0;
        var s = 0;
        var ip = 0;
        
        if(this.divisions.length > 0) {
            s = this.getCompletedDivisions().length;
            svalue = this.getCompletedDivisions().length / this.divisions.length;
        }
        
        if(this.divisions.length > 0) {
            ip = this.getIPDivisions().length;
            ipvalue = this.getIPDivisions().length / this.divisions.length;
        }
        
        svalue = svalue * 100;
        ipvalue = ipvalue * 100;
        return <div>                
                {division_too && this.current_division && this.current_division.progressBar()}
                {this.divisions.length > 0 &&  <ProgressBar style={{ height:'32px' }}>
                    <ProgressBar variant={'success'} now={svalue} label={s} />
                    <ProgressBar variant={'primary'} now={ipvalue} label={ip} />
                </ProgressBar>}
               </div>;
    }
    
    progressBar(division_too = false, date = null,session_filter = null) {
        
        var svalue = 0;
        var ipvalue = 0;
        var s = 0;
        var ip = 0;
        
        
        var t = this.getAllDivisions(date,session_filter).length;
        
        if(t > 0) {
            s = this.getCompletedDivisions(date,session_filter).length;
            svalue = this.getCompletedDivisions(date,session_filter).length / t;
        }
        
        if(this.divisions.length > 0) {
            ip = this.getIPDivisions(date,session_filter).length;
            ipvalue = this.getIPDivisions(date,session_filter).length / t;
        }
        
        svalue = svalue * 100;
        ipvalue = ipvalue * 100;
        
        return <div>                
                {division_too && this.current_division && this.current_division.progressBar()}
                {t > 0 &&  <ProgressBar>
                    <ProgressBar variant={'success'} now={svalue} label={s} />
                    <ProgressBar variant={'primary'} now={ipvalue} label={ip} />
                </ProgressBar>}
               </div>;
    }
    
    confirmJudge(judge) {
        judge.start_time = new Date();
        
        this.updateJudges();
        
        window.APP.forceUpdate();
    }
    
    cancelJudge(judge) {
        judge.planned_stop = new Date();
        this.updateJudges(judge);
        window.APP.forceUpdate();
    }
    
    leaveJudge(judge) {
        judge.stop_time = new Date();
        if(isNaN(judge.planned_stop ) ) {
            judge.planned_stop = new Date();
        }
        this.updateJudges(judge);
        window.APP.forceUpdate();
    }
    
    getFutureJudgesArea() {
        return this.getFutureJudges().map((ju) => 
        <tr>
        <td>{ju.thumb != '' && <img style={{ width: '75px' }} src={ju.thumb} ></img>}</td>
        <th>{this.judge_check_in > 1 && ju.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i>&nbsp;</span>}
            {this.judge_check_in > 1 && ju.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i>&nbsp;</span>}
            {ju.name} {ju.center != "" && <Badge variant="secondary">C</Badge>}</th><td>{ju.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>
        <td>
              <ButtonGroup>
              <DropdownButton as={ButtonGroup} title="More">
                  <Dropdown.Item onClick={ ()=>{ this.cancelJudge(ju) } }>Cancel Judge</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>  
              </td>
            </tr>
                     )
    }
    
    getFutureJudgesScheduleArea(schedules) {
        return this.getFutureJudges().map((ju) => {
            
            var schedule = null;
            if(ju.schedule_id != null) {
                schedules.forEach(s=>{
                   if(schedule != null) return;
                   if(s.id == ju.schedule_id) schedule = s;
                });
            }
            var time = null;
            if(schedule) {
                time = new Date(schedule.time);
            }
            
        return <tr>
        <td>{ju.thumb != '' && <img style={{ width: '75px' }} src={ju.thumb} ></img>}</td>
        <th>{this.judge_check_in > 1 && ju.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i>&nbsp;</span>}
            {this.judge_check_in > 1 && ju.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i>&nbsp;</span>}
            {ju.name} {ju.center != "" && <Badge variant="secondary">C</Badge>}</th><td>{ju.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )} {time != null && !isNaN(time) && <span>({time.toLocaleDateString(window.APP.date_locale,window.APP.date_options) } {time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )})</span>}</td>
        <td>
              <ButtonGroup>
              <DropdownButton as={ButtonGroup} title="More">
                  <Dropdown.Item onClick={ ()=>{ this.cancelJudge(ju) } }>Cancel Judge</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>  
              </td>
            </tr>}
                     )
    }
    
    getUpcomingJudgesArea() {
        return this.getUpcomingJudges().map((ju) => 
        <tr>
        <td>{ju.thumb != '' && <img style={{ width: '75px' }} src={ju.thumb} ></img>}</td>
        <th>{this.judge_check_in > 1 && ju.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i>&nbsp;</span>}
            {this.judge_check_in > 1 && ju.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i>&nbsp;</span>}
            {ju.name} {ju.center != "" && <Badge variant="secondary">C</Badge>}</th><td>{ju.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td>
        <td>
              <ButtonGroup>
              <Button variant="success" disabled={(this.judge_check_in == 3 && ju.checked_in == 0)?'disabled':''} onClick={ ()=>{ this.confirmJudge(ju) } } ><i class="fas fa-user-check"></i></Button>
                <DropdownButton as={ButtonGroup} title="More">
                  <Dropdown.Item>Send Reminder</Dropdown.Item>
                  <Dropdown.Item onClick={ ()=>{ this.cancelJudge(ju) } }>Cancel Judge</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>  
              </td>
            </tr>
                     )
    }
    
    getCurrentJudgesArea(options = true) {
        return this.getJudges().map((ju) => 
        <tr>
            <td>{ju.thumb != '' && <img style={{ width: '75px' }} src={ju.thumb} ></img>}</td>
                <th>{this.judge_check_in > 1 && ju.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i>&nbsp;</span>}
                {this.judge_check_in > 1 && ju.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i>&nbsp;</span>}
                {ju.name} {ju.center != "" && <Badge variant="secondary">C</Badge>}</th><td>{ju.number}</td>
                <td>
              {options && <ButtonGroup>
              <Button onClick={ ()=>{ this.leaveJudge(ju) } } variant="danger" ><i class="fas fa-user-times"></i></Button>
                <DropdownButton as={ButtonGroup} title="More" >
                        <Dropdown.Item href={ '/app?type=judge&num=' + (ju.number - 1) + '&id=' + this.id } target='_blank'>Judge As Link</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>}
              </td>
              </tr>
                     )
    }
  
    icon() {
        
        if(this.color != '#000000' || this.symbol) {
            return <div style={ {height:'1.6em', width:'1.6em',textAlign:'center', backgroundColor:this.color,color:this.icolor }} ><i class={this.symbol}></i></div>
        } else {
            return <div style={ {height:'1.6em', width:'1.6em',textAlign:'center' }} ></div>
        }
    }
    
    statusIcon() {
        return <div style={ {textAlign:'center' }} >{this.alertsManager.symbolsDisplay() }{this.protestSymbolsDisplay()  } </div>
    }
    
    getActiveProtests() {
        var protests = [];
        
        this.divisions.forEach(element => { 
            element.activeProtests().forEach(p => { 
                protests.push({
                    name:element.name,
                    protest:p,
                    d:element
                });
            });
        });
        return protests;
    }
    
    protestSymbolsDisplay() {
        var protests = [];
        
        this.divisions.forEach(element => { 
            element.activeProtests().forEach(p => { 
                protests.push({
                    name:element.name,
                    protest:p
                });
            });
        });

        
        return protests.map((p) => { 
                     const popover = (
                            <Popover>
                              <Popover.Title>{p.name}</Popover.Title>
                              <Popover.Content>
                                    Status: {p.protest.status}    
                              </Popover.Content>
                            </Popover>
                          );

                        const Area = () => (
                                <OverlayTrigger placement="top" overlay={popover}>
                                    <i style={{color:'red',marginLeft:'3px',marginRight:'3px' }} class='far fa-hand-paper'></i>
                                </OverlayTrigger>
                         );

                         return <Area />   
               }
        );
             
    
    }
    
    protestsDisplay(inteface) {
        var protests = [];
        
        this.divisions.forEach(element => { 
            element.activeProtests().forEach(p => { 
                protests.push({
                    name:element.name,
                    protest:p,
                    id:element.id
                });
            });
        });

        
        return protests.map((p) => { 
                        const Area = () => (
                                <Alert variant="danger">
                                    <Alert.Heading>Protest</Alert.Heading>
                                    <Container>
                                    <Row>
                                    <Col xs='10'>
                                        <p>Division {p.name}</p>
                                        <p>Status: {p.protest.status}</p>
                                    </Col>
                                    <Col xs='2'>
                                        {inteface && <Button onClick={ ()=>{ inteface.setDivision(p.id); } } >Go</Button>}
                                    </Col>
                                    </Row>
                                    </Container>
                                    
                                </Alert>
                         );

                         return <Area />   
               }
        );
    }
    
    
  
    swapFight() {
        window.APP.ring.current_fight.swap();
        
    }
  
    setDivision(division) {
        this.current_division = division; 
    }
  
    setCurrentFight(fight) {    
    
        if(this.current_fight != null && fight == null) {
            this.current_fight.in_ring_name = '';
        }
    
        this.current_fight = fight;
        if(this.current_division != null) {
            this.current_division.setCurrentComp(fight)
        }
            
        if(fight) {
            postMaster.toSend('/tournament-ring/'+ this.id + '/fight/' + fight.id   )
        } else {
            postMaster.toSend('/tournament-ring/'+ this.id + '/fight/clear' )
        }
        window.APP.forceUpdate();
    }
  
    getAVCalls() {
        return this.getCurrentAvailableCalls().filter(function(e) { return e.type < 1; } );
    }
    
    getPAVAcalls() {
         return this.getCurrentAvailableCalls().filter(function(e) { return e.type < 1 && e.primary == 1;});
    }
    getDivision(id) {
        if(this.ring_import && this.ring_import.id == id) {
            return this.ring_import;
        }    
        return this.divisions.find(element => element.id == id);
    }
    
    getNumJudges() {
        return this.getJudges().length;
    }
    
    updateJudges(jud_alt = null) {
        var all_judges = this.getJudges();
        
        var center_num = Math.floor( ( all_judges.length ) / 2.0) + 1;
        
        for (let step = 0; step < all_judges.length; step++) {             
             all_judges[step].number = 0;                
        }
        
        var has_center = false;
        
        for (let step = 0; step < all_judges.length; step++) {
            if(all_judges[step].center != "") {
                all_judges[step].number = center_num;
                has_center = true;
                break;
            }
        }
        
        if(!has_center) {
            center_num = 0;
        }
        
        var on_num = 1;
        
        for (let step = 0; step < all_judges.length; step++) {
            if(all_judges[step].number != 0) {
                continue;
            }
            if(on_num == center_num) {
                on_num = on_num + 1;
            }
            all_judges[step].number = on_num;
            on_num = on_num + 1;
        }
        
        var judge_data = [];
        for (let step = 0; step < all_judges.length; step++) {
            judge_data.push(all_judges[step].simple() );
        }
        
        if(jud_alt) {
            judge_data.push(jud_alt.simple() );
        }
        
        postMaster.toSendData('/tournament-ring/' + this.id + '/judge-data',judge_data );
    }
    
    getSessionJudges(id) {
        var js = this.a_judges.filter(function(e) { return !e.is_current() && e.is_future() && !e.is_upcoming() && e.schedule_id == id; });
        return js;        
    }
    
    getJudge(num) {
        var js = this.a_judges.filter(function(e) { return e.is_current() && e.number == num;});
        
        if(js.length > 0) {
            return js[0];
        } else {
            return false;
        }
    }
    
    getJudges() {
        const judgeSort = function(a, b){             
                return a.number - b.number;
        }
        
        return this.a_judges.filter(function(e) { return e.is_current();}).sort(judgeSort);
    }
    
    getUpcomingJudges() {
         return this.a_judges.filter(function(e) { return e.is_upcoming();});
    }
    
    getFutureJudges() {
         return this.a_judges.filter(function(e) { return e.is_future();});
    }
  
    load(ring_data, comm_data = false) {
        var ring = this;
        this.alertsManager.setCreateURL('/tournament-ring/'+ ring.id + '/alert-create/') 
        this.alertsManager.setUpdateURL('/tournament-ring/'+ ring.id + '/alert-forward/') 

        
        if(ring_data == null) {
            postMaster.immediateGet("/tournament-ring/" + this.id + '?ajax=1')
            .then(res => res.json())
            .then(
              (result) => {
                if(result.timeZone) {
                    window.APP.setTimeOption('timeZone',result.timeZone);
                }
                if(result.uses_kg) {
                    window.APP.useKg(result.uses_kg);
                }
                ring.tournament_id = result.tournament_id;
                ring.name = result.name;
                window.APP.name = result.name;
                ring.judge_display = result.judge_display;
                ring.status = result.status;
                ring.active = result.active;
                ring.groups = result.groups;
                ring.image = result.image;
                ring.lock_shuffles = result.lock_shuffles;
                ring.judge_as_buttons = result.judge_as_buttons;
                ring.ordered = result.ordered;
                ring.divisions = [];
                ring.alertsManager.load(result.alerts);
                
                if(result.help_alert_id!='') {
                    ring.alertsManager.availableAlerts.forEach(function(alert){
                        if(result.help_alert_id == alert.id) {
                            ring.help_alert = alert;
                        }
                    });
                }
                
                ring.default_call_pack = result.default_call_pack;
                ring.available_call_packs = [];
                ring.judge_check_in = parseInt(result.judge_check_in);
                
                ring.ring_import_request = result.imported_division_request;
                
                ring.ring_import_uuid = result.imported_uuid;
                
                result.fighting_call_packs.forEach(element => { 
                    var p = {
                        uuid : element.uuid,
                        name : element.name,
                        available_calls: []
                    }
                    
                    element.calls.forEach(c => { 
                        p.available_calls.push(
                                new FightAvCall(c)
                        )
                    });
                    ring.available_call_packs[element.uuid] = p;
                });
                
                result.active_alerts.forEach(element => { 
                    ring.alertsManager.addAlert(element)
                });
                
                 ring.a_judges = [];
                 result.all_judges.forEach(element => { 
                     let j = new Judge(element);
                     ring.a_judges.push(j);
                 });
                
                result.divisions.forEach(element => { 
                            let div = new Division(element.id);
                            div.ring = ring;
                            div.load(element);
                            ring.divisions.push(div);                            
                            if(result.current_division_id == div.id) {
                                ring.current_division = div;
                            }
                        });
                if(result.imported_division) {        
                    var imported_div = new Division(result.imported_division.id)
                    imported_div.ring = ring;
                    imported_div.load(result.imported_division);
                    ring.ring_import = imported_div;
                }
                let div = ring.current_division;
                if(div){
                    ring.current_fight = div.getFight(result.fight_id);
                    if(ring.current_fight) {
                        postMaster.toSend('/tournament-fight/'+ ring.current_fight.id +'/tick/sync/sync');
                    }
                    if(div.current_comp) {
                        postMaster.toSend('/tournament-standing/'+ div.current_comp.id +'/tick/sync/sync')
                    }
                }
                
                ring.comm_data_from = result.comm_data_from;
                
                document.getElementById("loading_area").style.display = "none";
                window.APP.forceUpdate();
                
                
                if(comm_data && ring.comm_data_from != null && ring.comm_data_from != ''){
                    postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/ring/" + ring.id)
                    .then(res => res.json())
                    .then(
                    (result) => {
                        result.forEach(p=>{ 
                            if(p == null) {
                                return;
                            }
                            ring.processProfile(p);
                        });
                         window.APP.reset();
                    });                    
                    
                    window.EchoScreen.private('ring.'+ring.id+'.partner.'+ring.comm_data_from+'.broadcast')
                    .listen('TournamentStandingCommentatorUpdate', (e) => {   
                        var div = ring.getDivision(e.division_id);
                        if(!div)return;
                        var standing = div.getComp(e.standing_id);
                        if(!standing) return;
                        
                        postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/standing/" + standing.id)
                        .then(res => res.json())
                        .then(
                        (result) => {
                            if(result == null) {
                                    return;
                            }
                            standing.profile_data = result.comp;
                            window.APP.reset();
                        });
                    });
                }
              }
            );
            return;
        } else {
            ring.tournament_id = ring_data.tournament_id;        
            ring.name = ring_data.name;
            ring.judge_display = ring_data.judge_display;
            ring.status = ring_data.status;
            ring.active = ring_data.active;
            ring.image = ring_data.image;
            ring.groups = ring_data.groups;
            ring.lock_shuffles = ring_data.lock_shuffles;
            ring.judge_as_buttons = ring_data.judge_as_buttons;
            ring.divisions = [];
            ring.alertsManager.load(ring_data.alerts);
            ring.ordered = ring_data.ordered;
            ring.color = ring_data.color;
            ring.icolor = ring_data.icolor;
            ring.symbol = ring_data.symbol;
            ring.location = ring_data.location;
            ring.judge_check_in = parseInt(ring_data.judge_check_in);

            ring.default_call_pack = ring_data.default_call_pack;

            ring.ring_import_request = ring_data.imported_division_request;
            
            ring.ring_import_uuid = ring_data.imported_uuid;

            ring_data.active_alerts.forEach(element => { 
                ring.alertsManager.addAlert(element)
            });

             ring_data.all_judges.forEach(element => { 
                 let j = new Judge(element)
                 ring.a_judges.push(j)
             });

            if(ring_data.default_call_pack) {
                ring.default_call_pack = ring_data.default_call_pack;
                ring_data.fighting_call_packs.forEach(element => { 
                    var p = {
                        uuid : element.uuid,
                        name : element.name,
                        available_calls: []
                    }

                    element.calls.forEach(c => { 
                        p.available_calls.push(
                                new FightAvCall(c)
                        )
                    });
                    ring.available_call_packs[element.uuid] = p;
                });
            }

            ring_data.divisions.forEach(element => { 
                        let div = new Division(element.id);
                        div.ring = ring;
                        div.load(element);                        
                        ring.divisions.push(div)

                        if(ring_data.current_division_id == div.id) {
                            ring.current_division = div;
                        }
                    });
            if(ring_data.imported_division) {         
                var imported_div = new Division(ring_data.imported_division.id)
                imported_div.ring = ring;
                imported_div.load(ring_data.imported_division);   
                ring.ring_import = imported_div;
            }

            let div = ring.current_division;
            if(div){
                ring.current_fight = div.getFight(ring_data.fight_id);
                if(ring.current_fight) {
                    postMaster.toSend('/tournament-fight/'+ ring.current_fight.id +'/tick/sync/sync');
                }
            }
            
            window.APP.forceUpdate()
        }

            
    }
    
    getDivisionsInOrder() {
        const timeSort = function(a, b){          
                if(a.estimated_start.getTime() == b.estimated_start.getTime()) {
                    return a.ordered - b.ordered;
                }
                return a.estimated_start.getTime() - b.estimated_start.getTime();
        };
        return this.divisions.sort(timeSort);
    }
    
    getSchedule(numHoursAhead) {
        const timeSort = function(a, b){   
                if(a.estimated_start.getTime() == b.estimated_start.getTime()) {
                    return a.ordered - b.ordered;
                }
                return a.estimated_start.getTime() - b.estimated_start.getTime();
        }
        
        
        
        var f = new Date( Date.now() );
        f.setTime(f.getTime() + (numHoursAhead*60*60*1000) );
        
        return this.divisions.sort(timeSort)
                //.filter((x)=>{ return x.estimated_start.getTime() < f.getTime()  })
        
    }
    
    
    getWindowSchedule(styling) { 
        const adj = styling.adj;
        const divisionsList = this.getSchedule(24).filter(function(value, index, arr){ return (value.status == 0); }).map((division) => 
        <tr onClick={() => { this.swapToDivision(division)} }  ><td style={{ fontSize:((24 * adj) + 'px') }}>{ division.code }</td><td style={{ fontSize:((24 * adj) + 'px') }}>{division.name}</td><td style={{ fontSize:((24 * adj) + 'px') }}>{ division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }</td><td style={{ fontSize:((24 * adj) + 'px'), textAlign:'center' }} >{division.standings.length}</td></tr>
          );
  
         return <Container style={{ width:'100%',maxWidth:'100%' }}>
            <Row style={{ textAlign:'center'}}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'1%', marginTop:((15 * adj) + 'px'), width:'98%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <Table striped bordered hover variant="dark">
                         <thead><tr><th>Code</th><th>Name</th><th>Time</th><th style={{ textAlign:'center' }}>Competitors</th></tr></thead>
                                <tbody>
                                    {divisionsList}
                                </tbody>
                            </Table>
                        </Card.Body>
                  </Card>
                  </Col>
            </Row>                                                       
            </Container>
    }
}