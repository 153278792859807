
import {AppDomain} from '../constants';
import {goesOffline, goesOnline} from '../functions';

class PostMaster {
    
    constructor() {
        this.queue=[];
        this.onMessage = null;
        this.current_sending = false;
        this.hasTimer = false;
    }
    
    immediateGet(url) { 
        return window.fetch(AppDomain + url,
            {
                credentials: 'include',
                headers: {
                    'X-Socket-ID' : window.Echo.socketId()
                }
            }).then(function(response) {
                if( response.headers.get('x-mat') != 'true' ) { goesOffline(); return response; }
                if( response.headers.get('x-mat-auth') != null && response.headers.get('x-mat-auth') != 'true' && (!response.redirected || response.url.includes('login')  ) ) { goesOffline(); return response; }
                
                
                goesOnline();
                return response;
            }).catch(function() {
                goesOffline();
            });
    }
    
    immediatePost(url, obj = {}, method='POST') {
        return window.fetch(AppDomain + url,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Socket-ID' : window.Echo.socketId()
                },
                body: JSON.stringify(obj)
            }).then(function(response) {
                if( response.headers.get('x-mat') != 'true' ) { goesOffline(); return response; }
                if( response.headers.get('x-mat-auth') != null && response.headers.get('x-mat-auth') != 'true' && (!response.redirected || response.url.includes('login')  ) ) { goesOffline(); return response; }
                
                goesOnline();
                return response;
            }).catch(function() {
                goesOffline();
            });
    }
    
    toSend(url, passback = null, afterback = null) {        
        var newMessage = new PostMasterMessage('get',url, null, false, passback,this,afterback)
        this.queueMessage(newMessage);       
    }
    
    toSendData(url, obj, data = true, passback = null, afterback = null) {
        var newMessage = new PostMasterMessage('post',url, obj, data, passback, this, afterback)
        this.queueMessage(newMessage); 
    }
    
    queueMessage(message) {      
        var change = false;
        if(this.onMessage == null) {
            change = true;
        }
        
        if(this.queue.length > 0 || this.current_sending || this.onMessage != null) {
            this.queue.push(message);
            this.retry();
            return;
        }
        this.onMessage = message;
        this.current_sending = true;
        message.trySend();
        
        if(change) {
            window.APP.reset();
        }
    }
    
    leftToSend() {
        return this.queue.length > 0 || this.onMessage != null;
    }
    
    errorSend() {
        goesOffline();
        var t = this;
        this.current_sending = false;
        if(this.hasTimer == false) {
            this.hasTimer = true;
            setTimeout(function(){ t.hasTimer = false; t.retry(); }, 10000);
        }
    }
    
    sent() {
        goesOnline();
        
        if(this.queue.length > 0) {
            this.onMessage = this.queue.shift();
            this.onMessage.trySend();
        } else {
            this.onMessage = null;
            this.current_sending = false;
            window.APP.reset();
        }
    }
    
    retry() {
        if(this.current_sending == false && this.onMessage == null && this.queue.length > 0 ) {
            this.onMessage = this.queue.shift();
        } 
        
        
        if(this.current_sending == false && this.onMessage != null && window.sending) {
            this.current_sending = true;
            this.onMessage.trySend();            
        }
    }
}

class PostMasterMessage {
    constructor(type, url, obj, data, passback, returnto, afterback= null) {
        this.type = type;
        this.url = url;
        this.obj = obj;
        this.data = data;
        this.passback = passback;
        this.returnto = returnto;
        this.time = Date.now();
        this.afterback = afterback;
    }
    
    trySend() {
        var returnto = this.returnto;
        var msg = this;
        if(this.type == 'get') {
            if(!window.sending) {
                return;
            }
            
            var f = window.fetch(AppDomain + this.url,
            {
                credentials: 'include',
                headers: {
                    'X-Socket-ID' : window.Echo.socketId(),
                    'X-MAT-time-queued' : this.time
                }
            }).then(function(response) {
                if (!response.ok) {
                 //   throw Error(response.statusText);
                }
                if( response.headers.get('x-mat') != 'true' ) { returnto.errorSend(); return response; }
                if( response.headers.get('x-mat-auth') != null && response.headers.get('x-mat-auth') != 'true' && (!response.redirected || response.url.includes('login')  ) ) {
                    returnto.errorSend(); return response;
                }
                
                if(msg.afterback) {
                    msg.afterback();
                }
                
                returnto.sent();
                return response;
            }).catch(function() {
                returnto.errorSend();
            });
            if(this.passback) {
                this.passback(f);
            }
        }
        if(this.type == 'post') {
            var sendbody = '';
            if(this.data) {
                sendbody = JSON.stringify({data: this.obj});
            } else {
                sendbody = JSON.stringify(this.obj);
            }
            
            window.fetch(AppDomain + this.url,
            {   method: 'POST',
                credentials: 'include',
                headers: {
                   'Content-Type': 'application/json',
                   'X-Socket-ID' : window.Echo.socketId(),
                    'X-MAT-time-queued' : this.time
                },
                body: sendbody
            }).then(function(response) {
                if (!response.ok) {
                 //   throw Error(response.statusText);
                }
                
                if( response.headers.get('x-mat') != 'true' ) { returnto.errorSend(); return response; }
                if( response.headers.get('x-mat-auth') != null && response.headers.get('x-mat-auth') != 'true' && (!response.redirected || response.url.includes('login')  ) ) { returnto.errorSend(); return response; }
                
                if(msg.afterback) {
                    msg.afterback();
                }
                
                returnto.sent();
                return response;
            }).catch(function() {
                returnto.errorSend();
            });   
       }
    }
}

if(window.MATPostMaster == null) {
    window.MATPostMaster = new PostMaster();
}

export default window.MATPostMaster;
