import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import postMaster from '../logic/postmaster';
import {AppContext, AppDomain} from '../constants';
import {AlertsManager} from '../models/alerts';
import {MessagesDisplay, Message} from '../models/messages';
import {TimeDisplay} from './TimeDisplay';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


export class MedicInteface extends React.Component { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);        
        
        
        this.messages =false;
                
        this.messagesToggle = this.messagesToggle.bind(this);
        
        this.image = '';
        this.tournament_name = '';
        this.tournament_id = props.tournament_id;
        
        this.alerts = [];
        
        this.alertsManager = new AlertsManager()
        
        window.APP.medic = this;
        
        this.resize = this.resize.bind(this);
        
        this.load();
        var inter = this;
        var t = this;
        window.Echo.private('tournament.' + this.tournament_id + '.medic')
        .listen('CustomMessage', (e) => {        
            window.APP.messages_manager.addMessage(new Message(e.message.subject, e.message.body  ));    
            window.APP.reset();
        })
        .listen('AlertForward', (e) => {
           inter.alertsManager.receiveEvent(e)
            window.APP.reset();
        })
        .listen('RefreshRequest', (e) => {        
            if(e.type == 'data') {
                window.APP.needs_reload = 1;
        
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                   t.reload();
                }
            }
    
            if(e.type == 'refresh') {
                window.APP.needs_refresh = 1;
                
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                    window.location.reload();
                }
            }
            
            window.APP.reset();
        });
                                                                         ;
        
    }
    
    reload() {
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        this.load();
        window.APP.needs_reload = 0;
        window.APP.reload();
    }
    
    load() {
        var inter = this;
        postMaster.immediateGet("/tournament/" + this.tournament_id + '/medic_info' ).then(res => res.json())
       .then(
            (result) => {
                inter.image = result.image;
                inter.name = result.name;
                if(result.timeZone) {
                    window.APP.setTimeOption('timeZone',result.timeZone);
                }
                if(result.uses_kg) {
                    window.APP.useKg(result.uses_kg);
                }
                inter.alertsManager.load(result.alerts);
                result.active_alerts.forEach(element => { 
                    inter.alertsManager.addAlert(element)
                });
                document.getElementById("loading_area").style.display = "none";
                window.APP.reset();
            }
        );        
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    componentWillUnmount(){
        window.APP.unRegisterResize(this);
   }
   
   componentDidMount(){
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
        this.headerRow.clientHeight);

        window.APP.registerResize(this);
   }
   
   resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
   }
   
   componentWillUnmount(){
        
   }
   
    
    getManager() {
        return <MedicManagerDisplay interface={this}></MedicManagerDisplay>;
    }
    
    
    
   render() {
        let showSidebar = this.messages;
        let holder = this;
        
        return (
           <Container>
     <Row>
        <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
            <Navbar bg="dark" expand="lg" variant="dark">
            { this.image && 
                <Navbar.Brand href="#home"><img
                src={this.image}
                className="tournamentlogo"
                alt="tournament logo"
              /></Navbar.Brand> }
            <Navbar.Brand href="#home" className="tagname">Medic</Navbar.Brand>
                <Nav className="mr-auto">
                   
                </Nav>
                
                <Nav>
                    <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
                </Nav>
            </Navbar>

        

        </Col>   
        <Col  xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px'  }}>    
                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                    No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                  Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Internet was lost - data may be outdated - {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}
                
                {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    When you have a moment please redownload data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                </Alert>}

                {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Please refresh at the nearest opportunity <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                </Alert>}
                
            {this.getManager() }
        </Col>
        { showSidebar && 
        <Col xs={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'30px' }}>

            <Container>
                { this.messages && 
                <Row>
                    <Col xs={12}>

                    <Card  bg='dark' text='light' >
                    <Card.Header >Messages
                    <div style={{float:'right'}}>
                    <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                                </div>
                                </Card.Header>
                    <Card.Body style={{ color:'black' }} >
                        <MessagesDisplay></MessagesDisplay>  
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>}

            </Container>

        </Col>
        }

    </Row>


            <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">

              <Nav className="mr-auto">
              <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
              
              {window.APP.connection_status == 0 && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
          </Nav>}
          
              <Nav style={{ flexDirection:'row'}}>
              
              {postMaster.queue.length == 0 && postMaster.onMessage == null && window.APP.connection_status > 0 && window.pusher_status == 1 && <Nav.Link  onClick={() => { holder.reload(); } } ><span class={ (window.APP.connection_status == 1 ) ? 'text-primary':'text-secondary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Click To Download Data
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-download-alt"></i></OverlayTrigger></span></Nav.Link>}
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
          
              
              {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
              
              {window.APP.canFullscreen() && !window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.goFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Go Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-expand"></i></OverlayTrigger></span></Nav.Link>}
                
                    {window.APP.canFullscreen() && window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.exitFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Exit Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-compress"></i></OverlayTrigger></span></Nav.Link>}
              
                <Navbar.Brand href="#home">
          <img
            src={ AppDomain + '/pass_M_Logo.png' }
            height="30"
            alt="MAT Action logo"
          />
        </Navbar.Brand>
              </Nav>
          </Navbar>
    </Container>
    );
  }
}

class MedicManagerDisplay extends React.Component {
    
     constructor(props) {
        super(props);
        
        this.interface = props.interface;
        
        this.results = [];
                
        this.name_filter = '';
        
        
        this.search = this.search.bind(this);
        this.nameFilterUpdate = this.nameFilterUpdate.bind(this);
        this.healed = this.healed.bind(this);
    }
    
    nameFilterUpdate(event) {
        this.setState({name_filter: event.target.value.toLowerCase() });
        this.name_filter =  event.target.value.toLowerCase();
        this.search(event.target.value.toLowerCase());
    }
    
    healed(id) {
        postMaster.toSend('/registration/' + id + '/action/heal');
        var t = this;
        setTimeout(function(){ t.search(t.name_filter); }, 1000 );
    }
    
    search(value) {
        var t = this;
        setTimeout(function(){ t.searchIfSame(value); }, 500 );
    }
    
    componentDidMount(){       
        window.APP.registerReload(this)
    }

    componentWillUnmount() {
        window.APP.unRegisterReload(this)
    }
    
    reload() {
        this.searchIfSame(this.name_filter);
    }
    
    searchIfSame(value) {
        if(value != this.name_filter) { 
            return;
        }
    
        var it = this;
        postMaster.immediateGet("/tournament/" + this.interface.tournament_id + '/medic/compsearch?search=' + this.name_filter
                ).then(res => res.json())
          .then(
             (result) => {
                it.results = [];
                result.forEach(r => {
                   it.results.push(r);
               });
               it.setState( {results : it.results} )
             }
           );
    }
    
    render() { 
        if(this.comp != null && this.comp_name == '') {
            this.comp_name = this.comp.name;
        }
        
        return <Container>
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Active Ring Alerts</Card.Header>
                        <Card.Body>
                            <Container>
                                <Row style={{ color:'black' }}>          
                                    {this.interface.alertsManager.multiDisplay()}
                                </Row>
                            </Container>                                                   
                        </Card.Body>
                    </Card>
                </Col>                
            </Row>
            
            <Row style={{ textAlign:'center' }}>
                
                <Col xs={12}>
                    <Card bg='dark'    text= 'light' style={{ margin:'1%', width:'98%'  }}>
                        <Card.Header >Injured Competitor Search</Card.Header>
                        <Card.Body>
                        <Container>
                        <Row style={{ textAlign:'center' }}>
                            <Col md='12'>
                                <InputGroup className="mb-3">
                                      <InputGroup.Text ><i class="fas fa-search"></i></InputGroup.Text>
                                    <Form.Control value={this.name_filter} onChange={this.nameFilterUpdate}
                                      placeholder="Search Name"
                                    />
                                </InputGroup>    
                            </Col>
                        </Row>
                        <Row>
                            { this.results.map( (x) => 
                            <Col md="4">
                            <Card border="light" bg='dark' text= 'light' style={{ margin:'1%', width:'98%'  }}>
                                <Card.Header>{x.name}</Card.Header>
                                <Card.Body >
                                    <Container>
                                    <Button onClick={ ()=>{this.healed(x.reg_id)} } >Healed</Button>
                                    </Container>
                                </Card.Body >
                            </Card>
                            </Col> ) }                                      
                        </Row>
                        </Container>                                                   
                        </Card.Body>
                    </Card>
                </Col>                
            </Row>
        </Container>;
    }
}