import React, {useRef} from 'react';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {AppContext, AppDomain, stripeKey} from '../constants';
import postMaster from '../logic/postmaster';

export class RoomDisplay  extends React.Component {
    
    constructor(props) {
        super(props);        
        if(props.room == null) {         
            this.holder = props.holder;    
            this.room = null;    
        } else {
            this.room = props.room;    
        }
            
        this.ticket = props.ticket;        
        this.widthSpec = 100;        
        this.toUpdate = this.toUpdate.bind(this);
    }
    
    getRoom() {
        if(this.room == null) {
            return this.holder.selected_room;            
        } else {
            return this.room;
        }
    }
    
    setCurrentSeat(s) {
        this.current_seat = s;
        this.setState({current_seat:s});
    }
    
    toUpdate(room) {
        
        this.setState({room:this.room,holder:this.holder });
    }
    
    componentDidMount(){       
       this.widthSpec = this.area.clientWidth ;
       this.setState({widthSpec:this.widthSpec});
       this.getRoom().subscribe(this.toUpdate)
   }
   
   componentWillUnmount() {
       this.getRoom().unSubscribe(this.toUpdate)
    }
    
    sitin(selected_ticket, seat) {
        postMaster.immediatePost('/seating-seat/' + seat.id + '/seat-in/' + selected_ticket.id  + '?json=1',{})
        .then(res => res.json())
        .then(
             (result) => { 
                selected_ticket.can_sit = result.can_sit;
                selected_ticket.upgrades = result.upgrades;
                selected_ticket.name = result.name;
                selected_ticket.status = result.status;
                selected_ticket.product = result.product;
                selected_ticket.tier = result.tier;
                selected_ticket.seat = result.seat;
                selected_ticket.room = result.room;
                selected_ticket.room_id = result.room_id;
                selected_ticket.main = result.main;
                selected_ticket.addon = result.addon;
                window.APP.reset();
             }
           );
    }
    
    render() { 
        var display = this;
        var room = this.getRoom();
        
        if(this.area) {
            this.widthSpec = this.area.clientWidth ;
        }
        var spec = this.widthSpec / room.width;
        var room_height = room.height * spec;
        
        return <div>
        {this.ticket != null 
                && <Container>
        <Row>
        <Col md="12">
        <Card bg='dark' text='light' style={{marginTop:'15px'}}>
        <Card.Body>
            <Container>
            <Row>
            <Col md="4">
            Name: {this.ticket.name}<br></br>
            Tier: {this.ticket.tier}
            </Col>
            <Col md="4">
            {this.ticket.seat != null && <div>
            Seat: {this.ticket.seat.code}<br></br>
            Block: {this.ticket.seat.block}<br></br>
            Row: {this.ticket.seat.row}<br></br>
            Number: {this.ticket.seat.number}   </div>   
            }
            
            {this.ticket.seat == null && <div>Not Seated</div>   
            }
            </Col>
            <Col md="4">
            Product: {this.ticket.product}
            </Col>
            </Row>
            </Container>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>        
        }
        
        {this.current_seat != null 
                && this.current_seat.asile == 0
                && <Container>
        <Row>
        <Col md="12">
        <Card bg='dark' text='light' style={{marginTop:'15px'}}>
        <Card.Body>
            <Container>
            <Row>
            <Col md="4">
            Seat: {this.current_seat.code}<br></br>
            Tier: {this.current_seat.tier_name}
            </Col>
            <Col md="4">
            Block: {this.current_seat.block}<br></br>
            Row: {this.current_seat.row}<br></br>
            Number: {this.current_seat.number}            
            </Col>
            <Col md="4">
            Status: {this.current_seat.statusname}<br></br>
            {this.current_seat.ticketname != '' && <span>Seated: {this.current_seat.ticketname}</span>}
            {this.current_seat.status == 0 && this.ticket != null && <Button onClick={()=>{ display.sitin(this.ticket,this.current_seat) }} variant='success'>Seat {this.ticket.name} Here</Button> }
            </Col>
            </Row>
            </Container>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>        
        }
        <Container>
        <Row>
        <Col md="12">
        <Card bg='dark' text='light' style={{marginTop:'15px'}}>
        <Card.Body>
            <Container>
            <Row>
            <Col md="12">
            Seats to Sell
            </Col>
            </Row>
            <Row>
            {room.tiers.map(x=><Col md="4">
            {x.name}: {x.left}
            </Col>)}
            </Row>
            </Container>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container> 
        { (this.current_seat == null || this.current_seat.asile == 1) && <Container>
        <Row>
        <Col md="12">
        <Card bg='dark' text='light' style={{marginTop:'15px'}}>
        <Card.Body>
        <Container>
            <Row>
            <Col md="12">
            Seat: <br></br>
            Click on a seat below to select<br></br>
            &nbsp;&nbsp;&nbsp;            
            </Col>
            </Row>
            </Container>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>        
        }
        <div height={(room_height + 100)} style={{ width:'100%', height:(room_height + 100) + 'px', marginTop:'15px' }}>
            <div ref={ (area) => { this.area = area } } style={{ height:(room_height + 100) + 'px', position:'absolute', width:'100%' }} >
            {room.blocks.map((block) => <div
                    style={ {
                    width: block.width * spec,
                    heigth: block.height * spec,
                    left: block.x * spec,
                    top: block.y * spec,
                    position:'absolute'
                    }}
                    >
                    
                    {block.rows.map((row) => {
                        var rowstyle = { float:'left' };
                            if(block.directional == 0) {
                                rowstyle.clear="left";
                                rowstyle.width = spec * row.width + 'px';
                            } else {
                                rowstyle.width = spec + 'px';
                            }
                            
                            if(row.x != -1 && row.y != -1) {
                                rowstyle.position='absolute';
                                rowstyle.left=row.x * spec;
                                rowstyle.top=row.y * spec;
                            }
                            
                        return <div
                        
                    style={rowstyle}
                        >
                        {row.seats.map((seat) => { 
                            var ci = '#000000';
                            var co = '#FFFFFF';
                            
                            var seatStyle = {
                                display:block, height:spec +'px',width:spec+'px', float:'left'
                            }
                            
                            if(row.direction == 1) {
                                seatStyle.clear = 'left';
                            }
                            
                            if(seat.status == 0) {
                                ci = seat.opencolor;
                                co = seat.opencolor;
                            } else {
                                ci = seat.filledcolor;
                                co = seat.filledcolor;
                            }
                            if(seat.x > 0 && seat.y > 0) {
                                seatStyle.position='absolute';
                                seatStyle.left=seat.x * spec;
                                seatStyle.top=seat.y * spec;
                            }
                            
                            
                            var font = '0.8';
                            
                            if(seat.status == 2) {
                                co = '#c65cff';
                            }

                            if(seat.status == 3) {
                                co = '#cc0000';
                            }

                            if(seat.status == -1) {
                                co = '#3366ff';
                            }

                            if(seat.number < 100) {
                                font = 1.2;
                            }
                            if(seat.number < 10) {
                                font = 1.6;
                            }
                            
                                        return <div>
                                        
                                        <div
                        onClick={ (e)=>{ if (e.ctrlKey) { display.sitin(this.ticket,seat) } else { display.setCurrentSeat(seat); }  } }
                            onContextMenu={(e)=>{ e.preventDefault();  display.sitin(this.ticket,seat); return false; }}    
                        
                        style={ seatStyle }
                        >
                        
                        {seat.asile != 1 && 
                        <svg style={{ display:'block', height:spec +'px',width:spec+'px' }} viewBox="0 0 84 84" height={spec} width={spec}>
                        <circle cx="42" cy="42" r="28" stroke="black" stroke-width="0" fill={co} />
                        <circle cx="42" cy="42" r="23" stroke="black" stroke-width="0" fill="white" />
                        <circle cx="42" cy="42" r="19" stroke="black" stroke-width="0" fill={ci} />
                        <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" font-size={font + 'em'} fill="white">{seat.number}</text>
                        </svg>
                                }
                        
                                                </div></div>;}) }
                    
                    </div>;}) }
            </div>) }
            </div>
            </div>
            </div>;        
    }
    
}