import React from 'react';

import config from 'react-reveal/globals';


import Pusher from 'pusher-js';
import Echo from "laravel-echo";


import {AdditionalWindowManager,AdditionalWindowManagerDisplay } from './logic/windows';
import {AppContext, AppDomain, PusherKey} from './constants';
import postMaster from './logic/postmaster'
import {AdminHelpDesk} from './components/adminHelpDesk'
import {Ring} from './models/ring'
import {Scorekeeper} from './components/scorekeepers'
import {MessagesManager} from './models/messages'
import {PersonalJudgeInteface,JudgeInterface} from './components/judges'
import {CoordinatorInteface} from './components/coordinators'
import {MedicInteface} from './components/medic'
import {RegistrationInteface} from './components/registrations'
import {TrophyInteface} from './components/trophy';
import {SoundsManager} from './logic/sounds';
import {DivisionBracketsDisplay} from './components/divisionbracketsdisplay';
import {ScreenDisplay} from './components/screen';

import {goesOffline, goesOnline} from './functions';

import './App.css';



config({ ssrFadeout: true });
const google = window.google;

// or: const { SubresourceIntegrityPlugin } = require('webpack-subresource-integrity');


window.Pusher = require('pusher-js');
window.Pusher.logToConsole = false;
window.Pusher.log = function(message) {};
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: PusherKey,
    cluster: 'us2',
    forceTLS: true,
    authEndpoint: AppDomain + '/broadcasting/auth'
});

window.EchoScreen = new Echo({
    broadcaster: 'pusher',
    key: PusherKey,
    cluster: 'us2',
    forceTLS: true,
    authEndpoint: AppDomain + '/screen-data/broadcasting/auth'
});

window.pusher_status = 0;
window.sending = true;

window.addEventListener('beforeunload', function (e) {
    if(postMaster.onMessage != null || postMaster.queue.length > 0) {
        e.preventDefault();
        e.returnValue = 'There are unsent events that will be lost, are you sure you want to refresh?';
    }
});

window.Echo.connector.pusher.connection.bind("unavailable", function () {
        goesOffline();
        window.pusher_status=0;
  });

  window.Echo.connector.pusher.connection.bind("connected", function () {
       window.pusher_status=1;
        goesOnline();
  });

  window.Echo.connector.pusher.connection.bind("disconnected", function () {
       window.pusher_status=0;
        goesOffline();
  });

  window.Echo.connector.pusher.connection.bind("connecting", function () {
       window.pusher_status=0;
        goesOffline(true);
  });

  window.Echo.connector.pusher.connection.bind("failed", function () {
       window.pusher_status=0;
        goesOffline();
  });

  window.addEventListener('offline', function(e) { goesOffline(); });


class App extends React.Component {
    
    heartbeat() {
        var t = this;
        setTimeout(function(){
            t.heartbeat();
        }, 1000 * 60 * 200
                );
        
        postMaster.immediateGet('/heartbeat');
    }
    
    useKg(uses_kg) {
        window.APP.uses_kg = uses_kg;
    }
    
    
    
    setTimeOption(key, value) {
        window.APP.time_options[key] = value;
        if(key == 'timezone') {
            window.APP.date_options[key] = value;
        }
    }
    
    constructor(props) {
        super(props);
        
        this.state = { hasError: false };
        
        Pusher.logToConsole = true; 
        
        this.window_manager = new AdditionalWindowManager();
        this.messages_manager = new MessagesManager();

        this.sound_manager = new SoundsManager();

        window.APP = this;
        window.APP.connection_status = 2;
        window.APP.connection_status_hide = 0;
        window.APP.fail_transparent = false;
        
        
        window.APP.needs_reload = 0;
        window.APP.needs_refresh = 0;
        
        
        window.APP.time_locale = [];
        window.APP.time_options = {timeStyle:'short'};
        
        window.APP.date_locale = 'en-US';
        window.APP.date_options = {weekday:'short'};
        
        window.APP.uses_kg = false;
        
        window.APP.name = '';
        window.APP.header_second = '';
        window.APP.sparring_color = 'blue';
        
        if(window.PARAM.type == 'ring') {
            if(!window.crypto) {
                alert('This browser will not use a cryptographically secure shuffle')
            }            
        }
        
        if(window.PARAM.type == 'ring' || (window.PARAM.type == 'judge' && window.PARAM.num !== null)) {
        
            if (! window.RING ) {
                window.RING = new Ring(window.PARAM.id);  
                window.RING.load();
            }
            this.ring = window.RING
            
            this.type = window.PARAM.type;
        }
        
        if(window.PARAM.type == 'judget' && window.PARAM.judge_id !== null) {
            this.type = window.PARAM.type;
        }
        
        if(window.PARAM.type == 'helpdesk') {
            this.type = 'helpdesk';
        }
        
        if(window.PARAM.type == 'coord' && window.PARAM.coord_id !== null) {
            this.type = 'coord';
        }
        
        if(window.PARAM.type == 'medic' && window.PARAM.coord_id !== null) {
            this.type = 'medic';
        }
        
        if(window.PARAM.type == 'registration' && window.PARAM.coord_id !== null) {
            this.type = 'registration';
        }
        
        if(window.PARAM.type == 'trophy' && window.PARAM.coord_id !== null) {
            this.type = 'trophy';
        }
        
        if(window.PARAM.type == 'divisionbracketdisplay' && window.PARAM.coord_id !== null) {
            this.type = 'divisionbracketdisplay';
        }
        
        if(window.PARAM.type == 'screen') {
            this.type = 'screen';
        }
        
        var t = this;
        setTimeout(function(){
            t.heartbeat();
        }, 1000 * 60 * 200
                );
        
        
        this.onReloads = [];        
        this.postReload = [];
        this.onResize = [];
        
        this.resize = this.resize.bind(this);
        window.onresize = this.resize;
        window.onorientationchange = this.resize;
        window.document.addEventListener('webkitfullscreenchange', this.resize);
        window.document.addEventListener('fullscreenchange', this.resize);
        window.document.addEventListener('mozfullscreenchange', this.resize);
    }
    
    isFullscreen() {
        
        if (document.fullscreenElement != null || document.mozFullScreenElement  != null|| document.webkitFullscreenElement  != null || document.msFullscreenElement  != null) {
            return true;
        }
        
        if( Math.abs(window.screen.width - window.innerWidth) < 20 && Math.abs(window.screen.height - window.innerHeight) < 20 ) {
            return true;
        }
        return false;
    }
    
    exitFullscreen() {        
        if (document.exitFullscreen != null ) {
            document.exitFullscreen();
            return true;
        }
        
        if (document.msExitFullscreen != null ) {
            document.msExitFullscreen();
            return true;
        }
        
        if (document.webkitExitFullscreen != null ) {
            document.webkitExitFullscreen();
            return true;
        }
        
        if (document.mozExitFullscreen != null ) {
            document.mozExitFullscreen();
            return true;
        }
        
        return false;
    }
    
    goFullscreen() {
        if (document.documentElement.requestFullscreen != null ) {
            document.documentElement.requestFullscreen();
            return true;
        }
        
        if (document.documentElement.msRequestFullscreen != null ) {
            document.documentElement.msRequestFullscreen();
            return true;
        }
        
        if (document.documentElement.mozRequestFullscreen != null ) {
            document.documentElement.mozRequestFullscreen();
            return true;
        }
        
        if (document.documentElement.webkitRequestFullscreen != null ) {
            document.documentElement.webkitRequestFullscreen();
            return true;
        }
        
        
        return false;
    }
    
    canFullscreen() {        
        if (document.fullscreenEnabled != null || document.mozFullscreenEnabled  != null|| document.webkitFullscreenEnabled  != null || document.msFullscreenEnabled  != null) {
            return true;
        }
        return false;
    }
    
    resetSecond() {
        setTimeout(function(){ window.APP.reset(); }, 500);
    }
    
    reload() {
        this.onReloads.forEach( (x)=> {
            x.reload();
        });
    }    
    registerReload(item) {
        this.onReloads.push(item);
    }     
    unRegisterReload(item){
        this.onReloads = this.onReloads.filter( (x) => x !== item);
    }
    
    resize() {
        if(window.PARAM.type == 'registration' ) {
           // return;
        }
        this.onResize.forEach( (x)=> {
            x.resize();
        });
        
        if(window.PARAM.type != 'registration' ) {
            window.APP.reset();
        }
        
        var t = this;
        
        setTimeout(function(){
            t.onResize.forEach( (x)=> {
                x.resize();
            });
            if(window.PARAM.type != 'registration' ) {
                window.APP.reset();
            }
        },200);
        
        setTimeout(function(){
            t.onResize.forEach( (x)=> {
                x.resize();
            });
            if(window.PARAM.type != 'registration' ) {
                window.APP.reset();
            }
        },2000);
        
        setTimeout(function(){
            t.onResize.forEach( (x)=> {
                x.resize();
            });
            if(window.PARAM.type != 'registration' ) {
                window.APP.reset();
            }
        },5000);
        
    }    
    registerResize(item) {
        this.onResize.push(item);
    }     
    unRegisterResize(item){
        this.onResize = this.onResize.filter( (x) => x !== item);
    }
    
    onReload() {
        this.postReload.forEach( (x)=> {
            x.postReload();
        });
    }    
    registerOnReload(item) {
        this.postReload.push(item);
    }     
    unRegisterOnReload(item){
        this.postReload = this.postReload.filter( (x) => x !== item);
    }
    
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
      }

      componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        var data = error.toString();
        postMaster.toSendData('/react-error', {error:data} );
      }
    
    render() {        
        
        if (this.state.hasError && !window.APP.fail_transparent) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        } 
        if(this.state.hasError) {
            window.document.body.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            return <div></div>;
        }
        
        if(this.type == 'ring') {
            return <AppContext.Provider value={this} ><Scorekeeper manager={this.window_manager} ring={this.ring} ></Scorekeeper><AdditionalWindowManagerDisplay></AdditionalWindowManagerDisplay></AppContext.Provider>;
        }
        
        if(this.type == 'judge') {
            return <AppContext.Provider value={this} ><JudgeInterface manager={this.window_manager} ring={this.ring} ></JudgeInterface></AppContext.Provider>;
        }
        
        if(this.type == 'judget') {
            return <AppContext.Provider value={this} ><PersonalJudgeInteface manager={this.window_manager} ></PersonalJudgeInteface></AppContext.Provider>;
        }
        
        if(this.type == 'helpdesk') {
            return <AdminHelpDesk></AdminHelpDesk>;
        }
        
        if(this.type == 'coord') {
            return <AppContext.Provider value={this} ><CoordinatorInteface tournament_id={window.PARAM.coord_id}></CoordinatorInteface></AppContext.Provider>;
        }
        
        if(this.type == 'medic') {
            return <AppContext.Provider value={this} ><MedicInteface tournament_id={window.PARAM.coord_id}></MedicInteface></AppContext.Provider>;
        }
        
        if(this.type == 'registration') {
            return <AppContext.Provider value={this} ><RegistrationInteface tournament_id={window.PARAM.coord_id}></RegistrationInteface></AppContext.Provider>;
        }
        
        if(this.type == 'trophy') {
            console.log(window.PARAM.coord_id);
            return <AppContext.Provider value={this} ><TrophyInteface tournament_id={window.PARAM.coord_id}></TrophyInteface></AppContext.Provider>;
        }
        
        if(this.type == 'divisionbracketdisplay') {
            return <AppContext.Provider value={this} ><DivisionBracketsDisplay division_id={window.PARAM.coord_id}></DivisionBracketsDisplay></AppContext.Provider>;
        }
        
        if(this.type == 'screen') {
            return <AppContext.Provider value={this} ><ScreenDisplay></ScreenDisplay><AdditionalWindowManagerDisplay></AdditionalWindowManagerDisplay></AppContext.Provider>;
        }
        
        document.getElementById("loading_area").style.display = "none";
        return <div style={{ color: 'white' }}>Please Check Your Link (Invalid link)</div>;
    }
    
    reset() {
        this.setState(this.state);
    }
    
    

}

export default App;
