import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';

import postMaster from '../../logic/postmaster'
import {StopWatch, Timer} from '../../logic/clocks';
import {AppContext, AppDomain, seeds} from '../../constants';
import {FightCall, Fight, BracketChart} from '../../models/fights'
import {Standing} from '../../models/standing'
import {Division} from '../../models/division'
import {Judge} from '../../models/judge'
import {MessagesDisplay, Message} from '../../models/messages'
import {swapFights} from '../../functions';
import {joinMeeting} from '../../logic/meeting';


export class DivisionManagerDisplay extends React.Component { 
    static contextType = AppContext;
    

    constructor(props) {
        super(props);
        props.holder.div_area = this;
        this.holder = props.holder;
        this.ring = props.ring;
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        
        
        this.swapToDivision = this.swapToDivision.bind(this);
        this.checkCompetitor = this.checkCompetitor.bind(this);
        this.checkin_filter = '';
        
        this.checkinFilterUpdate = this.checkinFilterUpdate.bind(this);
        this.startDivision = this.startDivision.bind(this);
        this.startShufDivision = this.startShufDivision.bind(this);
        this.startScoringDivision = this.startScoringDivision.bind(this);
        this.returnToScoringDivision = this.returnToScoringDivision.bind(this);
        this.cancelDivision = this.cancelDivision.bind(this);
        this.setScore = this.setScore.bind(this);
        this.completeDivision = this.completeDivision.bind(this);
        this.toggleWinner = this.toggleWinner.bind(this);
        this.setAddTime = this.setAddTime.bind(this);
        this.setSubTime = this.setSubTime.bind(this);
        this.toggleCardView = this.toggleCardView.bind(this);
        this.setCheckinOrder = this.setCheckinOrder.bind(this);
        this.toggleShowCompleted = this.toggleShowCompleted.bind(this)
        this.filteredComplete = this.filteredComplete.bind(this);
        this.closeDivision = this.closeDivision.bind(this);
        this.clearJudges = this.clearJudges.bind(this);
        this.acceptRingImport = this.acceptRingImport.bind(this);
        this.clearImport = this.clearImport.bind(this);
        this.clearImportConfirm = this.clearImportConfirm.bind(this);
        this.toggleFightList = this.toggleFightList.bind(this);
        
        this.show_completed = false;
        
        this.addTime = false;
        this.subTime = false;
        
        this.card_view = false;
        
        this.check_order = 'checkin'
        
        this.focused_score = null;
        this.focusOnScore = this.focusOnScore.bind(this);
        
        this.edit_fight_value = 30;
    }
    
    acceptRingImport() { 
        postMaster.toSendData('/tournament-ring/'+ this.ring.id + '/action/accept_import?ajax=1&division_id='+this.ring.ring_import_request.uuid,{});
        this.ring.ring_import_request = null;
        window.APP.reset();
    }
    
    clearImport() {
        postMaster.toSendData('/tournament-ring/'+ this.ring.id + '/action/clear_import?ajax=1',{});
        this.ring.ring_import_request = null;
        this.ring.ring_import = null;
        window.APP.reset();
    }
    
    clearImportConfirm() {
        if(window.confirm('You will not be able to enter this imported division again on your own.  Are you sure you want to exit?') ) {
           this.clearImport();
        }
    }
    
    focusOnScore(score){ 
        this.focused_score = score;
        this.setState({ focused_score:score })
    }
    
    focusedScoreUpdate(score, x) {
        this.setScore(this.current_division.current_comp, score.judge,x);
        
        if(this.current_division.current_comp.scores.length > parseInt(score.judge) + 1 ) {
            this.focused_score = this.current_division.current_comp.getScore(parseInt(score.judge) + 1);
        } else{
            this.focused_score = null;
        }
        
        this.setState({ focused_score:this.focused_score });
    }
    
    focusNextScore(score) {
        if(this.current_division.current_comp.scores.length > parseInt(score.judge) + 1 ) {
            this.focused_score = this.current_division.current_comp.getScore(parseInt(score.judge) + 1);
        } else{
            this.focused_score = null;
        }
        
        this.setState({ focused_score:this.focused_score });
    }
    
    next() {
        if(this.current_division != null && this.current_division.current_comp != null) {
            let comp = this.current_division.current_comp;
            if(comp.after == null) {
                if(this.current_division.status == 2) {
                    //this.completeDivision();
                }
                
                if(this.current_division.status == 1) {
                    //this.startScoringDivision();
                }                
            } else {
                this.setCurrentComp(comp.after);
            }
        }
    }
    
    previous() {
        if(this.current_division != null && this.current_division.current_comp != null) {
            let comp = this.current_division.current_comp;
            this.setCurrentComp(comp.preceding);
        }        
    }
    
    clearJudges() {
        var r = this.ring;
        var js = [];
        r.getJudges().forEach((judge) => {
            judge.stop_time = new Date();
            if(isNaN(judge.planned_stop ) ) {
                judge.planned_stop = new Date();
            } 
            js.push(judge);
        });   
        
        js.forEach((judge) => {
           r.updateJudges(judge); 
        });
        
        
        window.APP.forceUpdate();
    }
    
    filteredComplete(bool){
        if(!bool) {
            return this.ring.getSchedule(24).filter(function(value, index, arr){ 
                return value.status < 3;
            });
        } else {
            return this.ring.getSchedule(24);
        }
    }
    
    toggleShowCompleted() {
        this.show_completed = !this.show_completed
        this.setState({show_completed: this.show_completed});
    }
    
    setCheckinOrder(sortBy) {
        this.check_order = sortBy;
        this.setState({check_order: this.check_order});
    }
    
    toggleCardView() {
        this.card_view = !this.card_view;
        this.setState({card_view:this.card_view} );
    }
    
    swapToDivision(division) {
        this.current_division = division;
        this.ring.setDivision(division);
        
        if(division) {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/current-division/' + division.id )
        } else {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/current-division/clear' )
        }
        this.setState({current_division: division});
    }
    
    startShufDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 1 )
        this.current_division.shufStart();
        this.setState({current_division: this.current_division});
    }
    
    startDivision(last = false) {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 1 )
        this.current_division.start();
        if(last) {
            this.setCurrentComp(this.current_division.getCompOrder()[this.current_division.getCompOrder().length - 1])
        }
        this.setState({current_division: this.current_division});
    }
    
    toggleWinner() {
        this.current_division.toggleWinnerPage();
        this.setState({current_division: this.current_division});
    }
    
    toggleFightList() {
        this.current_division.toggleFightList();
        this.setState({current_division: this.current_division});
    }
    
    returnToScoringDivision() {
        if(this.current_division.config_type == 'scores') {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 2 )
            this.current_division.startScore();
            this.setCurrentComp(this.current_division.getCompOrder()[this.current_division.getCompOrder().length - 1])
            this.setState({current_division: this.current_division});
        } else {
            this.startDivision();
        }
    }
    
    startScoringDivision() {
        
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 2 )
        this.current_division.startScore();
        this.setState({current_division: this.current_division});
    }
    
    finishSparring() { 
        var div = this.current_division;
        
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 );
        this.current_division.standings.forEach(standing => {
            standing.place = 0;
        });
              
        var comps = this.current_division.standings.filter(function(e){ return e.checked_in;});
        
        if(comps.length == 3 && (this.current_division.sparring_rr_if_three == 'Yes' || this.current_division.sparring_rr_if_three == 'Yes With Accumalation Tie Breaker' ) ) {                                     
            comps.forEach(standing => {
                standing.place = 3;
            });
            
            this.current_division.fights.forEach(fight => {
                if(fight.comp1 != null && fight.comp2 != null ) {                    
                    var k = fight.getWinner();
                    if(k) {
                        k.place = k.place - 1;
                    }
                }
            });
            
            var tie = true;
            
            this.current_division.standings.forEach(standing => {
                standing.temp_score = 0;
                if(standing.place != 2 && standing.place > 0 ) {
                    tie = false;
                }
            });
            
            
            
            if(tie && this.current_division.sparring_rr_if_three == 'Yes With Accumalation Tie Breaker') {
                var fights = this.current_division.fights;
                this.current_division.standings.forEach(standing => {
                    fights.forEach(fight => {
                        if(fight.comp1 != null && fight.comp1.id == standing.id ) {  
                            standing.temp_score = standing.temp_score + fight.comp1_points;
                        }
                        
                        if(fight.comp2 != null && fight.comp2.id == standing.id ) {    
                            standing.temp_score = standing.temp_score + fight.comp2_points;
                        }
                    });
                });
                
                const compSort = function(a, b) {             
                    return b.temp_score - a.temp_score;
                }
                
                let sorted_comps = this.current_division.standings.filter(
                        function (stand) {
                            return stand.checked_in == true;
                        }
                ).sort(compSort);
                
                let score = -1;
                let rank = 0;
                
                for (let step = 0; step < sorted_comps.length; step++) {
                    if (score != sorted_comps[step].temp_score) {
                        rank = rank + 1;
                    }
                    sorted_comps[step].place = rank;
                    score = sorted_comps[step].temp_score;
                }
            }
        } else {
            this.current_division.fights.forEach(fight => {
                if(fight.comp1 != null) {
                    var place1 = parseInt(fight.winner_place);
                    if(fight.winner == 2) {
                        place1 = place1 + 1;
                    }

                    var c1 = div.getComp(fight.comp1.id);

                    if(c1.place <= 0 || (c1.place > place1 && place1 > 0) ) {
                        c1.place = place1;
                    }            
                }
                
                if(fight.comp2 != null) {
                    var place2 = parseInt(fight.winner_place);
                    if(fight.winner == 1) {
                        place2 = place2 + 1;
                    }
                    var c2 = div.getComp(fight.comp2.id);

                    if(c2.place <= 0 || (c2.place > place2 && place2 > 0) ) {
                       c2.place = place2;
                    }

                }
            });
        
        }
        this.current_division.complete();
        this.setState({current_division: this.current_division});
    }
    
    finishSplit() { 
        var div = this.current_division;
        
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 );
        this.current_division.standings.forEach(standing => {
            standing.place = 0;
        });
        this.current_division.completeSplit();
        this.setState({current_division: this.current_division});
    }
    
    completeDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 )
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        this.current_division.complete();
        this.setState({current_division: this.current_division});
    }
    
    closeDivision() {
                
        if(this.current_division.getNotCheckedIn().length > 0 && !window.confirm("This division has competitors - are you sure you want to close it?") ) { 
            return;            
        }
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 3 );        
        this.current_division.complete();
        this.swapToDivision(null);
        this.setState({current_division: this.current_division});
    }
    
    cancelDivision() {
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/status/' + 0 )
        this.current_division.cancel();
        this.setState({current_division: this.current_division});
    }
    
    checkCompetitor(comp, value) {
        if(comp.members.length == 0) {
            comp.check(value);        
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/checkin/' + value )
        } else {
            comp.members.forEach( (st, index, self2) => {
                this.checkCompetitor(st, value);
            });
        }                                                                                                                            
        this.setState({current_division: this.division});
    }
    
    checkinFilterUpdate(event) {
        this.setState({checkin_filter: event.target.value.toLowerCase() });
        this.checkin_filter =  event.target.value.toLowerCase();
    }
    
    sendStandingReminder(comp, e) {
        var btn = e.target;
        btn.disabled = true;
        var after = function(req) {
            req.then(response => response.json()).then(data => alert(data.status.message)).then(() => { btn.disabled=false; });
        }
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/reminder', after );
    }
    
    competitorSeeding(comp, value) {
        comp.setSeeding(value);
        postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/seeding/' + value )
        var ring = this.ring;
        comp.members.forEach( (st, index, self2) => {
           if (st.id == comp.id) return; 
           st.setSeeding(value);
           postMaster.toSend('/tournament-ring/'+ ring.id + '/standing/' + st.id + '/seeding/' + value )
        }) 
                                            
        this.setState({current_division: this.division});
    }
    
    competitorModalOpen(comp) {
        comp.changeModal(true);
        this.setState({current_division: this.division});
    }

    competitorModalClose(comp) {
        comp.changeModal(false);
        this.setState({current_division: this.division});
    }
    
    setCurrentComp(comp) {    
        if(this.current_division.current_comp && this.current_division.current_comp.stopWatch.current > 0 && this.current_division.current_comp.stopWatch.running) {
            this.current_division.current_comp.stopWatch.stop();
        }
        this.current_division.setCurrentComp(comp)
        let div = this.current_division;
        if(this.current_division.status == 2 && comp != null && comp.scores.length > 0) {
            let i = 0;
            this.focused_score = null;
            while (i < comp.scores.length) {
                if( parseFloat(comp.scores[i].og_score) == 0.0 
                        || 
                        parseFloat(comp.scores[i].og_score) == parseFloat(div.score_prefill)
                        ||                         
                        (div.score_rules != 'int' && comp.scores[i].og_score.toString().length < 4 )         
                        ||
                        parseFloat(comp.scores[i].og_score) > div.max_score
                        ||
                        ( parseFloat(comp.scores[i].og_score) < div.min_score   && parseFloat(comp.scores[i].og_score) > 0 && parseFloat(comp.scores[i].og_score) != parseFloat(div.score_dq) ) 
                        ) {
                    this.focused_score = comp.scores[i];
                    break;
                }                
                i++;                
            }
        }
            
        if(comp) {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/' + comp.id  )
        } else {
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/clear' )
        }
        this.setState({current_division: this.division});
    }
    
    
    setScore(comp, judge,value) {
        let f = parseFloat(value);
        
        
        if (this.current_division.score_rules != 'int' &&  f % 10 == 0 && f > 10.0 && f < 100) {
            f = f / 10.0;
            f = f + '.0';
        } else {
            if (this.current_division.score_rules != 'int' &&  f > 10.0) {
                f = f / 10.0;
            } 

            if (this.current_division.score_rules != 'int') {
                f =  Math.round(f * 100.0) / 100.0;
            }
        }
        if(isNaN(f) ) {
            f = 0;
        }        
        
        var og = value.toString();
        var n = f.toString();
                
        if (this.current_division.score_rules != 'int') {
            while(og.replace(".", "").length > n.replace(".", "").length && og.length > 2  && n.length < 4 ) {
                n = n + '0';
            }
            if(parseFloat(n) > 10) {
                n = '10';
            }
        }
        comp.setScore(judge, n);
        postMaster.immediateGet('/tournament-ring/'+ this.ring.id + '/division/' + this.current_division.id + '/comp/' + comp.id + '/score/' + judge + '/' + n );        
        this.forceUpdate();
    }
    
    setCompStatus(comp, status) {
        comp.status = status;
        var t = this;
        postMaster.toSendData('/tournament/' + this.ring.tournament_id + '/standings/update-status?ajax=1', {
            standing_id:comp.id,
            status:status
        }, false );
        comp.members.forEach((x) => {
            x.status = status;
            postMaster.toSendData('/tournament/' + t.ring.tournament_id + '/standings/update-status?ajax=1', {
                standing_id:x.id,
                status:status
            }, false );
        });
        
        var score_dq = 0;
                
        if(!isNaN(this.current_division.score_dq) ) {
            score_dq = this.current_division.score_dq;
        }
        
        if(status == 4) {
            var scores = [];        
            comp.scores.forEach(s => {
                s.score = score_dq;
                s.og_score = score_dq;
                s.judge_id = window.APP.ring.getJudge(parseInt(s.judge) + 1).id;
                scores.push({ id: comp.id,score: s.score,og_score: s.og_score,drop: s.drop, num:s.judge, judge_id:s.judge_id });
            });        
            postMaster.toSendData('/tournament-ring/' +window.APP.ring.id + '/division/' +this.current_division.id + '/fixed-scores',scores )
        }
        this.setState({current_division: this.current_division } );
    }
    
    setAddTime(v) {
        this.addTime = v;
        this.subTime = false;
        this.setState({subTime: this.subTime, addTime: this.addTime } );
    }
    
    setSubTime(v) {
        this.subTime = v;
        this.addTime = false;
        this.setState({subTime: this.subTime, addTime: this.addTime } );   
    }
    
    
    
    render() { 
        const rendered = this;
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        
        if(this.current_division ) {
            window.APP.header_second = this.current_division.name;
        } else {
            window.APP.header_second = '';
        }
        
        if(this.ring.current_fight) { 
            this.context.window_manager.setDef(
                    this.ring.current_fight.getDisplay
                   );
           
            var timercolor = 'white';
            
            if(this.ring.current_fight.timer.current < 11 && this.ring.current_fight.timer.current % 2 == 0) {
                timercolor = 'yellow';
            };
            
            if(this.ring.current_fight.timer.current == 0) {
                timercolor = 'red';
            };
            
            const historyList = this.ring.current_fight.history.slice().reverse().map( call => {
                    var on = '';
                    var other = '';
                    
                    if(call.winner == 1) {
                        return <tr>
                        <td>{this.ring.current_fight.comp1.name} has won!</td>
                        </tr>
                    }
                    
                    if(call.winner == 2) {
                        return <tr>
                        <td>{this.ring.current_fight.comp2.name} has won!</td>
                        </tr>
                    }
                    
                    if(call.winner == -1) {
                        return <tr>
                        <td>Winner Cleared</td>
                        </tr>
                    }
                    
                    if(call.end_round != 0) {
                       return <tr>
                        <td>End of round ({ call.end_round })</td>
                        </tr>
                    }
                    
                    if(call.time_change != 0) {
                       return <tr>
                        <td>Manual Time Change ({ call.time_change })</td>
                        </tr>
                    }
                    
                    if(call.person && this.ring.current_fight.comp1 && call.person.id == this.ring.current_fight.comp1.id) {
                        if(this.ring.current_fight.swapped == 1) {
                            on = 'blue';
                            other = 'red';
                        } else {
                            on = 'red';
                            other = 'blue';
                        }
                    }
                    
                    if(call.person && this.ring.current_fight.comp2 && call.person.id == this.ring.current_fight.comp2.id) {
                        if(this.ring.current_fight.swapped == 1) {                            
                            on = 'red';
                            other = 'blue';
                        } else {
                            on = 'blue';
                            other = 'red';
                        }
                    }
                    
                    return <tr>
                    <td>{call.points} to {on}{call.opposing_points != 0 && <span>, {call.opposing_points} to {other}</span>} {call.call && <span>for {call.call.name} on {on}</span> }</td>
                    </tr>
                    }
                    )
            
            const postitiveList = this.ring.getPAVAcalls().map(
                    (c) => <Button onClick={()=> this.ring.current_fight.addAvCall(c, this.ring.current_fight.getLeftComp()) } style={{ margin:'4px' }} variant={c.type == -1 ? 'danger' : 'success'}>{c.points} Point{ (c.points > 1)?'s':''  } | {c.name}</Button>
                    );
            const postitiveList2 = this.ring.getPAVAcalls().map(
                    (c) => <Button onClick={()=> this.ring.current_fight.addAvCall(c, this.ring.current_fight.getRightComp()) } style={{ margin:'4px' }} variant={c.type == -1 ? 'danger' : 'success'}>{c.points} Point{ (c.points > 1)?'s':''  } | {c.name}</Button>
                    );
            
            var winner = this.ring.current_fight.getWinner(); 
            
            if(this.ring.current_division && winner != null) {
                this.ring.current_division.fights.forEach(fight => {
                    fight.is_editable = true;
                    fight.is_viewable = true;
                });

                this.ring.current_division.fights.forEach(fight => {
                    fight.check();
                });
            }
            
            return <Container>
             
                <Row style={{ marginTop:'0px' }}>
                    <Col xs={2} style={{ paddingRight:'5px' }}>
                    <Card bg='dark'
                        text= 'light'
                      >
                        <Card.Body onClick={ () => {this.ring.current_fight.timer.stop(); this.ring.setCurrentFight(null)} } >
                         Close
                        </Card.Body>
                  </Card>
                  </Col>
                    {this.ring.current_division && <Col xs={8}>
                        <Card bg="dark" style={{ color:'white' }}>
                            <Card.Body style={{ textAlign:'center' }}>
                            {this.ring.current_division.name} 
                            {this.ring.current_fight.rounds > 1 && (this.ring.current_fight.on_break == null || this.ring.current_fight.on_break < this.ring.current_fight.current_round) && <span> - Round {this.ring.current_fight.current_round} / {this.ring.current_fight.rounds}</span> }
                            {this.ring.current_fight.rounds > 1 && (this.ring.current_fight.current_round < this.ring.current_fight.rounds && this.ring.current_fight.on_break == this.ring.current_fight.current_round) && <span> - Break After Round {this.ring.current_fight.current_round} / {this.ring.current_fight.rounds}</span> }
                        
                            </Card.Body>
                        </Card>
                    </Col>}
                    
                    {this.ring.ring_import && <Col xs={8}>
                        <Card bg="dark" style={{ color:'white' }}>
                            <Card.Body style={{ textAlign:'center' }}>
                            {this.ring.ring_import.name} 
                            {this.ring.current_fight.rounds > 1 && (this.ring.current_fight.on_break == null || this.ring.current_fight.on_break < this.ring.current_fight.current_round) && <span> - Round {this.ring.current_fight.current_round} / {this.ring.current_fight.rounds}</span> }
                            {this.ring.current_fight.rounds > 1 && (this.ring.current_fight.current_round < this.ring.current_fight.rounds && this.ring.current_fight.on_break == this.ring.current_fight.current_round) && <span> - Break After Round {this.ring.current_fight.current_round} / {this.ring.current_fight.rounds}</span> }
                        
                            </Card.Body>
                        </Card>
                    </Col>}
                    
                    {!this.ring.current_division && !this.ring.ring_import && <Col xs={8}>
                        <Card bg="dark" style={{ color:'white' }}>
                            <Card.Body style={{ textAlign:'center' }}>
                                Custom Fight
                             </Card.Body>
                        </Card>
                    </Col>}
                    
                    {!winner && (this.ring.current_division == null || this.ring.current_division.is_final == 0 ) && !(this.ring.current_fight.timer.current == 0 && this.ring.current_fight.rounds > this.ring.current_fight.current_round) && <Col xs={2} style={{ paddingLeft:'5px' }}>
                    <Card bg='dark'
                        text= 'light'
                      >
                        <Card.Body onClick={ () => { this.ring.current_fight.declaring = true; window.APP.reset(); } }>
                         Winner
                        </Card.Body>
                  </Card>
                  </Col>}
                  
                  {!winner && this.ring.current_fight.timer.current == 0 && this.ring.current_fight.rounds > this.ring.current_fight.current_round &&
                          <Col xs={2} style={{ paddingLeft:'5px' }}>
                    <Card bg='dark'
                        text= 'light'
                      >
                        <Card.Body onClick={ ()=>{  this.ring.current_fight.fightContinue(); window.APP.reset(); } }>
                         Continue
                        </Card.Body>
                  </Card>
                  </Col>
                    }
                  
                  {winner &&
                          !(this.ring.current_fight.double_1 && winner.id == this.ring.current_fight.comp2.id) &&
                          !(this.ring.current_fight.double_2 && winner.id == this.ring.current_fight.comp1.id) &&
                  <Col xs={2} style={{ paddingLeft:'5px' }}>
                    <Card bg='dark'
                        text= 'light'
                      >
                        <Card.Body onClick={ () => {this.ring.current_fight.timer.stop(); this.ring.setCurrentFight(null)} } >
                         Close
                        </Card.Body>
                  </Card>
                  </Col>
                  }
                </Row>
            
            {this.ring.current_fight.notEnoughSpread() && <Alert variant="danger" style={{ textAlign:'center' }}>
                    <Alert.Heading>Fight Spread</Alert.Heading>
                    <p>You have declared a winner, but this fight has not met the needed spread of {this.ring.current_fight.min_fight_spread}.  This may be correct but should be confirmed before moving on.</p>
                </Alert>}
            
            {winner && 
                <Alert variant="success" style={{ textAlign:'center' }}>
                    <Alert.Heading>{winner.name}</Alert.Heading>
                    <p>{winner.name} has won this fight {this.ring.current_fight.is_editable == true && (this.ring.current_division == null || this.ring.current_division.is_final == 0 ) && <Button onClick={ () => {this.ring.current_fight.pushWinner(-1)  }} variant="danger">Clear Winner</Button>} </p>
                </Alert>
            }
            
            {winner && this.ring.current_fight.double_1 && winner.id == this.ring.current_fight.comp2.id &&
                <Alert variant="danger" style={{ textAlign:'center' }}>
                    <Alert.Heading>{winner.name}</Alert.Heading>
                    <p>This is a double elimination and the loser has only lost once.  You should reset this fight {this.ring.current_fight.is_editable == true && (this.ring.current_division == null || this.ring.current_division.is_final == 0 ) && <Button onClick={ () => { this.ring.current_fight.pushWinner(-1); this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), this.ring.current_fight.getLeftPoints() * -1); this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), this.ring.current_fight.getRightPoints() * -1); this.ring.current_fight.timer.reset(); this.ring.current_fight.double_1 = false; this.ring.current_fight.current_round =  1; this.ring.current_fight.on_break = 0; }} variant="danger">Reset Fight</Button>} </p>
                </Alert>
            }
            
            {winner && this.ring.current_fight.double_2 && winner.id == this.ring.current_fight.comp1.id &&
                <Alert variant="danger" style={{ textAlign:'center' }}>
                    <Alert.Heading>{winner.name}</Alert.Heading>
                    <p>This is a double elimination and the loser has only lost once.  You should reset this fight {this.ring.current_fight.is_editable == true && (this.ring.current_division == null || this.ring.current_division.is_final == 0 ) && <Button onClick={ () => { this.ring.current_fight.pushWinner(-1); this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), this.ring.current_fight.getLeftPoints() * -1); this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), this.ring.current_fight.getRightPoints() * -1); this.ring.current_fight.timer.reset(); this.ring.current_fight.double_2 = false; this.ring.current_fight.current_round = 1; this.ring.current_fight.on_break = 0; }} variant="danger">Reset Fight</Button>} </p>
                </Alert>
            }
            
            <Modal size="lg" show={this.ring.current_fight.declaring} onHide={() => { this.ring.current_fight.declaring = false; window.APP.reset(); } }>
                <Modal.Header closeButton>
                  <Modal.Title>Declare Winner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>
                        <Col xs={6}>
                        <Card border={ (winner && this.ring.current_fight.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                            <Card.Header className="sparring-red">{winner && this.ring.current_fight.getLeftComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.ring.current_fight.getLeftComp() && this.ring.current_fight.getLeftComp().name  } &nbsp; 
                            {this.ring.current_fight.getLeftComp().country != '' && 
                                                <OverlayTrigger placement={'top'}
                                  overlay={
                                    <Tooltip>
                                      {this.ring.current_fight.getLeftComp().country_name}
                                    </Tooltip>
                                  }
                                  ><img style={{ 'width':'50px' }} src={this.ring.current_fight.getLeftComp().country}   ></img></OverlayTrigger> }
                            </Card.Header>
                            <Card.Body style={{ textAlign:'center' }} >
                                <Card>
                                    <Card.Body style={{ padding:'0',fontSize:'3em',color:'black',lineHeight:'1',paddingBottom:'5px'}}>{this.ring.current_fight.getLeftPoints() }</Card.Body>
                                </Card>
                                {!winner && <Button variant="danger" style={{ marginBottom:'10px', marginTop:'15px' }} onClick={() => { this.ring.current_fight.pushWinner(1);this.ring.current_fight.declaring = false; this.ring.current_fight.timer.stop(); window.APP.reset(); } }>WIN</Button>}
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col xs={6}>
                        <Card border={ (winner && this.ring.current_fight.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                                <Card.Header className={"sparring-" + window.APP.sparring_color}  >{winner && this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().name  } &nbsp; {this.ring.current_fight.getRightComp() != null && this.ring.current_fight.getRightComp().country != '' && 
                                            <OverlayTrigger placement={'top'}
                                        overlay={
                                          <Tooltip>
                                            {this.ring.current_fight.getRightComp().country_name}
                                          </Tooltip>
                                        }
                                        ><img style={{ 'width':'50px' }} src={this.ring.current_fight.getRightComp().country}   ></img></OverlayTrigger> }</Card.Header>

                                <Card.Body style={{ textAlign:'center' }} >
                                    <Card>
                                        <Card.Body style={{ padding:'0',fontSize:'3em',color:'black',lineHeight:'1',paddingBottom:'4%'}}>{this.ring.current_fight.getRightPoints() }</Card.Body>
                                    </Card>
                                    {!winner && <Button style={{ marginBottom:'10px', marginTop:'15px' }} variant="primary" onClick={() => { this.ring.current_fight.pushWinner(2); this.ring.current_fight.declaring = false; this.ring.current_fight.timer.stop(); window.APP.reset(); } }>WIN</Button>}
                                </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </Container>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={this.ring.current_fight.edit_timer} onHide={() => { this.ring.current_fight.edit_timer = false; window.APP.reset(); } }>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Timer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ width:'100%;', maxWidth:'100%' }}>
                        <Row>
                            <Col xs={12} style={{ textAlign:'center',fontSize:'3.5em',lineHeight:'1' }}>
                            {this.ring.current_fight.timer.getDisplay() }
                            </Col>
                            <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                                <ButtonGroup className="mr-2" aria-label="First group" >
                                    <Button variant="warning" onClick={ ()=>this.ring.current_fight.changeTime(-10) }>-10</Button>
                                    <Button variant="warning" onClick={ ()=>this.ring.current_fight.changeTime(-5) }>-5</Button>
                                    <Button variant="warning" onClick={ ()=>this.ring.current_fight.changeTime(-1) }>-1</Button>
                                    <Button variant="success" onClick={ ()=>this.ring.current_fight.changeTime(1) }>+1</Button>
                                    <Button variant="success" onClick={ ()=>this.ring.current_fight.changeTime(5) }>+5</Button>
                                    <Button variant="success" onClick={ ()=>this.ring.current_fight.changeTime(10) }>+10</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        
                        <Row>
                        <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}></Col>
                            <Col xs={6} style={{ textAlign:'center',marginTop:'10px' }}>
                                <InputGroup className="mb-3">
                                    
                                        <Button onClick={ ()=>this.ring.current_fight.changeTime(this.edit_fight_value * -1) } variant="warning">Subtract</Button>
                                    
                                    <FormControl value={this.edit_fight_value} onChange={(e)=>{ this.edit_fight_value = parseInt(e.target.value); window.APP.reset(); } } aria-describedby="basic-addon1" type="number" />
                                    
                                        <Button onClick={ ()=>this.ring.current_fight.changeTime(this.edit_fight_value) } variant="success">Add</Button>
                                    
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Row style={{ marginTop:'5px' }}>
            <Col style={{ padding:'0px' }} ><Card bg="dark"><Card.Body style={{ textAlign:'center',padding:'0px' }} >
                <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>
                <Col xs={4}>
                <Card border={ (winner && this.ring.current_fight.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                    <Card.Header className="sparring-red">{winner && this.ring.current_fight.getLeftComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.ring.current_fight.getLeftComp() && this.ring.current_fight.getLeftComp().name  } &nbsp; 
                    {this.ring.current_fight.getLeftComp().country != '' && 
                                        <OverlayTrigger placement={'top'}
                          overlay={
                            <Tooltip>
                              {this.ring.current_fight.getLeftComp().country_name}
                            </Tooltip>
                          }
                          ><img style={{ 'width':'50px' }} src={this.ring.current_fight.getLeftComp().country}   ></img></OverlayTrigger> }
                    </Card.Header>
                    <Card.Body style={{ textAlign:'center', backgroundColor:'#610008' }} ><Card.Body style={{ textAlign:'center' }} >{this.ring.current_fight.getLeftComp() && this.ring.current_fight.getLeftComp().team}</Card.Body>
                    </Card.Body>
                </Card>
                </Col>
                
                <Col xs={4}>
                <Card bg="dark" style={{ color:timercolor,height:'100%',borderColor:this.ring.current_fight.isTimeToEnd() ?'#f5ff33':'rgb(15, 15, 15)',borderWidth:'10px',borderStyle: 'solid' }} >
                    <Card.Body style={{ textAlign:'center',padding:'0px',backgroundColor: '#0f0f0f' }} >
                        <div>
                        <span style={{ fontSize:'3.5em',lineHeight:'1', }}>
                            {this.ring.current_fight.timer.getDisplay() }</span><br/>
                            
                        {this.ring.current_fight.timer.master && 
                        <ButtonGroup className="mr-2" aria-label="First group">
                            { !this.ring.current_fight.timer.running &&  <Button varient='primary' onClick={ ()=>{ this.ring.current_fight.timer.force_master = true; this.ring.current_fight.timer.start() } } ><i class="fas fa-play" ></i></Button>}
                            { this.ring.current_fight.timer.running && <Button variant="warning" onClick={ ()=>{this.ring.current_fight.timer.force_master = true; this.ring.current_fight.timer.stop() } }><i class="fas fa-pause" ></i></Button>}
                            { !this.ring.current_fight.timer.running && <Button variant="danger" onClick={ ()=>{this.ring.current_fight.timer.force_master = true; this.ring.current_fight.timer.reset() } }><i class="fas fa-sync" ></i></Button>}
                            </ButtonGroup>}
                            {this.ring.current_fight.timer.master && 
                            <ButtonGroup className="mr-2" aria-label="First group">
                                <Button onClick={() => { this.ring.current_fight.edit_timer = true; this.ring.current_fight.timer.force_master = true; window.APP.reset();} } variant="primary">Edit</Button>
                            </ButtonGroup>}
                        </div>
                        
                        </Card.Body>
                </Card>
                
                </Col>
                
                <Col xs={4}>
                <Card border={ (winner && this.ring.current_fight.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                        <Card.Header className={"sparring-" + window.APP.sparring_color}  >{winner && this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().name  } &nbsp; {this.ring.current_fight.getRightComp() != null && this.ring.current_fight.getRightComp().country != '' && 
                                    <OverlayTrigger placement={'top'}
                                overlay={
                                  <Tooltip>
                                    {this.ring.current_fight.getRightComp().country_name}
                                  </Tooltip>
                                }
                                ><img style={{ 'width':'50px' }} src={this.ring.current_fight.getRightComp().country}   ></img></OverlayTrigger> }</Card.Header>
                          
                        <Card.Body style={{ textAlign:'center', backgroundColor:('#' + ( (window.APP.sparring_color == 'blue') ? '011c47' : 'afb4bd') ) }} >{this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().team}</Card.Body>
                </Card>
                </Col>
                
                <Col xs={6}>
                <Card border={ (winner && this.ring.current_fight.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                    <Card.Body style={{ textAlign:'center' }} >
                        <Container>
                        <Row style={{ paddingBottom:'20px' }}>
                        <Col xs={5}>{this.ring.current_fight.getLeftComp() &&  this.ring.current_fight.getLeftComp().photoL != '' &&  <img style={{ width:  '98%',borderRadius:'5px'}} src={this.ring.current_fight.getLeftComp().photoL} /> }
                        </Col>
                        <Col xs={4}>
                            { this.ring.current_fight.getLeftComp() && this.ring.current_fight.getLeftComp().weight && this.ring.current_fight.getLeftComp().weight != '' &&  <span>{this.ring.current_fight.getLeftComp().getWeightString()}</span> }<br/>
                            { this.ring.current_fight.getLeftComp() && this.ring.current_fight.getLeftComp().age && this.ring.current_fight.getLeftComp().age != '' &&  <span>{this.ring.current_fight.getLeftComp().age} years</span> }<br/>
                            { this.ring.current_fight.getLeftComp() &&  this.ring.current_fight.getLeftComp().height }<br/>
                            { this.ring.current_fight.getLeftCPoints()>0 &&  <span>Cumulative: {this.ring.current_fight.getLeftCPoints()}</span> }
                        </Col>
                        <Col xs={3}>
                        <Card border={this.ring.current_fight.getLeftBorderColor() }>
                            {!winner &&<Card.Header onClick={ ()=>this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), 1) } className="scores-control" style={{ padding:'0',fontSize:'1.5em' }}><div style={{ width:'100%' }}><i class="fas fa-arrow-circle-up"  ></i></div></Card.Header>}
                            <Card.Body style={{ padding:'0',fontSize:'3em',color:this.ring.current_fight.getLeftColor(),lineHeight:'1',paddingBottom:'4%'}}>{this.ring.current_fight.getLeftPoints() }</Card.Body>
                            {!winner && <Card.Header onClick={ ()=>this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), -1) } className="scores-control" style={{ padding:'0',fontSize:'1.5em'}}><i class="fas fa-arrow-circle-down"  ></i></Card.Header>}
                        </Card>
                        </Col>
                        </Row><Row>
                        <Col xs={12}>
                            {!winner && postitiveList}
                            {!winner && <Button style={{ margin:'4px',display:'none' }} variant="warning">Penalty</Button>}
                        </Col>
                        {this.ring.current_fight.rounds > 2 && <Col xs={12}>
                           <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-danger" onClick={ ()=>{ this.ring.current_fight.adjLeftRPoints(-1) } }><i class="fas fa-caret-down"></i></button>
                                <button type="button" class="btn btn-primary">{this.ring.current_fight.getLeftRPoints() }</button>
                                <button type="button" class="btn btn-success" onClick={ ()=>{ this.ring.current_fight.adjLeftRPoints(1) } }><i class="fas fa-caret-up"></i></button>
                            </div>
                        </Col>}
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
                </Col>
                
                
                <Col xs={6}>
                <Card border={ (winner && this.ring.current_fight.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                    <Card.Body style={{ textAlign:'center' }} >
                        <Container>
                        <Row style={{ paddingBottom:'10px' }}>
                        <Col xs={3}>
                            <Card  border={this.ring.current_fight.getRightBorderColor() }>
                                {!winner &&<Card.Header onClick={ ()=>this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), 1) } className="scores-control" style={{ padding:'0',fontSize:'1.5em'}}><div style={{ width:'100%' }}><i class="fas fa-arrow-circle-up"  ></i></div></Card.Header>}
                                <Card.Body style={{ padding:'0',fontSize:'3em',color:this.ring.current_fight.getRightColor(),lineHeight:'1',paddingBottom:'4%'}}>{this.ring.current_fight.getRightPoints() }</Card.Body>
                                {!winner && <Card.Header  onClick={ ()=>this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), -1) } className="scores-control" style={{ padding:'0',fontSize:'1.5em'}}><i class="fas fa-arrow-circle-down" ></i></Card.Header>}
                            </Card>
                        </Col>
                        <Col xs={4}>
                            { this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().weight != '' && this.ring.current_fight.getRightComp().weight &&  <span>{this.ring.current_fight.getRightComp().getWeightString()}</span> }<br/>
                            { this.ring.current_fight.getRightComp() && this.ring.current_fight.getRightComp().age != '' && this.ring.current_fight.getRightComp().age &&  <span>{this.ring.current_fight.getRightComp().age} years</span> }<br/>
                            { this.ring.current_fight.getRightComp() &&  this.ring.current_fight.getRightComp().height }<br />
                            { this.ring.current_fight.getRightCPoints()>0 &&  <span>Cumulative: {this.ring.current_fight.getRightCPoints()}</span> }
                        </Col>
                        <Col xs={5}>{this.ring.current_fight.getRightComp() &&  this.ring.current_fight.getRightComp().photoL != '' &&  <img style={{ width:  '98%',borderRadius:'5px'}} src={this.ring.current_fight.getRightComp().photoL} /> }
                        </Col>
                        </Row><Row>
                        <Col xs={12}>
                            {!winner && postitiveList2}
                             {!winner && <Button style={{ margin:'4px',display:'none' }} variant="warning">Penalty</Button>}

                        </Col>
                        </Row>
                        <Row>
                        {this.ring.current_fight.rounds > 2 && <Col xs={12}>
                           <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-danger" onClick={ ()=>{ this.ring.current_fight.adjRightRPoints(-1) } }><i class="fas fa-caret-down"></i></button>
                                <button type="button" class="btn btn-primary">{this.ring.current_fight.getRightRPoints() }</button>
                                <button type="button" class="btn btn-success" onClick={ ()=>{ this.ring.current_fight.adjRightRPoints(1) } }><i class="fas fa-caret-up"></i></button>
                            </div>
                        </Col>}
                        </Row>
                        </Container>
                    </Card.Body>
                </Card>
                </Col>
                
                </Row></Container>    
                                                                                                                                                                                                                                
            </Card.Body></Card></Col>                                                                                                                                                                                                            
            </Row>  
            
            
            <Row style={{ marginTop:'5px' }}>
            <Col style={{ padding:'0px' }} ><Card bg="dark"><Card.Body style={{ textAlign:'center' }} >
                <Table variant='dark'>
                <thead><tr><th>History</th></tr></thead>
                {historyList}
                </Table>                                                                                                                                                                                          
            </Card.Body></Card></Col>                                                                                                                                                                                                            
            </Row>
            
                 </Container>;
        }
        
        if(this.ring.ring_import != null && this.ring.ring_import.status == 1) {
            this.context.window_manager.setDef(
                        this.ring.ring_import.getCompOrderDisplay()
                       );

                return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                        <Card bg='dark'
                        style={{ margin:'0.5%', width:'99%'  }}
                            text= 'light'
                          >
                            <Card.Body onClick={this.clearImportConfirm} >
                             Exit Division
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={8}>
                        <Card bg='dark'
                        style={{ margin:'0.5%', width:'99%'  }}
                            text= 'light'
                          >
                            <Card.Body>
                             <OverlayTrigger
                                key={'top'}
                                placement={'top'}
                                overlay={
                                  <Tooltip>
                                  <span style={{ fontSize:'1.5em' }}>{this.ring.ring_import.code}</span>
                                  </Tooltip>
                                }
                              >
                                <div>{this.ring.ring_import.name}</div>
                              </OverlayTrigger>
                            </Card.Body>
                      </Card>
                    </Col>                  
                    <Col xs={2}>
                    </Col>
                </Row>

                <Row  style={{ textAlign:'center' }}>
                <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {this.ring.ring_import.getCheckedIn().length} Competitors
                        </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%' }}
                        text= 'light'
                      >
                        <Card.Body>
                        <span class={(this.ring.ring_import.fights.filter(function(e) {return e.winner == 0; }).length > 0 ) ? 'text-danger':'text-success'}>{ this.ring.ring_import.fights.filter(function(e) {return e.winner == 0 && !e.ignore; }).length }</span> Incomplete Fights
                        </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%' }}
                        text= 'light'
                      >
                        <Card.Body>
                         <span class={(this.ring.getNumJudges()  < this.ring.ring_import.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()}</span> Judges
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>

                {this.ring.ring_import.scorekeeper_warning && <Row ><Col xs={12}>
                    <Alert variant="danger" style={{ textAlign:'center' }}>
                        <p>{this.ring.ring_import.scorekeeper_warning}</p>
                        </Alert></Col></Row>
                }
                <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                       <Card.Header
                       
                        >{this.ring.ring_import.config_type == 'sparring' && this.ring.ring_import.sparring_bracket_type != 'double' && <div style={{float:'left'}}>
                            <Form.Check 
                              type='checkbox' checked={ (this.ring.ring_import.show_fight_list) ?'checked' : '' } onChange={this.toggleFightList}
                            /> Show Fight List
                            </div>}Brackets {this.ring.ring_import.zoomed_fight && <div style={{float:'right'}}><Button variant="primary" onClick={()=>{ this.ring.ring_import.zoomed_fight = null; window.APP.reset(); }}>
                            Clear Zoom
                            </Button></div>}</Card.Header>
                        <Card.Body >
                            <Container>
                                <Row>
                                    <Col xs="12">
                                        <BracketChart tv={0} div={this.ring.ring_import} drag_stopped={true} ></BracketChart>
                                    </Col>                                
                                </Row>                      
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
        }
    
        if(this.current_division) { 
            var div = this.current_division;
    
            div.setCheckNumbers();
    
            if(div.status == 0) {
                this.context.window_manager.setDef(
                    div.getUncheckedDisplay
                   );

               let filter_val = this.checkin_filter;
               
               const can_checkin = div.child_divisions.length > 0 ||  this.ring.lock_shuffles == 0 || div.shuffled == 0 || div.shuffled == '0';
               const can_checkout = div.child_divisions.length > 0 || !(this.ring.lock_shuffles == 1 && div.config_type == 'sparring' ) || div.shuffled == 0 || div.shuffled == '0';

               const standingsList = div.getCheckinMainOrder()
                       .filter(function(value, index, arr){ 
                                   return value.name.toLowerCase().indexOf(filter_val) >=0 || filter_val.localeCompare('') == 0;
               }).map((comp) => {

                return   <Col xs={4}>
                        <Card bg='secondary' border={comp.checked_in ? 'success':'danger'}
                        style={{ margin:'1%', width:'98%'  }}
                            text= 'light'
                          >
                            <Card.Header >{comp.checked_in && <i class="fas fa-check-circle text-success"></i>}{!comp.checked_in && <i class="fas fa-times-circle text-danger"></i>}&nbsp;
                            {comp.photo != '' && comp.members.length == 0 && comp.members.length == 0 &&  <img style={{ width: '40px',float:'left' }} src={comp.photo} /> }
                            {comp.check_num}. {comp.name}
                            </Card.Header>
                            <Card.Body>
                            <Container>
                                <Row>
                                    <Col xs='2'>
                                    {comp.seeding}
                                    </Col>
                                    <Col xs='10' >
                                        <ButtonGroup>
                                           <Button onClick={() => this.competitorModalOpen(comp, false)} variant="light">Info <i class="fas fa-info-circle"></i></Button>                                           
                                           {!comp.checked_in && (comp.members.length > 0 || comp.is_blocked != 'Yes') && can_checkin && <Button onClick={() => this.checkCompetitor(comp, true)} variant="primary">Check-In</Button>}
                                        </ButtonGroup>
                                        {!comp.reg_check && comp.blocked_reason != 'Not Checked In' && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not Checked In</Tooltip> }><i  style={{ marginLeft:'10px' }} class={'text-danger fas fa-vote-yea'} ></i></OverlayTrigger>}
                                        { comp.is_blocked == 'Yes' &&  comp.members.length == 0 && <OverlayTrigger placement={'top'} overlay={<Tooltip>{comp.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={comp.blocked_symbol} ></i></OverlayTrigger>  }
                                         
                                        { div.is_team == 1 && comp.getCheckedIn().length > div.max_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Too many people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-minus"} ></i></OverlayTrigger>  }
                                        { div.is_team == 1 && comp.getCheckedIn().length < div.min_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not enough people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-plus"} ></i></OverlayTrigger>  }
                                        { div.is_team == 1 && comp.checked_in && comp.getCheckedIn().length != comp.members.length && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not everyone is checked in</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-exclamation"} ></i></OverlayTrigger>  }
                                    </Col>
                                </Row>
                            </Container>
                            </Card.Body>
                      </Card>
                   </Col>;
               }
             );
     
     const standingsModal = div.getCheckinMainOrder()
                       .filter(function(value, index, arr){ 
                                   return value.name.toLowerCase().indexOf(filter_val) >=0 || filter_val.localeCompare('') == 0;
               }).map((comp) => {

               return <Modal size="lg" show={comp.modal} onHide={() => this.competitorModalClose(comp, false)}>
                           <Modal.Header closeButton>
                             <Modal.Title>{comp.name}</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                           <Table>
                           <tbody>
                           {comp.members.length == 0 && !comp.checkedin && <tr>
                               <td colspan="2">
                               <Button onClick={(e) => { this.sendStandingReminder(comp,e) }} variant="success">Send Reminder Message</Button>
                                 </td>
                                 </tr>}
                           {comp.inputs && comp.members.length == 0 &&
                            comp.inputs.map(
                                    (input) => 
                                        <tr><th>{input.label}</th><td>{input.value}</td></tr>
                                    )
                                           }
                           {comp.blocked_reason != '' && 
                                   comp.members.length == 0 && <tr class="table-danger" ><th colspan="2">{comp.blocked_reason}</th></tr>}       
                                   
                           <tr><th>Seed</th>
                               <td><Form.Control as="select" size="lg" value={comp.seeding}  onChange={(event) => this.competitorSeeding(comp,  event.target.value)}>
                                   <option>A</option>
                                   <option>B</option>
                                   <option>C</option>
                                   <option>D</option>
                                   <option>E</option>
                                 </Form.Control>
                                 </td>
                                 </tr>
                                {comp.members.length == 0 && <tr><th>Height</th><td>{comp.height}</td></tr>}
                                {comp.members.length == 0 && <tr><th>Weight</th><td>{comp.getWeightString()}</td></tr>}
                                {comp.members.length == 0 && <tr><th>Age</th><td>{comp.age}</td></tr>}
                                {comp.members.length == 0 && <tr><th>Gender</th><td>{comp.gender}</td></tr>}
                                {comp.members.length == 0 && <tr><th>Rank</th><td>{comp.rank}</td></tr>}
                                   </tbody>
                            {comp.members.length > 0 &&
                            <tbody>{
                                comp.members.map(
                                (input) => 
                        <tr><th>{input.checked_in && <i class="fas fa-check-circle text-success"></i>}{!input.checked_in && <i class="fas fa-times-circle text-danger"></i>}&nbsp; {input.name}</th>
                        <td>
                            <ButtonGroup>
                                {input.checked_in && input.is_blocked != 'Yes' && <Button onClick={() => this.checkCompetitor(input, false)} variant="warning">Check-Out</Button>}
                                {!input.checked_in  && input.is_blocked != 'Yes'  && <Button onClick={() => this.checkCompetitor(input, true)} variant="primary">Check-In</Button>}
                             </ButtonGroup>
                             
                            &nbsp;{input.is_blocked != 'Yes' && !input.checked_in &&  <Button onClick={(e) => { this.sendStandingReminder(input,e) }} variant="success">Send Reminder Message</Button>}
                             
                            {input.is_blocked == 'Yes'  && <OverlayTrigger placement={'top'} overlay={<Tooltip>{input.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={input.blocked_symbol} ></i></OverlayTrigger>}
                        </td>
                        </tr>
                                )
                            }                      
                            </tbody>
                            }
                            <tr>
                                <th>
                                Country
                                </th>
                            <td>
                                {comp.country_name}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                State
                                </th>
                            <td>
                                {comp.state_name}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                City
                                </th>
                            <td>
                                {comp.city}
                            </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign:'center' }} colspan='2'>
                                Schools
                                </th>
                            </tr>
                            {comp.schoolteams && comp.schoolteams.length > 0 &&
                            comp.schoolteams.map(
                                    (input) => 
                                        <tr><td colspan="2">{input}</td></tr>
                                    )
                                           }
                           </Table>
                           </Modal.Body>
                           <Modal.Footer>
                             <Button variant="secondary" onClick={() => this.competitorModalClose(comp, false)}>
                               Close
                             </Button>
                           </Modal.Footer>
                       </Modal>;
               }
             );
             
     const standingsListRow = div.getCheckinRowOrder(this.check_order)
                       .filter(function(value, index, arr){ 
                                   return value.name.toLowerCase().indexOf(filter_val) >=0 || filter_val.localeCompare('') == 0;
               }).map(function(comp)  {

               return  <tr>
               <td>{comp.checked_in && <i class="fas fa-check-circle text-success"></i>}{!comp.checked_in && <i class="fas fa-times-circle text-danger"></i>}</td>
                           <td style={{ width:'50px' }}>{comp.photo != '' &&  comp.members.length == 0 &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                           <td style={{ width:'50px', verticalAlign:'middle' }} >
            {comp.country != '' &&  comp.members.length == 0 && 
                    <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
       </td>
                           
                    <th style={{ textAlign:'left' }} >{comp.check_num}. {comp.name}</th>
                           <td><ButtonGroup>
                                           <Button onClick={() => rendered.competitorModalOpen(comp, false)} variant="light">Info <i class="fas fa-info-circle"></i></Button>
                                           {comp.checked_in &&  (comp.members.length > 0 || comp.is_blocked != 'Yes') && can_checkout && <Button onClick={() => rendered.checkCompetitor(comp, false)} variant="warning">Check-Out</Button>}
                                           {!comp.checked_in &&  (comp.members.length > 0 || comp.is_blocked != 'Yes') && can_checkin  && <Button onClick={() => rendered.checkCompetitor(comp, true)} variant="primary">Check-In</Button>}
                                        </ButtonGroup>
                                        {!comp.reg_check && comp.blocked_reason != 'Not Checked In' && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not Checked In at Registration</Tooltip> }><i  style={{ marginLeft:'10px' }} class={'text-danger fas fa-vote-yea'} ></i></OverlayTrigger>}
                                        { comp.is_blocked == 'Yes' &&  comp.members.length == 0 && <OverlayTrigger placement={'top'} overlay={<Tooltip>{comp.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={comp.blocked_symbol} ></i></OverlayTrigger>  }
                                        { div.is_team == 1 && comp.getCheckedIn().length > div.max_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Too many people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-minus"} ></i></OverlayTrigger>  }
                                        { div.is_team == 1 && comp.getCheckedIn().length < div.min_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not enough people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-plus"} ></i></OverlayTrigger>  }
                                        { div.is_team == 1 && comp.checked_in && comp.getCheckedIn().length != comp.members.length && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not everyone is checked in</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-exclamation"} ></i></OverlayTrigger>  }
                                    </td>
                                           <td>{comp.seeding}</td>
                           </tr>;
               }
             );

           return <Container >
            <Row style={{ textAlign:'center' }}>
                <Col xs={2}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                    <Card.Body onClick={() => { this.swapToDivision(null)} } >
                     Schedule
                    </Card.Body>
              </Card>
              </Col>
            <Col xs={( ( ( div.shuffled == 1 || div.shuffled == '1') && this.ring.lock_shuffles == 0 ) || (div.sparring_rr_if_three == 'Yes' && div.config_type == 'sparring' && div.getCheckedIn().length == 3 ) ) ? 6 : 8}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                    <Card.Body>
                      <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name}</div>
                          </OverlayTrigger>
                    </Card.Body>
              </Card>
              </Col>
              
              
              
              {div.child_divisions.length == 0 && this.ring.getNumJudges()  >= div.min_judges && div.getCheckedIn().length > 0 && ( this.ring.lock_shuffles == 0 || div.shuffled == 0 || div.shuffled == '0' || (div.sparring_rr_if_three == 'Yes' && div.config_type == 'sparring' && div.getCheckedIn().length == 3 ) ) && <Col xs={2}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }} 
                    text= 'light'
                  >
                    <Card.Body onClick={this.startShufDivision}>
                     Shuffle
                    </Card.Body>
              </Card>
              </Col>}
              
              {div.getCheckedIn().length == 0 && <Col xs={2}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }} 
                    text= 'light'
                  >
                    <Card.Body style={{ color:'green' }} onClick={this.closeDivision}>
                     Mark Completed
                    </Card.Body>
              </Card>
              </Col>}
              
              {(div.child_divisions.length > 0 || (this.ring.getNumJudges()  >= div.min_judges && div.getCheckedIn().length > 0 && (div.shuffled == 1 || div.shuffled == '1' ) ) ) && 
                <Col xs={2}>
                    <Card 
                        bg='dark'
                        style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                    >
                        <Card.Body onClick={()=>{this.startDivision() } }>
                            Continue
                        </Card.Body>
                    </Card>
                </Col>  }
            </Row>

            <Row  style={{ textAlign:'center' }}>
            <Col xs={4}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                    <Card.Body >
                    <span class={(div.standings.length > 18) ? 'text-danger':'text-success'}>{div.standings.length}</span> Competitors
                    </Card.Body>
              </Card>
              </Col>

              <Col xs={4}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                    <Card.Body>
                     <span class={(div.getNotCheckedIn().length  > 0) ? 'text-danger':'text-success'}>{div.getNotCheckedIn().length }</span> Waiting On
                    </Card.Body>
              </Card>
              </Col>

              <Col xs={4}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                    <Card.Body>
                     
                     <OverlayTrigger placement={'top'}
                        overlay={
                          <Tooltip>
                            Min needed {div.min_judges}
                          </Tooltip>
                        }
                        ><div><span class={(this.ring.getNumJudges()  < div.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()} {this.ring.getNumJudges()  < div.min_judges && <span>/ {div.min_judges}</span> }</span> Judges</div></OverlayTrigger>
                        
                    </Card.Body>
              </Card>
              </Col>
            </Row>
            
            {!(div.shuffled == 0 || div.shuffled == '0') && div.config_type == 'scores' && <Row ><Col xs={12}>
                <Alert variant="info" style={{ textAlign:'center' }}>
                    <p>This division is already shuffled, the numbers below do not represent the shuffle.  If you are here to check new people in without doing a shuffle, please move forward to the order page and use the settings pop-up to check them in going first.</p>
                    </Alert></Col></Row>
            }

            {div.scorekeeper_warning && <Row ><Col xs={12}>
                <Alert variant="danger" style={{ textAlign:'center' }}>
                    <p>{div.scorekeeper_warning}</p>
                    </Alert></Col></Row>
            }
            
            {div.child_divisions.length > 0 && <Row ><Col xs={12}>
                <Alert variant="warning" style={{ textAlign:'center' }}>
                    <p>This is a parent division, once checked in you can start the division where you can divide up the competitors into child divisions</p>
                    </Alert></Col></Row>
            }

            <Row  style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'
                style={{ margin:'0.5%', width:'99%'  }}
                    text= 'light'
                  >
                   <Card.Header ><div style={{float:'left'}}>
                   <Form.Check 
        type='checkbox' checked={ (this.card_view) ?'checked' : '' } onChange={this.toggleCardView}
        />Card View</div>
                                                                           Check In
                   <div style={{ float:'right'}}>
                      <Form.Control type="text" placeholder="Search" onChange={this.checkinFilterUpdate}  />
                       </div>
                   </Card.Header>
                    <Card.Body >
                    
                    {div.status == 0 &&  div.shuffled == 0 && div.hasDuplicateChecked() && <Button style={{ margin:'5px' }} 
                        onClick={()=>{ div.unCheckDups(); }}    
                        variant="danger">Uncheck Duplicate Competitors</Button>}
                    
                        <Container>
                            <Row  style={{ textAlign:'center' }}>
                               {this.card_view && standingsList}
                               {!this.card_view && <Table variant="dark" ><tr><th colspan="3" onClick={()=>this.setCheckinOrder('name')}>{this.check_order != 'name' && <i class="fas fa-sort"></i>} Name</th><th onClick={()=>this.setCheckinOrder('checkin')}>{this.check_order != 'checkin' && <i class="fas fa-sort"></i>} Status</th><th onClick={()=>this.setCheckinOrder('seed')}>{this.check_order != 'seed' && <i class="fas fa-sort"></i>} Seed</th></tr> {standingsListRow}</Table> }
                               {standingsModal}
                            </Row>                      
                        </Container>
                    </Card.Body>
              </Card>
              </Col>
            </Row>
            </Container>;
        }
        
        //COMPETING
        if (div.status == 1) {
        
        div.fights.forEach(fight => {
            fight.is_editable = true;
            fight.is_viewable = true;
        });
        
        div.fights.forEach(fight => {
            fight.check();
        });
        
            if(! div.current_comp || div.config_type == 'sparring') {
                this.context.window_manager.setDef(
                        div.getCompOrderDisplay()
                       );

                const standingsList = div.getCompOrder()
                        .map((comp) => {

                   return   <tr>
                    <td>{comp.ordered}</td>
                   
                    <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                         <td style={{ width:'50px', verticalAlign:'middle' }}>
            {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
       </td>
                    <td style={{ textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td>
                    <td>{comp.seeding}</td>
                       </tr>;
                   }
                 );



                return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    {div.is_final == 0 && <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.cancelDivision()}  >
                            Cancel
                        </Card.Body>
                  </Card>}
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name}</div>
                          </OverlayTrigger>
                        </Card.Body>
                  </Card>
                  </Col>
                  {div.child_divisions.length == 0 && div.config_type == 'scores' &&
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%' }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>{ if( div.getCompOrder()[0] != null ){ this.setCurrentComp(div.getCompOrder()[0]) } else { this.startScoringDivision() }  } } >
                         Start
                        </Card.Body>
                  </Card>
                  </Col>}
                  {div.child_divisions.length == 0 && div.config_type != 'scores' && div.fights.filter(function(e) {return e.winner == 0 && !e.ignore; }).length == 0 &&
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>{ this.finishSparring();  } } >
                         Finish
                        </Card.Body>
                  </Card>
                  </Col>}
                  
                  {div.child_divisions.length > 0 &&
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>{ this.finishSplit();  } } >
                         Finish
                        </Card.Body>
                  </Card>
                  </Col>}
                  
                  
                </Row>

                <Row  style={{ textAlign:'center' }}>
                <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {div.getCheckedIn().length} Competitors
                        </Card.Body>
                    </Card>
                  </Col>
                  {div.child_divisions.length >0 &&
                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        Please Split
                        </Card.Body>
                    </Card>
                  </Col>}
                  {div.child_divisions.length == 0 && div.config_type == 'scores' &&
                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        0 Competed
                        </Card.Body>
                    </Card>
                  </Col>}
                  {div.child_divisions.length == 0 && div.config_type == 'sparring' && 
                   <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%' }}
                        text= 'light'
                      >
                        <Card.Body>
                        <span class={(div.fights.filter(function(e) {return e.winner == 0; }).length > 0 ) ? 'text-danger':'text-success'}>{ div.fights.filter(function(e) {return e.winner == 0 && !e.ignore; }).length }</span> Incomplete Fights
                        </Card.Body>
                    </Card>
                  </Col>}

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <span class={(this.ring.getNumJudges()  < div.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()}</span> Judges
                        </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {div.scorekeeper_warning && <Row ><Col xs={12}>
                    <Alert variant="danger" style={{ textAlign:'center' }}>
                        <p>{div.scorekeeper_warning}</p>
                        </Alert></Col></Row>
                }
                    {div.shuffle_text != '' && <Alert variant={'primary'} key={div.shuffle_text}>
                                            {div.shuffle_text}
                                        </Alert>}
                <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                       <Card.Header >{div.config_type == 'sparring' && div.sparring_bracket_type != 'double' && <div style={{float:'left'}}>
                            <Form.Check 
                              type='checkbox' checked={ (this.current_division.show_fight_list) ?'checked' : '' } onChange={this.toggleFightList}
                            /> Show Fight List
                            </div>}{ (div.config_type == 'scores') ? 'Order' : ''}{ (div.config_type == 'sparring') ? 'Brackets' : ''}
                       {div.zoomed_fight && <div style={{float:'right'}}><Button variant="primary" onClick={()=>{ div.zoomed_fight = null; window.APP.reset(); }}>
                            Clear Zoom
                            </Button></div>}
                        </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row  style={{ textAlign:'center' }}>
                                    
                                    {div.child_divisions.length == 0 && div.config_type == 'scores' && div.hasDuplicateCompetitors() && <Button style={{ margin:'5px' }} 
                                        onClick={()=>{ div.deDuplicateComps(); }}    
                                        variant="danger">Remove Duplicate Competitors</Button>}
                                    
                                    {div.child_divisions.length == 0 &&  div.config_type == 'scores' && 
                                    <Table variant="dark">
                                               {standingsList}                                                                                    
                                    </Table>}
                                    
                                {div.child_divisions.length == 0 && div.config_type == 'sparring' &&                         
                                <BracketChart tv={0} div={div} > </BracketChart>}

                                {div.child_divisions.length > 0 && 
                                                                    <Table variant="dark">
                                                            <tr>
                                                            <th colspan="3">Name</th>
                                                            <th>Age</th>
                                                            <th>Weight</th>
                                                            <th>Gender</th>
                                                            <th>Rank</th>
                                                            <th>Division</th>
                                                            </tr>
                                                            {div.getCheckedIn().map( x => <tr>
                                                    <td style={{ width:'50px' }}>{x.photo != '' &&  <img style={{ width: '40px'}} src={x.photo} /> }</td>
                                                         <td style={{ width:'50px', verticalAlign:'middle' }}>
                                            {x.country != '' && <OverlayTrigger placement={'top'}
                                      overlay={
                                        <Tooltip>
                                          {x.country_name}
                                        </Tooltip>
                                      }
                                      ><img style={{ 'width':'50px' }} src={x.country}   ></img></OverlayTrigger> }
                                       </td>
                                                    <td style={{ textAlign:'left' }}>{x.name}</td>
                                                                
                                                                
                                                        <td>{x.age}</td>
                                                        <td>{x.getWeightString()}</td>
                                                        <td>{x.gender}</td>
                                                        <td>{x.rank}</td>
                                                        <td>{
                                                                div.child_divisions.map(d => <OverlayTrigger
                                                                    key={'top'}
                                                                    placement={'top'}
                                                                    overlay={
                                                                      <Tooltip>
                                                                      <span style={{ fontSize:'1.5em' }}>{d.name}</span>
                                                                      </Tooltip>
                                                                    }
                                                      >
                                                      <Button style={{ margin:'3px' }} variant={x.child_division_set_to == d.id ? 'primary' : 'outline-primary'} onClick={()=>{ x.child_division_set_to = d.id; window.APP.reset();} }>{d.code}</Button>
                                                        </OverlayTrigger>)     
                                                        }</td>
                                    </tr> )}                                                                           
                                    </Table>}
                                </Row>                      
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
            } else {
            
            div.getCompOrder();
            
           let comp = div.current_comp;
            if(comp != null) {
                comp.stopWatch.setTimer(div.timer_length);
            }
            
           let upcoming_c = [];
            
            var u_comp = comp.after;
            while(u_comp) {
               upcoming_c.push(u_comp);
               u_comp = u_comp.after;
            }
            
           const upcomingList =upcoming_c.map((comp) => {

                  return   <tr>
                    <td>{comp.ordered}</td>
                    <td>{comp.name}</td>
                    {seeds && <td>{comp.seeding}</td>}
                      </tr>;
                  }
                );
            
            const upcomingListTv = function(styling) {
                return upcoming_c.map((comp) => {

                  return   <tr>
                    <td style={{ fontSize:((150 * styling.adj) + '%') }}>{comp.ordered}</td>
                    <td style={{ fontSize:((150 * styling.adj) + '%') }}>{comp.name}</td>
                    {seeds && <td style={{ fontSize:((125 * styling.adj) + '%') }}>{comp.seeding}</td>}
                      </tr>;
                  }
                );
                };
            
            this.context.window_manager.setDef(
                       comp.getCompetingWindow (upcomingListTv, div) 
                      );
            
            return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.setCurrentComp(comp.preceding)} >
                            Previous
                        </Card.Body>
                  </Card>
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name}</div>
                          </OverlayTrigger>
                        {comp.ordered}. {comp.name} {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                        </Card.Body>
                  </Card>
                  </Col>
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={(comp.after == null) ? this.startScoringDivision : ()=> this.setCurrentComp(comp.after)} >
                         Next
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>

                <Row  style={{ textAlign:'center' }}>
                <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {upcoming_c.length} left
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
Competitor {comp.ordered}
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%' }}
                        text= 'light'
                      >
                        <Card.Body>
                         <span class={(this.ring.getNumJudges()  < div.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()}</span> Judges
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                
                {div.scorekeeper_warning && <Row ><Col xs={12}>
                            <Alert variant="danger" style={{ textAlign:'center' }}>
                                <p>{div.scorekeeper_warning}</p>
                                </Alert></Col></Row>
                        }


                <Row  style={{ textAlign:'center' }}>
                <Col xs={6}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                       <Card.Header >{comp.name}
                       {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                       </Card.Header>
                        <Card.Body>
                        <Container>
                        <Row>
                        <Col md={12}>
                        {comp.photo != '' &&  <img style={{ width: '150px'}} src={comp.photo} /> }
                                    <ButtonGroup className="mr-2" aria-label="First group">
                                    {!comp.stopWatch.running && comp.stopWatch.master &&  <Button varient='primary' onClick={()=>comp.stopWatch.start()}><i class="fas fa-play"></i></Button>}
                                    {comp.stopWatch.running && comp.stopWatch.master &&  <Button variant="warning" onClick={()=>comp.stopWatch.stop()}><i class="fas fa-pause"></i></Button>}
                                    {!comp.stopWatch.running && comp.stopWatch.master &&  <Button variant="danger" onClick={()=>comp.stopWatch.reset()}><i class="fas fa-sync"></i></Button>}
                                    </ButtonGroup>
                                    <ButtonGroup className="mr-2" aria-label="Second group" >
                                        <Button onClick={()=>{ if(comp.stopWatch.current > 0 && comp.stopWatch.running) { comp.stopWatch.stop(); } if(comp.after == null) {this.startScoringDivision() } else {this.setCurrentComp(comp.after);} this.checkCompetitor(comp, false);  } } variant="danger">Missing</Button>
                                        {comp.status != 4 && <Button onClick={()=>{ this.setCompStatus(comp, 4); if(comp.stopWatch.current > 0 && comp.stopWatch.running) { comp.stopWatch.stop(); } if(comp.after == null) {this.startScoringDivision() } else {this.setCurrentComp(comp.after);}  } } variant="warning">DQ</Button>}
                                        {comp.status == 4 && <Button onClick={()=>{ this.setCompStatus(comp, 1); } } variant="success">Reset</Button>}
                                    </ButtonGroup>
                                    <ButtonGroup aria-label="Third group">
                                      <Button onClick={()=>{ if(comp.stopWatch.current > 0 && comp.stopWatch.running) { comp.stopWatch.stop(); } if(comp.after == null) {this.startScoringDivision() } else {this.setCurrentComp(comp.after);} } } >Finish</Button>
                                    </ButtonGroup>
                                <h2 style={{ color:comp.stopWatch.color() }}>{comp.stopWatch.getDisplay()}</h2>
                                <p>
                                {
                            comp.inputs && comp.inputs.map(
                                    (input) => 
                            <p><strong>{input.label}</strong>: {input.value}</p>
                                    )
                                           }
                                </p>
                        </Col>
                        </Row>
                        
                        <Row>
                        <Col md={12}>
                        <Table variant="dark">
                        {comp.country_name != '' && <tr><th>Country</th><td>{comp.country_name}</td></tr>}
                        {comp.state_name != '' && <tr><th>State</th><td>{comp.state_name}</td></tr>}
                        {comp.city != '' && <tr><th>City</th><td>{comp.city}</td></tr>}
                        <tr><th colspan="2" style={{ textAlign:'center' }}>Schools</th></tr>
                        {comp.schoolteams && comp.schoolteams.length > 0 &&
                            comp.schoolteams.map(
                                    (input) => 
                                        <tr><td colspan="2">{input}</td></tr>
                                    )
                                           }
                        </Table>
                        </Col>
                        </Row>
                        </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                  
                  <Col xs={6}>
                    <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                       <Card.Header >Upcoming
                       </Card.Header>
                        <Card.Body >
                        <Table variant="dark">
                            {upcomingList}
                            </Table>
                        </Card.Body>
                  </Card>
                  </Col>
                  
                </Row>
                </Container>;
            }
        }
                
        //SCORING
        if (div.status == 2) {
            if(! div.current_comp) {
                this.context.window_manager.setDef(
                        div.getCompOrderDisplay(this.ring.getNumJudges()) 
                       );

                const standingsList = div.getCompOrder()
                        .map((comp) => {
                            
                            if(this.ring.getNumJudges() != comp.getNumberScores() ) {
                                comp.resetScores(this.ring.getNumJudges())
                            }
                            
                            
                            const scoresList = comp.getScores()
                            .map((score) => 
                                <td>
                                    { (score.score > 0 && parseFloat(score.og_score) != parseFloat(div.score_prefill) ) ? <i class="fas fa-check text-success"></i> : <i class="far fa-clock text-primary"></i> }
                                </td>  

                             );
                   return   <tr>
                    <td>{comp.ordered}</td>
                    <td>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td><td style={{ width:'50px', verticalAlign:'middle' }} >
            {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
       </td>
                    <td>{comp.name}{comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td>
                    {scoresList}
                    
                       </tr>;
                   }
                 );


                return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.startDivision(true)}  >
                            Back to Scorings
                        </Card.Body>
                  </Card>
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name} Scoring</div>
                          </OverlayTrigger>
                        </Card.Body>
                  </Card>
                  </Col>
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>{ div.fixAllScores(); if(div.getCompOrder()[0] != null) {this.setCurrentComp(div.getCompOrder()[0]) } else {this.completeDivision()} }} >
                         Start
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>

                <Row  style={{ textAlign:'center' }}>
                <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {div.getCheckedIn().length} Total
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        0 Scored
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <span class={(this.ring.getNumJudges()  < div.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()}</span> Judges
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>

                {div.scorekeeper_warning && <Row ><Col xs={12}>
                <Alert variant="danger" style={{ textAlign:'center' }}>
                    <p>{div.scorekeeper_warning}</p>
                    </Alert></Col></Row>
            }

                <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                       <Card.Header >Scores
                       </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row  style={{ textAlign:'center' }}>
                                    <Table variant="dark">
                                    {this.ring.judge_as_buttons && <tr>
                                                        <td></td>
                                                        <td></td><td style={{ width:'50px', verticalAlign:'middle' }} >
                                           </td>
                                                        <td>Judge As</td>
                                                        {this.ring.getJudges().map((ju) => <td>      
                                                            <OverlayTrigger
                                                                       
                                                                        placement={'top'}
                                                                        overlay={
                                                                          <Tooltip>
                                                                            Judge as Judge {ju.number}
                                                                          </Tooltip>
                                                                        }
                                                                      >
                                                                        <a class='btn btn-secondary' href={ '/app?type=judge&num=' + (ju.number - 1) + '&id=' + rendered.ring.id } target='_blank'><i class="fas fa-external-link-alt"></i></a>
                                                                      </OverlayTrigger>                                                                                                                                                                    
                                                        </td> )}

                                    </tr>}
                                               {standingsList}                                                                                    
                                    </Table>
                                </Row>                      
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
            } else {
            let comp = div.current_comp;
            if(this.ring.getNumJudges() != comp.getNumberScores() ) {
                comp.resetScores(this.ring.getNumJudges())
            }
            
            
            this.context.window_manager.setDef(
                        comp.getScoringWindow (div) 
                       );
            
            
            
            
                 let upcoming_c = [];
            
            var u_comp = comp.after;
            while(u_comp) {
               upcoming_c.push(u_comp);
               u_comp = u_comp.after;
            }
            
            var score_options = [];
            var all_score_options = [];
            var on = '';
            var max_score = '-1';
            var min_score = '-1';
                        
            if(div.quick_max_score) {
                max_score = parseFloat(div.quick_max_score);
            }
            
            if(div.quick_min_score) {
                min_score = parseFloat(div.quick_min_score);
            }
            
            if(max_score == -1 && div.max_score) {
                max_score = parseFloat(div.max_score);
            }
            
            if(min_score == -1 && div.min_score) {
                min_score = parseFloat(div.min_score);
            }
            
            if(div.min_score && div.max_score) {
                on = div.max_score;
                
                
                
                while (on >= div.min_score) { 
                    if(div.score_rules != 'int') { 
                        var pre_val2 = (Math.round(on * 100.0) / 100.0).toFixed(2) ;
                        if(!all_score_options.includes(pre_val2) ) all_score_options.push(pre_val2);
                    
                    } else {
                        if(!all_score_options.includes(on) ) all_score_options.push(on);
                        all_score_options.push( on )
                    }
                    
                    if(div.score_rules != 'int') {
                        on = on - 0.01;
                    } else {
                        on = on - 1;
                    }                                                                                                                                                                                                                                                                
                }
                
                if(div.score_prefill) {
                    var pre_val = (Math.round(div.score_prefill * 100.0) / 100.0).toFixed(2) ;
                    if(!all_score_options.includes(pre_val) ) all_score_options.unshift(pre_val);
                }
                
                if(div.score_dq) {
                    var d_val = (Math.round(div.score_dq * 100.0) / 100.0).toFixed(2);
                    if(!all_score_options.includes(d_val) ) all_score_options.push(d_val);
                }
                
                
            }
            
            if(min_score != -1 && max_score != -1) {
                on = max_score;
                
                while (on >= min_score) { 
                    if(div.score_rules != 'int') {
                        score_options.push( (Math.round(on * 100.0) / 100.0).toFixed(2) )
                    } else {
                        score_options.push( on )
                    }
                    
                    if(div.score_rules != 'int') {
                        on = on - 0.01;
                    } else {
                        on = on - 1;
                    }                                                                                                                                                                                                                                                                
                }
            }
            
            const aScoresList = comp.getScores()
                .map((score) => 
                    <td style={{borderTopStyle:'none', padding:'0 .15rem', width: ( (100.0 / comp.getScores().length)  + '%') }}>
                    <Card onClick={ (e) => {  rendered.focusOnScore(score); } } 
                    bg='dark' text='light' border={ (rendered.focused_score === score) ? 'danger' : '' }>
                    
                    {window.APP.ring.judge_display == 1 && 
                    <Card.Header>
                        {window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb != null && window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb != '' && <img style={{ height: '50px' }} src={window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb} ></img>}
                        { (window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb == null || window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb == '') && <img style={{ height: '50px' }}  ></img>}
                            
                        &nbsp;{ this.ring.getJudge(parseInt(score.judge) + 1) .name }&nbsp;</Card.Header> }
                          
                    <Card.Body class="text-center" style={{ textDecoration: (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }}>
                        <div style={{ paddingBottom: '0.2rem', fontSize:'1.5em' }}>
                            {div.niceFormatScore(parseFloat(score.score) ) }

                            { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score) && score.score != score.og_score  && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) )   &&                             
                                    <small><i class="fas fa-long-arrow-alt-up text-success"></i></small>                                
                            }

                            { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) )    &&                               
                            <small><i class="fas fa-long-arrow-alt-down text-danger"></i></small>                                    
                            }
                        </div>
                        <div>
                            {(rendered.holder.score_input_type == 'type' || all_score_options.length == 0 ) && <Form.Control disabled={ comp.status == 4 ? 'disabled' : '' }
                                as="input" type="text" value={score.og_score}  className={'scoreinput ' + ( ( div.score_rules != 'int' || score.og_score == 10 || score.og_score.toString().length > 3) && parseFloat(score.og_score) > parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) ? 'deviateddown' : ( (( div.score_rules != 'int' || score.og_score == 10 || score.og_score.toString().length > 3) && parseFloat(score.og_score) < parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) ? 'deviatedup' : 'nodev'  ) ) )  }
                                onChange={(event)=>this.setScore(comp, score.judge, event.target.value)} 
                                onFocus={ (e) => {  e.target.setSelectionRange( e.target.value.toString().length, e.target.value.toString().length); rendered.focusOnScore(score);var t=e.target; setTimeout(function(){ t.setSelectionRange(t.value.toString().length, t.value.toString().length); }, 10); } }  
                                >
                            </Form.Control>}
                            {rendered.holder.score_input_type == 'dropdown' && all_score_options.length > 0 && <Form.Control disabled={ comp.status == 4 ? 'disabled' : '' }
                            onFocus={ (e) => {  e.target.setSelectionRange( e.target.value.toString().length, e.target.value.toString().length); rendered.focusOnScore(score);var t=e.target; setTimeout(function(){ t.setSelectionRange(t.value.toString().length, t.value.toString().length); }, 10); } } className="scoreinput" 
                            value={ ( div.score_rules == 'int' || score.og_score == 10 || score.og_score.toString().length > 3) ? ( (div.score_rules == 'int') ? parseInt(score.og_score) : (Math.round(score.og_score * 100.0) / 100.0).toFixed(2) ) : 0 } 
                            onChange={(event)=>{this.setScore(comp, score.judge, event.target.value);setTimeout(function(){  rendered.focusNextScore(score); }, 15); }}  as="select" size="lg" >
                                <option value={0}>---</option>
                                {all_score_options.map((x) => <option value={x}>{x}</option> )}
                            </Form.Control>}
                        </div>
                        <div><small class="text-danger">&nbsp;
                            { parseFloat(score.og_score) > div.max_score   &&                               
                                <span>Above Max</span>                      
                            }
                            
                            { parseFloat(score.og_score) < div.min_score   && parseFloat(score.og_score) > 0 && parseFloat(score.og_score) != parseFloat(div.score_dq) &&                               
                                <span>Below Min</span>                                    
                            }
                            &nbsp;
                            </small> 
                        </div>
                    </Card.Body>
                    </Card>                                                                                                                                                                                                         
                    </td>  
                   
                   
                 );
            
            return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.setCurrentComp(comp.preceding)} >
                            Previous
                        </Card.Body>
                  </Card>
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%',marginTop:'0px',marginBottom:'5px', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name}</div>
                          </OverlayTrigger>
                        {comp.ordered}. {comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}
                        {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                        </Card.Body>
                  </Card>
                  </Col>
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={(comp.after == null) ? this.completeDivision : ()=> this.setCurrentComp(comp.after)}  >
                         Next
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>

                <Row  style={{ textAlign:'center' }}>
                <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body >
                        {upcoming_c.length} left
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                        Competitor {comp.ordered}
                        </Card.Body>
                  </Card>
                  </Col>

                  <Col xs={4}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <span class={(this.ring.getNumJudges()  < div.min_judges ) ? 'text-danger':'text-success'}>{this.ring.getNumJudges()}</span> Judges
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>


                {div.scorekeeper_warning && <Row ><Col xs={12}>
                            <Alert variant="danger" style={{ textAlign:'center' }}>
                                <p>{div.scorekeeper_warning}</p>
                                </Alert></Col></Row>
                        }

                <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%', padding:'0'  }}
                        text= 'light'
                      >
                        <Card.Body style={{ padding:'0'  }} >
                        <Table variant="dark">
                        <tr>
                                {aScoresList}                                                                                                                                                                                                                                       
                        </tr>
                        </Table>
                        { score_options.length > 0 && this.focused_score != null && <div style={{ marginBottom:'5px',marginTop:'5px' }}>
                            <div className="mb-2">
                                {
                                score_options.map((x) => <Button style= {{ margin:'3px' }} onClick={()=>{ rendered.focusedScoreUpdate(rendered.focused_score,x); } }>{x}</Button>)
                                }
                            </div>
                        </div>}
                        
                        <div style={{ marginBottom:'10px' }}>
                            <ButtonGroup className="mr-2" aria-label="Second group" >
                                {comp.status != 4 && <Button onClick={()=>{ this.setCompStatus(comp, 4); } } variant="warning">DQ</Button>}
                                {comp.status == 4 && <Button onClick={()=>{ this.setCompStatus(comp, 1); } } variant="success">Reset</Button>}
                            </ButtonGroup>
                            
                            <Button variant="primary" onClick={(comp.after == null) ? this.completeDivision : ()=> this.setCurrentComp(comp.after)}>Next</Button>
                        </div>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
            }
        }
        
        //WINNERS
        if (div.status == 3) {
            this.context.window_manager.setDef(
                        div.getCompPlacesDisplay
                       );

                if(div.getTies().length > 0) {
                    
                   
             
                    const tiesList = div.getTies()
                        .map((tie) => {
                            const ties = tie.map( (comp) => {  
                                const upScoresList = comp.getUpScores()
                                .map((score) => 
                                    <td>
                                    {div.niceFormatScore(parseFloat(score.score) )}                                                                                                                                                                                                              
                                    </td>  
                                 );
                         
                                const scoresList = comp.getScores()
                                .map((score, index) => 
                                    <td style={{ 'borderLeftStyle' : ( ( upScoresList.length > 0 && index == 0 )?'solid':'none') }} >
                                    {div.niceFormatScore(parseFloat(score.score) )}                                                                                                                                                                                                              
                                    </td>  
                                 );
                         return <tr><td>{comp.place}</td><td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                                                <td style={{ width:'50px', verticalAlign:'middle' }} >
                                 {comp.country != '' && <OverlayTrigger placement={'top'}
                           overlay={
                             <Tooltip>
                               {comp.country_name}
                             </Tooltip>
                           }
                           ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                            </td> <td  style={{ borderRightStyle:'solid' }}>{comp.name}</td>{div.config_type == 'scores' && upScoresList}{div.config_type == 'scores' && scoresList}{div.config_type == 'scores' && <td  style={{ borderLeftStyle:'solid' }}>{div.niceFormatScore(parseFloat(comp.getScoreTotal()) )}</td>}<td><Button variant="primary" onClick={ ()=>div.winTie(comp)  } >Win</Button></td></tr> 
                                                 })
                            
                            
                            
                            return <Table variant="dark">
                    {ties}
                          </Table> ;
                             
                             } );
                             
                             
                    return <Container>
                    <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    {(div.is_final == 0 || div.config_type != 'scores' ) && <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.returnToScoringDivision()}  >
                            Back
                        </Card.Body>
                  </Card>}
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name} Places</div>
                          </OverlayTrigger>
                        </Card.Body>
                  </Card>
                  </Col>
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>this.swapToDivision(null)} >
                         Schedule
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                {tiesList}
                </Container>;
                }

                let compW = div.getPlaceOrder()[0]; 
                
                if(div.getPlaceOrder().length > 1 ) { 
                    if(compW.place == div.getPlaceOrder()[1].place ) { 
                        compW = null;
                    }
                }
                if(this.current_division.winner_screen > 0 && div.activeProtests().length == 0 && compW != null && compW.winner_screen_forced  && div.break_ties_till > 0) { 
                    compW.winnerScreenRunning();
                    return <div className={compW.amimateWinnerIn} ><Container style={{ width:'100%', maxWidth:'100%' }}>
                        <Row style={{ textAlign:'center' }}>
                            <div style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'150%'  }}>
                                    <h1 style={{ fontSize:"120%"}}>{div.name}</h1>
                            </div>
                        </Row>


                       <Row style={{ textAlign:'center' }}>
                            <div style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'170%'  }}>

                                <h1 style={{ fontSize:"120%"}}>WINNER</h1>

                            </div>
                       </Row>

                        <Row style={{ textAlign:'center' }}>
                           <div className={compW.amimateWinner} style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'175%'  }}>

                               <h1   style={{ fontSize:"120%"}}>{compW.name}</h1>
                                   <div style={{ textAlign:'center' }}>
                                       {compW.photoL != '' &&  <img style={{ width: '250px'}} src={compW.photoL} /> }
                                   </div>

                                   {compW.country != '' && <div style={{ textAlign:'center', marginTop:'20px' }}><img style={{ 'width':'75px' }} 
                                           src={compW.country}   ></img></div> }
                           </div>
                       </Row>


                       </Container></div>;
                }

                const standingsList = div.getPlaceOrder()
                        .map((comp) => {
                            const upScoresList = comp.getUpScores()
                            .map((score) => 
                                <td style={{ 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }} >
                                    {div.niceFormatScore(parseFloat(score.score) )} 
                                            
                                    { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&
                                    
                                    <i class="fas fa-long-arrow-alt-up text-success"></i>
                                    
                                    }
                                    { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&
                                    
                                    <i class="fas fa-long-arrow-alt-down text-danger"></i>
                                    
                                    }
                                    </td>
                                    );
                            const scoresList = comp.getScores()
                            .map((score, index) => 
                                <td style={{ 'borderRightStyle':'none', 'textDecoration': (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none','borderLeftStyle' : ( ( upScoresList.length > 0 && index == 0 )?'solid':'none') }} >
                                    {div.niceFormatScore(parseFloat(score.score) )} 
                                            
                                    { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&
                                    
                                    <i class="fas fa-long-arrow-alt-up text-success"></i>
                                    
                                    }
                                    { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score) && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&
                                    
                                    <i class="fas fa-long-arrow-alt-down text-danger"></i>
                                    
                                    }
                                    </td>  
                             );
                   return   <tr>
                    <td style={{ width:'15px' }}>{comp.place}</td>
                    <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td><td style={{ width:'50px', verticalAlign:'middle' }} >
                        {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                   </td>
                    <td style={{ borderRightStyle:'solid', width:'400px',textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>} </td>
                    {div.config_type == 'scores' && upScoresList}
                    {div.config_type == 'scores' && scoresList}    
                    {div.config_type == 'scores' && <td style={{ borderLeftStyle:'solid' }} >{div.niceFormatScore(parseFloat(comp.getScoreTotal()) )}</td>}
                    </tr>;
                   }
                 );


                return <Container>
                <Row style={{ textAlign:'center' }}>
                    <Col xs={2}>
                    {(div.is_final == 0 || div.config_type != 'scores' ) && <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body text='text-danger' onClick={()=> this.returnToScoringDivision()}  >
                            Back
                        </Card.Body>
                  </Card>}
                  </Col>
                <Col xs={8}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Body>
                         <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip>
                              <span style={{ fontSize:'1.5em' }}>{div.code}</span>
                              </Tooltip>
                            }
                          >
                            <div>{div.name} Places</div>
                          </OverlayTrigger>
                        </Card.Body>
                  </Card>
                  </Col>
                  <Col xs={2}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }} 
                        text= 'light'
                      >
                        <Card.Body onClick={()=>this.swapToDivision(null)} >
                         Schedule
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                {div.scorekeeper_warning && <Row ><Col xs={12}>
                    <Alert variant="danger" style={{ textAlign:'center' }}>
                        <p>{div.scorekeeper_warning}</p>
                        </Alert></Col></Row>
                }
                <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                       <Card.Header >
                       {compW != null && this.current_division.winner_screen > 0 && compW.winner_screen_force_time >=0 && <div style={{float:'left'}}>
      <Form.Check 
        type='checkbox' checked={ (this.current_division.winner_display) ?'checked' : '' } onChange={this.toggleWinner}
      /> Winner Screen
      </div>}
        Places
                       </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row  style={{ textAlign:'center' }}>
                                    {div.child_divisions.length == 0 && <Table variant="dark">
                                               {standingsList}                                                                                    
                                    </Table>}
                                </Row>                      
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
        }
        
     } else {
         
        this.context.window_manager.setDef(
                 this.ring.getWindowSchedule
                );
     
        var sch_id = null;
        
        var need_divs = this.filteredComplete(false);
        if(need_divs.length > 0 ){
            sch_id = need_divs[0].schedule_id;
        }
        
         const divisionsList = this.filteredComplete(this.show_completed).map((division) => 
         <tr onClick={ (division.schedule_id == sch_id || division.status == 3 ) ? () => { this.swapToDivision(division)}  : () =>{}  }  ><td>{division.code}</td><td className={ (division.status == 3)?'text-success': (division.schedule_id != sch_id)?'text-danger':'' }>{division.name}</td><td>{division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }</td><td >{division.standings.length}</td></tr>
          );
  
         return <Container>
            <Row style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'0.5%', width:'99%'  }}
                        text= 'light'
                      >
                        <Card.Header>
                        <div style={{float:'left'}}>
                            <Form.Check 
                              type='checkbox' checked={ (this.show_completed) ?'checked' : '' } onChange={this.toggleShowCompleted}
                            /> Show Completed
                        </div>
                            Upcoming Divisions
                        </Card.Header>
                        <Card.Body>
                        {this.ring.ring_import_request && <Alert variant="primary" style={{ textAlign:'center' }}>
                            <Alert.Heading>Import Division Request</Alert.Heading>
                            <p>There is a request to help with {this.ring.ring_import_request.code} - {this.ring.ring_import_request.name} <Button onClick={this.acceptRingImport} variant="primary">Accept</Button> <Button onClick={this.clearImport} variant="danger">Decline</Button></p>
                        </Alert>}
                         <Table striped bordered hover variant="dark">
                         <thead><tr><th>Code</th><th>Name</th><th>Time</th><th>Competitors</th></tr></thead>
                                <tbody>
                                    {divisionsList}
                                </tbody>
                                {divisionsList.length == 0 && this.ring.getNumJudges() > 0 && 
                                        <tr><td colspan='4' style={{ textAlign:'center' }}><Button onClick={this.clearJudges}  variant='danger'>Clear Judges</Button></td></tr>
                                }
                            </Table>
                        </Card.Body>
                  </Card>
                  </Col>
            </Row>          
            
            <Row style={{ textAlign:'center' }}>
                <Col xs={12}>
                    
                </Col>
            </Row>
                                                                        
            </Container>
     }
    }
    
}