import {  ConsoleLogger,  DefaultDeviceController,  DefaultMeetingSession,  LogLevel,  MeetingSessionConfiguration,} from 'amazon-chime-sdk-js';

import {AppDomain} from '../constants';
import postMaster from '../logic/postmaster';

const logger = new ConsoleLogger('ChimeMeetingLogs', LogLevel.INFO);
const deviceController = new DefaultDeviceController(logger);

export class meetingObservor {
    constructor(meeting) {
        this.meeting = meeting;
    }
    
    videoTileDidUpdate(tileState) {
        const videoElement = document.getElementById('video_for_meetings');
        this.meeting.audioVideo.bindVideoElement(tileState.tileId , videoElement);
    } 
}

export function joinMeeting(url, share) {
    postMaster.immediateGet(url + '?name=' + window.APP.name)
   .then(
      (result) => {
        alert('Sent request');
      });
    return;
    
    if(window.meeting) {
        window.meeting.audioVideo.stopContentShare();
        window.meeting.audioVideo.stop();
        window.meeting = null;
        window.APP.forceUpdate();
        return;
    }
    
    postMaster.immediateGet(url)
           .then(res => res.json())
           .then(
              (result) => {
                  
                const configuration = new MeetingSessionConfiguration(result.meeting, result.atendee);
                const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
               
                window.meeting = meetingSession;
                
                window.APP.forceUpdate();
                
        try {
                    meetingSession.audioVideo.listAudioInputDevices()
                            .then((audioInputs) =>
                    {
                        meetingSession.audioVideo.chooseAudioInputDevice(audioInputs[0].deviceId)
                                .then(() => {
                                    const audioOutputElement = document.getElementById('use_for_meetings');
                                    meetingSession.audioVideo.bindAudioElement(audioOutputElement);
                                    meetingSession.audioVideo.start();
                                    if(share) {
                                        meetingSession.audioVideo.startContentShareFromScreenCapture();
                                    } else {
                                        meetingSession.audioVideo.addObserver(new meetingObservor(meetingSession) )
                                    }
                                })
                    }        
                    )       
                } finally {}
               
                }
            );
}