import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import postMaster from '../logic/postmaster'
import {AppContext, AppDomain} from '../constants';
import {MessagesDisplay} from '../models/messages';
import {Division} from '../models/division';
import {FightCall, Fight, BracketChart} from '../models/fights'

export class DivisionBracketsDisplay extends React.Component { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);                    
        this.division_id = props.division_id;            
        this.division = null;
        
        window.APP.simple_display = this;    
        
        this.load();
    }
        
    load() {
        var inter = this;
        postMaster.immediateGet("/tournament-division/" + this.division_id + '/public-info').then(res => res.json())
       .then(
          (result) => {
              var d = new Division(result.id);
              d.load(result);
              inter.division =d;
              document.getElementById("loading_area").style.display = "none";
              window.APP.reset();
          }
        );
    }
        
    render() {
        let holder = this;
        console.log(holder.division);
        return (
        <Container>
            <Row>{holder.division && holder.division.zoomed_fight && <div style={{float:'right'}}><Button variant="primary" onClick={()=>{ holder.division.zoomed_fight = null; window.APP.reset(); }}>
                                        Clear Zoom
                                        </Button></div>}</Row>
            <Row>   
            
                {holder.division != null && <Col  xs={12}>            
                       {this.division.config_type == 'sparring' && <BracketChart tv={0} div={this.division} bracket='true' > </BracketChart>}           
                </Col>}
            </Row>
        </Container>
    );
  }
}