import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import {AppContext, AppDomain} from '../constants';
import ReactDOM from 'react-dom';

export class AdditionalWindowManagerDisplay extends React.Component { 
    
    static contextType = AppContext;

    componentDidMount() {
        this.context.window_manager.setDisplay(this);
    }
   
    render() {
        
        var k = this;
        return <div>
        { k.context.window_manager.windows.map((window, index) =>
        <AdditionalWindow index={index}></AdditionalWindow>
      )}
        </div>
        ;
    }
}

export class AdditionalWindowManager extends React.Component { 
    constructor(props) {
        super(props);
        
        this.windows = [];
        this.insides = [];
        
        this.number = 0; 
        this.display = false;
        
        this.inside_name = '';
        this.use_def = true;
        this.def_inside = <div></div>;
        this.inside = document.createElement('div');
        
        this.logo = null;
        
        this.styling_options = {};
        
        var w = this.insides;
        
        
        
        window.addEventListener("beforeunload", function (e) { 
            w.forEach( (win) => {win.close();} );
        });
    }
    
    setLogo(logo) {
        this.logo = logo;
    }
    
    setDisplay(display){        
        this.display = display;               
    }
    
    resetStyling() {
        this.styling_options = {};
    }
    
    setDef(inside, styling= null) {
        if(styling != null) {
            this.styling_options = styling;
        }
        this.def_inside = inside;
        this.update();
    }
    
    opened() {
        return this.insides.filter( (win) => !win.externalWindow.closed  );
    }
    
    fullscreen() {
        return this. opened().filter( (win) => win.isFullscreen()  );
    }
    
    update() {
        var t = this;
        this.insides.forEach( (win) => {win.update(t.def_inside, t.inside,t.use_def, this.styling_options, this.logo);} );
    }
    
    addInside(inside) {
        this.insides.push(
            inside
        );
        this.update();
    }
    
    addWindow() {
        this.windows.push(
           {}
        );
        this.display.forceUpdate();
        
        window.APP.reset();
        setTimeout(function(){
                        window.APP.reset();
                    }, 1000);
    }
}


export class AdditionalWindow extends React.PureComponent {
    
   static contextType = AppContext;
    
   constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        var el = document.createElement('div');
        el.style.width = '100%';
        el.style.minHeight = '100%';
        el.style.backgroundColor = "#282c34";
        this.containerEl = el;
        this.externalWindow = null;

        this.isFullscreen = this.isFullscreen.bind(this);

        this.inside = '';

        this.sizing = 1;
        this.header_sizing = 1;
        this.type = 'tv';
        this.mode = '';
        this.display_type = 'normal';
        this.number = props.index + 1;
    }
    
    setMode(m) {
        this.mode = m;
    }
  
    setType(t) {
        this.type = t;
    }
    
    setDisplayType(t) {
        this.display_type = t;
        
        if(this.display_type == 'green') {
            this.externalWindow.document.body.style.overflow = 'hidden';
            this.containerEl.style.backgroundColor = "#00ff00"; 
        } else {
            this.externalWindow.document.body.style.overflow = 'initial';
            this.containerEl.style.backgroundColor = "#282c34";
        }
    }
    
    setHeaderSizing(s) {
        this.header_sizing = s;
    }
    
    setSizing(s) {
        this.sizing = s;
    }
  
    close() {
        if(this.externalWindow ) {
            this.externalWindow.close();
        }
    }
  
    render() {
      // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
      return ReactDOM.createPortal(this.inside, this.containerEl);
    }

  componentDidMount() {
    
       
    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open(AppDomain + '/app/tvtemplate.html', '', '');
    var w = this.externalWindow;
    
    w.onunload = function(event) { window.APP.resetSecond(); }
    var t = this;
    
    
    
    w.onresize = () => {window.APP.resize(); };
    w.onload = function() {

        t.context.window_manager.addInside(t);

        w.document.title = 'MAT Action | Display ' + t.number;

        var css = document.createElement("link");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css");
            css.setAttribute("crossorigin", "anonymous");
            w.document.head.appendChild(css);

        css = document.createElement("meta");
            css.setAttribute("name", "viewport");
            css.setAttribute("content", "width=device-width,initial-scale=1");
            w.document.head.appendChild(css);




        css = document.createElement("link");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.compat.css");
            css.setAttribute("crossorigin", "anonymous");
            w.document.head.appendChild(css);

        css = document.createElement("link");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", "https://www.mataction.com/CDN/fontawesome-free-5.11.2-web/css/all.css");
            css.setAttribute("crossorigin", "anonymous");
            w.document.head.appendChild(css);

        css = document.createElement("link");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", AppDomain + "/app/window_extra.css?v=8");
            css.setAttribute("crossorigin", "anonymous");
            w.document.head.appendChild(css);

        css = document.createElement("link");
            css.setAttribute("rel", "shortcut icon");
            css.setAttribute("href", AppDomain + '/favicon.png');
            css.setAttribute("type", "image/png");
            w.document.head.appendChild(css);

        css = document.createElement("link");
            css.setAttribute("rel", "icon");
            css.setAttribute("href", AppDomain + '/favicon.png');
            css.setAttribute("type", "image/png");
            w.document.head.appendChild(css);

        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        w.document.body.appendChild(t.containerEl);
    }
  }
  
    setDisplay(display) {
        this.inside = display;
        this.forceUpdate();
    };

goFullscreen() {
        if (this.externalWindow.document.documentElement.requestFullscreen != null ) {
            this.externalWindow.document.documentElement.requestFullscreen();
            return true;
        }
        
        if (this.externalWindow.document.documentElement.msRequestFullscreen != null ) {
            this.externalWindow.document.documentElement.msRequestFullscreen();
            return true;
        }
        
        if (this.externalWindow.document.documentElement.mozRequestFullscreen != null ) {
            this.externalWindow.document.documentElement.mozRequestFullscreen();
            return true;
        }
        
        if (this.externalWindow.document.documentElement.webkitRequestFullscreen != null ) {
            this.externalWindow.document.documentElement.webkitRequestFullscreen();
            return true;
        }
        
        
        return false;
    }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    this.externalWindow.close();
  }
  
  isFullscreen() {
        var w = this.externalWindow;
        if (w.document.fullscreenElement != null || w.document.mozFullScreenElement  != null|| w.document.webkitFullscreenElement  != null || w.document.msFullscreenElement  != null) {
            return true;
        }
        
        if(this.type == 'stream')  {
            return true;
        }
        
        if( Math.abs(w.screen.width - w.innerWidth) < 20 && Math.abs(w.screen.height - w.innerHeight) < 20 ) {
            return true;
        }
        return false;
    }
  
  canFullscreen() {        
      var d = this.externalWindow.document;
  
        if (d.fullscreenEnabled != null || d.mozFullscreenEnabled  != null|| d.webkitFullscreenEnabled  != null || d.msFullscreenEnabled  != null) {
            return true;
        }
        return false;
    }
  
  update(def_inside, inside, use_def, styling_options, logoObject) { 
      
      var header_appears = true;
      
        var fullScreenArea = <div >
            <Card bg={'dark'} text={'light'} style={{ position: 'absolute',bottom:'30px',left:'30px'}} onClick={ ()=>{ this.goFullscreen(); } }>
            <Card.Body style={{ padding:'0.3rem' }} ><i class="fas fa-expand text-primary"></i></Card.Body>
                </Card>
        </div>;
      
        if(this.isFullscreen() || !this.canFullscreen()   ) {
            fullScreenArea= '';
        }
      
        if(styling_options.brackets_adj != null) {
            this.brackets_adj = styling_options.brackets_adj;
        } else {
            this.brackets_adj = null;
        }
      
        if(styling_options == null) {
            styling_options = {};
        }
      
        if(styling_options.not_header) {
           header_appears = false;
        }
        
        var logo = <div></div>;
        
        if(logoObject) { 
            logo = <div ><img className={ (logoObject.status == 0) ? ('animated ' + logoObject.animateInGreen) : ('animated ' + logoObject.animateOutGreen) } 
            style={{ position:'fixed',top:'0',left:'0',right:'0',bottom:'0',margin:'auto', width:logoObject.width }}
                src={logoObject.url}
                alt={logoObject.name}
              /></div>;
        }
      
            var header = <div>
            
              
            <div style={{ width:'100%' }} className={ (this.display_type == 'green' || styling_options.display_time =='green') ? ((header_appears) ? 'animated slideInDown' : 'animated slideOutUp') : '' } >
        <Navbar style={{ display:'flex',flexDirection:'row',  paddingTop: ( ( (this.type == 'stream')? (35 * this.header_sizing) : (3 * this.header_sizing) ) + 'px')  }} bg="dark" expand="lg" variant="dark" className='justify-content-between' 
        >
            <Nav style={{ flexDirection:'row',flex:'1' }}>
                { window.APP && window.APP.ring && window.APP.ring.image && 
                <Navbar.Brand href="#home"><img
                src={window.APP.ring.image}
                height={ (120 * this.header_sizing) }
                alt="tournament logo"
              />
              { (this.type == 'stream') && <img
                  src={ 'https://cdn.mataction.com/CDN_3/MAT-Logo-top.png' }
                  height={ (80 * this.header_sizing) }
                  alt="MAT Action logo"
                  style={{ marginTop: ((20 * this.header_sizing)  + 'px') }}
                />}
                    </Navbar.Brand> }
            
            </Nav>
            <Nav style={{ flexDirection:'row' }}>
                <Navbar.Brand style={{ fontSize:( (200 * this.header_sizing) +'%'),textAlign:'center',verticalAlign:'middle',marginTop:'auto',marginBottom:'auto',whiteSpace:'normal' }} href="#home"> { window.APP && window.APP.ring &&  window.APP.ring.name} { (window.APP && window.APP.header_second != '') ? <span>{this.display_type == 'green' && <br></br>}{this.display_type != 'green' && <span> - </span>}{window.APP.header_second}</span> : '' }</Navbar.Brand>
            </Nav>
            <Nav style={{ flexDirection:'row',flex:'1' }}>
                <Navbar.Brand href="#home" style={{ marginLeft: 'auto' }}>
            
                { (this.type != 'stream') && <img
                  src={ 'https://cdn.mataction.com/CDN_3/MAT-Logo-top.png' }
                  height={ (80 * this.header_sizing) }
                  alt="MAT Action logo"
                  style={{ marginTop: ((20 * this.header_sizing)  + 'px') }}
                />}</Navbar.Brand>
            </Nav>
        </Navbar></div>
        </div>;
        
        if(styling_options.remove_header) {
            header = <div></div>;
        }
        
        const adj = this.sizing;
                
        styling_options.adj=adj;
        styling_options.screen=this;
        styling_options.display_type=this.display_type;
        
        if(this.type == 'none') {
            header = <div>{logo}{fullScreenArea}</div>;
        }
        
        if(this.mode == 'blank') {
            this.setDisplay(<div>{header}{logo}{fullScreenArea}</div>);
                return;
        }
        
        if(this.mode == 'ring') {
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }}><div style={{ color:"white", textTransform : "uppercase", width : '100%', height:'100%', paddingTop: '10%',textAlign:'center', fontSize :'3em'  }}>
                    <h1 style={{ fontSize:"130%"}}>{window.APP && window.APP.ring && window.APP.ring.name}</h1>
                </div></div>{fullScreenArea}{fullScreenArea}</div>);
                return;
        }
        
        if(this.mode == 'schedule') { 
            var insidering = window.APP && window.APP.ring && window.APP.ring.getWindowSchedule(styling_options)
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }}>{insidering}</div>{fullScreenArea}</div>);
                return;
        }
        
        if(this.mode == 'timer') { 
            var insidering = window.APP && window.APP.ring && window.APP.ring.getWindowSchedule(styling_options)
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }}><div style={{ color:"white", textTransform : "uppercase", width : '100%', height:'100%', paddingTop: '10%',textAlign:'center', fontSize :'3em'  }}>
                    <h1 style={{ fontSize:"200%"}}>{window.APP && window.APP.timer && <span style={{color:window.APP.timer.color() }}>{window.APP.timer.getDisplay(true)}</span>}</h1>
                </div></div>{fullScreenArea}</div>);
                return;
        }
        
        if(this.mode == 'stopwatch') { 
            var insidering = window.APP && window.APP.ring && window.APP.ring.getWindowSchedule(styling_options)
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }}><div style={{ color:"white", textTransform : "uppercase", width : '100%', height:'100%', paddingTop: '10%',textAlign:'center', fontSize :'3em'  }}>
                    <h1 style={{ fontSize:"200%"}}>{window.APP && window.APP.stopwatch && <span>{window.APP.stopwatch.getDisplay()}</span>}</h1>
                </div></div>{fullScreenArea}</div>);
                return;
        }
        
        if(styling_options.not_body == 2) {
            this.setDisplay(<div>{header}{logo}{fullScreenArea}</div>);
                return;
        }
        
        if(typeof def_inside === 'function') { 
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }} >{def_inside(styling_options) }</div>{fullScreenArea}</div>);
        } else {
            this.setDisplay(<div>{header}{logo}<div style={{ fontSize:(( 100 * adj ) +'%') }} >{def_inside}</div>{fullScreenArea}</div>);
        }
  }
  
    
}