import React from 'react';

export const AppDomain = 'https://staging.mataction.com' ; 
export const stripeKey = 'pk_test_4m91Y9SED0F10ZYuD7UPlQ0C';
export const PusherKey = '9985f4c83ea908a83c69'; 

//export const AppDomain = 'https://www.mataction.com' ;export const PusherKey = '11a496518f630c628ead';
//export const stripeKey = 'pk_live_51H4saEHzm5sgevBhC2wsQMpQlSNAUzdVDPbYm6C2EgZErmxX8WVwonCjAUA7Qp902k7t11OMrMD9nS0SEY1hUNxP00tcd6wc9Y';

export const seeds = false;

export const animatedIntros = ['bounceInRight','bounceInLeft','bounceInUp','bounceInDown','bounceIn',
//'fadeIn','fadeInUp','fadeInDown','fadeInRight','fadeInLeft',
'flipinx','flipiny',

'rotateIn','rotateInDownLeft','rotateInDownRight','rotateInUpRight','rotateInUpRight',
'zoomIn','zoomInDown','zoomInLeft','zoomInRight','zoomInUp',
'slideInDown','slideInLeft','slideInRight','slideInUp',

'jackinthebox','rollIn'
];
export const animatedLeftover = [
//    'bounce', 
    'pulse',
    'headShake',
 //   'swing',
    'tada',
    'heartBeat' 
];

export const dayWords = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
    
];


export const stateABB = {
    'ALABAMA':'AL',
'ALASKA':'AK',
'AMERICAN SAMOA':'AS',
'ARIZONA':'AZ',
'ARKANSAS':'AR',
'CALIFORNIA':'CA',
'COLORADO':'CO',
'CONNECTICUT':'CT',
'DELAWARE':'DE',
'DISTRICT OF COLUMBIA':'DC',
'FLORIDA':'FL',
'GEORGIA':'GA',
'GUAM':'GU',
'HAWAII':'HI',
'IDAHO':'ID',
'ILLINOIS':'IL',
'INDIANA':'IN',
'IOWA':'IA',
'KANSAS':'KS',
'KENTUCKY':'KY',
'LOUISIANA':'LA',
'MAINE':'ME',
'MARYLAND':'MD',
'MASSACHUSETTS':'MA',
'MICHIGAN':'MI',
'MINNESOTA':'MN',
'MISSISSIPPI':'MS',
'MISSOURI':'MO',
'MONTANA':'MT',
'NEBRASKA':'NE',
'NEVADA':'NV',
'NEW HAMPSHIRE':'NH',
'NEW JERSEY':'NJ',
'NEW MEXICO':'NM',
'NEW YORK':'NY',
'NORTH CAROLINA':'NC',
'NORTH DAKOTA':'ND',
'NORTHERN MARIANA IS':'MP',
'OHIO':'OH',
'OKLAHOMA':'OK',
'OREGON':'OR',
'PENNSYLVANIA':'PA',
'PUERTO RICO':'PR',
'RHODE ISLAND':'RI',
'SOUTH CAROLINA':'SC',
'SOUTH DAKOTA':'SD',
'TENNESSEE':'TN',
'TEXAS':'TX',
'UTAH':'UT',
'VERMONT':'VT',
'VIRGINIA':'VA',
'VIRGIN ISLANDS':'VI',
'WASHINGTON':'WA',
'WEST VIRGINIA':'WV',
'WISCONSIN':'WI',
'WYOMING':'WY',
'NEWFOUNDLAND AND LABRADOR':'NL',
'PRINCE EDWARD ISLAND':'PE',
'NOVA SCOTIA':'NS',
'NEW BRUNSWICK':'NB',
'QUEBEC':'QC',
'ONTARIO':'ON',
'MANITOBA':'MB',
'SASKATCHEWAN':'SK',
'ALBERTA':'AB',
'BRITISH COLUMBIA':'BC',
'YUKON':'YT',
'NORTHWEST TERRITORIES':'NT',
'NUNAVUT':'NU',

}

export const AppContext = React.createContext();