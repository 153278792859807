export class Judge {
    constructor(load) {
        this.id = load.id;
        this.name = load.name;
        this.number = parseInt(load.position);
        if(isNaN(this.number) ) {
            this.number = 0;
        }
        this.center = load.center;
        this.start_time = new Date(load.start_time);
        this.stop_time = new Date(load.stop_time);
        this.planned_start = new Date(load.planned_start);
        this.planned_stop = new Date(load.planned_stop);
        this.thumb = load.thumb;
        this.med = load.med;
        this.on_name = load.on_ent;
        this.symbols = load.symbols;
        this.checked_in = load.checked_in;
        
        this.schedule_id = load.schedule_id;
    }
    
    update(load) {
        this.number = parseInt(load.position);
        if(isNaN(this.number) ) {
            this.number = 0;
        }
        
        this.start_time = new Date(load.start_time);
        this.stop_time = new Date(load.stop_time);
        this.planned_start = new Date(load.planned_start);
        this.planned_stop = new Date(load.planned_stop);
        
        this.center = load.center;
        this.name = load.name;
        this.thumb = load.thumb;
        this.med = load.med;
        this.checked_in = load.checked_in;
        
        this.schedule_id = load.schedule_id;
    }
    
    simple() {
        return {
            id: this.id,
            start_time: this.start_time,
            stop_time: this.stop_time,
            planned_start: this.planned_start,
            planned_stop: this.planned_stop,
            number: this.number
        }
    }
    
    is_done() {
        return !isNaN(this.start_time.getTime() ) && !isNaN(this.stop_time );
    }
    
    is_current() {
        return !isNaN(this.start_time.getTime() ) && isNaN(this.stop_time );
    }
    
    is_upcoming() {
        return isNaN(this.start_time.getTime() ) &&
                isNaN(this.stop_time.getTime() ) &&
                !isNaN(this.planned_start.getTime() ) && 
                new Date(this.planned_start.getTime() - 90*60000) < new Date()  &&
                (isNaN(this.planned_stop.getTime() ) || this.planned_stop.getTime() > new Date() );
    }
    
    is_future() {
        return isNaN(this.start_time.getTime() ) &&
                isNaN(this.stop_time.getTime() ) &&
            !isNaN(this.planned_start.getTime() ) &&
            (isNaN(this.planned_stop.getTime() ) || this.planned_stop.getTime() > new Date() );
    }
}