export class StopWatch {
    constructor() 
    {
        this.running = false;
        this.current = 0;
        this.onTick = [];
        this.updateFunctions = [];
        this.tick = this.tick.bind(this);
        
        this.master = true;
        
        this.start_at = 0;
        
        this.timer = 0;
        this.warning_sound = false;
        this.stop_sound = false;
        
    }
    
    alter(v) {
        
        if (this.running === false) { 
            this.current = parseInt(this.current) + parseInt(v) * 1000;            
        } else {
            this.start_at = this.start_at - parseInt(v) * 1000;
        }        
        
        if(this.current < 0) {
            this.current = 0;
        }        
        window.APP.forceUpdate();
    }
    
    update() {
        if(this.master) {
            this.updateFunctions.forEach( f => f(this) );
        }
    }
    
    setTimer(timer) {
        this.timer = timer;
    }
    
    getDisplay(sounds = false) {
        
        if(this.timer != 0 && this.running) {
            
            if(this.current < (this.timer - 15) * 1000 ) {
                this.warning_sound = false;
            }
            if(this.current > (this.timer - 15) * 1000  && this.warning_sound == false) {
                this.warning_sound = true;
                window.APP.sound_manager.playWarning();
            }   
            
            
            if(this.current < (this.timer) * 1000 ) {
                this.stop_sound = false;
            }
            if(this.current > (this.timer) * 1000  && this.stop_sound == false) {
                this.stop_sound = true;
                window.APP.sound_manager.playStop();
            }   
        }
        
        let s = this.current / 1000;
        let seconds = Math.floor(s % 60);
        let min = Math.floor(s / 60.0)
        return min + ':' + ( (seconds < 10)? '0' : '' ) + seconds;
    }
    
    tick() {
        if (this.running === false) {
            return;
        }
        var d = new Date();
        this.current = (d.getTime() - this.start_at);
        
        
        window.APP.forceUpdate();        
        this.pTick();
    }
    
    
    color() {
        if(this.stop_sound) {
            return 'red';
        }
        
        if(this.warning_sound) {
            return 'yellow';
        }
        
        return 'white';
    }
    
    
    pTick() {
        let s = this;
        setTimeout(function(){ s.tick(); }, 100);
    }
    
    sync(run, val) {
        this.current = parseInt(val);
        if(run == 'true') {
            var d = new Date();
            this.start_at = d.getTime() - this.current;
            this.running = true;
            this.pTick();
        } else {
            this.running = false;
        }
        this.current = parseInt(val);
        
        window.APP.forceUpdate();
    }
    
    start() {
        this.running = true;
        window.APP.forceUpdate();
        this.pTick();
        this.update();
        var d = new Date();
        this.start_at = d.getTime() - this.current;
    }
    
    stop() {
        var d = new Date();
        if(this.running && this.start_at > 0) {
            this.current = (d.getTime() - this.start_at); 
        }
        this.running = false;
        
        window.APP.forceUpdate();
        this.update();
    }
    
    reset() {
        this.running = false;
        this.current = 0;
        window.APP.forceUpdate();
        this.update();
    }
    
    subFunction(f) {
        this.updateFunctions.push(f);
    }
}


export class Timer {
    constructor(initial) 
    {
        this.running = false;
        this.current = initial * 1000;
        this.start_value = initial;
        
        this.start_at = null;
        
        this.onTick = [];
        this.updateFunctions = [];
        
        this.master = true;
        this.force_master = false;;
        
        this.tick = this.tick.bind(this);
        this.start = this.start.bind(this);
        this.stop_sound = false;
        
    }
    
    sync(run, val) {
        this.current = parseInt(val);
        
        if(run == 'true') {
            this.running = true;
            this.pTick();
            
            var d = new Date();
            this.start_at = (d.getTime() - (this.start_value*1000 - this.current) );
            
            
            this.running = true;
            this.pTick();
        } else {
            this.running = false;
        }
        
        
        window.APP.forceUpdate();
    }
    
    update() {
        if(this.master) {
            this.updateFunctions.forEach( f => f(this) );
        }
    }
    
    alter(v) {
        
        if (this.running === false) { 
            this.current = parseInt(this.current) + parseInt(v) * 1000;            
        } else {
            this.start_at = this.start_at + parseInt(v) * 1000;
        }
        
        
        if(this.current < 0) {
            this.current = 0;
        }
        
        window.APP.forceUpdate();
    }
    
    getDisplay(sounds = false) {
        let s = Math.ceil(this.current / 1000);
        let seconds = Math.ceil(s % 60);
        let min = Math.floor(s / 60.0);
        
        if(sounds) {            
            if(this.current > 0 ) {
                this.stop_sound = false;
            }
            if(this.current == 0  && this.stop_sound == false) {
                this.stop_sound = true;
                window.APP.sound_manager.playStop();
            }   
        }
        
        return min + ':' + ( (seconds < 10)? '0' : '' ) + seconds;
    }
    
    tick() { 
        if (this.running === false) { 
            return;
        }
        
        var d = new Date();
        this.current = this.start_value * 1000 - (d.getTime() - this.start_at);
        
        window.APP.forceUpdate();
        
        if(this.current <= 0) {
            this.current = 0;
            this.end();
            return;
        }
        
        this.pTick();
        
        
    }
    
    end() {
        this.running = false;
        window.APP.forceUpdate();
        this.update();
    }
    
    pTick() {
        let s = this;
        setTimeout(function(){ s.tick(); }, 200);
        s.left = 1000;
    }
    
    start() {
        
        this.running = true;
        var d = new Date();
        this.start_at = (d.getTime() - (this.start_value*1000 - this.current) );
        window.APP.forceUpdate();
        this.pTick();
        this.update();
    }
    
    stop() {
        var was_running = this.running;
        this.running = false;        
        window.APP.forceUpdate();
        this.update();
        
    }
    
    setTimer(time) {
        this.start_value = parseInt(time);
    }
    
    reset() {
        this.running = false;
        this.current = this.start_value * 1000;
        window.APP.forceUpdate();
        this.update();
    }
    
    subFunction(f) {
        this.updateFunctions.push(f);
    }
    
    color() {
        if(this.current == 0) {
            return 'red';
        }
        
        if(this.current < 15000) {
            return 'yellow';
        }
        
        return 'white';
    }
}