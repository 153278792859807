import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import postMaster from '../logic/postmaster'
import {StopWatch} from '../logic/clocks';
import {customRandom, convertToKgs} from '../functions';
import { AppDomain, animatedIntros, animatedLeftover, stateABB} from '../constants';

export class Standing {
    constructor(id) {
        this.id = id;
        this.name = '';
        this.status = 0;
        this.checked_in = false;
        this.reg_check = false;
        this.scores = [];
        this.up_scores = [];
        this.ordered = 0;
        this.image = '';
        this.weight = '';
        this.height = '';
        this.disq = false;
        this.seeding = 'D'
        this.modal  = false;
        this.age = 0;
        this.after = null;
        this.preceding = null;
        this.place = 0;
        this.photo = '';
        this.photoL = '';
        this.temp_score = 0;
        this.temp_place = 0;
        this.temp_ordered = 0;
        
        this.instructor = '';
        
        this.child_division_set_to = '';
        
        this.status = 1;
        
        this.move = false;
        this.remove_restrictions = null;
        
        this.pri_bye = 0;
        
        this.inputs = [];
        this.members = [];
        
        this.is_blocked = 'No';
        this.blocked_reason = '';
        this.blocked_symbol = '';
       
        this.amimateWinner='animated repeat-3 slow delay-4s '+  animatedLeftover[customRandom(animatedLeftover.length)];
        this.amimateWinnerIn='animated slower ' +  animatedIntros[customRandom(animatedIntros.length)];
        
        this.winner_screen_started = false;
        this.winner_screen_force_time = 10;
        this.winner_screen_forced = true;
        
    //    this.winner_screen_force_time = -1;
    //    this.winner_screen_forced = false;
        
        this.stopWatch = new StopWatch();
        this.country = '';
        this.country_name = '';
        
        this.state_name = '';
        this.city = '';
        
        this.rank = '';
        
        this.schoolteams = [];
        this.division = null;
        this.members = [];
        
        this.stopWatch.updateFunctions.push(
                (watch) => 
               postMaster.toSend('/tournament-standing/'+ this.id +'/tick/' + watch.current + '/' + watch.running)
                        );
                
                
        this.profile_data = null;
    }
    
    
    getTeamCompare() {
        return <span>{this.getTeam()} {this.getTeam() != this.team && this.team != '' && this.team != null && <span>(Provided: {this.team})</span>}</span>;
    }
    
    getTournamentNotes() {
        if(this.profile_data != null) {
            if(this.profile_data.tournament_notes != null && this.profile_data.tournament_notes != '') {
                return this.profile_data.tournament_notes;
            }
        }
        return this.comm_notes;
    }
    
    getProfileNotes() {
        if(this.profile_data != null) {
            if(this.profile_data.profile_notes != null) {
                return this.profile_data.profile_notes;
            }
        }
        return '';
    }
    
    getTeam() {
        if(this.profile_data != null) {
            if(this.profile_data.team != '' && this.profile_data.team != null) {
                return this.profile_data.team;
            }
        }
        return this.team;
    }
    
    getBio() {
        if(this.profile_data != null) {
            if(this.profile_data.bio != null) {
                return this.profile_data.bio;
            }
        }
        return '';
    }
    
    getKG() {
        return  convertToKgs(this.weight);
    }
    
    getWeightString() {
        if(this.weight == 0) return '';
        
        var weight_string = '';
        
        if(window.APP.uses_kg) {
            weight_string = <OverlayTrigger placement={'top'} overlay={<Tooltip>{this.weight + ' lb'}</Tooltip> }><span>{this.getKG()} kg</span></OverlayTrigger>;
        } else {
            weight_string =  <OverlayTrigger placement={'top'} overlay={<Tooltip>{this.getKG() + ' kg'}</Tooltip> }><span>{this.weight} lb</span></OverlayTrigger>;
        }
        
        return weight_string;  
    }
    
    getWeightSimple() {
        if(this.weight == 0) return '';
        
        var weight_string = '';
        
        if(window.APP.uses_kg) {
            weight_string = this.getKG() + ' kg';
        } else {
            weight_string = this.weight+ ' lb';
        }
        
        return weight_string;  
    }
    
    getCountry() {
        if(this.profile_data != null) {
            if(this.profile_data.country != '' && this.profile_data.country != null) {
                return this.profile_data.country;
            }
        }
        return this.country;
    }
    
    
    getCountryName() {
        if(this.profile_data != null) {
            if(this.profile_data.country_name != '' && this.profile_data.country_name != null) {
                return this.profile_data.country_name;
            }
        }
        return this.country_name;
    }
    
    getCountryCompare() {
        return <span>{this.getCountryName()} {this.getCountryName() != this.country_name && this.country_name != '' && this.country_name != null && <span>(Provided: {this.country_name})</span>}</span>;
    }
    
    getName() {
        if(this.profile_data != null) {
            if(this.profile_data.name != '' && this.profile_data.name != null) {
                return this.profile_data.name;
            }
        }
        return this.name;
    }
    
    getNameCompare() {
        return <span>{this.getName()} {this.getName() != this.name && this.name != '' && this.name != null && <span>(Provided: {this.name})</span>}</span>;
    }
    
    getState() {
        if(this.profile_data != null) {
            if(this.profile_data.state_name != '' && this.profile_data.state_name != null) {
                return this.profile_data.state_name;
            }
        }
        return this.state_name;
    }
    
    getStateCompare() {
        return <span>{this.getState()} {this.getState() != this.state_name && this.state_name != '' && this.state_name != null && <span>(Provided: {this.state_name})</span>}</span>;
    }
    
    getInstructor() {
        if(this.profile_data != null) {
            if(this.profile_data.instructor != '' && this.profile_data.instructor != null) {
                return this.profile_data.instructor;
            }
        }
        return this.instructor;
    }
    
    getCity() {
        if(this.profile_data != null) {
            if(this.profile_data.city != '' && this.profile_data.city != null) {
                return this.profile_data.city;
            }
        }
        return this.city;
    }
    
    getCityCompare() {
        return <span>{this.getCity()} {this.getCity() != this.city && this.city != '' && this.city != null && <span>(Provided: {this.city})</span>}</span>;
    }
    
    getShortState() {
        if(!this.state_name) {
            return;
        }
        var state = this.state_name.toUpperCase();
        if(stateABB[state] ) {
            return stateABB[state];
        } 
        return '';
    }
    
    getCheckedIn() {
        return this.members.filter(
                function (stand) {
                    return stand.checked_in == true;
                }
         );
    }
    
    getMember(id) {
        return  this.members.find(element => element.id == id);
    }
    
    setPlace(p) {
        this.place = p;
    }

    winnerScreenRunning() {     
        if(this.winner_screen_started == false) {
            this.winner_screen_started = true;
            this.winnerScreenTick();            
        }
    }
    
    winnerScreenTick() {
        this.winner_screen_force_time = this.winner_screen_force_time - 1;
        var c = this;
        
        if(this.winner_screen_force_time == 0) {
            this.winner_screen_forced = false;
            window.APP.forceUpdate();
            return;
        }
        
        setTimeout(function(){ c.winnerScreenTick(); }, 1000 );
    }
    
    showScores() {
        var show = true;
        const t = this;
        this.scores.forEach( (value, index, self2) => {
            if(!t.shouldShowScore(value.og_score) ) {
                show = false;
            }
        });
        return show;
    }
    
    
    shouldShowScore(s) {
        if(this.division.score_rules == 'int') {
            return true;
        }
        
        if(parseFloat(s) == 10.0 || parseFloat(s) == 0.0) {
            return true;
        }
        
        if(s.toString().length == 4) {
            return true;
        }
    }

    setTempScore(value, type = null) {
        let f = parseFloat(value);
        if(type) {
            if (type != 'int') {
                if(f > 10.0)f = f / 10.0;
                f =  Math.round(f * 100.0) / 100.0;
            }
        } else {
            if (window.APP.ring.current_division.score_rules != 'int') {
                if(f > 10.0)f = f / 10.0;
                f =  Math.round(f * 100.0) / 100.0;
            }
        }
        
        if(isNaN(f) ) {
            f = 0;
        }
        
        var og = value.toString();
        var n = f.toString();
         
        if(type) {
            if (type != 'int') {
                while(og.replace(".", "").length > n.replace(".", "").length && og.length > 2 && n.length < 4 ) {
                    n = n + '0';
                }
                if(parseFloat(n) > 10) {
                    n = '10';
                }
            }
        }
        
        this.temp_score = n;
        
        window.APP.forceUpdate();
    }

    load(element, division, load_scores = true) {
        
        let c=this;
    
        this.division = division;
    
        this.name = element.name;
        if(element.checked_in == 1) {
            this.checked_in = true;
        } else {
            this.checked_in = false;
        }
        this.reg_check = element.reg_checked_in;
        this.scores = [];
        this.up_scores = [];
        
        if(window.APP.ring) {
            this.resetScores(window.APP.ring.getNumJudges() );
        }
        
        this.check_num = 0;
        this.status = element.status;
        this.ordered = parseInt(element.ordered);
        this.place = parseInt(element.place);
        this.image = '';
        this.weight = element.weight;
        this.height = element.height;
        this.disq = false;
        this.seeding = element.seeding;
        this.age = element.age;
        this.stopWatch.current = parseInt(element.stopwatch);
        this.photo = element.photo;
        this.photoL = element.photoL;
        this.country = element.country;
        this.country_name = element.country_name;
        
        this.instructor = element.instructor;
        
        if(division.score_prefill) {
            this.temp_score = division.score_prefill;
        }
        
        this.state_name = element.state_name;
        this.city = element.city;
        this.gender = element.gender;
        this.rank = element.rank;
        
        this.inputs = element.inputs;
        
        if(this.country != '') {
            this.country = AppDomain + this.country
        }
        
        this.is_blocked = element.is_blocked;
        this.blocked_reason = element.blocked_reason;
        this.blocked_symbol = element.blocked_symbol;
        
        if(element.scores && load_scores) {
            element.scores.forEach(score => {
                if(score.key < 0) {
                    c.up_scores[score.key * -1] = {judge:score.key,score:score.score,drop:score.drop,og_score:score.og_score};
                } else {
                    c.scores[score.key] = {judge:score.key,score:score.score,drop:score.drop,og_score:score.og_score};
                }
            });
        }
        
        if(element.members) { 
            element.members.forEach(sta => {
                let standing = new Standing(sta.id);
                standing.load(sta, c);
                this.members.push(standing)
            });
        }
        
        this.schoolteams = [];
        this.team = element.team;
        
        this.remove_restrictions = element.remove_restrictions;
        
        if(element.team != '' && element.team != null) {
            this.schoolteams.push(element.team)
        }
        if(element.schools) {
            element.schools.forEach(sta => {
               this.schoolteams.push(sta)
            });
        }
        
    }
    
    getTransparentLarge(){
        if(this.profile_data != null) {
            if(this.profile_data.transparent_photoL != '' && this.profile_data.transparent_photoL != null) {
                return this.profile_data.transparent_photoL;
            }
        }
        return null;
    }
    
    getGeneralLarge(){
        if(this.profile_data != null) {
            if(this.profile_data.general_photoL != '' && this.profile_data.general_photoL != null) {
                return this.profile_data.general_photoL;
            }
            return this.getTransparentLarge();
        }
        return null;
    }
    
    getBestPhoto() {
        var best = this.getGeneralLarge();
        if(best == '' || best == null) {
            best = this.photoL;
        }
        return best;
    }
    
    update(element) {
        
        let c=this;
    
        this.name = element.name;
        this.status = element.status;
        
        if(element.checked_in == 1) {
            this.checked_in = true;
        } else {
            this.checked_in = false;
        }
        
        this.is_blocked = element.is_blocked;
        this.blocked_reason = element.blocked_reason;
        this.blocked_symbol = element.blocked_symbol;
        
        this.reg_check = element.reg_checked_in;
        
        this.ordered = parseInt(element.ordered);
        this.place = parseInt(element.place);
        this.rank = element.rank;
        this.weight = element.weight;
        this.height = element.height;
        this.seeding = element.seeding;
        this.instructor = element.instructor;
        this.age = element.age;
        this.photo = element.photo;
        this.photoL = element.photoL;
        this.country = element.country;
        this.country_name = element.country_name;
        this.remove_restrictions = element.remove_restrictions;
        this.inputs = element.inputs;
        
        if(this.country != '') {
            this.country = AppDomain + this.country;
        }
    }
    
    getNumberScores() {
        return this.scores.length;
    }
    
    getNumberUpScores() {
        return this.up_scores.length;
    }
    
    getTotalNumberScores() {
        return this.scores.length + this.up_scores.length;
    }
    
    resetScores(number) {
        this.scores = [];        
        for (let step = 0; step < number; step++) {
            if(this.division.score_prefill > 0) {
                this.scores[step] = {judge:step, score:this.division.score_prefill, drop:0,og_score: this.division.score_prefill };
            } else {
                this.scores[step] = {judge:step, score:0, drop:0,og_score:0 };
             }
        }
    }
    
    getScore(number) {
        if(number < 0) {
            return this.up_scores[number*-1]
        } else {
            return this.scores[number]
        }        
    }
    
    getTextScore(number) {
        try {
        if(!this.scores) return '';
        
        if(number >= this.scores.length) return '';
        
        return this.scores[number].score;
    } catch(x)  {
        return '';
    }  
    }
    
    getScores() {
        return this.scores;
    }
    
    getUpScores() {
        return this.up_scores;
    }
    
    fixScores(division) {
        
        function orderasc(a, b){
            return a.og_score - b.og_score;
        }
        
        function orderdesc(a, b){
            return b.og_score - a.og_score;
        }
        
        function orderjudge(a, b){
            return a.judge - b.judge;
        }
        var allscores = this.scores;
        var sorted_scores = this.scores.sort(orderasc);
        
        var sorted_scores_deviation = this.scores.filter(function(itm){
            console.log( itm.judge )
            return itm.judge > -1;
            
          }).sort(orderasc);
          
          console.log(sorted_scores);
          console.log(sorted_scores_deviation);
          
        var distance = parseFloat(division.deviation);
        var c = this;
        
        var use_deviation = true;
        var use_drop = true;
        
        if(parseInt(division.deviation_below_drop_above) > 0) {
            var num_judges = division.ring.getNumJudges();
            var switch_on = parseInt(division.deviation_below_drop_above);
            if(num_judges <= switch_on) {
                use_deviation = true;
                use_drop = false;
            }
            if(num_judges > switch_on) {
                use_deviation = false;
                use_drop = true;
            }
        }
        
        //DEVIATION
        if(use_deviation && distance > 0.0 && sorted_scores_deviation.length %2 == 1) {
           var median = parseFloat(sorted_scores_deviation[ Math.floor(sorted_scores_deviation.length/2.0)].og_score)
           this.scores.forEach(score => {
                if(score.judge < 0) return;
               
               
                var og = parseFloat(score.og_score);
                
                if(og <  parseFloat(division.min_score)  &&  og > 0 && og !=  parseFloat(c.division.score_dq) ) {
                    og = parseFloat(division.min_score);
                }

                if(og > parseFloat(division.max_score)) {
                    og = parseFloat(division.max_score);
                }
                
                if(og > median && og - median > distance) {
                    score.score = median + distance;
                }                
                if(og < median && median - og > distance) {
                    score.score = median - distance;
                } 
                
                score.score =  Math.round(score.score * 100.0) / 100.0;
            });
        }
        
        for (let step = 0; step < this.scores.length; step++) {
            this.scores[step].drop = 0;
        }
        
        if(use_drop) {
            for (let step = 0; step < division.drop_low && step < this.scores.length; step++) {
                this.scores[step].drop = 1;
            }

            sorted_scores = this.scores.sort(orderdesc);


            var dropped_low = 0;
            for (let step = 0; dropped_low < division.drop_high && step < this.scores.length; step++) {
                if(this.scores[step].drop != 0) {
                    continue;
                }
                dropped_low = dropped_low + 1;
                this.scores[step].drop = 1;
            }
        }
        this.scores.sort(orderjudge);
    }
    
    dropScore(num, value) {
        this.scores[num].drop = value;
    }
    
    getAllScoreTotal() {
        let total = 0;
    
        this.scores.forEach( (value, index, self2) => {
           total += parseFloat(value.score);
        })
        
        this.up_scores.forEach( (value, index, self2) => {
           total += parseFloat(value.score);
        })
        
        if(window.APP.ring.current_division.score_rules != 'int') {
            total = Math.round(total * 100.0) / 100.0;
        }
        
        return total;
    }
    
    getScoreTotal(div = null) {
        let total = 0;
        if(div == null) {
            div = window.APP.ring.current_division;
        }
    
        this.scores.forEach( (value, index, self2) => {
           if(value.drop == 1 || value.drop == '1') {} else {
               if(div.score_rules != 'int') {
                    total += Math.round(parseFloat(value.score) * 100.0) / 100.0;
                } else {
                    total += parseFloat(value.score);
                }
            }
        });
        
        this.up_scores.forEach( (value, index, self2) => {
           if(value.drop == 1 || value.drop == '1') {} else {
               if(div.score_rules != 'int') {
                    total += Math.round(parseFloat(value.score) * 100.0) / 100.0;
                } else {
                    total += parseFloat(value.score);
                }
            }
        });
        
        if(div.score_rules != 'int') {
            total = Math.round(total * 100.0) / 100.0;
        }
        
        return total;
    }
    
    fixScore(number, value) {
        this.scores[number].score = value;
    }
    
    fixUpScore(number, value) {
        this.up_scores[number].score = value;
    }
    
    setScore(number, value) {
        let c = this;
        
        if(!this.scores) {
            this.scores = [];
        }
        
        if(! (this.scores[number] ) ) { 
            this.scores[number] = {judge:number,score:c.division.score_prefill,drop:0,og_score:c.division.score_prefill};
        }
        
        this.scores[number].og_score = value;
        
        this.scores.forEach(s => {
            s.drop = 0;
            var d_score = s.og_score;
            
            if(d_score <  parseFloat(c.division.min_score) &&  d_score > 0 && d_score !=  parseFloat(c.division.score_dq) ) {
                d_score = parseFloat(c.division.min_score);
            }
            
            if(d_score > parseFloat(c.division.max_score)) {
                d_score = parseFloat(c.division.max_score);
            }
            
            s.score = d_score;
        });
        
        
    }
    
    setUpScore(number, value) {
        if(!this.up_scores) {
            this.up_scores = [];
        }
        
        if(! (this.up_scores[number] ) ) { 
            this.up_scores[number] = {judge:number,score:0,drop:0,og_score:0};
        }
        
        this.up_scores.forEach(s => {
            s.drop = 0;
            s.score = s.og_score;
        });
        this.up_scores[number].score = value;
        this.up_scores[number].og_score = value;
    }
    
   
    
    check(value) {
        this.checked_in = value;
    }
    
    changeModal(value) {
        this.modal = value;
    }
    
    setSeeding(value) {
        this.seeding = value;
    }
    
    getCompetingWindow(upcomingList, div) {
        var t = this;
        
        return function(styling ) {
        const adj = styling.adj;
        if(styling.display_type != 'green') {
        return <Container style={{ width:'100%', maxWidth:'100%' }}>
            <Row  style={{ textAlign:'center' }}>
                <Col xs={6}>
                    <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                       <Card.Header style={{ fontSize:'175%' }} >{t.ordered}. {t.name} {t.country != '' && <img style={{ 'width':((50 * adj) + 'px') }} 
            src={t.country}   ></img> }
                       </Card.Header>
                        <Card.Body >
                            {t.status == 4 && <h2 style={{fontSize:'200%' }} ><Badge pill variant="danger">DQ</Badge></h2>}                        
                            {t.photo != '' &&  <img style={{ width: ((150 * adj) + 'px') }} src={t.photo} /> }                                 
                            {t.status != 4 && <h2 style={{fontSize:'200%', color:t.stopWatch.color() }} >{t.stopWatch.getDisplay()}</h2>}                                
                        </Card.Body>
                  </Card>
                  </Col>
                  
                  <Col xs={6}>
                    <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                       <Card.Header style={{ fontSize:'150%' }} >Upcoming
                       </Card.Header>
                        <Card.Body >
                        <Table variant="dark">
                            {upcomingList(styling)}
                            </Table>
                        </Card.Body>
                  </Card>
                  </Col>
                  
                </Row>
            
                                                                        
            </Container>
                       } else {
                           return <Container className={(styling.not_body == 1)? 'animated slideOutLeft' : 'animated slideInLeft' } style={{ width:'80%', maxWidth:'80%', position:'absolute',bottom:'10px',left:'0' }}>
            <Row  style={{ textAlign:'center' }}>
                <Col xs={1}></Col>
                <Col xs={3} >
                    {t.photo != '' && !styling.hide_title_images &&  <img style={{ width:'100%' }} src={t.photoL} /> }
                </Col> 
                   
            </Row>
            <Row  style={{ textAlign:'center' }}>
                <Col xs={1} style={{padding:'0', backgroundColor:'#000',display:'flex',alignItems:'center'}}>
                    <img style={{ 'width':'60%',marginLeft:'auto',marginRight:'auto' }} 
                            src={AppDomain + "/app/white_M_icon.png"}   ></img>
                </Col> 
                  
                <Col xs={6} style={{padding:'0'}}>
                    <Card bg='dark' 
                    style={{ width:'100%', borderRadius:'0'  }}
                        text= 'light'
                      >
                       <Card.Body  style={{ fontSize:'150%' }} >{!styling.hide_standing_order && <span>{t.ordered}.</span>} {t.name} {t.country != '' && !styling.hide_flags && <img style={{ 'height':'1em' }} 
            src={t.country}   ></img> }                    
                        </Card.Body>
                  </Card>
                  </Col> 
                </Row>
                
                {!( (t.team == '' || t.team == null) && (t.city == '' || t.city == null) && (t.state_name == '' || t.state_name == null) && (t.age == null || t.age == '')  ) && <Row  style={{ textAlign:'center' }}>
                <Col xs={7} style={{padding:'0'}}>
                    <Card
                    style={{ width:'100%', borderRadius:'0'  }}
                      >
                       <Card.Body  style={{ fontSize:'90%',color:'#212529' }} >
                            <Container>
                            <Row>
                            <Col xs={5}>{t.team}</Col>
                            <Col xs={4}>{t.city}{t.city != '' && t.state_name != '' && <span>,</span>} {t.state_name}</Col>
                            <Col xs={3}>
                            { t.age && t.age != '' &&  <span>{t.age} {t.age != '' &&<span>Yrs</span>}</span> }
                            </Col>
                            </Row>
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col> 
                  
                </Row>}
            
                                                                        
            </Container>
                       }
        }
    }
    
    getScoringWindow(div) { 
        return function(styling) {
        const adj = styling.adj;        
        if(styling.display_type != 'green') {
            
            var on = 0;
            var scores_on_line = 0;
            var max_scores_on_line = Math.min(6, this.scores.length);
            var each = Math.floor(12 / max_scores_on_line);
            const scores = this.scores.map((score) => {

                        return   <Col xs={each} style={{ fontSize:'150%', marginTop:((15 * adj) + 'px') }}>
                        <Card bg='dark' text='light'>
                        { window.APP.ring.judge_display == 1 &&
                        <Card.Header >{window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb != '' && <img style={{ height: ((50 * adj) + 'px') }} src={window.APP.ring.getJudge(parseInt(score.judge) + 1).thumb} ></img>} { window.APP.ring.getJudge(parseInt(score.judge) + 1) .name }</Card.Header>
                                                        }
                        <Card.Body class="text-center" style={{ textDecoration: (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none', fontSize:'250%' }}>

                                {this.shouldShowScore(score.og_score) && <span>{div.niceFormatScore(parseFloat(score.score) )}</span>}

                            { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                            <i class="fas fa-long-arrow-alt-up text-success"></i>                                    
                            }

                            { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                            <i class="fas fa-long-arrow-alt-down text-danger"></i>                                    
                            }
                        </Card.Body>
                        </Card>
                        </Col>;
                       }
                     );

            var display_scores = [];

            for (let step = 0; step < scores.length; step++) {
                if(step % 6 == 0) { //we are on a new line
                    scores_on_line = Math.min(6, scores.length - step);
                    
                    if((12 - ( scores_on_line * each)) / 2 > 0) {
                        display_scores.push( <Col style={{ marginTop:((15 * adj) + 'px') }} xs={ Math.floor((12 - ( scores_on_line * each)) / 2) }></Col> );
                    }
                    //add an offset for half of the rest
                }
                
                if(scores.length % 3 == 1 && scores.length - 1 == step) {
                  //  display_scores.push( <Col style={{ marginTop:((15 * adj) + 'px') }} xs={4}></Col> );
                }

                if(scores.length % 3 == 2 && scores.length - 2 == step) {
                  //  display_scores.push( <Col style={{ marginTop:((15 * adj) + 'px') }} xs={2}></Col> );
                }

                display_scores.push( scores[step] );
            }

            return <Container style={{ width:'100%', maxWidth:'100%' }}>         
                <Row  style={{ textAlign:'center' }}>
                    <Col xs={12}>
                        <Card bg='dark'
                        style={{ margin:'1%', width:'98%'  }}
                            text= 'light'
                          >
                          <Card.Header ><span  style={{ fontSize:'175%'  }}>{this.ordered}. {this.name} {this.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</span> {this.country != '' && <img style={{ 'width':((50 * adj) + 'px'), marginLeft:((10 * adj) + 'px'), position:'relative', bottom:((5 * adj) + 'px') }} 
                src={this.country}   ></img> }
                           </Card.Header>
                            <Card.Body >
                            <Container style={{ width:'100%', maxWidth:'100%' }}>
                                    <Row>
                                    {display_scores}                                      
                                    </Row>                                        
                                    </Container>
                            {this.photo != '' &&  <img style={{ width: ((150 * adj) + 'px'), marginBottom:((20 * adj) + 'px') }} src={this.photo} /> }
                                    
                            </Card.Body>
                      </Card>
                      </Col>
                    </Row>                                             
                </Container>;
            } else {
                
                var display_scores = [];


                var on = 0;
                var scores_on_line = 0;
                var max_scores_on_line = Math.min(6, this.scores.length);
                var each = Math.floor(12 / max_scores_on_line);
                const scores = this.scores.map((score) => {

                        return   <Col xs={1} style={{ fontSize:'200%',display:'flex',alignItems:'center', marginTop:((3 * adj) + 'px'), textDecoration: (score.drop == 1 || score.drop == '1' ) ? 'line-through' : 'none' }}>
                            <div style={{ width:'100%' }}>
                                    {div.niceFormatScore(parseFloat(score.score) )}

                                    { div.deviation > 0 && parseFloat(score.og_score) < parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-up text-success"></i>                                    
                                    }

                                    { div.deviation > 0 && parseFloat(score.og_score) > parseFloat(score.score)  && score.score != score.og_score   && div.niceFormatScore(parseFloat(score.score) ) != div.niceFormatScore(parseFloat(score.og_score) ) &&                                    
                                    <i class="fas fa-long-arrow-alt-down text-danger"></i>                                    
                            }</div>
                        </Col>;
                       }
                     );

            
            
            for (let step = 0; step < scores.length; step++) {
                if(step % 6 == 0) { //we are on a new line
                    scores_on_line = Math.min(6, scores.length - step);
                    
                    if((12 - ( scores_on_line * each)) / 2 > 0) {
                  //      display_scores.push( <Col style={{ marginTop:((15 * adj) + 'px') }} xs={ Math.floor((12 - ( scores_on_line * each) ) / 2) }></Col> );
                    }
                    //add an offset for half of the rest
                }

                display_scores.push( scores[step] );
            }

            return <div><Container style={{ padding:'1%', width:'98%', maxWidth:'100%', position:'absolute',bottom:'0px' }}  className={(styling.not_body == 1)? 'animated slideOutDown' : 'animated slideInUp' }>         
                <Row style={{ paddingLeft:'15px', display:'none' }}>
                    <Col xs={1} style={{padding:'0', backgroundColor:'#000', textAlign:'center', borderRadius:'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'  }}>
                        
                    </Col>
                </Row>
                <Row  style={{ textAlign:'center' }}>
                    <Col xs={12}>
                        <Card bg='dark'
                            text= 'light'
                          >
                          <Card.Header >
                          <Container style={{ maxWidth:'100%' }}>
                          <Row>
                          <Col xs={1}>
                                <img style={{ width:'50%',marginBottom:'-25px' }} 
                                src={AppDomain + "/app/white_M_icon.png"}   ></img>
                            </Col>
                            <Col xs={10}>
                                       <span  style={{ fontSize:'150%', paddingTop:'1px',paddingBottom:'1px'  }}>{this.country != '' && !styling.hide_flags &&  <img style={{ 'height':'1em', marginLeft:((10 * adj) + 'px'), position:'relative', bottom:((5 * adj) + 'px') }} 
                            src={this.country}   ></img> } {!styling.hide_standing_order && <span>{this.ordered}.</span>} {this.name}</span> 
                            </Col>
                            </Row>
                            </Container>
                           </Card.Header>
                            <Card.Body style={{ paddingTop:'1px',paddingBottom:'1px' }}>
                                    <Container style={{ width:'100%', maxWidth:'100%' }}>
                                    <Row>
                                    
                                     
                                     
                                    {display_scores}
                                    {display_scores.length < 8 && <Col xs={8 - display_scores.length}></Col> }
                                        
                                        <Col xs={2} style={{ fontSize:'200%',display:'flex',alignItems:'center', marginTop:((3 * adj) + 'px') }}>
                                            <div style={{ width:'100%' }}>
                                            <strong>{div.niceFormatScore(parseFloat(this.getScoreTotal(div) )   )}</strong>                              
                                            </div>
                                        </Col>
                                        <Col xs={2} style={{ fontSize:'200%',display:'flex',alignItems:'center', marginTop:((3 * adj) + 'px') }}>
                                            <img
                                                src={window.APP.ring.image}
                                                width={'90%' }
                                                alt="tournament logo"
                                              />
                                        </Col>
                                        

                                    </Row>                                        
                                    </Container>
                            </Card.Body>
                      </Card>
                      </Col>
                    </Row>                                             
                </Container>
                </div>;
            }
        }.bind(this);
    }
    
}