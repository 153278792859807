import React, {useRef} from 'react';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {AppContext, AppDomain, stripeKey} from '../constants';
import postMaster from '../logic/postmaster';

export class TimeDisplay  extends React.Component {
    
    constructor(props) {
        super(props);   
        
        this.running = false;
        this.current_time = new Date;
    }
    
    componentDidMount(){       
        this.running = true;
        this.beat();
    }
    
    beat() {
        if(this.running) {
            var t = this;
            t.current_time = new Date;
            t.setState({ current_time:  t.current_time });
            setTimeout(function() {t.beat(); },1000);
        }
    }
   
    componentWillUnmount() {
       this.running = false;
    }
    
    
    render() { 
        return <span>{this.current_time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</span>;
    }
    
}