import React from 'react';

import postMaster from './logic/postmaster';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


var isOnline = 0; // 0 is likely
export function goesOffline(startuppos = false) {
    if(isOnline == -1) {
        return;
    }
    if(isOnline == 0 && startuppos) {
        
    } else {
        isOnline = -1;
        if(window.APP) {
            window.APP.connection_status = 0;
            window.APP.reset();
        }
        
    }
    
}

var starting_youtube = false;
var onYoutubeReady = [];
var youtubeIsReady = false;

export function convertToLbs(kgs) {
    return  Math.round( (kgs * 2.2) * 10) / 10.0;
}

export function convertToKgs(lbs) {
    return  Math.round( (lbs / 2.2) * 10) / 10.0;
}

export function getTimeString(weightLBs) {
    if(weightLBs == null || weightLBs == undefined || weightLBs == 0) return '';

    var weight_string = '';

    if(window.APP.uses_kg) {
        weight_string = <OverlayTrigger placement={'top'} overlay={<Tooltip>{weightLBs + ' lb'}</Tooltip> }><span>{convertToKgs(weightLBs)} kg</span></OverlayTrigger>;
    } else {
        weight_string =  <OverlayTrigger placement={'top'} overlay={<Tooltip>{ convertToKgs(weightLBs) + ' kg'}</Tooltip> }><span>{weightLBs} lb</span></OverlayTrigger>;
    }

    return weight_string;  
}

export function prepYoutube(x = null) {
    //only do this once
    if(starting_youtube) {
        if(x) {
            whenYoutubeReady(x)
        }
        return;
    }
    starting_youtube = true;
    
    //TAKEN FROM YOUTUBE https://developers.google.com/youtube/iframe_api_reference
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
    //can pass in the player function if ready
    if(x) {
        whenYoutubeReady(x)
    }
}

export function whenYoutubeReady(x) {
    if(youtubeIsReady) {
        x();
    }
    onYoutubeReady.push(x);
}
 
 
 window.onYouTubeIframeAPIReady  = function() {
    youtubeIsReady = true;
    onYoutubeReady.forEach((x)=>{x(); });
}
 
export function goesOnline() {
    
    if(isOnline == 1) {
        return;
    }
    isOnline = 1;
    if(window.APP) {
        if(window.APP.connection_status != 2) {
            window.APP.connection_status = 1;
            window.APP.reset();
        
            if(postMaster) {
                postMaster.retry();
            }
        }
    }
}

export function customRandom(upperBound) {
    if(window.crypto) {
        return Math.abs( window.crypto.getRandomValues(new Int8Array(1))[0] ) % upperBound;
    } else {
        return Math.floor(Math.random() * upperBound);
    }
}

export function divShuffle(array, start) {
    var m = array.length, t, i;
    // While there remain elements to shuffle…
    while (m > 0) {
        // Pick a remaining element…
        i = customRandom(m);
        m--;
        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;

        array.forEach( (value, index, self2) => { 
           value.ordered = index + start;
        }) 
    }
    return array.length;
}

//FIGHTING
export function divFightShuffle(array, div) {
        array.forEach((x) => { x.pri_bye = 0; })
    
        //SEEDS
        var comp = 1;
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "E" && value.checked_in === true) ; } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "D"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "C"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "B"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "A"  && value.checked_in === true); } ), comp);
    
        var rev_order_seeds = div.simpleOrder().filter((value) => { return value.checked_in === true; }).reverse();
    
        var seeds = [];
        if(rev_order_seeds.length > 0) {
            seeds[0] = rev_order_seeds[0];
        }
        if(rev_order_seeds.length > 1) {
            seeds[1] = rev_order_seeds[1];
        }
        if(rev_order_seeds.length > 2) {
            seeds[2] = rev_order_seeds[2];
        }            
        if(rev_order_seeds.length > 3) {         
            seeds[3] = rev_order_seeds[3];
        }
        
        //pure shuffle division without seeds
        var comps_normal = div.standings.filter(function(value, index, arr){ return (seeds.filter((x)=>value.id == x.id).length == 0  && value.checked_in === true); } );
        divShuffle(comps_normal, 1)
    
        var total = array.length;
        
        var b_calc = 2;
        while(b_calc < array.length){
            b_calc = b_calc * 2;
        }
        var b_number = b_calc - array.length;
        
        
        var size_1 = (b_calc /2 ) - Math.floor(b_number / 2)  

        if(array.length == 7) {
            size_1 = 3;
        }
        
        if(array.length == 13) {
            size_1 = 7;
        }

        var pos_2 = size_1;
        var pos_4 = size_1 - 1; 

        //move first seed to first
        if(seeds.length> 0) {
            seeds[0].ordered = -1;
            seeds[0].pri_bye = 4;
        }

        //position 4th seed - alwasy last in first bracket
        if(seeds.length > 3) {
            array.filter((x)=>x.ordered >= pos_4 ).forEach((x) => {x.ordered = x.ordered + 1;});            
            seeds[3].ordered = pos_4;
            seeds[3].pri_bye = 1;
        }

          
        //position 2nd seed - always 1 after first bracket
        if(seeds.length > 1) {
            array.filter((x)=>x.ordered >= pos_2 ).forEach((x) => {x.ordered = x.ordered + 1;});
            seeds[1].ordered = pos_2;
            seeds[1].pri_bye = 3;
        }
        
        //position 3rd seed - always goes last
        if(seeds.length > 2) {
            seeds[2].ordered = 999;
            seeds[2].pri_bye = 2;
        } 
            
}

//FORMS
export async function slowShuffleDiv(array, start, number, div) {
        
        
        //FORMS
        var comp = 1;
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "E" && value.checked_in === true) ; } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "D"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "C"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "B"  && value.checked_in === true); } ), comp);
        comp = comp + divShuffle(div.standings.filter(function(value, index, arr){ return (value.seeding === "A"  && value.checked_in === true); } ), comp);

        
        
        
        window.APP.forceUpdate();
        
        if(number > 0) {
            setTimeout(function(){
                slowShuffleDiv(array, start, number -1, div)
            }, 500);
        } else {
            div.completeShuffle()
        }
        
    }
    

export function swapFights(fight1, fight2, first=false) {
    //cannot be a top - and winners of parents cannot be decided
    if (!fight1.proceeds_winner || !fight2.proceeds_winner || fight1.proceeds_winner.getWinner() ) {        
        return;
    }
    
    var is_incorrect = false;

    var upwards = null;
    
    upwards = fight1;
    while(upwards != null && !is_incorrect) {
        if(upwards.id == fight2.id) {
            is_incorrect = true;
        }
        upwards = upwards.proceeds_winner;
    }
    
    upwards = fight2;
    while(upwards != null && !is_incorrect) {
        if(upwards.id == fight1.id) {
            is_incorrect = true;
        }
        upwards = upwards.proceeds_winner;
    }
    
    

    var fight1_p = fight1.proceeds_winner;
    var fight2_p = fight2.proceeds_winner;

    if(fight1_p.id == fight2_p.id || fight1.id == fight2.id) {
        return;
    }

    if(is_incorrect) {
        alert('This is not a fight swap you can do')
        return;
    }

    var t = fight1.is_bye
    fight1.is_bye = fight2.is_bye
    fight2.is_bye = t

    


    //if fight1 - was fight 1 of the old fight
    if(fight1_p.comp1_fight && fight1_p.comp1_fight.id == fight1.id) {
        fight1_p.comp1_fight = fight2;
        if(fight2.getWinner() ) {
            fight1_p.comp1 = fight2.getWinner();
        }
    }

    //if fight 1 was fight 2 of old parent
    if(fight1_p.comp2_fight && fight1_p.comp2_fight.id == fight1.id) {
        fight1_p.comp2_fight = fight2;
        if(fight2.getWinner() ) {
           fight1_p.comp2 = fight2.getWinner();            
        }
    }


    //if fight 2 is fight 1 of parent
    if(fight2_p.comp1_fight && fight2_p.comp1_fight.id == fight2.id) {
        fight2_p.comp1_fight = fight1;
        if(fight1.getWinner() ) {
            fight2_p.comp1 = fight1.getWinner();
        }
    }

    //if fight 2 is fight 2 of parent
    if(fight2_p.comp2_fight && fight2_p.comp2_fight.id == fight2.id) {
        fight2_p.comp2_fight = fight1;
        if(fight1.getWinner() ) {
            fight2_p.comp2 = fight1.getWinner();
        }
    }

    fight1.proceeds_winner = fight2_p;
    fight2.proceeds_winner = fight1_p;
    

    window.APP.forceUpdate();
    if(first) {
        postMaster.toSend('/swap-fights/'+fight1.id+'/'+fight2.id);
    }
}    

//divisions must be sorted so all of the session must be together
export function sessionFormat(divisions, sessions_data) {
    var data = [];
    var division_index = 0;
    
    sessions_data.forEach( (value, index, self2) => { 
        while(division_index < divisions.length && divisions[division_index].schedule_id != value.id &&  
                (divisions[division_index].schedule_id == null || divisions[division_index].schedule_id == "") 
                &&  divisions[division_index].estimated_start.getTime() < value.time.getTime() )  {
            data.push({
                type:'division',
                children:null,
                value:divisions[division_index]
            }); 
            division_index = division_index + 1;
        }
        var s_data = {
                type:'session',
                children:[],
                value:value,
                incomplete:0
            };
        while(division_index < divisions.length && divisions[division_index].schedule_id == value.id )  {
            s_data.children.push({
                type:'division',
                children:null,
                value:divisions[division_index]
            }); 
            
            if(divisions[division_index].status < 3) {
                s_data.incomplete = 1;
            }
            
            division_index = division_index + 1;
        }
        data.push(s_data);
     }); 
     
     while(division_index < divisions.length )  {
            data.push({
                type:'division',
                children:null,
                value:divisions[division_index]
            }); 
            division_index = division_index + 1;
        }
        
        
    return data;
}

function searchSortArrayValue(hay, needles) {
    var found = 0;
    needles.forEach((n)=>{
        if (hay.includes(n) ) {
            found = found + 1;
        }
    });
    return found;
}

export function searchArray(array, haystackfunc, needle, sort=true) {
    if(needle == '') {
        return array;
    }
    needle = needle.toLowerCase();    
    var splits = needle.split(' ');
    var needles = [];    
    
    splits.forEach((x)=>{
        if(x != '') {
            needles.push(x);
        }
    });
    
    if(needles.length == 0) {
        return array;
    }
    
    array = array.filter((x) => {
        let hay = haystackfunc(x).toLowerCase();
        return searchSortArrayValue(hay, needles) > 0;
    });
        
    if(sort) {
        array = array.sort((a, b) => {
            let haya = haystackfunc(a).toLowerCase();
            let hayb = haystackfunc(b).toLowerCase();
            return  searchSortArrayValue(hayb, needles) - searchSortArrayValue(haya, needles);
        });
    }
    return array;    
}

export function searchDivisionHay(x) {
    return x.code+' '+x.name;
}

export function searchRingHay(x) {
    var h = x.location+' '+x.name;
    if(x.groups != null ) {
        x.forEach((y) => {
            h = h + ' ' + y;
        });
    }
    return h;
}