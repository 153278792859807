import React, { useRef,useState,useEffect }  from 'react';

import {Ring} from '../../models/ring';
import postMaster from '../../logic/postmaster';
import {AppContext, AppDomain, seeds} from '../../constants';
import {FightCall, Fight, BracketChart, FightAvCall} from '../../models/fights'
import {Standing} from '../../models/standing'
import {Division} from '../../models/division'
import {Judge} from '../../models/judge'
import {swapFights, customRandom} from '../../functions';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {MessagesDisplay, Message} from '../../models/messages';
import Badge from 'react-bootstrap/Badge';
import {StopWatch, Timer} from '../../logic/clocks';
import {sessionFormat} from '../../functions';
import { v4 as uuidv4 } from 'uuid';
import Toast from 'react-bootstrap/Toast';
import { Editor } from '@tinymce/tinymce-react';
import {TimeDisplay} from '../TimeDisplay';

class MyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.div = props.div;
    this.type = props.type;
    this.state = { value: props.initialValue ?? '' };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
        this.setState({ value: this.props.initialValue ?? '' })
    }
  }

  handleEditorChange(value, editor) {
    this.setState({ value });
  }
  
  save() { 
      postMaster.toSendData('/tournament-division/' + this.div.id + '/notes-update',{type:this.type, value:this.state.value}, false );
      this.div[this.type +'_edit'] = false;
     // this.div[this.type] = this.state.value;
      window.APP.reset();
  }

  render() {
    return (
            <div>
      <Editor
        tinymceScriptSrc="https://cdn.mataction.com/CDN_3/js/tinymce/js/tinymce/tinymce.min.js"
        initialValue={this.props.initialValue}
        value={this.state.value}
        onEditorChange={this.handleEditorChange}
      />
      <Button variant="success" onClick={this.save}>Save</Button>
      </div>
    )
  }
}

class ProfileNotesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.comp = props.comp;
    this.state = { value: props.initialValue ?? '' };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
        this.setState({ value: this.props.initialValue ?? '' })
    }
  }

  handleEditorChange(value, editor) {
    this.setState({ value });
  }
  
    save() {
        console.log(this.comp);
        postMaster.toSendData('/profile-update/' + this.comp.profile_data.id + '/notes-update',{commentator_notes:this.state.value}, false );
        this.comp.profile_data['edit_profile_notes'] = false;
        window.APP.reset();
    }

    render() {
      return (
              <div>
        <Editor
          tinymceScriptSrc="https://cdn.mataction.com/CDN_3/js/tinymce/js/tinymce/tinymce.min.js"
          initialValue={this.props.initialValue}
          value={this.state.value}
          onEditorChange={this.handleEditorChange}
        />
        <Button variant="success" onClick={this.save}>Save</Button>
        </div>
      )
    }
}

class TournamentNotesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.comp = props.comp;
    this.state = { value: props.initialValue ?? '' };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
        this.setState({ value: this.props.initialValue ?? '' })
    }
  }

  handleEditorChange(value, editor) {
    this.setState({ value });
  }
  
    save() {
        console.log(this.comp);
        postMaster.toSendData('/standing-notes-update/' + this.comp.id + '/notes-update',{comm_notes:this.state.value}, false );
        this.comp.profile_data['edit_tournament_notes'] = false;
        window.APP.reset();
    }

    render() {
      return (
              <div>
        <Editor
          tinymceScriptSrc="https://cdn.mataction.com/CDN_3/js/tinymce/js/tinymce/tinymce.min.js"
          initialValue={this.props.initialValue}
          value={this.state.value}
          onEditorChange={this.handleEditorChange}
        />
        <Button variant="success" onClick={this.save}>Save</Button>
        </div>
      )
    }
}

class AnnouncerGeneral extends React.Component { 
    
    static contextType = AppContext;
    
    
    constructor(props) {
        super(props);   
        this.ring_id = props.ring_id;
        this.ring = new Ring(this.ring_id);
        this.ring.load(null, true);
        var t = this;
        var ring = this.ring;
        
        this.g_current_div = null;
        this.g_current_status = 1;      
        this.g_current_item = null; 
        
        this.layers = [];
        this.special_messages = [];
        
        window.EchoScreen.private('ring.' + this.ring_id)
        .listen('AnnouncerMessage', (e) => {   
            this.processAnnouncerMessage({
                header:e.message.subject,
                body:e.message.body,
                uuid:uuidv4(),
                time: new Date(),
            });
            window.APP.reset();
        })
        .listen('CommentatorMessage', (e) => {   
            this.processCommentatorMessage({
                header:e.message.subject,
                body:e.message.body,
                uuid:uuidv4(),
                time: new Date(),
            });
            window.APP.reset();
        })
        .listen('CustomMessage', (e) => {        
            window.APP.messages_manager.addMessage(new Message(e.message.subject, e.message.body  ));    
            window.APP.reset();
        })
        .listen('TournamentJudgesUpdate', (e) => {   
            e.sent.forEach(j => {
                var jf = ring.a_judges.filter(function(e) {return e.id == j.id;});
                if(jf.length > 0) {
                    jf[0].update(j)
                } else {
                    ring.a_judges.push(new Judge(j))
                }
            });
            window.APP.reset();
        })
        .listen('TournamentDivisionStatus', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) { div.status = e.status; div.is_final = e.is_final; }
            window.APP.reset();
        })
        .listen('ProtestUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) div.updateProtest(e);
            window.APP.reset();
        })
        .listen('TournamentRingCurrentDivision', (e) => {
            let div = ring.getDivision(e.division_id);
            ring.current_division = div
            window.APP.reset();
        })
        .listen('TournamentDivisionCurrentComp', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(div.current_comp && div.current_comp.stopWatch.master && div.current_comp.stopWatch.running) {
                div.current_comp.stopWatch.stop()
            }
            
            div.current_comp = div.getComp(e.standing_id)
            window.APP.reset();
        })
        .listen('TournamentStandingUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) return;
                
                team.name = e.team_name;
                team.seeding = e.comp.seeding;
                
                let comp = team.getMember(e.standing_id);
                if(!comp) return;
                comp.update(e.comp);  
                
                div.checkTeams();
            } else {
                let comp = div.getComp(e.standing_id)
                if(!comp) return;
                comp.update(e.comp);  
            }
            window.APP.reset();
        })
        .listen('TournamentStandingScore', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            comp.setScore(e.num, parseFloat(e.value));           
            window.APP.reset();
        })
        .listen('TournamentDivisionAllScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                comp.setScore(s.num, parseFloat(s.score));
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentDivisionFixedScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                if(s.num < 0) {
                    if(comp.up_scores[s.num * -1] == null) {
                        comp.up_scores[s.num* -1] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.up_scores[s.num* -1].score = s.score;
                    comp.up_scores[s.num* -1].og_score = s.og_score;
                    comp.up_scores[s.num* -1].drop = s.drop;
                    comp.up_scores[s.num* -1].judge_id = s.judge_id;
                } else {
                    if(comp.scores[s.num] == null) {
                        comp.scores[s.num] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.scores[s.num].score = s.score;
                    comp.scores[s.num].og_score = s.og_score;
                    comp.scores[s.num].drop = s.drop;
                    comp.scores[s.num].judge_id = s.judge_id;
                }
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentStandingTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            
            if(e.running == 'sync' || e.tick == 'sync') {
                if(comp.stopWatch.master && (comp.stopWatch.left != 1000 || comp.stopWatch.running )) {
                    comp.stopWatch.update();
                }
                return;
            }
            
            comp.stopWatch.master = false;
            comp.stopWatch.sync(e.running, e.tick );
            
            window.APP.reset();
        })
        .listen('TournamentDivisionCompAdd', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                    let team = div.getComp(e.team)
                    if(team == null) {
                            team = new Standing(e.comp.id);
                            team.name = e.team_name;
                            team.seeding = e.comp.seeding;
                            div.standings.push(team) 
                    }

                    let member = new Standing(e.comp.id);
                    member.load(e.comp, div);
                    team.members.push(member)

                    div.checkTeams();
            } else {
                    let standing = new Standing(e.comp.id);
                    standing.load(e.comp, div);
                    div.standings.push(standing)  
                    
                    if(ring.comm_data_from != null && ring.comm_data_from != ''){
                        postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/standing/" + standing.id)
                        .then(res => res.json())
                        .then(
                        (result) => {
                            if(result == null) {
                                    return;
                            }
                            standing.profile_data = result.comp;
                            window.APP.reset();
                        });
                    }
            }
            div.checkTeams();
            
            
            
            window.APP.reset();
        })
        
        .listen('TournamentDivisionCompRemoved', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) {
                    //check for old team with removed lead
                    team = div.getComp(e.id);
                    if(!team) return;
                    
                    team.id = e.team;
                };
                team.members = team.members.filter(function(value, index, arr){ return value.id != e.id });
                
                if(team.members.length == 0 ) {
                    div.standings = div.standings.filter(function(value, index, arr){ return value.id != team.id });
                }
            } else {
                div.standings = div.standings.filter(function(value, index, arr){ return value.id != e.id });
            }
            div.checkTeams();
            window.APP.reset();
        })
        
        .listen('TournamentDivisionShuffle', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.ordered = parseInt(element.ordered)
            });
            div.shuffled = 1;
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffleReset', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            div.shuffled = 0;
            window.APP.reset();
        })
        
        .listen('TournamentRingDivisionRemove', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
        
            ring.divisions = ring.divisions.filter(function(value, index, arr){ return value.id != e.division_id });
            window.APP.reset();
        })
       .listen('TournamentRingDivisionAdd', (e) => {
            postMaster.toSend("/tournament-ring/" + ring.id + '/division/' + e.division_id,
    
            function(p) {
                p.then(res => res.json())
                .then((result) => {
                    if(result.call_pack != null && result.call_pack != '' && (ring.available_call_packs[result.call_pack] == undefined || ring.available_call_packs[result.call_pack] == null) &&  result.call_pack_full != null &&  result.call_pack_full != undefined ) {
                        var element = result.call_pack_full;
                        var p = {
                           uuid : element.uuid,
                           name : element.name,
                           available_calls: []
                        };

                        element.calls.forEach(c => { 
                           p.available_calls.push(
                                   new FightAvCall(c)
                           );
                        });
                        ring.available_call_packs[element.uuid] = p;
                    }
                    
                    let div = new Division(result.id);
                    div.ring = ring;
                    div.load(result);
                    ring.divisions.push(div);
                    div.ring = ring;
                    
                    if(ring.comm_data_from != null && ring.comm_data_from != ''){
                        postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/division/" + div.id)
                        .then(res => res.json())
                        .then(
                        (result) => {
                            result.forEach(p=>{ 
                                    if(p == null) {
                                            return;
                                    }
                                    div.processProfile(p);                                        
                            });
                            window.APP.reset();
                        });
                    }
                    
                    window.APP.reset();
                });
            });
        })
        .listen('TournamentDivisionUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return; 
            
            for (const [key, value] of Object.entries(e.changes)) {
                if(key == 'break_ties_till' || key == 'min_place' || key == 'min_size' || key == 'max_size' || key == 'is_team') {
                    div[key] = parseInt(value);   
                } else {
                    div[key] = value;    
                }
                
            }
            
            div.standings.forEach((s) => {
                s.division = div;
            });
            
            window.APP.reset();            
        })
        .listen('TournamentRingScheduleUpdate', (e) => {    
            e.updates.forEach(element => { 
                let div = ring.getDivision(element.division_id);
                if(!div) return; 
                
                div.estimated_start = new Date(element.est_start);    
                div.estimated_stop = new Date(element.est_stop); 
                div.duration_estimate = parseInt(element.duration_estimate);
                div.ordered = parseInt(element.ordered);
            });            
            window.APP.reset();            
        })
        .listen('TournamentFightCall', (e) => {                
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
    
    
            let acall = ring.getAVCalls().find(element => element.id == e.call.call_id);
            
            let comp = null;
            
            if(e.call.person_id == f.comp1.id) {
               comp = f.comp1;
            }  else {
                comp = f.comp2;
            }
            
            let call = new FightCall({
                id: e.call.id,
                opposing_points: e.call.opposing_points,
                points: e.call.points,
                dq: e.call.dq,
                warning: e.call.warning,
                person: comp,
                availableCall: acall,
                winner: e.call.winner,
                time_change: e.call.time_change,
                end_round: e.call.end_round
            });
    
    
            f.receiveCall(call)
            window.APP.reset();
        })
        
        .listen('TournamentFightTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
            
            if(e.running == 'sync' || e.tick == 'sync') { 
                if(f.timer.master && (f.timer.current != f.round_timer * 1000 || f.timer.running  )) {
                    f.timer.update();
                }
                return;
            }
            
            if(!f.timer.force_master) {
                f.timer.master = false;
            }
            
            
            f.timer.sync(e.running, e.tick );
            
            if(e.round) {
                f.current_round = e.round
            }
            
            if(e.break) {
                f.on_break = e.break
            }
            
            window.APP.reset();
        })
        
        .listen('TournamentFightSwapSides', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            f.swapped = parseInt(e.value);
            
            window.APP.reset();
        })
        
        .listen('TournamentRingCurrentFight', (e) => {
            let div = ring.current_division;
            if(!div) return;
            ring.current_fight = div.getFight(e.fight_id);
            window.APP.reset();
        })
        
        .listen('TournamentDivisionNewBrackets', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            div.fights = [];
            div.shuffled = 1;
            if(e.top_fights) {
                e.top_fights.forEach((t)=>{
                    div.addFight(t);
                });
            }
            window.APP.reset();
        })
        
        .listen('TournamentDivisionFightSwap', (e) => {  
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f1 = div.getFight(e.one);
            let f2 = div.getFight(e.two);
            if(f1 == null || f2 == null) {
                return;
            }
            swapFights(f1, f2);
            window.APP.reset();
        })
        
        .listen('TournamentDivisionPlaces', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.place = parseInt(element.rank);
            });

            window.APP.reset();
        })
        .listen('AlertForward', (e) => {
           ring.alertsManager.receiveEvent(e)
            window.APP.reset();
        })
        ;
    }
    
    removeSpecialMessage(m) {
        this.special_messages = this.special_messages.filter(x=>x.id != m.id);
        window.APP.reset();
    }
    
    render() {
       return <div></div>;
    }
}

export class AnnouncerInterface extends AnnouncerGeneral { 
    
    static contextType = AppContext;
    
    reload() {
        if(!window.APP.ring) {
            alert('Error!  Refresh recommended instead');
            return;
        }
        
        if(window.APP.connection_status < 1 ) {
            alert('Error!  Incorrect internet status');
            return;
        }
        
        if(window.pusher_status == 0 ) {
            alert('Error!  Incorrect connection status');
            return;
        }
        
        window.APP.needs_reload = 0;
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        window.APP.reload();
        
        window.APP.ring.load();
    }
    
    constructor(props) {
        super(props);        
        
        this.state = {
            showWindowPortal: false
        };
        
        this.stopwatch = new StopWatch();
        this.timer = new Timer(120);
        
        window.APP.timer = this.timer;
        window.APP.stopwatch = this.stopwatch;
        
        this.alertsModal = false;
        
        this.randomNumber = null;
        this.maxRandomNumber = 2;
        
        this.stopwatchModal = false;
        this.timerModal = false;
        this.scheduleModal = false;
        this.randomModal = false;
        this.settingsModal = false;
                
        this.messages =false;
        
        this.full_screen_division = null;
        this.full_screen_comp = null;
         
        this.judgesModalOpen = this.judgesModalOpen.bind(this);
        
        this.resetRandom = this.resetRandom.bind(this);
        this.getRandom = this.getRandom.bind(this);
        
        this.judgesModalClose = this.judgesModalClose.bind(this);
        
        this.scheduleModalOpen = this.scheduleModalOpen.bind(this);
        
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        
        this.timerModalOpen = this.timerModalOpen.bind(this);
        
        this.randomModalOpen = this.randomModalOpen.bind(this);
        this.randomModalClose = this.randomModalClose.bind(this);
        
        this.settingsModalOpen = this.settingsModalOpen.bind(this);
        this.settingsModalClose = this.settingsModalClose.bind(this);
        
        this.messagesToggle = this.messagesToggle.bind(this);
        
        this.maxRandomNumberUpdate = this.maxRandomNumberUpdate.bind(this);
                
        this.mainAreaHeight = 650;
        
        this.current_set_division = null;
        
        this.reload = this.reload.bind(this);
        this.resize = this.resize.bind(this);
        
        this.timer_special_value = 120;
        this.updateSpecialTimerValue = this.updateSpecialTimerValue.bind(this);
        
        this.schedule_scroll_percent = 0;
    }
    
    processAnnouncerMessage(message) {
        this.special_messages.push(message);
        window.APP.reset();
    }
    
    processCommentatorMessage(message) {
    }
    
    updateSpecialTimerValue(e) {
        this.timer_special_value = parseInt(e.target.value);
        this.setState({timer_special_value:this.timer_special_value});
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    scheduleModalOpen() {
        this.scheduleModal = true;
        this.setState({scheduleModal:true});
    }

    scheduleModalClose() {
        this.scheduleModal = false;
        this.setState({scheduleModal:false});
    }
    
    timerModalOpen() {
        this.timerModal = true;
        this.setState({timerModal:true});
    }

    timerModalClose() {
        this.timerModal = false;
        this.setState({timerModal:false});
    }
    
    stopwatchModalOpen() {
        this.stopwatchModal = true;
        this.setState({stopwatchModal:true});
    }

    stopwatchModalClose() {
        this.stopwatchModal = false;
        this.setState({stopwatchModal:false});
    }
    
    
    randomModalOpen() {
        this.randomModal = true;
        this.resetRandom();
        this.setState({randomModal:true});
    }

    randomModalClose() {
        this.randomModal = false;
        this.setState({randomModal:false});
    }
    
    settingsModalOpen() {
        this.settingsModal = true;
        this.setState({settingsModal:true});
    }

    settingsModalClose() {
        this.settingsModal = false;
        this.setState({settingsModal:false});
    }
        
    resetRandom() {
        this.randomNumber = null;
        this.setState({randomNumber:this.randomNumber});
    }

    getRandom() {        
        this.randomNumber = customRandom(this.maxRandomNumber) + 1;
        this.setState({randomNumber:this.randomNumber});
    }
    
    
    judgesModalOpen() {    
        this.judgesModal = true;
        this.setState({judgesModal:true});
    }

    judgesModalClose() {
        this.judgesModal = false;
        this.setState({judgesModal:false});
    }
    
    addWindow() {
        this.manager.addWindow();
    }
    
    updateScheduleScrolling() {
        var colused = document.getElementById('schedule_area');
        
        if(!colused)return;
        
        var h = colused.offsetHeight;
        var scroll = colused.scrollTop;
        this.schedule_scroll_percent = scroll / h;
    }
    
    setScheduleScrolling() { 
        var colused = document.getElementById('schedule_area');        
        if(!colused)return;        
        var h = colused.offsetHeight;
        colused.scrollTop = this.schedule_scroll_percent * h;
    }
    
    maxRandomNumberUpdate(event) {
        var t = this;
        this.maxRandomNumber = event.target.value;
        this.setState( {maxRandomNumber : this.maxRandomNumber} );
    }
   
    componentDidMount(){
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight +  this.headerRow.clientHeight);    
        window.APP.registerResize(this);
    }
   
    componentWillUnmount(){
        window.APP.unRegisterResize(this);
    }
   
    resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
    }
      
    render() {
     
    var divs = this.ring.getSchedule(48);
    var current_comps = [];
    var winner = null;
    const holder = this;
    
    if(this.current_set_division != null) {
        
        
        if(this.current_set_division.status == 3 && this.current_set_division.order_by_ranks ) {
            current_comps = this.current_set_division.getPlaceOrder();
        } else {
            current_comps = this.current_set_division.getCompOrder();
        }
        if(current_comps.length == 0) {
            current_comps = this.current_set_division.standings;
        }
        
        var rorder = this.current_set_division.getPlaceOrder();
        if(rorder.length > 0) {
            winner = rorder[0]; 
            if(this.current_set_division.getPlaceOrder().length > 1 ) { 
                if(winner.place == this.current_set_division.getPlaceOrder()[1].place ) { 
                    winner = null;
                }
            }
        }
    }
    
        
        var divs_area_parts = [];

        divs.forEach( (item, index, self2) => {       
                
                divs_area_parts.push(<div  style={{ margin: `8px`, padding:'4px',
                                                    backgroundColor:'white'  }}></div>);
                      
                if(item.before_announcer_notes && !item.before_announcer_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }} variant={'warning'}><div dangerouslySetInnerHTML={{__html:item.parseText(item.before_announcer_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.before_announcer_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                
                if(item.before_announcer_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'warning'}><MyEditor type="before_announcer_notes" div={item} initialValue={item.before_announcer_notes}></MyEditor></Alert>);                                    
                }
                
                divs_area_parts.push(<div style={{ margin: `0px`,  
                                                    borderStyle:(holder.current_set_division != null && holder.current_set_division.id == item.id) ? 'solid':'none',
                                                    borderColor:'#ffc400',
                                                    backgroundColor:(item.status == 3) ? '#075c07' : ((item.status > 0) ? '#7a0505' :'#231f40' ),
                                                    color:'white', padding:'5px'  }}>
                          <Container>
                                <Row>
                                    <Col xs='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                    </Col>
                                    <Col xs='4' style={{ fontSize:'0.7em' }}>
                                         {item.name}
                                    </Col>
                                    <Col xs='1' style={{ fontSize:'0.7em' }}>
                                       {item.standings.length }
                                    </Col>
                                    <Col xs='3' style={{ fontSize:'0.7em' }}>
                                            { (holder.current_set_division == null || holder.current_set_division.id != item.id ) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_division = item; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye"></i></Button>} 
                                            
                                            {holder.current_set_division != null && holder.current_set_division.id == item.id && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_division = null; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye-slash"></i></Button> }
                                            
                                            {item.before_announcer_notes == '' && !item.before_announcer_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.before_announcer_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-arrow-up"></i></Button>    }
                                            
                                            {item.during_announcer_notes == '' && !item.during_announcer_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.during_announcer_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-primary'><i class="fas fa-arrow-left"></i></Button>    }
                                            
                                            {item.after_announcer_notes == '' && !item.after_announcer_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.after_announcer_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-success'><i class="fas fa-arrow-down"></i></Button>    }
                                    </Col>
                                </Row>
                          </Container>
                    </div>);
            
                if(item.during_announcer_notes && !item.during_announcer_notes_edit) {
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }}  variant={'primary'} ><div dangerouslySetInnerHTML={{__html:item.parseText(item.during_announcer_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.during_announcer_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                
                if(item.during_announcer_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'primary'}><MyEditor type="during_announcer_notes" div={item} initialValue={item.during_announcer_notes}></MyEditor></Alert>);                                    
                }
                
                if(item.after_announcer_notes != '' && !item.after_announcer_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }}  variant={'success'}><div dangerouslySetInnerHTML={{__html:item.parseText(item.after_announcer_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.after_announcer_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                if(item.after_announcer_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'success'}><MyEditor type="after_announcer_notes" div={item} initialValue={item.after_announcer_notes}></MyEditor></Alert>);                                    
                }
            });

        var divsarea = <div  >
                        {divs_area_parts}
                      </div>;   
        
        
     
    let showSidebar = this.messages || this.ring.alertsManager.hasAlerts();
    var judgesListArea = this.ring.getCurrentJudgesArea(false);           
             
        var divs_schedule = this.ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); ;
        
    setTimeout(function(x){
        holder.setScheduleScrolling();
    }, 50);

    return (
       <Container>
           
           {this.current_set_comp != null &&<Modal size="lg" show={true} onHide={()=>{holder.current_set_comp = null; window.APP.reset(); }}>
                           <Modal.Header closeButton>
                             <Modal.Title>{this.current_set_comp.getNameCompare()}</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                           <Table>
                           <tbody>
                           
                           {this.current_set_comp.inputs && this.current_set_comp.members.length == 0 &&
                            this.current_set_comp.inputs.map(
                                    (input) => 
                                        <tr><th>{input.label}</th><td>{input.value}</td></tr>
                                    )
                                           }
                           {this.current_set_comp.blocked_reason != '' && 
                                   this.current_set_comp.members.length == 0 && <tr class="table-danger" ><th colspan="2">{this.current_set_comp.blocked_reason}</th></tr>}       
                                   
                           <tr><th>Seed</th>
                               <td>{this.current_set_comp.seeding}                                   
                                 </td>
                                 </tr>
                                {this.current_set_comp.members.length == 0 && <tr><th>Height</th><td>{this.current_set_comp.height}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Weight</th><td>{this.current_set_comp.getWeightString()}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Competing Age</th><td>{this.current_set_comp.age}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Gender</th><td>{this.current_set_comp.gender}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Rank</th><td>{this.current_set_comp.rank}</td></tr>}
                                   </tbody>
                            {this.current_set_comp.members.length > 0 &&
                            <tbody>{
                                this.current_set_comp.members.map(
                                (input) => 
                        <tr><th>{input.checked_in && <i class="fas fa-check-circle text-success"></i>}{!input.checked_in && <i class="fas fa-times-circle text-danger"></i>}&nbsp; {input.name}</th>
                        <td>
                        </td>
                        </tr>
                                )
                            }                      
                            </tbody>
                            }
                            <tr>
                                <th>
                                Country
                                </th>
                            <td>
                                {this.current_set_comp.getCountryCompare()}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                State
                                </th>
                            <td>
                                {this.current_set_comp.getStateCompare()}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                City
                                </th>
                            <td>
                                {this.current_set_comp.getCityCompare()}
                            </td>
                            </tr>
                            
                            <tr>
                                <th>
                                Team
                                </th>
                            <td>
                            {this.current_set_comp.getTeamCompare()}
                            </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign:'center' }} colspan='2'>
                                Schools
                                </th>
                            </tr>
                            {this.current_set_comp.schoolteams && this.current_set_comp.schoolteams.length > 0 &&
                            this.current_set_comp.schoolteams.map(
                                    (input) => 
                                        <tr><td colspan="2">{input}</td></tr>
                                    )
                                           }
                           </Table>
                           </Modal.Body>
                           <Modal.Footer>
                             <Button variant="secondary" onClick={() => { holder.current_set_comp = null; window.APP.reset(); } }>
                               Close
                             </Button>
                           </Modal.Footer>
                       </Modal>}
           
 <Row>
    <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
        <Navbar bg="dark" expand="lg" variant="dark">
        { this.ring.image && 
            <Navbar.Brand href="#home"><img
            src={this.ring.image}
            className="tournamentlogo"
            alt="tournament logo"
          /></Navbar.Brand> }
        <Navbar.Brand href="#home" className="tagname"> {this.ring.name} - Announcer</Navbar.Brand>
            <Navbar.Toggle aria-controls="tools-navbar-nav" />   
            <Navbar.Collapse id="tools-navbar-nav">
            <Nav className="mr-auto" style={{ flexDirection:'row' }}>
              <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={this.timerModalOpen}>Timer</NavDropdown.Item>
                <NavDropdown.Item onClick={this.stopwatchModalOpen}>Stopwatch</NavDropdown.Item>
                 <NavDropdown.Item onClick={this.randomModalOpen}>Random Number</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.scheduleModalOpen}>Schedule</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link onClick={(e) => {e.target.blur(); this.judgesModalOpen()}} >Judges <i class="text-info fas fa-gavel"></i></Nav.Link>
              <Nav.Link onClick={(e) => {e.target.blur(); this.settingsModalOpen()}} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
                  
               {this.special_messages.length > 0 && <Nav.Link><i class="text-danger fas fa-exclamation-triangle"></i></Nav.Link>}
            </Nav>
           </Navbar.Collapse>
          <Nav>
            <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
          </Nav>
          
          
          
          
        </Navbar>
        
        
        
        
        <Modal size="lg" show={this.judgesModal} onHide={() => this.judgesModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Judges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h2>Current</h2>
            <Table responsive>
                <tbody>
                    {judgesListArea}
                </tbody>
            </Table>
            
            
            
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.judgesModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.scheduleModal} onHide={() => this.scheduleModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table >
                    <tbody>
                    {
                        divs_schedule.map((division) => {

                                return  <tr>
                                            <td>{division.codePop() }</td>                                                                                                                               
                                            <td>{division.name }</td>
                                            <td>{division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td> 
                                            <td>{division.standings.length }</td>
                                        </tr>;
                                }
                              )
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.scheduleModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.stopwatchModal} onHide={() => this.stopwatchModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Stopwatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px', fontSize:'4em' }}>
                {this.stopwatch.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.stopwatch.running && <Button varient='primary' onClick={()=>this.stopwatch.start()} ><i class="fas fa-play"></i></Button>}
                {this.stopwatch.running && <Button variant="warning" onClick={()=>this.stopwatch.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.stopwatch.running && <Button variant="danger" onClick={()=>this.stopwatch.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'stopwatch' && <Button variant="success" onClick={(e) => { w.setMode("stopwatch");window.APP.reset(); }}>Set to StopWatch</Button>}
                            {w.mode == 'stopwatch' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                    </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.stopwatchModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.randomModal} onHide={() => this.randomModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Random Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col xs='12'>
                    This will product a random number beetween 1 and the number below (inclusive)
                    {
                        <Form.Control type="number" placeholder="2" onChange={this.maxRandomNumberUpdate} value={this.maxRandomNumber} />
                        }                                              
                    </Col>
                </Row>
                
                <Row style={{ marginTop:'15px' }}>
                    {this.randomNumber != null && <Col xs='12'>
                    {this.randomNumber}<br></br><br></br><Button variant='warning' onClick={this.resetRandom}>Reset</Button>
                    </Col>}
                    
                    {this.randomNumber == null && <Col xs='12'>
                    <Button variant='success' onClick={this.getRandom}>Get Random</Button>
                    </Col>}
                </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.randomModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col xs='12'>
                    <h1>The merge fields</h1>
                    <h2>Division Fields</h2>
                    <Table>
                        <tr>
                            <th>%%div_name%%</th>
                            <td>The division Name</td>
                        </tr>
                        <tr>
                            <th>%%cert_name%%</th>
                            <td>The certificate name, regular name if not provided</td>
                        </tr>
                    </Table>
                    <h2>Score Division Fields</h2>
                    <p>Replace X with the competitor number to display</p>
                    <Table>
                        <tr>
                            <th>%%compX_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%compX_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%compX_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%compX_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%compX_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%compX_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%compX_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr>
                        <tr>
                            <th>%%compX_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%compX_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                        <tr>
                            <th>%%compX_score1%%</th>
                            <td>The competitor's 1 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score2%%</th>
                            <td>The competitor's 2 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score3%%</th>
                            <td>The competitor's 3 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score4%%</th>
                            <td>The competitor's 4 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score5%%</th>
                            <td>The competitor's 5 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score6%%</th>
                            <td>The competitor's 6 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score7%%</th>
                            <td>The competitor's 7 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_total_score%%</th>
                            <td>The competitor's total score - drops come at the end of the division</td>
                        </tr>                        
                    </Table>
                    
                    <h2>Sparring Division Fields</h2>
                    <p>Replaced with the scorekeepers current fight.  DIR can be left or right</p>
                    <Table>
                        <tr>
                            <th>%%fight_time%%</th>
                            <td>The current timer</td>
                        </tr>
                        <tr>
                            <th>%%DIR_points%%</th>
                            <td>The side's current point value</td>
                        </tr>
                        <tr>
                            <th>%%DIR_cpoints%%</th>
                            <td>The competitor's culumative score</td>
                        </tr>
                        <tr>
                            <th>%%DIR_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%DIR_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%DIR_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%DIR_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%DIR_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%DIR_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%DIR_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr>    
                        <tr>
                            <th>%%DIR_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%DIR_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                    </Table>
                    
                    <h2>Winner Fields</h2>
                    <p>Replaced with the divisions winner - does not occur till the end of the division</p>
                    <Table>
                        <tr>
                            <th>%%winner_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%winner_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%winner_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%winner_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%winner_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%winner_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%winner_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr> 
                        <tr>
                            <th>%%winner_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%winner_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                        <tr>
                            <th>%%winner_total_score%%</th>
                            <td>The competitor's total score</td>
                        </tr> 
                    </Table>
                    </Col>
                </Row>
                
                
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.timerModal} onHide={() => this.timerModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Timer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'30px', fontSize:'4em' }}>
                {this.timer.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.timer.running && <Button varient='primary' onClick={()=>this.timer.start()} ><i class="fas fa-play"></i></Button>}
                {this.timer.running && <Button variant="warning" onClick={()=>this.timer.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.timer.running && <Button variant="danger" onClick={()=>this.timer.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                
                {!this.timer.running && <Row>
                    <Col xs={5} style={{ textAlign:'center',marginTop:'10px' }}>
                        Timer Value (seconds)
                    </Col>
                    <Col xs={4} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Form.Control type="text"  onChange={this.updateSpecialTimerValue} value={this.timer_special_value}  />
                    </Col>
                    <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Button variant="danger" onClick={()=>{ this.timer.setTimer(this.timer_special_value); this.timer.reset()}}>Set</Button>
                    </Col>
                </Row>}
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'timer' && <Button variant="success" onClick={(e) => { w.setMode("timer");window.APP.reset(); }}>Set to Timer</Button>}
                            {w.mode == 'timer' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.timerModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        
    </Col>   
    <Col xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }} >  
                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                    No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}
                  
                  {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                    Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}

                    {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Internet was lost - data may be outdated {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                    </Alert>}
                    
                    {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        When a division is not active, please redownload the ring data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                    </Alert>}
                    
                    {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Please refresh at the nearest opportunity - this will close any displays <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                    </Alert>}
                    <Container>
                        <Row>
                        <Col id='schedule_area' onScroll={()=>{ holder.updateScheduleScrolling() }} xs={(this.current_set_division != null) ? 6 : 12} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                            {divs_area_parts}
                        </Col>
                        {this.current_set_division != null && <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                        {this.current_set_division != null && <Container>
                            <Row>
                            <Col xs={12} style={{ color:'white'}}>
                                <h2>{this.current_set_division.name}</h2>
                                
                                {this.current_set_division.status < 3 && <Button variant='outline-secondary'>Order by Places</Button>}
                                {this.current_set_division.status == 3 && !this.current_set_division.order_by_ranks && <Button variant='outline-success' onClick={()=>{ holder.current_set_division.order_by_ranks = true; window.APP.reset(); } }>Order by Places</Button>}
                                {this.current_set_division.status == 3 && this.current_set_division.order_by_ranks && <Button variant='success' onClick={()=>{ holder.current_set_division.order_by_ranks = false; window.APP.reset(); } }>Order by Places</Button>}
                            </Col>
                            </Row>
                            <Row>
                            <Col xs={12} style={{color:'white'}}>
                                {this.current_set_division.status == 0 && <span>Not Started</span>}
                                {this.current_set_division.status == 1 && <span>Competing</span>}
                                {this.current_set_division.status == 2 && <span>Scoring</span>}
                                {this.current_set_division.status == 3 && <span>Completed!</span>}
                            </Col>
                            </Row>
                            {current_comps.map(c =>
                            <Row style={{  borderStyle:(holder.current_set_division.current_comp != null && holder.current_set_division.current_comp.id == c.id) ? 'solid':'none',
                                            borderColor:'#ffc400' }}>
                            {(this.current_set_division.status < 3 || !this.current_set_division.order_by_ranks)&& <Col xs={1} style={{ color:'white'}}>
                                {c.ordered}
                            </Col>}
                            {this.current_set_division.status == 3 && this.current_set_division.order_by_ranks && <Col xs={1} style={{ color:'white'}}>
                                {c.place == 1 && <span>1st</span>}{c.place == 2 && <span>2nd</span>}{c.place == 3 && <span>3rd</span>}{c.place > 3 && <span>{c.place}th</span>}
                            </Col>}
                            <Col xs={1} style={{color:'white'}}>
                                <img style={{ maxWidth:'100%',maxHeight:'1.8em',borderRadius:'5px'}} src={c.photo} />
                            </Col>
                            
                            <Col xs={5} style={{color:'white'}}>
                                {c.getName()} {winner && winner.id == c.id && <span style={{ color:'#ffd500'}}><i class="fas fa-trophy"></i></span>}
                            </Col>
                            <Col xs={3} style={{color:'white', textAlign:'center'}}>
                                <Button style={{ margin:'4px' }}
                                onClick={()=>{ this.current_set_comp = c; window.APP.reset(); }}    
                                variant='primary'><i class="fas fa-eye"></i></Button>
                            </Col>
                            {c.showScores() && parseFloat(c.getScoreTotal(this.current_set_division) ) > 0.0 && <Col xs={2} style={{ textAlign:'right',color:'white'}}>
                                {c.getScoreTotal(this.current_set_division)}
                            </Col>}
                            {c.showScores() && <Col xs={12} style={{ color:'white'}}>
                                <Table>
                                <tr>{c.getScores()
                                    .map((score) => 
                                        <td style={{ color:'white',textAlign:'center',padding:'0px' }}>
                                            {score.score}
                                        </td>  
                                     )}</tr>                                                                                                                                                     
                                </Table>
                            </Col>}
                            </Row>)
                            }
                                <Row><Col xs={12}>
                                    {this.current_set_division.config_type == 'sparring' &&
                                    this.current_set_division.status > 0 &&
                                        <BracketChart tv={0} div={this.current_set_division} > </BracketChart>}
                                </Col></Row>
                            </Container>
                        }     
                        
                        </Col>}
                        </Row>
                    </Container>
    </Col>
    { showSidebar && 
    <Col xs={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'30px' }}>
          
        <Container>
            { this.messages && 
            <Row>
                <Col xs={12}>
                
                <Card  bg='dark' text='light' >
                <Card.Header >Messages
                <div style={{float:'right'}}>
                <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                            </div>
                            </Card.Header>
                <Card.Body style={{ color:'black' }} >
                    <MessagesDisplay></MessagesDisplay>  
                    </Card.Body>
                </Card>
                </Col>
            </Row>}
            
        </Container>
      
    </Col>
    }


</Row>
        <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">
        
          <Nav className="mr-auto" style={{ flexDirection:'row' }}>    
          <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
          
          
          
          {window.APP.connection_status == 0 && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Ring Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
          </Nav>}
          
          <Nav style={{ flexDirection:'row' }}>
              
            
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
                    
                    
          {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
          
           
          <Navbar.Brand href="#home">
      <img
        src={ AppDomain + '/pass_M_Logo.png' }
        height="30"
        alt="MAT Action logo"
      />
      
    </Navbar.Brand>
        
          </Nav>
      </Navbar>
      {this.special_messages.map(m=>
            <div style={{zIndex:100000000, width:'50%',padding:'3px', color:'white',position:'fixed', left:'0px',bottom:'0px'}}>
            <Toast onClose={() => this.removeSpecialMessage(m) } >
            <Toast.Header style={{ color:'black' }} >
                <strong className="mr-auto">{m.header}</strong>
                  <small>{m.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }</small>
                </Toast.Header>
            <Toast.Body style={{ color:'black' }}>{m.body}</Toast.Body>                            
            </Toast>
        </div>)}
</Container>
    );
  }
}


export class CommentatorInterface extends AnnouncerGeneral { 
    
    static contextType = AppContext;
    
    reload() {
        if(!window.APP.ring) {
            alert('Error!  Refresh recommended instead');
            return;
        }
        
        if(window.APP.connection_status < 1 ) {
            alert('Error!  Incorrect internet status');
            return;
        }
        
        if(window.pusher_status == 0 ) {
            alert('Error!  Incorrect connection status');
            return;
        }
        
        window.APP.needs_reload = 0;
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        window.APP.reload();
        
        window.APP.ring.load();
    }
    
    constructor(props) {
        super(props);        
        window.HIME = this;
        this.state = {
            showWindowPortal: false
        };
        
        this.stopwatch = new StopWatch();
        this.timer = new Timer(120);
        
        window.APP.timer = this.timer;
        window.APP.stopwatch = this.stopwatch;
        
        this.alertsModal = false;
        
        this.randomNumber = null;
        this.maxRandomNumber = 2;
        
        this.stopwatchModal = false;
        this.timerModal = false;
        this.scheduleModal = false;
        this.randomModal = false;
        this.settingsModal = false;
             
        this.schedule_scroll_percent = 0;
                                                                                                                                                                                                                                                                                
        this.messages =false;
         
        this.judgesModalOpen = this.judgesModalOpen.bind(this);
        
        this.resetRandom = this.resetRandom.bind(this);
        this.getRandom = this.getRandom.bind(this);
        
        this.judgesModalClose = this.judgesModalClose.bind(this);
        
        this.scheduleModalOpen = this.scheduleModalOpen.bind(this);
        
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        
        this.timerModalOpen = this.timerModalOpen.bind(this);
        
        this.randomModalOpen = this.randomModalOpen.bind(this);
        this.randomModalClose = this.randomModalClose.bind(this);
        
        this.settingsModalOpen = this.settingsModalOpen.bind(this);
        this.settingsModalClose = this.settingsModalClose.bind(this);
        
        this.messagesToggle = this.messagesToggle.bind(this);
        
        this.maxRandomNumberUpdate = this.maxRandomNumberUpdate.bind(this);
                
        this.mainAreaHeight = 650;
        
        this.current_set_division = null;
        
        this.reload = this.reload.bind(this);
        this.resize = this.resize.bind(this);
        
        this.timer_special_value = 120;
        this.updateSpecialTimerValue = this.updateSpecialTimerValue.bind(this);
        
        this.add_div_data = [];
        this.comm_regs_data = [];
        this.comm_mappings_data = [];
        
        var t = this;
        
        postMaster.immediateGet("/tournament-ring/" + this.ring_id + '/commentate-data')
            .then(res => res.json())
            .then(
              (result) => {
                  t.processCommData(result)
              });
    }
    
    updateScheduleScrolling() { 
        var colused = document.getElementById('schedule_area');
        
        if(!colused)return;
        
        var h = colused.offsetHeight;
        var scroll = colused.scrollTop;
        this.schedule_scroll_percent = scroll / h;
    }
    
    setScheduleScrolling() { 
        var colused = document.getElementById('schedule_area');        
        if(!colused)return;        
        var h = colused.offsetHeight;
        colused.scrollTop = this.schedule_scroll_percent * h;
    }
    
    getStanding(uuid) { var found = null;
        this.ring.divisions.forEach(function(x){
            if(!found){
                found = x.getComp(uuid)
            }
        });
        if(found) return found;
        this.add_div_data.forEach(function(x){
            if(!found){
                found = x.getComp(uuid)
            }
        });
        return found;
    }
    
    getDivision(uuid) {
        var x= this.ring.divisions.find(x=>x.id == uuid);
        if(x) {
            return x;
        }
        return this.add_div_data.find(x=>x.id == uuid);
    }
    
    getAddDivision(uuid) {
        return this.add_div_data.find(x=>x.id == uuid);
    }
    
    getDivisionMappings(uuid) { 
        return this.comm_mappings_data.find(x=>x.id == uuid);
    }
    
    getRegistration(uuid) {
        return this.comm_regs_data.find(x=>x.standings.includes(uuid) );
    }
    
    processCommData(result){
        //this.add_div_data = result.additional_divisions;
        this.add_div_data = [];
        this.comm_regs_data = result.regs;
        var t=this;
        
        result.additional_divisions.forEach(element => { 
            let div = new Division(element.id);
            div.load(element);
            t.add_div_data.push(div);          
        });
        
        
        this.comm_mappings_data = result.divisions;
    }
    
    processAnnouncerMessage(message) {
       
    }
    
    processCommentatorMessage(message) {
        this.special_messages.push(message);
        window.APP.reset();
    }
    
    updateSpecialTimerValue(e) {
        this.timer_special_value = parseInt(e.target.value);
        this.setState({timer_special_value:this.timer_special_value});
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    scheduleModalOpen() {
        this.scheduleModal = true;
        this.setState({scheduleModal:true});
    }

    scheduleModalClose() {
        this.scheduleModal = false;
        this.setState({scheduleModal:false});
    }
    
    timerModalOpen() {
        this.timerModal = true;
        this.setState({timerModal:true});
    }

    timerModalClose() {
        this.timerModal = false;
        this.setState({timerModal:false});
    }
    
    stopwatchModalOpen() {
        this.stopwatchModal = true;
        this.setState({stopwatchModal:true});
    }

    stopwatchModalClose() {
        this.stopwatchModal = false;
        this.setState({stopwatchModal:false});
    }
    
    
    randomModalOpen() {
        this.randomModal = true;
        this.resetRandom();
        this.setState({randomModal:true});
    }

    randomModalClose() {
        this.randomModal = false;
        this.setState({randomModal:false});
    }
    
    settingsModalOpen() {
        this.settingsModal = true;
        this.setState({settingsModal:true});
    }

    settingsModalClose() {
        this.settingsModal = false;
        this.setState({settingsModal:false});
    }
        
    resetRandom() {
        this.randomNumber = null;
        this.setState({randomNumber:this.randomNumber});
    }

    getRandom() {        
        this.randomNumber = customRandom(this.maxRandomNumber) + 1;
        this.setState({randomNumber:this.randomNumber});
    }
    
    
    judgesModalOpen() {    
        this.judgesModal = true;
        this.setState({judgesModal:true});
    }

    judgesModalClose() {
        this.judgesModal = false;
        this.setState({judgesModal:false});
    }
    
    addWindow() {
        this.manager.addWindow();
    }
    
    maxRandomNumberUpdate(event) {
        var t = this;
        this.maxRandomNumber = event.target.value;
        this.setState( {maxRandomNumber : this.maxRandomNumber} );
    }
   
    componentDidMount(){
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight +  this.headerRow.clientHeight);    
        window.APP.registerResize(this);
    }
   
    componentWillUnmount(){
        window.APP.unRegisterResize(this);
    }
   
    
   
    resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
    }
    
    renderSchedule() {
        var divs = this.ring.getSchedule(48);
        var current_comps = [];
        const holder = this;
        var winner = null;
        
        if(this.current_set_division != null) {
            if(this.current_set_division.status == 3 && this.current_set_division.order_by_ranks) {
                current_comps = this.current_set_division.getPlaceOrder();
            } else {
                current_comps = this.current_set_division.getCompOrder();
            }
            if(current_comps.length == 0) {
                current_comps = this.current_set_division.standings;
            }
            
            var rorder = this.current_set_division.getPlaceOrder();
            if(rorder.length > 0) {
                winner = rorder[0]; 
                if(this.current_set_division.getPlaceOrder().length > 1 ) { 
                    if(winner.place == this.current_set_division.getPlaceOrder()[1].place ) { 
                        winner = null;
                    }
                }
            }
        }
        
        var divs_area_parts = [];

        divs.forEach( (item, index, self2) => {       
                
                divs_area_parts.push(<div  style={{ margin: `8px`, padding:'4px',
                                                    backgroundColor:'white'  }}></div>);
                      
                if(item.before_comm_notes && !item.before_comm_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }} variant={'warning'}><div dangerouslySetInnerHTML={{__html:item.parseText(item.before_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.before_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                
                if(item.before_comm_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'warning'}><MyEditor type="before_comm_notes" div={item} initialValue={item.before_comm_notes}></MyEditor></Alert>);                                    
                }
                
                divs_area_parts.push(<div style={{ margin: `0px`,  
                                                    borderStyle:(holder.current_set_division != null && holder.current_set_division.id == item.id) ? 'solid':'none',
                                                    borderColor:'#ffc400',
                                                    backgroundColor:(item.status == 3) ? '#075c07' : ((item.status > 0) ? '#7a0505' :'#231f40' ),
                                                    color:'white', padding:'5px'  }}>
                          <Container>
                                <Row>
                                    <Col xs='2' style={{ fontSize:'0.7em' }}>
                                            { item.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }
                                    </Col>
                                    <Col xs='5' style={{ fontSize:'0.7em' }}>
                                         {item.name}
                                    </Col>
                                    <Col xs='1' style={{ fontSize:'0.7em' }}>
                                       {item.standings.length }
                                    </Col>
                                    <Col xs='4' style={{ fontSize:'0.7em' }}>
                                            { (holder.current_set_division == null || holder.current_set_division.id != item.id ) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_division = item; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye"></i></Button>} 
                                            
                                            {holder.current_set_division != null && holder.current_set_division.id == item.id && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_division = null; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye-slash"></i></Button> }
                                            
                                            <Button style={{ margin:'4px' }}
                                            onClick={()=>{ holder.full_screen_division = item; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-expand-arrows-alt"></i></Button>
                                            
                                            {item.before_comm_notes == '' && !item.before_comm_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.before_comm_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-arrow-up"></i></Button>    }
                                            
                                            {item.during_comm_notes == '' && !item.during_comm_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.during_comm_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-primary'><i class="fas fa-arrow-left"></i></Button>    }
                                            
                                            {item.after_comm_notes == '' && !item.after_comm_notes_edit && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ item.after_comm_notes_edit =true; window.APP.reset(); }}    
                                            variant='outline-success'><i class="fas fa-arrow-down"></i></Button>    }
                                                    
                                                    
                                    </Col>
                                </Row>
                          </Container>
                    </div>);
            
                if(item.during_comm_notes && !item.during_comm_notes_edit) {
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }}  variant={'primary'} ><div dangerouslySetInnerHTML={{__html:item.parseText(item.during_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.during_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                
                if(item.during_comm_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'primary'}><MyEditor type="during_comm_notes" div={item} initialValue={item.during_comm_notes}></MyEditor></Alert>);                                    
                }
                
                if(item.after_comm_notes != '' && !item.after_comm_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%', color:'black' }}  variant={'success'}><div dangerouslySetInnerHTML={{__html:item.parseText(item.after_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ item.after_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>);                                    
                }
                if(item.after_comm_notes_edit) { 
                    divs_area_parts.push(<Alert style={{fontSize:'70%' }} variant={'success'}><MyEditor type="after_comm_notes" div={item} initialValue={item.after_comm_notes}></MyEditor></Alert>);                                    
                }
            });

        var divsarea = <div  >
                        {divs_area_parts}
                      </div>;   
                      
        setTimeout(function(x){
            holder.setScheduleScrolling();
        }, 50);
        
        
        return <Container>
                        <Row>
                        <Col onScroll={()=>{ holder.updateScheduleScrolling() }} id="schedule_area" xs={(this.current_set_division != null) ? 6 : 12} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                            {divs_area_parts}
                        </Col>
                        {this.current_set_division != null && <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                        {this.current_set_division != null && <Container>
                            <Row>
                            <Col xs={12} style={{ color:'white'}}>
                                <h2>{this.current_set_division.name}</h2>
                                {this.current_set_division.status < 3 && <Button variant='outline-secondary'>Order by Places</Button>}
                                {this.current_set_division.status == 3 && !this.current_set_division.order_by_ranks && <Button variant='outline-success' onClick={()=>{ holder.current_set_division.order_by_ranks = true; window.APP.reset(); } }>Order by Places</Button>}
                                {this.current_set_division.status == 3 && this.current_set_division.order_by_ranks && <Button variant='success' onClick={()=>{ holder.current_set_division.order_by_ranks = false; window.APP.reset(); } }>Order by Places</Button>}
                            
                            </Col>
                            </Row>
                            <Row>
                            <Col xs={12} style={{color:'white'}}>
                                {this.current_set_division.status == 0 && <span>Not Started</span>}
                                {this.current_set_division.status == 1 && <span>Competing</span>}
                                {this.current_set_division.status == 2 && <span>Scoring</span>}
                                {this.current_set_division.status == 3 && <span>Completed!</span>}
                            </Col>
                            </Row>
                            {current_comps.map(c =>
                            <Row style={{  borderStyle:(holder.current_set_division.current_comp != null && holder.current_set_division.current_comp.id == c.id) ? 'solid':'none',
                                            borderColor:'#ffc400' }}>
                            {(this.current_set_division.status < 3 || !this.current_set_division.order_by_ranks) && <Col xs={1} style={{ color:'white'}}>
                                {c.ordered}
                            </Col>}
                            {this.current_set_division.status == 3 && this.current_set_division.order_by_ranks && <Col xs={1} style={{ color:'white'}}>
                                {c.place == 1 && <span>1st</span>}{c.place == 2 && <span>2nd</span>}{c.place == 3 && <span>3rd</span>}{c.place > 3 && <span>{c.place}th</span>}
                            </Col>}
                            <Col xs={1} style={{color:'white'}}>
                                <img style={{ maxWidth:'100%',maxHeight:'1.8em',borderRadius:'5px'}} src={c.photo} />
                            </Col>
                            
                            <Col xs={5} style={{color:'white'}}>
                                {c.getName()} {winner && winner.id == c.id && <span style={{ color:'#ffd500'}}><i class="fas fa-trophy"></i></span>}
                            </Col>
                            <Col xs={3} style={{color:'white', textAlign:'center'}}>                                
                                <Button style={{ margin:'4px' }}
                                onClick={()=>{ this.current_set_comp = c; window.APP.reset(); }}    
                                variant='primary'><i class="fas fa-eye"></i></Button>
                                
                                <Button style={{ margin:'4px' }}
                                onClick={()=>{ holder.full_screen_comp = c; holder.full_screen_division = this.current_set_division; window.APP.reset(); }}    
                                variant='primary'><i class="fas fa-expand-arrows-alt"></i></Button>
                            </Col>
                            {c.showScores() && parseFloat(c.getScoreTotal(this.current_set_division) ) > 0.0 && <Col xs={2} style={{ textAlign:'right',color:'white'}}>
                                {c.getScoreTotal(this.current_set_division)}
                            </Col>}
                            {c.showScores() && <Col xs={12} style={{ color:'white'}}>
                                <Table>
                                <tr>{c.getScores()
                                    .map((score) => 
                                        <td style={{ color:'white',textAlign:'center',padding:'0px' }}>
                                            {score.score}
                                        </td>  
                                     )}</tr>                                                                                                                                                     
                                </Table>
                            </Col>}
                            </Row>)
                            }
                                <Row><Col xs={12}>
                                    {this.current_set_division.config_type == 'sparring' &&
                                    this.current_set_division.status > 0 &&
                                        <BracketChart tv={0} div={this.current_set_division} > </BracketChart>}
                                </Col></Row>
                            </Container>
                        }     
                        
                        </Col>}
                        </Row>
                    </Container>;
                      
    }
    
    renderDivMappings(mappings, layer ='') { 
        var t = this;
        
        if(mappings == null) return null;
        
        return mappings.maps.map(function(map){
            var div_data = t.getDivision(map.d);
            var comps = '';
            if(div_data) {
                comps = div_data.standings.filter(x=>x.place>0 && x.place <= map.n).map(x=>x.name).join(', ');
            }
            
            var toReturn = [];
            toReturn.push(<tr style={{ color:'white'}}><td>{layer}{div_data && div_data.name}{map.n>1&&<span> ({map.n})</span>}</td><td>{comps}</td><td> <Button style={{ margin:'4px' }}
                                            onClick={()=>{ t.current_pop_up_division = div_data; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye"></i></Button></td></tr>);
            toReturn.push(t.renderDivMappings(map.maps,layer + '-') );
            return toReturn;           
            });
    }
    
    renderDiv() {
        const holder = this;
        const div = this.full_screen_division;
        
        var current_comps = [];
        var winner = null;
        
        if(this.full_screen_division != null) {        

            if(this.full_screen_division.status == 3 && this.full_screen_division.order_by_ranks) {
                current_comps = this.full_screen_division.getPlaceOrder();
            } else {
                current_comps = this.full_screen_division.getCompOrder();
            }
            if(current_comps.length == 0) {
                current_comps = this.full_screen_division.standings;
            }
            
            var rorder = div.getPlaceOrder();
            if(rorder.length > 0) {
                winner = rorder[0]; 
                if(div.getPlaceOrder().length > 1 ) { 
                    if(winner.place == div.getPlaceOrder()[1].place ) { 
                        winner = null;
                    }
                }
            }
        }
        
        return <Container>
                        <Row>
                        <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                            <Container>
                            <Row>
                            <Col xs={12} style={{ color:'white'}}>
                                <h2>{this.full_screen_division.name}
                                {div.before_comm_notes == '' && !div.before_comm_notes_edit && <Button style={{ margin:'4px' }}
                                onClick={()=>{ div.before_comm_notes_edit =true; window.APP.reset(); }}    
                                variant='outline-warning'><i class="fas fa-arrow-up"></i></Button>    }

                                {div.during_comm_notes == '' && !div.during_comm_notes_edit && <Button style={{ margin:'4px' }}
                                onClick={()=>{ div.during_comm_notes_edit =true; window.APP.reset(); }}    
                                variant='outline-primary'><i class="fas fa-arrow-left"></i></Button>    }

                                {div.after_comm_notes == '' && !div.after_comm_notes_edit && <Button style={{ margin:'4px' }}
                                onClick={()=>{ div.after_comm_notes_edit =true; window.APP.reset(); }}    
                                variant='outline-success'><i class="fas fa-arrow-down"></i></Button>    }
                                </h2>
                            </Col>
                            </Row>
                            <Row>                            
                            <Col xs={12} style={{ color:'white'}}>
                            <Table>
                            {(div.before_comm_notes || div.before_comm_notes_edit) && <tr><td style={{ width:'100px',color:'white' }}>Before</td>
                                <td>
                                    <Alert style={{fontSize:'70%', color:'black' }} variant={'warning'}>
                                        {!div.before_comm_notes_edit && <div><div dangerouslySetInnerHTML={{__html:div.parseText(div.before_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ div.before_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></div>}
                                        {div.before_comm_notes_edit && <MyEditor type="before_comm_notes" div={div} initialValue={div.before_comm_notes}></MyEditor>}
                                    </Alert>
                                </td>
                            </tr>}
                            
                            {(div.during_comm_notes || div.during_comm_notes_edit) && <tr><td style={{ width:'100px',color:'white' }}>During</td>
                                <td>
                                    <Alert style={{fontSize:'70%', color:'black' }} variant={'primary'}>
                                        {!div.during_comm_notes_edit && <div><div dangerouslySetInnerHTML={{__html:div.parseText(div.during_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ div.during_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></div>}
                                        {div.during_comm_notes_edit && <MyEditor type="during_comm_notes" div={div} initialValue={div.during_comm_notes}></MyEditor>}
                                    </Alert>
                                    </td>
                                </tr>}
                            
                            { (div.after_comm_notes ||div.after_comm_notes_edit ) && <tr><td style={{ width:'100px',color:'white' }}>After</td>
                                <td>
                                    <Alert style={{fontSize:'70%', color:'black' }} variant={'success'}>
                                        {!div.after_comm_notes_edit && <div><div dangerouslySetInnerHTML={{__html:div.parseText(div.after_comm_notes)}}></div><Button variant="outline-primary" onClick={()=>{ div.after_comm_notes_edit = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></div>}
                                        {div.after_comm_notes_edit && <MyEditor type="after_comm_notes" div={div} initialValue={div.after_comm_notes}></MyEditor>}
                                    </Alert>
                                </td>
                            </tr>}
                            </Table>
                            </Col>
                            </Row>
                            
                            <Row>                            
                            <Col xs={12} style={{ color:'white'}}>
                                <h2>Eliminations</h2>
                            <Table>
                            {holder.renderDivMappings(this.getDivisionMappings(div.id) )}
                            </Table>
                            </Col>
                            </Row>
                            
                            </Container>
                        </Col>
                        <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                        <Container>
                        
                            <Row>
                                <Col xs={12}>
                                {this.full_screen_division.status < 3 && <Button variant='outline-secondary'>Order by Places</Button>}
                                {this.full_screen_division.status == 3 && !this.full_screen_division.order_by_ranks && <Button variant='outline-success' onClick={()=>{ holder.full_screen_division.order_by_ranks = true; window.APP.reset(); } }>Order by Places</Button>}
                                {this.full_screen_division.status == 3 && this.full_screen_division.order_by_ranks && <Button variant='success' onClick={()=>{ holder.full_screen_division.order_by_ranks = false; window.APP.reset(); } }>Order by Places</Button>}
                            
                                </Col>
                                
                            </Row>
                        
                            {current_comps.map(c =>
                            <Row style={{  borderStyle:(holder.full_screen_division.current_comp != null && holder.full_screen_division.current_comp.id == c.id) ? 'solid':'none',
                                            borderColor:'#ffc400' }}>
                            {(this.full_screen_division.status < 3 || !this.full_screen_division.order_by_ranks) && <Col xs={1} style={{ color:'white'}}>
                                {c.ordered}
                            </Col>}
                            {this.full_screen_division.status == 3 && this.full_screen_division.order_by_ranks && <Col xs={1} style={{ color:'white'}}>
                                {c.place == 1 && <span>1st</span>}{c.place == 2 && <span>2nd</span>}{c.place == 3 && <span>3rd</span>}{c.place > 3 && <span>{c.place}th</span>}
                            </Col>}
                            <Col xs={1} style={{color:'white'}}>
                                <img style={{ maxWidth:'100%',maxHeight:'1.8em',borderRadius:'5px'}} src={c.photo} />
                            </Col>
                            
                            <Col xs={5} style={{color:'white'}}>
                                {c.getName() } {winner && winner.id == c.id && <span style={{ color:'#ffd500'}}><i class="fas fa-trophy"></i></span>}
                            </Col>
                            <Col xs={3} style={{color:'white', textAlign:'center'}}>                                
                                <Button style={{ margin:'4px' }}
                                onClick={()=>{ this.current_set_comp = c; window.APP.reset(); }}    
                                variant='primary'><i class="fas fa-eye"></i></Button>
                                
                                <Button style={{ margin:'4px' }}
                                onClick={()=>{ holder.full_screen_comp = c; holder.full_screen_division = this.full_screen_division; window.APP.reset(); }}    
                                variant='primary'><i class="fas fa-expand-arrows-alt"></i></Button>
                            </Col>
                            {c.showScores() && parseFloat(c.getScoreTotal(this.full_screen_division) ) > 0.0 && <Col xs={2} style={{ textAlign:'right',color:'white'}}>
                                {c.getScoreTotal(this.full_screen_division)}
                            </Col>}
                            {c.showScores() && <Col xs={12} style={{ color:'white'}}>
                                <Table>
                                <tr>{c.getScores()
                                    .map((score) => 
                                        <td style={{ color:'white',textAlign:'center',padding:'0px' }}>
                                            {score.score}
                                        </td>  
                                     )}</tr>                                                                                                                                                     
                                </Table>
                            </Col>}
                            </Row>)
                            }
                                <Row><Col xs={12}>
                                    {this.full_screen_division.config_type == 'sparring' &&
                                    this.full_screen_division.status > 0 &&
                                        <BracketChart tv={0} div={this.full_screen_division} > </BracketChart>}
                                </Col></Row>
                            </Container>
                        </Col>
                        </Row>
                    </Container>;
                      
    }
    
    renderDivComp() {
        const holder = this;
        const comp = this.full_screen_comp;
        const div = comp.division;
        
        var current_set_comp_extra = null;
        if(comp) {
            current_set_comp_extra = this.getRegistration(comp.id);
        }
        
        return <Container>
                        <Row>
                        <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                            <Container>
                            <Row>
                            <Col xs={12} style={{ color:'white'}}>
                                <h2>{comp.getName()} - {div.name}</h2>
                            </Col>
                            </Row>
                            <Row>                            
                            <Col xs={12} style={{ color:'white'}}>
                                
                            {current_set_comp_extra && current_set_comp_extra.notes && <Alert variant={'secondary'}><div dangerouslySetInnerHTML={{__html:current_set_comp_extra.notes}}></div></Alert>}
                            
                                {comp.profile_data && <div dangerouslySetInnerHTML={{__html:comp.profile_data.quick_notes}}></div>}
                                
                                {comp.profile_data && comp.profile_data.bio != '' && comp.profile_data.bio != null &&  <h2>Bio</h2>}
                                {comp.profile_data && comp.profile_data.bio != '' && comp.profile_data.bio != null &&  <div>{comp.profile_data.bio}</div>}
                           
                            
                                {comp.profile_data && <h2>Profile Notes</h2>}
                                {comp.profile_data && !comp.profile_data.edit_profile_notes && (comp.profile_data.profile_notes == '' || comp.profile_data.profile_notes == null) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ comp.profile_data.edit_profile_notes = true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-plus"></i></Button>    }
                                {comp.profile_data && comp.profile_data.edit_profile_notes &&  <Alert style={{fontSize:'70%' }} variant={'secondary'}><ProfileNotesEditor  comp={comp} initialValue={comp.profile_data.profile_notes}></ProfileNotesEditor></Alert>}
                            
                                {comp.profile_data && comp.profile_data.profile_notes != '' && comp.profile_data.profile_notes != null && !comp.profile_data.edit_profile_notes && <Alert style={{fontSize:'70%', color:'black' }}  variant={'secondary'}><div dangerouslySetInnerHTML={{__html:comp.profile_data.profile_notes}}></div><Button variant="outline-primary" onClick={()=>{ comp.profile_data.edit_profile_notes = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>}
                                {comp.profile_data && <h2>Tournament Notes</h2>}     
                                {comp.profile_data && !comp.profile_data.edit_tournament_notes && (comp.profile_data.tournament_notes == '' || comp.profile_data.tournament_notes == null) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ comp.profile_data.edit_tournament_notes = true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-plus"></i></Button>    }
                                {comp.profile_data && comp.profile_data.edit_tournament_notes &&  <Alert style={{fontSize:'70%' }} variant={'secondary'}><TournamentNotesEditor  comp={comp} initialValue={comp.profile_data.tournament_notes}></TournamentNotesEditor></Alert>}
                                {comp.profile_data && comp.profile_data.tournament_notes != '' && comp.profile_data.tournament_notes != null && !comp.profile_data.edit_tournament_notes && <Alert style={{fontSize:'70%', color:'black' }}  variant={'secondary'}><div dangerouslySetInnerHTML={{__html:comp.profile_data.tournament_notes}}></div><Button variant="outline-primary" onClick={()=>{ comp.profile_data.edit_tournament_notes = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>}
                            
                            
                                <Table className='whiteText'>
                                    <tbody>

                                    {comp.inputs && comp.members.length == 0 &&
                                     comp.inputs.map(
                                             (input) => 
                                                 <tr><th>{input.label}</th><td>{input.value}</td></tr>
                                             )
                                                    }
                                    {comp.blocked_reason != '' && 
                                            comp.members.length == 0 && <tr class="table-danger" ><th colspan="2">{comp.blocked_reason}</th></tr>}       

                                    <tr><th>Seed</th>
                                        <td>{comp.seeding}                                   
                                          </td>
                                          </tr>
                                         {comp.members.length == 0 && <tr><th>Height</th><td>{comp.height}</td></tr>}
                                         {comp.members.length == 0 && <tr><th>Weight</th><td>{comp.getWeightString()}</td></tr>}
                                         {comp.members.length == 0 && <tr><th>Competing Age</th><td>{comp.age}</td></tr>}
                                         {comp.members.length == 0 && current_set_comp_extra && (current_set_comp_extra.actual_age != comp.age) && <tr><th>Actual Age</th><td>{current_set_comp_extra.actual_age}</td></tr>}
                                         {comp.members.length == 0 && <tr><th>Gender</th><td>{comp.gender}</td></tr>}
                                         {comp.members.length == 0 && <tr><th>Rank</th><td>{comp.rank}</td></tr>}
                                            </tbody>
                                     {comp.members.length > 0 &&
                                     <tbody>{
                                         comp.members.map(
                                         (input) => 
                                    <tr><th>{input.checked_in && <i class="fas fa-check-circle text-success"></i>}{!input.checked_in && <i class="fas fa-times-circle text-danger"></i>}&nbsp; {input.name}</th>
                                    <td>
                                    </td>
                                    </tr>
                                         )
                                     }                      
                                     </tbody>
                                     }
                                     <tr>
                                         <th>
                                         Country
                                         </th>
                                     <td>
                                         {comp.getCountryCompare()}
                                     </td>
                                     </tr>
                                     <tr>
                                         <th>
                                         State
                                         </th>
                                     <td>
                                         {comp.getStateCompare()}
                                     </td>
                                     </tr>
                                     <tr>
                                         <th>
                                         City
                                         </th>
                                     <td>
                                         {comp.getCityCompare()}
                                     </td>
                                     </tr>
                                     <tr>
                                        <th>
                                        Team
                                        </th>
                                    <td>
                                    {comp.getTeamCompare()}
                                    </td>
                                    </tr>
                                     <tr>
                                         <th style={{ textAlign:'center' }} colspan='2'>
                                         Schools
                                         </th>
                                     </tr>
                                     {comp.schoolteams && comp.schoolteams.length > 0 &&
                                     comp.schoolteams.map(
                                             (input) => 
                                                 <tr><td colspan="2">{input}</td></tr>
                                             )
                                                    }
                                    </Table>
                            </Col>
                            </Row>
                            
                            </Container>
                        </Col>
                        <Col xs={6} style={{padding:'15px', height:holder.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }}>
                            <Container><Row><Col xs='12'>
                            <Table className='whiteText'>
                                 {current_set_comp_extra && current_set_comp_extra.standings.map(function(uuid) {
                                    var standing = holder.getStanding(uuid);
                                    return <tr>
                                    <td>{standing.place > 0 && standing.place}</td>
                                    <td>{standing.division.name}</td>
                                    <td> <Button style={{ margin:'4px' }}
                                            onClick={()=>{ holder.current_pop_up_division = standing.division; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-eye"></i></Button></td>
                                    </tr>
                                     
                                     })}                                                                                                                                                                   
                            </Table></Col></Row>
                            </Container>
                        </Col>
                        </Row>
                    </Container>;
                      
    }
      
      
    render() {
    const holder = this;        
        
        
        
     
    let showSidebar = this.messages || this.ring.alertsManager.hasAlerts();
    var judgesListArea = this.ring.getCurrentJudgesArea(false);           
             
        var divs_schedule = this.ring.getSchedule(48).filter(function(value, index, arr){ 
                return value.status < 3;
            }); ;
        
        var current_set_comp_extra = null;
        if(this.current_set_comp) {
            current_set_comp_extra = this.getRegistration(this.current_set_comp.id);
        }
             
    var pop_up_comps = [];
    var popup_winner = null;
    
    if(this.current_pop_up_division != null) {
        if(this.current_pop_up_division.status == 3) {
            pop_up_comps = this.current_pop_up_division.getPlaceOrder();
        } else {
            pop_up_comps = this.current_pop_up_division.getCompOrder();
        }
        if(pop_up_comps.length == 0) {
            pop_up_comps = this.current_pop_up_division.standings;
        }
        
        var pop_up_rorder = this.current_pop_up_division.getPlaceOrder();
            if(pop_up_rorder.length > 0) {
                popup_winner = pop_up_rorder[0]; 
                if(pop_up_rorder.length > 1 ) { 
                    if(popup_winner.place == pop_up_rorder[1].place ) { 
                        popup_winner = null;
                    }
                }
            }
    }    
             
    return (
       <Container>
           {this.current_pop_up_division != null &&<Modal size="lg" show={true} onHide={()=>{holder.current_pop_up_division = null; window.APP.reset(); }}>
                           <Modal.Header closeButton>
                             <Modal.Title>{this.current_pop_up_division.name}</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                            <Container>
                                <Row>
                                
                                <Col xs={12} style={{padding:'15px',paddingBottom:'30px' }}>
                                <Container>
                                    <Row>
                                    <Col xs={12}>
                                        {this.current_pop_up_division.status == 0 && <span>Not Started</span>}
                                        {this.current_pop_up_division.status == 1 && <span>Competing</span>}
                                        {this.current_pop_up_division.status == 2 && <span>Scoring</span>}
                                        {this.current_pop_up_division.status == 3 && <span>Completed!</span>}
                                    </Col>
                                    </Row>
                                    {pop_up_comps.map(c =>
                                    <Row style={{  borderStyle:(holder.current_pop_up_division.current_comp != null && holder.current_pop_up_division.current_comp.id == c.id) ? 'solid':'none',
                                                    borderColor:'#ffc400' }}>
                                    {this.current_pop_up_division.status < 3 && <Col xs={1}>
                                        {c.ordered}
                                    </Col>}
                                    {this.current_pop_up_division.status == 3 && <Col xs={1}>
                                        {c.place == 1 && <span>1st</span>}{c.place == 2 && <span>2nd</span>}{c.place == 3 && <span>3rd</span>}{c.place > 3 && <span>{c.place}th</span>}
                                    </Col>}
                                    <Col xs={1} >
                                        <img style={{ maxWidth:'100%',maxHeight:'1.8em',borderRadius:'5px'}} src={c.photo} />
                                    </Col>

                                    <Col xs={5}>
                                        {c.getName()} {popup_winner && popup_winner.id == c.id && <span style={{ color:'#ffd500'}}><i class="fas fa-trophy"></i></span>}
                                    </Col>
                                    {c.showScores() && parseFloat(c.getScoreTotal(this.current_pop_up_division) ) > 0.0 && <Col xs={2} style={{ textAlign:'right'}}>
                                        {c.getScoreTotal(this.current_pop_up_division)}
                                    </Col>}
                                    {c.showScores() && <Col xs={12} >
                                        <Table>
                                        <tr>{c.getScores()
                                            .map((score) => 
                                                <td style={{ textAlign:'center',padding:'0px' }}>
                                                    {score.score}
                                                </td>  
                                             )}</tr>                                                                                                                                                     
                                        </Table>
                                    </Col>}
                                    </Row>)
                                    }
                                    </Container>
                                </Col>
                                </Row>
                            </Container>
                           
                           </Modal.Body>
                           <Modal.Footer>
                             <Button variant="secondary" onClick={() => { holder.current_pop_up_division = null; window.APP.reset(); } }>
                               Close
                             </Button>
                           </Modal.Footer>
                       </Modal>}
           
           
           {(this.current_set_division != null || this.full_screen_division != null)  && this.current_set_comp != null &&<Modal size="lg" show={true} onHide={()=>{holder.current_set_comp = null; window.APP.reset(); }}>
                           <Modal.Header closeButton>
                             <Modal.Title>{this.current_set_comp.getNameCompare()}</Modal.Title>
                           </Modal.Header>
                           <Modal.Body>
                           
                           <Button style={{ margin:'4px' }}
                                            onClick={()=>{ holder.full_screen_comp = this.current_set_comp; holder.full_screen_division = this.current_set_division; window.APP.reset(); }}    
                                            variant='primary'><i class="fas fa-expand-arrows-alt"></i></Button>
                           
                                {current_set_comp_extra && current_set_comp_extra.notes && <Alert variant={'secondary'}><div dangerouslySetInnerHTML={{__html:current_set_comp_extra.notes}}></div></Alert>}
                           
                                {this.current_set_comp.profile_data && <div dangerouslySetInnerHTML={{__html:this.current_set_comp.profile_data.quick_notes}}></div>}
                                
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.bio != '' && this.current_set_comp.profile_data.bio != null &&  <h2>Bio</h2>}
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.bio != '' && this.current_set_comp.profile_data.bio != null &&  <div>{this.current_set_comp.profile_data.bio}</div>}
                           
                                {this.current_set_comp.profile_data && <h2>Profile Notes</h2>}
                                {this.current_set_comp.profile_data && !this.current_set_comp.profile_data.edit_profile_notes && (this.current_set_comp.profile_data.profile_notes == '' || this.current_set_comp.profile_data.profile_notes == null) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_comp.profile_data.edit_profile_notes = true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-plus"></i></Button>    }
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.edit_profile_notes &&  <Alert style={{fontSize:'70%' }} variant={'secondary'}><ProfileNotesEditor  comp={this.current_set_comp} initialValue={this.current_set_comp.profile_data.profile_notes}></ProfileNotesEditor></Alert>}
                            
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.profile_notes != '' && this.current_set_comp.profile_data.profile_notes != null && !this.current_set_comp.profile_data.edit_profile_notes && <Alert style={{fontSize:'70%', color:'black' }}  variant={'secondary'}><div dangerouslySetInnerHTML={{__html:this.current_set_comp.profile_data.profile_notes}}></div><Button variant="outline-primary" onClick={()=>{ this.current_set_comp.profile_data.edit_profile_notes = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>}
                                {this.current_set_comp.profile_data && <h2>Tournament Notes</h2>}     
                                {this.current_set_comp.profile_data && !this.current_set_comp.profile_data.edit_tournament_notes && (this.current_set_comp.profile_data.tournament_notes == '' || this.current_set_comp.profile_data.tournament_notes == null) && <Button style={{ margin:'4px' }}
                                            onClick={()=>{ this.current_set_comp.profile_data.edit_tournament_notes = true; window.APP.reset(); }}    
                                            variant='outline-warning'><i class="fas fa-plus"></i></Button>    }
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.edit_tournament_notes &&  <Alert style={{fontSize:'70%' }} variant={'secondary'}><TournamentNotesEditor  comp={this.current_set_comp} initialValue={this.current_set_comp.profile_data.tournament_notes}></TournamentNotesEditor></Alert>}
                                {this.current_set_comp.profile_data && this.current_set_comp.profile_data.tournament_notes != '' && this.current_set_comp.profile_data.tournament_notes != null && !this.current_set_comp.profile_data.edit_tournament_notes && <Alert style={{fontSize:'70%', color:'black' }}  variant={'secondary'}><div dangerouslySetInnerHTML={{__html:this.current_set_comp.profile_data.tournament_notes}}></div><Button variant="outline-primary" onClick={()=>{ this.current_set_comp.profile_data.edit_tournament_notes = true; window.APP.reset(); }} style={{ position:'absolute',bottom:'0px',right:'0px' }} ><i class="far fa-edit"></i></Button></Alert>}
                            
                            
                            <Table>
                           <tbody>
                           
                           {this.current_set_comp.inputs && this.current_set_comp.members.length == 0 &&
                            this.current_set_comp.inputs.map(
                                    (input) => 
                                        <tr><th>{input.label}</th><td>{input.value}</td></tr>
                                    )
                                           }
                           {this.current_set_comp.blocked_reason != '' && 
                                   this.current_set_comp.members.length == 0 && <tr class="table-danger" ><th colspan="2">{this.current_set_comp.blocked_reason}</th></tr>}       
                                   
                           <tr><th>Seed</th>
                               <td>{this.current_set_comp.seeding}                                   
                                 </td>
                                 </tr>
                                {this.current_set_comp.members.length == 0 && <tr><th>Height</th><td>{this.current_set_comp.height}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Weight</th><td>{this.current_set_comp.getWeightString()}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Competing Age</th><td>{this.current_set_comp.age}</td></tr>}
                                {this.current_set_comp.members.length == 0 && current_set_comp_extra && (this.current_set_comp.age != current_set_comp_extra.actual_age) && <tr><th>Actual Age</th><td>{current_set_comp_extra.actual_age}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Gender</th><td>{this.current_set_comp.gender}</td></tr>}
                                {this.current_set_comp.members.length == 0 && <tr><th>Rank</th><td>{this.current_set_comp.rank}</td></tr>}
                                   </tbody>
                            {this.current_set_comp.members.length > 0 &&
                            <tbody>{
                                this.current_set_comp.members.map(
                                (input) => 
                        <tr><th>{input.checked_in && <i class="fas fa-check-circle text-success"></i>}{!input.checked_in && <i class="fas fa-times-circle text-danger"></i>}&nbsp; {input.name}</th>
                        <td>
                        </td>
                        </tr>
                                )
                            }                      
                            </tbody>
                            }
                            <tr>
                                <th>
                                Country
                                </th>
                            <td>
                                {this.current_set_comp.getCountryCompare()}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                State
                                </th>
                            <td>
                                {this.current_set_comp.getStateCompare()}
                            </td>
                            </tr>
                            <tr>
                                <th>
                                City
                                </th>
                            <td>
                                {this.current_set_comp.getCityCompare()}
                            </td>
                            </tr>
                            
                            <tr>
                                <th>
                                Team
                                </th>
                            <td>
                            {this.current_set_comp.getTeamCompare()}
                            </td>
                            </tr>
                            
                            <tr>
                                <th style={{ textAlign:'center' }} colspan='2'>
                                Schools
                                </th>
                            </tr>
                            
                            {this.current_set_comp.schoolteams && this.current_set_comp.schoolteams.length > 0 &&
                            this.current_set_comp.schoolteams.map(
                                    (input) => 
                                        <tr><td colspan="2">{input}</td></tr>
                                    )
                                           }
                           </Table>
                           </Modal.Body>
                           <Modal.Footer>
                             <Button variant="secondary" onClick={() => { holder.current_set_comp = null; window.APP.reset(); } }>
                               Close
                             </Button>
                           </Modal.Footer>
                       </Modal>}
           
 <Row>
    <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
        <Navbar bg="dark" expand="lg" variant="dark">
        { this.ring.image && 
            <Navbar.Brand href="#home"><img
            src={this.ring.image}
            className="tournamentlogo"
            alt="tournament logo"
          /></Navbar.Brand> }
        <Navbar.Brand href="#home" className="tagname"> {this.ring.name} - Commentator</Navbar.Brand>
            <Navbar.Toggle aria-controls="tools-navbar-nav" />   
            <Navbar.Collapse id="tools-navbar-nav">
            <Nav className="mr-auto" style={{ flexDirection:'row' }}>
              <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={this.timerModalOpen}>Timer</NavDropdown.Item>
                <NavDropdown.Item onClick={this.stopwatchModalOpen}>Stopwatch</NavDropdown.Item>
                 <NavDropdown.Item onClick={this.randomModalOpen}>Random Number</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.scheduleModalOpen}>Schedule</NavDropdown.Item>
              </NavDropdown>
              
                <Nav.Link onClick={(e) => {e.target.blur(); this.judgesModalOpen()}} >Judges <i class="text-info fas fa-gavel"></i></Nav.Link>
                <Nav.Link onClick={(e) => {e.target.blur(); this.settingsModalOpen()}} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
                      
               {this.special_messages.length > 0 && <Nav.Link><i class="text-danger fas fa-exclamation-triangle"></i></Nav.Link>}
            </Nav>
           </Navbar.Collapse>
          <Nav>
            <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
          </Nav>
          
          
        </Navbar>
        
        
        
        
        <Modal size="lg" show={this.judgesModal} onHide={() => this.judgesModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Judges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h2>Current</h2>
            <Table responsive>
                <tbody>
                    {judgesListArea}
                </tbody>
            </Table>
            
            
            
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.judgesModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.scheduleModal} onHide={() => this.scheduleModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table >
                    <tbody>
                    {
                        divs_schedule.map((division) => {

                                return  <tr>
                                            <td>{division.codePop() }</td>                                                                                                                               
                                            <td>{division.name }</td>
                                            <td>{division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td> 
                                            <td>{division.standings.length }</td>
                                        </tr>;
                                }
                              )
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.scheduleModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.stopwatchModal} onHide={() => this.stopwatchModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Stopwatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px', fontSize:'4em' }}>
                {this.stopwatch.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.stopwatch.running && <Button varient='primary' onClick={()=>this.stopwatch.start()} ><i class="fas fa-play"></i></Button>}
                {this.stopwatch.running && <Button variant="warning" onClick={()=>this.stopwatch.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.stopwatch.running && <Button variant="danger" onClick={()=>this.stopwatch.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'stopwatch' && <Button variant="success" onClick={(e) => { w.setMode("stopwatch");window.APP.reset(); }}>Set to StopWatch</Button>}
                            {w.mode == 'stopwatch' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                    </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.stopwatchModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.randomModal} onHide={() => this.randomModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Random Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col xs='12'>
                    This will product a random number beetween 1 and the number below (inclusive)
                    {
                        <Form.Control type="number" placeholder="2" onChange={this.maxRandomNumberUpdate} value={this.maxRandomNumber} />
                        }                                              
                    </Col>
                </Row>
                
                <Row style={{ marginTop:'15px' }}>
                    {this.randomNumber != null && <Col xs='12'>
                    {this.randomNumber}<br></br><br></br><Button variant='warning' onClick={this.resetRandom}>Reset</Button>
                    </Col>}
                    
                    {this.randomNumber == null && <Col xs='12'>
                    <Button variant='success' onClick={this.getRandom}>Get Random</Button>
                    </Col>}
                </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.randomModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col xs='12'>
                    <h1>The merge fields</h1>
                    <h2>Division Fields</h2>
                    <Table>
                        <tr>
                            <th>%%div_name%%</th>
                            <td>The division Name</td>
                        </tr>
                        <tr>
                            <th>%%cert_name%%</th>
                            <td>The certificate name, regular name if not provided</td>
                        </tr>
                    </Table>
                    <h2>Score Division Fields</h2>
                    <p>Replace X with the competitor number to display</p>
                    <Table>
                        <tr>
                            <th>%%compX_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%compX_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%compX_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%compX_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%compX_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%compX_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%compX_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr>
                        <tr>
                            <th>%%compX_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%compX_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                        <tr>
                            <th>%%compX_score1%%</th>
                            <td>The competitor's 1 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score2%%</th>
                            <td>The competitor's 2 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score3%%</th>
                            <td>The competitor's 3 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score4%%</th>
                            <td>The competitor's 4 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score5%%</th>
                            <td>The competitor's 5 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score6%%</th>
                            <td>The competitor's 6 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_score7%%</th>
                            <td>The competitor's 7 score</td>
                        </tr>
                        <tr>
                            <th>%%compX_total_score%%</th>
                            <td>The competitor's total score - drops come at the end of the division</td>
                        </tr>                        
                    </Table>
                    
                    <h2>Sparring Division Fields</h2>
                    <p>Replaced with the scorekeepers current fight.  DIR can be left or right</p>
                    <Table>
                        <tr>
                            <th>%%fight_time%%</th>
                            <td>The current timer</td>
                        </tr>
                        <tr>
                            <th>%%DIR_points%%</th>
                            <td>The side's current point value</td>
                        </tr>
                        <tr>
                            <th>%%DIR_cpoints%%</th>
                            <td>The competitor's culumative score</td>
                        </tr>
                        <tr>
                            <th>%%DIR_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%DIR_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%DIR_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%DIR_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%DIR_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%DIR_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%DIR_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr>      
                        <tr>
                            <th>%%DIR_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%DIR_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                    </Table>
                    
                    <h2>Winner Fields</h2>
                    <p>Replaced with the divisions winner - does not occur till the end of the division</p>
                    <Table>
                        <tr>
                            <th>%%winner_name%%</th>
                            <td>The competitor's first and last name</td>
                        </tr>
                        <tr>
                            <th>%%winner_city%%</th>
                            <td>The competitor's city</td>
                        </tr>
                        <tr>
                            <th>%%winner_state%%</th>
                            <td>The competitor's state</td>
                        </tr>
                        <tr>
                            <th>%%winner_country%%</th>
                            <td>The competitor's country</td>
                        </tr>
                        <tr>
                            <th>%%winner_team%%</th>
                            <td>The competitor's team field</td>
                        </tr>
                        <tr>
                            <th>%%winner_weight%%</th>
                            <td>The competitor's weight with unit</td>
                        </tr>
                        <tr>
                            <th>%%winner_age%%</th>
                            <td>The competitor's age without unit</td>
                        </tr>
                        <tr>
                            <th>%%winner_instructor%%</th>
                            <td>The competitor's instructor</td>
                        </tr>
                        <tr>
                            <th>%%winner_gender%%</th>
                            <td>The competitor's gender (male/female)</td>
                        </tr>
                        <tr>
                            <th>%%winner_total_score%%</th>
                            <td>The competitor's total score</td>
                        </tr> 
                    </Table>
                    </Col>
                </Row>
                
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.timerModal} onHide={() => this.timerModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Timer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'30px', fontSize:'4em' }}>
                {this.timer.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.timer.running && <Button varient='primary' onClick={()=>this.timer.start()} ><i class="fas fa-play"></i></Button>}
                {this.timer.running && <Button variant="warning" onClick={()=>this.timer.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.timer.running && <Button variant="danger" onClick={()=>this.timer.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                
                {!this.timer.running && <Row>
                    <Col xs={5} style={{ textAlign:'center',marginTop:'10px' }}>
                        Timer Value (seconds)
                    </Col>
                    <Col xs={4} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Form.Control type="text"  onChange={this.updateSpecialTimerValue} value={this.timer_special_value}  />
                    </Col>
                    <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Button variant="danger" onClick={()=>{ this.timer.setTimer(this.timer_special_value); this.timer.reset()}}>Set</Button>
                    </Col>
                </Row>}
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'timer' && <Button variant="success" onClick={(e) => { w.setMode("timer");window.APP.reset(); }}>Set to Timer</Button>}
                            {w.mode == 'timer' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.timerModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        <Breadcrumb style={{ backgroundColor:'black'}}>
            <Breadcrumb.Item onClick={()=>{ holder.full_screen_division = null;holder.full_screen_comp = null; window.APP.reset(); }} >Schedule</Breadcrumb.Item>
            {holder.full_screen_division != null && <Breadcrumb.Item onClick={()=>{ holder.full_screen_comp = null; window.APP.reset(); }} >{holder.full_screen_division.name}</Breadcrumb.Item>}
            {holder.full_screen_comp != null && <Breadcrumb.Item >{holder.full_screen_comp.name}</Breadcrumb.Item>}
        </Breadcrumb>
        
    </Col>   
    <Col xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }} >  
                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                    No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}
                  
                  {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                    Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}

                    {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Internet was lost - data may be outdated {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                    </Alert>}
                    
                    {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        When a division is not active, please redownload the ring data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                    </Alert>}
                    
                    {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Please refresh at the nearest opportunity - this will close any displays <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                    </Alert>}
                    
                    { holder.full_screen_comp == null && holder.full_screen_division == null && holder.renderSchedule()}
                    { holder.full_screen_comp == null && holder.full_screen_division != null && holder.renderDiv()}
                    { holder.full_screen_comp != null && holder.full_screen_division != null && holder.renderDivComp()}
    </Col>
    { showSidebar && 
    <Col xs={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'30px' }}>
          
        <Container>
            { this.messages && 
            <Row>
                <Col xs={12}>
                
                <Card  bg='dark' text='light' >
                <Card.Header >Messages
                <div style={{float:'right'}}>
                <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                            </div>
                            </Card.Header>
                <Card.Body style={{ color:'black' }} >
                    <MessagesDisplay></MessagesDisplay>  
                    </Card.Body>
                </Card>
                </Col>
            </Row>}
            
        </Container>
      
    </Col>
    }


</Row>
        <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">
        
          <Nav className="mr-auto" style={{ flexDirection:'row' }}>    
          <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
          
          
          
          {window.APP.connection_status == 0 && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Ring Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
          </Nav>}
          
          <Nav style={{ flexDirection:'row' }}>
              
            
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
                    
                    
          {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
          
           
          <Navbar.Brand href="#home">
      <img
        src={ AppDomain + '/pass_M_Logo.png' }
        height="30"
        alt="MAT Action logo"
      />
      
    </Navbar.Brand>
        
          </Nav>
      </Navbar>
      {this.special_messages.map(m=>
            <div style={{zIndex:100000000, width:'50%',padding:'3px', color:'white',position:'fixed', left:'0px',bottom:'0px'}}>
            <Toast onClose={() => this.removeSpecialMessage(m) } >
            <Toast.Header style={{ color:'black' }} >
                <strong className="mr-auto">{m.header}</strong>
                  <small>{m.time.toLocaleTimeString(window.APP.time_locale,window.APP.time_options ) }</small>
                </Toast.Header>
            <Toast.Body style={{ color:'black' }}>{m.body}</Toast.Body>                            
            </Toast>
        </div>)}
</Container>
    );
  }
}
