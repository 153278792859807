import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

import postMaster from '../logic/postmaster'
import {AppContext, AppDomain} from '../constants';
import {prepYoutube} from '../functions';
import {MessagesDisplay, Message} from '../models/messages';
import {GraphicsController, GraphicsReceiver} from './screens/GraphicsController';
import {AnnouncerInterface, CommentatorInterface} from './screens/Announcer';

export class ScreenDisplay extends React.Component { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);   
        
        this.screen_id = '';
        this.screen_cg_id = '';
        
        this.mode = 2;
        this.group_mode = 2;
        
        this.data = [];
        this.group_data = [];
        
        this.override = false;
        
        this.current_player = null;
        
        this.setupCurrentVid = this.setupCurrentVid.bind(this);
        this.playVid = this.playVid.bind(this);
        
        this.load();
        
    }
    
    load() {
        var inter = this;
        postMaster.immediateGet("/screen-data/data").then(res => res.json())
       .then(
          (result) => {
              
            if(result.timeZone) {
                window.APP.setTimeOption('timeZone',result.timeZone);
            }
            if(result.uses_kg) {
                window.APP.useKg(result.uses_kg);
            }
            this.screen_id = result.uuid;
            this.screen_cg_id = result.cg_uuid;
            
            this.override = result.screen_data.mode > -1;
            this.group_data = result.group_data.data;
            this.group_mode = result.group_data.mode;
            
            if(this.override){
                this.data = result.screen_data.data;
                this.mode = result.screen_data.mode;
            } else {
                this.data = this.group_data;
                this.mode = this.group_mode;
            }
            this.youtube_id_ready = false;
              
            window.EchoScreen.join('screen.' + this.screen_id)
            .listen('ScreenCGUpdate', (e) => {
                this.mode = e.mode;      
                this.mode = e.data;
                this.setState({mode:this.mode, mode:this.mode});
            })
            .listen('ScreenReSync', (e) => {
                if(this.mode == 23) {      
                    var  p = document.getElementById('url_video_player');
                    if(p == null) {  
                        return;
                    }                        
                    p.currentTime = 0;
                    p.play();
                }
            }).listen('RefreshRequest', (e) => { 
                
                if(e.type == 'refresh') {
                    window.location.reload();
                } else {
                    window.APP.reload();
                }
            }).listen('ScreenUpdate', (e) => {
                if(e.mode < 0) {
                    this.override = false;                    
                    this.data = this.group_data;
                    this.mode = this.group_mode;       
                } else {
                    this.override = true;                
                    this.data = e.data;
                    this.mode = e.mode;             
                    
                }
                this.setState({mode:this.mode, data:this.data});
            });
            
            window.EchoScreen.join('screencontrolgroup.' + this.screen_cg_id)
            .listen('ScreenCGUpdate', (e) => {
                this.group_mode = e.mode;      
                this.group_data = e.data;
                
                if(!this.override) {
                    this.data = this.group_data;
                    this.mode = this.group_mode;                
                    this.setState({mode:this.mode, data:this.data});
                }
            })
            .listen('ScreenReSync', (e) => {
                if(this.mode == 23) {      
                    var  p = document.getElementById('url_video_player');
                    if(p == null) {  
                        return;
                    }                        
                    p.currentTime = 0;
                    p.play();
                }
            }).listen('RefreshRequest', (e) => {                 
                if(e.type == 'refresh') {
                    window.location.reload();
                } else {
                    window.APP.reload();
                }
            });
              
            document.getElementById("loading_area").style.display = "none";
            window.APP.reset();
          }
        );
    }
    
    reload() {
        var inter = this;
        postMaster.immediateGet("/screen-data/data").then(res => res.json())
       .then(
          (result) => {              
           if(result.timeZone) {
                window.APP.setTimeOption('timeZone',result.timeZone);
            }
            if(result.uses_kg) {
                window.APP.useKg(result.uses_kg);
            }
            this.screen_id = result.uuid;
            this.screen_cg_id = result.cg_uuid;
            
            this.override = result.screen_data.mode > -1;
            this.group_data = result.group_data.data;
            this.group_mode = result.group_data.mode;
            
            if(this.override){
                this.data = result.screen_data.data;
                this.mode = result.screen_data.mode;
            } else {
                this.data = this.group_data;
                this.mode = this.group_mode;
            }
            this.youtube_id_ready = false;
            window.APP.reset();
          }
        );
    }
    
    componentWillUnmount() {
        window.APP.unRegisterReload(this)
    }
    
    readyYoutubeID() {
        if(this.youtube_id_ready || document.getElementById("player-holder") == null) {
            return;
        }
        this.youtube_id_ready = true;
        
        var player_div = document.createElement("div");                 // Create a <li> node
        player_div.id = 'youtube_player'
        document.getElementById("player-holder").appendChild(player_div);
    }
    
    playVid() {
        if(this.current_player != null && this.current_player != undefined  ){
            var player = this.current_player
            player.playVideo();
            player.mute();        
            player.playVideo();
        }
    }
    
    onState(event) {
        if(event.data == 0) {
            this.playVid();
        }
    }
    
    setupCurrentVid() {
        if(this.current_player == null && this.mode && this.mode.v_id) {
            this.readyYoutubeID();
            var p = this.playVid;
        
            this.current_player = new window.YT.Player('youtube_player', {
                height: window.innerHeight,
                width: window.innerWidth,
                videoId: this.mode.v_id,
                playerVars: {
                    controls: 0,
                },
                events: {
                    'onReady': function(){ p();},
                    'onStateChange':function(event){ p(event);},
                }
            });
        }
    }
    
    componentDidMount() {
        window.APP.registerReload(this)
        if(this.mode = 21) {
            this.readyYoutubeID();
            this.current_player = null;
        }   
    }
    
    getManager() { 
        if (this.mode != 21) {
            this.youtube_id_ready = false;
            this.current_player = null;
        }
        
        if(this.mode == 1) {
            return <div style={{ position:'fixed',width:'100%',height:'100%',backgroundColor:'black', }}></div>;
        }
        
        if(this.mode == 2) {
            return <div></div>;
        }
        
        if(this.mode == 10) {         
            return <div style={{ position: 'fixed',height: '100%',width: '100%',textAlign:'center' }}><img style={{ maxHeight: '100%',maxWidth: '100%' }} key={this.mode.url} src={this.mode.url} /></div>;
        }
        
        if(this.mode == 15) {
            return <div style={{ position: 'fixed',height: '100%',width: '100%' }}><div style={{ color:'white',position: 'absolute',top: '20%',left: '20%',
                width: '60%',textAlign: 'center',fontSize: '4em' }}>{this.data.text}</div></div>;
        }
        
        if(this.mode == 21) {
            if(this.current_player == null) { 
                prepYoutube(this.setupCurrentVid );
            }            
            return <div style={{ position: 'fixed',height: '100%',width: '100%' }}><div id="player-holder"></div></div>;
        }
        
        if(this.mode == 23) {         
            return <div style={{ position: 'fixed',height: '100%',width: '100%' }}><video id='url_video_player' loop key={this.data.url} autoplay="autoplay" muted="muted" style={{ width:'100%',height:'100%' }}  ><source src={this.data.url} /></video></div>;
        }
        
         if(this.mode == 551) {
            return <GraphicsController ring_id={this.data.ring_id}></GraphicsController>;
        }
        
        if(this.mode == 581) {
            return <GraphicsReceiver ring_id={this.data.ring_id}></GraphicsReceiver>;
        }
        
        if(this.mode == 511) {
            return <AnnouncerInterface ring_id={this.data.ring_id}></AnnouncerInterface>;
        }
        
        if(this.mode == 521) {
            return <CommentatorInterface ring_id={this.data.ring_id}></CommentatorInterface>;
        }
        
        return <div>NA</div>;
    }
    
    
   render() {
       return <div>{this.getManager()}</div>;
  }
}
