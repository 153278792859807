import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import {TimeDisplay} from './TimeDisplay';

import Alert from 'react-bootstrap/Alert';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import postMaster from '../logic/postmaster'
import {StopWatch, Timer} from '../logic/clocks';
import {AppContext, AppDomain} from '../constants';
import {Standing} from '../models/standing'
import {Division} from '../models/division'
import {Judge} from '../models/judge'
import {MessagesDisplay, Message} from '../models/messages'
import {Scorekeeper} from './scorekeepers';
import {DivisionManagerDisplay} from './scorekeepermains/DivisionManagerDisplay'

export class DivisionManagerDisplayJudge extends DivisionManagerDisplay {
    uploadScores() {
        
        this.ring.current_division.needs_update = false;
    
        var calls = [];
        
        this.ring.current_division.standings.forEach(comp => {
            calls.push({ id: comp.id,score: comp.temp_score });
        });
        
        
        postMaster.toSendData('/tournament-ring/' +this.ring.id + '/division/' +this.current_division.id + '/judge-scores/' + window.PARAM.num,calls )
       window.APP.forceUpdate();
    }

    render() {  
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        
        
        
        if(this.current_division) { 
            var score_dq = 0;
                
            if(!isNaN(this.current_division.score_dq) ) {
                score_dq = this.current_division.score_dq;
            }
            
            var div =    this.current_division;
            if (div.status == 1 || div.status == 2) {

                    const standingsList = div.getCompOrder()
                            .map((comp) => {
                                if(comp.status == 4) {
                                    comp.temp_score = score_dq;
                                }
                       return   <tr className={ ( div.current_comp == comp ? 'table-success':'' ) } style={{ color: ( div.current_comp == comp ? 'black':'white' ) }} >
                        <td>{comp.ordered}</td>
                        <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                        <td style={{ width:'50px' }}>
            {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
       </td>                        
                        <td style={{ textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td>
                        <td style={{ minWidth:'5em' }}>
                            <Form.Control as="input" type="text" value={comp.temp_score} 
                            disabled={ comp.status == 4 ? 'disabled' : '' }
                            className='scoreinput'
                                                onChange={(event)=>{div.needs_update = true; comp.setTempScore(event.target.value); } }  >
                            </Form.Control>
                            <div>
                                <small class="text-danger">&nbsp;
                                { parseFloat(comp.temp_score) > div.max_score   &&                               
                                    <span>Above Max</span>                      
                                }

                                { parseFloat(comp.temp_score) < div.min_score   &&                               
                                    <span>Below Min</span>                                    
                                }
                                &nbsp;
                                </small> 
                            </div>
                        </td>
                           </tr>;
                       }
                     );



                    return <Container>
                    <Row  style={{ textAlign:'center' }}>
                    <Col xs={12}>
                        <Card bg='dark'
                        style={{ margin:'1%', width:'98%'  }}
                            text= 'light'
                          >
                           <Card.Header >{ div.name } { (div.status == 1) ? 'Competing' : 'Scoring' }</Card.Header>
                            <Card.Body >
                                <Container>
                                    <Row  style={{ textAlign:'center' }}>
                                        <Table variant="dark" responsive>
                                        <thead>
                                                <tr>
                                                    <th>Order</th>
                                                    <th colspan="3">Name</th>
                                                    <th>Score</th>                                                   
                                                </tr>                                                                                                                                                                                 
                                            </thead>
                                                   {standingsList}    
                                        {div.status == 2 && 
                                            <tr>
                                                <td colspan='4'>
                                                <Button variant={ (this.ring.current_division.needs_update) ? 'success' : 'secondary' } onClick={() => this.uploadScores() }>Submit Scores</Button>
                                               </td>
                                            </tr>    
                                        }           
                                          
                                        </Table>
                                    </Row>                      
                                </Container>
                            </Card.Body>
                      </Card>
                      </Col>
                    </Row>
                    </Container>;

            }
            
            if(div.status == 3) {
            const standingsList = div.getPlaceOrder()
                        .map((comp) => {
                            const scoresList = comp.getScores()
                            .map((score) => 
                                <td>
                                {score.score}                                                                                                                                                                                                              
                                </td>  
                             );
                   return   <tr>
                    <td>{comp.place}</td>
                    <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                    <td style={{ width:'50px' }}>
            {comp.country != '' && <OverlayTrigger placement={'top'}
      overlay={
        <Tooltip>
          {comp.country_name}
        </Tooltip>
      }
      ><img style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
       </td>
                    
                    <td style={{ textAlign:'left' }}>{comp.name}</td>
                    {scoresList}
                    <td>{comp.getScoreTotal()}</td>
                    </tr>;
                   }
                 );
                 
            return <Container>
               <Row  style={{ textAlign:'center' }}>
                <Col xs={12}>
                    <Card bg='dark'
                    style={{ margin:'1%', width:'98%'  }}
                        text= 'light'
                      >
                       <Card.Header >
                       Places
                       </Card.Header>
                        <Card.Body >
                            <Container>
                                <Row  style={{ textAlign:'center' }}>
                                    <Table variant="dark" responsive>
                                    <thead>
                                                <tr>
                                                    <th>Order</th>
                                                    <th colspan="3">Name</th>
                                                    <th>Score</th>                                                   
                                                </tr>                                                                                                                                                                                 
                                            </thead>
                                               {standingsList}                                                                                    
                                    </Table>
                                </Row>                      
                            </Container>
                        </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </Container>;
            }
        }
        
    
return <Container>
<p style={{ marginTop:'50px',textAlign:'center',color: 'white',fontSize:'3em' }}>{this.ring.name}</p>
    <p style={{ marginTop:'25px',textAlign:'center',color: 'white',fontSize:'1.2em' }}>Welcome Judge! Please wait for your ring to start and check-in to be completed.</p>
</Container>;  
    }
}



export class JudgeInterface extends Scorekeeper {
    render() {
        let holder = this;
        
        return (
           <Container>
     <Row>
        <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
            <Navbar bg="dark" expand="md" variant="dark">
            { this.ring.image && 
                <Navbar.Brand href="#home"><img
                src={this.ring.image}
                className="tournamentlogo"
                alt="tournament logo"
              /></Navbar.Brand> }
              <Navbar.Brand href="#home" className="tagname" >{this.ring.name}</Navbar.Brand>
              <Navbar.Toggle aria-controls="tools-navbar-nav" />   
                <Navbar.Collapse id="tools-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link onClick={() => this.settingsModalOpen()} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
              </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tbody>
                        </tbody>        
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>

        </Col>   
        <Col xs={12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden' ,paddingBottom:'30px' }}>  
            {window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

              {window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

                {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Internet was lost - data may be outdated - {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}
                
                {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    When you have a moment please redownload data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                </Alert>}

                {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Please refresh at the nearest opportunity <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                </Alert>}
                
                    <DivisionManagerDisplayJudge ring={this.ring} holder={this} ></DivisionManagerDisplayJudge>

        </Col>
        

    </Row>


            <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">

              <Nav className="mr-auto">
              <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
              
                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
                </Nav>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>} <Spinner animation="grow" variant="info" /></Navbar.Brand>
                </Nav>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Ring Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
                </Nav>}
              
              <Nav style={{ flexDirection:'row', padding: '.5rem 1rem' }}>
              
              {postMaster.queue.length == 0 && postMaster.onMessage == null && window.APP.connection_status > 0 && window.pusher_status == 1 && <Nav.Link  onClick={() => { holder.reload() } } ><span class={ (window.APP.connection_status == 1 ) ? 'text-primary':'text-secondary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Click To Download Data
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-download-alt"></i></OverlayTrigger></span></Nav.Link>}
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
          
              
              {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
              
              {window.APP.canFullscreen() && !window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.goFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Go Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-expand"></i></OverlayTrigger></span></Nav.Link>}
                
                    {window.APP.canFullscreen() && window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.exitFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Exit Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-compress"></i></OverlayTrigger></span></Nav.Link>}
              
                <Navbar.Brand href="#home">
          <img
            src={ AppDomain + '/pass_M_Logo.png' }
            height="30"
            alt="MAT Action logo"
          />
        </Navbar.Brand>
              </Nav>
          </Navbar>
    </Container>
    );
  }
}

export class PersonalJudgeInteface extends React.Component { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);        
        
        window.APP.judge = this;
        
        this.messages =false;
        this.stopwatchModal = false;
        this.timerModal = false;
        
        this.stopwatch = new StopWatch();
        this.timer = new Timer(120);
        
        this.settingsModalOpen = this.settingsModalOpen.bind(this);
        this.settingsModalClose = this.settingsModalClose.bind(this);
        
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        
        this.timerModalOpen = this.timerModalOpen.bind(this);
        this.timerModalOpen = this.timerModalOpen.bind(this);
        
        this.messagesToggle = this.messagesToggle.bind(this);
        
        this.resize = this.resize.bind(this);
        this.schedule = [];
        this.image = '';
        this.tournament_name = '';
        this.tournament_id = '';
        this.judge_name = '';
        this.judge_thumb = '';
        this.judge_med = '';
        
        this.judge_check_in = 0;
        this.judge_is_checked_in = 0;
        
        this.current_div = null;
        
        var pjudge = this;
        var t = this;
        
        window.Echo.join('tournament.judge.' + window.PARAM.judge_id)
        .listen('CustomMessage', (e) => {        
            window.APP.messages_manager.addMessage(new Message(e.message.subject, e.message.body  ));
            window.APP.reset();
        })
        .listen('RefreshRequest', (e) => {        
            if(e.type == 'data') {
                window.APP.needs_reload = 1;
        
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                   t.reload();
                }
            }
    
            if(e.type == 'refresh') {
                window.APP.needs_refresh = 1;
                
                if( (e.force == 1 && !postMaster.leftToSend() ) || e.force == 2) {
                    window.location.reload();
                }
            }
            
            window.APP.reset();
        })
        .listen('TournamentDivisionUpdate', (e) => {
            if(!this.current_div || this.current_div.id != e.division_id) return; 
            let div = this.current_div;
            for (const [key, value] of Object.entries(e.changes)) {
                if(key == 'break_ties_till' || key == 'min_place' || key == 'min_size' || key == 'max_size' || key == 'is_team' || key == 'ignore_final_fight') {
                    div[key] = parseInt(value);   
                } else {
                    div[key] = value;    
                }                
            }            
            div.standings.forEach((s) => {
                s.division = div;
            })
            
            window.APP.reset();            
        })
        .listen('JudgeUpdateSchedule', (sch) => {   

            var jf = this.schedule.filter(function(e) {return e.id == sch.id;});
            if(jf.length > 0) {
                jf[0].update(sch);
            } else {
                this.schedule.push(new Judge(sch));
            }
             
            if(!sch.not_refresh){
                postMaster.immediateGet("/tournament-judge/" + window.PARAM.judge_id + '/judge').then(res => res.json())
               .then(
                  (result) => {

                     if(result.current_div) {
                        this.current_div = new Division(result.current_div.id);
                        this.current_div.load(result.current_div);
                     } else {
                         this.current_div = null;
                     }

                     this.judge_is_checked_in = result.judge_is_checked_in;

                    window.APP.forceUpdate()
                  }
                );
            } else{
                this.judge_is_checked_in = sch.checked_in;
            }
            
            window.APP.reset();
        })
        .listen('TournamentRingCurrentDivision', (e) => {
            postMaster.immediateGet( "/tournament-judge/" + window.PARAM.judge_id + '/judge').then(res => res.json())
           .then(
              (result) => {
                  
                 if(result.current_div) {
                    this.current_div = new Division(result.current_div.id);
                    this.current_div.load(result.current_div);
                 } else {
                     this.current_div = null;
                 }
                 
                 var holder = this;
                 
                if(this.current_div) {
                    this.current_div.standings.forEach((stand) => {
                        if(holder.input_type == 'type' && stand.temp_score == 0 && holder.current_div.score_prefill ) {
                            stand.temp_score = holder.current_div.score_prefill;
                        } 
                        if(holder.input_type == 'dropdown' && parseFloat(stand.temp_score) ==  parseFloat( holder.current_div.score_prefill) ) {
                            stand.temp_score = 0;
                        }
                    });            
                }
                
                window.APP.forceUpdate()
              }
            );
        })
        .listen('TournamentDivisionStatus', (e) => {
            if(this.current_div != null && this.current_div.id == e.division_id) {this.current_div.status = e.status; this.current_div.is_final = e.is_final; };
            window.APP.reset();
        })
        .listen('TournamentDivisionCurrentComp', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
           
            this.current_div.current_comp = this.current_div.getComp(e.standing_id)
            window.APP.reset();
        })
        .listen('TournamentStandingUpdate', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
    
            let comp = this.current_div.getComp(e.standing_id);
            
            if(e.team) {
                let team = this.current_div.getComp(e.team)
                if(!team) return;
                
                team.name = e.team_name;
                team.seeding = e.comp.seeding;
                
                let comp = team.getMember(e.standing_id);
                if(!comp) return;
                comp.update(e.comp);  
                comp.
                this.current_div.checkTeams();
            } else {
                let comp = this.current_div.getComp(e.standing_id)
                if(!comp) return;
                comp.update(e.comp);  
            }
            window.APP.reset();
        })
        
        .listen('TournamentStandingCheckin', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
    
            let comp = this.current_div.getComp(e.standing_id)
            if(!comp) return;
            
            if(e.value == "false") {
                comp.check(false)
            } else {
                comp.check(true)
            }
            window.APP.reset();
        })
        .listen('TournamentDivisionCompAdd', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
            let div = this.current_div;
            
            if(e.team) {
                    let team = div.getComp(e.team)
                    if(team == null) {
                            team = new Standing(e.comp.id);
                            team.name = e.team_name;
                            team.seeding = e.comp.seeding;
                            div.standings.push(team)  
                    }

                    let member = new Standing(e.comp.id);
                    member.load(e.comp, div);
                    team.members.push(member)

                    div.checkTeams();
            } else {
                    let standing = new Standing(e.comp.id);
                    standing.load(e.comp, div);
                    div.standings.push(standing)  
            }
            div.checkTeams();
            window.APP.reset();
        })
        .listen('TournamentDivisionCompRemoved', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
            
            var div = this.current_div;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) {
                    //check for old team with removed lead
                    team = div.getComp(e.id);
                    if(!team) return;
                    
                    team.id = e.team;
                };
                team.members = team.members.filter(function(value, index, arr){ return value.id != e.id });
                
                if(team.members.length == 0 ) {
                    div.standings = div.standings.filter(function(value, index, arr){ return value.id != team.id });
                }
            } else {
                div.standings = div.standings.filter(function(value, index, arr){ return value.id != e.id });
            }
            div.checkTeams();
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffle', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;

            e.standings.forEach(element => { 
                let standing = this.current_div.getComp(element.id);
                if(standing == null) return;
                standing.ordered = parseInt(element.ordered)
                if(standing.ordered > 0) {
                    standing.check(true)
                }
            });
            this.current_div.shuffled = 1;
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffleReset', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;
            this.current_div.shuffled = 0;
            window.APP.reset();
        })
        .listen('TournamentDivisionPlaces', (e) => {
            if(this.current_div != null && this.current_div.id != e.division_id) return;

            e.standings.forEach(element => { 
                let standing = this.current_div.getComp(element.id);
                if(standing == null) return;
                standing.place = parseInt(element.rank)
            });

            window.APP.reset();
        })
        ;
        
        postMaster.immediateGet("/tournament-judge/" + window.PARAM.judge_id + '/judge').then(res => res.json())
           .then(
              (result) => {
                if(result.timeZone) {
                    window.APP.setTimeOption('timeZone',result.timeZone);
                }
                if(result.uses_kg) {
                    window.APP.useKg(result.uses_kg);
                }
                this.image = result.image;
                this.tournament_name = result.tournament_name;
                this.tournament_id = result.tournament_id;
                this.judge_name = result.name;
                this.judge_thumb = result.thumb;
                this.judge_med = result.med;
                this.judge_check_in = result.judge_check_in;
                this.judge_is_checked_in = result.judge_is_checked_in;
                
                result.all_judges.forEach(element => { 
                     let j = new Judge(element)
                     pjudge.schedule.push(j)
                 });
                 
                 if(result.current_div) {
                    this.current_div = new Division(result.current_div.id);
                    this.current_div.load(result.current_div);
                 }
                 var holder = this;
                 if(this.current_div) {
                    this.current_div.standings.forEach((stand) => {
                        if(holder.input_type == 'type' && stand.temp_score == 0 && holder.current_div.score_prefill ) {
                            stand.temp_score = holder.current_div.score_prefill;
                        } 
                        if(holder.input_type == 'dropdown' && parseFloat(stand.temp_score) ==  parseFloat( holder.current_div.score_prefill) ) {
                            stand.temp_score = 0;
                        }
                    });            
                }
                
                document.getElementById("loading_area").style.display = "none";
                window.APP.forceUpdate()
              }
            );
    
        this.timer_special_value = 120;
        this.updateSpecialTimerValue = this.updateSpecialTimerValue.bind(this);
        
        if(window.innerWidth > 992) {
            this.input_type = 'type';
            
        } else {
            this.input_type = 'dropdown';
        }
    }
    
    setInputType(type) {
        this.input_type = type;
        var v= this.current_div;
        
        var holder = this;
        if(this.current_div) {
           this.current_div.standings.forEach((stand) => {
               if(holder.input_type == 'type' && stand.temp_score == 0 && holder.current_div.score_prefill ) {
                   stand.temp_score = holder.current_div.score_prefill;
               } 
               if(holder.input_type == 'dropdown' && parseFloat(stand.temp_score) ==  parseFloat( holder.current_div.score_prefill) ) {
                   stand.temp_score = 0;
               }
           });            
       }
        
        this.setState({input_type:type});
    }
    
    reload() {
        var pjudge = this;
        window.APP.needs_reload = 0;
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        
        var old_div = this.current_div;
        
        postMaster.immediateGet("/tournament-judge/" + window.PARAM.judge_id + '/judge')
            .then(res => res.json())
            .then(
              (result) => {
                  
                if(result.timeZone) {
                    window.APP.setTimeOption('timeZone',result.timeZone);
                }
                if(result.uses_kg) {
                    window.APP.useKg(result.uses_kg);
                }
                this.image = result.image;
                this.tournament_name = result.tournament_name;
                this.tournament_id = result.tournament_id;
                this.judge_name = result.name;
                this.judge_thumb = result.thumb;
                this.judge_med = result.med;
                this.judge_check_in = result.judge_check_in;
                this.judge_is_checked_in = result.judge_is_checked_in;
                pjudge.schedule = [];
                result.all_judges.forEach(element => { 
                    let j = new Judge(element);
                    pjudge.schedule.push(j);
                });
                 
                if(result.current_div) {
                    this.current_div = new Division(result.current_div.id);
                    this.current_div.load(result.current_div);
                }
                
                if(this.current_div) {
                this.current_div.standings.forEach( (x) => {
                        var old = old_div.standings.find( (fin) => fin.id == x.id  );
                        if(old == null) return;
                        x.temp_score = old.temp_score;
                     });
                 }
                 var holder = this;
                 if(this.current_div) {
                    this.current_div.standings.forEach((stand) => {
                        if(holder.input_type == 'type' && stand.temp_score == 0 && holder.current_div.score_prefill ) {
                            stand.temp_score = holder.current_div.score_prefill;
                        } 
                        if(holder.input_type == 'dropdown' && parseFloat(stand.temp_score) ==  parseFloat( holder.current_div.score_prefill) ) {
                            stand.temp_score = 0;
                        }
                    });            
                }
                
                document.getElementById("loading_area").style.display = "none";
                window.APP.forceUpdate();
              }
            );
    
        window.APP.reload();
        
    }
    
    updateSpecialTimerValue(e) {
        this.timer_special_value = parseInt(e.target.value);
        this.setState({timer_special_value:this.timer_special_value});
    }
    
    getActiveJudge() {
       var list = this.schedule.filter(function(e) { return e.is_current();} );
       if(list.length > 0) {
           return list[0];
       } else {
           return null;
       }
    }
    
    getUpcomingJudges() {
         return this.schedule.filter(function(e) { return e.is_future();});
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    timerModalOpen() {
        this.timerModal = true;
        this.setState({timerModal:true});
    }

    timerModalClose() {
        this.timerModal = false;
        this.setState({timerModal:false});
    }
    
    stopwatchModalOpen() {
        this.stopwatchModal = true;
        this.setState({stopwatchModal:true});
    }

    stopwatchModalClose() {
        this.stopwatchModal = false;
        this.setState({stopwatchModal:false});
    }
    
    alertsModalClose() {
        this.alertsModal = false;
        this.setState({alertsModal:false});
    }
    
    alertsModalOpen() {
        this.alertsModal = true;
        this.setState({alertsModal:true});
    }
    
    settingsModalOpen() {
        this.compAddSearchState = '';
        this.compAddSearch = [];
    
        this.settingsModal = true;
        this.setState({settingsModal:true});
    }

    settingsModalClose() {
         this.settingsModal = false;
        this.setState({settingsModal:false});
    }
   
   componentWillUnmount(){
        window.APP.unRegisterResize(this);
   }
   
   componentDidMount(){
       this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
    
        window.APP.registerResize(this);
   }
   
   resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
   }
   
   componentWillUnmount(){
        
   }
   
    
   render() {
        let showSidebar = this.messages;
        let holder = this;
         

        return (
           <Container>
     <Row>
        <Col className="header" xs={12} ref={ (headerRow) => { this.headerRow = headerRow } }>
            <Navbar expand="md" bg="dark"  variant="dark">
            { this.image && 
                <Navbar.Brand href="#home"><img
                src={this.image}
                className="tournamentlogo"
                alt="tournament logo"
              /></Navbar.Brand> }
            <Navbar.Brand className="tagname" href="#home"> {this.tournament_name}</Navbar.Brand>
                <Navbar.Toggle aria-controls="tools-navbar-nav" />   
                <Navbar.Collapse id="tools-navbar-nav">
                <Nav className="mr-auto">
                    <NavDropdown title="Tools" id="basic-nav-dropdown">
                          <NavDropdown.Item onClick={this.timerModalOpen}>Timer</NavDropdown.Item>
                          <NavDropdown.Item onClick={this.stopwatchModalOpen}>Stopwatch</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link onClick={() => this.settingsModalOpen()} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
                </Nav>
                </Navbar.Collapse>
                
                <Nav>
                    <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
                </Nav>
                
            </Navbar>

            <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table>
                <tbody>
                <tr>
                <th style={{ textAlign:'left' }} >Score Input</th>
                    <td>
                    <Form.Control value={this.input_type} onChange={(e) => { holder.setInputType(e.target.value);window.APP.reset(); }} as="select" size="lg" >
                        <option value="type">Type</option>
                        <option value="dropdown">Dropdown</option>
                    </Form.Control>
                    </td>
                </tr>
                
                        </tbody>        
                </Table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal size="lg" show={this.stopwatchModal} onHide={() => this.stopwatchModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Stopwatch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px', fontSize:'4em' }}>
                    {this.stopwatch.getDisplay()}</Col></Row>
                <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <ButtonGroup className="mr-2" aria-label="First group">
                    {!this.stopwatch.running && <Button varient='primary' onClick={()=>this.stopwatch.start()} ><i class="fas fa-play"></i></Button>}
                    {this.stopwatch.running && <Button variant="warning" onClick={()=>this.stopwatch.stop()}><i class="fas fa-pause"></i></Button> }
                    {!this.stopwatch.running && <Button variant="danger" onClick={()=>this.stopwatch.reset()}><i class="fas fa-sync"></i></Button>}
                    </ButtonGroup></Col></Row>
                    <Row>
                    <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                        <ButtonGroup className="mr-2" aria-label="First group" >
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-10) }>-10</Button>
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-5) }>-5</Button>
                            <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-1) }>-1</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(1) }>+1</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(5) }>+5</Button>
                            <Button variant="success" onClick={ ()=>this.stopwatch.alter(10) }>+10</Button>
                        </ButtonGroup>
                    </Col>
                    </Row>
                    {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <h2>Displays</h2>
                    <Table>
                        {
                        this.context.window_manager.insides.map((w, index) =>
                        <tr><td>Display {(index + 1)}</td>
                            <td>
                                {w.mode != 'stopwatch' && <Button variant="success" onClick={(e) => { w.setMode("stopwatch");window.APP.reset(); }}>Set to StopWatch</Button>}
                                {w.mode == 'stopwatch' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                            </td>
                        </tr> 
                        )
                        }
                        </Table></Col>
                    </Row>}
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.stopwatchModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={this.timerModal} onHide={() => this.timerModalClose()}>
                <Modal.Header closeButton>
                  <Modal.Title>Timer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'30px', fontSize:'4em' }}>
                    {this.timer.getDisplay()}</Col></Row>
                <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <ButtonGroup className="mr-2" aria-label="First group">
                    {!this.timer.running && <Button varient='primary' onClick={()=>this.timer.start()} ><i class="fas fa-play"></i></Button>}
                    {this.timer.running && <Button variant="warning" onClick={()=>this.timer.stop()}><i class="fas fa-pause"></i></Button> }
                    {!this.timer.running && <Button variant="danger" onClick={()=>this.timer.reset()}><i class="fas fa-sync"></i></Button>}
                    </ButtonGroup></Col></Row>
                    <Row>
                    <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                        <ButtonGroup className="mr-2" aria-label="First group" >
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-10) }>-10</Button>
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-5) }>-5</Button>
                            <Button variant="warning" onClick={ ()=>this.timer.alter(-1) }>-1</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(1) }>+1</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(5) }>+5</Button>
                            <Button variant="success" onClick={ ()=>this.timer.alter(10) }>+10</Button>
                        </ButtonGroup>
                    </Col>
                    </Row>

                    {!this.timer.running && <Row>
                        <Col xs={5} style={{ textAlign:'center',marginTop:'10px' }}>
                            Timer Value (seconds)
                        </Col>
                        <Col xs={4} style={{ textAlign:'center',marginTop:'10px' }}>
                            <Form.Control type="text"  onChange={this.updateSpecialTimerValue} value={this.timer_special_value}  />
                        </Col>
                        <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}>
                            <Button variant="danger" onClick={()=>{ this.timer.setTimer(this.timer_special_value); this.timer.reset()}}>Set</Button>
                        </Col>
                    </Row>}
                    {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <h2>Displays</h2>
                    <Table>
                        {
                        this.context.window_manager.insides.map((w, index) =>
                        <tr><td>Display {(index + 1)}</td>
                            <td>
                                {w.mode != 'timer' && <Button variant="success" onClick={(e) => { w.setMode("timer");window.APP.reset(); }}>Set to Timer</Button>}
                                {w.mode == 'timer' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                            </td>
                        </tr> 
                        )
                        }
                    </Table></Col>
                    </Row>}
                </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.timerModalClose()}>
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>

        </Col>   
        <Col  xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px'  }}>    
            {window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

              {window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
              </Alert>}

                {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Internet was lost - data may be outdated - {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                </Alert>}
                
                {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    When you have a moment please redownload data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                </Alert>}

                {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                    Please refresh at the nearest opportunity <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                </Alert>}
                
                {this.judge_check_in == 3 && this.judge_is_checked_in == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                    You need to check in to judge at this tournament
                </Alert>}
                
            <DivisionManagerDisplayPersonalJudge judge={this} ></DivisionManagerDisplayPersonalJudge>
        </Col>
        { showSidebar && 
        <Col xs={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'30px' }}>

            <Container>
                { this.messages && 
                <Row>
                    <Col xs={12}>

                    <Card  bg='dark' text='light' >
                    <Card.Header >Messages
                    <div style={{float:'right'}}>
                    <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                                </div>
                                </Card.Header>
                    <Card.Body style={{ color:'black' }} >
                        <MessagesDisplay></MessagesDisplay>  
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>}

            </Container>

        </Col>
        }

    </Row>


            <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">

              <Nav className="mr-auto">
              <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
              </Nav>
              
              {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
                </Nav>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
                </Nav>}

                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
                <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Ring Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
                </Nav>}
          
              <Nav style={{ flexDirection:'row' }}>
              { postMaster.queue.length == 0 && postMaster.onMessage == null && window.APP.connection_status > 0 && window.pusher_status == 1 && <Nav.Link  onClick={() => { holder.reload() } } ><span class={ (window.APP.connection_status == 1 ) ? 'text-primary':'text-secondary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Click To Download Data
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-download-alt"></i></OverlayTrigger></span></Nav.Link>}
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
          
              
              {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
                    
                   {window.APP.canFullscreen() && !window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.goFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Go Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-expand"></i></OverlayTrigger></span></Nav.Link>}
                
                    {window.APP.canFullscreen() && window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.exitFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Exit Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-compress"></i></OverlayTrigger></span></Nav.Link>}
                    
              
                <Navbar.Brand href="#home">
          <img
            src={ AppDomain + '/pass_M_Logo.png' }
            height="30"
            alt="MAT Action logo"
          />
        </Navbar.Brand>
              </Nav>
          </Navbar>
    </Container>
    );
  }
}

export class DivisionManagerDisplayPersonalJudge extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.judge = props.judge;
        this.selected_comp = null;
        this.select_comp = this.select_comp.bind(this);
        
        this.sort_by = 'order';
    }
    
    setOrder(sortBy) {
        this.sort_by = sortBy;
        this.setState({sort_by: this.sort_by});
    }
    
    select_comp(comp) {
        this.selected_comp = comp;
        this.setState({ selected_comp: comp });
    }
    
    uploadScores() {
        
        this.judge.current_div.needs_update = false;
    
        var calls = [];
        
        this.judge.current_div.standings.forEach(comp => {
            calls.push({ id: comp.id,score: comp.temp_score });
        });
        
        
       postMaster.toSendData('/tournament-judge-scores/'+  window.PARAM.judge_id + '/' +this.judge.current_div.id,calls )
       window.APP.forceUpdate();
    }

    render() {  
        var active = this.judge.getActiveJudge();
        var div = this.judge.current_div;
        
        //Has a ring but no division
        if(active && (!div || div.status == 0) ) {
            return <Container>
                <p style={{ marginTop:'50px',textAlign:'center',color: 'white',fontSize:'3em' }}>{active.on_name}</p>
                <p style={{ marginTop:'25px',textAlign:'center',color: 'white',fontSize:'1.2em' }}>Welcome { this.judge.judge_name }! Please wait for your ring to start and competitor check-in to be completed.</p>
            </Container>;  
        }
        
        if(active && div) {
            if (div.status == 1 || div.status == 2) {

                div.setTempPlaces();

                var used_standings = div.getCompOrder();
                if(this.sort_by == 'name') { 
                    used_standings = used_standings.sort(function(a, b){ 
                        return a.name.localeCompare(b.name);
                    });
                }
                
                if(this.sort_by == 'score') { 
                    used_standings = used_standings.sort(function(a, b){ 
                        if (a.temp_score < b.temp_score) return -1;
                        if (a.temp_score > b.temp_score) return 1;
                        return 0;
                    });
                }
                
                if(this.sort_by == 'rank') { 
                    used_standings = used_standings.sort(function(a, b){
                        if (a.temp_place > b.temp_place) return 1;
                        if (a.temp_place < b.temp_place) return -1;
                        return 0
                    });
                }
                

                

                var score_options = [];
                var all_score_options = [];
                var on = null;
                
                var max_score = '-1';
                var min_score = '-1';

                if(div.quick_max_score) {
                    max_score = parseFloat(div.quick_max_score);
                }

                if(div.quick_min_score) {
                    min_score = parseFloat(div.quick_min_score);
                }

                if(max_score == -1 && div.max_score) {
                    max_score = parseFloat(div.max_score);
                }

                if(min_score == -1 && div.min_score) {
                    min_score = parseFloat(div.min_score);
                }
                
                if(div.min_score && div.max_score) {
                    on = div.max_score;

                    while (on >= div.min_score) { 
                        if(div.score_rules != 'int') {
                            all_score_options.push( (Math.round(on * 100.0) / 100.0).toFixed(2) )
                        } else {
                            all_score_options.push( on )
                        }

                        if(div.score_rules != 'int') {
                            on = on - 0.01;
                        } else {
                            on = on - 1;
                        }                                                                                                                                                                                                                                                                
                    }

                    if(!isNaN(div.score_dq ) && parseInt(div.score_dq) > 0 ) {
                        if(div.score_rules != 'int') {
                            var d_val = (Math.round(div.score_dq * 100.0) / 100.0).toFixed(2);
                            if(!all_score_options.includes(d_val) ) all_score_options.shift(d_val);
                        } else {
                            var d_val = (parseInt(div.score_dq));
                            if(!all_score_options.includes(d_val) ) all_score_options.shift(d_val);
                        }
                    }
                }
                
                if(min_score != -1 && max_score != -1) {
                    on = max_score;

                    while (on >= min_score) { 
                        if(div.score_rules != 'int') {
                            score_options.push( (Math.round(on * 100.0) / 100.0).toFixed(2) )
                        } else {
                            score_options.push( on )
                        }

                        if(div.score_rules != 'int') {
                            on = on - 0.01;
                        } else {
                            on = on - 1;
                        }                                                                                                                                                                                                                                                                
                    }
                }
                
                var score_dq = 0;
                
                if(!isNaN(div.score_dq) ) {
                    score_dq = div.score_dq;
                }
                
                const standingsList = used_standings
                                .map((comp) => {

                                if(comp.status == 4) {
                                    comp.temp_score = score_dq;
                                }

                        return   <tr className={ ( div.current_comp == comp ? 'table-success':'' ) } style={{ color: ( div.current_comp == comp ? 'black':'white' ) }} >
                            <td>{comp.ordered}</td>
                            <td style={{ width:'50px' }}>{comp.photo != '' &&  <img style={{ width: '40px'}} src={comp.photo} /> }</td>
                            <td style={{ width:'50px' }}>
                                    {comp.country != '' && <OverlayTrigger placement={'top'}
                              overlay={
                                <Tooltip>
                                  {comp.country_name}
                                </Tooltip>
                              }
                              ><img className="d-none d-md-block" style={{ 'width':'50px' }} src={comp.country}   ></img></OverlayTrigger> }
                               </td>                        
                            <td style={{ textAlign:'left' }}>{comp.name} {comp.status == 4 && <span>&nbsp;<Badge pill variant="danger">DQ</Badge></span>}</td>
                            <td style={{ textAlign:'left' }}>
                                <Button onClick={() => { this.select_comp(comp) } } variant="light"><span className="d-none d-md-inline">Info</span> <i class="fas fa-info-circle"></i></Button>
                            </td>
                            <td style={{ minWidth:'5em' }}>
                                {(this.judge.input_type == 'type' || all_score_options.length == 0 ) && <Form.Control disabled={ comp.status == 4 ? 'disabled' : '' } onFocus={ (e) => {  e.target.setSelectionRange( e.target.value.toString().length, e.target.value.toString().length); var t=e.target; setTimeout(function(){ t.setSelectionRange(t.value.toString().length, t.value.toString().length); }, 10); } } className='scoreinput' as="input" type="text" value={comp.temp_score} onChange={(event)=>{div.needs_update = true; comp.setTempScore(event.target.value, div.score_rules); } }  >
                                </Form.Control>}
                                {(this.judge.input_type == 'dropdown' && all_score_options.length > 0 ) && <Form.Control disabled={ comp.status == 4 ? 'disabled' : '' } as="select" value={(div.score_rules == 'int') ? comp.temp_score : (Math.round(comp.temp_score * 100.0) / 100.0).toFixed(2)} onChange={(event)=>{div.needs_update = true; comp.setTempScore(event.target.value, div.score_rules); } }  >
                                    <option value={ 0 }>---</option>
                                    {all_score_options.map((x) => <option value={x}>{x}</option> )}
                                </Form.Control>}
                                <div>
                                    <small class="text-danger">&nbsp;
                                    { parseFloat(comp.temp_score) > div.max_score   &&                               
                                        <span>Above Max</span>                      
                                    }

                                    { parseFloat(comp.temp_score) < div.min_score && comp.temp_score > 0  && parseFloat(comp.temp_score) != div.score_dq &&                                
                                        <span>Below Min</span>                                    
                                    }
                                    &nbsp;
                                    </small> 
                                </div>
                            </td>
                            <td>
                            { comp.temp_place != 0  && parseFloat(comp.temp_score) != div.score_dq && (div.score_rules == 'int'  || comp.temp_score == 0 || comp.temp_score == 10 || comp.temp_score.toString().length > 3  ) && <div>({ comp.temp_place})&nbsp;
                            {
                             div.temp_ties_at.includes(comp.temp_place) && 
                                        <OverlayTrigger
                                            key={'left'}
                                            placement={'left'}
                                            overlay={
                                              <Tooltip>
                                                You must break this tie
                                              </Tooltip>
                                            }
                                          >
                                        <i class="fas fa-exclamation-triangle text-danger"></i>
                                        </OverlayTrigger>
                                                                                 }</div>
                                }
                            </td>
                            <td  className="d-none d-md-table-cell">
                                { score_options.length > 0 && <div>
                                    <div className="mb-2">
                                        {
                                        score_options.map((x) => <Button style={{ margin:'3px' }} onClick={()=>{ div.needs_update = true; comp.setTempScore(x, div.score_rules); } }>{x}</Button>)
                                        }
                                    </div>
                                </div>}
                            </td>
                               </tr>;
                           }
                         );

                return <Container>
                        <Row  style={{ textAlign:'center' }}>
                        <Col xs={12}>
                            {this.selected_comp != null && 
                            <Modal size="lg" show={this.selected_comp != null} onHide={() => this.select_comp(null)}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>{this.selected_comp.name}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Table>
                                    <tbody>
                                    <tr><th>Country</th><td>{this.selected_comp.country_name}</td></tr>
                                    <tr><th>Weight</th><td>{this.selected_comp.getWeightString()}</td></tr>
                                    <tr><th>Age</th><td>{this.selected_comp.age}</td></tr>
                                    <tr><th>Gender</th><td>{this.selected_comp.gender}</td></tr>
                                    <tr><th>Rank</th><td>{this.selected_comp.rank}</td></tr>
                                    <tr>
                                        <th style={{ textAlign:'center' }} colspan='2'>
                                        Schools
                                        </th>
                                    </tr>
                                    {this.selected_comp.schoolteams && this.selected_comp.schoolteams.length > 0 &&
                                    this.selected_comp.schoolteams.map(
                                            (input) => 
                                                <tr><td colspan="2">{input}</td></tr>
                                            )
                                    }
                                    </tbody>        
                                    </Table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button variant="secondary" onClick={() => this.select_comp(null)}>
                                        Close
                                      </Button>
                                    </Modal.Footer>
                            </Modal>}
                            <Card bg='dark'
                            style={{ margin:'1%', width:'98%'  }}
                                text= 'light'
                              >
                               <Card.Header >{ div.name } { (div.status == 1) ? 'Competing' : 'Scoring' }</Card.Header>
                                <Card.Body style={{ padding:'0.2rem', paddingBottom: '100px' }} >
                                    <Container>
                                        <Row  style={{ textAlign:'center' }}>
                                            <Table variant="dark" responsive>
                                            <thead>
                                                <tr>
                                                    <th style={{ whiteSpace:'nowrap' }} onClick={()=>this.setOrder('order')}>{this.sort_by != 'order' && <i class="fas fa-sort"></i>} Order</th>
                                                    <th style={{ whiteSpace:'nowrap' }} colspan="4" onClick={()=>this.setOrder('name')}>{this.sort_by != 'name' && <i class="fas fa-sort"></i>} Competitor</th>
                                                    <th style={{ whiteSpace:'nowrap' }} onClick={()=>this.setOrder('score')}>{this.sort_by != 'score' && <i class="fas fa-sort"></i>} Score</th> 
                                                    <th style={{ whiteSpace:'nowrap' }} onClick={()=>this.setOrder('rank')}>{this.sort_by != 'rank' && <i class="fas fa-sort"></i>} Place</th> 
                                                    <th style={{ whiteSpace:'nowrap' }} className="d-none d-md-table-cell" >Quick Scores</th> 
                                                </tr>                                                                                                                                                                                 
                                            </thead>
                                            
                                                       {standingsList}    
                                            {div.status == 2 && div.temp_ties_at.length == 0 &&
                                                <tr>
                                                    <td colspan='8'>
                                                    <Button variant={ (div.needs_update) ? 'success' : 'secondary' } onClick={() => this.uploadScores() }>Submit Scores</Button>
                                                   </td>
                                                </tr>    
                                            } 
                                            
                                            {div.status == 2 && div.temp_ties_at.length != 0 &&
                                                <tr>
                                                    <td colspan='8' class='text-danger' style={{ textAlign:'center' }}>
                                                        You have an invalid tie
                                                   </td>
                                                </tr>    
                                            }
                                            
                                            {div.status != 2 &&
                                                <tr>
                                                    <td colspan='8' style={{ textAlign:'center' }}>
                                                        The division must finish before you can submit scores
                                                   </td>
                                                </tr>    
                                            } 

                                            </Table>
                                        </Row>                      
                                    </Container>
                                </Card.Body>
                          </Card>
                          </Col>
                        </Row>
                        </Container>;

                }
                if (div.status == 3) {
                    return <Container>
                        <p style={{ marginTop:'50px',textAlign:'center',color: 'white',fontSize:'3em' }}>{active.on_name}</p>
                        <p style={{ marginTop:'25px',textAlign:'center',color: 'white',fontSize:'1.2em' }}>Thank you for judging { this.judge.judge_name }! Please wait for the next division.</p>
                    </Container>;  
                }
            }
            
        
        
        
        //Has no active assignment
        var sch = this.judge.getUpcomingJudges().map(s=><tr><td>{ s.on_name }</td><td>{s.planned_start.toLocaleDateString(window.APP.date_locale,window.APP.date_options) } {s.planned_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td></tr>)
        return <Container>
            <p style={{ marginTop:'30px',textAlign:'center',color: 'white',fontSize:'1.3em' }}>Welcome { this.judge.judge_name }! Please see your current schedule below and have the scorekeeper check you in when ready.</p>
                <Table variant="dark" striped bordered hover>
                    <thead>
                      <tr>
                        <th>Ring</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sch}
                    </tbody>
                  </Table>
        </Container>;  
    }
}