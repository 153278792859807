import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {FightAvCall} from '../models/fights'

import Spinner from 'react-bootstrap/Spinner';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';

import postMaster from '../logic/postmaster'
import {TimeDisplay} from './TimeDisplay';
import {StopWatch, Timer} from '../logic/clocks';
import {AppContext, AppDomain, seeds} from '../constants';
import {FightCall, Fight, BracketChart} from '../models/fights'
import {Standing} from '../models/standing'
import {Division} from '../models/division'
import {Judge} from '../models/judge'
import {MessagesDisplay, Message} from '../models/messages'
import {swapFights, customRandom} from '../functions';
import {joinMeeting} from '../logic/meeting';

import {DivisionManagerDisplay} from './scorekeepermains/DivisionManagerDisplay';

import {DivisionGraphicsDisplay} from './scorekeepermains/DivisionGraphicsDisplay';



export class Scorekeeper extends React.Component { 
    
    static contextType = AppContext;
    
    reload() {
        if(!window.APP.ring) {
            alert('Error!  Refresh recommended instead');
            return;
        }
        
        if(window.APP.connection_status < 1 ) {
            alert('Error!  Incorrect internet status');
            return;
        }
        
        if(window.pusher_status == 0 ) {
            alert('Error!  Incorrect connection status');
            return;
        }
        
        window.APP.needs_reload = 0;
        window.APP.connection_status = 2;        
        document.getElementById("loading_area").style.display = "initial";
        window.APP.reload();
        
        window.APP.ring.load();
    }
    
    constructor(props) {
        super(props);        
        this.ring = props.ring
        this.div_area = null;
        var ring = this.ring
        
        var t = this;
        
        window.Echo.private('ring.' + ring.id) 
        .listen('CustomMessage', (e) => {        
            window.APP.messages_manager.addMessage(new Message(e.message.subject, e.message.body  ));    
            window.APP.reset();
        })
        .listen('RefreshRequest', (e) => {        
            if(e.type == 'data') {
                window.APP.needs_reload = 1;
        
                if( (e.force == 1 && !postMaster.leftToSend() && (t.ring == null || t.ring.canRefresh() ) ) || e.force == 2) {
                   t.reload();
                }
            }
    
            if(e.type == 'refresh') {
                window.APP.needs_refresh = 1;
                
                if( (e.force == 1 && !postMaster.leftToSend()  && (t.ring == null || t.ring.canRefresh() ) ) || e.force == 2) {
                    window.location.reload();
                }
            }
            
            window.APP.reset();
        })
        .listen('TournamentJudgesUpdate', (e) => {   
            e.sent.forEach(j => {
               var jf = ring.a_judges.filter(function(e) {return e.id == j.id;});
               if(jf.length > 0) {
                   jf[0].update(j)
               } else {
                   ring.a_judges.push(new Judge(j))
               }
            });
            window.APP.reset();
        })
        .listen('TournamentDivisionStatus', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) { div.status = e.status; div.is_final = e.is_final; }
            window.APP.reset();
        })
        .listen('ProtestUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) div.updateProtest(e);
            window.APP.messages_manager.addMessage(new Message("Protest Update", e.message  ));
            window.APP.reset();
        })
        .listen('TournamentRingCurrentDivision', (e) => {
            let div = ring.getDivision(e.division_id);
            ring.current_division = div
            window.APP.reset();
        })
        .listen('TournamentDivisionCurrentComp', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(div.current_comp && div.current_comp.stopWatch.master && div.current_comp.stopWatch.running) {
                div.current_comp.stopWatch.stop()
            }
            
            div.current_comp = div.getComp(e.standing_id)
            window.APP.reset();
        })
        .listen('TournamentStandingUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) return;
                
                team.name = e.team_name;
                team.seeding = e.comp.seeding;
                
                let comp = team.getMember(e.standing_id);
                if(!comp) return;
                comp.update(e.comp);  
                
                div.checkTeams();
            } else {
                let comp = div.getComp(e.standing_id)
                if(!comp) return;
                comp.update(e.comp);  
            }
            window.APP.reset();
        })
        .listen('TournamentStandingScore', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            comp.setScore(e.num, parseFloat(e.value));           
            window.APP.reset();
        })
        .listen('TournamentDivisionAllScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                comp.setScore(s.num, parseFloat(s.score));
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentDivisionFixedScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                if(s.num < 0) {
                    if(comp.up_scores[s.num * -1] == null) {
                        comp.up_scores[s.num* -1] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.up_scores[s.num* -1].score = s.score;
                    comp.up_scores[s.num* -1].og_score = s.og_score;
                    comp.up_scores[s.num* -1].drop = s.drop;
                    comp.up_scores[s.num* -1].judge_id = s.judge_id;
                } else {
                    if(comp.scores[s.num] == null) {
                        comp.scores[s.num] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.scores[s.num].score = s.score;
                    comp.scores[s.num].og_score = s.og_score;
                    comp.scores[s.num].drop = s.drop;
                    comp.scores[s.num].judge_id = s.judge_id;
                }
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentStandingTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            
            if(e.running == 'sync' || e.tick == 'sync') {
                if(comp.stopWatch.master && (comp.stopWatch.left != 1000 || comp.stopWatch.running )) {
                    comp.stopWatch.update();
                }
                return;
            }
            
            comp.stopWatch.master = false;
            comp.stopWatch.sync(e.running, e.tick );
            
            window.APP.reset();
        })
        .listen('TournamentDivisionCompAdd', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                    let team = div.getComp(e.team)
                    if(team == null) {
                            team = new Standing(e.comp.id);
                            team.name = e.team_name;
                            team.seeding = e.comp.seeding;
                            div.standings.push(team) 
                    }

                    let member = new Standing(e.comp.id);
                    member.load(e.comp, div);
                    team.members.push(member)

                    div.checkTeams();
            } else {
                    let standing = new Standing(e.comp.id);
                    standing.load(e.comp, div);
                    div.standings.push(standing)  
            }
            div.checkTeams();
            window.APP.reset();
        })
        
        .listen('TournamentDivisionCompRemoved', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) {
                    //check for old team with removed lead
                    team = div.getComp(e.id);
                    if(!team) return;
                    
                    team.id = e.team;
                };
                team.members = team.members.filter(function(value, index, arr){ return value.id != e.id });
                
                if(team.members.length == 0 ) {
                    div.standings = div.standings.filter(function(value, index, arr){ return value.id != team.id });
                }
            } else {
                div.standings = div.standings.filter(function(value, index, arr){ return value.id != e.id });
            }
            div.checkTeams();
            window.APP.reset();
        })
        
        .listen('TournamentDivisionShuffle', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.ordered = parseInt(element.ordered)
            });
            div.shuffled = 1;
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffleReset', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            div.shuffled = 0;
            window.APP.reset();
        })
        
        .listen('TournamentRingDivisionRemove', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
        
            window.APP.messages_manager.addMessage(new Message( 'Division Removed', div.name + ' was removed.'  ));
        
            ring.divisions = ring.divisions.filter(function(value, index, arr){ return value.id != e.division_id });
            window.APP.reset();
        })
       .listen('TournamentRingDivisionAdd', (e) => {
            postMaster.toSend("/tournament-ring/" + ring.id + '/division/' + e.division_id,
    
            function(p) {
                p.then(res => res.json())
                .then((result) => {
                    if(result.call_pack != null && result.call_pack != '' && (ring.available_call_packs[result.call_pack] == undefined || ring.available_call_packs[result.call_pack] == null) &&  result.call_pack_full != null &&  result.call_pack_full != undefined ) {
                        var element = result.call_pack_full;
                        var p = {
                           uuid : element.uuid,
                           name : element.name,
                           available_calls: []
                        };

                        element.calls.forEach(c => { 
                           p.available_calls.push(
                                   new FightAvCall(c)
                           );
                        });
                        ring.available_call_packs[element.uuid] = p;
                    }
                    
                    let div = new Division(result.id);
                    div.ring = ring;
                    div.load(result);
                    ring.divisions.push(div);
                    div.ring = ring;
                    window.APP.messages_manager.addMessage(new Message( 'Division Added', 'Added ' + div.name  ));
                    window.APP.reset();
                });
            });
        })
        .listen('TournamentDivisionUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return; 
            
            for (const [key, value] of Object.entries(e.changes)) {
                if(key == 'break_ties_till' || key == 'min_place' || key == 'min_size' || key == 'max_size' || key == 'is_team') {
                    div[key] = parseInt(value);   
                } else {
                    div[key] = value;    
                }
                
            }
            
            div.standings.forEach((s) => {
                s.division = div;
            });
            
            window.APP.reset();            
        })
        .listen('TournamentRingScheduleUpdate', (e) => {    
            e.updates.forEach(element => { 
                let div = ring.getDivision(element.division_id);
                if(!div) return; 
                
                div.estimated_start = new Date(element.est_start);    
                div.estimated_stop = new Date(element.est_stop); 
                div.duration_estimate = parseInt(element.duration_estimate);
                div.ordered = parseInt(element.ordered);
            });            
            window.APP.reset();            
        })
        .listen('TournamentFightCall', (e) => {                
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
    
    
            let acall = ring.getAVCalls().find(element => element.id == e.call.call_id);
            
            let comp = null;
            
            if(e.call.person_id == f.comp1.id) {
               comp = f.comp1;
            }  else {
                comp = f.comp2;
            }
            
            let call = new FightCall({
                id: e.call.id,
                opposing_points: e.call.opposing_points,
                points: e.call.points,
                dq: e.call.dq,
                warning: e.call.warning,
                person: comp,
                availableCall: acall,
                winner: e.call.winner,
                time_change: e.call.time_change,
                end_round: e.call.end_round
            });
    
    
            f.receiveCall(call)
            window.APP.reset();
        })
        
        .listen('TournamentFightTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
            
            if(e.running == 'sync' || e.tick == 'sync') { 
                if(f.timer.master && f.timer.force_master && (f.timer.current != f.round_timer * 1000 || f.timer.running  )) {
                    f.timer.update();
                }
                return;
            }
            
            if(!f.timer.force_master) {
                f.timer.master = false;
            }
            
            
            f.timer.sync(e.running, e.tick );
            
            if(e.round) {
                f.current_round = e.round
            }
            
            if(e.break) {
                f.on_break = e.break
            }
            
            window.APP.reset();
        })
        
        .listen('TournamentFightSwapSides', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            f.swapped = parseInt(e.value);
            
            window.APP.reset();
        })
        
        .listen('TournamentRingCurrentFight', (e) => {
            
            var div = ring.getDivision(e.division_id);
            if(!div) return;
            div.fights
                    .filter(x=>{return x.in_ring_name == e.ring_name;})
                    .forEach(x=>{x.in_ring_name = ''; });
        
            if(ring.id != e.ring_id) {     
                let f = div.getFight(e.fight_id);
                if(f) {
                    f.in_ring_name = e.ring_name;
                }
            } else {
                ring.current_fight = div.getFight(e.fight_id);
            }
            window.APP.reset();
        })
        
        .listen('TournamentDivisionNewBrackets', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            div.fights = [];
            div.shuffled = 1;
            if(e.top_fights) {
                e.top_fights.forEach((t)=>{
                    div.addFight(t);
                });
            }
            window.APP.reset();
        })
        
        .listen('TournamentDivisionFightSwap', (e) => {  
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f1 = div.getFight(e.one);
            let f2 = div.getFight(e.two);
            if(f1 == null || f2 == null) {
                return;
            }
            swapFights(f1, f2);
            window.APP.reset();
        })
        
        .listen('TournamentDivisionPlaces', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.place = parseInt(element.rank);
            });

            window.APP.reset();
        })
        
        .listen('AlertForward', (e) => {
           ring.alertsManager.receiveEvent(e)
            window.APP.reset();
        })
        
        .listen('TournamentDivisionImportRequest', (e) => {
            if(e.division_id == '' || !e.division_id) {
                ring.ring_import_request = null;
            } else {            
                ring.ring_import_request = {
                    uuid:e.division_id,
                    code:e.division_code,
                    name:e.division_name,
                };
            }
           window.APP.reset();
        })
        
        .listen('TournamentDivisionImported', (e) => { 
            if(e.division_id) {
                postMaster.toSend("/tournament-ring/" + ring.id + '/division/' + e.division_id,

                function(p) {
                    p.then(res => res.json())
                    .then((result) => {
                        if(result.call_pack != null && result.call_pack != '' && (ring.available_call_packs[result.call_pack] == undefined || ring.available_call_packs[result.call_pack] == null) &&  result.call_pack_full != null &&  result.call_pack_full != undefined ) {
                            var element = result.call_pack_full;
                            var p = {
                               uuid : element.uuid,
                               name : element.name,
                               available_calls: []
                            };

                            element.calls.forEach(c => { 
                               p.available_calls.push(
                                       new FightAvCall(c)
                               );
                            });
                            ring.available_call_packs[element.uuid] = p;
                        }

                        let div = new Division(result.id);
                        div.ring = ring;
                        div.load(result);

                        ring.ring_import = div;
                        ring.ring_import_request = null;

                        window.APP.messages_manager.addMessage(new Message( 'Division Imported', 'Added ' + div.name  ));
                        window.APP.reset();
                    });
                });
            } else {
                ring.ring_import = null;
                ring.ring_import_request = null;
                window.APP.reset();
            }
        })
        ;
        
        this.manager = props.manager;
        
        this.state = {
            showWindowPortal: false
        };
        
        this.stopwatch = new StopWatch();
        this.timer = new Timer(120);
        
        window.APP.timer = this.timer;
        window.APP.stopwatch = this.stopwatch;
        
        this.settingsModal = false;
        this.alertsModal = false;
        
        this.randomNumber = null;
        this.maxRandomNumber = 2;
        
        this.stopwatchModal = false;
        this.timerModal = false;
        this.scheduleModal = false;
        this.randomModal = false;
        
        this.keyBindingsModal = false;
        
        this.messages =false;
        
        this.compAddSearch = [];

        this.compAddSearchState = '';
        this.key_bindings=[
        {
            action:'timer',
            name:'Start/Stop Timer',
            key:'Space',
            description:'Start and stop the time for comeptitor stopwatches and sparring timers'
        },
        {
            action:'Forward',
            name:'Next Competitor',
            key:'ArrowRight',
            description:'When doing a scores division, moves to the next competitor'
        },
        {
            action:'Back',
            name:'Previous Competitor',
            key:'ArrowLeft',
            description:'When doing a scores division, moves to the previous competitor'
        },
        {
            action:'Left_Up',
            name:'Left Add Point',
            key:'ArrowLeft',
            description:'Adds one point for the left competitor in a sparring match'
        },
        {
            action:'Left_Down',
            name:'Left Remove Point',
            key:'A',
            description:'Subtracts one point for the left competitor in a sparring match'
        },
        {
            action:'Right_Up',
            name:'Right Add Point',
            key:'ArrowRight',
            description:'Adds one point for the right competitor in a sparring match'
        },
        {
            action:'Right_Down',
            name:'Right Remove Point',
            key:'S',
            description:'Subtracts one point for the right competitor in a sparring match'
        }
        ];

        this.addWindow = this.addWindow.bind(this);  
        this.settingsModalOpen = this.settingsModalOpen.bind(this);  
        this.settingsModalClose = this.settingsModalClose.bind(this);  
        this.judgesModalOpen = this.judgesModalOpen.bind(this);
        
        this.resetRandom = this.resetRandom.bind(this);
        this.getRandom = this.getRandom.bind(this);
        
        this.keyBindingsModalOpen = this.keyBindingsModalOpen.bind(this);     
        this.keyBindingsModalClose = this.keyBindingsModalClose.bind(this);   
        
        this.judgesModalClose = this.judgesModalClose.bind(this);
        this.alertsModalClose = this.alertsModalClose.bind(this);
        this.alertsModalOpen = this.alertsModalOpen.bind(this);
        
        this.scheduleModalOpen = this.scheduleModalOpen.bind(this);
        
        this.stopwatchModalOpen = this.stopwatchModalOpen.bind(this);
        
        this.timerModalOpen = this.timerModalOpen.bind(this);
        
        
        this.randomModalOpen = this.randomModalOpen.bind(this);
        this.randomModalClose = this.randomModalClose.bind(this);
        
        this.messagesToggle = this.messagesToggle.bind(this);
        this.competitorSearchList = this.competitorSearchList.bind(this);
        this.addCompSearchUpdate = this.addCompSearchUpdate.bind(this);
        
        this.maxRandomNumberUpdate = this.maxRandomNumberUpdate.bind(this);
        this.teamCompSearchUpdate = this.teamCompSearchUpdate.bind(this);
        this.teamAddSearchState = '';
        this.judgesAddSearchState = ''
        this.addJudgeSearchUpdate = this.addJudgeSearchUpdate.bind(this);
        this.addCompToDivision = this.addCompToDivision.bind(this);
        
        this.checkin_comp_late = this.checkin_comp_late.bind(this);
        
        this.startCustomFight = this.startCustomFight.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.mainAreaHeight = 650;
        this.judgeAddSearch = [];
        
        this.addUnkownJudge = this.addUnkownJudge.bind(this);
        
        this.addCenterValue = false;
        this.addCenterUpdate = this.addCenterUpdate.bind(this);
        this.reload = this.reload.bind(this);
        
        this.isgraphics = false;
        
        this.tvModal = false;
        this.tvInstructionsModal = false;
        
        this.protest = null;
        
        this.resize = this.resize.bind(this);
        
        this.timer_special_value = 120;
        this.updateSpecialTimerValue = this.updateSpecialTimerValue.bind(this);
        
        this.score_input_type = 'type';
    }
    
    updateSpecialTimerValue(e) {
        this.timer_special_value = parseInt(e.target.value);
        this.setState({timer_special_value:this.timer_special_value});
    }
    
    activelySettingKeyBinding() { 
        var active = false;
        this.key_bindings.forEach((e)=>{
            if(e.key == '') {
                active = true;
            }
        })
        return active;
    }
    
    checkin_comp_late(div, comp) {
        if(comp.ordered == 0 || comp.ordered == null) { 
            var min = 0;
        
            div.standings.forEach( (value, index, self2) => {
                if(value.ordered < min) {
                    min= value.ordered;
                }
            });
            comp.ordered = min - 1;
            
            if(comp.ordered == 0 || comp.ordered == null) {
                comp.ordered = -1;
            }
            
            div.completeShuffle();
        }
        
        if(comp.members.length == 0) {
            comp.check(true);        
            postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + comp.id + '/checkin/' + true )
        } else {
            comp.members.forEach( (st, index, self2) => {
                comp.check(true);        
                postMaster.toSend('/tournament-ring/'+ this.ring.id + '/standing/' + st.id + '/checkin/' + true )
            });
        }
                                                                                                                                    
        this.setState({current_division: this.division});
    }
    
    messagesToggle() {
        this.messages = ! this.messages;
        this.setState({messages:this.messages});
    }
    
    scheduleModalOpen() {
        this.scheduleModal = true;
        this.setState({scheduleModal:true});
    }

    scheduleModalClose() {
        this.scheduleModal = false;
        this.setState({scheduleModal:false});
    }
    
    timerModalOpen() {
        this.timerModal = true;
        this.setState({timerModal:true});
    }

    timerModalClose() {
        this.timerModal = false;
        this.setState({timerModal:false});
    }
    
    stopwatchModalOpen() {
        this.stopwatchModal = true;
        this.setState({stopwatchModal:true});
    }

    stopwatchModalClose() {
        this.stopwatchModal = false;
        this.setState({stopwatchModal:false});
    }
    
    
    randomModalOpen() {
        this.randomModal = true;
        this.resetRandom();
        this.setState({randomModal:true});
    }

    randomModalClose() {
        this.randomModal = false;
        this.setState({randomModal:false});
    }
    
    alertsModalClose() {
        this.alertsModal = false;
        this.setState({alertsModal:false});
    }
    
    alertsModalOpen() {
        this.alertsModal = true;
        this.setState({alertsModal:true});
    }
    
    settingsModalOpen() {
        this.compAddSearchState = '';
        this.compAddSearch = [];
    
        this.settingsModal = true;
        this.setState({settingsModal:true});
    }
    
    settingsModalClose() {
        this.settingsModal = false;
        this.setState({settingsModal:false});
    }
    
    resetRandom() {
        this.randomNumber = null;
        this.setState({randomNumber:this.randomNumber});
    }

    getRandom() {
        
        this.randomNumber = customRandom(this.maxRandomNumber) + 1;
        this.setState({randomNumber:this.randomNumber});
    }
    
    tvModalOpen() {
        this.tvModal = true;
        this.setState({tvModal:true});
    }

    tvModalClose() {
        this.tvModal = false;
        this.setState({tvModal:false});
    }
    
    tvInstructionsModalOpen() {
        this.tvInstructionsModal = true;
        this.setState({tvInstructionsModal:true});
    }

    tvInstructionsModalClose() {
        this.tvInstructionsModal = false;
        this.setState({tvInstructionsModal:false});
    }
    
    keyBindingsModalOpen() {
        this.settingsModal = false;
        this.keyBindingsModal = true;
        this.setState({settingsModal:false,keyBindingsModal:false });
    }

    keyBindingsModalClose() {
        if(this.activelySettingKeyBinding() ) {
            return;
        }
    
        this.keyBindingsModal = false;
        this.setState({keyBindingsModal:false});
    }
    
    keyBindingsArea() {
       return this.key_bindings.map( (prop) => {
                return <tr>
                <th>
                 <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        {prop.description}
                      </Tooltip>
                    }
                    ><div>
                    {prop.name}</div></OverlayTrigger>
                </th>
                <td>{prop.key}</td>
                    <td>
                        {prop.key != '' && !this.activelySettingKeyBinding() && <Button onClick={() => { prop.key=''; window.APP.reset(); }}>Set</Button>}
                        {prop.key == '' && <div><Button variant='danger' onClick={() => { prop.key='No Key'; window.APP.reset(); }}>No Key</Button></div>}
                    </td>
                </tr>;
                
        });
       
    }
    
    judgesModalOpen() {    
        this.judgesModal = true;
        this.setState({judgesModal:true});
    }

    judgesModalClose() {
        this.judgesModal = false;
        this.setState({judgesModal:false});
    }
    
    addCenterUpdate(event) {
        this.addCenterValue = event.target.checked;
    }
    
    addWindow() {
        this.manager.addWindow();
    }
   
    
    addJudgeToDivision(comp) {
        postMaster.immediatePost('/tournament-ring/' + this.ring.id + '/add-ejudge',{ 
                        name:comp.uuid,
                        center:this.addCenterValue
                    })
        .then(
             (result) => {  
                this.judgesAddSearchState = '';
                this.judgeAddSearch = [];
                window.APP.reset();
             }
           );
            this.judgesAddSearchState = '';
            this.judgeAddSearch = [];
            window.APP.reset();
    }
    
    judgesSearchList() { 
        return this.judgeAddSearch.map( (comp ) => 
        <ListGroup.Item style={{color:'black'}} onClick={ () => this.addJudgeToDivision(comp)  }  as="li">
            {this.ring != null && this.ring.judge_check_in > 1 && comp.checked_in == 0 && <span><i class={'text-danger fas fa-vote-yea'} ></i>&nbsp;</span>}
            {this.ring != null && this.ring.judge_check_in > 1 && comp.checked_in == 1 && <span><i class={'text-success fas fa-vote-yea'} ></i>&nbsp;</span>}
            
            {comp.name}
            </ListGroup.Item>
          );
    }
   
    addJudgeSearchUpdate(event) {
        var t = this;
        this.judgesAddSearchState = event.target.value;
        this.setState( {judgesAddSearchState : this.judgesAddSearchState} );
        var k = event.target.value;
        setTimeout(function(){ t.addJudgeSearchUpdateCheck(k); }, 500 ); 
    }
    
    addJudgeSearchUpdateCheck(value) {
        if(value != this.judgesAddSearchState) { 
            return;
        }
        
        if(this.ring.tournament_id) {
         postMaster.immediateGet("/tournament/" + this.ring.tournament_id + '/judgesearch?ring_id='+ this.ring.id + '&search=' + this.judgesAddSearchState).then(res => res.json())
           .then(
              (result) => {
                  this.judgeAddSearch = [];
                  result.forEach(r => {
                    this.judgeAddSearch.push(r);
                });                
                this.setState( {judgeAddSearch : this.judgeAddSearch} )
                
              }
            );
        }
    }
   
   maxRandomNumberUpdate(event) {
        var t = this;
        this.maxRandomNumber = event.target.value;
        this.setState( {maxRandomNumber : this.maxRandomNumber} );
    }
   
    addCompSearchUpdate(event) {
        var t = this;
        this.compAddSearchState = event.target.value;
        this.setState( {compAddSearchState : this.compAddSearchState} );
        var k = event.target.value;
        setTimeout(function(){ t.addCompSearchUpdateCheck(k); }, 500 ); 
    }
    
    addCompSearchUpdateCheck(value) {
        if(value != this.compAddSearchState) { 
            return;
        }
        
        if(this.ring.current_division) {
         postMaster.immediateGet("/tournament-division/" + this.ring.current_division.id + '/available-ringside?search=' + this.compAddSearchState).then(res => res.json())
           .then(
              (result) => {
                  this.compAddSearch = [];
                  result.forEach(r => {
                    this.compAddSearch.push(r);
                });
                
                this.setState( {compAddSearch : this.compAddSearch} )
                
              }
            );
        }
    }
    
    teamCompSearchUpdate(event) {
        this.teamAddSearchState = event.target.value;
        this.setState( {teamAddSearchState : this.teamAddSearchState} )
    }
    
    addCompToDivision(comp) { 
        var extra = '';
        if(this.ring.current_division.is_team == 1) {
            extra = '?team=' + this.teamAddSearchState;
        }
        
        if(this.ring.current_division) {
            postMaster.toSend('/tournament-division/'+ this.ring.current_division.id + '/add-comp/' + comp.reg_id + extra )
        }
        this.settingsModalClose();
        
    }
    
    
    
    startCustomFight() {
        var fight = new Fight(-1);
        
        fight.comp1 = {id:1};
        fight.comp2 = {id:2};
        
        this.ring.setCurrentFight(fight);
        
        window.APP.forceUpdate();
    }
   
    competitorSearchList() {
        return this.compAddSearch.map( (comp ) => 
        <ListGroup.Item onClick={ () => this.addCompToDivision(comp)  }  as="li">{comp.name}</ListGroup.Item>
          );
    }
   
   
    addUnkownJudge() {
        postMaster.immediatePost('/tournament-ring/' + this.ring.id + '/add-ejudge',{ 
                        name:this.judgesAddSearchState,
                        center:this.addCenterValue
                    } )
        .then(
             (result) => {  
                this.judgesAddSearchState = '';
                this.judgeAddSearch = [];
                window.APP.reset();
             }
           );
            this.judgesAddSearchState = '';
            this.judgeAddSearch = [];
            window.APP.reset();
    }
   
   
   
        
   handleKeyPress(event) {
        var x = event.code;
        var action = '';
        
        if(event.target.classList.contains('form-control') ) {
            return;
        }
        
        if(x.includes('Key') ) {
            x = event.charCode || event.keyCode;
            x = String.fromCharCode(x);
        }
        
        if(this.activelySettingKeyBinding() ) { 
            this.key_bindings.forEach((e)=>{
                if(e.key == '') {
                   e.key = x;
                }
            });
            window.APP.reset();
            return;
        }
        
        this.key_bindings.forEach((e)=>{
            if(e.key == x) {
               action = e.action
            }
        });
        
        if(this.ring.current_fight) {
            if(action == 'timer') {
                if(this.ring.current_fight.timer.running) {
                    this.ring.current_fight.timer.stop();
                } else {
                    this.ring.current_fight.timer.start();
                }
                window.APP.reset();
                event.preventDefault();
            }
            
            if(action == 'Left_Up') {
                this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), 1)
                window.APP.reset();
                event.preventDefault();
            }
            
            if(action=='Left_Down') {
                this.ring.current_fight.addCustomPoints(this.ring.current_fight.getLeftComp(), -1)
                window.APP.reset();
                event.preventDefault();
            }
            
            if(action=='Right_Up') {
                this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), 1)
                window.APP.reset();
                event.preventDefault();
            }
            
            if(action=='Right_Down') {
                this.ring.current_fight.addCustomPoints(this.ring.current_fight.getRightComp(), -1)
                window.APP.reset();
                event.preventDefault();
            }
            
            
        } else {
            if(this.div_area) {
                if(action == 'Back') {
                    this.div_area.previous();
                    window.APP.reset();
                    event.preventDefault();
                }

                if(action == 'Forward') {
                    this.div_area.next();
                    window.APP.reset();
                    event.preventDefault();
                }
                
                if(this.ring.current_division && this.ring.current_division.current_comp &&  action == 'timer') {
                    let comp = this.ring.current_division.current_comp;
                    if(comp.stopWatch.master) {
                        if(comp.stopWatch.running) {
                            comp.stopWatch.stop();                            
                        } else {
                            comp.stopWatch.start();
                        }
                        window.APP.reset();
                        event.preventDefault();
                    }
                }
            }
        }        
   }
   
   componentDidMount(){
        document.addEventListener("keydown", this.handleKeyPress);
       
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
    
        window.APP.registerResize(this);
   }
   
   componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyPress);
        window.APP.unRegisterResize(this);
   }
   
   resize() {
        this.mainAreaHeight = window.innerHeight - (this.footerRow.clientHeight + 
            this.headerRow.clientHeight);
   }
   
    setActiveProtest(p) {
        this.protest = p;
        this.setState({protest:p });
    }
   
    render() {
     
     const holder = this;
     
    let wm = this.context.window_manager;
    let activeP = this.ring.getActiveProtests();
    let showSidebar = this.messages || this.ring.alertsManager.hasAlerts() || activeP.length > 0;
    var judgesListArea = this.ring.getCurrentJudgesArea();             
    var upcomingJudgesListArea = this.ring.getUpcomingJudgesArea();
             
    var divs_schedule = this.ring.getSchedule(48).filter(function(value, index, arr){ 
        return value.status < 3;
    }); 
             
    return (
       <Container>
 <Row>
    <Col xs={12} className="header" ref={ (headerRow) => { this.headerRow = headerRow } }>
        <Navbar bg="dark" expand="lg" variant="dark">
        { this.ring.image && 
            <Navbar.Brand href="#home"><img
            src={this.ring.image}
            className="tournamentlogo"
            alt="tournament logo"
          /></Navbar.Brand> }
        <Navbar.Brand href="#home" className="tagname"> {this.ring.name}</Navbar.Brand>
            <Navbar.Toggle aria-controls="tools-navbar-nav" />   
            <Navbar.Collapse id="tools-navbar-nav">
            <Nav className="mr-auto" style={{ flexDirection:'row' }}>
              <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={this.timerModalOpen}>Timer</NavDropdown.Item>
                <NavDropdown.Item onClick={this.stopwatchModalOpen}>Stopwatch</NavDropdown.Item>
                <NavDropdown.Item onClick={this.startCustomFight}>Custom Fight</NavDropdown.Item>
                 <NavDropdown.Item onClick={this.randomModalOpen}>Random Number</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.scheduleModalOpen}>Schedule</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link onClick={(e) => {e.target.blur(); this.settingsModalOpen(); }} >Settings <i class="text-info fas fa-cog"></i></Nav.Link>
              <Nav.Link onClick={(e) => {e.target.blur(); this.judgesModalOpen()}} >Judges <i class="text-info fas fa-gavel"></i></Nav.Link>
              <Nav.Link onClick={(e) => {e.target.blur(); this.alertsModalOpen();} } >Alerts <i class="text-danger fas fa-exclamation-triangle"></i></Nav.Link>
                  <Nav.Link onClick={(e) => {e.target.blur(); this.tvModalOpen();} } >TV <span class={ (this.context.window_manager.opened().length > 0) ? ( (this.context.window_manager.opened().length > this.context.window_manager.fullscreen().length ) ? 'text-warning' : 'text-primary') : 'text-danger' }><i class="fas fa-tv"></i></span></Nav.Link>

            </Nav>
           </Navbar.Collapse>
          <Nav>
            <Nav.Link onClick={() => this.messagesToggle()} ><i class={ ( (window.APP.messages_manager.getNumberNew() == 0) ? 'far fa-comment-alt ' : 'fas fa-comment-alt ' ) + ( (this.messages) ? 'text-success' : '' ) }></i>{(window.APP.messages_manager.getNumberNew() > 0) && <span>&nbsp;<Badge variant="light" >{window.APP.messages_manager.getNumberNew()}</Badge></span>}</Nav.Link>
          </Nav>
          
          
        </Navbar>
        
        <Modal size="lg" show={this.keyBindingsModal} onHide={() => this.keyBindingsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Key Bindings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        {this.keyBindingsArea()}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.keyBindingsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.settingsModal} onHide={() => this.settingsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Table>
            
            <tbody>
                 
            
            { this.ring.current_division && this.ring.current_division.status == 0 && 
            <tr><th>Add Competitor</th>
            <td>
                <Form.Control type="text" placeholder="search name" onChange={this.addCompSearchUpdate} value={this.compAddSearchState} />
                <ListGroup as="ul">
                    {this.competitorSearchList()}  
                </ListGroup>
                
                
                  </td>
                  </tr>
                  }
                  
                  { this.ring.current_division && this.ring.current_division.status == 0 &&  this.ring.current_division.is_team == 1 &&
            <tr><th>To Team</th>
            <td>
                <Form.Control type="text" placeholder="search name" onChange={this.teamCompSearchUpdate} value={this.teamAddSearchState} />
                  </td>
                  </tr>
                  }
                  
                    { this.ring.current_division  && <tr><th>Min Judges</th><td>{this.ring.current_division.min_judges}</td></tr> }
                    { this.ring.current_division  && this.ring.current_division.tie_solver != ''  && <tr><th>Tie Solver</th><td>{this.ring.current_division.tie_solver}</td></tr>}
                    { this.ring.current_division  && this.ring.current_division.deviation > 0  && <tr><th>Max Derivation</th><td>{this.ring.current_division.deviation}</td></tr>}
                    { this.ring.current_division  && this.ring.current_division.drop_high > 0  && <tr><th>Drop High</th><td>{this.ring.current_division.drop_high}</td></tr>}
                    { this.ring.current_division  && this.ring.current_division.drop_low > 0   && <tr><th>Drop Low</th><td>{this.ring.current_division.drop_low}</td></tr>}
                  
                  
                  { this.ring.current_fight && 
            <tr><th>Swap Sides</th>
                <td>
                    <Button onClick={() => window.APP.ring.swapFight()  }>Swap</Button>
                </td>
                </tr>
                  }
                  </tbody>   
                  {this.ring.current_fight && 
            <tbody>
                <tr><th colspan="2">Fight Settings</th></tr>
                <tr><th>Timer</th><td>{this.ring.current_fight.round_timer}</td></tr>
                {this.ring.current_fight.number_rounds > 0 && <tr><th>Rounds</th><td>{this.ring.current_fight.number_rounds}</td></tr>}
                {this.ring.current_fight.number_rounds > 0 && this.ring.current_fight.break_timer > 0 && <tr><th>Break Timer</th><td>{this.ring.current_fight.break_timer}</td></tr>}
                {this.ring.current_fight.max_fight_spread > 0 && <tr><th>Max Spread</th><td>{this.ring.current_fight.max_fight_spread}</td></tr>}
                {this.ring.current_fight.min_fight_spread > 0 && <tr><th>Min Spread</th><td>{this.ring.current_fight.min_fight_spread}</td></tr>}
            </tbody>}
            </Table>
            
            {this.ring.current_division && this.ring.current_division.status == 1 && this.ring.current_division.current_comp == null && this.ring.current_division.config_type == 'scores' &&
                    <div><h2>Check-In Late</h2>
                    
                    <Table>
                    <tbody>
                    {
                    this.ring.current_division.getCheckinRowOrder(this.check_order)
                       .filter(function(value, index, arr){ 
                                   return !value.checked_in;
                            }).map((comp) => {

                            return  <tr>
                            <th style={{ textAlign:'left' }} >{comp.name}</th>
                                           <td><ButtonGroup>
                                                           {!comp.checked_in &&  (comp.members.length > 0 || comp.is_blocked != 'Yes')  && <Button onClick={() => this.checkin_comp_late( this.ring.current_division, comp)} variant="success">Check-In <i class="fas fa-arrow-circle-down"></i></Button>}
                                                        </ButtonGroup>
                                                        { comp.is_blocked == 'Yes' &&  comp.members.length == 0 && <OverlayTrigger placement={'top'} overlay={<Tooltip>{comp.blocked_reason}</Tooltip> }><i  style={{ marginLeft:'10px' }} class={comp.blocked_symbol} ></i></OverlayTrigger>  }
                                                        { this.ring.current_division.is_team == 1 && comp.getCheckedIn().length > this.ring.current_division.max_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Too many people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-minus"} ></i></OverlayTrigger>  }
                                                        { this.ring.current_division.is_team == 1 && comp.getCheckedIn().length < this.ring.current_division.min_size && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not enough people</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-user-plus"} ></i></OverlayTrigger>  }
                                                        { this.ring.current_division.is_team == 1 && comp.checked_in && comp.getCheckedIn().length != comp.members.length && <OverlayTrigger placement={'top'} overlay={<Tooltip>Not everyone is checked in</Tooltip> }><i  style={{ marginLeft:'10px' }} class={"fas fa-exclamation"} ></i></OverlayTrigger>  }
                                                    </td>
                                                           <td>{comp.seeding}</td>
                                           </tr>
                    })}
                    </tbody>
                    </Table>
            </div>
            }
            
            {this.ring.current_division && this.ring.current_division.protests.length > 0 &&
                    <div><h2>Protests</h2>
                    
                    <Table>
                    <tbody>
                    {
                    this.ring.current_division.protests
                       .map((p) => {

                            return  <tr>
                            <th style={{ textAlign:'left' }} >{(this.ring.current_division.getComp(p.protestor_id))?  this.ring.current_division.getComp(p.protestor_id).name : <span>?</span>} VS {(this.ring.current_division.getComp(p.protestee_id))? this.ring.current_division.getComp(p.protestee_id).name : <span>?</span>}</th>
                                           <td>{p.status}</td>
                                           <td><Button variant='primary' onClick={ ()=>{ this.settingsModalClose(); this.setActiveProtest({name:this.ring.current_division.name,protest:p,d:this.ring.current_division }); } } ><i class="fas fa-eye"></i></Button></td>
                             </tr>
                    })}
                    </tbody>
                    </Table>
            </div>}
            
            <div>
                <h2>Other</h2>
                <Table>
                <tbody>
                <tr>
                <th style={{ textAlign:'left' }} >Graphics Only</th>
                    <td>
                    <Form.Check onClick={ (x) => { holder.isgraphics = !holder.isgraphics; holder.manager.resetStyling();  window.APP.reset(); } }
                        checked={holder.isgraphics}
                        label="Graphics Controller"
                      />
                    </td>
                </tr>
                
                <tr>
                <th style={{ textAlign:'left' }} >Score Input</th>
                    <td>
                    <Form.Control value={this.score_input_type} onChange={(e) => { holder.score_input_type = e.target.value;window.APP.reset(); }} as="select" size="lg" >
                        <option value="type">Type</option>
                        <option value="dropdown">Dropdown</option>
                    </Form.Control>
                    </td>
                </tr>
                
                </tbody>
                </Table>
            </div>
            
            
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => { this.settingsModalClose();this.keyBindingsModalOpen();}}>
                Key Bindings
              </Button>
            
              <Button variant="secondary" onClick={() => this.settingsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.tvModal} onHide={() => this.tvModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Display Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Table>
            <tbody>
            <tr>
            <td colspan="2"><Button class="btn btn-primary" onClick={this.addWindow}>Add Display</Button></td>
            </tr>
            </tbody>
           
            {
            this.context.window_manager.insides.map((w, index) =>
            <tbody className={ w.externalWindow.closed ? 'invisibletable' : '' }>
            <tr>
                <th colspan="2" class="text-center">   
                  Display {(index + 1)}
                </th>
            </tr>
            
            <tr className={w.isFullscreen() ? 'table-success':'table-danger'}>
            <td>Full Screen</td><td>{w.isFullscreen() && <span>Yes</span>}{!w.isFullscreen() && <span>No</span>}</td>
            </tr>
            
            <tr>
                 <td>Header Sizing</td>
            
                <td>
                <Form.Group controlId="formBasicRangeCustom">
                    <Form.Control value={w.header_sizing} step="0.1" min="0.1" max="2" type="range" custom onChange={(e)=>{ w.setHeaderSizing(e.target.value); window.APP.reset(); }} />
                </Form.Group>     
                </td>
            </tr>
            <tr><td>Header Type</td>
                <td>
                <Form.Control value={w.type} onChange={(e) => { w.setType(e.target.value);window.APP.reset(); }} as="select" size="lg" >
                
                        <option value="stream">Stream</option>
                        <option value="tv">TV</option>
                        <option value="none">None</option>
                    </Form.Control>
                </td>
            </tr> 
            
            <tr>
                 <td>Display Sizing</td>            
                <td>
                <Form.Group controlId="formBasicRangeCustom">
                    <Form.Control value={w.sizing} step="0.1" min="0.1" max="2" type="range" custom onChange={(e)=>{ w.setSizing(e.target.value); window.APP.reset(); }} />
                </Form.Group>     
                </td>
            </tr>
            
            <tr><td>Screen Type</td>
                <td>
                <Form.Control value={w.display_type} onChange={(e) => { w.setDisplayType(e.target.value);window.APP.reset(); }} as="select" size="lg" >
                
                        <option value="normal">Normal</option>
                        <option value="green">Green</option>
                    </Form.Control>
                </td>
            </tr> 
            
            <tr><td>Content Mode</td>
                <td>
                    <Form.Control value={w.mode} onChange={(e) => { w.setMode(e.target.value);window.APP.reset(); }} as="select" size="lg" >
                        <option value="">Default</option>                        
                        <option value="schedule">Schedule</option>
                        <option value="blank">Blank</option>
                        <option value="ring">Ring</option>
                        <option value="timer">Timer</option>
                        <option value="stopwatch">Stopwatch</option>
                    </Form.Control>
                </td>
            </tr> 
            
            </tbody>
            )
            }
            
            
            
            </Table>
            </Modal.Body>
            <Modal.Footer>            
              <Button variant="secondary" onClick={() => this.tvModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.tvInstructionsModal} onHide={() => this.tvInstructionsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Non-Full Screens</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>On the display you should find a <i class="fas fa-expand"></i> symbol in the bottom left.  Click on it to make the display full screen.</p>
            <p>Displays that are not full screen</p>
            <Table>           
            {
            this.context.window_manager.insides.map((w, index) =>
            <tbody className={ (w.isFullscreen() || w.externalWindow.closed)? 'invisibletable' : '' }>
            <tr>
                <th colspan="2" class="text-center">   
                  Display {(index + 1)}
                </th>
            </tr>
            </tbody>
            ) }
            </Table>
            </Modal.Body>
            <Modal.Footer>            
              <Button variant="secondary" onClick={() => this.tvInstructionsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.judgesModal} onHide={() => this.judgesModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Judges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h2>Current</h2>
            <Table responsive>
                <tbody>
                    {judgesListArea}
                </tbody>
            </Table>
            {upcomingJudgesListArea.length > 0 &&
            <div>
            <h2>Upcoming</h2>
            <Table responsive>
                <tbody>
                    {upcomingJudgesListArea}
                </tbody>
            </Table>
            </div>
            }
            
            <h2>Add Judge</h2>
            <Form.Check
                onChange={this.addCenterUpdate}
                type="checkbox"
                className="mb-2 mr-sm-2"
                label="Center"
              />
            <Form.Control type="text" placeholder="search name" onChange={this.addJudgeSearchUpdate} value={this.judgesAddSearchState} />
            <ListGroup as="ul">
                {this.judgesSearchList()}  
            </ListGroup>
            <Button variant='success' onClick={this.addUnkownJudge} >Add New Judge</Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.judgesModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.alertsModal} onHide={() => this.alertsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Alerts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.ring.alertsManager.popupChooserDisplay()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.alertsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.alertsModal} onHide={() => this.alertsModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Alerts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.ring.alertsManager.popupChooserDisplay()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.alertsModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.scheduleModal} onHide={() => this.scheduleModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table >
                    <tbody>
                    {
                        divs_schedule.map((division) => {

                                return  <tr>
                                            <td>{division.codePop() }</td>                                                                                                                               
                                            <td>{division.name }</td>
                                            <td>{division.estimated_start.toLocaleTimeString(window.APP.time_locale,window.APP.time_options )}</td> 
                                            <td>{division.standings.length }</td>
                                        </tr>;
                                }
                              )
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.scheduleModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.stopwatchModal} onHide={() => this.stopwatchModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Stopwatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px', fontSize:'4em' }}>
                {this.stopwatch.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.stopwatch.running && <Button varient='primary' onClick={()=>this.stopwatch.start()} ><i class="fas fa-play"></i></Button>}
                {this.stopwatch.running && <Button variant="warning" onClick={()=>this.stopwatch.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.stopwatch.running && <Button variant="danger" onClick={()=>this.stopwatch.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.stopwatch.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.stopwatch.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'stopwatch' && <Button variant="success" onClick={(e) => { w.setMode("stopwatch");window.APP.reset(); }}>Set to StopWatch</Button>}
                            {w.mode == 'stopwatch' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                    </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.stopwatchModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.randomModal} onHide={() => this.randomModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Random Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                <Row>
                    <Col xs='12'>
                    This will product a random number beetween 1 and the number below (inclusive)
                    {
                        <Form.Control type="number" placeholder="2" onChange={this.maxRandomNumberUpdate} value={this.maxRandomNumber} />
                        }                                              
                    </Col>
                </Row>
                
                <Row style={{ marginTop:'15px' }}>
                    {this.randomNumber != null && <Col xs='12'>
                    {this.randomNumber}<br></br><br></br><Button variant='warning' onClick={this.resetRandom}>Reset</Button>
                    </Col>}
                    
                    {this.randomNumber == null && <Col xs='12'>
                    <Button variant='success' onClick={this.getRandom}>Get Random</Button>
                    </Col>}
                </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.randomModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal size="lg" show={this.timerModal} onHide={() => this.timerModalClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Timer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container><Row><Col xs={12} style={{ textAlign:'center',marginTop:'30px', fontSize:'4em' }}>
                {this.timer.getDisplay()}</Col></Row>
            <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
            <ButtonGroup className="mr-2" aria-label="First group">
                {!this.timer.running && <Button varient='primary' onClick={()=>this.timer.start()} ><i class="fas fa-play"></i></Button>}
                {this.timer.running && <Button variant="warning" onClick={()=>this.timer.stop()}><i class="fas fa-pause"></i></Button> }
                {!this.timer.running && <Button variant="danger" onClick={()=>this.timer.reset()}><i class="fas fa-sync"></i></Button>}
                </ButtonGroup></Col></Row>
                <Row>
                <Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                    <ButtonGroup className="mr-2" aria-label="First group" >
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-10) }>-10</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-5) }>-5</Button>
                        <Button variant="warning" onClick={ ()=>this.timer.alter(-1) }>-1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(1) }>+1</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(5) }>+5</Button>
                        <Button variant="success" onClick={ ()=>this.timer.alter(10) }>+10</Button>
                    </ButtonGroup>
                </Col>
                </Row>
                
                {!this.timer.running && <Row>
                    <Col xs={5} style={{ textAlign:'center',marginTop:'10px' }}>
                        Timer Value (seconds)
                    </Col>
                    <Col xs={4} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Form.Control type="text"  onChange={this.updateSpecialTimerValue} value={this.timer_special_value}  />
                    </Col>
                    <Col xs={3} style={{ textAlign:'center',marginTop:'10px' }}>
                        <Button variant="danger" onClick={()=>{ this.timer.setTimer(this.timer_special_value); this.timer.reset()}}>Set</Button>
                    </Col>
                </Row>}
                {this.context.window_manager.insides.length > 0 && <Row><Col xs={12} style={{ textAlign:'center',marginTop:'10px' }}>
                <h2>Displays</h2>
                <Table>
                    {
                    this.context.window_manager.insides.map((w, index) =>
                    <tr><td>Display {(index + 1)}</td>
                        <td>
                            {w.mode != 'timer' && <Button variant="success" onClick={(e) => { w.setMode("timer");window.APP.reset(); }}>Set to Timer</Button>}
                            {w.mode == 'timer' && <Button variant="danger" onClick={(e) => { w.setMode("");window.APP.reset(); }}>Reset</Button>}
                        </td>
                    </tr> 
                    )
                    }
                </Table></Col>
                </Row>}
            </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.timerModalClose()}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
        
        
    </Col>   
    <Col xs={(showSidebar) ? 9 : 12} style={{ height:this.mainAreaHeight+'px', overflowY:'scroll', overflowX:'hidden',paddingBottom:'30px' }} >  
                {window.APP.connection_status_hide == 0 && window.APP.connection_status == 0 && <Alert variant='danger' style={{ margin: '10px'}}>
                    No Internet Connection {postMaster.leftToSend() && <span> - Unsent events - do not refresh</span>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}
                  
                  {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && postMaster.leftToSend() && <Alert variant='info' style={{ margin: '10px'}}>
                    Sending Data {!window.sending && <span>Paused</span>} - Do not refresh <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                  </Alert>}

                    {window.APP.connection_status_hide == 0 && window.APP.connection_status == 1 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Internet was lost - data may be outdated {window.pusher_status == 1 && <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>} <Button onClick={()=>{ window.APP.connection_status_hide=1;window.APP.reset();} } variant='primary'>Hide warnings</Button>
                    </Alert>}
                    
                    {window.APP.needs_reload == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        When a division is not active, please redownload the ring data <Button onClick={()=>{ holder.reload();} } variant='primary'>Reload Data</Button>
                    </Alert>}
                    
                    {window.APP.needs_refresh == 1 && window.APP.connection_status == 2 && !postMaster.leftToSend() && <Alert variant='warning' style={{ margin: '10px'}}>
                        Please refresh at the nearest opportunity - this will close any displays <Button onClick={()=>{ window.location.reload();} } variant='primary'>Refresh</Button>
                    </Alert>}
                {holder.isgraphics == false && <DivisionManagerDisplay holder={this} ring={this.ring} ></DivisionManagerDisplay>}
                {holder.isgraphics == true && <DivisionGraphicsDisplay holder={this} ring={this.ring} ></DivisionGraphicsDisplay>}
    </Col>
    { showSidebar && 
    <Col xs={3} style={{ overflowY:'scroll', height:this.mainAreaHeight+'px',paddingBottom:'30px' }}>
          
        <Container>
            { this.ring.alertsManager.hasAlerts() &&
            <Row>
                <Col xs={12} >
                    <div>
                        <Card  bg='dark' text='light' style={{ marginTop:'15px' }}>
                        <Card.Body style={{  color:'black' }} >
                            {this.ring.alertsManager.activeDisplay()}
                        </Card.Body>
                        </Card>
                    </div>
                 </Col>
            </Row>
            }
            {activeP.length > 0 && 
                <Row>
                    <Col xs={12} >
                        <div>
                            <Card  bg='dark' text='light' style={{ marginTop:'15px' }}>
                            <Card.Body style={{  color:'black' }} >
                                <Container>
                                <Row>
                                {activeP.map( (x) => <Col xs={12} style={{ textAlign:'center',marginTop:'15px' }}>
                                            <Card>
                                                <Card.Header>{'Protest'}</Card.Header>
                                                <Card.Body>
                                                Status: {x.protest.status}    
                                                </Card.Body>
                                                <Card.Footer>
                                                        <Button onClick={()=>{ this.setActiveProtest(x);} } variant="primary"><i class="fas fa-eye"></i></Button>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                
                                                                       )}
                                                               
                                </Row></Container>
                            </Card.Body>
                            </Card>
                        </div>
                     </Col>
                </Row>    
            }
            { this.messages && 
            <Row>
                <Col xs={12}>
                
                <Card  bg='dark' text='light' >
                <Card.Header >Messages
                <div style={{float:'right'}}>
                <Button variant='outline-light' onClick={() => this.context.messages_manager.clearMessages()} ><i class="fas fa-sync" ></i></Button>
                            </div>
                            </Card.Header>
                <Card.Body style={{ color:'black' }} >
                    <MessagesDisplay></MessagesDisplay>  
                    </Card.Body>
                </Card>
                </Col>
            </Row>}
            
        </Container>
      
    </Col>
    }


</Row>
            {this.protest && 
                <Modal size="lg" show={this.protest != null} onHide={()=>{ this.setActiveProtest(null) }}>
                    <Modal.Header closeButton>
                      <Modal.Title>{(this.protest.d.getComp(this.protest.protest.protestor_id))?  this.protest.d.getComp(this.protest.protest.protestor_id).name : <span>?</span>} VS {(this.protest.d.getComp(this.protest.protest.protestee_id))? this.protest.d.getComp(this.protest.protest.protestee_id).name : <span>?</span>}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                           <tbody>
                                <tr><th>Division</th><td>{this.protest.d.name}</td></tr>
                                <tr><th>Status</th><td>{this.protest.protest.status}</td></tr>
                                <tr><th>User</th><td>{this.protest.protest.user}</td></tr>
                                <tr><th>On behalf Of</th><td>{(this.protest.d.getComp(this.protest.protest.protestor_id))?  this.protest.d.getComp(this.protest.protest.protestor_id).name : <span>?</span>}</td></tr>
                                <tr><th>Protestor</th><td>{(this.protest.d.getComp(this.protest.protest.protestee_id))?  this.protest.d.getComp(this.protest.protest.protestee_id).name : <span>?</span>}</td></tr>
                                <tr><th>Reason</th><td>{this.protest.protest.reason}</td></tr>
                                {this.protest.protest.complete && <tr><th>Response</th><td>{this.protest.protest.response}</td></tr>}
                                {this.protest.protest.complete && <tr><th>Refund</th><td>{(this.protest.protest.refund == 0) ? 'No' : 'Yes'}</td></tr>}                           
                            
                            </tbody>                            
                        </Table>  
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{ this.setActiveProtest(null) }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>        
            }

        <Navbar ref={ (footerRow) => { this.footerRow = footerRow } } bg="dark" expand="lg" variant="dark" fixed="bottom">
        
          <Nav className="mr-auto" style={{ flexDirection:'row' }}>                 
                <Nav.Link style={{color:'white'}} >&nbsp;<TimeDisplay /></Nav.Link>
                {this.ring.alertsManager.medicNavButton()}
          </Nav>
          
          {this.context.window_manager.opened().length == 0 && <Nav>
          <Navbar.Brand href="#home" onClick={(e) => {e.target.blur(); this.tvModalOpen();} }><i class="fas fa-tv text-danger" onClick={(e) => {e.target.blur(); this.tvModalOpen();} }></i> No Display <i class="fas fa-tv text-danger"></i></Navbar.Brand>
          </Nav>}
          
          {this.context.window_manager.opened().length > this.context.window_manager.fullscreen().length && <Nav>
          <Navbar.Brand href="#home" onClick={(e) => {e.target.blur(); this.tvInstructionsModalOpen();} }><i class="fas fa-tv text-warning"></i> Display not Full Screen <i class="fas fa-tv text-warning"></i></Navbar.Brand>
          </Nav>}
          
          
          {window.APP.connection_status == 0 && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="danger" /> Internet Loss <Spinner animation="grow" variant="danger" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="info" /> Sending Data {!window.sending && <span>Paused</span>}<Spinner animation="grow" variant="info" /></Navbar.Brand>
          </Nav>}
          
          {window.APP.connection_status == 1 && !postMaster.leftToSend() && <Nav>
          <Navbar.Brand href="#home"><Spinner animation="grow" variant="warning" /> Ring Data Missed <Spinner animation="grow" variant="warning" /></Navbar.Brand>
          </Nav>}
          
          <Nav style={{ flexDirection:'row' }}>
              
            

              
            {postMaster.queue.length == 0 && postMaster.onMessage == null && window.APP.connection_status > 0 && window.pusher_status == 1 && <Nav.Link  onClick={() => {holder.reload() } } ><span class={ (window.APP.connection_status == 1 ) ? 'text-primary':'text-secondary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Click To Download Data
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-download-alt"></i></OverlayTrigger></span></Nav.Link>}
            { postMaster.onMessage != null && window.APP.connection_status > 0 && window.sending && window.pusher_status == 1 && <Nav.Link  onClick={() => {postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                       Uploading Events
                      </Tooltip>
                    }
                    ><i class="fas fa-cloud-upload-alt"></i></OverlayTrigger></span></Nav.Link>}
                    
                    
          {window.APP.connection_status == 0 && window.sending && <Nav.Link  onClick={() => { window.sending = false; window.APP.reset(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Will process queue click to hold queue
                      </Tooltip>
                    }
                    ><i class="fas fa-play-circle"></i></OverlayTrigger></span></Nav.Link>}
                    
            {!window.sending && <Nav.Link  onClick={() => { window.sending = true; window.APP.reset(); postMaster.retry() } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                         Won't process queue click to process                        
                      </Tooltip>
                    }
                    ><i class="fas fa-pause"></i></OverlayTrigger></span></Nav.Link>}
                    
          {window.APP.connection_status == 0 && <Nav.Link  onClick={() => { postMaster.immediateGet('/heartbeat'); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Check Internet
                      </Tooltip>
                    }
                    ><i class="fas fa-broadcast-tower"></i></OverlayTrigger></span></Nav.Link>}
          
            {window.APP.canFullscreen() && !window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.goFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Go Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-expand"></i></OverlayTrigger></span></Nav.Link>}
                
                    {window.APP.canFullscreen() && window.APP.isFullscreen() && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { window.APP.exitFullscreen(); } } ><span class={ 'text-primary' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        Exit Full Screen
                      </Tooltip>
                    }
                    ><i class="fas fa-compress"></i></OverlayTrigger></span></Nav.Link>}
                    
            {this.ring.help_alert && <Nav.Link style={{paddingRight:'1rem'}} onClick={() => { holder.ring.alertsManager.createNew(holder.ring.help_alert) } } ><span class={ 'text-danger' }><OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>
                        {this.ring.help_alert.name}
                      </Tooltip>
                    }
                    ><i class=" fas fa-phone"></i></OverlayTrigger></span></Nav.Link>}  
          <Navbar.Brand href="#home">
      <img
        src={ AppDomain + '/pass_M_Logo.png' }
        height="30"
        alt="MAT Action logo"
      />
      
    </Navbar.Brand>
        
          </Nav>
      </Navbar>
</Container>
    );
  }
}

