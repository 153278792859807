import Table from 'react-bootstrap/Table';
import React from 'react';
import {joinMeeting} from '../logic/meeting';

export class AdminHelpDesk  extends React.Component {
    
    constructor(props) {
        super(props);
        this.meetings = [];
        
        let ahd = this;
        document.getElementById("loading_area").style.display = "none";
        
        window.Echo.private('helpdesk') 
        .listen('AdminHelpNeeded', (e) => {        
            ahd.meetings.push( {id : e.meeting_id } );
            ahd.setState({ meetings: ahd.meetings  });
        });
    }
    
    render() {
        
        const helpList = this.meetings.map((meeting ) => 
         <tr><td onClick={ ()=>{joinMeeting('/help/' + meeting.id, false  );} } 
                     
                    >Join</td></tr>
          );
  
  
        return        <Table variant="dark">
       {helpList}
        </Table>
        ;
    }
    
}