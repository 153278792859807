import React from 'react';

import {Ring} from '../../models/ring';
import postMaster from '../../logic/postmaster';
import {AppContext, AppDomain, seeds} from '../../constants';
import {FightCall, Fight, BracketChart, FightAvCall} from '../../models/fights'
import {Standing} from '../../models/standing'
import {Division} from '../../models/division'
import {Judge} from '../../models/judge'
import {swapFights, customRandom} from '../../functions';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

class GraphicsComp extends React.Component { 
    
    static contextType = AppContext;
    
    componentDidMount() {
        window.APP.registerReload(this);  
    }
    
    reload() {
        var t = this;        
        postMaster.immediateGet("/tournament-ring/" + this.ring_id + '/graphics')
        .then(res => res.json())
        .then(
          (result) => {
            t.layers = result.layers;
            window.APP.forceUpdate();
          }
        );

        var old_ring = this.ring;
        this.ring = new Ring(this.ring_id);
        this.ring.load(null, true);        
    }
    
    setLayerOverrides(obj, data) { 
        var t = this;     
        obj.subs.filter(x=>x.type == 'text').forEach(function(x) {
            if(data[x.id]== undefined) {
                x.value_override = null;
            } else{
                x.value_override = data[x.id];
            }
            t.setLayerOverrides(x, data);
        });
    }
    
    sendEagerLoad() {
        var data = {};        
        postMaster.toSendData('/tournament-ring/' +this.ring.id + '/graphics/eager',data,false )
        window.APP.reset();
    }
    
    constructor(props) {
        super(props);   
        this.ring_id = props.ring_id;
        this.ring = new Ring(this.ring_id);
        this.ring.load(null, true);
        var t = this;
        var ring = this.ring;
        
        this.g_current_div = null;
        this.g_current_status = 1;      
        this.g_current_item = null; 
        
        this.needs_update = 0;
        
        this.layers = [];
        
        postMaster.immediateGet("/tournament-ring/" + this.ring_id + '/graphics')
        .then(res => res.json())
        .then(
          (result) => {
            t.layers = result.layers;
            window.APP.forceUpdate()
          }
        );
        
        window.EchoScreen.private('ring.' + this.ring_id + '.graphics')
        .listen('BroadcastDivision', (e) => {   
            t.g_current_div = t.ring.divisions.find(x => x.id == e.division);
            t.status = t.ring.divisions.find(x => x.id == e.status);
            if(e.item_type == 'standing') {
               t.g_current_item = t.g_current_div.standings.find(x => x.id == e.item_id);
            }
            if(e.item_type == 'fight') {
                t.g_current_item = t.g_current_div.fights.find(x => x.id == e.item_id);
            }
            if(e.item_type == null || e.item_id == null) {
                t.g_current_item = null;
            }
            window.APP.reset();
        })
        .listen('BroadcastLayer', (e) => {
            var layer = t.layers.find(x => x.id == e.layer);
            if(layer == null) return;
            t.processLayerUpdate(layer, e);
            layer.state = e.state;   
            window.APP.reset();
        })
        .listen('BroadcastLayerOverride', (e) => {
            var layer = t.layers.find(x => x.id == e.layer);
            if(layer == null) return;
            t.setLayerOverrides(layer, e.overrides);
            
            window.APP.reset();
        })
        .listen('BroadcastEager', (e) => {
            t.ring.divisions.forEach(function(div){
                div.standings.forEach(function(s){
                   if(s.photoL) {
                       const img = new Image();
                       img.src = s.photoL;
                   } 
                });
            });
            
            t.layers.forEach(function(lay){
                lay.subs.forEach(function(object){
                    if(object.type == 'image' && object.value && object.value != 'null' && !object.value.includes('%%') ) {
                       const img3 = new Image();
                       img3.src = object.value;
                    }
                    
                    if(object.into_gif != '' && object.into_gif && object.into_gif != 'null' ) {
                       const img2 = new Image();
                       img2.src = object.into_gif;
                    }
                });
            });
            
            window.APP.reset();
        });
        
        window.EchoScreen.private('ring.' + this.ring_id)
        .listen('TournamentJudgesUpdate', (e) => {   
            e.sent.forEach(j => {
                var jf = ring.a_judges.filter(function(e) {return e.id == j.id;});
                if(jf.length > 0) {
                    jf[0].update(j)
                } else {
                    ring.a_judges.push(new Judge(j))
                }
            });
            window.APP.reset();
        })
        .listen('TournamentDivisionStatus', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) { div.status = e.status; div.is_final = e.is_final; }
            window.APP.reset();
        })
        .listen('ProtestUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(div) div.updateProtest(e);
            window.APP.reset();
        })
        .listen('TournamentRingCurrentDivision', (e) => {
            let div = ring.getDivision(e.division_id);
            ring.current_division = div
            window.APP.reset();
        })
        .listen('TournamentDivisionCurrentComp', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(div.current_comp && div.current_comp.stopWatch.master && div.current_comp.stopWatch.running) {
                div.current_comp.stopWatch.stop()
            }
            
            div.current_comp = div.getComp(e.standing_id)
            window.APP.reset();
        })
        .listen('TournamentStandingUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) return;
                
                team.name = e.team_name;
                team.seeding = e.comp.seeding;
                
                let comp = team.getMember(e.standing_id);
                if(!comp) return;
                comp.update(e.comp);  
                
                div.checkTeams();
            } else {
                let comp = div.getComp(e.standing_id)
                if(!comp) return;
                comp.update(e.comp);  
            }
            window.APP.reset();
        })
        .listen('TournamentStandingScore', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            comp.setScore(e.num, parseFloat(e.value));           
            window.APP.reset();
        })
        .listen('TournamentDivisionAllScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                comp.setScore(s.num, parseFloat(s.score));
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentDivisionFixedScores', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            e.scores.forEach(function(s, index) { 
                let comp = div.getComp(s.comp_id);
                if(!comp) return;
                if(s.num < 0) {
                    if(comp.up_scores[s.num * -1] == null) {
                        comp.up_scores[s.num* -1] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.up_scores[s.num* -1].score = s.score;
                    comp.up_scores[s.num* -1].og_score = s.og_score;
                    comp.up_scores[s.num* -1].drop = s.drop;
                    comp.up_scores[s.num* -1].judge_id = s.judge_id;
                } else {
                    if(comp.scores[s.num] == null) {
                        comp.scores[s.num] = {judge:s.num,score:0,drop:0,og_score:0};
                    }

                    comp.scores[s.num].score = s.score;
                    comp.scores[s.num].og_score = s.og_score;
                    comp.scores[s.num].drop = s.drop;
                    comp.scores[s.num].judge_id = s.judge_id;
                }
            } );
                     
            window.APP.reset();
        })
        .listen('TournamentStandingTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let comp = div.getComp(e.standing_id)
            if(!comp) return;
            
            if(e.running == 'sync' || e.tick == 'sync') {
                if(comp.stopWatch.master && (comp.stopWatch.left != 1000 || comp.stopWatch.running )) {
                    comp.stopWatch.update();
                }
                return;
            }
            
            comp.stopWatch.master = false;
            comp.stopWatch.sync(e.running, e.tick );
            
            window.APP.reset();
        })
        .listen('TournamentDivisionCompAdd', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                    let team = div.getComp(e.team)
                    if(team == null) {
                            team = new Standing(e.comp.id);
                            team.name = e.team_name;
                            team.seeding = e.comp.seeding;
                            div.standings.push(team) 
                    }

                    let member = new Standing(e.comp.id);
                    member.load(e.comp, div);
                    team.members.push(member)

                    div.checkTeams();
            } else {
                    let standing = new Standing(e.comp.id);
                    standing.load(e.comp, div);
                    div.standings.push(standing);
                    
                    if(ring.comm_data_from != null && ring.comm_data_from != ''){
                        postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/standing/" + standing.id)
                        .then(res => res.json())
                        .then(
                        (result) => {
                            if(result == null) {
                                    return;
                            }
                            standing.profile_data = result.comp;
                             window.APP.reset();
                        });
                    }
            }
            div.checkTeams();
            window.APP.reset();
        })
        
        .listen('TournamentDivisionCompRemoved', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            if(e.team) {
                let team = div.getComp(e.team)
                if(!team) {
                    //check for old team with removed lead
                    team = div.getComp(e.id);
                    if(!team) return;
                    
                    team.id = e.team;
                };
                team.members = team.members.filter(function(value, index, arr){ return value.id != e.id });
                
                if(team.members.length == 0 ) {
                    div.standings = div.standings.filter(function(value, index, arr){ return value.id != team.id });
                }
            } else {
                div.standings = div.standings.filter(function(value, index, arr){ return value.id != e.id });
            }
            div.checkTeams();
            window.APP.reset();
        })
        
        .listen('TournamentDivisionShuffle', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.ordered = parseInt(element.ordered)
            });
            div.shuffled = 1;
            window.APP.reset();
        })
        .listen('TournamentDivisionShuffleReset', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;
            div.shuffled = 0;
            window.APP.reset();
        })
        
        .listen('TournamentRingDivisionRemove', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
        
            ring.divisions = ring.divisions.filter(function(value, index, arr){ return value.id != e.division_id });
            window.APP.reset();
        })
       .listen('TournamentRingDivisionAdd', (e) => {
            postMaster.toSend("/tournament-ring/" + ring.id + '/division/' + e.division_id,
    
            function(p) {
                p.then(res => res.json())
                .then((result) => {
                    if(result.call_pack != null && result.call_pack != '' && (ring.available_call_packs[result.call_pack] == undefined || ring.available_call_packs[result.call_pack] == null) &&  result.call_pack_full != null &&  result.call_pack_full != undefined ) {
                        var element = result.call_pack_full;
                        var p = {
                           uuid : element.uuid,
                           name : element.name,
                           available_calls: []
                        };

                        element.calls.forEach(c => { 
                           p.available_calls.push(
                                   new FightAvCall(c)
                           );
                        });
                        ring.available_call_packs[element.uuid] = p;
                    }
                    
                    let div = new Division(result.id);
                    div.ring = ring;
                    div.load(result);
                    ring.divisions.push(div);
                    div.ring = ring;
                    
                    if(ring.comm_data_from != null && ring.comm_data_from != ''){
                        postMaster.immediateGet("/media-data/"+ring.comm_data_from+"/division/" + div.id)
                        .then(res => res.json())
                        .then(
                        (result) => {
                            result.forEach(p=>{ 
                                if(p == null) {
                                    return;
                                }
                                div.processProfile(p);
                            });
                            window.APP.reset();
                        });
                    }
                    
                    window.APP.reset();
                });
            });
        })
        .listen('TournamentDivisionUpdate', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return; 
            
            for (const [key, value] of Object.entries(e.changes)) {
                if(key == 'break_ties_till' || key == 'min_place' || key == 'min_size' || key == 'max_size' || key == 'is_team') {
                    div[key] = parseInt(value);   
                } else {
                    div[key] = value;    
                }
                
            }
            
            div.standings.forEach((s) => {
                s.division = div;
            });
            
            window.APP.reset();            
        })
        .listen('TournamentRingScheduleUpdate', (e) => {    
            e.updates.forEach(element => { 
                let div = ring.getDivision(element.division_id);
                if(!div) return; 
                
                div.estimated_start = new Date(element.est_start);    
                div.estimated_stop = new Date(element.est_stop); 
                div.duration_estimate = parseInt(element.duration_estimate);
                div.ordered = parseInt(element.ordered);
            });            
            window.APP.reset();            
        })
        .listen('TournamentFightCall', (e) => {                
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
    
    
            let acall = ring.getAVCalls().find(element => element.id == e.call.call_id);
            
            let comp = null;
            
            if(e.call.person_id == f.comp1.id) {
               comp = f.comp1;
            }  else {
                comp = f.comp2;
            }
            
            let call = new FightCall({
                id: e.call.id,
                opposing_points: e.call.opposing_points,
                points: e.call.points,
                dq: e.call.dq,
                warning: e.call.warning,
                person: comp,
                availableCall: acall,
                winner: e.call.winner,
                time_change: e.call.time_change,
                end_round: e.call.end_round
            });
    
    
            f.receiveCall(call)
            window.APP.reset();
        })
        
        .listen('TournamentFightTick', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            if(!f) return;
            
            if(e.running == 'sync' || e.tick == 'sync') { 
                if(f.timer.master && (f.timer.current != f.round_timer * 1000 || f.timer.running  )) {
                    f.timer.update();
                }
                return;
            }
            
            if(!f.timer.force_master) {
                f.timer.master = false;
            }
            
            
            f.timer.sync(e.running, e.tick );
            
            if(e.round) {
                f.current_round = e.round
            }
            
            if(e.break) {
                f.on_break = e.break
            }
            
            window.APP.reset();
        })
        
        .listen('TournamentFightSwapSides', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f = div.getFight(e.fight_id)
            f.swapped = parseInt(e.value);
            
            window.APP.reset();
        })
        
        .listen('TournamentRingCurrentFight', (e) => {
            let div = ring.current_division;
            if(!div) return;
            ring.current_fight = div.getFight(e.fight_id);
            window.APP.reset();
        })
        
        .listen('TournamentDivisionNewBrackets', (e) => {
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            
            div.fights = [];
            div.shuffled = 1;
            if(e.top_fights) {
                e.top_fights.forEach((t)=>{
                    div.addFight(t);
                });
            }
            window.APP.reset();
        })
        
        .listen('TournamentDivisionFightSwap', (e) => {  
            let div = ring.getDivision(e.division_id);
            if(!div) return;
            let f1 = div.getFight(e.one);
            let f2 = div.getFight(e.two);
            if(f1 == null || f2 == null) {
                return;
            }
            swapFights(f1, f2);
            window.APP.reset();
        })
        
        .listen('TournamentDivisionPlaces', (e) => {

            let div = ring.getDivision(e.division_id);
            if(!div) return;

            e.standings.forEach(element => { 
                let standing = div.getComp(element.id);
                if(standing == null) return;
                standing.place = parseInt(element.rank);
            });

            window.APP.reset();
        })
        .listen('AlertForward', (e) => {
           ring.alertsManager.receiveEvent(e)
            window.APP.reset();
        })
        ;
    }
    
    render() {
       return <div></div>;
    }
}

export class GraphicsController extends GraphicsComp { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);   
        
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.apply = this.apply.bind(this);
        this.resetPending = this.resetPending.bind(this);
        this.resetRing = this.resetRing.bind(this);
        this.setCurrentWinner = this.setCurrentWinner.bind(this);
        this.resetPending = this.resetPending.bind(this);
        
        this.p_current_div = null;
        this.p_current_status = 1;
        this.p_current_item = null;
        
        this.choosen_layer = null;
    }
    
    
    
    componentWillUnmount() {    
        window.APP.unRegisterReload(this);
        document.removeEventListener("keydown", this.handleKeyPress);
    }
    
    chooseLayer(layer) {
        this.choosen_layer = layer;
        this.setState({choosen_layer:layer});
    }
    
    layerOverrides(layer) { 
        var t = this;    
        return layer.subs.filter(x=>x.type == 'text').map(x => {
            return <tbody key={x.id}><tr>
            <td style={{color:'white'}}>{x.name}</td>
                <td style={{color:'white'}}>
                <Form.Control placeholder={x.value} value={x.value_override} onChange={(e) => { layer.needs_send = 1; x.value_override = e.target.value; window.APP.reset(); }} type="text" >
                </Form.Control>            
                </td>
                </tr>{t.layerOverrides(x)}</tbody>
        });
    }
    
    resetRing() {
        this.p_current_div = this.current_division;
        if(this.ring.current_fight != null) {
            this.p_current_item = this.ring.current_fight;
        } else if(this.ring.current_division != null) {
            this.p_current_item = this.ring.current_division.current_comp;
        }        
        this.needs_update = 1;
        window.APP.reset();
    }
    
    resetPending() {
        this.p_current_div = this.g_current_div;
        this.p_current_status = this.g_current_status; 
        this.p_current_item = this.g_current_item; 
        this.needs_update = 0;
        window.APP.reset();
    }
    
    apply() {
        this.g_current_div = this.p_current_div;
        this.g_current_status = this.p_current_status;      
        this.g_current_item = this.p_current_item;
        this.needs_update = 0;
        
        var data = {};
        
        if(this.g_current_div == null) {
            this.g_current_item = null;
            data.div = null;
        }else {
            data.div = this.g_current_div.id;
        }
        data.div_status = this.g_current_status;
        
        if(this.g_current_item != null
                && this.g_current_item instanceof Fight) {
            data.item_type = 'fight';
            data.item_id = this.g_current_item.id;
        } else if (this.g_current_item != null) {
            data.item_type = 'standing';
            data.item_id = this.g_current_item.id;
        } else {
            data.item_type = null;
            data.item_id = null;
        }
        
        
        
        postMaster.immediatePost('/tournament-ring/' +this.ring.id + '/graphics/division-update',data )
        
        window.APP.reset();
    }
    
    
    addWindow() {
        window.APP.window_manager.addWindow();
    }
    
   getLayerOverrides(obj, overridesp) { 
        var t = this;    
        var overrides = overridesp;
        obj.subs.filter(x=>x.type == 'text').forEach(function(x) {
            overrides[x.id] = x.value_override;
            overrides = t.getLayerOverrides(x, overrides);
        });
        
        return overrides;
    }
    
    sendOverrides(layer) {
        var data = {};
        data.layer_id = layer.id;
        var over = {};
        data.overrides = this.getLayerOverrides(layer, over);
        
        postMaster.immediatePost('/tournament-ring/' +this.ring.id + '/graphics/layer-update',data );        
    }
    
    setCurrentWinner() {
        this.needs_update = 1;
        
        if(this.p_current_div != null) {
            this.p_current_item = this.p_current_div.getWinner();
        }
        window.APP.reset();
    }
    
    setLayerStatus(layer, state) {
        var data = {};
        
        data.layer_id = layer.id;
        data.state = state;
        data.send = 1;
        postMaster.immediatePost('/tournament-ring/' +this.ring.id + '/graphics/layers',data )
        layer.state = -1;
        window.APP.reset();
    }
    
    setPLayerStatus(layer, state) {
        layer.p_state = state;
        
        var t = this;
        if(state == 1) {
            layer.subs.forEach(x=>{
                if(x.into_gif != '' && x.into_gif != null && (x.into_gif.includes('.webm') || x.into_gif.includes('.mov') ) ){
                    window.APP.window_manager.insides.forEach(inside=>{
                        var  p = inside.externalWindow.document.getElementById(x.id+'-videom');
                        if(p != null) { 
                            p.currentTime = 0;
                            p.play();
                        }                       
                         
                    });
                    
                    var internal = window.document.getElementById(x.id+'-videom');
                    if(internal != null) { 
                        internal.currentTime = 0;
                        internal.play();
                    }  
                }
            });
        
            setTimeout(function(){ 
                    if (layer.p_state != 1) {
                        return;
                    }
                    layer.p_state = 2;
                    t.setPLayerStatus(layer, 2); 

                    

                    layer.subs.forEach(x=>{
                        if(x.into_gif != '' && x.into_gif != null && (x.into_gif.includes('.webm') || x.into_gif.includes('.mov') ) ){
                            window.APP.window_manager.insides.forEach(inside=>{
                                var  p = inside.externalWindow.document.getElementById(x.id+'-videom');
                                if(p != null) {
                                    p.pause();
                                }                                
                            });
                            
                            var  internal = window.document.getElementById(x.id+'-videom');
                            if(internal != null) {
                                internal.pause();
                            }                            
                        }
                    });

                    window.APP.reset();
                }, 1000 * 4
            );
        }

        if(state == 3) {

            layer.subs.forEach(x=>{
                if(x.into_gif != '' && x.into_gif != null && (x.into_gif.includes('.webm') || x.into_gif.includes('.mov') ) ){
                    window.APP.window_manager.insides.forEach(inside=>{
                        var  p = inside.externalWindow.document.getElementById(x.id+'-videom');
                        if(p == null) { 
                            return;
                        }                        
                        p.currentTime = 6;
                        p.play();
                    });
                   
                }
            });
            window.APP.reset();
            setTimeout(function(){ 
                    if (layer.p_state != 3) {
                        return;
                    }
                    layer.p_state = 0;
                    t.setPLayerStatus(layer, 0);
                    window.APP.reset();
                }, 1000 * 3
            );
        }
        
        window.APP.reset();
    }
    
    resetLayers() {
        var t = this;
        this.layers.filter(x=>x.state > 0).forEach(function(x){
            t.setLayerStatus(x,0);
        });
    }
    
    resetKeyLayers() {
        var t = this;
        console.log(this.layers.filter(x=>x.state > 0 && x.key != '' && x.key))
        this.layers.filter(x=>x.state > 0 && x.key != '' && x.key).forEach(function(x){
            t.setLayerStatus(x,0);
        });
    }
    
    processLayerUpdate(layer, e) {
        var t = this;
    }
    
    parseImage(image) {
        if(image == '%%current_comp_image%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Standing) { 
                    var standing = this.p_current_item;
                    image = standing.photoL;
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_transparent%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Standing) { 
                    var standing = this.p_current_item;
                    image = standing.getTransparentLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_general%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Standing) { 
                    var standing = this.p_current_item;
                    image = standing.getGeneralLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_best%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Standing) { 
                    var standing = this.p_current_item;
                    image = standing.getBestPhoto();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_image%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.photoL;
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_transparent%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getTransparentLarge();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_general%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getGeneralLarge();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_best%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getBestPhoto();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_image%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.photoL;
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_transparent%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getTransparentLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_general%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getGeneralLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_best%%') {
            if(this.p_current_div) {
               if(this.p_current_item instanceof Fight) { 
                    var standing = this.p_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getBestPhoto();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        return image;
    }
    
    parseText(text) { 
        
        if(text == null || text == '' || text == undefined) {
            return '';
        }
        
        var div_name='';
        var cert_name='';
        var current_comp_name='';
        var current_comp_number='';
        var current_comp_city='';
        var current_comp_state='';
        var current_comp_state_short='';
        var current_comp_weight ='';
        var current_comp_age = '';
        var current_comp_team = '';
        
        var score1 = '';
        var score2 = '';
        var score3 = '';
        var score4 = '';
        var score5 = '';
        var score6 = '';
        var score7 = '';
        
        var current_fight_time = '';        
        
        var comp1_name='';
        var comp2_name='';
        
        var comp1_points='';
        var comp2_points='';
        
        var comp1_cpoints='';
        var comp2_cpoints='';
        
        var comp1_city='';
        var comp2_city='';
        
        var comp1_state='';
        var comp2_state='';
        
        var comp1_state_short='';
        var comp2_state_short='';
        
        var comp1_weight='';
        var comp2_weight='';
        
        var comp1_age='';
        var comp2_age='';
        
        var comp1_team='';
        var comp2_team='';
        
        var ring_name='';
        
        var total_score ='';
        
        var ring = this.ring;
        ring_name = ring.name;
        
        if(this.p_current_div) {
            div_name = this.p_current_div.name;
            
            if(this.p_current_div.certificate_name) {
                cert_name = this.p_current_div.certificate_name;
            } else {
               cert_name = div_name; 
            }
            
            if(this.p_current_item instanceof Fight) {
                var left = this.p_current_item.getLeftComp();
                var right = this.p_current_item.getRightComp();
                
                current_fight_time = this.p_current_item.timer.getDisplay();
                
                comp1_name=left.getName();
                comp2_name=right.getName();

                comp1_points=this.p_current_item.getLeftPoints();
                comp2_points=this.p_current_item.getRightPoints();
                
                comp1_cpoints=this.p_current_item.getLeftTotalPoints();
                comp2_cpoints=this.p_current_item.getRightTotalPoints();

                comp1_city=left.getCity();
                comp2_city=right.getCity();

                comp1_state=left.getState();
                comp2_state=right.getState();
                
                comp1_state_short = left.getShortState();
                comp2_state_short = right.getShortState();

                comp1_weight=left.getWeightSimple();
                comp2_weight=right.getWeightSimple();

                comp1_age=left.age;
                comp2_age=right.age;
                
                comp1_team=left.getTeam();
                comp2_team=right.getTeam();
            }
            
            if(this.p_current_item instanceof Standing) { 
                var standing = this.p_current_item;
                current_comp_name=standing.getName();
                current_comp_number=standing.ordered;
                current_comp_city=standing.getCity();
                current_comp_state=standing.getState();
                current_comp_state_short=standing.getShortState();
                current_comp_weight=standing.getWeightSimple();
                current_comp_age=standing.age;
                current_comp_team=standing.getTeam();
                
                score1 = standing.getTextScore(0);
                score2 = standing.getTextScore(1);
                score3 = standing.getTextScore(2);
                score4 = standing.getTextScore(3);
                score5 = standing.getTextScore(4);
                score6 = standing.getTextScore(5);
                score7 = standing.getTextScore(6);
                
                total_score = standing.getScoreTotal(this.p_current_div);
            }
        }
        
        text = text.replace('%%div_name%%', div_name);
        text = text.replace('%%cert_name%%', cert_name);
        
        text = text.replace('%%current_comp_name%%', current_comp_name);
        text = text.replace('%%current_comp_number%%', current_comp_number);
        text = text.replace('%%current_comp_city%%', current_comp_city);
        text = text.replace('%%current_comp_state%%', current_comp_state);
        text = text.replace('%%current_comp_state_short%%', current_comp_state_short);
        text = text.replace('%%current_comp_weight%%', current_comp_weight);
        text = text.replace('%%current_comp_age%%', current_comp_age);
        text = text.replace('%%current_comp_team%%', current_comp_team);
        
        text = text.replace('%%current_comp_score1%%', score1);
        text = text.replace('%%current_comp_score2%%', score2);
        text = text.replace('%%current_comp_score3%%', score3);
        text = text.replace('%%current_comp_score4%%', score4);
        text = text.replace('%%current_comp_score5%%', score5);
        text = text.replace('%%current_comp_score6%%', score6);
        text = text.replace('%%current_comp_score7%%', score7);
        
        
        text = text.replace('%%%%', '');
        
        
        text = text.replace('%%current_comp_scoretotal%%', total_score);
        
        
        text = text.replace('%%current_fight_time%%', current_fight_time);
        
        text = text.replace('%%comp1_name%%', comp1_name);
        text = text.replace('%%comp2_name%%', comp2_name);
        
        text = text.replace('%%comp1_city%%', comp1_city);
        text = text.replace('%%comp2_city%%', comp2_city);
        
        text = text.replace('%%comp1_points%%', comp1_points);
        text = text.replace('%%comp2_points%%', comp2_points);
        
        text = text.replace('%%comp1_cpoints%%', comp1_points);
        text = text.replace('%%comp2_cpoints%%', comp2_cpoints);
        
        text = text.replace('%%comp1_state%%', comp1_state);
        text = text.replace('%%comp2_state%%', comp2_state);
        
        
        text = text.replace('%%comp1_state_short%%', comp1_state_short);
        text = text.replace('%%comp2_state_short%%', comp2_state_short);
        
        text = text.replace('%%comp1_weight%%', comp1_weight);
        text = text.replace('%%comp2_weight%%', comp2_weight);
        
        text = text.replace('%%comp1_age%%', comp1_age);
        text = text.replace('%%comp2_age%%', comp2_age);
        
        text = text.replace('%%comp1_team%%', comp1_team);
        text = text.replace('%%comp2_team%%', comp2_team);
        
        text = text.replace('%%ring_name%%', ring_name);
        
        return text;
    }
    
    renderObject(object, state) { 
        var t = this;
        var style = {};
        var classname = '';
        
        if( (state == 1 || state == 2) && object.into_animation) {
            classname = object.into_animation + ' animated';
        }
        
        if(state == 2 && object.hold_animation) {
            classname = object.hold_animation + ' animated';
        }
        
        if(state == 3 && object.exit_animation) {
            classname = object.exit_animation + ' animated';
        }
        
        if(object.x != '' && object.x != null) {
           style.position = 'absolute';
           style.left = object.x;
        }
        
        if(object.y != '' && object.y != null) {
            style.position = 'absolute';
            style.top = object.y;
        }
        
        if(object.xprime != '' && object.xprime != null) {
           style.position = 'absolute';
           style.right = object.xprime;
        }
        
        if(object.yprime != '' && object.yprime != null) {
           style.position = 'absolute';
           style.bottom = object.yprime;
        }
        
        if(object.height != '' && object.height != null) {
            style.height = object.height;
        }
        
        if(object.width != '' && object.width != null) {
            style.width = object.width;
        }
        
        if(object.font != '' && object.font != null) {
            style.fontFamily = object.font;
        }
        
        if(object.font_size != '' && object.font_size != null) {
            style.fontSize = object.font_size;
        }
        
        if(object.color != '' && object.color != null) {
            style.color = object.color;
        }
        
        if(object.css != ''&& object.css != null) {
            var things = object.css.split(';');
            for (var i = 0; i < things.length; i++) {
                var items = things[i].split(':');                
                if(items.length >= 2) {
                    style[items[0]] = items[1];
                } 
            }
        }
        
        if(object.into_gif != '' && object.into_gif != null) { 
                        
            if(state == 1 && object.into_gif.includes('.gif')) {
                return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={object.into_gif} /></div>
            } 
                        
            if( (state == 1  || state == 2 || state == 3) && (object.into_gif.includes('.webm') || object.into_gif.includes('.mov') )) {
                return <div className={classname} style={style}><video id={ object.id+'-videom' } key={object.into_gif} autoplay="autoplay" muted="muted" style={{ width:'100%',height:'100%' }}  ><source src={object.into_gif} /></video></div>
            }
        }
        
        if(state == 3 && object.exit_gif != '' && object.exit_gif != null && object.exit_gif.includes('.gif') ) {
            return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={object.exit_gif} /></div>
        }
        
        if(object.type == 'css' && state > 0) {
            return <div style={style}></div>
        }
        
        if((object.value == null ||object.value == undefined) && (object.value_override == null ||object.value_override == undefined) ) {
            return <div></div>;
        }
                                    
        if(object.type == 'text' && state > 0) {
            if(object.value_override != null && object.value_override != '') {
                return <div style={style}><div className={classname}>{ t.parseText(object.value_override) }</div></div>;
            }
            
            return <div style={style}><div className={classname}>{t.parseText(object.value) }</div></div>;
        }
        
        if(object.type == 'aggregate') {
            return <div className={classname} style={style}>
               {object.subs.map(function(ob){ return t.renderObject(ob, state);})}
            </div>;
        }
        
        if(object.type == 'image' && state > 0) {        
            if(t.parseImage(object.value) == '') {
                return <div></div>;
            }        
            if( object.value.includes('.webm') || object.value.includes('.mov')) {
                return <div className={classname} style={style}><video key={object.value} autoplay="autoplay" muted="muted" style={{ width:'100%',height:'100%' }}  ><source  src={t.parseImage(object.value)} /></video></div>;
            }                        
            return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={t.parseImage(object.value)} /></div>;
        }
        
        
        return <div></div>
    }
    
    renderPreview(){ var t= this;
        return <div style={{ position:'fixed',top:'0px',left:'0px',width:'100%', height:'100%'}}>
        {this.layers.map(layer=><div style={{ position:'absolute',top:'0px',left:'0px',width:'100%', height:'100%',display:(layer.p_state==0)?'none':'initial' }} >
            {layer.subs.map(function(object){ return t.renderObject(object, layer.p_state);})}
        </div>)}                                                                                        
                                                                                                
        </div>;
    }
    
    handleKeyPress(event) {
        var x = event.code;
        var action = '';
        
        
        if(x.includes('Key') ) {
            x = event.charCode || event.keyCode;
            x = String.fromCharCode(x);
        }
        
        if (event.altKey && !event.shiftKey ) {    
            if(x == 'ArrowRight') { //move to next competitor
               this.next();
            }

            if(x == 'ArrowLeft') { //move to before competitor
                this.previous();
            }

            if(x == 'Enter') { //send to display
                this.apply();
            }

            if(x == 'Backspace') { //reset to display
                this.resetPending();
            }

            if(x == 'Backslash') { //set to scorekeeper
                this.resetRing();
            }

            if(x == 'Slash') { //set winner
                this.setCurrentWinner();
            }
            
            var layer = this.layers.find(l => l.key == x);
            
            if(layer) {
                if(layer.p_state == 0) {
                    this.setPLayerStatus(layer,1);
                }

                if(layer.p_state == 2) {
                    this.setPLayerStatus(layer,3);
                }
            }
            
            event.preventDefault();
        }
        
        if(event.altKey && event.shiftKey ) {
            
            if(x == 'Backspace') {
            
                this.resetKeyLayers();
            }
            
            var layer = this.layers.find(l => l.key == x);
            
            if(layer) {
                if(layer.state == 0) {
                    this.setLayerStatus(layer,1);
                }

                if(layer.state == 2) {
                    this.setLayerStatus(layer,3);
                }
                
                if( (event.ctrlKey || event.metaKey)  && (layer.state == 1 || layer.state == 3) ) {
                    this.setLayerStatus(layer,0);
                }
            }
            
            event.preventDefault();
        }        
   }
   
   componentDidMount(){
        document.addEventListener("keydown", this.handleKeyPress);
        console.log('mounted');
   }
   
    
    next() {
        if(!this.p_current_div) {
            return;
        }
        var order = this.p_current_div.getCompOrder();
        
        if(this.p_current_item && this.p_current_item instanceof Standing) {
            this.p_current_item = this.p_current_item.after;
        }else if(!this.p_current_item && order.length > 0) {
            this.p_current_item = order[0];
        }
        this.needs_update = 1;
        
        window.APP.reset();
    }
    
    previous() {
        if(!this.p_current_div) {
            return;
        }
        var order = this.p_current_div.getCompOrder();
        
        if(this.p_current_item && this.p_current_item instanceof Standing) {
            this.p_current_item = this.p_current_item.previous;
        }else if(!this.p_current_item && order.length > 0) {
            this.p_current_item = order[order.length - 1];
        }
        this.needs_update = 1;
        
        window.APP.reset();
    }
    
    render() {
        
        this.divisions = this.ring.divisions;
        this.current_division = this.ring.current_division;
        const holder = this;
        var layer_types = [];
        this.layers.forEach(function(x){ if(!layer_types.includes(x.type) ) { layer_types.push(x.type);} })
        
        
        this.context.window_manager.setDef(
                        holder.renderPreview(), {
                                not_header:true,
                                remove_header:true,
                                display_type:'green'
                            }
                        );
        
        return <Container style={{ height:this.mainAreaHeight+'px', overflowX:'hidden',paddingBottom:'30px', position:'fixed',width:'100%',height:'100%',overflowY:'scroll' }}>
            <Row  style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'
                style={{ margin:'1%', width:'98%'  }}
                    text= 'light'
                  >
                   <Card.Header >Graphics Controller</Card.Header>
                    <Card.Body >
                        <Container>
                            <Row  style={{ textAlign:'center',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Scorekeeper</strong>
                                </Col>
                                <Col xs={4}>
                                <div>{holder.current_division != null && <span>Division: {holder.current_division.name}</span>}&nbsp;</div>
                                <div>&nbsp;{holder.current_division != null && <span>Status: {holder.current_division.status == 0 && <span>Check-in</span>}{holder.current_division.status == 1 && <span>Competing</span>}{holder.current_division.status == 2 && <span>Scoring</span>}{holder.current_division.status == 3 && <span>Completed</span>}</span>}</div>
                                </Col>
                                <Col xs={4}>
                                <div>Current: {holder.current_division != null && holder.current_division.current_comp != null && <span>{holder.current_division.current_comp.name}</span> }
                                {this.ring.current_fight != null && <span>{this.ring.current_fight.getLeftComp() != null && this.ring.current_fight.getLeftComp().name} vs {this.ring.current_fight.getRightComp() != null && this.ring.current_fight.getRightComp().name}</span> }</div>
                                </Col>
                                <Col xs={3}>
                                </Col>
                            </Row>
                            
                            <Row  style={{ display:'none', textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Display</strong>
                                </Col>
                                <Col xs={5}>
                                <div>
                                    <div>Division: {holder.g_current_div != null && <span>{holder.g_current_div.name}</span> }</div>
                                    <div>Status: {holder.g_current_status == 0 && <span>Check-in</span>}{holder.g_current_status == 1 && <span>Competing</span>}{holder.g_current_status == 2 && <span>Scoring</span>}{holder.g_current_status == 3 && <span>Completed</span>}</div>
                                </div>
                                </Col>
                                <Col xs={5}>
                                {this.g_current_div != null && <div>
                                    Display Item: {this.g_current_item != null && <span>
                                        {this.g_current_item instanceof Fight &&
                                                <span>{this.g_current_item.getLeftComp() != null && this.g_current_item.getLeftComp().name} vs {this.g_current_item.getRightComp() != null && this.g_current_item.getRightComp().name}</span>}

                                        {! this.g_current_item instanceof Fight &&
                                            <span>{this.g_current_item.name}</span>}
                                    </span>}

                                    </div>}
                                </Col>
                            </Row>
                            <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>                                    
                                <Col xs={2}>
                                <div>
                                <Button variant={ (holder.needs_update == 1)? 'primary':'outline-warning' } onClick={ ()=>{ holder.apply() } }>Apply Pending to Display</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button variant='danger' onClick={ ()=>{ holder.resetRing() } }>Set Pending to Scorekeeper</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button  variant='warning' onClick={ ()=>{ holder.resetPending() } }>Reset Pending to Display</Button>
                                </div>
                                </Col>
                                <Col xs={2}>
                                <div>
                                <Button  variant='danger' onClick={ ()=>{ holder.resetLayers() } }>Reset All Layers</Button>
                                </div>
                                </Col>
                                
                                <Col xs={2}>
                                <div>
                                <Button  variant='success' onClick={ ()=>{ holder.next() } }>Next Comp</Button>
                                </div>
                                </Col>
                                
                                <Col xs={2}>
                                <div>
                                <Button  variant='danger' onClick={ ()=>{ holder.sendEagerLoad() } }>Send Eager</Button>
                                <Button  variant='success' onClick={ ()=>{ holder.addWindow() } }>Add Preview</Button>
                                </div>
                                </Col>                                
                                
                            </Row>
                            
                            
                            <Row  style={{ textAlign:'center',borderColor:'white',borderTopStyle:'solid', paddingTop:'20px',paddingBottom:'20px' }}>
                                <Col xs={1}>  
                                    <strong>Pending</strong>
                                </Col>
                                <Col xs={4}>
                                    <div>
                                    Division to Display
                                    <Form.Control value={(this.p_current_div != null) ?  this.p_current_div.id : '' } onChange={(e) => { holder.needs_update = 1; holder.p_current_div = holder.divisions.find(x => x.id == e.target.value); holder.p_current_item = null; window.APP.reset();  }} as="select" size="lg" >
                                        <option value=''>None</option>
                                        {this.ring.getDivisionsInOrder().filter(x => x.status < 3 ).map(x => <option value={x.id}> {x.status == 3 && ' -- '} { (x.status == 2 || x.status == 1 ) && ' !! '} {x.name}</option> )}
                                        {this.ring.getDivisionsInOrder().filter(x => x.status == 3 ).map(x => <option value={x.id}> {x.status == 3 && ' -- '} { (x.status == 2 || x.status == 1 ) && ' !! '} {x.name}</option> )}
                                    </Form.Control>
                                    
                                    <div style={{ display:'none' }}>Division Status to Display
                                    <Form.Control value={this.p_current_status} onChange={(e) => { holder.needs_update = 1; this.p_current_status = e.target.value; window.APP.reset(); }} as="select" size="lg" >
                                        <option value='0'>Check-In</option>
                                        <option value='1'>Competing</option>
                                        <option value='2'>Scoring</option>
                                        <option value='3'>Completed</option>                                      
                                        </Form.Control>   </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    {this.p_current_div != null &&  (this.p_current_status > 0) && <div>
                                      
                                       <div>Current
                                            <Form.Control value={(this.p_current_item != null) ?  this.p_current_item.id : '' } onChange={(e) => { holder.needs_update = 1; holder.p_current_item = holder.p_current_div.standings.find(x => x.id == e.target.value); window.APP.reset(); }} as="select" size="lg" >
                                                <option value=''>None</option>
                                                {this.p_current_div.simpleOrderAll().map(x => <option value={x.id}>{x.ordered} {x.name}</option> )}
                                            </Form.Control></div>                                                                                                    
                                               
                                             {this.p_current_div.config_type == 'sparring' && <div>
                                            Fight
                                            <Form.Control value={(this.p_current_item != null) ?  this.p_current_item.id : '' } onChange={(e) => { holder.needs_update = 1; holder.p_current_item = holder.p_current_div.fights.find(x => x.id == e.target.value); window.APP.reset(); }} as="select" size="lg" >
                                                <option value=''>None</option>
                                                {this.p_current_div.fights.filter(x => x .comp1 != null && x.comp2 != null ).map(x => <option value={x.id}>{x.getLeftComp() != null && x.getLeftComp().name} vs {x.getRightComp() != null && x.getRightComp().name}</option> )}
                                            </Form.Control>
                                            </div>}
                                            
                                            <Button variant='success' onClick={ ()=>{ holder.setCurrentWinner() } }>Set Winner</Button>
                                    </div>}
                                </Col>
                            </Row>                               
                        </Container>
                    </Card.Body>
              </Card>
              </Col>
            </Row>
            
            <Row  style={{ textAlign:'center' }}>
            <Col xs={12}>
                <Card bg='dark'
                style={{ margin:'1%', width:'98%'  }}
                    text= 'light'
                  >
                   <Card.Header >Layers</Card.Header>
                    <Card.Body >
                        <Container>
                        <Row>
                        <Col sm='7'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="fav">
                        <Nav variant="pills" className="flex-row" >
                                <Nav.Item>
                                  <Nav.Link eventKey="fav">Fav</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="active">Active</Nav.Link>
                                </Nav.Item>
                                {layer_types.map(x => <Nav.Item>
                                  <Nav.Link eventKey={x}>{x}</Nav.Link>
                                </Nav.Item>)}
                            </Nav>
                        <Tab.Content>
                        <Tab.Pane eventKey="fav">
                             <Table>
                                {this.layers.filter(x=>x.favourite == 1).map(x=><tr className={ (x.state == 2 ? 'table-success' : (x.state == 0 ? 'table-dark' : ( 'table-warning' ) ) ) }>
                                    <td><Button onClick={()=>{ holder.chooseLayer(x); }} variant='primary'><i class="fas fa-eye"></i></Button> {x.p_state == 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.p_state == 1 && <i class="fas fa-step-forward"></i>}{x.p_state == 2 && <Button onClick={()=>{ holder.setPLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.p_state == 3 && <span><i class="fas fa-step-backward"></i></span>}  {x.p_state > 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                    <td>{x.name}</td>
                                    <td>{x.state == 0 && <Button onClick={()=>{ holder.setLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.state == 1 && <i class="fas fa-step-forward"></i>}{x.state == 2 && <Button onClick={()=>{ holder.setLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.state == 3 && <span><i class="fas fa-step-backward"></i></span>}  {x.state > 0 && <Button onClick={()=>{ holder.setLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                </tr>)}                                           
                                </Table>                                             
                        </Tab.Pane>
                        <Tab.Pane eventKey="active">
                             <Table>
                                {this.layers.filter(x=>x.state != 0).map(x=><tr className={ (x.state == 2 ? 'table-success' : (x.state == 0 ? 'table-dark' : ( 'table-warning' ) ) ) }>
                                    <td><Button onClick={()=>{ holder.chooseLayer(x); }} variant='primary'><i class="fas fa-eye"></i></Button> {x.p_state == 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.p_state == 1 && <i class="fas fa-step-forward"></i>}{x.p_state == 2 && <Button onClick={()=>{ holder.setPLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.p_state == 3 && <span><i class="fas fa-step-backward"></i></span>}  {x.p_state > 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                    <td>{x.name}</td>
                                    <td>{x.state == 0 && <Button onClick={()=>{ holder.setLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.state == 1 && <i class="fas fa-step-forward"></i>}{x.state == 2 && <Button onClick={()=>{ holder.setLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.state == 3 && <span><i class="fas fa-step-backward"></i></span>}  {x.state > 0 && <Button onClick={()=>{ holder.setLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                </tr>)}                                           
                                </Table>                                             
                        </Tab.Pane>
                        {layer_types.map(x => <Tab.Pane eventKey={x}>
                                <Table>
                                {this.layers.filter(y => y.type == x).map(x=><tr className={ (x.state == 2 ? 'table-success' : (x.state == 0 ? 'table-dark' : ( 'table-warning' ) ) ) }>
                                    <td><Button onClick={()=>{ holder.chooseLayer(x); }} variant='primary'><i class="fas fa-eye"></i></Button> {x.p_state == 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.p_state == 1 && <i class="fas fa-step-forward"></i>}{x.p_state == 2 && <Button onClick={()=>{ holder.setPLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.p_state == 3 && <span><i class="fas fa-step-backward"></i></span>}  {x.p_state > 0 && <Button onClick={()=>{ holder.setPLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                    <td>{x.name}</td>
                                    <td>{x.state == 0 && <Button onClick={()=>{ holder.setLayerStatus(x,1); }} variant='warning'><i class="fas fa-play"></i></Button>}{x.state == 1 && <i class="fas fa-step-forward"></i>}{x.state == 2 && <Button onClick={()=>{ holder.setLayerStatus(x,3); }} variant='danger'><i class="fas fa-stop"></i></Button>}{x.state == 3 && <span><i class="fas fa-step-backward"></i></span>} {x.state > 0 && <Button onClick={()=>{ holder.setLayerStatus(x,0); }} variant='danger'><i class="fas fa-skull-crossbones"></i></Button>}</td>
                                </tr>)}                                           
                                </Table>                                     
                        </Tab.Pane>)}
                        </Tab.Content>
                        </Tab.Container>
                        </Col>
                        {holder.choosen_layer && <Col sm="5">
                        <h2>{holder.choosen_layer.name}</h2>
                        <Table>{holder.layerOverrides(holder.choosen_layer)}</Table>
                        <Button onClick={()=>{ holder.choosen_layer.needs_send = 0; holder.sendOverrides(holder.choosen_layer); }} variant={ (holder.choosen_layer.needs_send == 1)? 'primary':'outline-warning' }>Send</Button>
                        </Col>}
                        </Row>                                           
                        </Container>
                    </Card.Body>
              </Card>
              </Col>
            </Row>
        </Container>;
        
    }
}

export class GraphicsReceiver extends GraphicsComp { 
    
    static contextType = AppContext;
    
    constructor(props) {
        super(props);   
        window.APP.fail_transparent = true;
        document.body.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    }
    
    componentWillUnmount() {    
        window.APP.fail_transparent = false;
        document.body.style.backgroundColor = 'rgb(40, 44, 52)';
        window.APP.unRegisterReload(this)
    }
    
    setLayerStatus(layer, state) {
        var data = {};
        
        data.layer_id = layer.id;
        data.state = state;
        data.send = 0;
        postMaster.toSendData('/tournament-ring/' +this.ring.id + '/graphics/layers',data,false )
        window.APP.reset();
    }
        
    processLayerUpdate(layer, e) {
        var t = this;
        if(e.send == 1) { 
            if(e.state == 1) {
                setTimeout(function(){ 
                        if (layer.state != 1) {
                            return;
                        }
                        layer.state = 2;
                        t.setLayerStatus(layer, 2); 
                        
                        layer.subs.forEach(x=>{
                            if(x.into_gif != '' && x.into_gif != null && (x.into_gif.includes('.webm') || x.into_gif.includes('.mov') ) ){
                               var  p = window.document.getElementById(x.id+'-videom');
                               if(p == null) {
                                   return;
                               }
                               p.pause();
                            }
                        });
                        
                        window.APP.reset();
                    }, 1000 * 4
                );
            }

            if(e.state == 3) {
                
                layer.subs.forEach(x=>{
                    if(x.into_gif != '' && x.into_gif != null && (x.into_gif.includes('.webm') || x.into_gif.includes('.mov') ) ){
                        var  p = window.document.getElementById(x.id+'-videom');
                            if(p == null) { 
                                return;
                            }                        
                        p.currentTime = 6;
                        p.play();
                    }
                });
                window.APP.reset();
                setTimeout(function(){ 
                        if (layer.state != 3) {
                            return;
                        }
                        layer.state = 0;
                        t.setLayerStatus(layer, 0);
                        window.APP.reset();
                    }, 1000 * 3
                );
            }
        }
    }
    
    parseText(text) { 
        
        if(text == null || text == '' || text == undefined) {
            return '';
        }
        
        var div_name='';
        var cert_name='';
        
        var current_comp_name='';
        var current_comp_number='';
        var current_comp_city='';
        var current_comp_state='';
        var current_comp_state_short = '';
        var current_comp_weight ='';
        var current_comp_age = '';
        var current_comp_team = '';
        
        var score1 = '';
        var score2 = '';
        var score3 = '';
        var score4 = '';
        var score5 = '';
        var score6 = '';
        var score7 = '';
        
        var current_fight_time = '';        
        
        var comp1_name='';
        var comp2_name='';
        
        var comp1_points='';
        var comp2_points='';
        
        var comp1_cpoints='';
        var comp2_cpoints='';
        
        var comp1_city='';
        var comp2_city='';
        
        var comp1_state='';
        var comp2_state='';
        
        var comp1_state_short='';
        var comp2_state_short='';
        
        var comp1_weight='';
        var comp2_weight='';
        
        var comp1_age='';
        var comp2_age='';
        
        var comp1_team='';
        var comp2_team='';
        
        var total_score = '';
        
        var ring_name='';
        
        var ring = this.ring;
        ring_name = ring.name;
        
        if(this.g_current_div) {
            div_name = this.g_current_div.name;
            
            if(this.g_current_div.certificate_name) {
                cert_name = this.g_current_div.certificate_name;
            } else {
               cert_name = div_name; 
            }
            
            if(this.g_current_item instanceof Fight) {
                var left = this.g_current_item.getLeftComp();
                var right = this.g_current_item.getRightComp();
                
                current_fight_time = this.g_current_item.timer.getDisplay();
                
                comp1_name=left.getName();
                comp2_name=right.getName();

                comp1_points=this.g_current_item.getLeftPoints();
                comp2_points=this.g_current_item.getRightPoints();
                
                comp1_cpoints=this.g_current_item.getLeftTotalPoints();
                comp2_cpoints=this.g_current_item.getRightTotalPoints();

                comp1_city=left.getCity();
                comp2_city=right.getCity();

                comp1_state=left.getState();
                comp2_state=right.getState();
                
                comp1_state_short = left.getShortState();
                comp2_state_short = right.getShortState();

                comp1_weight=left.getWeightSimple();
                comp2_weight=right.getWeightSimple();

                comp1_age=left.age;
                comp2_age=right.age;
                
                comp1_team=left.getTeam();
                comp2_team=right.getTeam();
            }
            
            if(this.g_current_item instanceof Standing) { 
                var standing = this.g_current_item;
                current_comp_name=standing.getName();
                current_comp_number=standing.ordered;
                current_comp_city=standing.getCity();
                current_comp_state=standing.getState();
                current_comp_state_short=standing.getShortState();
                current_comp_weight=standing.getWeightSimple();
                current_comp_age=standing.age;
                current_comp_team=standing.getTeam();
                
                score1 = standing.getTextScore(0);
                score2 = standing.getTextScore(1);
                score3 = standing.getTextScore(2);
                score4 = standing.getTextScore(3);
                score5 = standing.getTextScore(4);
                score6 = standing.getTextScore(5);
                score7 = standing.getTextScore(6);
                
                total_score = standing.getScoreTotal(this.g_current_div);
            }
        }
        
        text = text.replace('%%div_name%%', div_name);
        text = text.replace('%%cert_name%%', cert_name);
        
        text = text.replace('%%current_comp_name%%', current_comp_name);
        text = text.replace('%%current_comp_number%%', current_comp_number);
        text = text.replace('%%current_comp_city%%', current_comp_city);
        text = text.replace('%%current_comp_state%%', current_comp_state);
        text = text.replace('%%current_comp_state_short%%', current_comp_state_short);
        text = text.replace('%%current_comp_weight%%', current_comp_weight);
        text = text.replace('%%current_comp_age%%', current_comp_age);
        text = text.replace('%%current_comp_team%%', current_comp_team);
        
        text = text.replace('%%current_comp_score1%%', score1);
        text = text.replace('%%current_comp_score2%%', score2);
        text = text.replace('%%current_comp_score3%%', score3);
        text = text.replace('%%current_comp_score4%%', score4);
        text = text.replace('%%current_comp_score5%%', score5);
        text = text.replace('%%current_comp_score6%%', score6);
        text = text.replace('%%current_comp_score7%%', score7);
        
        text = text.replace('%%current_comp_scoretotal%%', total_score);
        
        text = text.replace('%%current_fight_time%%', current_fight_time);
        
        text = text.replace('%%comp1_name%%', comp1_name);
        text = text.replace('%%comp2_name%%', comp2_name);
        
        text = text.replace('%%comp1_city%%', comp1_city);
        text = text.replace('%%comp2_city%%', comp2_city);
        
        text = text.replace('%%comp1_points%%', comp1_points);
        text = text.replace('%%comp2_points%%', comp2_points);
        
        text = text.replace('%%comp1_cpoints%%', comp1_points);
        text = text.replace('%%comp2_cpoints%%', comp2_cpoints);
        
        text = text.replace('%%comp1_state%%', comp1_state);
        text = text.replace('%%comp2_state%%', comp2_state);
        
        text = text.replace('%%comp1_weight%%', comp1_weight);
        text = text.replace('%%comp2_weight%%', comp2_weight);
        
        text = text.replace('%%comp1_age%%', comp1_age);
        text = text.replace('%%comp2_age%%', comp2_age);
        
        text = text.replace('%%comp1_team%%', comp1_team);
        text = text.replace('%%comp2_team%%', comp2_team);
        
        text = text.replace('%%ring_name%%', ring_name);
        
        return text;
    }
    
    parseImage(image) {
        
        if(image == '%%current_comp_image%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Standing) { 
                    var standing = this.g_current_item;
                    image = standing.photoL;
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_transparent%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Standing) { 
                    var standing = this.g_current_item;
                    image = standing.getTransparentLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_general%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Standing) { 
                    var standing = this.g_current_item;
                    image = standing.getGeneralLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%current_comp_best%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Standing) { 
                    var standing = this.g_current_item;
                    image = standing.getBestPhoto();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_image%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getLeftComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.photoL;
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_transparent%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getTransparentLarge();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_general%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getGeneralLarge();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%left_comp_best%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getLeftComp();
                    if(standing == null) { 
                        return '';
                    }
                    image = standing.getBestPhoto();
                }else { 
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_image%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.photoL;
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_transparent%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getTransparentLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_general%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getGeneralLarge();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        if(image == '%%right_comp_best%%') {
            if(this.g_current_div) {
               if(this.g_current_item instanceof Fight) { 
                    var standing = this.g_current_item.getRightComp();
                    if(standing == null) {
                        return '';
                    }
                    image = standing.getBestPhoto();
                }else {
                    image = '';
                }
            } else {
                image = '';
            }
        }
        
        return image;
    }
    
    renderObject(object, state) { 
        var t = this;
        var style = {};
        var classname = '';
        
        if( (state == 1 || state ==2 ) && object.into_animation) {
            classname = object.into_animation + ' animated';
        }
        
        if(state == 2 && object.hold_animation) {
            classname = object.hold_animation + ' animated';
        }
        
        if(state == 3 && object.exit_animation) {
            classname = object.exit_animation + ' animated';
        }
        
        if(object.x != '' && object.x != null) {
           style.position = 'absolute';
           style.left = object.x;
        }
        
        if(object.y != '' && object.y != null) {
            style.position = 'absolute';
            style.top = object.y;
        }
        
        if(object.xprime != '' && object.xprime != null) {
           style.position = 'absolute';
           style.right = object.xprime;
        }
        
        if(object.yprime != '' && object.yprime != null) {
           style.position = 'absolute';
           style.bottom = object.yprime;
        }
        
        if(object.height != '' && object.height != null) {
            style.height = object.height;
        }
        
        if(object.width != '' && object.width != null) {
            style.width = object.width;
        }
        
        if(object.font != '' && object.font != null) {
            style.fontFamily = object.font;
        }
        
        if(object.font_size != '' && object.font_size != null) {
            style.fontSize = object.font_size;
        }
        
        if(object.color != '' && object.color != null) {
            style.color = object.color;
        }
        
        if(object.css != ''&& object.css != null) {
            var things = object.css.split(';');
            for (var i = 0; i < things.length; i++) {
                var items = things[i].split(':');                
                if(items.length >= 2) {
                    style[items[0]] = items[1];
                } 
            }
        }
        
        if(object.into_gif != '' && object.into_gif != null) { 
                        
            if(state == 1 && object.into_gif.includes('.gif')) {
                return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={object.into_gif} /></div>
            } 
                        
            if( (state == 1  || state == 2 || state == 3) && (object.into_gif.includes('.webm') || object.into_gif.includes('.mov') )) {
                return <div className={classname} style={style}><video id={ object.id+'-videom' } key={object.into_gif} autoplay="autoplay" muted="muted" style={{ width:'100%',height:'100%' }}  ><source src={object.into_gif} /></video></div>
            }
        }
        
        if(state == 3 && object.exit_gif != '' && object.exit_gif != null && object.exit_gif.includes('.gif') ) {
            return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={object.exit_gif} /></div>
        }
        
        if(object.type == 'css') {
            return <div style={style}></div>;
        }
        
        if((object.value == null ||object.value == undefined) && (object.value_override == null ||object.value_override == undefined) ) {
            return <div></div>;
        }
                                    
        if(object.type == 'text') {
            if(object.value_override != null && object.value_override != '') {
                return <div style={style}><div className={classname}>{ t.parseText(object.value_override) }</div></div>;
            }
            
            return <div style={style}><div className={classname}>{t.parseText(object.value) }</div></div>;
        }
        
        if(object.type == 'aggregate') {
            return <div className={classname} style={style}>
               {object.subs.map(function(ob){ return t.renderObject(ob, state);})}
            </div>;
        }
        
        if(object.type == 'image' && state > 0) {        
            if(t.parseImage(object.value) == '' || !object.value) {
                return <div></div>;
            }        
            if( object.value.includes('.webm') || object.value.includes('.mov')) {
                return <div className={classname} style={style}><video key={object.value} autoplay="autoplay" muted="muted" style={{ width:'100%',height:'100%' }}  ><source src={t.parseImage(object.value)} /></video></div>;
            }                        
            return <div className={classname} style={style}><img style={{ maxWidth:'100%',maxHeight:'100%' }} src={t.parseImage(object.value)} /></div>;
        }
        
        
        return <div></div>;
    }
    
    
    
    
    render() { var t= this;
        return <div style={{ position:'fixed',top:'0px',left:'0px',width:'100%', height:'100%'}}>
        {this.layers.map(layer=><div style={{ position:'absolute',top:'0px',left:'0px',width:'100%', height:'100%',display:(layer.state==0)?'none':'initial' }} >
            {layer.subs.map(function(object){ return t.renderObject(object, layer.state);})}
        </div>)}                                                                                        
                                                                                                
        </div>;
    }
}
