import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import postMaster from '../logic/postmaster'
import { v4 as uuidv4 } from 'uuid';
import {Timer} from '../logic/clocks';
import * as d3 from 'd3'
import {swapFights} from '../functions';
import { AppDomain} from '../constants';

const culmative = false;

export class FightCall {
    constructor(data) {
        this.id = data.id;
        
        this.warning = data.warning;
        this.dq = data.dq;
        this.points = data.points;
        
        this.opposing_points = data.opposing_points;
        this.call = data.availableCall;
        
        this.person = data.person;
        this.time_change = data.time_change;
        this.winner = data.winner;
        this.end_round = data.end_round;
    }
    
    simple() { 
        if(this.person){
            return {
                id: this.id,
                warning: this.warning,
                dq: this.dq,
                points: this.points,
                opposing_points: this.opposing_points,
                call: this.call,
                person_id: this.person.id,
                time_change: this.time_change,
                winner: this.winner,
                end_round: this.end_round
            } 
        } else {
            return {
                id: this.id,
                warning: this.warning,
                dq: this.dq,
                points: this.points,
                opposing_points: this.opposing_points,
                call: this.call,
                person_id: null,
                time_change: this.time_change,
                winner: this.winner,
                end_round: this.end_round
            } 
        }
    }
}

export class FightAvCall {
    constructor(data) {
        this.id = data.id;
        this.description = data.description;
        this.name = data.name;
        this.can_override = data.can_override;
        this.warning = data.warning;
        this.dq = data.dq;
        this.points = data.points;
        this.opposing_points = data.opposing_points;
        this.primary = data.primary;
        this.type = data.type;
        this.time_change = data.time_change;
    }
}

export class Fight { 
    
    getLowerFirstFightsCount() {
        var internal = 0;
        if  (this.comp1_fight == null && this.comp2_fight == null && this.is_bye == 0) {
            internal = internal + 1; 
        }
        if(this.comp1_fight == null && this.comp2_fight == null && this.is_bye == 1) {
            internal = internal + 2; 
        }
        
        if(internal == 0){
            if(this.comp1_fight && this.comp1_loser == 0) {
                internal = internal + this.comp1_fight.getLowerFirstFightsCount();
            }
            if(this.comp1_fight && this.comp1_loser == 1) {
                internal = internal + 1;
            }
            if(this.comp2_fight && this.comp2_loser == 0) {
                internal = internal + this.comp2_fight.getLowerFirstFightsCount();
            }
            if(this.comp2_fight && this.comp2_loser == 1) {
                internal = internal + 1;
            }
        }
        
        return internal;
    }

    getDetailsAhead(fight_id) { 
        var color = '';
        var points = '';
        var flag = '';
        
        if(this.comp2_fight && this.comp2_fight.id == fight_id) {
            if(this.swapped == 0) {
                color = 'blue';
            } else {
                color = 'red';
            }
            if(this.comp2) {
                flag = this.comp2.country;
            }
            points = this.comp2_points;
        }
        
        if(this.comp1_fight && this.comp1_fight.id == fight_id) {
            if(this.swapped == 0) {
                color = 'red';
            } else {
                color = 'blue';
            }
            if(this.comp1) {
                flag = this.comp1.country;
            }
            points = this.comp1_points;
        }
        
        if(this.winner == 0 && this.comp1_points == 0 && this.comp2_points == 0) {
            points = '';
        }
        
        if(this.winner == 0 && this.comp1_points == 0 && this.comp2_points == 0) {
            points = '';
        }
        
        return {
            color:color,points:points,flag:flag
        }
    }
    
    getLeftFight() {
        if(this.swapped == 0) {
            return this.comp1_fight;
        }
        return this.comp2_fight;
    }
    
    getRightFight() {
        if(this.swapped == 0) {
            return this.comp2_fight;
        }
        return this.comp1_fight;
    }
    
    getLeftComp() {
        if(this.swapped == 0) {
            return this.comp1;
        }
        return this.comp2;
    }
    
    getRightComp() {
        if(this.swapped == 0) {
            return this.comp2;
        }
        return this.comp1;
    }
    
    getLeftPoints() {
        if(this.swapped == 0) {
            return this.comp1_points;
        }
        return this.comp2_points;
    }
    
    adjRightRPoints(adj) {
        if(this.swapped == 0) {
            this.comp2_rs+=adj;
        } else {
            this.comp1_rs+=adj;
        }
        window.APP.reset();
    }
    
    
    adjLeftRPoints(adj) {
        if(this.swapped == 0) {
            this.comp1_rs+=adj;
        } else {
            this.comp2_rs+=adj;
        }
        window.APP.reset();
    }
    
    
    getRightRPoints() {
        if(this.swapped == 0) {
            return this.comp2_rs;
        }
        return this.comp1_rs;
    }
    
    getLeftRPoints() {
        if(this.swapped == 0) {
            return this.comp1_rs;
        }
        return this.comp2_rs;
    }
    
    getRightPoints() {
        if(this.swapped == 0) {
            return this.comp2_points;
        }
        return this.comp1_points;
    }
    
    getLeftCPoints() {
        if(this.swapped == 0) {
            return this.comp1_c;
        }
        return this.comp2_c;
    }
    
    getRightCPoints() {
        if(this.swapped == 0) {
            return this.comp2_c;
        }
        return this.comp1_c;
    }
    
    getLeftTotalPoints() {
        if(this.swapped == 0) {
            return this.comp1_c + this.comp1_points;
        }
        return this.comp2_c  + this.comp2_points;
    }
    
    getRightTotalPoints() {
        if(this.swapped == 0) {
            return this.comp2_c + this.comp2_points;
        }
        return this.comp1_c + this.comp1_points;
    }
    
    addAvCall(avCall, comp) {
        var d = {
            id: uuidv4(),
            warning: avCall.warning,
            dq:avCall.dq,
            points: parseInt(avCall.points),
            opposing_points:parseInt(avCall.opposing_points),
            availableCall:avCall,
            person: comp,
            time_change: avCall.time_change,
            winner : 0,
            end_round: 0
        }
        this.processCall(new FightCall( d ) );
    }
    
    getWinner() {
        if(this.winner == 1) {
            return this.comp1;
        }
        if(this.winner == 2) {
            return this.comp2;
        }
        return null;
    }
    
    getLoser() {
        if(this.winner == 2) {
            return this.comp1;
        }
        if(this.winner == 1) {
            return this.comp2;
        }
        return null;
    }
    
    check() {
        if(this.comp1 == null && this.comp1_fight != null && this.comp1_fight.winner != 0) {
            if(this.comp1_loser == 0) {
                this.comp1 = this.comp1_fight.getWinner();
            } else {
                this.comp1 = this.comp1_fight.getLoser();
            }
        }
        
        if(this.comp2 == null && this.comp2_fight != null && this.comp2_fight.winner != 0) {
            if(this.comp2_loser == 0) {
                this.comp2 = this.comp2_fight.getWinner();
            } else {
                this.comp2 = this.comp2_fight.getLoser();
            }
        }
        
        if(this.winner != 0) {
            if(this.comp1_fight) {
                this.comp1_fight.is_editable = false;
            }
            if(this.comp2_fight) {
                this.comp2_fight.is_editable = false;
            }
        }
    }
    
    fightContinue() { 
        if(this.break_timer > 0 && (this.on_break == null || this.on_break < this.current_round)) {
           this.on_break = this.current_round;
           this.timer.current = this.break_timer * 1000;
            var d = {
                id: uuidv4(),
                warning:0,
                dq:0,
                points:0,
                opposing_points:0,
                call:null,
                person: null,
                time_change:0,
                winner: 0,
                end_round: this.current_round
            }        
            this.processCall(new FightCall( d ) );
           
        } else if(this.current_round < this.rounds && (this.break_timer == 0  || this.on_break == this.current_round) ) {
           this.current_round = this.current_round + 1;
           this.timer.current = this.round_timer * 1000;
           
           if(this.break_timer == 0) {
               var d = {
                    id: uuidv4(),
                    warning:0,
                    dq:0,
                    points:0,
                    opposing_points:0,
                    call:null,
                    person: null,
                    time_change:0,
                    winner: 0,
                    end_round: this.current_round
                }        
                this.processCall(new FightCall( d ) );
           }
           
        }
       
       postMaster.toSend('/tournament-fight/'+ this.id +'/tick/' + this.timer.current + '/' + this.timer.running +'?round=' + this.current_round + '&break=' + this.on_break)
    }
    
    shouldIgnore() {
        this.ignore = true;
    }
    
    constructor(place, id, timer=120, rounds = 1, break_time=0, max_fight_spread=0,min_fight_spread=0) {
        this.ignore = false;
        
        if(id) {
            this.id = id;
        } else {
            this.id = uuidv4();
        }
        
        this.declaring = false;
        this.edit_timer = false;
        
        this.fight_num = '';
        this.bracket_round = '';
        
        this.rounds = rounds;
        this.break_timer = break_time;
        this.current_round = 1;
        this.on_break = null;
        
        this.in_ring_name = '';
        
        this.comp1 = null;
        this.comp1_points = 0;
        this.comp1_fight = null;
        this.comp1_loser = 0;
        
        this.double_1 = false;
        this.double_2 = false;
        
        this.comp2 = null;
        this.comp2_points = 0;
        this.comp2_fight = null;
        this.comp2_loser = 0;
        
        this.comp1_c = 0;
        this.comp2_c = 0;
        
        this.comp1_rs = 0;
        this.comp2_rs = 0;
        
        this.swapped = 0;
        
        this.winner = 0;
        this.round_timer = timer;
        this.timer = new Timer(timer);
        
        this.max_fight_spread = max_fight_spread;
        this.min_fight_spread = min_fight_spread;
        
        this.timer.updateFunctions.push(
                (timer) => 
               postMaster.toSend('/tournament-fight/'+ this.id +'/tick/' + timer.current + '/' + timer.running)
                        )
        
        this.proceeds_winner = null;
        this.proceeds_loser = null;
        
        this.history = [];
        
        this.is_bye = 0;
        this.winner_place = place;
        this.is_editable = false;
        this.is_viewable = false;
        this.assignWinnerPlaces = this.assignWinnerPlaces.bind(this);
        
        
        this.getDisplay = this.getDisplay.bind(this);
    }
    
    setTimer(timer) {
        this.timer_start = timer;
        this.timer = new Timer(timer);
        
        this.timer.updateFunctions.push(
                (timer) => 
               postMaster.toSend('/tournament-fight/'+ this.id +'/tick/' + timer.current + '/' + timer.running)
                        )
    }
    
    simple() {
        
        var one = null;
        var two = null;
        
        if(this.comp1_fight) {
            one = this.comp1_fight.simple();
        }
        
        if(this.comp2_fight) {
            two = this.comp2_fight.simple();
        }
        
        var cone = null;
        var ctwo = null;
        
        if(this.comp1) {
            cone = this.comp1.id;
        }
        
        if(this.comp2) {
            ctwo = this.comp2.id;
        }
        
        return {
            id: this.id,
            comp1: cone,
            comp1_points: this.comp1_points,
            comp1_fight: one,
            comp1_loser: this.comp1_loser,

            comp2: ctwo,
            comp2_points: this.comp2_points,
            comp2_fight: two,
            comp2_loser: this.comp2_loser,

            winner: this.winner ,

            history: this.history,

            is_bye: this.is_bye,
            winner_place: this.winner_place,
            
            timer_current: this.timer.current,
            
            current_round : this.current_round,
            rounds : this.rounds,
            break_timer : this.break_timer,
            on_break: this.on_break,
            round_timer: this.round_timer,
            swapped: this.swapped,
            min_fight_spread:this.min_fight_spread,
            max_fight_spread:this.max_fight_spread,            
        }
    }
    
    swap() {
        if(this.swapped == 0) {
            this.swapped = 1;
        } else {
            this.swapped = 0;
        }
        
        var action = {
            type : 'swap',
            value : this.swapped
        };
        
        postMaster.toSendData( '/tournament-fight/' + this.id + '/action', action );       
        
        window.APP.forceUpdate();
    }
    
    getD3Rep(div) {
        var name ='';
        var data = {color:'',points:'',flag:''};
        
        var winner = this.getWinner();
        if(this.proceeds_winner) {            
            data = this.proceeds_winner.getDetailsAhead(this.id);
        } else {
            if(winner != null) {
                data.flag = winner.country;
            }
        }
        if(winner) {
            name = winner.name;
        }
        
        var rep = {
            id : this.id,
            name : name,
            children : [], click: function() {},
            fill: '#2b2b2b',
            fight: this,
            color: data.color,
            points: data.points,
            flag:data.flag
        }
        
        if(this.winner != 0 || (this.comp1 && this.comp2) ) {
            if(data.color == 'blue') {
                rep.fill = 'url(#blue_grad)';
            }

            if(data.color == 'red') {
                rep.fill = 'url(#red_grad)';
            }
        }
        
        var fight_start = this;
        
        if(this.winner == 0 || this.comp1 == null || this.comp2 == null || this.ignore) {
            this.is_editable = false;
            this.is_viewable = false;
        }
        
        if(this.is_viewable) {            
            if(window.APP.ring) {
                rep.click = function() {
                    if(fight_start.in_ring_name == '') {
                        window.APP.ring.setCurrentFight(fight_start);
                        window.APP.forceUpdate();
                    }
                }
            } else {
                rep.click = function() {
                    window.APP.view_fight = fight_start;
                    window.APP.forceUpdate();
                }
            }
        }
        
        
        
        if(this.winner == 0 && this.comp1 && this.comp2 && !this.ignore
            && !( div && div.ring && div.ring.current_fight
            && div.ring.current_fight.id == this.id )    
                ) {
            var fight_start = this;
            if(window.APP.ring) {                
                rep.click = function() {
                    if(fight_start.in_ring_name == '') {
                        window.APP.ring.setCurrentFight(fight_start);
                        window.APP.forceUpdate();
                    }
                }                
            } else {
                if(window.APP.type != 'divisionbracketdisplay') {
                    rep.click = function() { 
                        window.APP.view_fight = fight_start;
                        window.APP.forceUpdate();
                    }
                }
            }
            
            rep.name = (window.APP.ring) ? 'Start Fight' : 'Waiting';
            rep.fill = 'url(#green_grad)'
            
            
        }
        
        if(this.ignore) {
            rep.name = 'skip';            
        }
        
        if(this.winner == 0 && this.comp1 && this.comp2
            && (( div && div.ring && div.ring.current_fight
            && div.ring.current_fight.id == this.id )  || this.in_ring_name != ''  )  
                ) {
            var fight_start = this;
            if(window.APP.ring) {
                rep.click = function() {
                    if(fight_start.in_ring_name == '') {
                        window.APP.ring.setCurrentFight(fight_start);
                        window.APP.forceUpdate();
                    }
                }
            } else {
                rep.click = function() {
                    window.APP.view_fight = fight_start;
                    window.APP.forceUpdate();
                }
            }
            if(this.in_ring_name == '') {
                rep.name = 'Active';
            } else {
                rep.name = this.in_ring_name + ' - Active';
            }
            rep.fill = 'url(#orange_grad)';
        }
        
        if(this.is_bye > 0) {
            rep.children.push({id:'', name:'', children:[], click: function() {}, fill:'#2b2b2b' }); //  8c8c8c
            rep.children.push({id:'', name:'', children:[], click: function() {}, fill:'#2b2b2b' });
        } 
        
        rep.rightclick = function() {
            div.zoomed_fight = fight_start;
            window.APP.forceUpdate();
        }        
        
        if(this.comp1_fight) {
            
            if(this.comp1_loser == 1) {
                rep.children.push(this.comp1_fight.getD3LRep(div) );
            } else {     
                rep.children.push(this.comp1_fight.getD3Rep(div) );
            }   
        }
        
        if(this.comp2_fight) {
            if(this.comp2_loser == 1) {
                rep.children.push(this.comp2_fight.getD3LRep(div) );
            } else {            
                rep.children.push(this.comp2_fight.getD3Rep(div) );
            }            
        }
        
        return rep;
    }
    
    getD3LRep(div) {
        var name ='';
        var data = {color:'',points:'',flag:''};
        
        if(this.proceeds_winner) {            
            data = this.proceeds_loser.getDetailsAhead(this.id);
        }
        
        var loser = this.getLoser();
        if(loser) {
            name = loser.name;
        }
        
        var rep = {
            id : this.id,
            name : name,
            children : [], click: function() {},
            fill: 'url(#loser_grad)',
            fight: this,
            color: data.color,
            points: data.points,
            flag:data.flag
        }
        return rep;
    }
    
    assignWinnerPlaces(num) {
        this.winner_place = num;
        
        if(this.comp1_loser == 0 && this.comp1_fight != null) {
            this.comp1_fight.assignWinnerPlaces( (num * 2) + 1);
        }
        
        if(this.comp2_loser == 0 && this.comp2_fight != null) {
            this.comp2_fight.assignWinnerPlaces( (num * 2) + 1);
        }
    }
    
    pushWinner(ognum) { 
        var num = ognum;
         
        if(this.swapped == 1) {
            if(ognum == 1) {
                num = 2;
            }
            if(ognum == 2) {
                num = 1;
            }
        } else {
            num = ognum;
        }
         
          var d = {
            id: uuidv4(),
            warning:0,
            dq:0,
            points:0,
            opposing_points:0,
            call:null,
            person: null,
            time_change:0,
            winner: num,
            end_round: 0
        }        
        this.processCall(new FightCall( d ) );
     }
    
    changeTime(time) {
        var d = {
            id: uuidv4(),
            warning:0,
            dq:0,
            points:0,
            opposing_points:0,
            call:null,
            person: null,
            time_change:time,
            winner: 0,
            end_round: 0
        }
        
        this.processCall(new FightCall( d ) );
    }
    
    addCustomPoints(comp, points) {
        var d = {
            id: uuidv4(),
            warning:0,
            dq:0,
            points:points,
            opposing_points:0,
            call:null,
            person: comp,
            time_change:0,
                winner: 0,
            end_round: 0
        }
        
        this.processCall(new FightCall( d ) );
    }
    
    receiveCall(call, time=true) {
        if(call.person && this.comp1 && call.person.id == this.comp1.id) {
            this.comp1_points += parseInt(call.points);
            this.comp2_points += parseInt(call.opposing_points);
        }
        
        if(call.person && this.comp2 && call.person.id == this.comp2.id) {
            this.comp2_points += parseInt(call.points);
            this.comp1_points += parseInt(call.opposing_points);
        }
        if(time){
            this.timer.alter(parseInt( call.time_change ))
        }
        if(call.winner != 0) {
            this.declareWinner(call.winner)
        }
        
        if(!culmative && this.rounds > 2 && call.end_round > 0) {
            this.comp1_c += this.comp1_points;
            this.comp2_c += this.comp2_points;
            
            this.comp2_points = 0;
            this.comp1_points = 0;
            
        }
        
        this.history.push(call);
        
        window.APP.forceUpdate();
    }
    
    processCall(call) { 
       postMaster.toSendData('/tournament-fight/'+this.id+'/call',call.simple() );
       this.receiveCall(call);
    }
    
    isTimeToEnd() {
        return this.spreadWinAllowed() || this.timer.current  == 0;
    }
    
    declareWinner(num) {
        this.winner = num;
        
        var winner = null;
        var loser = null;
        if(num == 1) {
            winner = this.comp1;
            loser = this.comp2;
        }
        if(num == 2) {
            winner = this.comp2;
            loser = this.comp1;
        }
        
        if(num == -1) {
            this.winner = 0;
            winner = null;
            loser = null;
        }
        
        if(this.proceeds_winner) {
            var p = this.proceeds_winner;
            
            if(p.comp1_fight && p.comp1_fight.id == this.id) {
                p.comp1 = winner;
            }
            
            if(p.comp2_fight && p.comp2_fight.id == this.id) {
                p.comp2 = winner;
            }
        }
        
        if(this.proceeds_loser) {
            var p2 = this.proceeds_loser;
            
            if(p2.comp1_fight && p2.comp1_fight.id == this.id) {
                p2.comp1 = loser;
            }
            
            if(p2.comp2_fight && p2.comp2_fight.id == this.id) {
                p2.comp2 = loser;
            }
        }
        
        window.APP.forceUpdate();
    } 
    
    pointsDiff() {
        return Math.abs(this.comp1_points - this.comp2_points);
    }
    
    spreadWinAllowed() {
        return  this.max_fight_spread > 0 && this.pointsDiff() >= this.max_fight_spread;
    }
    
    winnerFromSpread() {
        if(!this.spreadWinAllowed() ) {
            return 0;
        }
        if(this.comp1_points > this.comp2_points) {
            return 1;
        } 
        return 2;
    }
    
    get1Color() {
        if(this.notEnoughSpreadYet() ) {
            return '#801d19';
        }
    
        var w = this.winnerFromSpread();
        if(w == 2) {
            return '#801d19';            
        }
        if(w == 1) {
            return '#009414';
        }
    
        return 'black';
    }
    
    get2Color() {
        if(this.notEnoughSpreadYet() ) {
            return '#801d19';
        }
        
        var w = this.winnerFromSpread();
        if(w == 2) {
            return '#009414';            
        }
        if(w == 1) {
            return '#801d19';
        }
        
        return 'black';
    }
    
    getRightColor() {
        if(this.swapped == 0) {
            return this.get2Color();
        }
        return this.get1Color();
    }
    
    getLeftColor() {
        if(this.swapped == 0) {
            return this.get1Color();
        }
        return this.get2Color();
    }
    
    get1BorderColor() {
        if(!this.enoughSpread() ) {
            return 'warning';
        }
        
        return '';
    }
    
    get2BorderColor() {
        if(!this.enoughSpread() ) {
            return 'warning';
        }
        
        return '';
    }
    
    getRightBorderColor() {
        if(this.swapped == 0) {
            return this.get2BorderColor();
        }
        return this.get1BorderColor();
    }
    
    getLeftBorderColor() {
        if(this.swapped == 0) {
            return this.get1BorderColor();
        }
        return this.get2BorderColor();
    }
    
    isLeftWinnerSpread() {
        if(this.swapped == 0) {
            return 1 === this.winnerFromSpread();
        }
        return 2 === this.winnerFromSpread();
    }
    
    isRightWinnerSpread() {
        if(this.swapped == 0) {
            return 2 === this.winnerFromSpread();
        }
        return 1 === this.winnerFromSpread();
    }
    
    enoughSpread() {
        return  this.min_fight_spread == 0 || this.pointsDiff() >= this.min_fight_spread;
    }
    
    notEnoughSpreadYet() {
        if(this.min_fight_spread == 0) {
            return false;
        }
        return this.pointsDiff() < this.min_fight_spread;
    }
    
    notEnoughSpread() {
        if(this.min_fight_spread == 0) {
            return false;
        }
        if(this.winner == 0) {
            return false;
        }
        return this.pointsDiff() < this.min_fight_spread;
    }
    
    getDisplay(styling) { 
        const adj = styling.adj;
    
        var timercolor = 'white';

        if(this.timer.current < 11 && this.timer.current % 2 == 0) {
            timercolor = 'yellow';
        };

        if(this.timer.current == 0) {
            timercolor = 'red';
        };

        const winner = this.getWinner();
        
        var invert = styling.display_type != 'green' || styling.invert_directions == true;
        if(invert) {
            if(styling.display_type != 'green') { 
            return <Container className="not-greenhere" style={{ maxWidth:'100%' }}>
                <Row style={{ marginTop:((15 * adj) + 'px') }}>
                <Col  ><Card bg="dark"><Card.Body style={{ textAlign:'center',padding:'0px' }} >
                    <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>

                    <Col xs={4} style={{ padding:'0px' }}>
                    <Card border={ (winner && this.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                            <Card.Header style={{ fontSize:'175%' }} className={"sparring-" + window.APP.sparring_color}  >{winner && this.getRightComp() && this.getRightComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.getRightComp() && this.getRightComp().name  } &nbsp; {this.getRightComp().country != '' && 
                                        <OverlayTrigger placement={'top'}
                                    overlay={
                                      <Tooltip>
                                        {this.getRightComp().country_name}
                                      </Tooltip>
                                    }
                                    ><img style={{ 'width':((50 * adj) + 'px') }} src={this.getRightComp().country}   ></img></OverlayTrigger> }</Card.Header>

                            <Card.Body style={{ textAlign:'center',backgroundColor:('#' + ( (window.APP.sparring_color == 'blue') ? '011c47' : 'afb4bd') ) }} >
                                <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     
                                    <Col xs="12">{this.getRightComp().team}</Col>
                                </Row>
                                {winner && <Row style={{ paddingBottom:((20 * adj) + 'px') }}>                        
                                        <Col xs={6} style={{ padding:'0px' }}>{this.getRightComp() &&  this.getRightComp().photoL != '' &&  <img style={{ width: '100%'}} src={this.getRightComp().photoL} /> }
                                        </Col>
                                        <Col xs={6} style={{ padding:'0px' }}>
                                            <Card>
                                                <Card.Body style={{ padding:'0',fontSize:'5em',color:'black',lineHeight:'1',paddingBottom:'4%'}}>{this.getRightPoints() }</Card.Body>
                                            </Card>
                                        </Col>
                                </Row>}
                            </Card.Body>
                    </Card>
                    </Col>
                    <Col xs={4} style={{ padding:'0px' }}>
                        {!winner && <Card bg="dark" style={{ color:timercolor,height:'100%',borderColor:this.isTimeToEnd() ?'#f5ff33':'rgb(15, 15, 15)',borderWidth:'20px',borderStyle: 'solid' }} >
                            <Card.Body style={{ textAlign:'center',padding:'0px',backgroundColor: '#0f0f0f' }} >
                                <div>
                                <span style={{ fontSize:'6em',lineHeight:'1', }}>
                                    {this.timer.getDisplay() }</span>
                                    <div>
                                        {this.rounds > 1 && (this.on_break == null || this.on_break < this.current_round) && <span>Round {this.current_round} / {this.rounds}</span> }
                                        {this.rounds > 1 && (this.current_round < this.rounds && this.on_break == this.current_round) && <span>Break After Round {this.current_round} / {this.rounds}</span> }
                                    </div>
                                </div>                        
                                </Card.Body>
                        </Card>}

                        {winner && <div  style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'250%'  }}>
                            <h1 style={{ fontSize:"80%"}}>Fight Winner</h1>
                           <h1 style={{ fontSize:"80%"}}>{winner.name}</h1>
                               <div style={{ textAlign:'center' }}>
                                   {winner.photoL != '' &&  <img style={{ width: ((250 * adj) + 'px')}} src={winner.photoL} /> }
                               </div>

                               {winner.country != '' && <div style={{ textAlign:'center', marginTop:((20 * adj) + 'px') }}><img style={{ 'width':((75 * adj) + 'px') }} 
                                       src={winner.country}   ></img></div> }
                       </div>}

                    </Col>
                    <Col xs={4} style={{ padding:'0px' }}>
                    <Card border={ (winner && this.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                        <Card.Header className="sparring-red" style={{ fontSize:'175%' }}>{winner && this.getLeftComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.getLeftComp() && this.getLeftComp().name  } &nbsp; 
                        {this.getLeftComp() && this.getLeftComp().country != '' && 
                                            <OverlayTrigger placement={'top'}
                              overlay={
                                <Tooltip>
                                  {this.getLeftComp().country_name}
                                </Tooltip>
                              }
                              ><img style={{ 'width':((50 * adj) + 'px') }} src={this.getLeftComp().country}   ></img></OverlayTrigger> }
                        </Card.Header>
                        <Card.Body style={{ textAlign:'center', backgroundColor:'#610008' }} >
                            <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     
                                <Col xs="12">{this.getLeftComp() && this.getLeftComp().team}</Col>
                            </Row>
                            {winner && <Row style={{ paddingBottom:((20 * adj) + 'px') }}>
                                <Col xs={6} style={{ padding:'0px' }}>
                            <Card>
                                <Card.Body style={{ padding:'0',fontSize:'5em',color:'black',lineHeight:'1',paddingBottom:'4%'}}>{this.getLeftPoints() }</Card.Body>
                            </Card>
                            </Col>                     
                            <Col xs={6} style={{ padding:'0px' }}>{this.getLeftComp() &&  this.getLeftComp().photoL != '' &&  <img style={{ width: '98%',borderRadius:'5px'}} src={this.getLeftComp().photoL} /> }
                            </Col>
                            </Row>}
                        </Card.Body>
                    </Card>
                    </Col>



                    {!winner && <Col xs={6} style={{padding:'0px'}}>
                    <Card border={ (winner && this.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                        <Card.Body style={{ textAlign:'center' }} >
                            <Container>
                            <Row style={{ paddingBottom:'20px' }}>
                            <Col xs={3}>{this.getRightComp() &&  this.getRightComp().photoL != '' &&  <img style={{ width: '98%',borderRadius:'5px'}} src={this.getRightComp().photoL} /> }
                            </Col>                        
                            <Col xs={3}>
                                { this.getRightComp() && this.getRightComp().weight && this.getRightComp().weight != '' &&  <span>{this.getRightComp().getWeightString()}</span> }<br/>
                                { this.getRightComp() && this.getRightComp().age && this.getRightComp().age != '' &&  <span>{this.getRightComp().age} years</span> }<br/>
                                { this.getRightComp() &&  this.getRightComp().height }
                            </Col>
                            <Col xs={6}>
                                <Card border={this.getRightBorderColor() }>
                                   <Card.Body style={{ padding:'0',fontSize:'9em',color:this.getRightColor(),lineHeight:'1',paddingBottom:'4%'}}>{this.getRightPoints() }</Card.Body>
                                </Card>
                            </Col>
                            </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    </Col>}

                    {!winner && <Col xs={6} style={{padding:'0px'}}>
                    <Card border={ (winner && this.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                        <Card.Body style={{ textAlign:'center' }} >
                            <Container>
                            <Row style={{ paddingBottom:'20px' }}>
                                <Col xs={6}>
                            <Card border={this.getLeftBorderColor()}>
                                <Card.Body style={{ padding:'0',fontSize:'9em',color:this.getLeftColor(),lineHeight:'1',paddingBottom:'4%'}}>{this.getLeftPoints() }</Card.Body>
                            </Card>
                            </Col>

                            <Col xs={3}>
                                { this.getLeftComp() && this.getLeftComp().weight && this.getLeftComp().weight != '' &&  <span>{this.getLeftComp().getWeightString()}</span> }<br/>
                                { this.getLeftComp() && this.getLeftComp().age && this.getLeftComp().age != '' &&  <span>{this.getLeftComp().age} years</span> }<br/>
                                { this.getLeftComp() &&  this.getLeftComp().height }
                            </Col>
                            <Col xs={3}>{this.getLeftComp() &&  this.getLeftComp().photoL != '' &&  <img style={{ width:  '98%',borderRadius:'5px'}} src={this.getLeftComp().photoL} /> }
                            </Col>
                            </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    </Col>}


                    </Row></Container>    

                </Card.Body></Card></Col>                                                                                                                                                                                                            
                </Row> 
            </Container>;
            } else {
                return <Container className={(styling.not_body == 1)? 'animated slideOutDown' : 'animated slideInUp' } style={{ maxWidth:'100%',  position:'absolute',bottom:'0px' }}>
                    <Row style={{ paddingLeft:'15px' }}>
                        <Col xs={1} style={{padding:'0', backgroundColor:'#000', textAlign:'center', borderRadius:'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'  }}>
                            <img style={{ 'width':'60%', marginTop:((10 * adj) + 'px') }} 
                                    src={AppDomain + "/app/white_M_icon.png"}   ></img>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom:'15px', }}>
                    <Col  ><Card bg="dark" style={{ borderRadius:"0 calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)" }}><Card.Body style={{ textAlign:'center',padding:'0px' }} >
                        <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>

                        <Col xs={4} style={{ padding:'0px' }}>
                        <Card border={ (winner && this.getRightComp().id == winner.id) ? 'warning' : '' } className={"sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>
                                <Card.Header style={{ fontSize:'200%',padding:'3px' }} className={"sparring-" + window.APP.sparring_color}  >{winner && this.getRightComp() && this.getRightComp().id == winner.id && <i class="fas fa-crown text-warning"></i>}  {this.getRightComp().country != '' && !styling.hide_flags && 
                                            <OverlayTrigger placement={'top'}
                                        overlay={
                                          <Tooltip>
                                            {this.getRightComp().country_name}
                                          </Tooltip>
                                        }
                                        ><img style={{ 'width':'1em' }} src={this.getRightComp().country}   ></img></OverlayTrigger> }&nbsp; {this.getRightComp() && this.getRightComp().name  }  </Card.Header>

                                <Card.Body style={{ textAlign:'center',backgroundColor:('#' + ( (window.APP.sparring_color == 'blue') ? '011c47' : 'afb4bd') ) }} >
                                    <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     
                                        <Col xs="6">{this.getRightComp().team}</Col>
                                        <Col xs={6}>
                                            { this.getRightComp() && this.getRightComp().weight && this.getRightComp().weight != '' &&  <span>{this.getRightComp().getWeightString()}</span> }
                                            { this.getRightComp() && this.getRightComp().age && this.getRightComp().age != '' &&  <span>&nbsp;({this.getRightComp().age})</span> }

                                        </Col>
                                    </Row>

                                </Card.Body>
                        </Card>
                        </Col>
                        <Col xs={1} style={{ padding:'0px' }}>
                            <Card style={{ height:'100%' }}>
                                <Card.Body style={{ fontSize:'3em',color:'black',lineHeight:'1',verticalAlign:'middle'}}>{this.getRightPoints() }</Card.Body>
                            </Card>    
                        </Col>
                        <Col xs={2} style={{ padding:'0px' }}>
                            {!winner && <Card bg="dark" style={{ color:timercolor,height:'100%' }} >
                                <Card.Body style={{ textAlign:'center',padding:'0px',backgroundColor: '#0f0f0f' }} >
                                    <div>
                                    <span style={{ fontSize:'4em',lineHeight:'1', }}>
                                        {this.timer.getDisplay() }</span>
                                        <div>
                                            {this.rounds > 1 && (this.on_break == null || this.on_break < this.current_round) && <span>Round {this.current_round} / {this.rounds}</span> }
                                            {this.rounds > 1 && (this.current_round < this.rounds && this.on_break == this.current_round) && <span>Break After Round {this.current_round} / {this.rounds}</span> }
                                        </div>
                                    </div>                        
                                    </Card.Body>
                            </Card>}

                            {winner && <div  style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'225%'  }}>
                                <h1 style={{ fontSize:"80%"}}>Fight Winner</h1>
                                    <h1 style={{ fontSize:"80%"}}>{winner == this.getLeftComp() && <span>Red</span>}{winner == this.getRightComp() && <span>Blue</span>}</h1>
                           </div>}

                        </Col>
                        <Col xs={1} style={{ padding:'0px' }}>
                            <Card style={{ height:'100%' }}>
                                <Card.Body style={{ fontSize:'3em',color:'black',lineHeight:'1',verticalAlign:'middle'}}>{this.getLeftPoints() }</Card.Body>
                            </Card>    
                        </Col>
                        <Col xs={4} style={{ padding:'0px' }}>
                        <Card border={ (winner && this.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-red" style={{ textAlign:'center', height:'100%' }}>
                            <Card.Header className="sparring-red" style={{ fontSize:'200%',padding:'3px' }}>{winner && this.getLeftComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.getLeftComp() && this.getLeftComp().name  } &nbsp; 
                            {this.getLeftComp() && this.getLeftComp().country != '' && !styling.hide_flags && 
                                                <OverlayTrigger placement={'top'}
                                  overlay={
                                    <Tooltip>
                                      {this.getLeftComp().country_name}
                                    </Tooltip>
                                  }
                                  ><img style={{ 'height':'1em' }} src={this.getLeftComp().country}   ></img></OverlayTrigger> }
                            </Card.Header>
                            <Card.Body style={{ textAlign:'center', backgroundColor:'#610008' }} >
                                <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     

                                    <Col xs="6">{this.getLeftComp().team}
                                    { this.getLeftComp() && this.getLeftComp().weight && this.getLeftComp().weight != '' &&  <span>{this.getLeftComp().getWeightString()}</span> }
                                            { this.getLeftComp() && this.getLeftComp().age && this.getLeftComp().age != '' &&  <span>&nbsp;({this.getLeftComp().age})</span> }
                                            </Col>
                                    <Col xs="6">{this.getLeftComp().team}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        </Col>
                        </Row></Container>    

                    </Card.Body></Card></Col>                                                                                                                                                                                                            
                    </Row> 
                </Container>;
            }
        } else {
            return <Container className={(styling.not_body == 1)? 'animated slideOutDown' : 'animated slideInUp' } style={{ maxWidth:'100%',  position:'absolute',bottom:'0px' }}>
                    <Row style={{ paddingLeft:'15px' }}>
                        <Col xs={1} style={{padding:'0', backgroundColor:'#000', textAlign:'center', borderRadius:'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'  }}>
                            <img style={{ 'width':'60%', marginTop:((10 * adj) + 'px') }} 
                                    src={AppDomain + "/app/white_M_icon.png"}   ></img>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom:'15px', }}>
                    <Col  ><Card bg="dark" style={{ borderRadius:"0 calc(.25rem - 1px) calc(.25rem - 1px) calc(.25rem - 1px)" }}><Card.Body style={{ textAlign:'center',padding:'0px' }} >
                        <Container style={{ width:'100%;', maxWidth:'100%' }}><Row>

                        <Col xs={4} style={{ padding:'0px' }}>
                        <Card border={ (winner && this.getLeftComp().id == winner.id) ? 'warning' : '' } className={"sparring-red"} style={{ textAlign:'center', height:'100%' }}>
                                <Card.Header style={{ fontSize:'200%',padding:'3px' }} className={"sparring-red"}  >{winner && this.getLeftComp() && this.getLeftComp().id == winner.id && <i class="fas fa-crown text-warning"></i>}  {this.getLeftComp().country != '' && !styling.hide_flags && 
                                            <OverlayTrigger placement={'top'}
                                        overlay={
                                          <Tooltip>
                                            {this.getLeftComp().country_name}
                                          </Tooltip>
                                        }
                                        ><img style={{ 'height':'1em' }} src={this.getLeftComp().country}   ></img></OverlayTrigger> }&nbsp; {this.getLeftComp() && this.getLeftComp().name  }  </Card.Header>

                                <Card.Body style={{ textAlign:'center',backgroundColor:('#610008'  ) }} >
                                    <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     
                                        <Col xs="6">{this.getLeftComp().team}</Col>
                                        <Col xs={6}>
                                            { this.getLeftComp() && this.getLeftComp().weight && this.getLeftComp().weight != '' &&  <span>{this.getLeftComp().getWeightString()}</span> }
                                            { this.getLeftComp() && this.getLeftComp().age && this.getLeftComp().age != '' &&  <span>&nbsp;({this.getLeftComp().age})</span> }

                                        </Col>
                                    </Row>

                                </Card.Body>
                        </Card>
                        </Col>
                        <Col xs={1} style={{ padding:'0px' }}>
                            <Card style={{ height:'100%' }}>
                                <Card.Body style={{ fontSize:'3em',color:'black',lineHeight:'1',verticalAlign:'middle'}}>{this.getLeftPoints() }</Card.Body>
                            </Card>    
                        </Col>
                        <Col xs={2} style={{ padding:'0px' }}>
                            {!winner && <Card bg="dark" style={{ color:timercolor,height:'100%' }} >
                                <Card.Body style={{ textAlign:'center',padding:'0px',backgroundColor: '#0f0f0f' }} >
                                    <div>
                                    <span style={{ fontSize:'4em',lineHeight:'1', }}>
                                        {this.timer.getDisplay() }</span>
                                        <div>
                                            {this.rounds > 1 && (this.on_break == null || this.on_break < this.current_round) && <span>Round {this.current_round} / {this.rounds}</span> }
                                            {this.rounds > 1 && (this.current_round < this.rounds && this.on_break == this.current_round) && <span>Break After Round {this.current_round} / {this.rounds}</span> }
                                        </div>
                                    </div>                        
                                    </Card.Body>
                            </Card>}

                            {winner && <div  style={{ color:"white", textTransform : "uppercase", width : '100%', paddingTop: '1%',textAlign:'center', fontSize :'225%'  }}>
                                <h1 style={{ fontSize:"80%"}}>Fight Winner</h1>
                                    <h1 style={{ fontSize:"80%"}}>{winner == this.getLeftComp() && <span>Red</span>}{winner == this.getRightComp() && <span>Blue</span>}</h1>
                           </div>}

                        </Col>
                        <Col xs={1} style={{ padding:'0px' }}>
                            <Card style={{ height:'100%' }}>
                                <Card.Body style={{ fontSize:'3em',color:'black',lineHeight:'1',verticalAlign:'middle'}}>{this.getRightPoints() }</Card.Body>
                            </Card>    
                        </Col>
                        <Col xs={4} style={{ padding:'0px' }}>
                        <Card border={ (winner && this.getLeftComp().id == winner.id) ? 'warning' : '' } className="sparring-blue" style={{ textAlign:'center', height:'100%' }}>
                            <Card.Header className="sparring-blue" style={{ fontSize:'200%',padding:'3px' }}>{winner && this.getRightComp().id == winner.id && <i class="fas fa-crown text-warning"></i>} {this.getRightComp() && this.getRightComp().name  } &nbsp; 
                            {this.getRightComp() && this.getRightComp().country != '' && !styling.hide_flags && 
                                                <OverlayTrigger placement={'top'}
                                  overlay={
                                    <Tooltip>
                                      {this.getRightComp().country_name}
                                    </Tooltip>
                                  }
                                  ><img style={{ 'height':'1em' }} src={this.getRightComp().country}   ></img></OverlayTrigger> }
                            </Card.Header>
                            <Card.Body style={{ textAlign:'center', backgroundColor:'#' + ( (window.APP.sparring_color == 'blue') ? '011c47' : 'afb4bd') }} >
                                <Row style={{ paddingBottom:((20 * adj) + 'px') }}>     

                                    <Col xs="6">{this.getRightComp().team}</Col>
                                    <Col xs="6">{ this.getRightComp() && this.getRightComp().weight && this.getRightComp().weight != '' &&  <span>{this.getRightComp().getWeightString()}</span> }
                                            { this.getRightComp() && this.getRightComp().age && this.getRightComp().age != '' &&  <span>&nbsp;({this.getRightComp().age})</span> }
                                            </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        </Col>
                        </Row></Container>    

                    </Card.Body></Card></Col>                                                                                                                                                                                                            
                    </Row> 
                </Container>;
        }
    }
}

export class BracketChart  extends React.Component {
    constructor(props) {
        super(props);         
        this.div = props.div;
        this.screen = props.screen;
        this.styling = props.styling;
        this.div_id = uuidv4();
        
        this.drag_allowed = !props.drag_stopped;
        
        this.tv = props.tv;
        
        this.chart_node = React.createRef();
        this.chart=false;
        this.bracket = props.bracket;
    }
    
    componentDidMount() {
        this.chart = true;
        this.draw();
    }
     
    componentDidUpdate(){
        this.draw();
    }
     
    draw() { const holder = this;
        if(this.div.show_fight_list && this.tv == 1) {
           return;
        }
        
        if(!this.is_rendering) {
            return;
        }
        
    
        var drag_allowed = this.drag_allowed;
    
        var adj = 1;
        if(this.tv == 1) {
            adj = parseFloat( this.screen.sizing ); 
            if(this.screen.brackets_adj) {
               adj = adj * parseFloat( this.screen.brackets_adj );  
            }
        } 
       
        
        const fontchange = (adj + 2.0) / 3.0;
        while (this.chart_node.current.hasChildNodes()) {
            this.chart_node.current.removeChild(this.chart_node.current.lastChild);
        }
        
         if(! this.div.bracketsD3Chart()) { 
            return;
        }
        
            var totalWidth = this.chart_node.current.clientWidth;
            var frameWidth = totalWidth;
        
            var nodes = d3.hierarchy(this.div.bracketsD3Chart() , function(d) {
              return d.children;
            });
            
            var depth = 0;
            var d_node = nodes;
          
            while (d_node != null &&  d_node.children != null && d_node.children.length > 0) {
                depth++;
                d_node = d_node.children[0];
            }

            var is_framed = false;

            if(this.bracket) {
                var w = window;
                if (w.frameElement != null
                        && w.frameElement.nodeName === "IFRAME"
                        && w.parent.jQuery) {                    
                    is_framed = true;
                }
            }

            if(is_framed) {
                let minWidth = 150 + 200 * (depth - 1);
                if(totalWidth < minWidth) {
                    totalWidth= minWidth;
                    frameWidth = totalWidth;
                }
                
            }

            totalWidth = totalWidth + (totalWidth / depth) * 1;
              

            var margin_tops = 5*adj;
            var margin_sides = 90*adj;
        
            var width = (totalWidth - (25*adj) ) - (180 * adj);
            var height =((50 * this.div.getFirstRowBracketCount() ) + 200)*adj - (10 * adj);

            if(this.bracket) {
                var w = window;
                if (w.frameElement != null
                        && w.frameElement.nodeName === "IFRAME"
                        && w.parent.jQuery) {
                    w.parent.jQuery('.bracketchartholder').height(height);
                    w.parent.jQuery('.bracketchartholder').css({
                        "min-width": frameWidth + "px"
                    });
                }
            }


            var treemap = d3.tree().size([height, width]);

            nodes = treemap(nodes);
          
          
            //Add full SVG
            var svg = d3.select(this.chart_node.current).append("svg");
          
            svg.attr("width", width + margin_sides + margin_sides);
            svg.attr("height", height + margin_tops + margin_tops);
            
            
            //add margins requested by the display
            var main = svg.append("g").attr("transform","translate(" + margin_sides + "," + margin_tops + ")");


            //ADD ALL THE gradiants
            var defs = svg.append("defs");
            var one = defs.append("linearGradient")

            .attr("id", "blue_grad")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%")
            ;
            one.append("stop")
            .attr("offset", "0%")
            .style("stop-color", "#00358B")
            .style("stop-opacity", 1)

            one.append("stop")
            .attr("offset", "50%")
            .style("stop-color", "#011c47")
            .style("stop-opacity", 1);

            one.append("stop")
            .attr("offset", "100%")
            .style("stop-color", "#00358B")
            .style("stop-opacity", 1);
    
            one = defs.append("linearGradient")

            .attr("id", "red_grad")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%")
            ;
            one.append("stop")
            .attr("offset", "0%")
            .style("stop-color", "#87000b")
            .style("stop-opacity", 1)

            one.append("stop")
            .attr("offset", "50%")
            .style("stop-color", "#610008")
            .style("stop-opacity", 1);
    
            one.append("stop")
            .attr("offset", "100%")
            .style("stop-color", "#87000b")
            .style("stop-opacity", 1);
    
    
            one = defs.append("linearGradient")

            .attr("id", "green_grad")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%")
            ;
            
            one.append("stop")
            .attr("offset", "0%")
            .style("stop-color", "#008207")
            .style("stop-opacity", 1)

            one.append("stop")
            .attr("offset", "50%")
            .style("stop-color", "#0d5711")
            .style("stop-opacity", 1);
    
            one.append("stop")
            .attr("offset", "100%")
            .style("stop-color", "#008207")
            .style("stop-opacity", 1);
    
            one = defs.append("linearGradient")

            .attr("id", "orange_grad")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%")
            ;
            
            one.append("stop")
            .attr("offset", "0%")
            .style("stop-color", "#cfa21b")
            .style("stop-opacity", 1)

            one.append("stop")
            .attr("offset", "50%")
            .style("stop-color", "#966400")
            .style("stop-opacity", 1);
    
            one.append("stop")
            .attr("offset", "100%")
            .style("stop-color", "#cfa21b")
            .style("stop-opacity", 1);
    
            one = defs.append("linearGradient")

            .attr("id", "loser_grad")
            .attr("x1", "0%")
            .attr("x2", "100%")
            .attr("y1", "0%")
            .attr("y2", "0%")
            ;
            one.append("stop")
            .attr("offset", "0%")
            .style("stop-color", "#6a1d6e")
            .style("stop-opacity", 1)

            one.append("stop")
            .attr("offset", "50%")
            .style("stop-color", "#3e0d40")
            .style("stop-opacity", 1);
    
            one.append("stop")
            .attr("offset", "100%")
            .style("stop-color", "#6a1d6e")
            .style("stop-opacity", 1);


            var linkdata = nodes.descendants().slice(1);

            //add the links
          var link = main.selectAll(".link")
              .data( linkdata )
            .join("path")
                .attr("class", function(d){
                        return 'link level-' + d.depth 
                 })
                 .attr("d", function(d) { //add the data to the path
                 var mid = (d.y + d.parent.y) / 2.0;
                 var fromy = d.x + 4*adj;
                 var toy = d.parent.x + 4*adj;
                     
                 var c = "M" + (width - d.y) + "," + fromy
                     + " " + (width - mid) + "," + fromy + "\n" +
                   "M" + (width - mid) + "," + fromy +
                    " " + (width - mid) + "," + toy + "\n" +
                    "M" +  (width - mid) + "," + toy +
                    " " + (width -d.parent.y) + "," + toy
                   ;
           
           
           ;
           return c;
                     
                 })  
            ;

          var node = main.selectAll(".node")
            .data(nodes.descendants())
            .join("g")
            .attr("class", function(d){
                return 'node level-' + d.depth + ' id-' + d.data.id;;
            })
              .on("click",function(d) { if(window.event && window.event.ctrlKey && d.data.rightclick) {  d.data.rightclick(); } else { d.data.click();} })
              .on("contextmenu", function (d, i) {
                  if(d.data.rightclick) {
                      d.data.rightclick();
                  }
                  d3.event.preventDefault();
                })
              .attr("transform", function(d) { 
                return "translate(" + ((width - d.y)+60) + "," + d.x + ")"; })
            .call(d3.drag().on("end", function() {
                
                if(!drag_allowed) {
                    return;
                }
                
                var one = null, two = null
                var s = d3.select(document.elementFromPoint(d3.event.sourceEvent.clientX, d3.event.sourceEvent.clientY))
                if(s._groups.length > 0 && s._groups[0].length > 0 && s._groups[0][0].__data__ && s._groups[0][0].__data__.data) {    
                     one = s._groups[0][0].__data__.data.fight
                }
                two = d3.event.subject.data.fight

                if (one && two) {
                    swapFights(one, two, true)
                }            
            } ) );


        var sadj = 1 + ( (adj - 1) * 0.9);
        const size = 215; //175 + 50

        //add each element to build each node
        node.append("rect")
             .attr("width", size * adj)
             .attr("fill", function(d) { return d.data.fill; })
             .attr("height", 23 * adj).attr("transform", "translate(" + (-115*adj) + "," + (-8*adj)+ ")" );

        node.append("image")
             //.attr("width", 25 * adj)
             .attr("href", function(d) { if(d.data.flag) return d.data.flag; return ''; } )
             .attr("height", 23* adj).attr("transform", "translate(" + (-150*adj) + "," + (-8*adj)+ ")")

        node.append("rect")
             .attr("width", 27* adj)
             .attr("fill", function(d) { return '#222'; })
             .attr("height", 23* adj).attr("transform", "translate(" + (97*adj) + "," + (-8*adj)+ ")")


        node.append("text")
              .attr("font-size", (70 * fontchange) + "%")
              .attr("fill", "white")
               .attr("stroke", "white")
                .attr("stroke-width", 0.5)
              .style("text-anchor", "middle")
              .attr("transform", "translate(" + (-5*adj) + "," + (9*sadj)+ ")")
              .text(function(d) { if(d.data.name.length > 20) {return d.data.name.substr(0, 22) + '...'; } return d.data.name; });
      
        node.append("text")
              .attr("font-size", (70* fontchange) + "%")
              .attr("fill", "white")
              .style("text-anchor", "middle")
              .attr("transform", "translate(" + (110*adj) + "," + (10*sadj) + ")")
              .text(function(d) { return d.data.points; });
    }
    
    render() {
        
        var adj = 1;
        if(this.tv == 1) {
            adj = parseFloat( this.screen.sizing );
            if(this.screen.brackets_adj) {
               adj = adj * parseFloat( this.screen.brackets_adj );  
            }
        }
        
        if(this.div.show_fight_list && this.tv == 1) {
            this.is_rendering = false;
            var round = 1;
            
            return <Container style={{maxWidth:'100%' }}>
            <Row>
            {this.div.fightsInOrder().filter(x=>x.winner == 0 && (x.comp1 || x.comp2) && !this.ignore).map(function(x) { 
                var arr = [];
                if(round != x.bracket_round) {
                    round++;
                    //arr.push(<Col xs="3"><Card bg={'light align-items-center d-flex justify-content-center'} text="dark"  style={{ textAlign:'center', height:'100%' }}>Round {round}</Card></Col>);
                }
                
                 arr.push(<Col xs="3">
                    <Container style={{ height:'100%', paddingBottom:'15px' }}>
                    <Row style={{ height:'100%' }}>
                    <Col xs="2" style={{ padding:'0px' }}><Card bg={'dark align-items-center d-flex justify-content-center'}  style={{ textAlign:'center', height:'100%' }}>{x.fight_num}. ({x.bracket_round})</Card></Col>   
                       
                    <Col xs="4" style={{ padding:'0px' }}><Card className={"align-items-center d-flex justify-content-center sparring-" + window.APP.sparring_color} style={{ textAlign:'center', height:'100%' }}>{x.getRightComp() ? x.getRightComp().name : (x.getRightFight().fight_num+'.')}</Card></Col>   
                    <Col xs="2" style={{ padding:'0px' }}><Card bg={'dark align-items-center d-flex justify-content-center'} style={{ textAlign:'center', height:'100%' }}>VS</Card></Col> 
                    <Col xs="4" style={{ padding:'0px' }}><Card className="align-items-center d-flex justify-content-center sparring-red" style={{ textAlign:'center', height:'100%' }}>{x.getLeftComp() ? x.getLeftComp().name : (x.getLeftFight().fight_num+'.')}</Card></Col>   
                    </Row>                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                    </Container>                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                    </Col>)
                    
                return arr;} )}                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
            </Row>                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
            </Container>
        }
        
        if(this.chart){
            this.draw();
        }
        this.is_rendering = true;
        return <div style={{ width:'100%', height:((50 * this.div.getFirstRowBracketCount() ) + 200)*adj - (10 * adj) }} id={this.div_id} ref={this.chart_node}></div>;
    }
}